import React, { FC } from 'react'
import {
  HeadLine1,
  HeadLine2,
  HeadLine3,
  BodyDefault,
  BodyMedium,
  BodySmall,
  BodySmallMedium,
  VerySmall,
} from './styled'

type TypographyProps = {
  variant: 'h1' | 'h2' | 'h3' | 'body' | 'body-medium' | 'small' | 'small-medium' | 'very-small',
  children: any,
  customStyles?:any,
}

const Typography:FC<TypographyProps> = ({variant, children, customStyles}) => {
  switch(variant){
    case 'h1':
      return <HeadLine1 style={customStyles}>{children}</HeadLine1>
    case 'h2':
      return <HeadLine2 style={customStyles}>{children}</HeadLine2>
    case 'h3':
      return <HeadLine3 style={customStyles}>{children}</HeadLine3>
    case 'body':
      return <BodyDefault style={customStyles}>{children}</BodyDefault>
    case 'body-medium':
      return <BodyMedium style={customStyles}>{children}</BodyMedium>
    case 'small':
      return <BodySmall style={customStyles}>{children}</BodySmall>
    case 'small-medium':
      return <BodySmallMedium style={customStyles}>{children}</BodySmallMedium>
    case 'very-small':
      return <VerySmall style={customStyles}>{children}</VerySmall>
    default: return <BodyDefault style={customStyles}>{children}</BodyDefault>
  }
    
}

export default Typography