import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  CheckboxWrapper,
  CheckboxContainer,
} from "./styles";
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import CheckBox from "../../checkbox";
import { useUi } from "../../../context/ui.context";
import { objStorageService } from "../../../api";
import TopProgress from "../../TopBarProgress";

type ModalProps = {
  closeModalCancel: () => void;
  closeModal: (data: any) => void;
  alertTitle: string;
  instance?: any;
  loading?: boolean;
  loadingComplete?: boolean;
};

const BucketVisibility: FC<ModalProps> = (props) => {
  const { closeModal, closeModalCancel, alertTitle, instance, loading, loadingComplete} = props;
  const [t] = useTranslation();
  const [visibility, setVisibility] = useState<string>("private");

  const onSubmit = () => {
    closeModal({access:visibility});
  };
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const {viewAlert} = useUi()
  useEffect(() => {
    objStorageService.getFileInfo(instance.bucketId, instance.name)
    .then((res) => {
      setVisibility(res.data.ACL)
      setPageLoading(false)
    })
    .catch((err) => {
      console.log('err',err?.response?.data?.detail);
      viewAlert({
        severity:'error',
        message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    })
  },[])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{ marginLeft: "10px", marginTop: "10px" }}>
          {t("OBJECT_VISIBILITY")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon} />
      </HeadWrapper>
      <BodyModal>
        <div>
          {t("OBJECT_NAME")}:{instance?.name}
        </div>
        <Typography variant="body">
          {t("OBJECT_VISIBILITY_DESCRIPTION")}
        </Typography>
        <CheckboxContainer
          onClick={() => setVisibility("private")}
          active={visibility === "private"}
        >
          <CheckBox
            title={t("PRIVATE")}
            value={visibility === "private"}
            onChange={() => setVisibility("private")}
          />
        </CheckboxContainer>
        <CheckboxContainer
          onClick={() => setVisibility("public-read")}
          active={visibility === "public-read"}
        >
          <CheckBox
            title={t("PUBLIC")}
            value={visibility === "public-read"}
            onChange={() => setVisibility("public-read")}
          />
        </CheckboxContainer>
        <div style={{ display: "flex", gap: "6px", marginTop: "30px" }}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: "177px", justifyContent: "center" }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: "177px", justifyContent: "center" }}
            onClick={onSubmit}
            loadingComplete={loadingComplete}
            title={t("SAVE")}
          />
        </div>
      </BodyModal>
    </Container>
  );
};

export default BucketVisibility;
