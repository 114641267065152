import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  BackArrow,
  Input,
  Toggle,
  Select,
  CheckBox,
  RevealButton,
  Button,
  TopProgress
} from '../../../components'
import {
  Container,
  TitleBig,
  FormWrapper,
  CreateContainer,
  Info,
  InfoText,
  DarkForm
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import Label from '../../../components/label'
import { networkService, portService } from '../../../api'
import { useUi } from '../../../context/ui.context'
import { InfoContainer, InfoTitle } from '../../../components/info-block/styles'
import { icons } from '../../../icons'



const CreatePort:FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const {portId} = useParams()
  const [port, setPort] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [portSecurity, setPortSecurity] = useState<boolean>(true)
  const [autoAddPortSecurity, setAutoAddPortSecurity] = useState<boolean>(true)
  const [admin,setAdmin] = useState<boolean>(true)
  const [subnets, setSubnets] = useState<any[]>([])
  const [networks, setNetworks] = useState<any[]>([])
  const [region, setRegion] = useState<any>('')
  const [vnicTypes, setVnicTypes] = useState<any[]>([])
  const [fixedIps, setFixedIps] = useState<any[]>([])



  const [initialValues, setInitialValues] = useState<any>({
    name:'',
    description:'',
    network:'',
    mac_address:'',
    ip_address_or_subnet:'unspecified',
    vnic_type:'',
    selected_fixed_ip_address:'',
    host_id:'',
    subnet:'',
    fixed_ips:[]

  })
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      ...values,
      admin_state_up:admin,
      auto_add_security_group:autoAddPortSecurity,
      port_security_enabled:portSecurity,
    }

    if(!location.pathname.includes('create') && port){
      portService.editPort({...port, ...data})
      .then((res) => {
        setLoadingComplete(true)
        setTimeout(() => {
          navigate('/ports')
          resetForm()
          setLoadingComplete(false)
          setLoading(false)
        },1000)
      })
      .catch((err:any) => {
        setLoading(false)
        viewAlert({
          severity:'error',
          message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
        })
      })
    } else {
      if (data.ip_address_or_subnet === 'subnet') {
        delete data.selected_fixed_ip_address;
        data.fixed_ips = [{subnet_id:values?.subnet}]
      }
      if (data.ip_address_or_subnet === 'fixed_ip_address') {
        delete data.subnet;
        data.fixed_ips = [{ip_address:values?.selected_fixed_ip_address}]
      }
      if (data.ip_address_or_subnet === 'unspecified') {
        delete data.selected_fixed_ip_address;
        delete data.subnet;
      }
      portService.createPort(data)
      .then((res) => {
        setLoadingComplete(true)
        setTimeout(() => {
          navigate('/ports')
          resetForm()
          setLoadingComplete(false)
          setLoading(false)
        },1000)
      })
      .catch((err:any) => {
        setLoading(false)
        viewAlert({
          severity:'error',
          message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
        })
      })
    }
  }

  const getCreateOptions = async () => {
    try{
      if(portId){
        const portRes = await portService.getPort(portId)
        setPort(portRes?.data)
        setInitialValues({
          name:portRes?.data?.name,
          description:portRes?.data?.description,
          network:portRes?.data?.network,
          mac_address:portRes?.data?.mac_address,
          ip_address_or_subnet:'fixed_ip_address',
          vnic_type:portRes?.data?.vnic_type,
          selected_fixed_ip_address:portRes?.data?.selected_fixed_ip_address,
          host_id:portRes?.data?.host_id,
          subnet:portRes?.data?.subnet,
        })
        setPortSecurity(portRes?.data?.port_security_enabled)
        // setAutoAddPortSecurity(portRes?.data?.)
        setAdmin(portRes?.data?.admin_state_up)

        setFieldValue('fixed_ips', portRes?.data?.fixed_ips?.map((ip:any) => {
          return {
            ip_address:ip?.ip_address || "",
            // subnet_id:ip?.subnet_id || ""
          }
        }));
        setFixedIps(portRes?.data?.fixed_ips?.map((ip:any) => {
          return {
            ip_address:ip?.ip_address || "",
            // subnet_id:ip?.subnet_id || ""
          }
        }))
        
      }
      const resCreateOptions = await portService.getCreateOptions()
      const resSubnets = await networkService.getSubnets()
      const resNetworks = await networkService.getAllNetworks({
        page:1,
        ordering:'',
        pageSize:50
      })
      setSubnets(resSubnets.data.objects?.map((subnet:any) => {
        return {
          ...subnet,
          label:subnet?.name || subnet?.id,
          value:subnet?.id
        }
      }))
      setNetworks(resNetworks.data.objects?.filter((net:any) => net?.name !== 'external_network')?.map((network:any) => {
        return {
          ...network,
          label:network?.name || network?.id,
          value:network?.id
        }
      }))
      setVnicTypes(resCreateOptions.data.vnic_types?.map((vnic:any) => {
        return {
          ...vnic,
          label:vnic?.[1],
          value:vnic?.[0]
        }
      }))
      setRegion(resCreateOptions.data.regions?.[0])
      await setPageLoading(false)
    } catch (e) {

    }

  }

  useEffect(() => {
    getCreateOptions()
  },[])

  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/

  const {
    values,
    errors,
    touched,
    resetForm,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
      network: yup.string().required(`${t('REQUIRED_FIELD')}`),
      mac_address: yup.string(),
      ip_address_or_subnet: yup.string(),
      vnic_type: yup.string(),
      selected_fixed_ip_address: !location.pathname.includes('create') && port ? yup.string() : yup.string().when("ip_address_or_subnet", {
        is: (ip_address_or_subnet:any) => ip_address_or_subnet === 'fixed_ip_address',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
      host_id:yup.string(),
      subnet: yup.string().when("ip_address_or_subnet", {
        is: (ip_address_or_subnet:any) => ip_address_or_subnet === 'subnet',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
      fixed_ips: !location.pathname.includes('create') && port ? yup.array().of(
        yup.object().shape({
          ip_address: yup.string().when("ip_address_or_subnet", {
            is: (ip_address_or_subnet:any) => ip_address_or_subnet === 'fixed_ip_address',
            then: yup.string().required(`${t('REQUIRED_FIELD')}`)
          }),
          subnet_id: yup.string().when("ip_address_or_subnet", {
            is: (ip_address_or_subnet:any) => ip_address_or_subnet === 'subnet',
            then: yup.string().required(`${t('REQUIRED_FIELD')}`)
          }),
        })
      ) : yup.array(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  console.log('errors', errors);
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const portSelect = [
    {
      label:t("PORT_SELECT_UNSPECIFIED"),
      value:'unspecified',
    },
    {
      label:t("PORT_SELECT_SUBNET"),
      value:'subnet',
    },
    {
      label:t("PORT_SELECT_FIXED_IP"),
      value:'fixed_ip_address',
    }
  ]
  const handleInputChange = (index:any, field:any, value:any) => {
    const updatedData = [...fixedIps];
    updatedData[index][field] = value;
    setFixedIps(updatedData);
    setFieldValue('fixed_ips', updatedData);
  };
  const addNewItem = (instance?:any) => {
    setFixedIps((prev:any) => {
      setFieldValue('fixed_ips', [...prev, 
        {
          ip_address:instance?.ip_address || "",
        }
      ]);
      return [...prev, 
        {
          ip_address:instance?.ip_address || "",
        }
      ]
    })
  };
  const removeItem = (index:any) => {
    const updatedData = [...fixedIps];
    updatedData.splice(index, 1);
    setFixedIps(updatedData);
    setFieldValue('fixed_ips', updatedData);
  };

  const isEdit = !location.pathname.includes('create') && port
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <CreateContainer>
      <Container>
        <BackArrow
          onClick={() => navigate('/ports')}
          title={t("BACK_TO_PORTS")}
        />
        <TitleBig style={{margin:'0px'}}>{isEdit ? t("EDIT_PORT") + ' ' + (port?.name || port?.id) : t("CREATE_A_NEW_PORT")}</TitleBig>
        <FormWrapper>
          <Input
            fieldName='name'
            title={t("NAME_PORT")}
            placeholder=''
            customStyles={{width:'100%'}}
            {...formikProps}
          />
          <Select
            required
            toolTip={false}
            data={networks}
            disabled={isEdit}
            fieldName='network'
            selectedValue={values['network']}
            onChange={(e) => {
              handleChange('network')(e)
              handleChange('subnet')('')
            }}
            {...formikProps}
            title={t("NETWORK")}
          />
        </FormWrapper>
        <Toggle
          value={admin}
          onChange={() => setAdmin(!admin)}
          title={t("ADMIN_STATE_UP")}
        />
        <FormWrapper>
          <Select
            toolTip={false}
            data={portSelect}
            fieldName='ip_address_or_subnet'
            selectedValue={values['ip_address_or_subnet']}
            onChange={(e) => handleChange('ip_address_or_subnet')(e)}
            {...formikProps}
            title={t("IP_OR_SUBNET")}
          />
          {values['ip_address_or_subnet'] === 'fixed_ip_address' && !isEdit ?
            <Input
              required
              fieldName='selected_fixed_ip_address'
              title={t("IP_ADDRESS")}
              placeholder=''
              customStyles={{width:'100%'}}
              {...formikProps}
            />
          : null}
          {values['ip_address_or_subnet'] === 'subnet' && values['network']  ? 
            <Select
              required
              toolTip={false}
              data={subnets?.filter((sub:any) => sub?.network === values['network'])}
              fieldName='subnet'
              selectedValue={values['subnet']}
              onChange={(e) => handleChange('subnet')(e)}
              {...formikProps}
              title={t("SELECT_SUBNET")}
            />
          : null}
          {isEdit && 
            <>
              {/* {values['ip_address_or_subnet'] === 'subnet' && values['network'] &&
                <Select
                  required
                  toolTip={false}
                  data={subnets?.filter((sub:any) => sub?.network === values['network'])}
                  fieldName='subnet'
                  selectedValue={values['subnet']}
                  onChange={(e) => handleChange('subnet')(e)}
                  {...formikProps}
                  title={t("SELECT_SUBNET")}
                />
              } */}
              {values['ip_address_or_subnet'] === 'fixed_ip_address' && values['network'] &&
                <InfoContainer>
                  <InfoTitle>
                    {t("IP_ADDRESSES")}
                  </InfoTitle>
                  <Button
                    variant="stroke"
                    size="display"
                    icon={icons.PlusDefault}
                    customStyles={{marginTop:"10px"}}
                    //@ts-ignore
                    onClick={addNewItem}
                    title={t("ADD_IP_ADDRESS")}
                  />
                  {fixedIps?.map((item:any, index:number) => (
                    <DarkForm>
                      <Input
                        fieldName={`fixed_ips[${index}].ip_address`}
                        required
                        toolTip={false}
                        title={t("IP_ADDRESS")}
                        placeholder=''
                        value={item.ip_address}
                        customStyles={{width:'241px', flex:'auto 1'}}
                        {...formikProps}
                        handleChange={(e:any) => handleInputChange(index, 'ip_address', e.target.value)}
                      />
                      <Button
                        onClick={() => removeItem(index)}
                        variant='icon'
                        size='display'
                        // disabled
                        customStyles={{marginTop:'24px'}}
                      >
                        <img src={icons.DeleteIcon}/>
                      </Button>
                    </DarkForm>
                  ))}
                </InfoContainer>
              }
            </>
          }
          <Input
            fieldName='mac_address'
            title={t("MAC_ADDRESS")}
            placeholder=''
            customStyles={{width:'100%'}}
            {...formikProps}
          />
        </FormWrapper>
        <CheckBox
          value={portSecurity}
          onChange={() => setPortSecurity(!portSecurity)}
          title={t("PORT_SECURITY")}
        />
        {isEdit ? null :
          <CheckBox
            customStyles={{marginTop:'10px'}}
            value={autoAddPortSecurity}
            onChange={() => setAutoAddPortSecurity(!autoAddPortSecurity)}
            title={t("AUTO_ADD_SECURITY_GROUP")}
          />
        }
        {isEdit ?
          <div>
            <Select
              toolTip={false}
              data={vnicTypes}
              fieldName='vnic_type'
              selectedValue={values['vnic_type']}
              onChange={(e) => handleChange('vnic_type')(e)}
              {...formikProps}
              title={t("VNIC_TYPE")}
              customStyles={{marginBottom:'20px', width:'360px'}}
            />
            {isEdit ? null :
              <Input
                fieldName='host_id'
                title={t("BINDING")}
                placeholder=''
                customStyles={{width:'360px'}}
                {...formikProps}
              />
            }
            <Input
              isTextArea
              fieldName='description'
              title={t("DESCRIPTION")}
              customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
              placeholder=''
              {...formikProps}
            />
          </div>
        :
          <div style={{ marginTop:"20px"}}>
            <RevealButton
              title={t("ADDITIONAL_SETTINGS")}
            >
              <Select
                toolTip={false}
                data={vnicTypes}
                fieldName='vnic_type'
                selectedValue={values['vnic_type']}
                onChange={(e) => handleChange('vnic_type')(e)}
                {...formikProps}
                title={t("VNIC_TYPE")}
                customStyles={{marginBottom:'20px', width:'360px'}}
              />
              <Input
                fieldName='host_id'
                title={t("BINDING")}
                placeholder=''
                customStyles={{marginBottom:'15px', width:'360px'}}
                {...formikProps}
              />
              <Input
                isTextArea
                fieldName='description'
                title={t("DESCRIPTION")}
                customStyles={{maxWidth:'550px', height:'200px'}}
                placeholder=''
                {...formikProps}
              />
            </RevealButton>
          </div>
        }
        <div style={{display:'flex', marginTop:'20px'}}>
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            customStyles={{marginRight:"10px"}}
            onClick={handleSubmit}
            title={isEdit ? t("EDIT") : t("CREATE")}
          />
          <Button
            variant="stroke"
            size="display"
            onClick={() => navigate("/ports")}
            title={t("CANCEL")}
          />
        </div>
      </Container>
      {isEdit ? null :
        <Container style={{marginTop:'91px'}}>
          <Info>
            <Label reverse title={t("IP_OR_SUBNET")}/>
            <InfoText>
              {t("IP_OR_SUBNET_INFO_1")}
            </InfoText>
            <InfoText style={{marginTop:"10px"}}>
              {t("IP_OR_SUBNET_INFO_2")}
            </InfoText>
          </Info>
          <Info style={{marginTop:"20px"}}>
            <Label reverse title={t("MAC_ADDRESS")}/>
            <InfoText>
              {t("MAC_INFO")}
            </InfoText>
          </Info>
        </Container>
      }
    </CreateContainer>
  )
}

export default CreatePort

