import styled from 'styled-components'
import { BodySmallMedium } from '../typography/styled'
import SVG from "react-inlinesvg";

type Styles = {
  customStyles?:any
}

export const ArrowCloseImg = styled(SVG)`
  & path {
    stroke:#4671F6;
  }
`

export const Container = styled.div<Styles>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* width:200px; */
  ${({customStyles}) => customStyles}

`

export const OnClickArea = styled.div<Styles>`
  display: flex;
  align-items: center;
  &:hover{
    cursor: pointer;
  }
`

export const BackText = styled(BodySmallMedium)`
  color: #4671F6;
  margin-left: 6px;
`