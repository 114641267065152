import styled from "styled-components";
import SVG from "react-inlinesvg";
import { BodyMedium, BodyDefault } from "../typography/styled";
type ActiveProp = {
  active?:boolean,
}
export const Container = styled.div<ActiveProp>`
  width: 100%;
  display: ${({active}) => active ? 'flex' : 'none' };;
  box-sizing: border-box;
  justify-content: space-between;
  right:20px;
  transform:${({active}) => active ? '' : 'translate(420px)' };
  opacity:${({active}) => active ? '1' : '0'};
  transition-property: transform, opacity, display;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  align-items: flex-start;
  padding: 10px;
  z-index: 1;
  border: ${({theme}) => `1px solid ${theme.palette[20]}`};
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  background-color:${({theme}) => theme.palette[10]};
  &:hover {
    cursor: pointer;
    background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[100]};
  }
  &:active{
    background-color: ${({theme}) => theme.isDark ? theme.palette[400] : theme.grey[200]};
    transition: 0.1s background-color ease-in-out;
  }
`

export const DocIcon = styled(SVG)`
  & path {
    fill:${({theme}) => theme.isDark ?  theme.palette[200] : ''};
    stroke:${({theme}) => theme.isDark ?  '#595959' : ''} ;
  }
`

export const IconBackground = styled.div`
  width:48px;
  height:48px;
  margin-right: 15px;
  box-sizing: border-box;
`
export const Title = styled(BodyMedium)`
  color: ${({theme}) => theme.primary[600]};
  /* text-transform: capitalize; */
`

export const TitleWrapper = styled.div`
  display:flex;
  flex-direction: column;
`

export const CloseIcon = styled(SVG)`
  margin-top: 2px;
  & path {
    stroke:${({theme}) => theme.palette[800]};
  }
  &:hover {
    cursor: pointer;
  }
`

export const Content = styled(BodyDefault)`
  color: #6C6C6C;
  margin-top: 4px;
`