import styled, {  keyframes, css } from 'styled-components'
import SVG from "react-inlinesvg";
import { BodyMedium, BodyDefault, BodySmall, VerySmall } from "../typography/styled";
type ActiveProp = {
  active?:boolean,
  type?:string,
  isDark?:boolean
}

function styleMyEl(type:any, isDark?:boolean) {
  switch (type) {
    case 'information':
      return css`
        background-color: #F1F4F9;
      `;
    case 'error':
      return css`
        background-color: #FFE5E5;
      `;
    case 'warning':
      return css`
        background: rgba(255, 209, 47, 0.1);;
      `;
    default:
      return css`
        background-color: ${isDark => isDark ? '#F1F4F9' : '#F1F4F9'};
      `;
  }
}

export const Container = styled.div<ActiveProp>`
  width: 400px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  right:20px;
  transform:${({active}) => active ? '' : 'translate(420px)' };
  opacity:${({active}) => active ? '1' : '0'};
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  align-items: flex-start;
  padding: 10px;
  border: ${({isDark}) => isDark ? `1px solid #393939` : '1px solid #E8E8E8'};
  border-radius: 4px;
  z-index: 40;
  margin-top: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: ${({isDark}) => isDark ? "#242424" : '#FFFF'};
`

export const IconBackground = styled.div<ActiveProp>`
  ${({type, isDark}) => styleMyEl(type, isDark)};
  width:28px;
  height:28px;
  padding: 4px;
  margin-right: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({isDark}) => isDark ? '#393939' : '#F1F4F9'};
`
export const Title = styled(BodyMedium)<ActiveProp>`
  color: ${({isDark}) => isDark ? "#E3E3E3" : '#1E1E1E'};
  text-transform: capitalize;
`

export const TitleWrapper = styled.div`
  /* margin-top: 4px; */
  width: 100%;
  display:flex;
  flex-direction: column;
`

export const CloseIcon = styled.img<ActiveProp>`
  width: 20px;
  height: 20px;
 & path {
    stroke:${({isDark}) => isDark ? "#E3E3E3" : "#474B53"};
  }
  &:hover {
    cursor: pointer;
  }
`

export const Content = styled(BodyDefault)<ActiveProp>`
  color: ${({isDark}) => isDark ? "#FFF" : "#6C6C6C"};
  word-break: break-word;
`

export const ProgressBar = styled.progress<AlertPropsCustom>`
  width: 100%;
  height: 4px;
  border-radius: 6px;
  margin-top:6px;
  transition: all 0.5s ease-in-out;
  ::-webkit-progress-bar {
    background-color: #D9D9D9;
    border-radius: 6px;
  }
  ::-webkit-progress-value {
    background-color: ${({theme, success}) =>  success ? theme.success : theme.primary[600]};
    border-radius: 6px;
  }
`

export const ThinText = styled(VerySmall)`
  color:${({theme}) => theme.grey[600]};
`
const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
`;
type AlertPropsCustom = {
  isDark?:any;
  closing?:any;
  success?:boolean;
}
export const AlertCustom = styled.div<AlertPropsCustom>`
  width: 400px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  right:20px;
  align-items: flex-start;
  padding: 10px;
  border: ${({isDark}) => isDark ? `1px solid #393939` : '1px solid #E8E8E8'};
  border-radius: 4px;
  z-index: 40;
  margin-top: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: ${({isDark}) => isDark ? "#242424" : '#FFFF'};
  animation: ${({ closing }) => closing ? css`${slideOut} 0.3s ease-in-out` : css`${slideIn} 0.3s ease-in-out`};

`;