import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BodyForm,
  InputsWrapper,

} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Input,
  Select,
  Button,
  ModalVariables,
  TopProgress,
  MultiInput,
  MatchPassword,
  
} from "../../../../components";
import { useQueryParam, StringParam } from 'use-query-params';
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { useAuth } from "../../../../context/user.context";
import ContentLoader, { List } from 'react-content-loader'
import { useTheme } from "styled-components";
import { useInstance } from "../../../../context/instanceContext";
import { icons } from "../../../../icons";
import ServerSelector from "./serverSelect";
import { PasswordWrapper } from "../../../Instances/components/create-instance/configure/styles";


const Configure:FC<any> = ({osList, serverList, disks}) => {
  const {user, quotas} = useAuth()
  const InputRef:any = useRef()
  const [activeConfirm, setActiveConfirm] = useState<boolean>(false)
  const {setDataToLs, getDataToLs, removeItemFromLs} = useLocalStorage()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [tags, setTags] = useState<string[]>(getDataToLs({key:'baremetal.configure'})?.tags || [])
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [activeButton, setActiveButton] = useState<boolean>(false)
  const closeModal = (isSave?:boolean) => {
    setViewModal(false)
    setActiveAnimation(false)
    if(!isSave){
      removeItemFromLs('baremetal.configure')
      removeItemFromLs('baremetal.network')
    }
    navigate("/instances")
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const openModal = () => {
    setViewModal(true)
    setActiveAnimation(true)
  }


  const navigate = useNavigate()
  const [t] = useTranslation()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [initialValues, setInitialValues] = useState<any>({
    name:getDataToLs({key:'baremetal.configure'})?.name,
    type:getDataToLs({key:'baremetal.configure'})?.type,
    os:getDataToLs({key:'baremetal.configure'})?.os,
    tag:getDataToLs({key:'baremetal.configure'})?.tag || '',
    disk_layout_id:getDataToLs({key:'baremetal.configure'})?.disk_layout_id || '',
    root_password:''
  }) 
  const onSubmit = (values:any) => {
    // setConfigureData({...values, tags:tags})
    setStep("network")
  }
  const regLetter = /^(?=.*[a-z])(?=.*[A-Z])/
  const regNumbers = /^(?=.*[0-9])/
  const regSymbols = /^(?=.*[!@#$%^&*()_=+§`~<>?])/
  const regName = /^[a-zA-Z0-9.]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
      .required(`${t('REQUIRED_FIELD')}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      type: yup.string().required(`${t('REQUIRED_FIELD')}`),
      os: yup.string().required(`${t('REQUIRED_FIELD')}`),
      tag:yup.string(),
      disk_layout_id:yup.string(),
      root_password:yup.string()
        .required(`${t('REQUIRED_FIELD')}`)
        .min(8, `${t("PASSWORD_DID_NOT_PASS")}`)
        .max(24, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regLetter, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regNumbers, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regSymbols, `${t("PASSWORD_DID_NOT_PASS")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const changeTags = (tag:string) => {
    const founded = tags.find((item:any) => item === tag)
    if(founded || tag === '' || tag === undefined) {
      setTags((prev:any) => {
        return prev.filter((item:any) => item !== tag)
      })
    } else {
      setTags((prev) => [...prev, tag])
      handleChange('tag')('')
    }
  }
  useEffect(() => {
    setInitialValues({
      ...initialValues,
      disk_layout_id:disks.find((disk:any) => disk.label === 'Automatic Partitioning').value
    })
  },[disks])
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  useEffect(() => {
    setDataToLs({key:'baremetal.configure', data:{...values, tags:tags}})
    // setConfigureData({...values, tags:tags})
  },[values, tags])
  const theme:any = useTheme()
  useEffect(() => {
    if(!pageLoading){
      const layout = disks.find((disk:any) => disk.value === values['disk_layout_id'])
      const server = serverList.find((disk:any) => disk.value === values['type'])
      if(server?.disksCount < layout?.min_disks){
        handleChange('disk_layout_id')('')
      }
    }
  },[values['type'], values['disk_layout_id']])
  if(pageLoading) return (
    <>
      <TopProgress loading={pageLoading}/>
      <ContentLoader backgroundColor={theme.palette[10]} viewBox="0 0 500 800" height={800} width={500}>
        <rect x="0" y="45" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="125" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="205" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="285" rx="4" ry="4" width="343" height="48" />
        {/* <rect x="0" y="335" rx="4" ry="4" width="543" height="308" /> */}
        <rect x="0" y="365" rx="4" ry="4" width="120" height="40" />
        <rect x="100" y="445" rx="4" ry="4" width="80" height="40" />
        <rect x="0" y="445" rx="4" ry="4" width="80" height="40" />
      </ContentLoader>
    </>
  )
  return (
    <BodyForm data-test-id='baremetal-configure-container'>
      <InputsWrapper>
        <Input
          required
          fieldName='name'
          title={t("NAME_OF_SERVER")}
          placeholder=''
          {...formikProps}
        />
      </InputsWrapper>
      <ServerSelector
        fieldName='type'
        errors={errors}
        servers={serverList}
        selectedValue={values['type']}
        onChange={(id:any) => handleChange('type')(id)}
      />
      <InputsWrapper>
        <Select
          required
          toolTip={false}
          data={osList}
          selectedValue={values['os']}
          onChange={(id:any) => handleChange('os')(id)}
          test_id={'baremetal-os-select'}
          title={t("OS")}
          fieldName='os'
          {...formikProps}
        />
        <Select
          required
          toolTip={false}
          data={disks}
          selectedValue={values['disk_layout_id']}
          onChange={(id:any) => handleChange('disk_layout_id')(id)}
          bootImage={serverList.find((b:any) => b.value === values['type'])}
          test_id={'baremetal-disk-layout-select'}
          title={t("DISK_LAYOUT")}
          fieldName='disk_layout_id'
          {...formikProps}
        />
        <PasswordWrapper>
          <Input
            required
            title={t('ROOT_PASSWORD_LABEL')}
            fieldName='root_password'
            placeholder={t('ROOT_PASSWORD_LABEL')}
            onFocus={() => setActiveConfirm(true)}
            onFocusOut={() => setActiveConfirm(false)}
            onBlur={handleBlur}
            ref={InputRef}
            isPassword
            {...formikProps}
          />
          <MatchPassword
            setEnabled={setActiveButton}
            validateString={values['root_password']}
            active={activeConfirm}
          />
        </PasswordWrapper>
        <MultiInput
          toolTip={true}
          toolTipText={`${t("INFO_CREATE_TAGS")}`}
          customStyles={{paddingLeft:'35px'}}
          placeholder={t("ADD_A_TAG")}
          fieldName='tag'
          tags={tags}
          icon={icons.IconTag}
          title={t("TAGS")}
          changeTags={changeTags}
          {...formikProps}
        />
      </InputsWrapper>

      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          // disabled={!activeButton}
          onClick={handleSubmit}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate('/bare-metal')}
          title={t("CANCEL")}
        />
      </div>
    </BodyForm>
  )
}

export default Configure
