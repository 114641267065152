import styled from 'styled-components'
import { BodyDefault } from "../../components/typography/styled";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height:100vh;

`
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:60px;
  max-width:437px;
`
export const GreyText = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
  margin:20px 0px;
`

export const GearImg = styled.img`
  position:absolute;
  z-index: 12;
  animation: 2s linear 0s normal none infinite running rot;
  @keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`
export const GearImg2 = styled.img`
  position:absolute;
  z-index: 12;
  animation: 2s linear 0s normal none infinite running rot2;
  @keyframes rot2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`
export const GearImg3 = styled.img`
  position:absolute;
  z-index: 12;
  animation: 2s linear 0s normal none infinite running rot3;
  @keyframes rot3 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
`


export const ShadowImg = styled.img`
  position:absolute;
  z-index:3;
  animation: 2s linear 0s normal none infinite running rot;
  @keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`
export const ShadowImg2 = styled.img`
  position:absolute;
  z-index:3;
  animation: 2s linear 0s normal none infinite running rot2;
  @keyframes rot2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`
export const ShadowImg3 = styled.img`
  position:absolute;
  z-index:3;
  animation: 2s linear 0s normal none infinite running rot3;
  @keyframes rot3 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
`
export const BackImg = styled.img`
  position:absolute;
  z-index:1;
  right:0px;
  top:0px;

`
export const BackImg2 = styled.img`
  position:absolute;
  z-index:1;
  top:18px;
  right:30px;
  height:380px;
  transform:rotate(-54deg);

`
export const BlueText = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
`
export const ImagesWrapper = styled.div`
  position:relative;
  background-repeat: no-repeat;
  width: 215px;
  height: 211px;
  background-color:#4671F6;
  transform: scaleY(0.7) rotate(54deg);
  background-position: center center;
  margin-top:40px;
  overflow: hidden;
`
export const LinkWrapper = styled.a`
  text-decoration: none;
  display: flex;
  gap:10px;
  margin-top: 20px;
  &:hover {
    cursor:pointer;
  }
`