import styled from 'styled-components'
import { BodySmallMedium } from '../../typography/styled'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 20px;
  box-sizing: border-box;
  width: 400px;
  /* height: 350px; */
  left: 540px;
  top: 305px;
  background: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`
export const BodyModal = styled.div`
  /* display:flex; */
  flex-direction: column;
  align-items: center;
  padding:0px 10px;
`
export const BodyWrapper = styled.div`
  display:flex;
  flex-direction: column;
  gap:20px;
`
export const Icon = styled.img`
  &:hover{
    cursor: pointer;
  }
`
export const WarningBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 8px;
  gap: 6px;
  box-sizing: border-box;
  width: 360px;
  /* height: 92px; */
  background-color: #FFF5D2;
  border-radius: 4px;
`
export const NotesContainer = styled.div`
  display:flex;
  flex-direction: column;
  gap: 20px;
`
export const NotesWrapper = styled.div`
  display: flex;
  /* word-wrap: break-word;
  word-break: break-all; */
`

export const NotesText = styled(BodySmallMedium)`
  margin-right: 4px;
  float: left;
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 14px;
  /* width: 360px;
  height: 94px; */
  background: ${({theme}) => theme.palette[200]};
  border-radius: 8px;
`

export const Divider = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.palette[20]}`};
  width:100%;
  margin-top:10px;
  margin-bottom:10px;
`

export const CheckBoxWrapper = styled.div`
  &:hover{
    cursor:pointer;
  }
`