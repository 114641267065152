const devConfig = {
  tinyToken:process.env.REACT_APP_TINY_TOKEN,
  supportPalToken: process.env.REACT_APP_SUPPORT_PAL_TOKEN,
  supportPalUrl: process.env.REACT_APP_SUPPORT_URL,
  apiUrl: localStorage.getItem('region_ocp_zone') === 'pl-1' ? process.env.REACT_APP_API_DEV_URL_SECOND : process.env.REACT_APP_API_DEV_URL, 
  keycloakUrl:process.env.REACT_APP_KEYCLOAK_DEV_URL,
  realmName: 'Onecloud',
  docs_url: localStorage.getItem('lang') === 'uk' ? process.env.REACT_APP_DOCS_URL_UA : process.env.REACT_APP_DOCS_URL
}

const prodConfig = {
  tinyToken:process.env.REACT_APP_TINY_TOKEN,
  supportPalToken: process.env.REACT_APP_SUPPORT_PAL_TOKEN,
  supportPalUrl: process.env.REACT_APP_SUPPORT_URL,
  apiUrl: localStorage.getItem('region_ocp_zone') === 'pl-1' ? process.env.REACT_APP_API_PROD_PL : process.env.REACT_APP_API_PROD_URL,
  keycloakUrl:process.env.REACT_APP_KEYCLOAK_PROD_URL, 
  realmName: 'onecloud',
  docs_url: localStorage.getItem('lang') === 'uk' ? process.env.REACT_APP_DOCS_URL_UA : process.env.REACT_APP_DOCS_URL
}

const rootConfig = process.env.NODE_ENV !== 'production' ? 
devConfig
  : 
    window.location.hostname === 'ocp-front-dev.netengi.com' ?
      devConfig
    :
      prodConfig

export default rootConfig