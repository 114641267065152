import React, { createContext, useEffect, useState, FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { instanceService, securityService } from '../api';
import { BodyDefault } from '../components/typography/styled';
import { handleOsIcons } from '../hooks/useOsIcons';
import {
  PropContainer,
  Prop,
} from '../pages/Instances/components/create-instance/configure/styles'
import { formatCurrency, formatCurrencyString } from '../utils/display-price';
import { useAuth } from './user.context';
import { useTranslation } from 'react-i18next';
export const InstanceContext = createContext<InstanceContextProps>({} as InstanceContextProps);

interface InstanceContextProps {
  instance?:any;
  pageLoading:boolean;
  setPageLoading:(type:boolean) => void;
  configureData:any;
  networkData:any;
  setConfigureData:(data:any) => void;
  setNetworkData:(data:any) => void;
  getCreateOptions:() => void;
  bootSource:any;
  networks:any;
  sshKeys:any;
  flavors:any;
  regions:any;
  storageType:any;
  groups:any;
  loadingRefresh:boolean;
  snapshotForRebuild?:any;
  setSnapshotForRebuild?:any;
  backupData:any;
  setBackupData:(data:any) => void;
  externalNics?:any[];
}

type ContextProps = {
  children?:any
}

const InstanceContextProvider: React.FC<ContextProps> = (props) => {
  const {instanceId} = useParams()
  const [instance, setInstance] = useState<any>(null)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [configureData, setConfigureData] = useState<any>()
  const [networkData, setNetworkData] = useState<any>()
  const [backupData, setBackupData] = useState<any>()
  const [groups, setGroups] = useState<any[]>([])
  const [snapshotForRebuild, setSnapshotForRebuild] = useState<any>()
  const [bootSource, setBootSource] = useState<any[]>([])
  const [networks, setNetworks] = useState<any[]>([])
  const [sshKeys, setSshKeys] = useState<any[]>([])
  const [flavors, setFlavors] = useState<any[]>([])
  const [regions, setRegions] = useState<any[]>([])
  const [externalNics, setExternalNics] = useState<any[]>([])
  const [storageType, setStorageType] = useState<any[]>([])
  const {user} = useAuth()
  const [t] = useTranslation()
  const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false)
  const location = useLocation()
  useEffect(() => {
    if(instanceId) {
      instanceService.getInstanceDetail(instanceId)
      .then((res) => {
        setInstance(res.data)
        setPageLoading(false)
      })
      .catch((err) => {
        console.log('error', err);
      })
    }
  },[instanceId])

  const getCreateOptions = () => {
    setLoadingRefresh(true)
    instanceService.getCreateOptions()
    .then((res) => {
      setBootSource([...res.data.bootSources.image.filter((im:any) => im.name.indexOf('kube') === -1).map((item:any) => {
        return {
          ...item,
          value: item.id,
          label: item.name,
          category:'public',
          icon: handleOsIcons(item.os_distro),
          bootSource:'image',
        }
      }), ...res.data.bootSources.owned_image.map((item:any) => {
        return {
          ...item,
          value: item.id,
          label: item.name,
          category:'client',
          icon: handleOsIcons(item.os_distro),
          bootSource:'owned_image',
        }
      })])
      setStorageType([{
        value: 'local',
        label: 'Local Storage'
      }, ...res.data.bootSources.volume_types.map((strg:any) => {
        return {
          value: strg.name,
          label: strg.description
        }
      })])
      setSshKeys(res.data.ssh_key.map((ssh:any) => {
        return {
          label: ssh.name,
          value: ssh.id.toString()
        }
      }))
      setNetworks([{
        value: 'create-network',
        label: `${t("CREATE_NEW_NETWORK_INSTANCE")}`
      }, ...res.data.nics.filter((net:any) => net.name !== 'external_network').map((item:any) => {
        return {
          value: item.id,
          label: item.name
        }
      })])
      setExternalNics(res.data.floating_nics.map((item:any) => {
        return {
          value: item.id,
          label: item.name
        }
      }))
      setRegions(res.data.region.map((item:any) => {
        return {
          value: item.id,
          label: item.id
        }
      }))
      let promises:any = []
      res.data.flavor.map((flavor:any) => {
        let queries = {
          flavorId: flavor.id,
          region:res.data.region?.[0]?.id,
          clientId: res.data.client.id,
          networks: [res.data.nics.find((net:any) => net.name === "external_network")?.id]
        }
        promises.push(instanceService.getPrice(queries))
      })
      securityService.getSecurityList({
        page: 1,
        ordering:'',
        pageSize:50
      })
      .then((res) => {
        setGroups(res.data.objects.map((group:any) => {
          if(group.name === 'fleio' || group.name === 'default') return null
          return{
            value: group.id,
            label: group.name
          }
        }).filter((i:any) => i !== null))
      })
      Promise.all(promises)
      .then((prices) => {
        setFlavors(res.data.flavor.map((item:any, index:any) => {
          let itemCustom = {
            ...item,
            estimateMonth:formatCurrencyString(prices[index].data.estimated_cost_display.split('-')[0]).month,
            estimateHour:formatCurrencyString(prices[index].data.estimated_cost_display.split('-')[1]).hour,
            estimate:`${formatCurrency(formatCurrencyString(prices[index].data.estimated_cost_display.split('-')[0]).month, user.user.clients?.[0]?.currency)}/h`
          }
          return {
            ...item,
            value: item.id,
            // label:  <ItemLabelType item={itemCustom}/>,
            customItem:itemCustom,
            type:"flavor"
          }
        }))

        setPageLoading(false)
        setLoadingRefresh(false)
      })

    })
    .catch((err) => {
      setPageLoading(false)
      setLoadingRefresh(false)
      console.log(err)
    })
  }
  return (
    <InstanceContext.Provider 
      value={{
        instance,
        pageLoading,
        setPageLoading,
        configureData,
        setConfigureData,
        getCreateOptions,
        bootSource,
        networks,
        sshKeys,
        flavors,
        regions,
        storageType,
        groups,
        setNetworkData,
        networkData,
        loadingRefresh,
        snapshotForRebuild,
        setSnapshotForRebuild,
        backupData,
        setBackupData,
        externalNics,
      }}
      {...props}
    />
  );
};

const useInstance = () => {
  const context = React.useContext(InstanceContext);
  if (context === undefined) {
    throw new Error('useInstance must be used within a InstanceProvider');
  }
  return context as InstanceContextProps;
};

export { InstanceContextProvider, useInstance };



