import styled from "styled-components";
import { BodyDefault, BodySmall, HeadLine1, HeadLine3, VerySmall } from "../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 50px 30px;
  position: relative;
`
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  /* margin-top: 60px; */
`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  margin-top: 20px;
`
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 15px;
  margin-top:20px;
  gap: 14px;
  background: ${({theme}) => theme.grey[100]};
  border-radius: 8px;
  min-width: 240px;
  max-width: 240px;
`

export const InfoText = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
`