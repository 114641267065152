import styled from "styled-components";
import { HeadLine1, HeadLine3, BodyDefault, VerySmall } from "../../../components/typography/styled";
type ActiveOverlay = {
  active: boolean
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  
`
export const CreateContainer = styled.div`
  justify-content: space-between;
  display: inline-flex;
  align-items: flex-start;
  padding: 10px 30px 50px 30px;
  position: relative;
  /* padding: 10px 30px 50px 30px; */
`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  margin-top: 20px;
  margin-bottom: 20px;
`
export const FormWrapper = styled.div`
  display:flex;
  width: 360px;
  flex-direction: column;
  gap:20px;
`

export const CreatedOverlay = styled.div<ActiveOverlay>`
  position:absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: ${({active}) => active ? 'flex' : 'none'};
  background: ${({active, theme}) => active ? theme.isDark ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)' : ''};
`
export const EstimateContainer = styled.div`
  box-sizing: border-box;
  padding: 15px;
  width: 240px;
  background: ${({theme}) => theme.palette[200]};
  border-radius: 8px;
  margin-top: 20px;
`

export const EstimateTitle = styled(HeadLine3)`
  color:${({theme}) => theme.palette[850]};
`
export const EstimateSum = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
`
export const PerHour = styled(VerySmall)`
  color:${({theme}) => theme.palette[650]};
  margin-left:2px;
  margin-bottom:2px;
`
