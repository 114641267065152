import React, {FC, useEffect, useState} from "react";
import {
  Container,
  BodyWrapper,
} from './styles'
import { TableSkeleton, TopProgress } from "../../../../components";
import { InfoContainer } from "../../../../components/info-block/styles";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";


const InvoiceSkeleton:FC<any> = ({pageLoading}) => {
  const theme:any = useTheme()
  return (
    <Container style={{gap:'10px'}}>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={135} height={25}
      />
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={235} height={50}
      />
      <BodyWrapper>
      <InfoContainer
        style={{
          display:'flex',
          flexDirection:'row',
          flex:'1 1',
          marginBottom:'0px',
          flexGrow:'1',
          flexBasis:'0',
          boxSizing:'border-box',
        }}>
          <div style={{
            display:"flex",
            flexDirection:'column',
            gap:'20px',
            flexGrow:'1',
            flexBasis:'0'
          }}>
            <Skeleton
              baseColor={theme.palette[300]}
              highlightColor={theme.palette[20]}
              width={95} style={{marginBottom:"5px"}} height={15}
            />
            {['1','2'].map((_) => (
              <Skeleton
                baseColor={theme.palette[300]}
                highlightColor={theme.palette[20]}
                width={155} style={{marginBottom:"5px"}} height={15}
              />
            ))}
          </div>
          <div style={{
            display:"flex",
            flexDirection:'column',
            gap:'20px',
            flexGrow:'1',
            flexBasis:'0'
          }}>
            {['1','2','3'].map((_, index:any) => index === 0 ? <div style={{minHeight:'20px'}}/> : (
              <Skeleton
                baseColor={theme.palette[300]}
                highlightColor={theme.palette[20]}
                width={195} style={{marginBottom:"5px"}} height={15}
              />
            ))}
          </div>
        </InfoContainer>
        <InfoContainer
          style={{
            display:'flex',
            flexDirection:'column',
            flex:'1 1',
            marginBottom:'0px',
            flexGrow:'1',
            flexBasis:'0',
        }}>
          <div style={{
            display:"flex",
            flexDirection:'column',
            gap:'20px',
            flexGrow:'1',
            flexBasis:'0'
          }}>
            <Skeleton
              baseColor={theme.palette[300]}
              highlightColor={theme.palette[20]}
              width={95} style={{marginBottom:"5px"}} height={15}
            />
            {['1','2'].map((_) => (
              <Skeleton
                baseColor={theme.palette[300]}
                highlightColor={theme.palette[20]}
                width={155} style={{marginBottom:"5px"}} height={15}
              />
            ))}
          </div>
        </InfoContainer>
      </BodyWrapper>
      <InfoContainer
        style={{
          display:'flex',
          flexDirection:'column',
          flex:'1 1',
          marginBottom:'0px',
          flexGrow:'1',
          flexBasis:'0',
          boxSizing:'border-box',
      }}>
        <Skeleton
          baseColor={theme.palette[300]}
          highlightColor={theme.palette[20]}
          width={95} style={{marginBottom:"5px"}} height={15}
        />
        <TableSkeleton
          rows={2}
          columns={4}
          // columnsData={Columns}
        />
      </InfoContainer>
      <InfoContainer
        style={{
          display:'flex',
          flexDirection:'column',
          flex:'1 1',
          marginBottom:'0px',
          flexGrow:'1',
          flexBasis:'0',
          boxSizing:'border-box',
      }}>
        <Skeleton
          baseColor={theme.palette[300]}
          highlightColor={theme.palette[20]}
          width={95} style={{marginBottom:"5px"}} height={15}
        />
        <TableSkeleton
          rows={5}
          columns={4}
          // columnsData={Columns}
        />
      </InfoContainer>
    </Container>
  )
}

export default InvoiceSkeleton