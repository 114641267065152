import React, { FC,useRef,useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  BodyWrapper,
} from './styles'
import {
  InfoBlock,
  BackArrow,
  Button,
  TopProgress,
  ModalVariables,
  InfoSkeleton
} from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import MenuButton from '../../../components/button/menu-button'
import { icons } from '../../../icons'
import DropOptions from '../../../components/header/components/drop-options'
import { PropsContainer } from '../../../components/table/styles'
import { ApiImage } from '../image-types'
import { authService, imageService } from '../../../api'
import { useUi } from '../../../context/ui.context'
import {formatFileSize} from '../../../utils/sizes'
import {timeConverterMMDDYYHHMM} from '../../../utils/dates'
import { handleOsIcons } from '../../../hooks/useOsIcons'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
// import { saveAs } from 'file-saver';
import Skeleton from 'react-loading-skeleton'
import config from '../../../config'
import { InstanceDot, InstanceHeadInfo, InstanceStatus } from '../../Instances/components/instance-page/styles'
import { handleStatusName } from '../../../utils/handle-status-name'


const ImagePage:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {imageId} = useParams()
  const {viewAlert} = useUi()
  const [options, setOptions] = useState<boolean>(false)
  const [image, setImage] = useState<ApiImage>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const {setDataToLs} = useLocalStorage()
  const dropsRef:any = useRef()
  const optionsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }
  const _updateImage = () => {
    if(imageId) {
      imageService.getImage(imageId)
      .then((res) => {
        setImage(res.data)
        setPageLoading(false)
      })
      .catch((err) => {
        viewAlert({
          severity: 'error',
          message: err?.response?.data?.detail || t("ERROR_OCCURRED"),
        })
        navigate('/images')
        setPageLoading(false)
      })
    }
  }
  useEffect(() => {
    _updateImage()
    const interval = setInterval(() => {
      _updateImage()
    },5000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const specifications = [
    {
      title:t("ID"),
      id:'1',
      value:image?.id
    },
    {
      title:t("IMAGE_SIZE"),
      id:'2',
      value:formatFileSize(image?.size)
    },
    {
      title:t("MINIMUM_MEMORY"),
      id:'3',
      value:`${image?.min_ram} MB`
    },
    {
      title:t("MINIMUM_DISK_SIZE"),
      id:'4',
      value:`${image?.min_disk} GB`
    },
    {
      title:t("CREATED_AT"),
      id:'5',
      value:timeConverterMMDDYYHHMM(image?.created_at)
    },
    {
      title:t("UPDATED_AT"),
      id:'6',
      value:timeConverterMMDDYYHHMM(image?.updated_at)
    },
    {
      title:t("CONTAINER_FORMAT"),
      id:'7',
      value:image?.container_format
    },
    {
      title:t("DISK_FORMAT"),
      id:'8',
      value:image?.disk_format
    },
    // {
    //   title:t("BOOTABLE"),
    //   id:'1',
    //   value:image?.'False'
    // },
  ]
  const architecture = [
    {
      title:t("OS_DISTRO"),
      id:'1',
      value:image?.os_distro,
      icon: handleOsIcons(image?.os_distro || '')
    },
    {
      title:t("HYPERVISOR_TYPE"),
      id:'2',
      value:image?.hypervisor_type || '-'
    },
    {
      title:t("OS_VERSION"),
      id:'3',
      value:image?.os_version || '-'
    },
    {
      title:t("VISABILITY"),
      id:'4',
      value:image?.visibility
    },
    // {
    //   title:t("GENERATED_FROM"),
    //   id:'1',
    //   value:image?.
    // },
  ]
  const items = [
    {
      title:t('LAUNCH'),
      id:'2',
      icon:icons.Launch,
      onClick: () => {
        setDataToLs({key:'instance.configure', data:{os:image?.id}})
        navigate('/instances/create?step=configure')
      },
      divider:false
    },
    {
      title:t('EDIT'),
      id:'3',
      icon:icons.Edit,
      onClick: () => {
        navigate(`edit`)
      },
      divider:false
    },
    {
      title:t("DOWNLOAD_IMAGE"),
      id:'5',
      icon:icons.Download,
      onClick: async () => {
        const res = await authService.generateOtpToken()
        const url = `${config.apiUrl}api/openstack/images/${image?.id}/download?fleio-token=${res.data.otp_token}`
        // window.open(url);
        window.location.href = url
      },
      divider:false
    },
    {
      title:t("DOUBLE_IMAGE"),
      id:'6',
      icon:icons.CopyProps,
      onClick: () => {
        openModal('copy-image')
        setAlertTitle(image?.name || image?.id)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        openModal('delete')
        setAlertTitle(t("IMAGE"))
        setAlertSubTitle([image])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  
  const closeModal = async (values:any) => {
    if(image){
      let res:any;
      try {
        setLoading(true);
        switch (modalType) {
          case 'copy-image':
            res = await imageService.copyImage(image.id, values)
            if (res?.data?.detail) {
              viewAlert({
                severity: 'info',
                message: res.data?.detail,
              });
            }
            break;
          case 'delete':
            await imageService.deleteImage(image.id);
            break; 
        }
        setViewModal(false)
        setActiveAnimation(false)
        if(modalType === 'delete'){
          navigate('/images')
        }
      } catch (e: any) {
        console.log('e',e);
        if (e?.response?.data.detail) {
          viewAlert({
            severity: 'error',
            message: e.response.data.detail,
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }
  if(pageLoading || !image) return (
  // if(true) return (
    <Container>
      <TopProgress loading={pageLoading}/>
      <BodyWrapper>
        <InfoSkeleton size={9}/>
        <InfoSkeleton size={4}/>
      </BodyWrapper>
    </Container>
  )
  return(
    <Container>
      <BackArrow onClick={() => navigate('/images')} title={t("BACK_TO_IMAGES")}/>
      <HeadWrapper>
        <div>
          <InstanceName>
            {image?.name}
          </InstanceName>
          <InstanceHeadInfo style={{margin:"0px"}}>
            <InstanceDot active={image.status}/>
            <InstanceStatus active={image.status}>
              {handleStatusName(image.status, t)}
            </InstanceStatus>
          </InstanceHeadInfo>
        </div>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              title={t("MENU")}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          title={t("IMAGE_SPECIFICATIONS")}
          customStyles={{ width:"545px", flex:'auto 1', marginRight:'10px'}}
        />
        <InfoBlock
          data={architecture}
          title={t("ARCHITECTURE")}
          customStyles={{ width:"545px", flex:'auto 1', marginRight:'10px'}}
        />
      </TopInfoWrapper>
      {/* @ts-ignore */}
      {Object.keys(image?.properties)?.length > 0 ? 
        <TopInfoWrapper>
          <InfoBlock
            dropped
            //@ts-ignore
            data={Object.keys(image?.properties).map((key) => ({
              title: key,
              //@ts-ignore
              value: image?.properties[key] || '-'
            }))}
            title={t("ADDITIONAL_PROPERTIES")}
            customStyles={{width:"545px", flex:'auto 1', marginRight:'10px'}}
          />
        </TopInfoWrapper>
      : null}
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={image}
      />
    </Container>
  )
}
export default ImagePage