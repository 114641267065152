import React, { createContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Alert } from '../components';
import { randomNameGenerator } from '../utils/create-random-name';
import { v4 as uuidv4 } from 'uuid';
import { useWindowSize } from '../hooks/useWindowSize';
export const UIContext = createContext<UIContextProps>({} as UIContextProps);


interface UIContextProps {
  viewAlert:(alert:AlertProps) => any;
  smallBar: boolean,
  setSmallBar: (is:boolean) => void;
  themeDark: boolean,
  setThemeDark: (is:boolean) => void;
  viewModalComplete:boolean;
  setViewModalComplete:(type:boolean) => void;
  activeAnimationComplete:boolean;
  setActiveAnimationComplete:(type:boolean) => void;
  updateAlertProgress:any;
}

export interface AlertProps {
  show?: boolean;
  severity: 'error' | 'information' | 'warning' | string;
  message?: string;
  deleteItem?:any;
  isDark?:boolean;
  timeout?:any;
  canClose?:boolean,
}
type ContextProps = {
  children?:any
}

const UIContextProvider: React.FC<ContextProps> = (props) => {
  const [width, height] = useWindowSize();
  const [smallBar, setSmallBar] = useState<boolean>(width < 1000)
  const [themeDark, setDark] = useState<boolean>(false)
  const alertsRef:any = useRef([])
  
  useEffect(() => {
    if(width < 1000){
      setSmallBar(true)
    } else {
      setSmallBar(false)
    }
  },[width])
  
  const [alerts, setAlerts] = useState<any[]>([])
  const [viewModalComplete, setViewModalComplete] = useState<boolean>(false)
  const [activeAnimationComplete, setActiveAnimationComplete] = useState<boolean>(false)
  useEffect(() => {
    alertsRef.current = alerts
  },[alerts])
  
  const viewAlert = (alert:AlertProps) => {
    let unic:any = uuidv4()
    setAlerts((prev) => {
      return [...prev, {
        severity:alert.severity,
        message:alert.message,
        show: alert.show,
        id: unic,
        timeout:alert.timeout,
        canClose:alert.canClose,
        progress:0
      }]
    })
    return unic
  }
  useLayoutEffect(() => {
    const localTheme = localStorage.getItem('theme') === "true"
    // @ts-ignore
    setDark(localTheme)
  },[])
  const setThemeDark = (status:boolean) => {
    setDark(status)
    // @ts-ignore
    localStorage.setItem('theme', status)
  }
  const deleteItem = (id:any) => {
    setAlerts(alertsRef.current.filter((alert:any) => alert.id !== id));
  }
  const updateAlertProgress = (uuid:any, progress:any) => {
    const alertIndex = alertsRef.current.findIndex((alert:any) => alert.id === uuid);
    if (alertIndex !== -1) {
      const updatedAlerts = [...alertsRef.current];
      updatedAlerts[alertIndex].progress = progress;
      setAlerts(updatedAlerts);

    }
  };
  const {
    children
  } = props
  return (
    <UIContext.Provider 
      value={{
        viewAlert,
        smallBar,
        setSmallBar,
        themeDark,
        setThemeDark,
        viewModalComplete,
        setViewModalComplete,
        activeAnimationComplete,
        setActiveAnimationComplete,
        updateAlertProgress,
      }}
    >
      {children}
      <div
        style={{
          bottom:'20px',
          right:'20px',
          position:'fixed',
          display:'flex',
          zIndex:99,
          flexDirection:'column',
          // alignItems: 'flex-end',
        }}
      >
        {alerts.map((item:any) => (
          <Alert
            key={item.id} 
            isDark={themeDark}
            severity={item.severity}
            message={item.message}
            progress={item.progress}
            timeout={item.timeout}
            canClose={item.canClose}
            show={item.show}
            deleteItem={() => deleteItem(item.id)}
          /> 
        ))}
      </div>
    </UIContext.Provider>
  );
};

const useUi = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context as UIContextProps;
};

export { UIContextProvider, useUi };