import styled from "styled-components";
import { BodyDefault, BodyMedium } from "../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 50px 20px;
`

export const PageBody = styled.div`
  display:flex;
  justify-content:space-between;
  width: 100%;
`

export const Form = styled.div`
  display:flex;
  flex-direction: column;
  gap:20px;
  padding-top:30px;
`

export const Gift = styled.div`
  background-size: 100%;
  background-repeat: no-repeat;
  width: 240px;
  height: 208px;
  box-sizing: border-box;
  padding:51px 15px 15px 15px;
`
export const AdditionalContainer = styled.div`
  background-color: ${({theme}) => theme.grey[100]};
  padding: 20px;
  gap: 20px;
  width: 404px;
  height: 304px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 10px;
`
export const GiftMoneyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 40px;
  background-color:#FFFF;
  border-radius: 8px;
  margin-bottom:22px;
`
export const Money = styled(BodyMedium)`
  color:#4671F6;
`

export const GiftDesc = styled(BodyDefault)`
  color:#FFFF;
`