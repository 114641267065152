import React, { FC } from 'react';
import {
  Container,
  BodyWrapper,
  TextWrapper,
  GreyText,
  BlueText,
  LinkWrapper,
  ImagesWrapper,
  GearImg,
  GearImg2,
  GearImg3,
  ShadowImg,
  ShadowImg2,
  ShadowImg3,
  BackImg,
  BackImg2,
} from './styles'
import Illustration2 from './rec1.svg'
import { useTranslation } from "react-i18next";
import { Typography } from "../../components";
import logoIc from './logo.svg'
import gear1Ic from './1.svg'
import gear2Ic from './2.svg'
import gear3Ic from './3.svg'
import shadow1Ic from './shadow1.svg'
import shadow2Ic from './shadow2.svg'
import shadow3Ic from './shadow3.svg'
import { icons } from '../../icons';
import { useTheme } from 'styled-components';

const TechBreak:FC<any> = () => {
  const [t] = useTranslation()
  const theme:any = useTheme()
  return(
    <Container>
      <img src={logoIc}/>
      <BodyWrapper>
        <ImagesWrapper >
          <BackImg2 src={Illustration2}/>
          <div style={{ position:"absolute", zIndex:"5",left:"-40px",top:"-35px",transform:'skew(40deg) rotate(30deg)'}}>
            <GearImg 
              style={{
                top:'130px',
                left:'68px'
              }}  
              src={gear1Ic}
            />
            <GearImg2 
              style={{
                top:'27px',
                left:'72px'
              }}  
              src={gear2Ic}
            />
            <GearImg3 
              style={{
                top:'65px',
                left:'125px'
              }}  
              src={gear3Ic}
            />
            <ShadowImg 
              style={{
                top:'138px',
                left:'58px'
              }}  
              src={shadow1Ic}
            />
            <ShadowImg2 
              style={{
                top:'38px',
                left:'65px'
              }}  
              src={shadow2Ic}
            />
            <ShadowImg3 
              style={{
                top:'75px',
                left:'118px'
              }}  
              src={shadow3Ic}
            />
          </div>
        </ImagesWrapper>
        <TextWrapper>
          <Typography variant='h1'>
            {t("TECH_BREAK_TITLE")}
          </Typography>
          <GreyText>
            {t("TECH_BREAK_SUBTITLE")}
          </GreyText>
          <LinkWrapper target='_blank' href='https://onecloudplanet.com/contacts'>
            <img src={theme.isDark ? icons.SupportGrey : icons.Support}/>
            <BlueText>
              {t("WRITE_TO_SUPPORT")}
            </BlueText>
          </LinkWrapper>
        </TextWrapper>
      </BodyWrapper>
    </Container>
  )
}

export default TechBreak