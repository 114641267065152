import styled from 'styled-components'
import { BodyMedium, BodySmall } from '../typography/styled'

type LabelProps = {
  reverse?:boolean
}

export const Container = styled.div`
  display:flex;
  align-items:center;
  /* margin:4px; */
`

export const ToolTipImg = styled.img<LabelProps>`
  margin-left:${({reverse}) => reverse ? '' : '3px'};
  margin-right:${({reverse}) => reverse ? '4px' : ''};
  background-color: ${({reverse, theme}) => reverse ? theme.palette[10] : ''};
  border-radius: ${({reverse}) => reverse ? '4px' : ''};
  box-shadow: ${({reverse}) => reverse ? '0px 1px 2px rgba(0, 0, 0, 0.1)' : ''};
  &:hover{
    cursor: pointer;
  }
`

export const LabelText = styled(BodyMedium)`
  color:${({theme}) => theme.palette[850]};
  /* word-break: break-word; */
`
export const SmallText = styled(BodySmall)`
  color:${({theme}) => theme.palette[800]};
`
export const LabelWrapper = styled.div<LabelProps>`
  display: flex;
  align-items: ${({reverse}) => reverse ? 'flex-start' : 'center'};
  /* justify-content: center; */
  flex-direction:${({reverse}) => reverse ? 'row-reverse' : 'row'};
  position: relative;
`

export const ToolTipView = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:flex-start;
  padding: 8px 10px;
  z-index: 13;
  position: absolute;
  width: auto;
  width: 250px;
  /* white-space: nowrap; */
  /* word-wrap: break-word; */
  left:25px;
  top: -4px;
  background: ${({theme}) => theme.palette[10]};
  color:${({theme}) => theme.palette[850]};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

export const Zirochka = styled.span`
  color:#658dfe!important;
`

export const DonutData = styled.div`
  display: flex;
  flex-direction: column;
  gap:5px;
  margin-bottom:10px;

`