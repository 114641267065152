import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, InfoBlock, ModalVariables, Tabs, TopProgress } from "../../../../components";
import DropOptions from "../../../../components/header/components/drop-options";
import { PropsContainer } from "../../../../components/table/styles";
import { icons } from "../../../../icons";
import {
  ContainerGlobal,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  InstanceHeadInfo,
} from './styles'
import { loadBalancerService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { handleStatusName } from "../../../../utils/handle-status-name";
import { GreenDot, Greentext, StateWrapper } from "../../../Instances/styles";
// import CreateLoadBalancer from "../create-page";
import {
  TopInfoWrapper,
  Container
} from '../members/styles'
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";


const MonitorPage:FC = () => {
  const navigate = useNavigate()
  const {poolId, monitorId, lbId} = useParams()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const [monitor, setMonitor] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)

  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [modalType, setModalType] = useState<any>('')
  const {viewAlert} = useUi()

  const updateMonitor = () => {
    // setPageLoading(true)
    if(monitorId && poolId){
      loadBalancerService.getMonitorById(monitorId, poolId)
      .then((res) => {
        setMonitor(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
        // navigate('/not-found')
      })
    }
  }
  useEffect(() => {
    updateMonitor()
    const interval = setInterval(() => {
      updateMonitor()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])

  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(!poolId) return;
    if(modalType === 'delete'){
      setLoading(true);
      loadBalancerService.deleteHealthMonitor(poolId, monitor.id)
      .then((res) => {
        updateMonitor()
        setViewModal(false)
        setActiveAnimation(false)
        navigate(`/load-balancer-pool/${lbId}/${poolId}/health-monitors`)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }

  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: () => navigate(`edit`),
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        setAlertTitle(t("HEALTH_MONITOR"))
        openModal('delete')
        setAlertSubTitle([monitor])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <ContainerGlobal>
      <BackArrow onClick={() => navigate(`/load-balancer-pool/${lbId}/${poolId}/health-monitors`)} title={t("GO_BACK")}/>
      <HeadWrapper>
        <InstanceName>
          {monitor?.name || monitor.id} 
        </InstanceName>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              active={options}
              title={`${t("MENU")}`}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <InstanceHeadInfo>
        <StateWrapper>
        <GreenDot active={monitor.operating_status}/>
          <Greentext active={monitor.operating_status}>
          {handleStatusName(monitor.operating_status, t)}
          </Greentext>
        </StateWrapper>
      </InstanceHeadInfo>
      <MonitorInfo monitor={monitor} />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={instance}
        instanceId={monitor.id}
        // instance={instance}
        loading={loading}
      />
    </ContainerGlobal>
  )
}

export default MonitorPage



const MonitorInfo:FC<any> = ({monitor}) => {
  const [t] = useTranslation()

  const monitorInfo = [
    {
      title:t("ID"),
      id:'1',
      value:monitor?.id || '-',
      copy:true,
    },
    {
      title:t("NAME"),
      id:'2',
      value:monitor?.name || 'n/a'
    },
    {
      title:t("OPERATING_STATUS"),
      id:'3',
      value: (
      <StateWrapper>
        <GreenDot active={monitor.operating_status}/>
        <Greentext active={monitor.operating_status}>
          {handleStatusName(monitor.operating_status, t)}
        </Greentext>
      </StateWrapper>) 
    },
    {
      title:t("PROVISIONING_STATUS"),
      id:'4',
      value: (
      <StateWrapper>
        <GreenDot active={monitor.provisioning_status}/>
        <Greentext active={monitor.provisioning_status}>
          {handleStatusName(monitor.provisioning_status, t)}
        </Greentext>
      </StateWrapper>)
    },
    {
      title:t("ADMIN_STATE"),
      id:'14',
      value:monitor?.admin_state_up ? 'Yes' : 'No'
    },
    {
      title:t("CREATED_AT"),
      id:'6',
      value: timeConverterMMDDYYHHMM(monitor?.created_at)
    },
    {
      title:t("UPDATED_AT"),
      id:'7',
      value:timeConverterMMDDYYHHMM(monitor?.updated_at)
    },
    {
      title:t("DELAY_SEC"),
      id:'8',
      value:monitor?.delay || '-',
    },
    {
      title:t("TIMEOUT_SEC"),
      id:'9',
      value:monitor?.timeout || '-',
    },
    {
      title:t("MAX_RETRIES"),
      id:'11',
      value:monitor?.max_retries || '-',
    },
    {
      title:t("MAX_RETRIES"),
      id:'16',
      value:monitor?.max_retries_down || '-',
    },
    {
      title:t("TYPE"),
      id:'10',
      value:monitor?.type || '-',
    },
    {
      title:t("HTTP_METHOD"),
      id:'12',
      value:monitor?.http_method || '-',
    },
    {
      title:t("URL_PATH"),
      id:'13',
      value:monitor?.url_path || '-',
    },
    {
      title:t("EXPECTED_CODES"),
      id:'14',
      value:monitor?.expected_codes || '-',
    },
  ]
  return (
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={monitorInfo}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1'}}
        />
      </TopInfoWrapper>
    </Container>
  )
}