import axios, { AxiosRequestConfig } from "axios";
import  secureLocalStorage  from  "react-secure-storage";
import config from "../config";
import keycloak from '../keycloak'
// import Config from "../Config";
const ApiClient = axios.create({
  baseURL: `${config.apiUrl}api/`,
  // baseURL: 'https://core.ocplanet.cloud/backend/api/',
  withCredentials: true,
  headers: {
    // 'Access-Control-Allow-Headers': "Cookie, Set-Cookie",
  }
});


ApiClient.interceptors.response.use(response => {
  // console.log('res200', response);
  return response;
}, error => {
  // console.log('reserror', error);
  if(error?.response?.status === 401 || error?.response?.status === 498){
    // if(localStorage.getItem('clientTokenRefresh')){
      keycloak?.updateToken(170)
    // } else {
    //   keycloak.logout()
    // }
    return Promise.reject('Update token now');
  } else {

    return Promise.reject(error);
  }

});

ApiClient.interceptors.request.use(
  config => {
    // if (config.headers.Authorization === undefined || config.headers.Authorization === 'Bearer undefined') {
    //   const token = getToken();
    //   if (token) {
    //     config.headers.Authorization = `Bearer ${token}`;
    //   }
    // }

    return config;
  },
  error => Promise.reject(error)
);
export const setClientToken = (token:any) => {
  if (token) {
    ApiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    // ApiClient.defaults.withCredentials = true
  }
};


export const resetClientToken = () => {
  delete ApiClient.defaults.headers.common.Authorization;
};

export default ApiClient