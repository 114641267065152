import React, { FC, useEffect, useState } from 'react'
import {Container} from './styles'
import { Button, TopProgress } from '../../../../../components'
import { useTranslation } from 'react-i18next'
import { instanceService } from '../../../../../api'
import { useUi } from '../../../../../context/ui.context'

type ConsoleProps = {
  instance:any
}
const Console:FC<ConsoleProps> = ({instance}) => {
  const [t] = useTranslation()
  const {
    viewAlert
  } = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [url, setUrl] = useState<string>('')
  const _getConsoleUrl = () => {
    setPageLoading(true)
    instanceService.getConsoleUrl(instance.id)
    .then((res) => {
      setUrl(res.data.remote_console.url)
      setPageLoading(false)
    })
    .catch((err) => {
      if(err?.response?.data?.detail){
        viewAlert({
          severity: 'error',
          message: err.response?.data?.detail,
        })
      } else {
        viewAlert({
          severity: 'error',
          message: `${t("ERROR_GET_CONDOLE_LINK")}`,
        })
      }
      setPageLoading(false)
    })
  }
  useEffect(() => {
    _getConsoleUrl()
  },[])
  return(
    <Container>
      <Button
        variant='stroke'
        size='display'
        onClick={_getConsoleUrl}
        arrow
        title={t("REFRESH_SESSION")}
        isFill
      />
      {pageLoading ? <TopProgress loading={pageLoading}/> 
      :
        <iframe width={'100%'} height={'800px'}
        src={url}>

        </iframe>
      }
    </Container>
  )
}

export default Console