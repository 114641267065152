import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider,
  NetName
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import Select from "../../select";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import { BodyDefault, HeadLine3 } from "../../typography/styled";
import Toggle from "../../toggle";
import { useRouterCustom } from "../../../context/routerContext";
import TopProgress from "../../TopBarProgress";
import { routerService } from "../../../api";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(props:any) => void;
  loading?:any;
  dataToEdit?:any;
  viewModal?:boolean;
}  

const CreateRouter:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    loading,
    viewModal,
    dataToEdit
  } = props
  const [t] = useTranslation()
  const [internetAcc, setinternetAcc] = useState<boolean>(true)
  const [adminState, setAdminState] = useState<boolean>(true)
  const [regions, setRegions] = useState<any[]>([])
  const [subnets, setSubnets] = useState<any[]>([])
  const [selectedSubnets, setSelectedSubnets] = useState<any[]>([])
  const [loadingOptions, setLoadingOptions] = useState<boolean>(true)

  const editChecked = (sub:any) => {
    const founded = selectedSubnets.find((item:any) => item.value === sub?.value)
    if(founded) {
      setSelectedSubnets((prev:any) => {
        return prev.filter((item:any) => item.value !== sub?.value)
      })
    } else {
      setSelectedSubnets((prev) => [...prev, sub])
    }
  } 
  const [initialValues, setInitialValues] = useState<any>({
    name:'',
    // address:'',
    region:regions[0]?.value,
  })
  
  const {
    routerOptions,
    interfaceOptions,
    routers,
    setRouters,
  } = useRouterCustom()
  const getOptions = async () => {
    setSelectedSubnets([])
    setLoadingOptions(true)
    let promises:any[] = []
    routers.forEach((item:any) => {
      let promise = routerService.getSingleRouter(item.id)
      promises.push(promise)
    })
    Promise.all(promises)
    .then((res) => {
      let interfaces:any[] = []
      const data = res.map((it:any) => it.data)
      // if(interfaces)
      interfaces = data.flatMap((obj) => {
        const externalFixedIps = obj.external_fixed_ips || [];
        const interfaceFixedIps = obj.interfaces.flatMap((interfaceObj:any) => interfaceObj.fixed_ips || []);
        return externalFixedIps.concat(interfaceFixedIps);
      }).map(ipObj => ipObj.subnet_id);
      //@ts-ignore
      const ocupado = [...new Set(interfaces)]
      setRegions(routerOptions.regions.map((reg:any) => {
        return {
          label:reg.id,
          value:reg.id
        }
      }))
      handleChange('region')(routerOptions.regions[0]?.id)
      setSubnets(interfaceOptions.map((sub:any) => {
        return {
          ...sub,
          ocupado:ocupado.find((item) => item === sub.id),
          add_label:(
            <div style={{display:'flex', alignItems:'center', gap:'4px'}}>
              {ocupado.find((item) => item === sub.id) ? 
                <NetName>
                  {sub.name}
                </NetName> 
              :
                <Typography variant='body'>
                  {sub.name}
                </Typography>
              }
              <NetName>
                {sub.network_name}
              </NetName>
            </div>
            ),
          label:sub.name,
          value:sub.id,
        }
      }))
      if(dataToEdit){
        let keks = interfaceOptions.map((sub:any) => {
          return {
            ...sub,
            ocupado:ocupado.find((item) => item === sub.id),
            add_label:(
              <div style={{display:'flex', alignItems:'center', gap:'4px'}}>
                {ocupado.find((item) => item === sub.id) ? 
                  <NetName>
                    {sub.name}
                  </NetName> 
                :
                  <Typography variant='body'>
                    {sub.name}
                  </Typography>
                }
                <NetName>
                  {sub.network_name}
                </NetName>
              </div>
              ),
            label:sub.name,
            value:sub.id,
          }
        })
        routerService.getSingleRouter(dataToEdit.id)
        .then((res) => {
          res.data.interfaces.forEach((pidor:any) => {
            let finded:any = keks.find((f:any) => f.id === pidor?.fixed_ips?.[0].subnet_id)
            if(finded){
              editChecked(finded)
            }
          })
          // keks.forEach((int:any) => {
          //   if(res.data.interfaces.find((ip:any) => ip?.fixed_ips?.[0].subnet_id === int.id)){
          //     editChecked(int)
          //   }
          // })
          setLoadingOptions(false)
        })
        .catch((err:any) => {
          console.log('err - ', err);
          setLoadingOptions(false)
        })
      } else {
        setLoadingOptions(false)

      }
    })
  }

  useEffect(() => {
    // if(viewModal){
      getOptions()
    // }
  },[dataToEdit])
  const onSubmit = (values:any) => {
    const data:any = {
      admin_state_up:adminState,
      description:'',
      external_network_id:internetAcc ? routerOptions?.external_networks?.find((net:any) => net.name === 'external_network')?.id : "",
      name:values.name,
      region:values.region || regions[0]?.value,
    }
    if(dataToEdit) {
      data.id = dataToEdit.id
    }
    closeModal({
      setErrors,
      data:data,
      subnets:selectedSubnets,
      handleClose:() => {
        resetForm()
        setSelectedSubnets([])
      }
    })
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    resetForm,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
      .required(`${t("REQUIRED_FIELD")}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      // address:yup.string().required(),
      region:yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  useEffect(() => {
    if(dataToEdit?.id){
      setInitialValues({
        name:dataToEdit.name,
        region:dataToEdit.region,
      })
      setinternetAcc(dataToEdit.external_network_id ? true : false)
      setAdminState(dataToEdit.admin_state_up)
      // setSelectedSubnets()
    }
  },[dataToEdit])
  if(loadingOptions) return <TopProgress loading={loadingOptions}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {dataToEdit?.id ? t("EDIT_ROUTER") : t("CREATE_NEW_ROUTER")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Input
          required
          title={t('NAME_ROUTER')}
          fieldName='name'
          placeholder={''}
          onBlur={handleBlur}
          {...formikProps}
        />
        {/* <Select
          toolTip={false}
          data={regions}
          selectedValue={values['region'] || regions[0]?.value}
          onChange={(e) => handleChange('region')(e)}
          title={t("PLACEMENT_REGION")}
          customStyles={{width:'360px'}}
        /> */}
        {dataToEdit?.id ? null :
          <Toggle
            title={t("INTERNET_ACCESS")}
            value={internetAcc}
            onChange={() => setinternetAcc(!internetAcc)}
          />
        }
        {dataToEdit?.id ? null :
          <Select
            toolTip={false}
            data={subnets}
            selectedValue={selectedSubnets}
            multipleOnChange={editChecked}
            multi
            onChange={(e) => {handleChange('subnets')(e)}}
            title={t("SUBNETS")}
            customStyles={{width:'360px'}}
          />
        }
        <Divider/>
        <Toggle
          title={t("ADMIN_STATE_UP")}
          value={adminState}
          // customStyles={{marginTop:''}}
          onChange={() => setAdminState(!adminState)}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'20px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={dataToEdit ? t("UPDATE") : t("CREATE")}
            loading={loading}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default CreateRouter