import React, { FC, useEffect, useState } from "react";
import {Container, FormWrapper} from './styles'
import { Button, Input, Select, Toggle, TopProgress } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { instanceService, loadBalancerService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { Title } from "../../../Instances/styles";




const CreatePolicy:FC<any> = ({isEdit}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {lbId, listenerId, policyId} = useParams()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [admin, setAdmin] = useState<boolean>(true)
  const {viewAlert} = useUi()
  const [actions, setActions] = useState<any[]>([])
  const [pools, setPools] = useState<any[]>([])
  const [policy, setPolicy] = useState<any>()
  const [initialValues, setInitialValues ] = useState<any>({
    action:"",
    description:"",
    name:"",
  })

  const getCreateOptions = async () => {
      // setPageLoading(true)
    if(!lbId) return;
    try{
      const createRes = await loadBalancerService.getPoliciesCreateOptions()
      await setActions(
        Object.keys(createRes?.data?.policy_actions).map((action) => {
          return {
            value:action,
            label:createRes?.data?.policy_actions[action],
          }
        })
        
      )
      const poolsRes = await loadBalancerService.getPools(lbId)
      await setPools(poolsRes.data.objects.map((pool:any) => {
        return {
          ...pool,
          value:pool.id,
          label:`${pool.name}(${pool.id})`,
        }
      }))
      if(policyId && listenerId){
        const policyRes:any = await loadBalancerService.getPolicyById(policyId, listenerId)
        await setPolicy(policyRes?.data)
        await setInitialValues({
          name:policyRes?.data?.name||'',
          action:policyRes?.data?.action||'',
          description:policyRes?.data?.description||'',
          position:policyRes?.data?.position||'',
          redirect_url:policyRes?.data?.redirect_url||'',
          redirect_pool_id:policyRes?.data?.redirect_pool_id||'',
        })
        await setAdmin(policyRes?.data?.admin_state_up)
      }
    } catch (err:any) {
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    } finally {
      setPageLoading(false)
    }
  }
  useEffect(() => {
    getCreateOptions()
  },[])



  const onSubmit = (values:any) => {
    if(!listenerId) return;
    setLoading(true)
    const data:any = {
      ...values,
      listener_id:listenerId,
      admin_state_up:admin,
    }
    loadBalancerService.createPolicy(data)
    .then((res) => {
      setLoadingComplete(true)
        setTimeout(() => {
          navigate(`/load-balancer-listener/${lbId}/${listenerId}/policies`)
          viewAlert({
            severity:'info',
            message:isEdit ? `${t("POLICY_EDITED_SUCCESS")}` : `${t("POLICY_CREATED_SUCCESS")}`,
          })
        }, 1500)
    })
    .catch((err) => {
      setErrors(err?.response?.data)
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
      setLoading(false)
    })
  }

  const onEdit = (values:any) => {
    if(!listenerId) return;
    setLoading(true)
    const data:any = {
      ...policy,
      ...values,
      admin_state_up:admin,
    }
    loadBalancerService.editPolicy(data)
    .then((res) => {
      setLoadingComplete(true)
        setTimeout(() => {
          navigate(`/load-balancer-listener/${lbId}/${listenerId}/policies`)
          viewAlert({
            severity:'info',
            message:`${t("POLICY_EDIT_SUCCESS")}`,
          })
        }, 1500)
    })
    .catch((err) => {
      setErrors(err?.response?.data)
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
      setLoading(false)
    })
  }

  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t('REQUIRED_FIELD')}`).matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      action: yup.string().required(`${t('REQUIRED_FIELD')}`),
      description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
      position: yup.string(),
      redirect_url:  yup.string().when("action", {
        is: (action:any) => action === 'REDIRECT_TO_URL',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`).max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
      }),
      redirect_pool_id:  yup.string().when("action", {
        is: (action:any) => action === 'REDIRECT_TO_POOL',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`).max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
      }),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit: isEdit ? onEdit : onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      <Title>{isEdit ? `${t("EDIT_FOR")} «${policy?.name || policy?.id}»` : t("CREATE_POLICY")}</Title>
      <FormWrapper>
        <Input
          fieldName={`name`}
          toolTip={false}
          required
          title={t("NAME")}
          placeholder=''
          {...formikProps}
        />
        <Input
          isTextArea
          toolTip={false}
          fieldName='description'
          title={t("DESCRIPTION")}
          placeholder=''
          customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
          {...formikProps}

        />
        <Select
          toolTip={false}
          required
          data={actions}
          selectedValue={values['action']}
          // onChange={() => {}}
          title={t("ACTION")}
          fieldName={`action`}
          onChange={(e:any) => handleChange('action')(e)}
          {...formikProps}
        />
        {values['action'] === 'REDIRECT_TO_POOL' && 
          <Select
            toolTip={false}
            required
            data={pools}
            selectedValue={values['redirect_pool_id']}
            // onChange={() => {}}
            title={t("REDIRECT_POOL_ID")}
            fieldName={`redirect_pool_id`}
            onChange={(e:any) => handleChange('redirect_pool_id')(e)}
          />
        }
        {values['action'] === 'REDIRECT_TO_URL' && 
          <Input
            fieldName={`redirect_url`}
            toolTip={false}
            required
            title={t("REDIRECT_URL")}
            placeholder=''
            {...formikProps}
          />
        }
        <Input
          fieldName={`position`}
          toolTip={false}
          title={t("POSITION")}
          type="number"
          placeholder=''
          {...formikProps}
        />
        <Toggle value={admin} onChange={() => setAdmin(!admin)} title={t("ADMIN_STATE_UP")}/>
      </FormWrapper>
      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          loading={loading}
          loadingComplete={loadingComplete}
          title={isEdit ? t("SAVE") : t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate(-1)}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default CreatePolicy