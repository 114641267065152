import React, {FC, useEffect, useState} from "react";
import {
  WrapperRow,
  Title,
  HideWrapper,
  BlueText,
  LeftSide,
  BodyText,
  BodyLink,
  QuotasContainer
} from '../../pages/Instances/styles'
import {
  Container,
  TutorItem,
  TutorTitle,
  TutorTitleWrapper,
  TutorContent,
  TutorIcon,
  TutorIconBack
} from './styles'
import { Diagrams } from "../../pages/HomePage/components";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import StyledIcon from "../../components/styled-icon";
import { Button, Table, TopProgress, TutorialVodeo, TableSkeleton } from "../../components";
import { useTheme } from "styled-components";
import Skeleton from "react-loading-skeleton";

type StartPageProps = {
  title:string,
  noResourceText?:string,
  createResourceButton:string,
  createResourceButtonEventOrLink:() => void | string,
  rightButtonComponentCustom?:any,
  rightButtonComponentCustomWithResources?:any,
  learnMoreAboutResource?:string,
  learnMoreAboutResourceLink?:string,
  howCreateVideoText:string,
  howCreateVideoUrl?:string,
  videoImage:any,
  videoImageDark:any,
  tableData:Table,
  haveResource:boolean,
  setHaveResource?:(type:boolean) => void,
  hideQuotas?:boolean,
  pageLoading?:boolean,
  updateData?:any,
  withoutCreateResourceButton?:boolean,
  rightCustomCreateButton?:any,
  tutorialLeftData?:any[],
  tutorialRightData?:any[],
}
type Table = {
  columns:any[],
  data:any[],
  props?:any[],
  allChecked?:any,
  setAllChecked?:any,
  checked?:any,
  setChecked?:any,
  paginationData?:any,
  updateData?:any,
  loading?:boolean,
}

const StartPage:FC<StartPageProps> = (props) => {
  // const [haveResource, setResource] = useState<boolean>(true)
  const [showQuotas, setShowQuotas] = useState<boolean>(true)
  const {
    title,
    noResourceText,
    createResourceButton,
    createResourceButtonEventOrLink,
    rightButtonComponentCustom,
    rightButtonComponentCustomWithResources,
    learnMoreAboutResource,
    learnMoreAboutResourceLink,
    howCreateVideoText,
    videoImage,
    videoImageDark,
    tableData,
    haveResource,
    hideQuotas,
    pageLoading,
    withoutCreateResourceButton,
    howCreateVideoUrl,
    rightCustomCreateButton,
    tutorialLeftData,
    tutorialRightData,
  } = props
  const [t] = useTranslation()
  const theme:any = useTheme()

  return (
    <Container>
      <WrapperRow>
        <Title>
          {title}
        </Title>
        {hideQuotas ? null :
          <HideWrapper onClick={() => setShowQuotas(!showQuotas)}>
            <StyledIcon src={icons.HideEyeBlue}/>
            <BlueText>
              {showQuotas ? t('HIDE_QUOTAS') : t('SHOW_QUOTAS')}
            </BlueText>
          </HideWrapper>
        }
      </WrapperRow>
      {hideQuotas ? null :
        <QuotasContainer active={showQuotas}>
          <Diagrams/>
        </QuotasContainer>
      }
      {pageLoading ?
        <WrapperRow style={{flexDirection:'column', marginTop:'18px'}}>
          <TopProgress loading={pageLoading}/>
          <div style={{display:'flex', gap:'6px', marginBottom:'20px'}}>
            <Skeleton
              baseColor={theme.palette[300]}
              highlightColor={theme.palette[20]}
              width={144} height={40}
            />
            <Skeleton
              baseColor={theme.palette[300]}
              highlightColor={theme.palette[20]}
              width={40} height={40}
            />

          </div>
          <TableSkeleton
            rows={5}
            columns={tableData?.columns?.length || 5}
            columnsData={tableData.columns}
          />
        </WrapperRow>
      : haveResource ?
        <WrapperRow style={{flexDirection:'column'}}>
          {withoutCreateResourceButton ? null : 
            <div style={{display:"flex", width:'100%', alignItems:'center'}}>
              <Button
                title={createResourceButton}
                onClick={createResourceButtonEventOrLink}
                variant='primary'
                size='custom'
                customStyles={{height:'40px', margin:'20px 6px 20px 0px', whiteSpace:'nowrap'}}
              />
            
              {rightButtonComponentCustomWithResources}
            </div>
          }
          <Table
            columns={tableData.columns}
            rows={tableData.data}
            settings={tableData.props}
            allChecked={tableData?.allChecked}
            setAllChecked={tableData?.setAllChecked}
            checked={tableData?.checked}
            setChecked={tableData?.setChecked}
            paginationData={tableData?.paginationData}
            updateData={tableData?.updateData}
            loading={tableData?.loading}
          />
        </WrapperRow>
      :
        <>
          <WrapperRow style={{marginTop:'20px', gap:'20px'}}>
            <LeftSide style={{width:'100%'}}>
              {/* @ts-ignore */}
              <BodyText>
                {noResourceText}
              </BodyText>
              {withoutCreateResourceButton ? rightCustomCreateButton ? rightCustomCreateButton : null : 
                <div style={{display:"flex", alignItems:'center'}}>
                  <Button
                    title={createResourceButton}
                    onClick={createResourceButtonEventOrLink}
                    variant='primary'
                    size='display'
                    customStyles={{ margin:'20px 0px'}}
                  />
                  {rightButtonComponentCustom ? rightButtonComponentCustom :
                  null}
                </div>
              }
              {learnMoreAboutResource && 
                <HideWrapper onClick={() => window.open(learnMoreAboutResourceLink)}>
                  <StyledIcon src={icons.LinkBlue}/>
                  <BodyLink>{learnMoreAboutResource}</BodyLink>
                </HideWrapper>
              }
            </LeftSide>
            <TutorialVodeo
              title={t('VIDEO_INSTRUCTION')}
              subtitle={howCreateVideoText}
              img={theme?.isDark ? videoImageDark : videoImage}
              url={howCreateVideoUrl}
            />
          </WrapperRow>
          {tutorialLeftData || tutorialRightData ?
            <div style={{display:'flex', width:"100%", gap:'10px', marginTop:'20px'}}>
              <div style={{display:'flex', flexWrap:"wrap", gap:'10px'}}>
                {tutorialLeftData?.map((tutorItem) => (
                  <TutorItemView item={tutorItem} />
                ))}
              </div>
              <div style={{display:'flex', marginRight:'10px'}}>
                {tutorialRightData?.map((tutorItem) => (
                  <TutorItemView item={tutorItem} />
                ))}
              </div>
            </div>
          : null}
        </>
      }
    </Container>
  )
}


export default StartPage



const TutorItemView:FC<any> = ({item}) => {
  return(
    <TutorItem isTutorial={item?.isTutorial} key={item.id}>
      {item?.iconFirst && <TutorIcon src={item?.iconFirst}/>}
      <TutorTitleWrapper>
        <TutorTitle>
          {item.title}
        </TutorTitle>
        <TutorContent style={item?.icon ? {width:'60%'} : {}}>
          {item.content}
        </TutorContent>
      </TutorTitleWrapper>
      {item?.icon && <TutorIconBack src={item?.icon}/>}
    </TutorItem>
  )
}
