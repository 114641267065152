import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  BodyWrapper,
  WarningBlock,
  NotesContainer,
  NotesWrapper,
  NotesText,
  SelectWrapper,
  Divider,
  CheckBoxWrapper,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
  WarningText
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { BodyDefault, BodySmall, HeadLine3 } from "../../typography/styled";
import Select from "../../select";
import CheckBox from "../../checkbox";
import { useInstance } from "../../../context/instanceContext";
import TopProgress from "../../TopBarProgress";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { instanceService, volumeService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import Input from "../../input";
import LoaderSpinner from "../../spinner";
import { useTheme } from "styled-components";
import { formatCurrencyString, formatCurrency } from "../../../utils/display-price";
import { useAuth } from "../../../context/user.context";
import Counter from "../../counter";
            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any, handleClose:any) => void;
  alertTitle?:any;
  alertSubTitle?:any;
  loading?:boolean;
  loadingComplete?:boolean;
  instance?:any;
  instanceId?:any;
}  

const CreateInstanceBackup:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    loadingComplete,
    instanceId,
    loading,
  } = props
  const theme:any = useTheme()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    name:'',
    rotation:1,
  })
  const {user} = useAuth()
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()
  const onSubmit = (values:any) => {
    const data = {
      backup_name:values.name,
      backup_type:'manual',
      rotation:values.rotation,
    }
    closeModal(data, () => {
      resetForm()
    })
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    resetForm,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required(`${t("REQUIRED_FIELD")}`)
        .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
        .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      rotation: yup.string().required(`${t("REQUIRED_FIELD")}`).matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  const getCurrentPrice = () => {

    setPriceLoading(true)
    instanceService.getPriceInstanceBackup(instanceId)
    .then((res) => {
      setPrice(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
      setPriceMonth(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
      setPriceLoading(false)
    })
  }
  
  useEffect(() => {
    getCurrentPrice()
  },[])
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("CREATE_BACKUP_FOR_INSTANCE")} «{alertTitle}»
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <BodyWrapper>
          <WarningBlock>
            <Icon src={icons.Warning}/>
            <WarningText>
              {t("BACKUP_INSTANCE_CREATE_WARNING")}
            </WarningText>
          </WarningBlock>
          <Input
            required
            title={t('NAME')}
            fieldName='name'
            placeholder={''}
            onBlur={handleBlur}
            {...formikProps}
          />
          <Counter
            title={t('ROTATION')}
            toolTip={true}
            tooltipText={`${t("ROTATION_INFO")}`}
            value={values['rotation']}
            minCount={1}
            maxCount={200}
            onChange={(v:any) => {
              handleChange('rotation')(v.toString())
            }}
            fieldName='rotation'
            {...formikProps}
          />
          <EstimateContainer>
            <EstimateTitle>
              {t("ESTIMATED_COST")}
            </EstimateTitle>
            {priceLoading ? 
              <LoaderSpinner customStyles={{color:theme.base.black}}/>
            :
              price ? 
                <div style={{display:'flex', gap:'10px',  alignItems:'flex-start'}}>
                  <div style={{display:'flex', alignItems:'flex-end'}}>
                    <EstimateSum>
                      {`${formatCurrency(price, user.user.clients?.[0]?.currency || 'UAH')}`}
                    </EstimateSum>
                    <PerHour>{t("HOURS")}</PerHour>
                  </div>
                  <div style={{display:'flex', alignItems:'flex-end'}}>
                    <EstimateSum>
                      {`${formatCurrency(priceMonth, user.user.clients?.[0]?.currency || 'UAH')}`}
                    </EstimateSum>
                    <PerHour>{t("MONTH")}</PerHour>
                  </div>
                </div>
              :
                null
              // <PerHour>
              //   To display the price, select a network
              // </PerHour>
            }
          </EstimateContainer>
        </BodyWrapper>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("CREATE_BACKUP")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default CreateInstanceBackup