import React, { createContext, useEffect, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { instanceService, loadBalancerService } from '../api';
import { useUi } from './ui.context';
import { LoadDetailsType } from '../types/load-balancer';
import { Button } from '../components';
import { useLocalStorage } from '../hooks/useLocalStorage';


export const BalancerContext = createContext<BalancerContextProps>({} as BalancerContextProps);

interface BalancerContextProps {
  subnets:any[],
  flavors:any[],
  zones:any[],
  algo:any[],
  listenersProtocols:any[],
  protocols:any[],
  sessionPersistance:any[],
  monitorTypes:any[],
  httpMethods:any[],
  setLoadDetails:(data:LoadDetailsType|null) => void
  setListenerDetails:(data:any|null) => void
  setPoolDetails:(data:any|null) => void
  pageLoading:boolean,
  setPageLoading:(state:boolean) => void
  setPoolRemembersData:(data:any|null) => void
  poolRemembers:any,
  monitorDetails:any,
  loadDetails:any,
  listenerDetails:any,
  poolDetails:any,
  setMonitorDetails:(data:any|null) => void,
  instances:any,
  setInstances:(data:any|null) => void,
  getCreateOptions:() => void,
}

type ContextProps = {
  children?:any
}

const BalancerContextProvider: React.FC<ContextProps> = (props) => {
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {getDataToLs} = useLocalStorage()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  //saved state
  const [loadDetails, setLoadDetails] = useState<LoadDetailsType|null>(null)
  const [listenerDetails, setListenerDetails] = useState<any|null>(null)
  const [poolDetails, setPoolDetails] = useState<any|null>(null)
  const [poolRemembers, setPoolRemembersData] = useState<any|null>(null)
  const [monitorDetails, setMonitorDetails] = useState<any|null>(null)

  // console.log('loadDetails', loadDetails);

  //create options
  const [subnets, setSubnets] = useState<any[]>([])
  const [flavors, setFlavors] = useState<any[]>([])
  const [zones, setZones] = useState<any[]>([])
  const [protocols, setProtocols] = useState<any[]>([])
  const [listenersProtocols, setListenersProtocols] = useState<any[]>([])
  const [algo, setAlgo] = useState<any[]>([])
  const [sessionPersistance, setSessionPersistance] = useState<any[]>([])
  const [monitorTypes, setMonitorTypes] = useState<any[]>([])
  const [httpMethods, setHttpMethods] = useState<any[]>([])
  const [instances, setInstances] = useState<any[]>([])
  const getCreateOptions = () => {
    setPageLoading(true)
    loadBalancerService.getCreateOptions()
    .then((res) => {
      setProtocols(
        Object.keys(res.data.protocols).map((protocol) => {
          return {
            value:protocol,
            label:protocol,
          }
        })
      )
      setListenersProtocols(
        res.data.listener_protocols.map((protocol:any) => {
          return {
            value:protocol,
            label:protocol,
          }
        })
      )
      setSessionPersistance(
        Object.keys(res.data.session_persistence_types).map((type) => {
          return {
            value:type,
            label:res.data.session_persistence_types[type],
          }
        })
      )
      setMonitorTypes(
        Object.keys(res.data.monitor_types).map((type) => {
          return {
            value:type,
            label:res.data.monitor_types[type],
          }
        })
      )
      setHttpMethods(
        Object.keys(res.data.monitor_http_methods).map((method) => {
          return {
            value:method,
            label:res.data.monitor_http_methods[method],
          }
        })
      )
      setAlgo(res.data.pool_supported_algorithms.map((algo:any) => {
        return {
          label: res.data.pool_algorithms[algo],
          value: algo,
        }
      }))
      setSubnets(res.data.subnets.map((subnet:any) => {
        return {
          ...subnet,
          value:subnet.id,
          label:subnet.name,
        }
      }))
      setFlavors(res.data.flavors)
      setZones(res.data.availability_zones)
      setPageLoading(false)
    })
    .catch((err:any) => {
      if(err.response.data.detail){
        viewAlert({
          severity:'error',
          message:err.response.data.detail
        })
      } else {
        viewAlert({
          severity:'error',
          message:`${t("ERROR_OCCURRED")}`
        })
      }
      // navigate(-1)
      setPageLoading(false)
    })
  }
  

  useEffect(() => {
    getCreateOptions()
    setLoadDetails(getDataToLs({key:'balancer.balancer_details'}))
    setListenerDetails(getDataToLs({key:'balancer.listener_details'}))
    setPoolDetails(getDataToLs({key:'balancer.pool_details'}))
    setPoolRemembersData(getDataToLs({key:'balancer.pool_remembers'}))
    setMonitorDetails(getDataToLs({key:'balancer.monitor_details'}))
  },[])
  return (
    <BalancerContext.Provider 
      value={{
        subnets,
        flavors,
        zones,
        algo,
        protocols,
        sessionPersistance,
        monitorTypes,
        httpMethods,
        setLoadDetails,
        listenersProtocols,
        setListenerDetails,
        setPoolDetails,
        pageLoading,
        setPageLoading,
        setPoolRemembersData,
        setMonitorDetails,
        poolRemembers,
        monitorDetails,
        loadDetails,
        listenerDetails,
        poolDetails,
        instances,
        setInstances,
        getCreateOptions
      }}
      {...props}
    />
  );
};

const useBalancer = () => {
  const context = React.useContext(BalancerContext);
  if (context === undefined) {
    throw new Error('useBalancer must be used within a BalancerContextProvider');
  }
  return context as BalancerContextProps;
};

export { BalancerContextProvider, useBalancer };



