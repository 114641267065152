import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TabContainer,
  // TopInfoWrapper,
} from '../../styles'
import { Select, Toggle } from '../../../../components'
import MoonIcon from '../../../../components/header/components/drop-options/moon.svg'
import { useUi } from "../../../../context/ui.context";
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { authService } from '../../../../api'
import { useAuth } from '../../../../context/user.context'
  
const Personalization:FC = () => {
  const { i18n, t } = useTranslation()
  // const [account, setAccount] = useState<boolean>(true)
  // const [billing, setBilling] = useState<boolean>(true)
  // const [general, setGeneral] = useState<boolean>(true)
  // const [technical, setTechnical] = useState<boolean>(true)
  //@ts-ignore
  const [selectedLang, setSelectedLang] = useState<string>(localStorage.getItem('lang') || 'uk')
  const [langs, setLangs] = useState<any[]>([
    {
      label:t("ENGLISH"),
      value:'en',
    },
    {
      label:t("UKRAINE"),
      value:'uk',
    }
  ])
  const {
    setDataToLs,
    getDataToLs
  } = useLocalStorage()
  const {user} = useAuth()
  const {
    themeDark,
    setThemeDark,
  } = useUi()


  const selectLang = (e:any) => {
    let selected = langs.find((item:any) => item.value === e)
    // setSelectedLang(selected.value)
    // i18n.changeLanguage(selected.value);

    const data = {
      language:selected.value,
      email:user.user.email,
      first_name:user.user.first_name,
      last_name:user.user.last_name,
    }
    authService.updateClient(data)
    .then((res) => {
      localStorage.setItem('lang', selected.value)
      window.location.reload()
    })
    .catch((err:any) => {
      console.log('err changelang', err);
    })
  } 
  return(
    <TabContainer>
      <div style={{width:'360px'}}>
      <Select
        toolTip={false}
        data={langs}
        selectedValue={selectedLang}
        onChange={selectLang}
        title={t("LANGUAGE")}
        customStyles={{marginBottom:'20px', width:'360px'}}
      />
      {/* <Select
        toolTip={true}
        data={['1','2']}
        selectedValue={'2'}
        onChange={() => {}}
        title={t("CURRENCY")}
        customStyles={{marginBottom:'20px', width:'360px'}}
      /> */}
      <Toggle
        toolTip={false}
        value={themeDark}
        onChange={() => setThemeDark(!themeDark)}
        title={t("DARK_THEME")}
        customStyles={{marginBottom:'20px'}}
      />
      </div>
        {/* <InfoTitle style={{marginBottom:'10px'}}>
          {t("YOUR_NOTIFICATIONS")}
        </InfoTitle>
        <LabelWrapper>
          <CheckBox
            value={account}
            onChange={() => setAccount(!account)}
            title={t("ACCOUNT_AUTH_NOTIFICATION")}/>
          <CheckBox
            value={billing}
            onChange={() => setBilling(!billing)}
            title={t("BILLING_AND_SERVICE_NOTIFICATIONS")}/>
          <CheckBox
            value={general}
            onChange={() => setGeneral(!general)}
            title={t("GENERAL_ANNOUNCEMETS")}/>
          <CheckBox
            value={technical}
            onChange={() => setTechnical(!technical)}
            title={t("TECHNICAL_ANNOUNCEMETS")}/>
        </LabelWrapper> */}
      {/* <Button
        variant='primary'
        disabled
        size='display'
        onClick={() => {}}
        customStyles={{marginTop:'24px'}}
        title={t("SAVE")}
        icon={icons.Filter}
      /> */}

    </TabContainer>
  )
}
export default Personalization
