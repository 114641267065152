import styled from "styled-components";
import { BodyDefault, BodySmall, HeadLine1, HeadLine3, VerySmall } from "../../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 60px 30px;
  /* height:100%; */
  /* box-sizing: border-box; */
  position: relative;
`
export const BodyWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  margin-top: 20px;
`
export const EstimateContainer = styled.div`
  /* display: flex; */
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
  max-width: 240px;
  /* height: 320px; */
  background: ${({theme}) => theme.palette[200]};
  border-radius: 8px;
  margin-top: 20px;
`

export const EstimateTitle = styled(HeadLine3)`
  color:${({theme}) => theme.palette[850]};
`
export const EstimateSum = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
`
export const PerHour = styled(VerySmall)`
  color:${({theme}) => theme.palette[650]};
  margin-left:2px;
  /* margin-bottom:2px; */
`
export const EstimateSubTitle = styled(BodySmall)`
  color:${({theme}) => theme.palette[700]};
  margin-top:14px;
`
export const EstimateDesc = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  margin-top:4px;
  /* max-width:200px; */
  word-break: break-all;
`
export const PropContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 3px 4px;
  /* height:21px; */
  background-color: ${({theme}) => theme.palette[10]};
  margin-right: 6px;
  border-radius: 4px;
`
export const Prop = styled(VerySmall)`
  color:${({theme}) => theme.palette[850]};
`
export const PropsWrapper = styled.div`
  display:flex;
  margin-top: 4px;
`
export const Divider = styled.div`
  border-bottom: ${({theme}) => `2px solid ${theme.palette[300]}`};
  width: 210px;
  margin-top:15px;
  
`