import React, { FC, useEffect, useState } from "react";
import {
  Container,
  Block,
  TextBlock,
  InfoText,
} from './styles'
import { Button, ClipBoardWrapper, Input, Link, RadioButtonGroup, Table, Typography } from "../../../../../components";
import { useTranslation } from "react-i18next";
import { InfoContainer } from "../../../../../components/info-block/styles";
import { useNavigate, useParams } from "react-router-dom";
import { objStorageService } from "../../../../../api";
import LockIc from './lock.svg'
import { formatFileSize } from "../../../../../utils/sizes";
import { useUi } from "../../../../../context/ui.context";
import { JsonEditor } from 'json-edit-react'

const Component:FC<any> = ({bucket}) => {
  console.log('bucket',bucket);
  const [t] = useTranslation()
  const [disabledVisibility, setDisabledVisibility] = useState<boolean>(false)
  const {viewAlert} = useUi()
  const navigate = useNavigate()
  const {bucketId} = useParams()
  useEffect(() => {
    objStorageService.getPolicy(bucket.id)
  },[])
  const [jsonState, setJsonState] = useState<any>({
    "Policy": {
      "Version": "2012-10-17",
      "Statement": [{
        "Effect": "Allow",
        "Principal": {"AWS": ["arn:aws:iam::usfolks:user/fred:subuser"]},
        "Action": "s3:PutObjectAcl",
        "Resource": [
          "arn:aws:s3:::testbucket2709/*"
        ]
      }]
    }
  });
  useEffect(() => {
    //@ts-ignore
    objStorageService.getPolicy(bucketId)
    .then((res) => {
      setJsonState(res.data.Policy)
    })
    .catch((err) => {
      console.log('error', err);
    })
  },[])
  const onSubmit = () => {
    // setLoading(true);
    //@ts-ignore
    objStorageService.createPolicy(bucketId,jsonState)
    .then((res: any) => {
      // setLoadingComplete(true);
      setTimeout(() => {
        // setLoading(false);
        // setLoadingComplete(false);
        navigate(-1);
      }, 1000);
    })
    .catch((err) => {
      if (err.response?.data?.detail) {
        viewAlert({
          severity: "error",
          message: err.response?.data?.detail,
        });
      } else if (err?.response?.data) {
        // setErrors(err.response.data);
      }
      // setLoading(false);
    });
  };
  return(
    <Container>
      <Block>
        <Typography variant="h1">
          {t("BUCKET_INFO")}
        </Typography>
        <div>
          <Table
            columns={[
              {
                title:t("VISIBILITY"),
                key:'visibility'
              },
              // {
              //   title:t("USE_CASE"),
              //   key:'use_case',
              // },
              {
                title:t("BUCKET_ID"),
                key:'bucket_id',
              },
              {
                title:t("SIZE"),
                key:'size'
              },
            ]}
            rows={[
              {
                visibility:(
                  <div style={{display:'flex', gap:'6px'}}>
                    {bucket.access === 'private' ? 
                      <img src={LockIc}/>
                    : null
                    }
                    <Typography variant='body'>
                      {bucket.access}
                    </Typography>
                  </div>
                ),
                use_case:'',
                bucket_id:<ClipBoardWrapper>{bucket.id}</ClipBoardWrapper>,
                size:formatFileSize(bucket.usage),
              }
            ]}
            hideOther
            hideCheckbox
            // paginationData={paginationData}
          />
          <Table
            columns={[
              {
                title:t("NUMBER_OF_OBJECTS"),
                key:'count'
              },
              // {
              //   title:t("BUCKET_ENDPOINT"),
              //   key:'endpoint',
              // },
            ]}
            rows={[
              {
                count:bucket.num_objects,
                endpoint:'',
              }
            ]}
            hideOther
            hideCheckbox
            // paginationData={paginationData}
          />
        </div>
      </Block>
      <Block>
        <Typography variant="h1">
          {t("TAGS")}
        </Typography>
        <InfoContainer style={{display:'flex', flexDirection:'column', gap:'10px'}}>
          <Typography variant="body">
            {t("TAGS_DESC")}
          </Typography>
            <Input
              fieldName='name'
              title={''}
              placeholder=''
              // {...formikProps}
            />
        </InfoContainer>
      </Block>
      <Block>
        <Typography variant="h1">
          {t("BUCKET_POLICY")}
        </Typography>
        <TextBlock>
          <div style={{display:'flex', flexDirection:'column', gap:'4px'}}>
            <InfoText>{t("BUCKET_POLICIES_INFO")}</InfoText>
            <InfoText style={{display:'inline-flex', gap:'4px'}}>
              {t("BUCKET_POLICIES_INFO1")}
              <span>
                <Link link='' linkText={t("BUCKET_POLICIES_INFO_LINK")}/>
              </span>
              {t("BUCKET_POLICIES_INFO2")}
            </InfoText>
          </div>
          <Button
            variant="primary"
            size="display"
            onClick={onSubmit}
            title={`${t("SAVE")}`}
          />
        </TextBlock>
        <JsonEditor
          data={ jsonState }
          setData={ setJsonState }
        />
      </Block>
      <Block>
        <Typography variant="h1">
          {t("BUCKET_VISIBILITY")}
        </Typography>
        <InfoContainer style={{display:'flex', flexDirection:'column', gap:'12px', width:'100%', alignItems:'flex-start'}}>
          <InfoText>
            {t("VISIBILITY_INFO")}
          </InfoText>
          {/* Bucket visibility updated */}
          <RadioButtonGroup
            title={''}
            buttons={[
              { value: 'private', title: t('PRIVATE') },
              { value: 'public-read', title: t('PUBLIC') },
            ]}
            selectedButton={bucket.access}
            disabled={disabledVisibility}
            onChange={async(e:any) => {
              try{
                await setDisabledVisibility(true)
                const res = await objStorageService.editBucket(bucket.id, {access: e })
                viewAlert({
                  severity:"information",
                  message:`${t("BUCKET_VISIBILITY_UPDATED")}`
                })
                // setVisibility(e)
                await setDisabledVisibility(false)
              } catch (err:any){
                viewAlert({
                  severity:"error",
                  message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                })
                await setDisabledVisibility(false)
              }

            }}
          />
        </InfoContainer>
      </Block>
      <Block>
        <Typography variant="h1">
          {t("DELETE_BUCKET")}
        </Typography>
        <TextBlock>
          <InfoText>
            {t("DELETE_BUCKET_INFO")}
          </InfoText>
          <Button
            variant="primary"
            size="display"
            onClick={() => {}}
            title={`${t("DELETE_BUCKET")}`}
          />
        </TextBlock>
      </Block>
    </Container>
  )
}

export default Component