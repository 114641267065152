import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  DarkForm
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import { HeadLine3 } from "../../typography/styled";
import { useUi } from "../../../context/ui.context";
import TopProgress from "../../TopBarProgress";
import { objStorageService } from "../../../api";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
  loadingComplete?:boolean;
}  

const BucketTags:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    loading,
    loadingComplete
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
  })
  const [metadata, setMetadata] = useState<any[]>([])
  const [tags, setTags] = useState<any[]>([])

  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const {viewAlert} = useUi()
  useEffect(() => {
    objStorageService.getFileInfo(instance.bucketId, instance.name)
    .then((res) => {
      setPageLoading(false)
      setMetadata(Object.keys(res.data.Metadata).map((key) => {
        return{
          key: key,
          value: res.data.Metadata[key]
        }
      }))
      setTags(res.data.TagSet?.map((tag:any) => {
        return{
          key: tag.Key,
          value: tag.Value,
        }
      }))
    })
    .catch((err) => {
      console.log('err',err?.response?.data?.detail);
      viewAlert({
        severity:'error',
        message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    })
  },[])
  const onSubmit = (values:any) => {
    closeModal({...values, metadata})
  }

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      tags: yup.array().of(
        yup.object().shape({
          key: yup.string().required(`${t('REQUIRED_FIELD')}`),
          value: yup.string().required(`${t('REQUIRED_FIELD')}`),
        })
      ),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const handleInputChange = (index:any, field:any, value:any) => {
    const updatedData = [...tags];
    updatedData[index][field] = value;
    setTags(updatedData);
    setFieldValue('tags', updatedData);
  };
  const addNewItem = (instance?:any) => {
    setTags((prev:any) => {
      setFieldValue('tags', [...prev, 
        {
          key: "",
          value: '',
        }
      ]);
      return [...prev, 
        {
          key: "",
          value: '',
        }
      ]
    })
    
  };
  const removeItem = (index:any) => {
    const updatedData = [...tags];
    updatedData.splice(index, 1);
    setTags(updatedData);
    setFieldValue('tags', updatedData);
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("OBJECT_TAGS")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Button
          variant="stroke"
          size="display"
          icon={icons.PlusDefault}
          customStyles={{marginTop:"10px"}}
          //@ts-ignore
          onClick={addNewItem}
          title={`+ ${t("ADD_VALUE")}`}
        />
        {tags?.map((item:any, index:any) => (
          <DarkForm>
            <Input
              fieldName={`tags[${index}].key`}
              required
              toolTip={false}
              title={t("KEY")}
              placeholder=''
              arrayName={'tags'}
              value={item.key}
              customStyles={{width:'241px', flex:'auto 1'}}
              {...formikProps}
              handleChange={(e:any) => handleInputChange(index, 'key', e.target.value)}
            />
            <Input
              required
              fieldName={`tags[${index}].value`}
              toolTip={false}
              title={t("VALUE")}
              placeholder=''
              customStyles={{width:'150px', flex:'auto 1'}}
              {...formikProps}
              arrayFields={true}
              arrayName={'tags'}
              value={item.value}
              arrayIndex={index}
              arrayFieldName={'value'}
              handleChange={(e:any) => handleInputChange(index, 'value', e.target.value)}
            />

            <Button
              onClick={() => removeItem(index)}
              variant='icon'
              size='display'
              // disabled
              customStyles={{marginTop:'24px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </DarkForm>
        ))}
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '236px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            customStyles={{ width: '236px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("SAVE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default BucketTags