import styled from "styled-components";

type HideProps = {
  active:boolean,
}

export const Container = styled.div`
  padding-top:20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap:20px;
`

export const HideContainer = styled.div<HideProps>`
  max-height: ${({active}) => active ? '1000px' : '0px'};
  overflow:${({active}) => active ? '' : 'hidden'};
  transition: max-height 0.5s ease-in-out;
  width: 560px;
`