import React, {FC, useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { StartPage, Button, ModalVariables, Label } from "../../components";
import TutorImg from './illustration.svg'
import TutorDarkImg from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { timeConverterMMDDYYHHMM } from "../../utils/dates";
import { volumeBackupService, volumeService } from "../../api";
import { useUi } from "../../context/ui.context";
import { useAuth } from "../../context/user.context";
import rootConfig from "../../config";
import { compareArrays } from "../../utils/findIntersection";

const VolumeBackups:FC = () => {
  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user, quotas} = useAuth()
  const [backups, setBackups] = useState<any[]>([])
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering='-created_at', setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam);
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [paginationData, setPaginationData] = useState<any>()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedBackup, setSelectedBackup] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(backups, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const [t] = useTranslation()
  const navigate = useNavigate()
  const _updateBackups = () => {
    // setLoading(true)
    volumeBackupService.getBackupList({
      page:page || 1,
      page_size:pageSize || 10,
      ordering:ordering,
    })
    .then((res) => {
      setBackups(res.data.objects.map((backup:any) => {
        return {
          ...backup,
          navigate: backup.id,
          sizeString:`${backup.size} GB`,
          // uniq_id: backup.id,
          nameStr: backup.name || backup.id,
          incremental: backup.is_incremental ? t("YES") : t("NO"),
          name: (
            <div style={{
              display: "flex",
              alignItems: "center",
              gap:'1px'
            }}>
              {backup.name || backup.id}
              {backup.description && 
                <Label
                  title=''
                  tooltipText={backup.description}
                />
              }
              {/* <img src={icons.IconInfo}/> */}
            </div>),
          created_at:timeConverterMMDDYYHHMM(backup.created_at),
          // status:(
          // <StateWrapper>
          //   <GreenDot active={volume.status}/>
          //   <Greentext style={{textTransform:"capitalize"}} active={volume.status}>
          //     {volume.status}
          //   </Greentext>
          // </StateWrapper>) ,
        }
      }))
      setPaginationData(res.data)
      setPageLoading(false)
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      navigate('/home')
      setPageLoading(false)
    })
  }

  useEffect(() => {
    _updateBackups()
    const interval = setInterval(() => {
      _updateBackups()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = async (values:any) => {
    let res:any;
    try{
      setLoading(true);
      switch(modalType){
        case 'restore-volume-backup':
          res = await volumeService.restoreVolumeBackup(selectedBackup.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break
        case 'edit-volume-backup':
          res = await volumeService.renameVolumeBackup(selectedBackup.id, {...selectedBackup, name:values.name, description:values.description})
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break
        case "delete":
          try {
            if (allChecked) {
              await Promise.all(backups.map((item) => volumeService.deleteBackup(item.id)));
              _updateBackups();
              setAllChecked(false);
              setChecked([]);
            } else if (checked?.length > 0) {
              await Promise.all(checked.map((item) => volumeService.deleteBackup(item.id)));
              _updateBackups();
              setChecked([]);
            } else if (selectedBackup) {
              await volumeService.deleteBackup(selectedBackup.id);
              _updateBackups();
            }
          } catch (e:any) {
            if (e?.response?.data.detail) {
              viewAlert({
                severity: 'error',
                message: e.response.data.detail,
              });
            }
            setLoading(false);
          } finally {
            setViewModal(false);
            setActiveAnimation(false);
          }
          break;
        case 'create-volume-backup':
          res = await volumeService.createBackup(values)
          // await setLoadingComplete(true)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          // await setTimeout(() => {
          //   setLoadingComplete(false)
          // },2000)
          break;
      }
      setViewModal(false)
      setActiveAnimation(false)
    } catch (e: any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setLoading(false);
    }
  }
  const items = [
    {
      title:`${t("EDIT")}`,
      id:'2',
      icon:icons.Edit,
      onClick: (backup:any) => {
        openModal('edit-volume-backup')
        setSelectedBackup(backup)
        setAlertTitle(`${t("EDIT_BACKUP")} «${backup.nameStr || backup.id}»`)
      },
      divider:false
    },
    {
      title:`${t("RESTORE")}`,
      id:'6',
      icon:icons.BackArrowRevert,
      onClick: (backup:any) => {
        openModal('restore-volume-backup')
        setSelectedBackup(backup)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(backup:any) => {
        openModal('delete')
        setAlertTitle(t("BACKUP"))
        setAlertSubTitle([backup])
        setSelectedBackup(backup)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]

  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    // {
    //   title:t("ID"),
    //   key:'uniq_id'
    // },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
    {
      title:t("INCREMENTAL"),
      key:'incremental'
    },
    {
      title:'',
      key:'props'
    },
  ]
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas.volume.volumes.limit === quotas.volume.volumes.in_use){
      viewAlert({
        severity:'error',
        message:`${t('VOLUMES_QUOTA_EXCEEDED')}`,
      })
    } else {
      openModal('create-volume-backup')
    }
  }
  return (
    <>
      <StartPage
        title={`${t("VOLUME_BACKUPS")}`}
        noResourceText={`${t("NO_VOLUME_BACKUPS")}`}
        createResourceButton={`${t('CREATE_BACKUP')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={backups && backups.length > 0}
        learnMoreAboutResource={`${t('LEARN_MORE_VOLUME_BACKUPS')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/volume-backups`}
        howCreateVideoUrl="https://www.youtube.com/embed/hppVX2LZt_g"
        howCreateVideoText={`${t('HOW_CREATE_VOLUME_BACKUP')}`}
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("VOLUME_BACKUPS"))
                setAlertSubTitle(allChecked ? backups : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        videoImage={TutorImg}
        videoImageDark={TutorDarkImg}
        pageLoading={pageLoading}
        tableData={{
          columns:Columns,
          data:backups,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          paginationData:paginationData,
          updateData:_updateBackups
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        loadingComplete={loadingComplete}
        instance={{name:selectedBackup?.nameStr, description:selectedBackup?.description}}
        restoreVloumeBackupProps={{backupId:selectedBackup?.id}}
      />
    </>
  )
}


export default VolumeBackups
