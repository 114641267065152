import React, { FC, useRef, useEffect, useState } from "react";
import {
  // Container,
  TopComponent,
  HeadLine,
  RightHeadText,
  Title,
  SubTitle,
  MarkTextWrapper,
  NotificationsWrapper,
  NotificationTitleWrapper,
  NotificationRightContainer,
  NotificationCard,
  TitleMedium,
  NotificationIconContainer,
  NoNotificationsContainer,
} from './styles'
import MarksIcon from './marks.svg'
import NewNotification from './new.svg'
import TestIcon from './testIcon.svg'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/user.context";
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";
import { useTranslation } from "react-i18next";
import { icons } from "../../../../icons";
import Typography from "../../../typography";
import { notificationService } from "../../../../api";
import TopProgress from "../../../TopBarProgress";
type NotificationsProps = {
  active?:boolean,
  ref?:any,
  handleClose?:any,
}

const NotificationsModal:FC<NotificationsProps> = ({active, ref, handleClose}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const {notifications, updateData} = useAuth()
  const [t] = useTranslation()
  const markAll = () => {
    setLoading(true)
    notificationService.markAllAsRead()
    .then(() => {
      updateData()
      setLoading(false)
    })
    .catch(() => {
      setLoading(false)
    })
  }
  return (
    <>
      <TopProgress loading={loading}/>
      <div>
        <TopComponent>
          <HeadLine>
            {t("NOTIFICATIONS")}
          </HeadLine>
          {notifications?.objects?.length > 0 && 
            <MarkTextWrapper onClick={markAll}>
              <img src={MarksIcon}/>
              <RightHeadText>
                {t("MARK_ALL_AS_READ")}
              </RightHeadText>
            </MarkTextWrapper>
          }
        </TopComponent>
        <NotificationsWrapper>
          {notifications?.objects?.length > 0 ? notifications?.objects.map((item:any) => (
            <NotificationItem key={item.id} item={item}/>
          )) : 
            <NoNotificationsContainer>
              <NotificationIconContainer src={icons.IcNotification}/>
              <Typography variant="h3">
                {t("NO_NOTIFICATIONS_YET")}
              </Typography>
              <Typography variant="body">
                {t("WELL_LET_YOU_KNOW_WHEN_WE_GET_NOTIFICATIONS")}
              </Typography>
            </NoNotificationsContainer>
          }
        </NotificationsWrapper>
      </div>
      {notifications?.objects?.length > 0 &&
        <RightHeadText
          onClick={() => {
            navigate('/notifications')
            
            handleClose()
          }}
          style={{marginTop:'8px'}}
        >
        {t("VIEW_ALL_NOTIFICATIONS")}
        </RightHeadText>
      }
    </>
  )
}

export default NotificationsModal

type NotificationItemProps = {
  item:any
}

const NotificationItem:FC<NotificationItemProps> = ({item}) => {
  const [titleHover, setTitleHover] = useState<boolean>(false)
  const navigate = useNavigate()
  return (
    <NotificationCard
      key={item.id}
      onClick={() => navigate(`/notifications/${item.id}`)}
      onMouseEnter={() => setTitleHover(true)}
      onMouseLeave={() => setTitleHover(false)}
      active={titleHover}
    >
      {item.status !== 'seen' ? 
      <img src={NewNotification}/>
      : null}
      <NotificationRightContainer>
        <NotificationTitleWrapper>
          {item.status !== 'seen' ?
            <TitleMedium active={titleHover}>
              {item.title}
            </TitleMedium>
          :
            <Title active={titleHover}>
              {item.title}
            </Title>
          }
          <SubTitle>
            {timeConverterMMDDYYHHMM(item.generated)}
          </SubTitle>
        </NotificationTitleWrapper>
        <img src={TestIcon}/>
      </NotificationRightContainer>
    </NotificationCard>
  )
}