import React, {FC, useState, useEffect} from "react";
import {
  Container
} from './styles'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { Table, TableSkeleton, TopProgress, Typography } from "../../../../components";
import { billingService } from "../../../../api";
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";
import { StatusText } from "../overview/styles";
import { HeadLine1 } from "../../../../components/typography/styled";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../utils/display-price";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";
import { handleStatusName } from "../../../../utils/handle-status-name";

const Invoices:FC = () => {
  const theme:any = useTheme()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [paginationData, setPaginationData] = useState<any>()
  const [invoices, setInvoices] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [t] = useTranslation()
  const getInvoices = () => {
    setLoading(true)
    billingService.getInvoiceList({
      page:page || 1,
      page_size:pageSize || 10,
      ordering:ordering || '-issue_date',
    })
    .then((res) => {
      setInvoices(res.data.objects.map((invoice:any) => {
        return {
          ...invoice,
          issue_date: timeConverterMMDDYYHHMM(invoice.issue_date),
          navigate:invoice.id.toString(),
          status:<StatusText status={invoice.status}>{handleStatusName(invoice.status, t)}</StatusText>,
          total:formatCurrency(invoice.total, invoice.currency),
        }
      }))
      setPaginationData(res.data)
      setPageLoading(false)
      setLoading(false)
    })
    .catch((e) => {
      console.log('error', e);
    })
  }
  useEffect(() => {
    getInvoices()
    const interval = setInterval(() => {
      getInvoices()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  const ColumnsInvoices = [
    {
      title:'ID',
      key:'id'
    },
    {
      title:t("OPERATION_STATUS"),
      key:'status',
      sorted:true,
    },
    {
      title:t("ISSUE_DATE"),
      sorted:true,
      key:'issue_date'
    },
    {
      title:t("TOTAL"),
      key:'total'
    },
  ]
  if(pageLoading) return (
    <>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={125} style={{marginBottom:"5px"}} height={30}
      />
      <TableSkeleton
        rows={10}
        columns={5}
        // columnsData={Columns}
      />
    </>
  )
  return (
    <Container>

      <HeadLine1 style={{marginBottom:'10px'}}>
        {t("INVOICES")}
      </HeadLine1>
      {invoices?.length > 0 ?
        <Table
          columns={ColumnsInvoices}
          rows={invoices}
          // hideOther
          hideCheckbox
          paginationData={paginationData}
        />
      :
        <Typography variant="body">
          {t("NO_INVOICES")}
        </Typography>
      }
    </Container>
  )
}

export default Invoices

