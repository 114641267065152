import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  InputWrapper,
  ReportingContainer,
  ReportingItems,
  ReporingItem,
  CopyContainer,
  InfoBlockItem,
  ImgBig,
} from './styles'
import { Button, Input, Label, Typography } from '../../../../components'
import { InfoContainer } from '../../../../components/info-block/styles'
import MetricsChart from '../../../../components/metrics'
import copy from 'copy-to-clipboard';
import WorldMap from "react-svg-worldmap";
import { icons } from '../../../../icons'
import { filterByToday } from '../../../../utils/dates'
import { useTheme } from 'styled-components'
import Illustration from './illustration-default.svg'
import IllustrationDark from './illustration-dark.svg'
import { useNavigate } from 'react-router-dom'


type OverviewProps = {
  programData:any,
  scales?:any,
  networkMetricsOut?:any,
  networkMetrics?:any,
  loadNetwork?:any,
  countries?:any,
}
function sumValuesByCountry(inputArray:any[]) {
  const outputArray:any[] = [];

  inputArray.forEach(obj => {
      const existingObjIndex = outputArray.findIndex(item => item.country === obj.country);
      if (existingObjIndex !== -1) {
          outputArray[existingObjIndex].value += obj.value;
      } else {
          outputArray.push({ country: obj.country, value: obj.value });
      }
  });

  return outputArray;
}
const Overview:FC<OverviewProps> = (props) => {
  const {
    programData,
    scales,
    networkMetricsOut,
    networkMetrics,
    loadNetwork,
    countries
  } = props;

  const theme:any = useTheme()
  const [t] = useTranslation()
  const [copyText, setCopyText] = useState<boolean>(false)
  const setIsCopied = () => {
    setCopyText(true)
    setTimeout(() => {
      setCopyText(false)
    },1500)
  }
  const navigate = useNavigate()
  const _updateMetrics = () => {


  }
  return(
    <Container>
      <div style={{
        display:'flex',
        gap:'10px',
        flexWrap:'wrap',
      }}>
        <ReporingItem style={{margin:'0px',display:'flex', flexDirection:'column',gap:'10px'}}>
          <Typography customStyles={{marginBottom:"10px"}} variant="h2">
            {t("GET_STARTED_REFERRAL_LINK")}
          </Typography>
          <InputWrapper>
            <Input
              title={t("SHAREABLE_LINK")}
              value={programData?.info?.link}
              readOnly={true}
              placeholder={programData?.info?.link}
            />
            {copyText ?
              <CopyContainer>
                <Typography variant='small'>
                  {t("COPIED")}
                </Typography>
              </CopyContainer>
              : null}
            <Button
              onClick={() => {
                setIsCopied()
                copy(programData?.info?.link)
              }}
              test_id='button-delete'
              customStyles={{marginTop:'17px'}}
              variant='icon'
              size='display'
            >
              <img src={icons.CopyProps}/>
            </Button>
          </InputWrapper>
          {/* <Toggle
            value={false}
            onChange={() => {}}
            title={t("CREATE_SHORT_LINK")}
          /> */}
        </ReporingItem>
        <InfoBlockItem>
          <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', gap:'6px',zIndex:'1', width:'80%'}}>
            <Typography  variant='h2'>
              <span>{t("GET")}</span>
              <span style={{margin:'0px',color:theme.primary[600]}}> 10% </span>
              <span>{t("FROM_EVERY_DEPOSIT")}</span>
            </Typography>
            <Typography variant='body'>
              {t("DEPOSIT_PERCENT_DESCRIPTION")}
            </Typography>
            <Button
              title={t("READ_INFO")}
              variant='secondary'
              size='small'
              onClick={() => navigate('/affiliate/info')}
            />
          </div>
          <ImgBig src={theme.isDark ? IllustrationDark : Illustration}/>
        </InfoBlockItem>
      </div>
      <ReportingContainer>
        <Typography variant="h2">
          {t('YOUR_DAILY_REPORTING')}
        </Typography>
        <ReportingItems>
          <ReporingItem>
            <Label
              tooltipText={t("CONVERSIONS_INFO")}
              title={t("CONVERSIONS")}
            />
            <Typography variant="h1">
              {filterByToday(programData.conversion, 'created_at')?.length}
            </Typography>
          </ReporingItem>
          <ReporingItem>
            <Label
              tooltipText={t("COMMISSION_INFO")}
              title={t("COMMISSION_AMOUNT")}
            />
            <Typography variant="h1">
              {programData.comissionAmountDaily}
            </Typography>
          </ReporingItem>
        </ReportingItems>
      </ReportingContainer>
      {/* <ReporingItem> */}

      {/* </ReporingItem> */}
      <div style={{
        display:'flex',
        gap:'10px',
        flexWrap:'wrap',
      }}>
      {networkMetricsOut?.length === 0 ? null :
          <MetricsChart
            updateData={_updateMetrics}
            title={t("COMMISSION_METRIC")}
            scales={scales}
            hideSelect
            affiliate
            data={networkMetricsOut}
            dataSecond={networkMetrics}
            loading={loadNetwork}
            wihoutDates={true}
            customStyles={{
              flexBasis:0,
              flexGrow: 1,
            }}
            // isBareMetal
            // customDataSelect={data}
          />
        }

      </div>
      <div style={{
        display:'flex',
        gap:'10px',
        flexWrap:'wrap',
      }}>
        <ReporingItem style={{border:'none', boxShadow:'none', padding:'0px', backgroundColor:'transparent'}}>
          <Typography variant="h2">
            {t('YOUR_COMMISSION_HIGHTLIGHTS')}
          </Typography>
          <ReportingItems style={{flexDirection:'column'}}>
            <ReporingItem style={{width:'100%'}}>
              <Label
                tooltipText={t("CONVERSIONS_INFO")}
                title={t("CONVERSIONS")}
              />
              <Typography variant="h1">
                {programData.conversion?.length}
              </Typography>
            </ReporingItem>
            <ReporingItem style={{width:'100%'}}>
              <Label
                tooltipText={t("COMMISSION_INFO")}
                title={t("COMMISSION_AMOUNT")}
              />
              <Typography variant="h1">
                {programData.comissionAmount}
              </Typography>
            </ReporingItem>
          </ReportingItems>
          {/* uncomment if sanya sdelaet balans suka */}
          <Typography variant="h2">
            {t('REFERRAL_BALANCE')}
          </Typography>
          <ReportingItems style={{flexDirection:'column'}}>
            <ReporingItem style={{width:'100%'}}>
              <Typography variant="h1">
                {programData?.balance}
              </Typography>
            </ReporingItem>
          </ReportingItems>
        </ReporingItem>
        <ReporingItem style={{justifyContent:'center', backgroundColor:theme.palette[10]}}>
          <WorldMap
            color={theme.primary[700]}
            borderColor={theme.primary[700]}
            title=""
            value-suffix="people"
            size="lg"
            data={sumValuesByCountry(countries)}
            backgroundColor={theme.palette[10]}
            // frameColor="#FFF"
          />
        </ReporingItem>
      </div>

    </Container>
  )
}
export default Overview