import React, { FC, useState, useEffect } from "react";
import {
  Container,
  TitleBig,
  NavWrapper
} from './styles'
import {
  BackArrow,
  Tabs,
  TopProgress
} from "../../../components";
import { useTranslation } from "react-i18next";
import {
  Route,
  Navigate,
  Routes,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import MainEdit from "./main";
import Properties from "./properties";
import { imageService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { randomNameGenerator } from "../../../utils/create-random-name";

const EditImage:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {imageId} = useParams()
  const {viewAlert} = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [image, setImage] = useState<any>({})
  const [activeTab, setActiveTab] = useState<string>()
  const [imageProps, setImageProps] = useState<any[]>([])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("MAIN_INFO"),
      slug:'main',
      id:'1'
    },
    {
      title:t("PROPERTIES"),
      slug:'properties',
      id:'2'
    },
  ])
  const location = useLocation()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])

  function objectToArray(obj: any) {
    return Object.entries(obj).map(([key, value]) => ({ key, value, id: randomNameGenerator({ name: 'prop', findArr: [] }) }));
  }
  const getEditedImage = () => {
    if(imageId){
      imageService.getImage(imageId)
      .then((res) => {
        setPageLoading(false)
        setImage(res.data)
        setImageProps(objectToArray(res.data.properties))
      })
      .catch((err) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: err.response.data.detail
          })
          navigate('/images')
        }
      })
    }
  }
  useEffect(() => {
    getEditedImage()
  },[])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow
        onClick={() => navigate('/images')}
        title={t("BACK_TO_IMAGES")}
      />
      <TitleBig>{t("EDIT")}&nbsp;{image?.name}</TitleBig>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="main" />} /> 
        <Route path='/main' element={<MainEdit image={image}/>}/>
        <Route path='/properties' element={<Properties imageProps={imageProps} image={image}/>}/>
      </Routes>
    </Container>
  )
}

export default EditImage