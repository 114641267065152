import React, { FC } from 'react';
import styled from 'styled-components';
const LogLine = styled.div`
  display: flex;
  margin: 4px 0;
  
  &.log-level-info {
    color: green;
  }
  
  &.log-level-warning {
    color: orange;
  }
  
  &.log-level-error {
    color: red;
  }
`;

const Timestamp = styled.span`
  margin-right: 8px;
  font-weight: bold;
`;

const Level = styled.span`
  margin-right: 8px;
  font-style: italic;
`;

const Message = styled.span`
  flex: 1;
`;
function parseLogLine(line:any) {
  // Регулярное выражение для парсинга строки лога
  const regex = /^(?:(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})\s)?(?:(<\d+>)\s)?(.+?):\s(.*)$/;
  const match = line.match(regex);

  if (match) {
    const timestamp = match[1];
    const level = match[2];
    const source = match[3];
    const message = match[4];

    return {
      timestamp,
      level,
      source,
      message,
    };
  }

  // Если не удается распарсить, вернем исходную строку как сообщение
  return {
    message: line,
  };
}

const LogViewer:FC<any> = ({ log }) => {
  // Разделите лог на строки
  const logLines = log.split('\n');

  return (
    <div>
      {logLines.map((line:any, index:any) => {
        // Распарсите каждую строку лога
        const parsedLine = parseLogLine(line);

        // Определите уровень логирования
        const levelClass = parsedLine.level ? `log-level-${parsedLine.level}` : '';

        // Отобразите каждую строку лога в соответствующем стиле
        return (
          <LogLine key={index} className={levelClass}>
            {parsedLine.timestamp && <Timestamp>{parsedLine.timestamp}</Timestamp>}
            {parsedLine.level && <Level>{parsedLine.level}</Level>}
            {parsedLine.message && <Message>{parsedLine.message}</Message>}
          </LogLine>
        );
      })}
    </div>
  );
};

export default LogViewer