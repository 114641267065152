import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Button, Table, ModalVariables, TopProgress, Label } from "../../../../../components";
import {
  Container,
  ButtonsWrapper
} from './styles'
import { icons } from "../../../../../icons";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { volumeService } from "../../../../../api";
import { useParams } from "react-router-dom";
import { timeConverterMMDDYYHHMM } from "../../../../../utils/dates";
import { useUi } from "../../../../../context/ui.context";
import { compareArrays } from "../../../../../utils/findIntersection";


type OverviewProps = {
  volume:any
}


const Backup:FC<OverviewProps> = ({volume}) => {
  const {volumeId} = useParams()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam);
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [backups, setBackups] = useState<any[]>([])
  const [paginationData, setPaginationData] = useState<any>()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedBackup, setSelectedBackup] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(backups, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const _updateBackups = () => {
    if(volumeId){
    // setLoading(true)
      volumeService.getVolumeBackupsList({
        page:page || 1,
        pageSize:pageSize || 10,
        ordering:ordering,
      }, volumeId)
      .then((res) => {
        setBackups(res.data.objects.map((backup:any) => {
          return {
            ...backup,
            uniq_id: backup.id,
            navigate: `/volume-backups/${backup.id}`,
            nameStr: backup.name,
            incremental: backup.is_incremental ? t("YES") : t("NO"),
            name: (
              <div style={{
                display: "flex",
                alignItems: "center",
                gap:'1px'
              }}>
                {backup.name || backup.id}
                {backup.description && 
                  <Label
                    title=''
                    tooltipText={backup.description}
                  />
                }
                {/* <img src={icons.IconInfo}/> */}
              </div>),
            created_at:timeConverterMMDDYYHHMM(backup.created_at),
          }
        }))
        setPaginationData(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        console.log('error', e);
      })
    }
  }
  useEffect(() => {
    _updateBackups()
    const interval = setInterval(() => {
      _updateBackups()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[])

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = async (values:any) => {
    let res:any;
    try{
      setLoading(true);
      switch(modalType){
        case 'restore-volume-backup':
          res = await volumeService.restoreVolumeBackup(selectedBackup.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break
        case 'edit-volume-backup':
          res = await volumeService.renameVolumeBackup(selectedBackup.id, {...selectedBackup, name:values.name, description:values.description})
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break
        case "delete":
          try {
            if (allChecked) {
              await Promise.all(backups.map((item) => volumeService.deleteBackup(item.id)));
              _updateBackups();
              setAllChecked(false);
              setChecked([]);
            } else if (checked?.length > 0) {
              await Promise.all(checked.map((item) => volumeService.deleteBackup(item.id)));
              _updateBackups();
              setChecked([]);
            } else if (selectedBackup) {
              await volumeService.deleteBackup(selectedBackup.id);
              _updateBackups();
            }
          } catch (e:any) {
            if (e?.response?.data.detail) {
              viewAlert({
                severity: 'error',
                message: e.response.data.detail,
              });
            }
            setLoading(false);
          } finally {
            setViewModal(false);
            setActiveAnimation(false);
          }
          break;
        case "create-backup":
          res = await volumeService.createBackup(values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
      }
      setViewModal(false)
      setActiveAnimation(false)
    } catch (e: any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setLoading(false);
    }
  }
  const items = [
    {
      title:`${t("EDIT")}`,
      id:'2',
      icon:icons.Edit,
      onClick: (backup:any) => {
        openModal('edit-volume-backup')
        setSelectedBackup(backup)
        setAlertTitle(`${t("EDIT_BACKUP")} «${backup.nameStr || backup.id}»`)
      },
      divider:false
    },
    {
      title:`${t("RESTORE")}`,
      id:'6',
      icon:icons.BackArrowRevert,
      onClick: (backup:any) => {
        openModal('restore-volume-backup')
        setSelectedBackup(backup)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(backup:any) => {
        openModal('delete')
        setAlertTitle(t("BACKUP"))
        setAlertSubTitle([backup])
        setSelectedBackup(backup)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]

  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("ID"),
      key:'uniq_id'
    },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
    {
      title:t("INCREMENTAL"),
      key:'incremental'
    },
    {
      title:'',
      key:'props'
    },
  ]


  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      {
        backups.length === 0 ? 
          <>
            <Typography variant="body">{t("THERE_ARE_NO_BACKUP_YET")}</Typography>
            <Button
              title={t('CREATE_BACKUP')}
              onClick={() => {
                setAlertTitle(volume.name || volume.id)
                openModal('create-backup')
              }}
              variant='primary'
              size='custom'
              customStyles={{height:'40px', margin:'20px 0px'}}
            />
          </>
        :
          <>
            <ButtonsWrapper>
              <div>
                <Button
                  title={t('CREATE_BACKUP')}
                  onClick={() => {
                    setAlertTitle(volume.name || volume.id)
                    openModal('create-backup')
                  }}
                  variant='primary'
                  size='custom'
                  customStyles={{ height:'40px', marginRight:'6px'}}
                />
                <Button
                  onClick={() => {
                    setAlertTitle(t("BACKUPS"))
                    setAlertSubTitle(allChecked ? backups : checked)
                    openModal('delete')
                  }}
                  variant='icon'
                  test_id='button-delete'
                  size='display'
                  disabled={!allChecked && checked?.length === 0}
                >
                  <img src={icons.DeleteIcon}/>
                </Button>
              </div>
            </ButtonsWrapper>
            <Table
              columns={Columns}
              rows={backups}
              paginationData={paginationData}
              settings={items}
              allChecked={allChecked}
              setAllChecked={setAllChecked}
              checked={checked}
              setChecked={editChecked}
              updateData={_updateBackups}
            />
          </>
      }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={{name:selectedBackup?.nameStr, description:selectedBackup?.description}}
        dataToEdit={{
          id:volumeId,
          volume_size:volume.size,
          region:volume.region,
        }}
        // instance={selectedBackup}
        restoreVloumeBackupProps={{backupId:selectedBackup?.id}}
        
      />
        
    </Container>
  )
}

export default Backup
