import React, { FC, useEffect, useState } from "react";
import { AlertProps } from "../../context/ui.context";
import { icons } from "../../icons";
import {
  Container,
  IconBackground,
  Title,
  TitleWrapper,
  Content,
  CloseIcon,
  DocIcon
} from './styles'
import Doc from './document.svg'

const BigAlert:FC<any> = (props) => {
  const [active, setActive] = useState<boolean>(true)

  const {
    severity,
    message, 
    deleteItem,
    onClick
  } = props

  return (
    <Container active={active}>
      <div onClick={onClick} style={{display:'flex', alignItems:'center'}}>
        <IconBackground>
          <DocIcon  src={Doc}/>
        </IconBackground>
        <TitleWrapper>
          <Title>
            {severity}
          </Title>
          <Content>
            {message}
          </Content>
        </TitleWrapper>
      </div>
      <CloseIcon onClick={() => {
        setActive(false)
        // setTimeout(() => {
        //   setActive()
        // },200)
        }} src={icons.CloseIcon}/>
    </Container>
  )
}


export default BigAlert