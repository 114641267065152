import styled from "styled-components";
import { BodyDefault, BodyMedium } from "../../typography/styled";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 20px;
  box-sizing: border-box;
  /* height: 350px; */
  left: 540px;
  top: 305px;
  background: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`
export const BodyModal = styled.div`
  /* display:flex; */
  flex-direction: column;
  align-items: center;
  padding:0px 10px;
`

export const Icon = styled.img`
  &:hover{
    cursor: pointer;
  }
`


export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 8px;
  gap: 10px;
  /* width: 505px; */
  /* height: 40px; */
  /* Grey/200 */
  background: ${({theme}) => theme.palette[200]};
  border-radius: 4px;
  box-sizing: border-box;
`
type ImgProps = {
  active?:boolean;
}
export const StepName = styled(BodyMedium)`
  text-transform: capitalize;
`
export const RotateImg = styled.img<ImgProps>`
  animation: ${({active}) => active ? 'rotating 0.8s linear infinite' : ''};
  @keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`