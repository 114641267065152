import React, { FC, useEffect, useState } from "react";
import {
  Container,
  HideContainer
} from './styles'
import { Button, Input, Select, Toggle, MultiInput } from "../../../../../components";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { WarningBlock, WarningText } from "../../../../Instances/components/create-instance/configure/styles";
import { icons } from "../../../../../icons";
import { kubernetesService } from "../../../../../api";
import { useUi } from "../../../../../context/ui.context";


const NetworkingPage:FC<any> = ({cluster, updateFunc}) => {
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [restriction, setRestriction] = useState<boolean>(cluster?.restriction_api || false)
  const [initialValues, setInitialValues ] = useState<any>({
    api_range:''
  })
  const [apiRanges, setApiRanges] = useState<string[]>(cluster?.restriction_ips || [])

  const onSubmit = (values:any) => {
    setLoading(true)
    if(apiRanges.length === 0 && restriction) {
      setLoading(false)
      //@ts-ignore
      setErrors({api_range:t("NOT_EMPTY_IP_LIST")})
      return
    }
    // setDataToLs({key:'balancer.balancer_details', data:{...values, admin_state_up:admin}})
    // setStep('configure-worker')
    const data = {
      restriction_api:restriction,
      restriction_ips:apiRanges,
    }
    kubernetesService.editRestriction(cluster.id, data)
    .then((res:any) => {
      setLoadingComplete(true)
      updateFunc()
      // viewAlert({
      //   severity:"info",
      //   message:res?.data?.detail
      // })
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
      }, 1500)
    })
    .catch((err:any) => {
      viewAlert({
        severity:"error",
        message:err?.response?.data?.detail || t("ERROR_OCCURRED")
      })
      setLoading(false)
      setLoadingComplete(false)
    })
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setErrors,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      api_range: yup.string()
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
    const changeTags = (tag:string) => {
    const founded = apiRanges.find((item:any) => item === tag)
    if(founded || tag === '' || tag === undefined) {
      setApiRanges((prev:any) => {
        return prev.filter((item:any) => item !== tag)
      })
    } else {
      setApiRanges((prev) => [...prev, tag])
      handleChange('api_range')('')
    }
  }

  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  function arraysAreEqual(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }
  return (
    <Container>
      <Toggle
        title={t("RESTRICTION_API")}
        toolTip={true}
        tooltipText={`${t("RESTRICTION_API_DESCRIPTION")}`}
        value={restriction}
        onChange={() => setRestriction(!restriction)}
      />
      <HideContainer active={restriction}>
      <div style={{display:'flex', flexDirection:"column", gap:"20px", width:'360px'}}>
        {/* <WarningBlock>
          <img src={icons.Warning}/>
          <WarningText>
            {t("INFO_CREATE_IPS")}
          </WarningText>
        </WarningBlock> */}
        <MultiInput
          toolTip={true}
          isRestrictionIp
          toolTipText={`${t("INFO_CREATE_IPS")}`}
          customStyles={{paddingLeft:'35px'}}
          placeholder={t("ADD_IP")}
          fieldName='api_range'
          tags={apiRanges}
          icon={icons.IconTag}
          title={t("RESTRICTION_API_IP_RANGES")}
          changeTags={changeTags}
          {...formikProps}
        />
      </div>
      </HideContainer>
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("SAVE")}
          loading={loading}
          loadingComplete={loadingComplete}
          disabled={cluster?.restriction_api === restriction && arraysAreEqual(cluster?.restriction_ips || [], apiRanges)}
        />
      </div>
    </Container>
  )
}

export default NetworkingPage