import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, ModalVariables, Tabs, TopProgress } from "../../../../components";
import MenuButton from "../../../../components/button/menu-button";
import DropOptions from "../../../../components/header/components/drop-options";
import { PropsContainer } from "../../../../components/table/styles";
import { icons } from "../../../../icons";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  InstanceHeadInfo,
} from './styles'
import {
  Rules,
  Overview,
} from './components'
import { BodyDefault } from "../../../../components/typography/styled";
import { loadBalancerService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { handleStatusName } from "../../../../utils/handle-status-name";
import { GreenDot, Greentext, StateWrapper } from "../../../Instances/styles";
// import CreateLoadBalancer from "../create-page";

const PolicyPage:FC = () => {
  const navigate = useNavigate()
  const {lbId, listenerId, policyId} = useParams()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const [policy, setPolicy] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)

  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [modalType, setModalType] = useState<any>('')
  const {viewAlert} = useUi()
  const updatePolicy = () => {
    if(policyId && listenerId){
      loadBalancerService.getPolicyById(policyId, listenerId)
      .then((res) => {
        setPolicy(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
      })
    }
  }
  useEffect(() => {
    updatePolicy()
    const interval = setInterval(() => {
      updatePolicy()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },
    {
      title:t("RULES"),
      slug:'rules',
      id:'3'
    },
  ])
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(modalType === 'delete'){
      setLoading(true);
      loadBalancerService.deletePolicy(policy.id)
      .then((res) => {
        setViewModal(false)
        setActiveAnimation(false)
        navigate(`/load-balancer-listener/${lbId}/${listenerId}/policies`)
        viewAlert({
          severity:'info',
          message:`${t("POLICY_DELETED_SUCCESSFUL")}`
        })
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }

  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: () => navigate(`edit`),
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        setAlertTitle(t("POLICY"))
        openModal('delete')
        setAlertSubTitle([policy])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow onClick={() => navigate(`/load-balancer-listener/${lbId}/${listenerId}/policies`)} title={t("GO_BACK")}/>
      <HeadWrapper>
        <InstanceName>
          {policy?.name || policy.id} 
        </InstanceName>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              active={options}
              title={`${t("MENU")}`}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <InstanceHeadInfo>
        <StateWrapper>
        <GreenDot active={policy.operating_status}/>
          <Greentext active={policy.operating_status}>
          {handleStatusName(policy.operating_status, t)}
          </Greentext>
        </StateWrapper>
      </InstanceHeadInfo>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview policy={policy}/>}/>
        <Route path='/rules' element={<Rules/>}/>
      </Routes>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={instance}
        instanceId={policy.id}
        // instance={instance}
        loading={loading}
      />
    </Container>
  )
}

export default PolicyPage