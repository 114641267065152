import styled from 'styled-components'
import { BodyDefault} from '../../../../components/typography/styled';

export const NetworkText = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
  &:hover{
    cursor: pointer;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 50px 0px;
  position: relative;
  gap:10px;
  box-sizing: border-box;
`
export const InfoTopBlocks = styled.div`
  width: 100%;
  display: flex;
  gap:10px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;
  gap:10px;
`

export const FloatingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

`
type ActiveProp = {
  active?:boolean,
  isSmall?:any
  ref?:any
}
export const PropsContainer = styled.div<ActiveProp>`
  box-sizing: border-box;
  padding: 4px 0px;
  right:13px;
  top: 45px;
  z-index: 10;
  position: absolute;
  background-color: ${({theme}) => theme.palette[10]};
  width: 240px;
  border:${({theme}) => `1px solid ${theme.grey[350]}`};
  border-radius:4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.1);
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  opacity:${({active}) => active ? '1' : '0'};
  transform-origin: top right;
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
`