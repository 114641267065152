import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, TableSkeleton, TopProgress } from "../../components";
import { useAuth } from "../../context/user.context";
import { icons } from "../../icons";
import { InstanceName } from "../Account-settings/styles";
import {Container} from './styles'
import { timeConverterMMDDYYHHMM } from "../../utils/dates";
import { useNavigate } from "react-router-dom";
import { clientService } from "../../api";
import { useUi } from "../../context/ui.context";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';

const Clients:FC = () => {
  const {user, updateUser} = useAuth()
  const [rows, setRows] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)

  const [paginationData, setPaginationData] = useState<any>()
  const [t] = useTranslation()

  const updateList = () => {
    setPageLoading(true)
    clientService.getClientList({
      page:page||1,
      ordering,
      pageSize:pageSize||10
    })
    .then((res) => {
      let data = res.data.objects.map((client:any) => {
        return {
        ...client,
        id:client.id,
        long_name:client.long_name,
        navigate:`${client.id}`,
        roles:client.roles.length > 0 ? client.roles.map((role:any) => role.display_name) : '',
        date_created:timeConverterMMDDYYHHMM(client.date_created),
      }})
      setPaginationData(res.data)
      setRows(data)
      setPageLoading(false)
    })
    .catch((e) => {
      if(e?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:e?.response?.data?.detail
        })
      }
      setPageLoading(false)
    })
  }
  const {viewAlert} = useUi()
  const navigate = useNavigate()
  const canDissociate = (id:any) => {
    clientService.canDissociate(id)
    .then((res) => {
      if(res.data.can_dissociate === false){
        viewAlert({
          severity:'info',
          message:`${t("CAN'T_DISSOCIATE_CLIENT")}`
        })
      } else {
       
        clientService.dissociateFromClient(id)
        .then((res) => {
          if(res.data.detail){
            viewAlert({
              severity:'info',
              message:res.data.detail
            })
          }
          updateUser()
        })
        .catch((e) => {
          if(e?.response?.data?.detail){
            viewAlert({
              severity:'error',
              message:e?.response?.data?.detail
            })
          }
        })
      }
    })
    .catch((e) => {
      if(e?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:e?.response?.data?.detail
        })
      }
    })
  }

  useEffect(() => {
    updateList()
  },[page, ordering, pageSize])
  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (item:any) => navigate(`${item.id}/client-edit`),
      divider:false
    },
    {
      title:t("DISSOCIATE"),
      id:'4',
      onClick:(item:any) => canDissociate(item.id),
      icon:icons.MinusDefault,
      divider:false
    },
  ]

  const columns = [
    {
      title:t("ID"),
      key:"id"
    },
    {
      title:t("NAME"),
      key:'long_name'
    },
    {
      title:t("YOUR_ROLES"),
      key:"roles",
      withBackground:true,
    },
    {
      title:t("CREATED_AT"),
      key:"date_created"
    },
    {
      title:"",
      key:'props'
    }
  ]
  return(
    <Container>
      <TopProgress loading={pageLoading}/>
      <InstanceName>
        {t("CLIENTS")}
      </InstanceName>
      {pageLoading ?
        <TableSkeleton
            rows={1}
            columns={columns?.length || 5}
            columnsData={columns}
          />
      :
        <Table
          columns={columns}
          rows={rows}
          hideCheckbox
          settings={items}
          paginationData={paginationData}
          updateData={updateList}
          loading={pageLoading}
        />
      }
    </Container>
  )
}

export default Clients
