import styled, {keyframes} from "styled-components";
import { BodySmall, VerySmall } from "../typography/styled";

type LegendProp = {
  second?:boolean
}
type ActiveOverlay = {
  active: boolean
}

export const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#4671F6 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#4671F6);
  mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
  animation:s3 1s infinite linear;

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}
`
export const HeadWrapper = styled.div`
  display: flex;
  width:100%;
  justify-content: space-between;
`

export const MetricSettingsTitle = styled(BodySmall)`
  color:${({theme}) => theme.palette[700]};
`
export const RadioWrapper = styled.div`
  display: flex;
  /* width: 316px; */
  position: relative;
`

export const LeftSideData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height:100%; */
  max-width: 50px;
  min-width: 50px;
  /* margin-right:10px ; */
  /* margin-bottom:30px; */
`
type LabelProps = {
  orientation?:boolean;
}
export const LabelMetric = styled.div<LabelProps>`
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  /* right: ${({orientation}) => orientation ? '' : '0px'};
  left: ${({orientation}) => orientation ? '0px' : ''}; */
  /* width: 200px; */
  z-index:999 !important;
  background-color: ${({theme}) => theme.palette[10]};
  justify-content:center;
  border-radius: 4px;
  white-space: nowrap;
  border:  ${({theme}) => `1px solid ${theme.grey[350]}`};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  /* box-shadow:0px 2px 10px rgba(0, 0, 0, 0.1); */
`
export const MetricData = styled(MetricSettingsTitle)`
  text-transform: uppercase;
  white-space: nowrap;
  margin-top: 15px;
  /* flex:1 1; */

`
export const ChartWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  position: relative;
  width: 100%;
`
export const Overlay = styled.div<ActiveOverlay>`
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 2px);
  height: 100%;
  z-index: 10;
  display: ${({active}) => active ? 'flex' : 'none'};
  background: ${({active, theme}) => active ? theme.isDark ? 'rgba(36, 36, 36, 0.7)' : 'rgba(255, 255, 255, 0.7)' : ''};
`
export const AreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  height:100%;
`

export const BottomData = styled.div`
  display: flex;
  flex-direction: row;
  /* padding-right: 49px; */
  /* height: 100%; */
  margin-left: 60px;
  /* margin: 20px 0px; */
  /* margin-right: 100px; */
  /* border-top: ${({theme}) => `1px solid ${theme.grey[350]}`}; */
  justify-content:space-between;
`
type AreaTypes = {
  data?:any
}

export const Area = styled.div<AreaTypes>`
  height: 100%;
  z-index: 2;
  background: linear-gradient(180deg, rgba(70, 113, 246, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
  clip-path: ${({data}) => `polygon(
    ${data},
    100% 100%,
    0% 100%
  )`};
`

export const Area2 = styled.div<AreaTypes>`
  height: 100%;
  /* position: absolute; */
  z-index: 9992;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
  clip-path: ${({data}) => `polygon(
    ${data},
    100% 100%,
    0% 100%
  )`};
`
export const GraphWrapper = styled.div`
  display:flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height:100%;
`
export const AreaTop = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;

  background: ${({theme}) => `repeating-linear-gradient(
      transparent,
      transparent,
      transparent 34px,
      ${theme.grey[350]} 35px
      )`};
  &>div{
    height:100%;
    margin-top:35px;
    position: relative;
  }
`
type ChartDotTypes = {
  second?:boolean
}
export const ChartDot = styled.div<ChartDotTypes>`
  width: 4px;
  height:4px;
  border-radius: 100%;
  position: absolute;
  background-color: ${({theme, second}) => second ? theme.isDark ? theme.base.black : '#081D42' : '#4671F6'};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 2;
  transform:translateX(-50%) translateY(50%);
  &>div{
    position: relative;
    z-index:-2;
  }
  &:hover{
    cursor:pointer;
    /* width: 8px;
    height:8px; */
  }
`

export const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 7px;
  height: 23px;
  background: ${({theme}) => theme.palette[200]};
  border-radius: 4px;
  margin-right:8px;
`
export const TraficText = styled(BodySmall)`
  font-weight: 500;
  color:${({theme}) => theme.palette[900]};
  text-transform:uppercase;
  margin-left: 8px;
`
type NameProps = {
  inactive?: boolean,
}
export const TraficName = styled(BodySmall)<NameProps>`
  color:${({theme}) => theme.palette[700]};
  text-decoration: ${({inactive}) => inactive ? 'line-through' : ''};
`

export const TraficDot = styled.div<LegendProp>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 6px;
  background-color: ${({second, theme}) => second ? theme.isDark ? theme.base.black : '#081D42' : '#4671F6'};
`

export const LegendWrapper = styled.div`
  display: flex;
  margin-top:17px;
`

export const VerticalBorder = styled.div`
  position: absolute;
  height: 85%;
  z-index: 1;
  bottom: 35px;
  /* width: 1px; */
  border-left: ${({theme}) => `1px solid ${theme.grey[350]}`};
  /* top:35px; */
  /* height:245px; */
`