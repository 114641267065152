import React, { FC, useState, useEffect, useRef } from 'react'
import {
  Container,
  StyledIconColored,
  Title,
  StyledChevron,
  DropOptions,
  DropOptionsTitle,
  DropItemWrapper,
  DropOptionItem,
  Divider,
  SoonText,
  BetaText
} from './styles'
import Chevron from './chevron.svg'
import { useUi } from '../../context/ui.context'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../context/user.context'
type TabProps = {
  defaultIcon: any,
  hoverIcon: any,
  title:string,
  dropOptions?:boolean,
  active:boolean,
  dropOptionsArr?:any[],
  isSmall:boolean,
  activeText:boolean,
  isDown?:boolean,
  onClick?:any,
  isFirst?:boolean,
  isSoon?:boolean,
  href:string,
  viewIfNoClient?:boolean,
  isBeta?:boolean,
}

const SideBarTab:FC<TabProps> = (props) => {
  const {
    defaultIcon,
    hoverIcon,
    title,
    dropOptions,
    dropOptionsArr,
    active,
    isSmall,
    activeText,
    isDown,
    onClick,
    isFirst,
    isSoon,
    href,
    viewIfNoClient,
    isBeta
  } = props
  const [hover, setHover] = useState<boolean>(false)
  const [activeDrop, setActiveDrop] = useState<boolean>(false)
  const dropOptionsRef:any = useRef()
  const dropOptionsParrentRef:any = useRef()
  const {user} = useAuth()
  const {themeDark} = useUi()
  const [t] = useTranslation()
  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // },[]);

  //   const handleClickOutside:any = (event:any) => {
  //     if (dropOptionsRef  && !dropOptionsRef.current.contains(event.target) && !dropOptionsParrentRef.current.contains(event.target)){
  //       setActiveDrop(false)
  //     }
  //   }


  return(
    <Container
      style={isFirst ? {marginTop:'76px'} : {}}
      isSmall={isSmall}
      to={href}
      disabled={(!user.user.active_client && !viewIfNoClient) || isSoon}
      // onClick={dropOptions ? () => {
      //   setActiveDrop(!activeDrop)
      //   if(onClick && (viewIfNoClient && user.user.active_client)){
      //     onClick()
      //   } 
      // } : onClick}
      active={!isSoon && active}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={dropOptionsParrentRef}
      isSoon={isSoon || (!viewIfNoClient && !user.user.active_client)}
    >
      <div style={{display:'flex'}}>
        <StyledIconColored src={(hover && !isSoon) && (viewIfNoClient && user.user.active_client) || (active && !isSoon)  ? themeDark ? defaultIcon : hoverIcon : defaultIcon}/>
        <Title isSmall={isSmall}>
          {!activeText ? '' : title}
          {isSoon && activeText ? <SoonText>{isBeta ? "BETA" : t("SOON")}</SoonText> : ''}
          {isBeta && !isSoon && activeText && <BetaText>BETA</BetaText>}
        </Title>
      </div>
        {/* {dropOptions ? 
          <StyledChevron active={active} src={Chevron}/>
          :null
        } */}
        {/* <DropOptions id='drop-list' isDown={isDown} ref={dropOptionsRef} isSmall={isSmall} active={activeDrop}>
          <DropOptionsTitle>
            Category
          </DropOptionsTitle>
          <DropItemWrapper>
            <DropOptionItem>
              Overviews
            </DropOptionItem>
          </DropItemWrapper>
          <DropItemWrapper>
            <DropOptionItem>
              Snapshots
            </DropOptionItem>
          </DropItemWrapper>
          <DropItemWrapper>
            <DropOptionItem>
              Backups
            </DropOptionItem>
          </DropItemWrapper>
          <Divider/>
          <DropOptionsTitle>
            Category2
          </DropOptionsTitle>
          <DropItemWrapper>
            <DropOptionItem>
            Item 4
            </DropOptionItem>
          </DropItemWrapper>
          <DropItemWrapper>
            <DropOptionItem>
            Distribute traffic between multiple Instance PHP web application
            </DropOptionItem>
          </DropItemWrapper>
          <DropItemWrapper>
            <DropOptionItem>
            Item 6
            </DropOptionItem>
          </DropItemWrapper>
        </DropOptions> */}
    </Container>
  )
}

export default SideBarTab