import styled from "styled-components";
import { HeadLine1, HeadLine3, BodyDefault, VerySmall, BodySmall, BodyMedium } from "../../../components/typography/styled";
import { statusColor } from "../../../utils/statusColor";


export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 30px 50px 20px;
  box-sizing: border-box;
  position: relative;
  align-items: flex-start;
`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  /* margin-top: 20px; */
  margin-bottom: 20px;
  max-width:500px;
  word-break: break-word;
`

export const RowHeader = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  width:100%;
  margin-top:10px;
`
export const BodyTicket = styled.div`
  display:flex;
  flex-direction:row;
  gap:30px;
  width: 100%;
  margin-top: 10px;
  justify-content:space-between;
  align-items:flex-start;
`

export const TicketOverflow = styled.div`
  display: flex;
  flex-direction:column;
  /* height: 700px; */
  /* padding-right:20px; */
  box-sizing: border-box;
  gap:20px;
  /* width: 100%; */
`
export const TicketColumn = styled.div`
  display: flex;
  flex-direction:column;
  width: 100%;
`


export const EnterDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding:20px;
  box-sizing: border-box;
  width: 100%;
  align-items:flex-start;
  background: ${({theme}) => theme.palette[200]};
  border-radius: 8px;
  gap:10px;
  margin-top:20px;
`