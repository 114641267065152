import styled from "styled-components";
import { VerySmall, HeadLine3 } from "../typography/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 405px;
  height: 245.02px;
  background-color: ${({theme}) => theme.grey[300]};
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: bottom right;
  position: relative;
  justify-content: flex-end;
  padding: 15px 15px 25px 20px;
  box-sizing: border-box;
  &:hover{
    cursor: pointer;
    background-color: ${({theme}) => theme.grey[350]};
    transition: 0.2s background-color ease-in-out;
  }
`

export const VideoDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
`
export const PlayBackground = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: ${({theme}) => theme.palette[10]};
  margin-bottom: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const VideoTitle = styled(VerySmall)`
  color:${({theme}) => theme.grey[600]};
  text-transform: uppercase;

`
export const VideoSubTitle = styled(HeadLine3)`
  color: ${({theme}) => theme.palette[850]};
  margin-top:6px;
`