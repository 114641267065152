import styled from 'styled-components'
import { BodyDefault } from '../../typography/styled'
type ModalProp = {
  sure?:boolean
}

export const IconBackground = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100px;
  height: 100px;
  background: linear-gradient(180deg, #D1D1D1 0%, #F8F5F5 100%);
  border-radius: 60px;
  margin-bottom: 14px;
`

export const ModalWrapper = styled.div<ModalProp>`
  display: flex;
  flex-direction: column;
  
  padding: 10px 10px 20px;
  width: 360px;
  /* height: ${({sure}) => sure ? '448px' : '366px'}; */
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette[10]};
  border-radius: 8px;
`

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const BodyModal = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  padding:0px 10px;
`
export const GreyText = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
  margin:8px 0px 20px;
`

export const Icon = styled.img`
  &:hover{
    cursor: pointer;
  }
`

export const WarningBlock = styled.div`
  display: flex;
  box-sizing: border-box;
  padding:6px 8px;
  gap:6px;
  background-color: #FFF5D2;
  width: 320px;
  /* height: 72px; */
  border-radius: 4px;
  margin-top:10px;
`