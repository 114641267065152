import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,

} from './styles'
import { Button, ModalVariables, Table } from '../../../../../components'
import { icons } from '../../../../../icons'
import { randomNameGenerator } from '../../../../../utils/create-random-name'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { networkService, routerService } from '../../../../../api'
import { useNavigate, useParams } from 'react-router-dom'
import { useUi } from '../../../../../context/ui.context'
import { compareArrays } from '../../../../../utils/findIntersection'
import { BodyDefault } from '../../../../../components/typography/styled'

type InterfacesProps = {
  interfaces:any[],
  external?:boolean,
  updateFunc:() => void,
}
const Interfaces:FC<InterfacesProps> = ({interfaces, external, updateFunc}) => {
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const navigate = useNavigate()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [editedSubnet, setEditedSubnet] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const {idRouter} = useParams()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [deletedRouter, setDeletedRouter] = useState<any>()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(interfaces, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const _deleteInterfaces = async () => {
    setLoading(true)
    try{
      if(allChecked) {
        let promises:any[] = []
        await interfaces.map((item:any) => {
          let data = {
            id:idRouter,
            interface_id:item.id,
            options: "remove_interface"
          }
          //@ts-ignore
          let promise = routerService.deleteInterface(idRouter, data)
          promises.push(promise)
        })
        await Promise.all(promises)
        await updateFunc()
        await setAllChecked(false)
        await setChecked([])
      } else if (checked?.length > 0){
        let promises:any[] = []
        await checked.map((item:any) => {
          let data = {
            id:idRouter,
            interface_id:item.id,
            options: "remove_interface"
          }
          //@ts-ignore
          let promise = routerService.deleteInterface(idRouter, data)
          promises.push(promise)
        })
        await Promise.all(promises)
        await updateFunc()
        await setChecked([])
      } else if (deletedRouter) {
        let data = {
          id:idRouter,
          interface_id:deletedRouter.id,
          options: "remove_interface"
        }
        //@ts-ignore
        await routerService.deleteInterface(idRouter, data)
        await updateFunc()
      }
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      updateFunc()
      setPageLoading(false)
      setLoading(false)
    } finally {
      setViewModal(false)
      updateFunc()
      setActiveAnimation(false)
    }
  }
  const items = [
    {
      title:t("DELETE"),
      id:'4',
      onClick:(id:any) => {
        openModal('delete')
        setAlertTitle(t("INTERFACE"))
        setAlertSubTitle([id])
        setDeletedRouter(id)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
              

  const _addInterface = async (props:any) => {
    try{
      const interfaceData = {
        // ip:props.ip ? props.ip : props.cidr.indexOf('0/24') !== -1 ? props.cidr.replace('0/24', '1') : props.cidr.replace('/24', ''),
        ip: '',
        subnet:props.sub
      }
      if(idRouter){
        const {data} = await routerService.addInterfaceToRouter(idRouter, interfaceData)
        setLoading(false)
        setViewModal(false)
        setActiveAnimation(false)
        if(updateFunc){
          updateFunc()
        }
        viewAlert({
          severity:'info',
          message:data?.detail
        })
      }
    } catch (e:any) {
      if(e?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:e?.response?.data?.detail
        })
      } else if (e?.response?.data?.ip){
        viewAlert({
          severity:'error',
          message:e?.response?.data?.ip
        })
      }
      setLoading(false)
    }
    
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = (props:any) => {
    if(modalType === 'delete'){
      _deleteInterfaces()
      setLoading(true)
    } else {
      setLoading(true)
      _addInterface(props)
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const Columns = [
    {
      title:t("NAME"),
      key:'id'
    },
    {
      title:t("SUBNET"),
      key:'cidr'
    },
    {
      title:t("GATEWAY_IP"),
      key:'gateway_ip'
    },
    {
      title:t("FIXED_IP"),
      key:'ip_version'
    },
    {
      title:'',
      key:'props'
    },
  ]
  return(
    <Container>
      <TopInfoWrapper>
        {external ? null :
          <div style={{display:'flex'}}>
            <Button
              variant="primary"
              size="display"
              onClick={() => {
              //   setEditedSubnet({
              //     name:randomNameGenerator({name:'subnet', findArr:[]}),
              //     // cidr:`10.0.${subnets.length + 2}.0/24`,
              //     ip_version:'4',
              //     setGatewayIp: "no-gateway",
              //     enable_dhcp:true,
              //     dns_nameservers:[],
              //     host_routes:[],
              //     // network_id:res.data.id,
              //     _id:randomNameGenerator({name:'', findArr:[]}),
              //     network_mask:0,
              //     network_address_source:'manually',
              //     allocation_pools:[],
            
              //     //data we don't have fields
              //     ipv6_ra_mode:null,
              //     ipv6_address_mode:null,
              //     gateway_ip:'',
              // })
                openModal('add-interface')}}
              title={t("ADD_INTERFACE")}
              customStyles={{marginBottom:'20px'}}
            />
            <Button
              onClick={() => {
                setAlertTitle(t("INTERFACES"))
                setAlertSubTitle(allChecked ? interfaces : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 6px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
      </TopInfoWrapper>
      {interfaces?.length > 0 ?
        <Table
          columns={Columns}
          rows={interfaces}
          // hideCheckbox
          settings={items}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          checked={checked}
          setChecked={editChecked}
          // paginationData={paginationData}
          updateData={updateFunc}
          hideOther
        />
      :
        <BodyDefault>
          {t("NO_INTERFACES")}
        </BodyDefault>
      }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        dataToEdit={editedSubnet}
        loading={loading}
      />
    </Container>
  )
}
export default Interfaces

