import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { HeadLine3 } from "../../typography/styled";
import Select from "../../select";
import { kubernetesService } from "../../../api";
import { handleOsIcons } from "../../../hooks/useOsIcons";
import TopProgress from "../../TopBarProgress";
import { useNavigate } from "react-router-dom";
import { useUi } from "../../../context/ui.context";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
  loadingComplete?:boolean;
}  

const UpgradeCluster:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    loading,
    loadingComplete,
  } = props
  const [t] = useTranslation()
  const [kuberVersions, setKuberVersions] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [initialValues, setInitialValues] = useState<any>({
    cluster_version:'',
  })
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  const navigate = useNavigate()
  const {
    viewAlert
  } = useUi()
  const getVersions = () => {
    setPageLoading(true)
    kubernetesService.getVersions()
    .then((kuberVersions) => {
      setKuberVersions(kuberVersions?.data.map((version:any) => {
        return {
          // images: version.images.map((item:any) => {
          //   return {
          //     ...item,
          //     value: item.image_name,
          //     label: item.name,
          //     icon: handleOsIcons(item.os_distro.toLowerCase())
          //   }
          // } ),
          label:version.version,
          value:version.version,
  
        }
      }))
      setPageLoading(false)
    })
    .catch((error:any) => {
      if(error?.response?.status >= 500){
        navigate('/kubernetes')
        viewAlert({
          severity:'error',
          message:`${t("CLUSTER_SERVICE_UNAVAILABLE")}`
        })
        setPageLoading(false)
        closeModalCancel()
      } else {
        viewAlert({
          severity:'error',
          message:error?.response?.data?.message || error?.response?.data?.details || `${t("ERROR_OCCURRED")}`
        })
        setPageLoading(false)
        closeModalCancel()
      }
    })

  }
  useEffect(() => {
    getVersions()
    setInitialValues({
      cluster_version:instance.cluster_version,
    })
  },[instance])
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      cluster_version: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {alertTitle}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Select
          required
          data={kuberVersions}
          selectedValue={values['cluster_version']}
          onChange={(e) => {
            handleChange('cluster_version')(e)}}
          title={t('VERSION_KUBERNETES_TITLE')}
          fieldName='cluster_version'
          {...formikProps}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            disabled={values['cluster_version'] === instance?.cluster_version}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("CHANGE_VERSION")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default UpgradeCluster