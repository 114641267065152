import styled from "styled-components";
import { BodyDefault, HeadLine2 } from "../../typography/styled";
import SVG from "react-inlinesvg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  width: 760px;
  min-height: 404px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

export const Header = styled.div`
  background-color: ${({theme}) => '#4671F6'};
  padding: 10px;
  /* height: 134px; */
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  box-sizing: border-box;
`

export const HeadContent = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0px 20px 20px 20px;
`
export const TitleWrapper = styled.div`
  display:flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
  margin-left: 30px;
`

export const HeadTitle = styled(HeadLine2)`
  color:${({theme}) => '#FFFF'};
`
export const HeadText = styled(BodyDefault)`
  color:${({theme}) => '#FFFF'};
`

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 3px;
`

export const CloseIconWhite = styled(SVG)`
  width: 20px;
  height: 20px;
  & path {
    stroke: ${({theme}) => '#FFFF'};
  };
  &:hover{
    cursor: pointer;
  }
`
export const Body = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background-color: ${({theme}) => theme.palette[10]};
  height: 100%;
  padding:20px 30px 30px 30px;
  gap:20px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 10px;
`