import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useParams } from "react-router-dom";
import { BackArrow, Button, ModalVariables, Tabs, TopProgress, Typography } from "../../../components";
import MenuButton from "../../../components/button/menu-button";
import DropOptions from "../../../components/header/components/drop-options";
import { PropsContainer } from "../../../components/table/styles";
import { BodyDefault } from "../../../components/typography/styled";
import { icons } from "../../../icons";
import {
  Container,
  HeaderWrapper,
  OperationStatusContainer,
  OperationDot,
  OperationStatus,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  InstanceHeadInfo,
  InstanceDot,
  InstanceStatus,
  InstanceType,
  NavWrapper,
  StyledIcon,
  StyledIconMenu,
  StyledConsoleIc
} from './styles'
import Overview from "./overview";
import SystemLogs from './system-log'
import Metrics from "./metrics";
import { bareMetalService, instanceService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { handleStatusName } from "../../../utils/handle-status-name";
import { compareArraysTags } from "../../../utils/findIntersection";
import ConsoleIc from './console-ic.svg'
import RebootIc from './ic-reboot.svg'
import RestartIc from './ic-restart.svg'



const BaremetalPage:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [options, setOptions] = useState(false)
  const {baremetalId} = useParams()
  const [bareMetal, setBareMetal] = useState<any>(null)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>('')
  const [statusServer, setStatusServer] = useState<any>()

  const _updateInstance = () => {
    if(baremetalId) {

      bareMetalService.getServerById(baremetalId)
      .then((res) => {
        setBareMetal(res.data)
        bareMetalService.getStatusServer(baremetalId)
        .then((res:any) => {
          setStatusServer(res.data)
        })
        .catch((err:any) => {
          console.log('errgetStatus', err);
        })
      })
      .catch((err) => {
        viewAlert({
          severity: 'error',
          message: err?.response?.data?.detail || t("ERROR_OCCURRED"),
        })
        navigate('/bare-metal')
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
  }
  // clg
  useEffect(() => {
    _updateInstance()
    const interval = setInterval(() => {
      _updateInstance()
    },5000)
    return () => {
      clearInterval(interval)
    }
  },[baremetalId])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },
    {
      title:t("METRICS"),
      slug:'metrics',
      id:'2'
    },
    {
      title:t("SYSTEM_LOG"),
      slug:'system-log',
      id:'3'
    },
  ])

  const itemsDefault = [
    {
      title:t("REINSTALL"),
      id:'1',
      icon:icons.Rebuild,
      onClick: () => {
        openModal('reinstall-baremetal')
      },
      divider:false
    },
    {
      title:t("RESTART"),
      id:'2',
      icon:RestartIc,
      onClick: () => {
        setAlertTitle(`${t("RESTART_SERVER")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_RESTART_SERVER")} ${bareMetal?.name}?`)
        openModal('universal')
      },
      divider:false
      
    },
    {
      title:t("REBOOT"),
      id:'3',
      icon:RebootIc,
      onClick: () => {
        setAlertTitle(`${t("REBOOT_SERVER")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_REBOOT_SERVER")} ${bareMetal?.name}?`)
        openModal('universal')
      },
      divider:false
    },
    {
      title:t("EDIT"),
      id:'4 ',
      icon:icons.Edit,
      onClick: () => {
        openModal('edit-baremetal')
      },
      divider:false
    },
    {
      title:t("ASSIGN_FLOATING_IP"),
      onClick:() => {
        setAlertTitle(bareMetal.name)
        openModal('baremetal-add-floating')
      },
      id:'5',
      icon:icons.PlusDefault,
      divider:false
    },
    {
      title:t("DISSOCIATE_IP"),
      onClick:() => {
        setAlertTitle(bareMetal.name)
        openModal('baremetal-dissociate-floating')
      },
      id:'6',
      icon:icons.MinusDefault,
      divider:false
    },
    {
      title:t("DELETE"),
      id:'8',
      icon:icons.DeleteIcon,
      divider:false,
      onClick:() => {
        setAlertTitle(`${t("BARE_METAL")}`)
        setAlertSubTitle([bareMetal])
        openModal('delete')
      },
    },
  ]
  
  const [activeTab, setActiveTab] = useState<string>()
  const location = useLocation()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }


  const closeModal = async (values: any) => {
    let res:any; // объявляем переменную res
    try {
      setLoading(true);
      console.log('values', values);
      switch (modalType) {
        case 'delete':
          res = await bareMetalService.deleteServerById(bareMetal.id)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          navigate('/bare-metal')
          break;
        case 'reinstall-baremetal':
          res = await bareMetalService.reinstallServer(bareMetal.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'edit-baremetal':
          res = await bareMetalService.editServer(bareMetal.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'baremetal-add-floating':
          res = await bareMetalService.addFloating(bareMetal.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'baremetal-dissociate-floating':
          res = await bareMetalService.removeFloating(bareMetal.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'universal':
          setLoading(true);
  
          if (alertTitle === `${t('STOP_SERVER')}`) {
            res = await bareMetalService.changePowerStatus(bareMetal.id, 'stop');
          } else if (alertTitle === `${t('START_SERVER')}`) {
            res = await bareMetalService.changePowerStatus(bareMetal.id, 'start');
          } else if (alertTitle === `${t('REBOOT_SERVER')}`) {
            res = await bareMetalService.changePowerStatus(bareMetal.id, 'reboot');
          } else if (alertTitle === `${t('RESTART_SERVER')}`) {
            res = await bareMetalService.changePowerStatus(bareMetal.id, 'restart');
          }
  
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        default:
          break;
      }
  
      _updateInstance();
      setViewModal(false);
      setActiveAnimation(false);
      setModalType('')
    } catch (e: any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const _getConsoleUrl = () => {
    setLoading(true)
    bareMetalService.getConsoleUrl(baremetalId)
    .then((res) => {
      window.open(res.data.access_url)
    })
    .catch((err) => {
      if(err?.response?.data?.detail){
        viewAlert({
          severity: 'error',
          message: err.response?.data?.detail,
        })
      } else {
        viewAlert({
          severity: 'error',
          message: `${t("ERROR_GET_CONDOLE_LINK")}`,
        })
      }
      // setPageLoading(false)
    })
    .finally(() => {
      setLoading(false)

    })
  }
  
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={!pageLoading && loading}/>
      <BackArrow title={t("BACK_TO_BARE_METAL")} onClick={() => navigate('/bare-metal')}/>
      <HeadWrapper>
        <InstanceName>
          {bareMetal?.name || bareMetal?.id}
        </InstanceName>
        <RightHeadContainer>
          {statusServer?.step !== 3 &&
            <Button
              variant='secondary'
              size='small'
              customStyles={{marginRight:'10px',whiteSpace:'nowrap'}}
              onClick={() => openModal('baremetal-status')}
              title={t("REINSTALL_STATUS")}
            />
          }
          <MenuButton 
            data-test-id='reboot-button'
            onClick={_getConsoleUrl}
            customStyles={{marginRight:'10px', width:'100%!important'}}>
            <StyledConsoleIc src={ConsoleIc}/>
            <Typography customStyles={{marginLeft:'6px'}} variant='small'>
              Console
            </Typography>
          </MenuButton>
          <MenuButton
            data-test-id='stop-button'
            onClick={() => {
              if(bareMetal?.state?.power_status === 'online'){
                setAlertTitle(`${t("STOP_SERVER")}`)
                setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_STOP_SERVER")} ${bareMetal?.name}?`)
                openModal('universal')
              } else {
                setAlertTitle(`${t("START_SERVER")}`)
                setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_STOP_SERVER")} ${bareMetal?.name}?`)
                openModal('universal')
              }
            }}
            customStyles={{marginRight:'10px'}}>
            <StyledIconMenu src={icons.Power}/>
          </MenuButton>
          {/* <MenuButton 
            data-test-id='reboot-button'
            onClick={() => {
              setAlertTitle(`${t("REBOOT_SERVER")}`)
              setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_REBOOT_SERVER")} ${bareMetal?.name}?`)
              openModal('universal')
            }}
            customStyles={{marginRight:'10px'}}>
            <StyledIconMenu src={icons.Restart}/>
          </MenuButton> */}
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              isFill
              size='small'
              icon={icons.More}
              title={t("MENU")}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={itemsDefault}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <InstanceHeadInfo>
        <OperationStatusContainer>
          <OperationDot active={bareMetal?.state?.power_status || 'active'}/>
          <OperationStatus active={bareMetal?.state?.power_status || 'active'}>
            {handleStatusName(bareMetal?.state?.power_status || 'active', t)}
          </OperationStatus>
        </OperationStatusContainer>
        <InstanceType>
          {t("TYPE")}
        </InstanceType>
        <BodyDefault>
          {bareMetal?.type}
        </BodyDefault>
      </InstanceHeadInfo>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path="*" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview updateData={_updateInstance} bareMetal={bareMetal} />}/>
        <Route path='/system-log' element={<SystemLogs />}/>
        <Route path='/metrics' element={<Metrics />}/>
        
      </Routes>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        alertSubTitle={alertSubTitle}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        instance={{...bareMetal, step:statusServer?.step}}
        instanceId={bareMetal?.id}
        loading={loading}
        dataToEdit={bareMetal}
      />
    </Container>
  )
}

export default BaremetalPage
