import ApiClient from "./apiClient";


const sshService = {
  getSshKeys:(pageSize:any=10, ordering:any='-date_created') => ApiClient.get(`pkm?page_size=${pageSize}&ordering=${ordering}`),
  generateKeyPair: () => ApiClient.get('pkm/get_generated_key_pair'),
  createSsh:(data:any) => ApiClient.post('pkm', data),
  editSsh:(data:any) => ApiClient.put(`pkm/${data.id}`, data),
  deleteSsh:(id:string) => ApiClient.delete(`pkm/${id}`),
  getSshById:(id:string) => ApiClient.get(`pkm/${id}`)
}

export default sshService