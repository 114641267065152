import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BackArrow,
  Input,
  Select,
  Button,
  RightInformation,
  Counter,
  CheckBox
} from '../../../../components'
import {
  Container,
  TitleBig,
  CreateContainer,
  FormWrapper,
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { securityService } from '../../../../api'
import { useUi } from '../../../../context/ui.context'


const CreateRule:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [groups, setGroups] = useState<any[]>([])
  const {groupId} = useParams()
  const {viewAlert} = useUi()
  const [initialValues, setInitialValues ] = useState<any>({
    description:'',
    direction:SourceTypes[0].value,
    ruleType:ruleTypes[0].value,
    remote_ip_prefix:'0.0.0.0/0',
    ethertype:'IPv4',
    remoteSource:remoteSources[0].value,
    port:'',
    protocol:0,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  useEffect(() => {
    securityService.getGroupsForRule()
    .then((res:any) => {
      setGroups(res.data.objects.map((group:any) => {
        return{
          value:group.id,
          label:group.name
        }
      }))
      setInitialValues({
        ...initialValues,
        securityGroup:res.data.objects?.[0]?.group.id
      })
    })
  }, [])
  const onSubmit = (values:any) => {
    setLoading(true);
    const data = {
      ...values,
      security_group_id:groupId
    }
    if(data.protocol === 0 || data.protocol === 'null'){
      data.protocol = null;
    }
    if (values.port?.length > 0) {
      const ports = data.port.split(':');
      if (ports.length > 1) {
        data.port_range_min = ports[0];
        data.port_range_max = ports[1];
        data.port_range = data.port
      } else {
        data.port_range_min = data.port_range_max = ports[0];
      }
    }
    if (data.remoteSource === 'ip') {
      delete data.remote_group_id;
      delete data.ethertype;
      data.remote_ip_prefix = values.remote_ip_prefix;
    } else {
      data.remote_ip_prefix = '';
      data.remote_group_id = values.securityGroup;
      data.ethertype = values.ethertype;
    }

    if (ruleTypes.find((rule:any) => rule.value === values.ruleType)?.hasOwnProperty('protocol')) {
      data.protocol = ruleTypes.find((rule:any) => rule.value === values.ruleType)?.protocol;
    }
    // pre defined port range
    if (ruleTypes.find((rule:any) => rule.value === values.ruleType)?.hasOwnProperty('port_range')) {
      if (ruleTypes.find((rule:any) => rule.value === values.ruleType)?.port_range?.indexOf(':') !== -1) {
        const portRangeSplit:any = ruleTypes.find((rule:any) => rule.value === values.ruleType)?.port_range?.split(':');
        data.port_range_min = portRangeSplit[0];
        data.port_range_max = portRangeSplit[1];
      } else {
        data.port_range_min = ruleTypes.find((rule:any) => rule.value === values.ruleType)?.port_range;
        data.port_range_max = ruleTypes.find((rule:any) => rule.value === values.ruleType)?.port_range;
      }
    }
    if (values.ruleType === 'ICMP' && values.icmp_type.length > 0) {
      if (data.icmp_type) {
        data.port_range_min = data.icmp_type;
      }
    }
    if (values.ruleType === 'ICMP' && values.icmp_code.length > 0) {
      if (data.icmp_code) {
        data.port_range_max = data.icmp_code;
      }
    }

    delete data.allPorts; // we don't need to send this
    delete data.securityGroup; // we don't need to send this
    delete data.remote; // we don't need to send this
    delete data.port; // we don't need to send this
    delete data.remoteSource; // we don't need to send this
    delete data.ruleType; // we don't need to send this
    if(groupId){
      securityService.createRule(groupId, data)
      .then((res:any) => {
        setLoadingComplete(true)
        setTimeout(() => {
          setLoading(false)
          setLoadingComplete(false)
          if(res?.data?.detail){
            viewAlert({
              severity:'info',
              message:res.data.detail
            })
          }
          navigate(-1)
        },1000)
      })
      .catch((err:any) => {
        setLoadingComplete(false)
        setLoading(false)
        if(err?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:err.response.data.detail
          })
        } else if(err?.response?.data){
          setErrors(err?.response?.data)
        }
      })
    }
    console.log('data', data);
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      description: yup.string(),
      remote_ip_prefix: yup.string().when("remoteSource", {
        is: (remoteSource:any) => remoteSource === 'ip',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
      direction:yup.string().required(`${t('REQUIRED_FIELD')}`),
      ruleType:yup.string().required(`${t('REQUIRED_FIELD')}`),
      remoteSource:yup.string().required(`${t('REQUIRED_FIELD')}`),
      port: yup.string().when("ruleType", {
        is: (ruleType:any) => ruleType === 'TCP' || ruleType === 'UDP' || ruleType === 'other',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`).matches(/^(\d{1,5}:\d{1,5}|\d{1,5})$/, 'Invalid port range')
      }),
      allPorts:yup.string(),
      //  yup.string()
      // .when("ruleType", {
      //   is: (ruleType:any) => (ruleType === 'TCP' || ruleType === 'UDP' || ruleType === 'other') && ,
      //   then: yup.string().required()
      // }),
      protocol: yup.string().when("ruleType", {
        is: (ruleType:any) => ruleType === 'other',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
      ethertype:yup.string().when("remoteSource", {
        is: (remoteSource:any) => remoteSource === 'security',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
      securityGroup:yup.string().when("remoteSource", {
        is: (remoteSource:any) => remoteSource === 'security',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
      icmp_code:yup.string().when("ruleType", {
        is: (ruleType:any) => ruleType === 'ICMP',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),

      icmp_type:yup.string().when("ruleType", {
        is: (ruleType:any) => ruleType === 'ICMP',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
      
      
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  useEffect(() => {
    if(values['allPorts'] === 'on'){
      handleChange('port')('1:65535')
    }
  },[values['allPorts']])
  return(
    <CreateContainer>
      <Container>
        <BackArrow
          customStyles={{width:"266px"}}
          onClick={() => navigate(-1)}
          title={`${t("BACK_TO")} Rules`}
        />
        <TitleBig>{t("CREATE_NEW_RULE")}</TitleBig>
        <FormWrapper>
          <Select
            toolTip={true}
            data={ruleTypes}
            selectedValue={values['ruleType']}
            onChange={(e) => {
              handleChange('ruleType')(e)}}
            title={t("RULE_PROTOCOL")}
            customStyles={{width:'360px'}}
          />
          {values['ruleType'] === 'ICMP' ?
            <div style={{display:'flex', justifyContent:"space-between", width:'360px'}}>
              <Counter
                maxCount={255}
                hideItems
                title={t("PROTOCOL")}
                value={values['icmp_code']}
                onChange={(v:any) => {
                  handleChange('icmp_code')(v.toString())}}
              />
              <Counter
                maxCount={255}
                hideItems
                title={t("PROTOCOL")}
                value={values['icmp_type']}
                onChange={(v:any) => {
                  handleChange('icmp_type')(v.toString())}}
              />
            </div>
          : null}
          {values['ruleType'] === 'other' ?
            <Counter
              maxCount={255}
              hideItems
              title={t("PROTOCOL")}
              value={values['protocol']}
              onChange={(v:any) => {
                handleChange('protocol')(v.toString())}}
            />
          :  null}
          {
            values['ruleType'] === 'TCP' ||
            values['ruleType'] === 'UDP' ||
            values['ruleType'] === 'other' ? 
            <>
              <Input
                fieldName='port'
                toolTip={true}
                readOnly={values['allPorts'] === 'on'}
                toolTipText={'Enter one port or port range as such: port_min:port_max'}
                title={t("PORT_OR_RANGE")}
                customStyles={{ width:'360px' }}
                placeholder=''
                {...formikProps}
              />
              <CheckBox
                title={t("ALL_PORTS")}
                value={values['allPorts'] === 'on'}
                //ToDo
                onChange={() => {
                  if(values['allPorts'] === 'on'){
                    handleChange('allPorts')('off')
                  } else {
                    handleChange('allPorts')('on')
                  }
                  
                }}
              />
            </>
          : null}
          <Select
            toolTip={false}
            data={SourceTypes}
            selectedValue={values['direction']}
            onChange={(e) => handleChange('direction')(e)}
            title={t("DIRECTION")}
            customStyles={{width:'360px'}}
          />
          <Select
            toolTip={false}
            data={remoteSources}
            selectedValue={values['remoteSource']}
            onChange={(e) => handleChange('remoteSource')(e)}
            title={t("REMOTE")}
            customStyles={{width:'360px'}}
          />
          {values['remoteSource'] === 'ip' ?
            <Input
              fieldName='remote_ip_prefix'
              title={t("CIDR")}
              customStyles={{ width:'360px' }}
              placeholder=''
              {...formikProps}
            />
          :
            <>
              <Select
                toolTip={false}
                data={groups}
                selectedValue={values['securityGroup']}
                onChange={(e) => handleChange('securityGroup')(e)}
                title={t("REMOTE_SECURITY_GROUP")}
                customStyles={{width:'360px'}}
              />
              <Select
                toolTip={false}
                data={etherTypes}
                selectedValue={values['ethertype']}
                onChange={(e) => handleChange('ethertype')(e)}
                title={t("ETHER_TYPE")}
                customStyles={{width:'360px'}}
              />
            </>
          }

          <Input
            isTextArea
            fieldName='description'
            title={t("DESCRIPTION")}
            customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
            placeholder=''
            {...formikProps}
          />
        </FormWrapper>
        <div style={{display:'flex', marginTop:'40px'}}>
          <Button
            variant="primary"
            size="display"
            customStyles={{marginRight:"10px"}}
            onClick={handleSubmit}
            title={t("CREATE")}
            loading={loading}
            loadingComplete={loadingComplete}
          />
          <Button
            variant="stroke"
            size="display"
            onClick={() => navigate(-1)}
            title={t("CANCEL")}
          />
        </div>
      </Container>
      <RightInformation
        customStyles={{height:'168px', marginTop:'101px'}}
        info={t("CREATE_RULE_INFO")}
      />
    </CreateContainer>
  )
}

export default CreateRule

const SourceTypes = [
  {value:"ingress", label:'Ingress - Incoming'},
  {value:"egress", label:'Egress - Outgoing'}
]
const etherTypes = [
  {value:"IPv4", label:'IPv4'},
  {value:"IPv6", label:'IPv6'}
]
const remoteSources = [
  {value:"ip", label:'IP Addresses'},
  {value:"security", label:'Security group'}
]
const ruleTypes = [
  {
    label:'Any',
    value: 'null'
  },
  {
    label:'Custom TCP rule',
    fields: ['port'],
    protocol: 'TCP',
    value: 'TCP',
  },
  {
    label:'Custom UDP rule',
    protocol: 'UDP',
    fields: ['port'],
    value: 'UDP',
  },
  {
    label:'Custom ICMP rule',
    fields: ['icmp_type', 'icmp_code'],
    value: 'ICMP',
    protocol: 'ICMP'
  },
  {
    label:'Other protocol',
    fields: ['protocol', 'port'],
    value:'other',
    port_required: false,
  },
  {
    label:'All TCP',
    value:'all_tcp',
    port_range: '1:65535',
    protocol: 'TCP'
  },
  {
    label:'All UDP',
    value:'all_upd',
    port_range: '1:65535',
    protocol: 'UDP'
  },
  {
    label:'All ICMP',
    value:'all_icpm',
    protocol: 'ICMP'
  },
  {
    label:'DNS',
    value:'DNS',
    protocol: 'UDP',
    port_range: '53'
  },
  {
    label:'FTP',
    value:'FTP',
    protocol: 'TCP',
    port_range: '21'
  },
  {
    label:'HTTP',
    value:'HTTP',
    protocol: 'TCP',
    port_range: '80'
  },
  {
    label:'HTTPS',
    value:'HTTPS',
    protocol: 'TCP',
    port_range: '443'
  },
  {
    label:'IMAP',
    value:'IMAP',
    protocol: 'TCP',
    port_range: '143'
  },
  {
    label:'IMAPS',
    value:'IMAPS',
    protocol: 'TCP',
    port_range: '993'
  },
  {
    label:'LDAP',
    value:'LDAP',
    protocol: 'TCP',
    port_range: '389'
  },
  {
    label:'MS SQL',
    value:'MS SQL',
    protocol: 'TCP',
    port_range: '1433'
  },
  {
    label:'MySQL',
    value:'MySQL',
    protocol: 'TCP',
    port_range: '3306'
  },
  {
    label:'POP3',
    value:'POP3',
    protocol: 'TCP',
    port_range: '110'
  },
  {
    label:'POP3S',
    value:'POP3S',
    protocol: 'TCP',
    port_range: '995'
  },
  {
    label:'RDP',
    value:'RDP',
    protocol: 'TCP',
    port_range: '3389'
  },
  {
    label:'SFTP',
    value:'SFTP',
    protocol: 'TCP',
    port_range: '115'
  },
  {
    label:'SMTP',
    value:'SMTP',
    protocol: 'TCP',
    port_range: '25'
  },
  {
    label:'SMTPS',
    value:'SMTPS',
    protocol: 'TCP',
    port_range: '465'
  },
  {
    label:'SSH',
    value:'SSH',
    protocol: 'TCP',
    port_range: '22'
  },
  {
    label:'WHOIS',
    value:'WHOIS',
    protocol: 'TCP',
    port_range: '43'
  }
]