import React, { FC, useEffect, useState, useRef } from "react";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  Divider,
  TopInfoWrapper
} from "./styles";
import { useTranslation } from 'react-i18next'
import { InfoBlock, BackArrow, Button, Table, TopProgress, ModalVariables } from '../../../components'
import { useNavigate, useParams } from "react-router-dom";
import { icons } from "../../../icons";
import DropOptions from "../../../components/header/components/drop-options";
import { PropsContainer } from "../../../components/table/styles";
import { securityService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { timeConverterMMDDYYHHMM } from "../../../utils/dates";


const GroupPage:FC = () => {
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [options, setOptions] = useState(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [securityGroup, setSecurityGroup] = useState<any>()
  const {groupId} = useParams()
  const navigate = useNavigate()
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()

  const [loading, setLoading] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [deletedRule, setDeletedRule] = useState<any>()
  const [editedGroup, setEditedGroup] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [deletedGroup, setDeletedGroup] = useState<any>()

  const editChecked = (id:any) => {
    const founded = checked.find((item:any) => item.id === id?.id)
    if(allChecked){
      setAllChecked(!allChecked)
    }
    if(founded) {
      setChecked((prev:any) => {
        return prev.filter((item:any) => item.id !== id?.id)
      })
    } else {
      setChecked([...checked, id])
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const _getGroup = () => {
    if(groupId){
      securityService.getGroupById(groupId)
      .then((res) => {
        setSecurityGroup({
          ...res.data,
          security_group_rules:res.data.security_group_rules.map((rule:any) => {
            return {
              ...rule,
              protocol:!rule.protocol ? "Any" : rule.protocol,
              ports: !rule.port_range_min && !rule.port_range_max ? 'Any' : rule.port_range_min === rule.port_range_max ? rule.port_range_max : `${rule.port_range_min}-${rule.port_range_max}`,
            }
          })
        })
        setPageLoading(false)
      })
      .catch((err) => {
        viewAlert({
          severity:"error",
          message:`${t("UNABLE_TO_FIND_SECURITY_GROUP")}`
        })
        navigate("/security-groups")
      })
    }
  }

  useEffect(() => {
    _getGroup()
  },[])
  const specifications = [
    {
      title:t("ID"),
      id:'1',
      value:securityGroup?.id,
      copy:true
    },
    {
      title:t("PROJECT"),
      id:'2',
      value:securityGroup?.project,
      copy:true
    },
    {
      title:t("PLACEMENT_REGION"),
      id:'3',
      value:securityGroup?.region
    },
    {
      title:t("CREATED_AT"),
      id:'4',
      value:timeConverterMMDDYYHHMM(securityGroup?.created_at)
    },
    {
      title:t("ASSOCIATED_INSTANCES"),
      id:'5',
      options:securityGroup?.associated_instances.map((item:any) => item.name),
      url:true,
      left:true
    },
  ]
  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (router:any) => {
        navigate(`edit`);
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        openModal('delete')
        setAlertTitle(t("SECURITY_GROUP"))
        setAlertSubTitle([{id:groupId}])
        setDeletedGroup({id:groupId})
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
const _deleteGroups = async () => {
    setLoading(true)
    try{
      if(allChecked) {
        let promises:any[] = []
        await securityGroup.security_group_rules.map((item:any) => {
          const data = {
            rule_id:item.id
          }
          //@ts-ignore
          let promise = securityService.deleteRule(groupId, data)
          promises.push(promise)
        })
        await Promise.all(promises)
        await _getGroup()
        await setAllChecked(false)
      } else if (checked?.length > 0){
        let promises:any[] = []
        await checked.map((item:any) => {
          const data = {
            rule_id:item.id
          }
          //@ts-ignore
          let promise = securityService.deleteRule(groupId, data)
          promises.push(promise)
        })
        await Promise.all(promises)
        await _getGroup()
        await setChecked([])
      } else if (deletedRule) {
        //@ts-ignore
        await securityService.deleteRule(groupId, {rule_id: deletedRule.id})
        await _getGroup()
      }
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      _getGroup()
      setPageLoading(false)
      setLoading(false)
    } finally {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setEditedGroup(null)
    setActiveAnimation(false)
  }
  const closeModal = async (props:any) => {
    if (deletedGroup) {
      await setLoading(true)
      await securityService.deleteGroup(deletedGroup.id)
      await setLoading(false)
      await navigate(-1)
      await setDeletedGroup(null)
    }else if(modalType === 'delete'){
      _deleteGroups()
    }
  }
  
  const Columns = [
    {
      title:t("DIRECTION"),
      key:'direction',
      checkBox: true,
    },
    {
      title:t("TYPE"),
      key:'ethertype'
    },
    {
      title:t("PROTOCOL"),
      key:'protocol'
    },
    {
      title:t("PORTS"),
      key:'ports'
    },
    {
      title:t("CIDR"),
      key:'remote_ip_prefix'
    },
    {
      title:'',
      key:'props'
    },
  ]


  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow onClick={() => navigate('/security-groups')} title={t("BACK_TO_SECURITY_GROUPS")}/>
      <HeadWrapper>
        <InstanceName>
          {securityGroup.name}
        </InstanceName>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              isFill
              title={t("MENU")}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>

      <InfoBlock
          data={specifications}
          title={null}
          customStyles={{marginTop:'10px', flex:'auto 1', marginRight:'10px'}}
        />
        <Divider/>
        <TopInfoWrapper>
        <div style={{display:'flex'}}>
          <Button
            variant="primary"
            size="display"
            onClick={() => navigate('create-rule')}
            title={t("CREATE_RULE")}
            customStyles={{marginBottom:'20px'}}
          />
          <Button
            onClick={() => {
              setAlertTitle(t("RULES"))
              setAlertSubTitle(allChecked ? securityGroup.security_group_rules : checked)
              openModal('delete')
            }}
            variant='icon'
            test_id='button-delete'
            size='display'
            disabled={!allChecked && checked?.length === 0 }
            customStyles={{margin:'0px 6px 0px 6px'}}
          >
            <img src={icons.DeleteIcon}/>
          </Button>
        </div>
      </TopInfoWrapper>
        <Table
          columns={Columns}
          rows={securityGroup.security_group_rules}
          settings={[
            {
              title:t("DELETE"),
              id:'4',
              onClick:(rule:any) => {
                openModal('delete')
                setAlertTitle(t("RULE"))
                setAlertSubTitle([rule])
                setDeletedRule(rule)
              },
              icon:icons.DeleteIcon,
              divider:false
            }
          ]}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          checked={checked}
          setChecked={editChecked}
          hideOther
        />
        <ModalVariables
          modalType={modalType}
          viewModal={viewModal}
          activeAnimation={activeAnimation}
          closeModal={closeModal}
          closeModalCancel={closeModalCancel}
          alertTitle={alertTitle}
          loading={loading}
          alertSubTitle={alertSubTitle}
          // dataToEdit={editedGroup}
        />
    </Container>
  )
}

export default GroupPage
