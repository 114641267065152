import ApiClient from "./apiClient";


const bareMetalService = {
  getListMyServers: () => ApiClient.get(`servers/`),
  getServerById: (id:string|number) => ApiClient.get(`servers/${id}/`),
  deleteServerById: (id:string|number) => ApiClient.delete(`servers/${id}/`),
  createServer:(data:any) => ApiClient.post(`servers/`,data),
  getServers: () => ApiClient.get(`servers/free_servers/`),
  getOs: () => ApiClient.get(`servers/list_of_os/`),
  changePowerStatus:(id:any, status:string) => ApiClient.get(`servers/${id}/${status}/`),
  editServer:(id:string, data:any) => ApiClient.patch(`servers/${id}/`, data),
  reinstallServer:(id:any, data:any) => ApiClient.post(`servers/${id}/reinstall/`, data),
  getFreeIps:(id:any) => ApiClient.get(`servers/${id}/floating_ips/`),
  addFloating:(id:any, data:any) => ApiClient.post(`servers/${id}/floating_ip/`,data),
  removeFloating:(id:any, data:any) => ApiClient.delete(`servers/${id}/floating_ip/`,{data:data}),
  getLogs:(id:any) => ApiClient.get(`servers/${id}/logs/`),
  getStatusServer:(id:any) => ApiClient.get(`servers/${id}/reinstall_status/`),
  getConsoleUrl:(id:any) => ApiClient.get(`servers/${id}/console/`),
  getMetrics:(id:any, data:any) => ApiClient.post(`servers/${id}/metrics/`, data),
  getOsToResque:() => ApiClient.get(`servers/list_of_rescue/`),
  getListOfDisk:() => ApiClient.get(`servers/list_of_disk/`),
  getBreakDown:(id:any) => ApiClient.get(`servers/${id}/network/`),
  getPrices:() => ApiClient.get(`servers/prices/`),
}

export default bareMetalService