import Keycloak from 'keycloak-js';


const devConfig = {
  url:process.env.REACT_APP_KEYCLOAK_DEV_URL,
  realm: 'Onecloud',
  clientId:'onecloud_main'
}

const prodConfig = {
  url:process.env.REACT_APP_KEYCLOAK_PROD_URL, 
  realm: 'onecloud',
  clientId:'whithout_secret'
}

//@ts-ignore
const keycloak = new Keycloak(window.location.hostname === 'console.ocplanet.cloud' ? prodConfig : devConfig);

export default keycloak;

