import React, { FC, useEffect, useState } from "react";
import { useAuth } from "../../../../context/user.context";
import {
  Container,
  Pie,
  DiagramWrapper,
  DiagramName,
  Description,
  PieBorder
} from "./styles";
import {formatQuotasRam, formatQuotasStorage} from '../../../../utils/sizes'
import { useTranslation } from "react-i18next";
import Skeleton from 'react-loading-skeleton'
import styled, { useTheme } from "styled-components";

type PieProp = {
  item:any
}
const SkeletonWrapper = styled(Skeleton)`
  width: 82px;
  height: 82px;
  border-radius:100%;
  margin-bottom:20px;
  /* background-color: ${({theme}) => theme.palette[200]}; */
`;

const Diagrams:FC = () => {
  const {quotas, quotasLoading} = useAuth()
  const [t] = useTranslation()
  const theme:any = useTheme()
  const data = [
    {
      title:t("INSTANCES"),
      desc:`${t("USED")} ${quotas?.core?.instances?.in_use} ${t("OF")} ${quotas?.core?.instances?.limit}`,
      id:'1',
      total:quotas?.core?.instances?.limit,
      used:quotas?.core?.instances?.in_use
    },
    {
      title:t("VCPUS"),
      desc:`${t("USED")} ${quotas?.core?.cores?.in_use} ${t("OF")} ${quotas?.core?.cores?.limit}`,
      id:'2',
      total:quotas?.core?.cores?.limit,
      used:quotas?.core?.cores?.in_use
    },
    {
      title:t("RAM"),
      desc:`${t("USED")} ${formatQuotasRam(quotas?.core?.ram?.in_use)} ${t("OF")} ${formatQuotasRam(quotas?.core?.ram?.limit)}`,
      id:'3',
      total:quotas?.core?.ram?.limit,
      used:quotas?.core?.ram?.in_use
    },
    {
      title:t("STORAGE"),
      desc:`${t("USED")} ${formatQuotasStorage(quotas?.volume?.gigabytes?.in_use)} ${t("OF")} ${formatQuotasStorage(quotas?.volume?.gigabytes?.limit)}`,
      id:'4',
      total:quotas?.volume?.gigabytes?.limit,
      used:quotas?.volume?.gigabytes?.in_use
    },
    {
      title:t("VOLUMES"),
      desc:`${t("USED")} ${quotas?.volume?.volumes?.in_use} ${t("OF")} ${quotas?.volume?.volumes?.limit}`,
      id:'5',
      total:quotas?.volume?.volumes?.limit,
      used:quotas?.volume?.volumes?.in_use
    },
    {
      title:t("IP"),
      desc:`${t("USED")} ${quotas?.network?.floatingip?.used} ${t("OF")} ${quotas?.network?.floatingip?.limit}`,
      id:'6',
      total:quotas?.network?.floatingip?.limit,
      used:quotas?.network?.floatingip?.used
    }
  ]
  if(quotasLoading) return (
    <Container>
      {[1,2,3,4,5,6].map(() => (
        <DiagramWrapper>
          <SkeletonWrapper
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
          />
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={135} style={{marginBottom:"12.5px"}} height={15}
          />
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={135} height={15}
          />
        </DiagramWrapper>
      ))}
    </Container>
  )
  if(quotas?.length === 0) return null
  return (
    <Container>
      {data.map((item:any) => (
        <PieComponent key={item.id} item={item}/>
      ))}
    </Container>
  )
}


export default Diagrams

const PieComponent:FC<PieProp> = ({item}) => {
  const computed:number = +(item.used / item.total * 100).toFixed()
  const [animateComputed, setAnimateComputed] = useState<number>(0)
  useEffect(() => {
    const interval = setInterval(() => {
      if(animateComputed < computed){
        setAnimateComputed((prev:any) => prev + 1);
      } else if(animateComputed > computed) {
        setAnimateComputed((prev:any) => prev - 1);
      } else {
        clearInterval(interval);
      }
    },0);
    return () => clearInterval(interval);
  }, [animateComputed, item]);
  
  return (
    <DiagramWrapper  key={item.id}>
      <Pie used={animateComputed} >
        {computed > 0 && computed < 100 ? 
        <PieBorder transform={360 / 100 * animateComputed} ></PieBorder>
        : null}
        </Pie>
      <DiagramName data-test-id={item?.id === '1' ? 'instance-quota-container' : 'other'}>
        {item.title}
      </DiagramName>
      <Description>
        {item.desc}
      </Description>
    </DiagramWrapper>
  )
}