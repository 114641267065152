import styled from "styled-components";
import { HeadLine2 } from "../components/typography/styled";


type ActiveProp = {
  isSmall?:boolean
}

export const PageWrapper = styled.div<ActiveProp>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const RouterWrapper = styled.div`
  background-color: ${({theme}) => theme.base.white};
  display: flex;
  justify-content: flex-start;

`

export const Link = styled(HeadLine2)`
  &:hover{
    cursor: pointer;
  }
  color:${({theme}) => theme.primary[600]};
  border-radius:4px;
  margin: 10px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height:100vh;
`
export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`