import React, { FC, useState } from "react";
import { BodyDefault } from "../typography/styled";
import {
  ToolTipView,
  Container,
  SpinnerLds
} from './styles'

const LoaderDashed: FC<any> = ({ title }) => {
  const [hover, setHover] = useState<boolean>(false)
  return (
    <Container
      style={{
        width:"20px",
        height:'20px',
        marginLeft:'10px'
      }}
      data-test-id='loader-dashed'
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <div style={{position:'relative'}}>
      <SpinnerLds>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
      </SpinnerLds>
      {hover &&
        <ToolTipView>
          <BodyDefault>
            {title}
          </BodyDefault>
        </ToolTipView>
      }
      </div>
    </Container>
  )
}


export default LoaderDashed