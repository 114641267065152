import styled from 'styled-components'
type CheckBoxProps = {
  active?:boolean,
  disabled?:boolean,
}
type ContainerProps = {
  customStyles?:any
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  ${({customStyles}) => customStyles}
  &:hover{
    cursor:pointer;
  }
`

export const StyledCheckBox = styled.input<CheckBoxProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance:none;
  min-width: 16px;
  max-width: 16px;
  min-height:16px;
  max-height:16px;
  border-radius: 4px;
  margin: 0px 10px 0px 0px;
  border:${({theme}) => `1px solid ${theme.grey[400]}`};
  &:checked{
    background-repeat: no-repeat;
    background-size: 100%;
    border: 1px solid #4671F6;

  }
  &:disabled{
    background-color: ${({theme}) => `${theme.grey[200]}`};
    border-color: ${({theme}) => `${theme.grey[400]}`};
  }
  &:hover{
    cursor:pointer;
  }
`



export const CheckBoxImg = styled.img`
  margin-right: 10px;
  min-width:16px;
  max-width:16px;
  height: 16px;
  &:hover{
    cursor: pointer;
  }
`