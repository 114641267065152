import styled from 'styled-components'

export const Container = styled.div`
  padding-top:20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap:20px;
`

export const Headers = styled.div`
  display: flex;
  flex-direction: column;
  gap:20px;
  margin-top:15px;
`

export const HeadersWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

`

export const HeadersColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width:50%;
  gap:10px;
`
export const Divider = styled.div`
  border-bottom: 1px solid #E8E8E8;
  width:100%;
  height:1px;
`