import React, { FC, useEffect, useState } from "react";
import {Container, FormWrapper} from './styles'
import { Button, Input, Select, Toggle, TopProgress } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { instanceService, loadBalancerService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { Title } from "../../../Instances/styles";




const CreateMonitor:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {poolId, lbId, memberId} = useParams()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [admin, setAdmin] = useState<boolean>(true)
  const {viewAlert} = useUi()
  const [initialValues, setInitialValues ] = useState<any>({

  })

  const [member, setMember] = useState<any>()
  const [backup, setBackup] = useState<boolean>(false)
  const getCreateOptions = async () => {
      // setPageLoading(true)
    try{
      if(memberId && poolId){
        const memberRes:any = await loadBalancerService.getMemberById(memberId, poolId)
        await setMember(memberRes.data)
        await setInitialValues({
          address: memberRes?.data?.address,
          protocol_port: memberRes?.data?.protocol_port,
          weight: memberRes?.data?.weight,
          monitor_address: memberRes?.data?.monitor_address || '',
          monitor_port: memberRes?.data?.monitor_port || '',
          name: memberRes?.data?.name,
        })
        await setAdmin(memberRes?.data?.admin_state_up)
        await setBackup(memberRes?.data?.backup)
      }
    } catch (err:any) {
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    } finally {
      setPageLoading(false)
    }
  }
  useEffect(() => {
    getCreateOptions()
  },[])



  const onSubmit = (values:any) => {
    if(!poolId) return;
    setLoading(true)
    const data:any = {
      ...member,
      ...values,
      backup:backup,
      admin_state_up:admin,
    }
    if(values.monitor_port === '') {
      delete data.monitor_port
    }
    if(values.monitor_address === '') {
      delete data.monitor_address
    }
    loadBalancerService.editMember(poolId, data)
    .then((res) => {
      setLoadingComplete(true)
        setTimeout(() => {
          navigate(`/load-balancer-pool/${lbId}/${poolId}/members`)
          viewAlert({
            severity:'info',
            message:`${t("MEMBER_EDITED_SUCCESS")}`,
          })
        }, 1500)
    })
    .catch((err) => {
      setErrors(err?.response?.data)
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
      setLoading(false)
    })
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      address: yup.string().required(`${t('REQUIRED_FIELD')}`),
      protocol_port: yup.number().required(`${t('REQUIRED_FIELD')}`),
      weight: yup.string().required(`${t('REQUIRED_FIELD')}`),
      monitor_address: yup.string(),
      monitor_port: yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      <Title>{t("EDIT_FOR")} «{member?.name || member?.id}»</Title>
      <FormWrapper>
        <Input
          fieldName={`name`}
          toolTip={false}
          title={t("NAME")}
          placeholder=''
          {...formikProps}
        />
        <Input
          fieldName={`address`}
          required
          toolTip={false}
          title={t("IP_ADDRESS")}
          readOnly
          placeholder=''
          {...formikProps}
        />
        <Input
          required
          fieldName={`protocol_port`}
          toolTip={false}
          title={t("PORT")}
          placeholder=''
          readOnly
          {...formikProps}
          arrayFields={true}
        />
        <Input
          fieldName={`weight`}
          required
          toolTip={false}
          title={t("WEIGHT")}
          placeholder=''
          {...formikProps}
        />
        <Input
          fieldName={`monitor_address`}
          toolTip={false}
          title={t("MONITOR_ADDRESS")}
          placeholder=''
          {...formikProps}
        />
        <Input
          fieldName={`monitor_port`}
          toolTip={false}
          title={t("MONITOR_PORT")}
          type="number"
          placeholder=''
          {...formikProps}
        />
        <Toggle value={admin} onChange={() => setAdmin(!admin)} title={t("ADMIN_STATE_UP")}/>
        <Toggle value={backup} onChange={() => setBackup(!backup)} title={t("BACKUP")}/>
      </FormWrapper>
      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          loading={loading}
          loadingComplete={loadingComplete}
          title={t("SAVE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate(-1)}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default CreateMonitor