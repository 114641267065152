import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, ModalVariables, Tabs, TopProgress } from "../../components";
import MenuButton from "../../components/button/menu-button";
import DropOptions from "../../components/header/components/drop-options";
import { PropsContainer } from "../../components/table/styles";
import { icons } from "../../icons";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  InstanceHeadInfo,
} from './styles'
import {
  Members,
  HealthMonitors,
  Overview,
} from './components'
import { BodyDefault } from "../../components/typography/styled";
import { loadBalancerService } from "../../api";
import { useUi } from "../../context/ui.context";
import { handleStatusName } from "../../utils/handle-status-name";
import { GreenDot, Greentext, StateWrapper } from "../Instances/styles";
// import CreateLoadBalancer from "../create-page";

const LbPools:FC = () => {
  const navigate = useNavigate()
  const {poolId, lbId} = useParams()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const [lbPool, setLbPool] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [subnets, setSubnets] = useState<any>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [modalType, setModalType] = useState<any>('')
  const {viewAlert} = useUi()
  const updateLb = () => {
    // setPageLoading(true)
    if(poolId){
      loadBalancerService.getPoolById(poolId)
      .then((res) => {
        setLbPool(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
        // navigate('/not-found')
      })
    }
  }
  useEffect(() => {
    updateLb()
    const interval = setInterval(() => {
      updateLb()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },
    {
      title:t("HEALTH_MONITORS"),
      slug:'health-monitors',
      id:'2'
    },
    {
      title:t("MEMBERS"),
      slug:'members',
      id:'3'
    },
  ])
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(!lbId) return ;
    if(modalType === 'delete'){
      setLoading(true);
      loadBalancerService.deletePool(lbId, lbPool.id)
      .then((res) => {
        updateLb()
        setViewModal(false)
        setActiveAnimation(false)
        navigate(`/load-balancers/${lbId}/pools`)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }

  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: () => navigate(`edit`),
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        setAlertTitle(t("POOL"))
        openModal('delete')
        setAlertSubTitle([lbPool])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow onClick={() => navigate(`/load-balancers/${lbId}/pools`)} title={t("GO_BACK")}/>
      <HeadWrapper>
        <InstanceName>
          {lbPool?.name || lbPool.id} 
        </InstanceName>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              active={options}
              title={`${t("MENU")}`}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <InstanceHeadInfo>
        <StateWrapper>
        <GreenDot active={lbPool.operating_status}/>
          <Greentext active={lbPool.operating_status}>
          {handleStatusName(lbPool.operating_status, t)}
          </Greentext>
        </StateWrapper>
      </InstanceHeadInfo>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview lbPool={lbPool}/>}/>
        <Route path='/health-monitors' element={<HealthMonitors/>}/>
        <Route path='/members' element={<Members/>}/>
      </Routes>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={instance}
        instanceId={lbPool.id}
        // instance={instance}
        loading={loading}
      />
    </Container>
  )
}

export default LbPools