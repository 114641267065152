import React, {FC, useState, useEffect} from "react";
import {
  Container,
  TitleContainer,
  TitleWrapper,
  InfoText,
  LinkWrapper,
  Link,
} from './styles'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { Button, ModalVariables, Table, TableSkeleton, TopProgress, Typography } from "../../../components";
import { objStorageService } from "../../../api";
import { timeConverterMMDDYYHHMM } from "../../../utils/dates";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../utils/display-price";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";
import { handleStatusName } from "../../../utils/handle-status-name";
import { useNavigate } from "react-router";
import LinkIc from './link.svg'
import { icons } from "../../../icons";
import { useUi } from "../../../context/ui.context";
import { formatFileSizeRounded, formatFileSize } from "../../../utils/sizes";
const BucketsPage:FC = () => {
  const theme:any = useTheme()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [paginationData, setPaginationData] = useState<any>()
  const [invoices, setInvoices] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [selectedBucket, setSelectedBucket] = useState<any>(null)

  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const navigate = useNavigate()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const getInvoices = () => {
    setLoading(true)
    objStorageService.getBuckets(
      {
        page:page || 1,
        page_size:pageSize || 10,
        ordering:ordering || '-issue_date',
      }
    )
    .then((res) => {
      setInvoices(res.data.objects.map((invoice:any) => {
        return {
          ...invoice,
          issue_date: timeConverterMMDDYYHHMM(invoice.issue_date),
          navigate:invoice.id.toString(),
          // status:<StatusText status={invoice.status}>{handleStatusName(invoice.status, t)}</StatusText>,
          total:formatCurrency(invoice.total, invoice.currency),
          usage:formatFileSize(invoice.usage)
        }
      }))
      setPaginationData(res.data)
      setPageLoading(false)
      setLoading(false)
    })
    .catch((e) => {
      console.log('error', e);
    })
  }
  useEffect(() => {
    getInvoices()
    const interval = setInterval(() => {
      getInvoices()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  const ColumnsInvoices = [
    {
      title:'Name',
      key:'name'
    },
    {
      title:t("VISIBILITY"),
      key:'access',
    },
    // {
    //   title:t("POLICY"),
    //   key:'policies1'
    // },
    {
      title:t("OBJECTS"),
      key:'num_objects'
    },
    {
      title:t("SIZE"),
      key:'usage'
    },
    {
      title:'',
      key:'props'
    }
  ]
  const props = [
    {
      title:t("BUCKET_SETTINGS"),
      id:'1',
      icon:icons.Edit,
      onClick: (bucket:any) => {
        navigate(`${bucket.id}/settings`)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'7',
      onClick:(bucket:any) => {
        openModal('delete')
        setAlertTitle(t("BUCKET"))
        setAlertSubTitle([bucket])
        setSelectedBucket(bucket)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setModalType('')
    setActiveAnimation(false)
  }
  const deleteBucket = async () => {
    setLoading(true);
    try {
      if (selectedBucket) {
        await objStorageService.deleteBucket(selectedBucket.id);
        // _updateImages();
      }
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      // setChecked([]);
    } finally {
      setViewModal(false);
      setActiveAnimation(false);
      setLoading(false);
    }
  }
  const closeModal = async (props?:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'add-folder':
          //@ts-ignore
          res = await objStorageService.addFolder(bucketId, {name:`${path}${props?.name}`})
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        // case 'upload-volume-to-image':
        //   res = await volumeService.uploadToImage(selectedVolume.id, values);
        //   if (res?.data?.detail) {
        //     viewAlert({
        //       severity: 'info',
        //       message: res.data?.detail,
        //     });
        //   }
        //   break;
        case 'delete':
          deleteBucket()
          break; 
      }
      setLoadingComplete(true)
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
        setViewModal(false)
        setActiveAnimation(false)
        setModalType('')
      },1000)
      if(modalType !== 'delete'){
        setViewModal(false)
        setActiveAnimation(false)
      }
    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      if(modalType !== 'delete'){
        setLoading(false);
      }
    }
  }

  if(pageLoading) return (
    <Container>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={125} style={{marginBottom:"5px"}} height={30}
      />
      <TableSkeleton
        rows={10}
        columns={5}
        // columnsData={Columns}
      />
    </Container>
  )
  return (
    <Container>
      <TitleContainer>
        <TitleWrapper>
          <Typography variant='h1'>
            {t("BUCKETS_TITLE")}
          </Typography>
          <InfoText>
            {t("BUCKETS_SUBTITLE")}
          </InfoText>
          <LinkWrapper>
            <Link>{t("BUCKETS_LINK")}</Link>    
            <img src={LinkIc} alt='LinkIc'/>      
          </LinkWrapper>
        </TitleWrapper>
        <Button
          variant="primary"
          size="display"
          customStyles={{whiteSpace:'nowrap'}}
          onClick={() => navigate('/object-storage/create')}
          title={`+ ${t("CREATE")}`}
          // loading={loading}
          // loadingComplete={loadingComplete}
        />
      </TitleContainer>
      {invoices?.length > 0 ?
        <Table
          columns={ColumnsInvoices}
          rows={invoices}
          // hideOther
          hideCheckbox
          settings={props}
          paginationData={paginationData}
        />
      :
        <Typography variant="body">
          {t("NO_BUCKETS")}
        </Typography>
      }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        instance={selectedBucket}
        // loading={loading}
        // loadingComplete={loadingComplete}
        alertSubTitle={alertSubTitle}
      />
    </Container>
  )
}

export default BucketsPage

