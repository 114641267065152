import React, { FC, useEffect, useState } from "react";
import {
  Container,
  TitleBig,
  NavWrapper,
  AlertsWrapper
} from './styles'
import { useTranslation } from "react-i18next";
import { NotificationBlock, Tabs } from "../../components";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import BucketsPage from "./buckets";
import BucketsPoliciesPage from "./bucket-policies";




const ObjectStoragePage:FC = () => {
  const [t] = useTranslation()
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("BUCKETS"),
      slug:'buckets',
      id:'1'
    },
    // {
    //   title:t("BUCKET_POLICIES"),
    //   slug:'bucket-policies',
    //   id:'2'
    // },
  ])

  const [activeTab, setActiveTab] = useState<string>()
  const location = useLocation()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  return(
    <Container>
      <TitleBig>{t("OBJECT_STORAGE")}</TitleBig>
      <AlertsWrapper>
        <NotificationBlock 
          severity="info"
          title={t("OBJECT_STORE_ALERT1")}
          message={t("OBJECT_STORE_ALERT1_SUB")}
        />
        <NotificationBlock 
          severity="warning"
          title={t("OBJECT_STORE_ALERT2")}
          message={t("OBJECT_STORE_ALERT2_SUB")}
        />
      </AlertsWrapper>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="buckets" />} /> 
        <Route path='/buckets' element={<BucketsPage />}/>
        {/* <Route path='/bucket-policies' element={<BucketsPoliciesPage />}/> */}
      </Routes>
    </Container>
  )
}

export default ObjectStoragePage;