import React, { FC, useEffect, useRef, useState } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import { floatingIpService } from "../../../api";
import TopProgress from "../../TopBarProgress";
import { useUi } from "../../../context/ui.context";
import { formatCurrency, formatCurrencyString } from "../../../utils/display-price";
import { useAuth } from "../../../context/user.context";
import FloatingBigIcon from './icon.svg'


type ModalProps = {
  closeModalCancel: () => void;
  closeModal: (values: any) => void;
  loading?: boolean;
  loadingComplete?: boolean;
}


const CreateFloatingIp: FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    loading,
    loadingComplete
  } = props
  const [t] = useTranslation()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loadingFloating, setLoadingFloating] = useState<boolean>(false)
  const [network, setNetwork] = useState<any[]>([])
  const [networks, setNetworks] = useState<any[]>([])
  const [region, setRegion] = useState<any[]>([])
  const [regions, setRegions] = useState<any[]>([])
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()
  const { user } = useAuth()
  useEffect(() => {
    floatingIpService.getCreateOptions()
    .then(res => {
      setNetworks(res.data.networks.map((net:any) => {
        return { value: net.id, label: net.name }
      }))
      setNetwork(res.data.networks[0]?.id)
      setRegions(res.data.regions.map((reg:any) => {
        return { value: reg.id, label: reg.id }
      }))
      setRegion(res.data.regions[0]?.id)
      
      let queries:any = {
        region: res.data.regions[0]?.id,
      }
  
      floatingIpService.getEstimate(queries)
      .then((resEstimate) => {
        setPageLoading(false)
        setPrice(formatCurrencyString(resEstimate.data.estimated_cost_display.split('-')[0]).hour)
        setPriceMonth(formatCurrencyString(resEstimate.data.estimated_cost_display.split('-')[1]).month)
      })
    })
    .catch(err => {});
  },[])
  const onSubmit = () => {
    closeModal({
      description:'',
      region,
      floating_network:network
    })
  }

  if (pageLoading) return <TopProgress loading={pageLoading} />
  return (
    <Container>
      <TopProgress loading={loadingFloating} />
      <HeadWrapper>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon} />
      </HeadWrapper>
      <img src={FloatingBigIcon}/>
      <HeadLine3 style={{ marginTop: '14px', marginLeft: '10px' }}>
          {`${t("CREATE_A_NEW_FLOATING_IP")}`}
      </HeadLine3>
      <BodyModal>
        <EstimateContainer>
          <EstimateTitle>
            {t("ESTIMATED_COST")}
          </EstimateTitle>
          {price ? 
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <div style={{display:'flex', alignItems:'flex-end'}}>
                  <EstimateSum>
                    {`${formatCurrency(price, user.user.clients?.[0]?.currency || 'UAH')}`}
                  </EstimateSum>
                  <PerHour>{t("HOURS")}</PerHour>
                </div>
                <div style={{display:'flex', alignItems:'flex-end'}}>
                  <EstimateSum>
                    {`${formatCurrency(priceMonth, user.user.clients?.[0]?.currency || 'UAH')}`}
                  </EstimateSum>
                  <PerHour>{t("MONTH")}</PerHour>
                </div>
              </div>
            :
            <PerHour>
              {t("SELECT_OPTIONS_TO_DISPLAY_PRICE")}
            </PerHour>
          }
        </EstimateContainer>
        <div style={{ display: "flex", gap: '6px', marginTop: '30px' }}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent: 'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent: 'center' }}
            onClick={onSubmit}
            loading={loading}
            loadingComplete={loadingComplete}
            title={t("CREATE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default CreateFloatingIp