const ShareIcon = ({styles}:{styles?:any}) => (
  <svg style={styles} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9747 15.7533L20.3613 11.8827C19.3813 10.9627 18.7573 9.68266 18.6907 8.24933L10.304 12.12C11.2853 13.0373 11.908 14.3187 11.9747 15.7533Z" fill="#C0D0EF"/>
    <path d="M20.3627 20.1187L11.976 16.248C11.9093 17.6813 11.2853 18.9627 10.3053 19.8827L18.692 23.7533C18.7587 22.3187 19.3813 21.0373 20.3627 20.1187Z" fill="#C0D0EF"/>
    <path d="M6.66668 10.6667C3.72134 10.6667 1.33334 13.0547 1.33334 16C1.33334 18.9453 3.72134 21.3333 6.66668 21.3333C9.61201 21.3333 12 18.9453 12 16C12 13.0547 9.61201 10.6667 6.66668 10.6667Z" fill="url(#paint0_linear_6436_49373)"/>
    <path d="M24 2.66667C21.0547 2.66667 18.6667 5.05467 18.6667 8.00001C18.6667 10.9453 21.0547 13.3333 24 13.3333C26.9453 13.3333 29.3333 10.9453 29.3333 8.00001C29.3333 5.05467 26.9453 2.66667 24 2.66667Z" fill="url(#paint1_linear_6436_49373)"/>
    <path d="M24 18.6667C21.0547 18.6667 18.6667 21.0547 18.6667 24C18.6667 26.9453 21.0547 29.3333 24 29.3333C26.9453 29.3333 29.3333 26.9453 29.3333 24C29.3333 21.0547 26.9453 18.6667 24 18.6667Z" fill="url(#paint2_linear_6436_49373)"/>
    <defs>
    <linearGradient id="paint0_linear_6436_49373" x1="6.66668" y1="10.6667" x2="6.66668" y2="21.3333" gradientUnits="userSpaceOnUse">
    <stop stop-color="#A7BCF1"/>
    <stop offset="1" stop-color="#5D83F5"/>
    </linearGradient>
    <linearGradient id="paint1_linear_6436_49373" x1="24" y1="2.66667" x2="24" y2="13.3333" gradientUnits="userSpaceOnUse">
    <stop stop-color="#A7BCF1"/>
    <stop offset="1" stop-color="#5D83F5"/>
    </linearGradient>
    <linearGradient id="paint2_linear_6436_49373" x1="24" y1="18.6667" x2="24" y2="29.3333" gradientUnits="userSpaceOnUse">
    <stop stop-color="#A7BCF1"/>
    <stop offset="1" stop-color="#5D83F5"/>
    </linearGradient>
    </defs>
  </svg>

)

const MoneyWithdrawIc = ({styles}:{styles?:any}) => (
  <svg style={styles} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.3333 26.6667H6.66666C4.45732 26.6667 2.66666 24.876 2.66666 22.6667V8.824C2.66666 7.83333 3.70932 7.188 4.59599 7.632L6.61599 8.64133C7.47199 9.06933 8.49199 9.00533 9.28799 8.47467L10.5213 7.65333C11.4173 7.056 12.584 7.056 13.48 7.65333L14.5213 8.348C15.4173 8.94533 16.584 8.94533 17.48 8.348L18.5213 7.65333C19.4173 7.056 20.584 7.056 21.48 7.65333L22.7133 8.476C23.5093 9.00667 24.5293 9.07067 25.3853 8.64267L27.4053 7.63333C28.2907 7.188 29.3333 7.83333 29.3333 8.824V22.6667C29.3333 24.876 27.5427 26.6667 25.3333 26.6667Z" fill="#C0D0EF"/>
    <path d="M23.9956 18.7111C23.9956 19.363 23.7882 19.8926 23.3734 20.3C22.9585 20.7 22.4178 20.9407 21.7511 21.0222V22H21.0067V21.0333C20.3845 20.9889 19.8511 20.8148 19.4067 20.5111C18.9623 20.2074 18.6437 19.7963 18.4511 19.2778L19.7734 18.5111C20.0104 19.1037 20.4215 19.4407 21.0067 19.5222V17.6C20.9993 17.6 20.9956 17.6 20.9956 17.6L20.9734 17.5889C20.7141 17.4926 20.503 17.4074 20.34 17.3333C20.1771 17.2593 19.9845 17.1519 19.7623 17.0111C19.5474 16.8704 19.3771 16.7259 19.2511 16.5778C19.1326 16.4222 19.0252 16.2259 18.9289 15.9889C18.84 15.7519 18.7956 15.4926 18.7956 15.2111C18.7956 14.5593 19.0067 14.037 19.4289 13.6444C19.8585 13.2519 20.3845 13.0259 21.0067 12.9667V12H21.7511V12.9889C22.7585 13.1074 23.4697 13.6296 23.8845 14.5556L22.5956 15.3111C22.4178 14.8667 22.1363 14.5963 21.7511 14.5V16.3556C22.44 16.6296 22.903 16.8481 23.14 17.0111C23.7104 17.4185 23.9956 17.9852 23.9956 18.7111ZM20.3178 15.2222C20.3178 15.3852 20.3697 15.5296 20.4734 15.6556C20.5771 15.7741 20.7548 15.8963 21.0067 16.0222V14.4667C20.7845 14.5111 20.6141 14.6 20.4956 14.7333C20.3771 14.8667 20.3178 15.0296 20.3178 15.2222ZM21.7511 19.5C22.2252 19.3963 22.4623 19.137 22.4623 18.7222C22.4623 18.537 22.403 18.3815 22.2845 18.2556C22.1734 18.1296 21.9956 18.0111 21.7511 17.9V19.5Z" fill="url(#paint0_linear_6436_49392)"/>
    <path d="M14.6746 21.0444C13.7043 21.0444 12.9413 20.6778 12.3858 19.9444C11.8376 19.2037 11.5635 18.2222 11.5635 17C11.5635 15.7778 11.8376 14.8 12.3858 14.0667C12.9413 13.3259 13.7043 12.9556 14.6746 12.9556C15.6524 12.9556 16.4154 13.3259 16.9635 14.0667C17.5117 14.8 17.7858 15.7778 17.7858 17C17.7858 18.2222 17.5117 19.2037 16.9635 19.9444C16.4154 20.6778 15.6524 21.0444 14.6746 21.0444ZM13.4969 18.9C13.7635 19.3296 14.1561 19.5444 14.6746 19.5444C15.1932 19.5444 15.5858 19.3259 15.8524 18.8889C16.1265 18.4518 16.2635 17.8222 16.2635 17C16.2635 16.1704 16.1265 15.537 15.8524 15.1C15.5858 14.663 15.1932 14.4444 14.6746 14.4444C14.1561 14.4444 13.7635 14.663 13.4969 15.1C13.2302 15.537 13.0969 16.1704 13.0969 17C13.0969 17.8296 13.2302 18.463 13.4969 18.9Z" fill="url(#paint1_linear_6436_49392)"/>
    <path d="M9.04444 13.1111H10.3778V20.8889H8.84444V14.7333L7.37778 15.1444L7 13.8333L9.04444 13.1111Z" fill="url(#paint2_linear_6436_49392)"/>
    <defs>
    <linearGradient id="paint0_linear_6436_49392" x1="21.2234" y1="12" x2="21.2234" y2="22" gradientUnits="userSpaceOnUse">
    <stop stop-color="#5B83F1"/>
    <stop offset="1" stop-color="#6186F6"/>
    </linearGradient>
    <linearGradient id="paint1_linear_6436_49392" x1="14.6746" y1="12.9556" x2="14.6746" y2="21.0444" gradientUnits="userSpaceOnUse">
    <stop stop-color="#5B83F1"/>
    <stop offset="1" stop-color="#6186F6"/>
    </linearGradient>
    <linearGradient id="paint2_linear_6436_49392" x1="8.68889" y1="13.1111" x2="8.68889" y2="20.8889" gradientUnits="userSpaceOnUse">
    <stop stop-color="#5B83F1"/>
    <stop offset="1" stop-color="#6186F6"/>
    </linearGradient>
    </defs>
  </svg>


)

const MoneyIc = ({styles}:{styles?:any}) => (
  <svg style={styles} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 6C16 4.15866 13.0147 2.66666 9.33332 2.66666C5.65199 2.66666 2.66666 4.15866 2.66666 6C2.66666 6.39066 2.66666 25.6093 2.66666 26C2.66666 27.8413 5.65199 29.3333 9.33332 29.3333C13.0147 29.3333 16 27.8413 16 26C16 25.6093 16 6.39066 16 6Z" fill="#C0D0EF"/>
    <path d="M29.3333 11.3333C29.3333 9.492 26.348 8 22.6667 8C18.9853 8 16 9.492 16 11.3333C16 11.724 16 22.9427 16 23.3333C16 25.1747 18.9853 26.6667 22.6667 26.6667C26.348 26.6667 29.3333 25.1747 29.3333 23.3333C29.3333 22.9427 29.3333 11.724 29.3333 11.3333Z" fill="#C0D0EF"/>
    <path d="M22.6667 22.6667C18.9853 22.6667 16 21.1747 16 19.3333C16 21.48 16 23.1933 16 23.3333C16 25.1747 18.9853 26.6667 22.6667 26.6667C26.348 26.6667 29.3333 25.1747 29.3333 23.3333C29.3333 23.1933 29.3333 21.48 29.3333 19.3333C29.3333 21.1747 26.348 22.6667 22.6667 22.6667Z" fill="url(#paint0_linear_6436_49379)"/>
    <path d="M9.33332 25.3333C5.65199 25.3333 2.66666 23.8413 2.66666 22C2.66666 24.1467 2.66666 25.86 2.66666 26C2.66666 27.8413 5.65199 29.3333 9.33332 29.3333C13.0147 29.3333 16 27.8413 16 26C16 25.86 16 24.1467 16 22C16 23.8413 13.0147 25.3333 9.33332 25.3333Z" fill="url(#paint1_linear_6436_49379)"/>
    <path d="M9.33332 17.3333C5.65199 17.3333 2.66666 15.8413 2.66666 14C2.66666 16.1467 2.66666 17.86 2.66666 18C2.66666 19.8413 5.65199 21.3333 9.33332 21.3333C13.0147 21.3333 16 19.8413 16 18C16 17.86 16 16.1467 16 14C16 15.8413 13.0147 17.3333 9.33332 17.3333Z" fill="url(#paint2_linear_6436_49379)"/>
    <path d="M9.33332 9.33333C5.65199 9.33333 2.66666 7.84133 2.66666 6C2.66666 8.14667 2.66666 9.86 2.66666 10C2.66666 11.8413 5.65199 13.3333 9.33332 13.3333C13.0147 13.3333 16 11.8413 16 10C16 9.86 16 8.14667 16 6C16 7.84133 13.0147 9.33333 9.33332 9.33333Z" fill="url(#paint3_linear_6436_49379)"/>
    <path d="M22.6667 14.6667C18.9853 14.6667 16 13.1747 16 11.3333C16 13.48 16 15.1933 16 15.3333C16 17.1747 18.9853 18.6667 22.6667 18.6667C26.348 18.6667 29.3333 17.1747 29.3333 15.3333C29.3333 15.1933 29.3333 13.48 29.3333 11.3333C29.3333 13.1747 26.348 14.6667 22.6667 14.6667Z" fill="url(#paint4_linear_6436_49379)"/>
    <defs>
    <linearGradient id="paint0_linear_6436_49379" x1="22.6667" y1="19.3333" x2="22.6667" y2="26.6667" gradientUnits="userSpaceOnUse">
    <stop stop-color="#A6BBF2"/>
    <stop offset="1" stop-color="#5E84F6"/>
    </linearGradient>
    <linearGradient id="paint1_linear_6436_49379" x1="9.33332" y1="22" x2="9.33332" y2="29.3333" gradientUnits="userSpaceOnUse">
    <stop stop-color="#A6BBF2"/>
    <stop offset="1" stop-color="#5E84F6"/>
    </linearGradient>
    <linearGradient id="paint2_linear_6436_49379" x1="9.33332" y1="14" x2="9.33332" y2="21.3333" gradientUnits="userSpaceOnUse">
    <stop stop-color="#A6BBF2"/>
    <stop offset="1" stop-color="#5E84F6"/>
    </linearGradient>
    <linearGradient id="paint3_linear_6436_49379" x1="9.33332" y1="6" x2="9.33332" y2="13.3333" gradientUnits="userSpaceOnUse">
    <stop stop-color="#A6BBF2"/>
    <stop offset="1" stop-color="#5E84F6"/>
    </linearGradient>
    <linearGradient id="paint4_linear_6436_49379" x1="22.6667" y1="11.3333" x2="22.6667" y2="18.6667" gradientUnits="userSpaceOnUse">
    <stop stop-color="#A6BBF2"/>
    <stop offset="1" stop-color="#5E84F6"/>
    </linearGradient>
    </defs>
  </svg>
)

const ArrowRight = ({color}:{color?:any}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33331 10H16.6666M16.6666 10L11.6666 5M16.6666 10L11.6666 15" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

)

export {
  ShareIcon,
  MoneyIc,
  MoneyWithdrawIc,
  ArrowRight,
}