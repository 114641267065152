import React, { FC } from "react";
import styled from "styled-components";
import { BodyDefault } from "../typography/styled";
import LinkIc from './Link.svg'

export const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  &:hover{
    opacity: 0.8;
  }
`

export const StyledLink = styled(BodyDefault)`
  color: ${({theme}) => theme.primary[600]};
`
type LinkProps = {
  link?: string,
  linkText: string,
  customStyles?: any,
}

const Link:FC<LinkProps> = (props) => {
  const {
    link,
    linkText,
    customStyles
  } = props
  return(
    <LinkWrapper style={customStyles} href={link}>
      <StyledLink>
        {linkText}
      </StyledLink>
      <img src={LinkIc}/>
    </LinkWrapper>
  )
}

export default Link