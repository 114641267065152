import React, { FC, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TabContainer,
} from '../../styles'
import { Button, TopProgress, ModalVariables } from '../../../../components'
import { icons } from '../../../../icons'
import { InfoContainer, InfoTitle } from '../../../../components/info-block/styles'
import AuthImage from './google-authenticator.svg'
import { useNavigate } from 'react-router-dom'
import { useUi } from "../../../../context/ui.context";
import { authService } from '../../../../api'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import EnabledIcon from './enabled.svg'
import { useCookies } from "react-cookie";
import keycloak from '../../../../keycloak';

const Security:FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['enduserFleioToken']);
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [googleAuth, setGoogleAuth] = useState<boolean>(false)
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const {removeItemFromLs} = useLocalStorage()
  
  const [user, setUser] = useState<any>({})
  const {viewAlert} = useUi()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  console.log('jusert', user);
  const onSubmit = (values:any) => {
    const data = {
      first_name:user.first_name,
      last_name:user.last_name,
      email:user.email,
      mobile_phone_number:user.mobile_phone_number,
      language:'en',
      ...values,
      // old_password:values.oldPassword,
      // password:values.password,
      // password_confirm:values.passwordConfirm,
    }
    setLoading(true)
    authService.updateClient(data)
    .then((res) => {
        setLoadingComplete(true)
        updateUser()
        setTimeout(() => {
          setLoading(false)
          setLoadingComplete(false)
          setViewModal(false)
          setActiveAnimation(false)
        },1000)
    })
    .catch((e => {
      if(e.response.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      setLoading(false)
    }))
  }
  // {
  //   title:'Dark mode',
  //   id:'1',
  //   icon:MoonIcon,
  //   divider:true
  // },


  useEffect(() => {
    authService.getStatus2fa()
    .then((res) => {
      setGoogleAuth(res.data.otp_enable)
    })
    .catch((e) => {
      console.log('err get google auth', e?.response?.detail);
      // removeItemFromLs('userToken')
      // window.location.reload()
    })
    .finally(() => {
      setPageLoading(false)
    })
  },[])
  const updateUser = () => {
    authService.getUserProfile()
    .then((res) => {
      setUser({...res.data.user,local_password_exist:res.data?.local_password_exist})

    })
    .catch((e) => {
      if(e.response.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
    })
  }
  useEffect(() => {
    updateUser()
  },[])
  // const regenerateCode = () => {
  //   authService.regenerateCode()
  //   .then((res) => {
  //     navigate('google-auth')
  //   })
  //   .catch((e) => {
  //     if(e.response.data.detail){
  //       viewAlert({
  //         severity:'error',
  //         message:e.response.data.detail,
  //       })
  //     }
  //   })
  // }

  const disableGoogleAuth = (values?:any) => {
    const data = {
      otp_enable:false,
      ...values
    }
    setLoading(true)
    authService.disable2fa(data)
    .then((res) => {
      setLoading(false)
      setLoadingComplete(true)
      setTimeout(() => {
        setLoadingComplete(false)
        setViewModal(false)
        setActiveAnimation(false)
      },1000)
      authService.getStatus2fa()
      .then((res) => {
        setGoogleAuth(res.data.otp_enable)

      })
      .catch((e) => {
        console.log('err get google auth', e?.response?.detail);
        // removeItemFromLs('userToken')
        // window.location.reload()
      })
      .finally(() => {
        setLoading(false)
      })
    })
    .catch((e) => {
      setLoading(false)
      if(e.response.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
    })
  }
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(modalType === 'change-password-user'){
      onSubmit(values)
    } else if(modalType === 'enable-google-auth') {
      const data = {
        otp_enable:true
      }
      setLoading(true)
      authService.enable2fa(data)
      .then((res) => {
          setLoading(false)
          setLoadingComplete(true)
          setTimeout(() => {
            setLoadingComplete(false)
            // removeItemFromLs('userToken')
            // window.location.reload()
            setViewModal(false)
            setActiveAnimation(false)
            removeItemFromLs('userToken')
            removeCookie('enduserFleioToken');
            document.cookie = ''
            keycloak.logout()
            window.location.reload()
          },1000)
      })
      .catch((e) => {
        if(e.response.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
        setLoading(false)
      })
    } else if(modalType === 'regenerate-google') {

    } else if(modalType === 'disable-google-auth'){
      disableGoogleAuth(values)
    } 
  }

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <TabContainer>
      <InfoContainer>
        <InfoTitle>
          {t('PASSWORD')}
        </InfoTitle>
        <Button
          variant="stroke"
          size="display"
          icon={icons.Password}
          onClick={() => openModal('change-password-user')}
          title={user?.local_password_exist ? t("CHANGE_PASSWORD") : t("CREATE_PASSWORD")}
          customStyles={{marginTop:'5px'}}
        />
      </InfoContainer>

      <InfoContainer style={{
        backgroundRepeat: 'no-repeat',
        backgroundImage:`url(${AuthImage})`,
        backgroundPosition:'right'
      }}>
        <InfoTitle style={{display:'flex', alignItems:'center'}}>
          {t('GOOGLE_AUTH')} &nbsp; {googleAuth ? <img src={EnabledIcon}/> : null}
        </InfoTitle>
        <div style={{display:'flex', gap:'10px', alignItems:'center', marginTop:'10px'}}>
          <Button
            variant='secondary'
            // disabled
            size='display'
            onClick={() => googleAuth ? openModal('disable-google-auth') : openModal('enable-google-auth')}
            title={googleAuth ? t("DISABLE_GOOGLE_AUTHENTICATOR") : t("ENABLE_GOOGLE_AUTHENTICATOR")}
            icon={icons.Filter}
          />
          {/* {googleAuth ? 
            <Button
              variant="stroke"
              size="display"
              arrow
              onClick={() => openModal('regenerate-google')}
              title={t("REGENERATE_NEW_KEY")}
            />  
          :
            null
          } */}
        </div>
      </InfoContainer>
      <ModalVariables
        modalType={modalType}
        closeModalCancel={closeModalCancel}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        loading={loading}
        loadingComplete={loadingComplete}
        instance={{google:googleAuth,local_password_exist:user?.local_password_exist}}
      />
    </TabContainer>
  )
}
export default Security
