import styled from "styled-components";
import { BodyDefault, BodyMedium } from "../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  height: 100vh;
  padding:20px;
  position:relative;
`
export const LeftSubTitle = styled(BodyDefault)`
  color:${({theme}) => theme.grey[600]};
  margin-bottom: 20px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height:100%;
  width: 100%;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:100%;
  position: absolute;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  z-index: 10;
  /* background-color: #FFF; */
`
export const OneMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 360px;
`
export const RightSideContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  background-color: ${({theme}) => theme.grey[300]};
  /* justify-content: center; */
  /* align-items: center; */
  width:90vw;
  height: 100%;
  padding: 82px;
  border-radius: 30px;
  background-position: bottom;
  background-repeat: no-repeat;
  @media screen and (max-height: 800px) {
    background-image: none !important;
  }
  @media screen and (max-width: 1150px) {
    display: none;
  }
`

export const FocusText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin:0px 20px;
  padding: 21px 18px;
  background-color: ${({theme}) => theme.base.white};
`
export const TopInfo = styled.div`
  width: 516px;
  height: 355px;
  display: flex;
  flex-direction: column;
`
export const TitleText = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color:${({theme}) => theme.grey[800]};
  margin-bottom: 36px;
  width: 273px;
`


export const Service = styled(BodyDefault)`
  color:${({theme}) => theme.grey[800]};
  margin-left:6px;
`

export const BlueText = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
  margin-left:10px;
  &:hover{
    cursor: pointer;
  }
`
export const BottomTextWrapper = styled.div`
  display: flex;

`

export const RightSideImage = styled.img`
  width:100%;
  position: absolute;
  bottom:0px;
`

export const ServiceContainer = styled.div`
  display: flex;
  background-color: ${({theme}) => theme.palette[10]};
  box-sizing: border-box;
  padding:8px 10px;
  height:36px;
  margin-bottom: 10px;
  border-radius: 4px;
  width: max-content;
`

export const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

export const ServicesTitle = styled(BodyMedium)`
  color:${({theme}) => theme.grey[800]};
  margin-top: 30px;
`

export const TopComponentText = styled.h2`
  font-weight: 500;
  font-size: 34px;
  line-height: 41px;
  margin: 0px 0px;
  color:${({theme}) => theme.grey[800]};
`
export const TopComponentTextBlue = styled(TopComponentText)`
  color:${({theme}) => theme.primary[600]};
`
export const TextColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextGrey = styled(BodyDefault)`
  color:${({theme}) => theme.grey[600]};
  margin-top:40px;
`

export const LogoTypeImg = styled.img`
  margin-left:50px;
  margin-top: 50px;
  height:27px;
  padding-bottom: 50px;

`

export const InputsWrapper = styled.div`
  display:flex;
  flex-direction: column;
  gap:20px;
`

export const StyledCard = styled.div`
  padding: 59px;
  position: absolute;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  z-index: 10;
`;