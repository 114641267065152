import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,

} from '../pools/styles'
import { Button, ModalVariables, Table, TopProgress } from '../../../../../components'
import { icons } from '../../../../../icons'
import { useNavigate, useParams } from 'react-router-dom'
import { loadBalancerService, networkService } from '../../../../../api'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { useUi } from '../../../../../context/ui.context'
import { handleStatusName } from '../../../../../utils/handle-status-name'
import { StateWrapper, GreenDot, Greentext } from '../../../../Instances/styles'

const Listeners:FC = () => {
  const [t] = useTranslation()
  const {lbId} = useParams()
  const {viewAlert} = useUi()
  const navigate = useNavigate()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [listeners, setListeners] = useState<any[]>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()

  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [deletedPort, setDeletedPort] = useState<any>()


  const editChecked = (item:any) => {
    const founded = checked.find((ckd:any) => ckd.id === item?.id)
    if(allChecked){
      setAllChecked(!allChecked)
    }
    if(founded) {
      setChecked((prev:any) => {
        return prev.filter((ckd:any) => ckd.id !== item?.id)
      })
    } else {
      setChecked([...checked, item])
    }
  }
  const _updatePorts = () => {
    //@ts-ignore
    loadBalancerService.getListeners(lbId, {
      page:page||1,
      ordering,
      pageSize:pageSize||20
    })
    .then((res) => {
      setListeners(res.data.objects.map((listener:any) => {
        return{
          ...listener,
          name:listener.name || 'n/a',
          navigate:`/load-balancer-listener/${lbId}/${listener.id}`,
          admin_state_up:listener.admin_state_up ? 'Yes' : 'No',
          operating_status: 
            (<StateWrapper>
              <GreenDot active={listener.operating_status}/>
              <Greentext active={listener.operating_status}>
                {handleStatusName(listener.operating_status, t)}
              </Greentext>
            </StateWrapper>),
          provisioning_status: 
            (<StateWrapper>
              <GreenDot active={listener.provisioning_status}/>
              <Greentext active={listener.provisioning_status}>
                {handleStatusName(listener.provisioning_status, t)}
              </Greentext>
            </StateWrapper>),
        }
      }))
      setPageLoading(false)
      setLoading(false)
    })
  }
  const _deletePorts = async () => {
    if(!lbId) return
    setLoading(true)
    try{
      if(allChecked) {
        let promises:any[] = []
        await listeners.map((item:any) => {
          let promise = loadBalancerService.deleteListener(lbId, item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
        await viewAlert({
          severity:'info',
          message:'Listeners deleted successfully!'
        })
        await _updatePorts()
        await setAllChecked(false)
      } else if (checked?.length > 0){
        let promises:any[] = []
        await checked.map((item:any) => {
          let promise = loadBalancerService.deleteListener(lbId, item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
        await viewAlert({
          severity:'info',
          message:'Listeners deleted successfully!'
        })
        await _updatePorts()
        await setChecked([])
      } else if (deletedPort) {
        await loadBalancerService.deleteListener(lbId, deletedPort.id)
        await viewAlert({
          severity:'info',
          message:'Listener successfully!'
        })
        await _updatePorts()
      }
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      _updatePorts()
      setPageLoading(false)
      setLoading(false)
    } finally {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }
  useEffect(() => {
    _updatePorts()
    const interval = setInterval(() => {
      _updatePorts()
    },10000)
    return () => clearInterval(interval)
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = (props:any) => {
    _deletePorts()
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (listener:any) => navigate(`/load-balancer-listener/${lbId}/${listener.id}/edit`),
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(id:any) => {
        openModal('delete')
        setAlertTitle(t("LISTENER"))
        setAlertSubTitle([id])
        setDeletedPort(id)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("PROTOCOL"),
      key:'protocol',
    },
    {
      title:t("PORT"),
      key:'protocol_port'
    },
    {
      title:t("OPERATING_STATUS"),
      key:'operating_status'
    },
    {
      title:t("PROVISIONING_STATUS"),
      key:'provisioning_status'
    },
    {
      title:t("ADMIN_STATE_UP"),
      key:'admin_state_up'
    },
    {
      title:'',
      key:'props'
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={loading}/>
      <TopInfoWrapper>
        <div style={{display:'flex', marginBottom:'20px'}}>
          <Button
            variant="primary"
            size="display"
            onClick={() => navigate(`/load-balancers/create-listener/${lbId}`)}
            title={t("CREATE_NEW_LISTENER")}
            customStyles={{marginRight:'10px'}}
          />
          {/* <Button
            onClick={() => {
              setAlertTitle(t("LISTENERS"))
              setAlertSubTitle(allChecked ? listeners : checked)
              openModal('delete')
            }}
            variant='icon'
            size='display'
            disabled={!allChecked && checked?.length === 0 }
          >
            <img src={icons.DeleteIcon}/>
          </Button> */}
        </div>
      </TopInfoWrapper>
        <Table
          columns={Columns}
          rows={listeners}
          settings={items}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          checked={checked}
          setChecked={editChecked}
          hideOther
          hideCheckbox
        />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={editedSubnet}
        loading={loading}
      />
    </Container>
  )
}
export default Listeners