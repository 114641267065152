import styled from "styled-components";
import { InfoContainer } from "../../components/info-block/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px 50px 20px;
  gap:20px;
  position: relative;
`
export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  display: flex;
  margin-right:10px;
  margin-top:50px;
  &>div{
    position: absolute;
    bottom:-1px;
    left:0px;
  }
`