import styled from "styled-components";
import { BodyDefault } from "../../typography/styled";
import { Link } from "react-router-dom";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 10px 10px 20px;
  box-sizing: border-box;
  width: 400px;
  /* height: 350px; */
  left: 540px;
  top: 305px;
  background: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`


export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`
export const BodyModal = styled.div`
  /* display:flex; */
  flex-direction: column;
  align-items: center;
  padding:0px 10px;
`

export const Icon = styled.img`
  &:hover{
    cursor: pointer;
  }
`

export const InvoiceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color:${({theme}) => theme.palette[200]};
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
`

export const InvoicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
`

export const InfoComponent = styled.div`
  background-color: ${({theme}) => theme.grey[100]};
  padding: 8px;
  box-sizing: border-box;
  border-radius:8px;
  margin-bottom:20px;
`

export const LinkText = styled(Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  color: ${({theme}) => theme.primary[600]};
  &:hover{
    cursor: pointer;
  }
`

export const PriceWrapper = styled.div`
  display: flex;
  gap:10px;
  width: 100%;
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 4px;
  background:${({theme}) => theme.grey[350]};
  border-radius: 4px;
`