import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { imageService, instanceService } from "../../../api";
import TopProgress from "../../TopBarProgress";
import { useUi } from "../../../context/ui.context";
import Input from "../../input";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  instanceId?:string;
  alertTitle?:string;
  loading?:boolean;
  viewModal?:boolean

}  


const UploadVolumeToImage:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instanceId,
    alertTitle,
    loading,
    viewModal
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    disk_format:'',
    image_name:'',
  })
  const {
    viewAlert
  } = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [diskFromat, setDiskFromat] = useState<any[]>([])
  const _getOptions = () => {
    // imageService.getCreateOptions()
    // .then((res) => {
      setDiskFromat(['raw', 'vmdk', 'vdi', 'qcow2', 'vhd'].map((format:any) => {
        return {
          ...format,
          value: format,
          label: format,
        }
      }))
      setPageLoading(false)
    // })
    // .catch((e) => {
    //   if(e?.response?.data?.detail){
    //     viewAlert({
    //       severity: 'error',
    //       message: e.response.data.detail
    //     })
    //   }
    //   setPageLoading(false)
    // })
  }
  useEffect(() => {
    _getOptions()
  },[])
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      disk_format: yup.string().required(`${t("REQUIRED_FIELD")}`),
      image_name: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
      <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {alertTitle}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Input
          required
          title={t('NAME')}
          fieldName='image_name'
          placeholder={''}
          onBlur={handleBlur}
          {...formikProps}
        />
        <Divider/>
        <Select
          required
          toolTip={true}
          data={diskFromat}
          selectedValue={values['disk_format']}
          onChange={(e) => handleChange('disk_format')(e)}
          title={t("DISK_FORMAT")}
          customStyles={{marginBottom:'20px', width:'360px'}}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            loading={loading}
            title={t("UPLOAD")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default UploadVolumeToImage