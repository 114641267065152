import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  BackArrow,
  Input,
  Button,
  TopProgress
} from '../../../components'
import {
  Container,
  TitleBig,
  FormWrapper,
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { icons } from '../../../icons'
import SShDefault from '../../../components/side-bar/icons/ssh-keys-default.svg'
import copy from 'copy-to-clipboard';
import { sshService } from '../../../api'
import { useUi } from '../../../context/ui.context'

const CreateSSH:FC = () => {
  const navigate = useNavigate()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [generated, setGenerated] = useState<boolean>(false)
  const [initialValues, setInitialValues ] = useState<any>({
    name:'',
    public_key:'',
    private_key:''
  })
  const [loading, setLoading] = useState<boolean>(false)
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      name:values.name,
      public_key:values.public_key,
    }
    if(values.private_key){
      //@ts-ignore
      data.private_key = values.private_key
    }
    sshService.createSsh(data)
    .then((res) => {
      navigate(-1)
    })
    .catch((e) => {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      if(e?.response?.data.public_key){
        setFieldError('public_key', e?.response?.data.public_key)
      }
    })
    .finally(() => {
      setLoading(false)
    })
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldError,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t('REQUIRED_FIELD')}`).max(30, `${t("STRING_VALIDATE_MAX")} 30 ${t("CHARACTERS")}`),
      public_key:yup.string().required(`${t('REQUIRED_FIELD')}`),
      private_key: generated ? yup.string().required(`${t('REQUIRED_FIELD')}`) : yup.string()
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const _generatePairKeys = () => {
    setLoading(true)
    sshService.generateKeyPair()
    .then((res) => {
      setGenerated(true)
      setInitialValues({
        ...values,
        public_key:res.data.public_key,
        private_key:res.data.private_key,
      })
      setLoading(false)
    })
    .catch((e) => {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }

    })
  }
  return(
    <Container>
      <TopProgress loading={loading}/>
      <BackArrow
        onClick={() => navigate('/ssh-keys')}
        title={t("BACK_TO_KEYS")}
      />
      <TitleBig>{t("CREATE_A_NEW_SSH")}</TitleBig>
      {generated ? 
        <FormWrapper>
          <Input
            required
            fieldName='name'
            title={t("NAME_KEY")}
            placeholder=''
            customStyles={{width:'360px'}}
            {...formikProps}
          />
          <Input
            required
            isTextArea
            fieldName='public_key'
            // toolTip={true}
            title={t("KEY_CONTENT")}
            customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
            readOnly
            placeholder=''
            {...formikProps}
          />
          <Button
            variant="stroke"
            size="display"
            icon={icons.Copy}
            onClick={() => copy(values['public_key'])}
            title={t("COPY_PUBLIC_TO_CLIPBOARD")}
            // customStyles={{width:'265px'}}
          />
          <Input
            required
            isTextArea
            fieldName='private_key'
            toolTip={false}
            
            title={t("PRIVATE_KEY")}
            customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
            placeholder=''
            readOnly
            {...formikProps}
          />
          <Button
            variant="stroke"
            size="display"
            icon={icons.Copy}
            onClick={() => copy(values['private_key'])}
            title={t("COPY_PRIVATE_TO_CLIBOARD")}
            // customStyles={{width:'265px'}}
          />
        </FormWrapper>
      :
        <FormWrapper>
          <Input
            required
            fieldName='name'
            title={t("NAME_KEY")}
            placeholder=''
            {...formikProps}
          />
          <Input
            required
            isTextArea
            fieldName='public_key'
            toolTip={true}
            toolTipText={`${t("PUBLIC_KEY_TOOLTIP")}`}
            title={t("KEY_CONTENT")}
            customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
            placeholder=''
            {...formikProps}
          />
        </FormWrapper>

      }
      <div style={{display:'flex', marginTop:'40px', gap:'10px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{}}
          onClick={handleSubmit}
          title={t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          icon={SShDefault}
          onClick={_generatePairKeys}
          title={t("GENERATE_NEW_KEY")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate("/ssh-keys")}
          title={t("CANCEL")}
        />
      </div>
    </Container>
  )
}

export default CreateSSH
