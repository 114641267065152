import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,

} from './styles'
import { Button, ModalVariables, Table, TopProgress } from '../../../../../components'
import { icons } from '../../../../../icons'
import { useNavigate, useParams } from 'react-router-dom'
import { loadBalancerService, networkService } from '../../../../../api'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { useUi } from '../../../../../context/ui.context'
import { handleStatusName } from '../../../../../utils/handle-status-name'
import { StateWrapper, GreenDot, Greentext } from '../../../../Instances/styles'

const Pools:FC = () => {
  const [t] = useTranslation()
  const {lbId} = useParams()
  const {viewAlert} = useUi()
  const navigate = useNavigate()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [pools, setPools] = useState<any[]>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [deletedPort, setDeletedPort] = useState<any>()

  const _updatePorts = () => {
    //@ts-ignore
    loadBalancerService.getPools(lbId)
    .then((res) => {
      setPools(res.data.objects.map((pool:any) => {
        return{
          ...pool,
          navigate: `/load-balancer-pool/${lbId}/${pool.id}`,
          name:pool.name || 'n/a',
          admin_state_up:pool.admin_state_up ? 'Yes' : 'No',
          operating_status: 
            (<StateWrapper>
              <GreenDot active={pool.operating_status}/>
              <Greentext active={pool.operating_status}>
                {handleStatusName(pool.operating_status, t)}
              </Greentext>
            </StateWrapper>),
          provisioning_status: 
            (<StateWrapper>
              <GreenDot active={pool.provisioning_status}/>
              <Greentext active={pool.provisioning_status}>
                {handleStatusName(pool.provisioning_status, t)}
              </Greentext>
            </StateWrapper>),
        }
      }))
      setPageLoading(false)
      setLoading(false)
    })
  }
  const _deletePorts = async () => {
    setLoading(true)
    if(!lbId) return
    try{
      await loadBalancerService.deletePool(lbId, deletedPort.id)
      await viewAlert({
        severity:'info',
        message:`${t("POOL_DELETED_SUCCESS")}`
      })
      await _updatePorts()
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      _updatePorts()
      setPageLoading(false)
      setLoading(false)
    } finally {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }
  useEffect(() => {
    _updatePorts()
    const interval = setInterval(() => {
      _updatePorts()
    },10000)
    return () => clearInterval(interval)
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = (props:any) => {
    _deletePorts()
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (pool:any) => navigate(`/load-balancer-pool/${lbId}/${pool.id}/edit`),
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(id:any) => {
        openModal('delete')
        setAlertTitle(t("POOL"))
        setAlertSubTitle([id])
        setDeletedPort(id)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("PROTOCOL"),
      key:'protocol',
    },
    {
      title:t("ALGORITHM"),
      key:'lb_algorithm'
    },
    {
      title:t("OPERATING_STATUS"),
      key:'operating_status'
    },
    {
      title:t("PROVISIONING_STATUS"),
      key:'provisioning_status'
    },
    {
      title:t("ADMIN_STATE_UP"),
      key:'admin_state_up'
    },
    {
      title:'',
      key:'props'
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={loading}/>
      <TopInfoWrapper>
        <div style={{display:'flex', marginBottom:'20px'}}>
          <Button
            variant="primary"
            size="display"
            onClick={() => navigate(`/load-balancers/create-pool/${lbId}`)}
            title={t("CREATE_NEW_POOL")}
            customStyles={{marginRight:'10px'}}
          />
          {/* <Button
            onClick={() => {
              setAlertTitle(t("POOLS"))
              setAlertSubTitle(allChecked ? pools : checked)
              openModal('delete')
            }}
            variant='icon'
            size='display'
            disabled={!allChecked && checked?.length === 0 }
          >
            <img src={icons.DeleteIcon}/>
          </Button> */}
        </div>
      </TopInfoWrapper>
        <Table
          columns={Columns}
          rows={pools}
          settings={items}
          hideOther
          hideCheckbox
        />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={editedSubnet}
        loading={loading}
      />
    </Container>
  )
}
export default Pools