import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import { HeadLine3 } from "../../typography/styled";
import MultiInput from "../../multi-input";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
  loadingComplete?:boolean;
}  

const EditBareMetal:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    loading,
    loadingComplete
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    name:instance?.clearName || instance?.name || instance?.id,
    tag:'',
  })
  const [tags, setTags] = useState<string[]>(instance?.tags || [])
  const onSubmit = (values:any) => {
    closeModal({...values, tags:tags})
  }
  useEffect(() => {
    setInitialValues({
      name:instance?.clearName || instance?.name || instance?.id,
      tag:'',
    })
  },[instance])
  const regName = /^[a-zA-Z0-9.]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
      .required(`${t("REQUIRED_FIELD")}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      tag:yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const changeTags = (tag:string) => {
    const founded = tags.find((item:any) => item === tag)
    if(founded || tag === '' || tag === undefined) {
      setTags((prev:any) => {
        return prev.filter((item:any) => item !== tag)
      })
    } else {
      setTags((prev) => [...prev, tag])
      handleChange('tag')('')
    }
  }
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("EDIT_SERVER")} {alertTitle}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Input
          required
          title={t('NAME')}
          fieldName='name'
          placeholder={''}
          onBlur={handleBlur}
          {...formikProps}
        />
        <MultiInput
          toolTip={true}
          toolTipText={`${t("INFO_CREATE_TAGS")}`}
          customStyles={{paddingLeft:'35px'}}
          placeholder={t("ADD_A_TAG")}
          fieldName='tag'
          tags={tags}
          icon={icons.IconTag}
          title={t("TAGS")}
          changeTags={changeTags}
          {...formikProps}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("RENAME")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default EditBareMetal