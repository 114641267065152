import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  WarningBlock
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import Select from "../../select";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import { BodySmall, HeadLine3 } from "../../typography/styled";
import RevealButton from "../../dropdown-modal";
import Toggle from "../../toggle";
import { networkService } from "../../../api";
import { randomNameGenerator } from "../../../utils/create-random-name";
import { useParams } from "react-router-dom";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(props?:any) => void;
  dataToEdit?:any,
  loading?:boolean,
}  

const CreateSubnet:FC<ModalProps> = (props) => {
  const IpVersionList = [
    {
      label:'IPv4',
      value:'4'
    },
    {
      label:'IPv6',
      value:'6'
    }
  ]
  const {
    closeModal,
    closeModalCancel,
    dataToEdit,
    loading
  } = props
  const {id} = useParams()
  const [t] = useTranslation()
  const [dhcp, setDhcp] = useState<boolean>(dataToEdit?.enable_dhcp)
  const [options, setOptions] = useState<any[]>([])
  const [initialValues, setInitialValues] = useState<any>({
    name:dataToEdit.name,
    address:dataToEdit.cidr,
    ip_version:dataToEdit.ip_version || IpVersionList[0].value,
    gateway_ip:dataToEdit.gateway_ip||"",
    setGatewayIp:dataToEdit.setGatewayIp||'default',
    ipv6_ra_mode:'',
    ipv6_address_mode:'',
    dns_name:'8.8.8.8'
  })
  const onSubmit = (values:any) => {
    const data:any = {
      // data we have
      id:dataToEdit?.id,
      name:values.name,
      cidr:values.address,
      ip_version:+values.ip_version,
      enable_dhcp:dhcp,
      dns_nameservers:[{ip:''}],
      host_routes:[],
      network_id:dataToEdit?.network_id || '',
      _id:dataToEdit._id,
      network_mask:0,
      network_address_source:'manually',
      allocation_pools:[],
      //data we don't have fields
      ipv6_ra_mode:null,
      ipv6_address_mode:null,
      setGatewayIp:values.setGatewayIp === 'default' ? false : values.setGatewayIp
    }
    if(values.setGatewayIp !== 'default' ){
      data.gateway_ip = values.gateway_ip
    }
    closeModal(data)
    // networkService.createSubnet(data)
    // .then((res) => {

    // })
  }
  useEffect(() => {
    setInitialValues({
      name:dataToEdit.name,
      address:dataToEdit.cidr,
      ip_version:dataToEdit.ip_version || IpVersionList[0].value,
      gateway_ip:dataToEdit.gateway_ip||"",
      setGatewayIp:dataToEdit.setGatewayIp||'default',
      ipv6_ra_mode:'',
      ipv6_address_mode:'',
    })
    if(id){
      networkService.getCreateSubnetOptions(id)
        .then((res) => {
          setOptions(res.data.ipv6_modes.map((item:any) => {
            return {
              value:item,
              label:item
            }
          }))
        })
    }
  },[dataToEdit])
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t("REQUIRED_FIELD")}`).matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      address:yup.string().required(`${t("REQUIRED_FIELD")}`),
      ip_version:yup.string().required(`${t("REQUIRED_FIELD")}`),
      gateway_ip:yup.string(),
      setGatewayIp:yup.string().required(`${t("REQUIRED_FIELD")}`),
      ipv6_ra_mode:yup.string(),
      ipv6_address_mode:yup.string(),
      dns_name:yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  
  const Gateway_ip_list = [
    {
      label:t("DO_NOT_SET_GATEWAY_IP"),
      value:'no-gateway'
    },
    {
      label:t("MANUALLY_SET_GATEWAY_IP"),
      value:'manually'
    },
    // {
    //   label:'Set default gateway IP',
    //   value:'4'
    // }
  ]
  
  const Gateway_ip_listCreate = [
    {
      label:t("SET_DEFAULT_GATEWAY_IP"),
      value:'default'
    },
    {
      label:t("MANUALLY_SET_GATEWAY_IP"),
      value:'manually'
    },
    {
      label:t("DO_NOT_SET_GATEWAY_IP"),
      value:'no-gateway'
    }
  ]
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {dataToEdit?.id ? `${t("EDIT")} ${dataToEdit?.name || dataToEdit?.id}` : t("CREATE_NEW_SUBNET")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Input
          required
          title={t('NAME_SUBNET')}
          fieldName='name'
          placeholder={''}
          onBlur={handleBlur}
          customStyles={{width:'360px'}}
          {...formikProps}
        />
        <Input
          required
          title={t('NETWORK_ADDRESS')}
          fieldName='address'
          placeholder={''}
          onBlur={handleBlur}
          customStyles={{width:'360px'}}
          {...formikProps}
        />
        <Select
          required
          toolTip={false}
          data={IpVersionList}
          test_id="ip_version_select"
          selectedValue={values['ip_version'] || IpVersionList[0].value}
          onChange={(e) => {
            handleChange('ip_version')(e)}}
          title={t("IP_VERSION")}
          customStyles={{width:'360px'}}
          fieldName='ipv6_ra_mode'
          {...formikProps}
        />
        {values['ip_version'] == 6 ? 
          id ? 
            <>
              <Select
                toolTip={false}
                data={options}
                test_id="ipv6_ra_mode_select"
                selectedValue={values['ipv6_ra_mode'] || options?.[0]?.value}
                onChange={(e) => {
                  handleChange('ipv6_ra_mode')(e)}}
                title={"IPv6 ra mode"}
                customStyles={{width:'360px'}}
                fieldName='ip_version'
                {...formikProps}
              />
              <Select
                toolTip={false}
                data={options}
                test_id="ipv6_address_mode_select"
                selectedValue={values['ipv6_address_mode'] || options?.[0]?.value}
                onChange={(e) => {
                  handleChange('ipv6_address_mode')(e)}}
                title={'IPv6 address mode'}
                customStyles={{width:'360px'}}
                fieldName='ipv6_address_mode'
                {...formikProps}
              />
            </>
          :
            <WarningBlock>
              <Icon src={icons.Warning}/>
              <BodySmall style={{color:"#000"}}>
                {t("IP_V6_ALERT_IN_CREATE_SUBNET")}
              </BodySmall>
            </WarningBlock>
        : null}
        <Select
          required
          toolTip={false}
          test_id="setGatewayIp_select"
          data={dataToEdit?.id ? Gateway_ip_list : Gateway_ip_listCreate}
          selectedValue={values['setGatewayIp'] || Gateway_ip_listCreate[0].value}
          onChange={(e) => handleChange('setGatewayIp')(e)}
          title={t("GATEWAY_IP_METHOD")}
          customStyles={{marginBottom:'6px', width:'360px'}}
        />
        {values['setGatewayIp'] === 'manually' ? 
          <Input
            title={t('GATEWAY_IP')}
            fieldName='gateway_ip'
            placeholder={''}
            onBlur={handleBlur}
            customStyles={{width:'360px'}}
            {...formikProps}
          />
        : null}
        <div style={{width:'360px', display:'flex', alignItems:'flex-start'}}>
          <RevealButton
            // customStyles={{width:'188px'}}
            title={t("ADDITIONAL_SETTINGS")}
          >
            <Toggle
              customStyles={{marginBottom:'20px'}}
              title={t("ENABLE_DHCP")}
              toolTip={true}
              tooltipText={`${t("DNS_ON_TOOLTIP")}`}
              value={dhcp}
              onChange={() => setDhcp(!dhcp)}
            />
            <div style={{display:'flex', flexDirection:'column', gap:'14px'}}>
              <Input
                title={t('DNS_SERVER_NAME')}
                fieldName='dns_name'
                placeholder={''}
                isTextArea
                onBlur={handleBlur}
                customStyles={{maxWidth:'320px',minWidth:'320px', maxHeight:'200px'}}
                {...formikProps}
              />
              {/* <Input
                title={t('HOST_ROUTE')}
                fieldName='address'
                placeholder={''}
                onBlur={handleBlur}
                // customStyles={{width:'360px'}}
                {...formikProps}
              /> */}
            </div>
          </RevealButton>
        </div>
        <div style={{display:"flex", gap:'6px', marginTop:'14px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            loading={loading}
            title={dataToEdit?.id ? t("EDIT") : t("CREATE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default CreateSubnet

