import styled from "styled-components";
import { HeadLine1 } from "../../components/typography/styled";



export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 50px 20px;
  position: relative;
`

export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  /* margin-top: 20px; */
  margin-bottom: 20px;
`
export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  display: flex;
  margin-top:40px;
  margin-bottom:20px;
  &>div{
    position: absolute;
    bottom:-1px;
    left:0px;
  }
`
