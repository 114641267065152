import React, { FC, useEffect, useState } from "react";
import {
  Container,
  TitleBig,
  FormWrapper,
  CreatedOverlay,
} from './styles'
import {
  Input,
  Select,
  Button,
  Counter,
  Toggle,
  TopProgress,
} from "../../../../components";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { imageService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";

type ImageProp = {
  image:any
}

const MainEdit:FC<ImageProp> = ({image}) => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [protectedValue, setProtected] = useState<boolean>(image.protected)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [visibilities, setVisibilities] = useState<any[]>([])
  const [diskFormats, setDiskFormats] = useState<any[]>([])
  const [distro, setDistro] = useState<any[]>([])
  const [region, setRegion] = useState<string>('')
  const [initialValues, setInitialValues] = useState<any>({
    disk_format:image.disk_format,
    name:image.name,
    min_disk:image.min_disk,
    min_ram:image.min_ram,
    os_distro:image.os_distro||'',
    os_version:image.os_version||'',
  })
  const getCreateOptions = () => {
    imageService.getCreateOptions()
    .then((res:any) => {
      setDistro(res.data.os_distros.map((os:any) => {
        return {
          value:os,
          label:os,
        }
      }))
      setDiskFormats(res.data.disk_formats.map((format:string) => {
        return {
          value:format,
          label:format,
        }
      }))
      setRegion(res.data.regions?.[0]?.id)
      setVisibilities(res.data.visibilities.map((visibility:string) => {
        return{
          value:visibility,
          label:visibility,
        }
      }))
      setPageLoading(false)
    })
    .catch((err) => {
      
    })
    .finally(() => {

    })
  }
  useEffect(() => {
    getCreateOptions()
  },[])
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      ...values,
      region:region,
      protected:protectedValue,
      visibility:visibilities?.[0]?.value
    }

    imageService.editImage(image.id, data) 
    .then((res) => {
      viewAlert({
        severity:'info',
        message: `${t("IMAGE_SUCCESSFULLY_EDITED")}`
      })
      setLoadingComplete(true)
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
        if(res?.data?.detail){
          viewAlert({
            severity:'info',
            message: res.data.detail
          })
        }
        navigate(`/images/${image.id}`)
      },1000)

    })
    .catch((e) => {
      if(e?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message: e.response.data?.detail
        })
        setLoading(false)
      } else {
        setErrors(e?.response?.data)
        setLoading(false)
      }
    })
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
      .required(`${t('REQUIRED_FIELD')}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      visibility:yup.string(),
      os_distro:yup.string(),
      os_version:yup.string(),
      min_disk:yup.string(),
      min_ram:yup.string(),
      disk_format:yup.string(),
      source:yup.string(),
      url: yup.string().when("source", {
        is: (source:any) => source === 'url',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };


  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <CreatedOverlay active={loading && values['source'] === 'file'}/>
      <FormWrapper>
        <Input
          required
          fieldName='name'
          title={t("NAME_IMAGE")}
          placeholder=''
          {...formikProps}
        />
        <Select
          toolTip={false}
          data={diskFormats}
          test_id="image-type"
          disabled
          selectedValue={values['disk_format']}
          onChange={(e) => handleChange('disk_format')(e)}
          title={t("DISK_FORMAT")}
        />
      </FormWrapper>
      <FormWrapper style={{marginBottom:'20px', marginTop:'20px'}}>
        <Select
          toolTip={false}
          data={distro}
          selectedValue={values['os_distro']}
          onChange={(e) => handleChange('os_distro')(e)}
          title={t("OS")}
          test_id="image-os"
        />
        <Input
          fieldName='os_version'
          title={t("OS_VERSION")}
          placeholder=''
          customStyles={{ width:'100%'}}
          {...formikProps}
        />
        <Counter
          title={t("MINIMUM_DISK_SIZE")}
          value={values['min_disk']}
          hideItems={'GB'}
          fieldName={'min_disk'}
          minCount={1}
          onChange={(v:any) => {
            handleChange('min_disk')(v.toString())}}
        />
        <Counter
          title={t("MINIMUM_REQUIRED_RAM")}
          value={values['min_ram']}
          minCount={1}
          hideItems={'GB'}
          fieldName={'min_ram'}
          onChange={(v:any) => {
            handleChange('min_ram')(v.toString())}}
        />
        <Toggle
          title={t("PROTECTED")} 
          value={protectedValue}
          onChange={() => setProtected(!protectedValue)}
        />

      </FormWrapper>
      <div style={{display:'flex', marginTop:'20px', gap:'10px'}}>
        <Button
          variant="primary"
          size="display"
          // customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          loading={loading}
          loadingComplete={loadingComplete}
          title={t("EDIT")}
        />
        <Button
          variant="stroke"
          size="display"
          // customStyles={{width: '81px'}}
          onClick={() => navigate("/images")}
          title={t("CANCEL")}
        />
      </div>
    </Container>
  )
}

export default MainEdit
