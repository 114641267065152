import React, { FC, useState, useRef, useEffect } from 'react'
import { Button, SearchInput, Select } from '..'
import {
  Container,
  RightSideContainer,
  ProfileContainer,
  Avatar,
  ProfileName,
  NoAvatar,
  ChevronIc,
  DropDownProfile,
  NotificationsContainer,
  ToolTipOpenstack,
  Rect,
  OpenstackWrapper,
  BalanceContainer
} from './styles'
import StyledIcon from '../styled-icon'
import ConsoleIcon from './console.svg'
import HelpIcon from './help.svg'
import NotificationIcon from './notifications.svg'
import NoNotificationsIcon from './no-notification.svg'
import AvatarIcon from './avatar.png'
import ChevronIcon from './chevron.svg'
import NotificationsModal from './components/notifications'
import { useUi } from "../../context/ui.context";
import DropOptions from "./components/drop-options";
import { useAuth } from '../../context/user.context'
import { authService, billingService } from '../../api'
import { useNavigate } from 'react-router-dom'
import { icons } from '../../icons'
import Gravatar from 'react-gravatar'
import UaFlag from './ua-flag.svg'
import PlFlag from './pl-flag.svg'
import { useTranslation } from 'react-i18next'
import { ErrorContainer, ErrorText } from '../../pages/HomePage/styles'
import AlertIcon from './error.svg'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { BodySmall, VerySmall } from '../typography/styled'
import { useTheme } from 'styled-components'
import { formatCurrency } from '../../utils/display-price'
import rootConfig from '../../config'


const Header:FC = () => {
  const [t] = useTranslation()
  const profileRef:any = useRef()
  const notificationsRef:any = useRef()
  const notificationIconRef:any = useRef()
  const {removeItemFromLs} = useLocalStorage()
  const [billingData, setBillingData] = useState<any>()
  const theme:any = useTheme()
  const navigate = useNavigate()
  const [activeDrop, setActiveDrop] = useState<boolean>(false)
  const [activeNotifications, setActiveNotifications] = useState<boolean>(false)
  const [hover, setHover] = useState<boolean>(false)
  const {smallBar, setSmallBar, viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user, notifications} = useAuth()
  const dropOptionsRef:any = useRef()
  const [region, setRegion] = useState<any>('')
  useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
      document.removeEventListener('click', handleClickOutside, true);
		};
  },[]);
  const updateBillingData = () => {
    billingService.getSummary()
    .then(async(res) => {
      setBillingData(res.data)
    })
    
  }
  const handleClickOutside = (event:any) => {
    if (dropOptionsRef  && !dropOptionsRef.current.contains(event.target) && !profileRef.current.contains(event.target)){
      setActiveDrop(false)
    }
    if (notificationsRef && !notificationsRef.current.contains(event.target) && !notificationIconRef.current.contains(event.target)){
      setActiveNotifications(false)
    }
  }
  const changeSizeBar = () => {
    setSmallBar(!smallBar)
  }
  const userData = user?.user
  useEffect(() => {
    const region = localStorage.getItem('region_ocp_zone')
    updateBillingData()
    if(!region){
      localStorage.setItem('region_ocp_zone', 'ua-1')
      setRegion('ua-1')
    } else {
      setRegion(region)
    }
  },[])
  const regions = [
    {
      icon:UaFlag,
      label:'ua-1',
      value:'ua-1',
    },
    {
      icon:PlFlag,
      label:'pl-1',
      value:'pl-1',
    }
  ]
  const changeRegion = (region:any) => {
      localStorage.setItem('region_ocp_zone', region)
      removeItemFromLs('instance.configure')
      removeItemFromLs('instance.network')
      removeItemFromLs('cluster.type')
      removeItemFromLs('cluster.configure_master')
      removeItemFromLs('cluster.configure_pools')
      removeItemFromLs('cluster.poools')
      removeItemFromLs('cluster.add-ons')
      removeItemFromLs('cluster.networking')
      window.location.reload()
  }

  const verEmail = () => {
    setViewModalComplete(true)
    setActiveAnimationComplete(true)
  }
  return(
    <>
      <Container isSmall={smallBar}>
        <SearchInput/>
        {/* <div/> */}
        <RightSideContainer>
          {localStorage.getItem('region_ocp_zone') === 'ua-1' && !user.user.active_client ? null :
            <Select
              data={regions}
              isRegion
              selectedValue={region || regions[0]?.value}
              onChange={(e) => changeRegion(e)} 
              customStyles={{marginRight:'10px', minWidth:'97px'}}
              title={''}
              toolTip={false}
            />
          }
          {billingData && 
            <BalanceContainer>
              <VerySmall style={{color:theme.palette[800], textTransform:"uppercase", fontSize:"8px", lineHeight:'9.68px'}}>
                {t("BALANCE")}
              </VerySmall>
              <div style={{display:'flex', width:'100%', gap:'4px'}}>
                <BodySmall onClick={() => {
                  navigate('/billing/overview')
                }} style={{whiteSpace:'nowrap'}}>
                  {formatCurrency(billingData?.uptodate_credit, billingData?.billing_currency)} 
                </BodySmall>
                <StyledIcon onClick={() => {
                  navigate('/billing/overview?modal=add_payment')
                }} src={icons.PlusDefault}/>
              </div>
            </BalanceContainer>
          }
          <>
            <OpenstackWrapper>
              <ToolTipOpenstack active={hover}>
                <BodySmall style={{fontSize:'12px'}}>
                  Openstack
                </BodySmall>
              <Rect active={hover}/>
              </ToolTipOpenstack>
              <div
                style={{display:'flex'}}
                onMouseEnter={() => {
                  setHover(true)
                }}
                onMouseLeave={() => {
                  setHover(false)
                }}
              >
                <StyledIcon
                  style={{marginRight:'20px'}}
                  active={false}
                  onClick={() => {
                    authService.openstackGet()
                    .then((res) => {
                      window.open(res?.data?.redirect_uri)
                    })
                    .catch((err:any) => {
                      viewAlert({
                        severity: 'error',
                        message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                      })
                    })
                  }}
                  src={ConsoleIcon}
                />
              </div>
              </OpenstackWrapper>
            <div style={{display:'flex', alignItems:'center'}} ref={notificationIconRef}>
            <StyledIcon
              style={{marginRight:'20px'}}
              active={activeNotifications}
              
              onClick={() => setActiveNotifications(!activeNotifications)}
              src={notifications?.extra?.unseen_count > 0 && notifications.count > 0 ? NotificationIcon : NoNotificationsIcon}
            />
            </div>
            <StyledIcon
              style={{marginRight:'40px'}}
              active={false}
              onClick={() => window.open(rootConfig.docs_url)}
              src={icons.HelpIcon}
            />
          </>
          <ProfileContainer
            data-test-id='profile-settings-dropdown'
            ref={profileRef}
            onClick={() => setActiveDrop(!activeDrop)}
          >
            {false ? 
              <Avatar src={AvatarIcon}/> 
            :
              <Gravatar
                email={userData?.email}
                size={24}
                style={{borderRadius: '100%'}}
              />
            }
            <ProfileName>
              {userData?.email} 
            </ProfileName>
            <ChevronIc active={activeDrop} src={ChevronIcon}/>
          </ProfileContainer>
          <DropDownProfile ref={dropOptionsRef} active={activeDrop}>
            <DropOptions
              handleClose={() => setActiveDrop(false)}
            />
          </DropDownProfile>
        </RightSideContainer>
        <NotificationsContainer
          active={activeNotifications}
          ref={notificationsRef}
        >
          <NotificationsModal />
        </NotificationsContainer>

      </Container>
      {(!user.user.active_client || user.requires_email_verification) && window.location.href.indexOf('/complete-registration') === -1 ?
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing:'border-box',
          paddingRight:"20px",
          paddingLeft:"20px", 
          marginBottom:'10px'
        }}>
            <ErrorContainer style={{marginTop:'0px'}} isError={true}>
            <div style={{display:'flex', alignItems:"center", gap:'6px'}}>
              <img src={AlertIcon}/>
              <ErrorText>{t("ERROR_VERIFICATION_MESSAGE")}</ErrorText>
            </div>
            <Button
              title={!user.user.active_client ? t("GO_TO_CREATE_CLIENT") : t("VERIFY_EMAIL")}
              onClick={!user.user.active_client ? () => navigate("/complete-registration") : () => verEmail()}
              size='small'
              variant='primary'
            />
          </ErrorContainer>
        </div>
      : null}
    </>
  )
}

export default Header