import styled from 'styled-components'
import { BodyDefault } from '../typography/styled';

type SelectProps = {
  active:boolean;
}

type ActiveProp = {
  active?:boolean;
}

export const Container = styled.div`
  position: relative;
  /* height:100%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
export const DropDown = styled.div<SelectProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100% ; */
  /* height: 40px; */
  background-color: ${({theme, active}) => active ? theme.palette[10] : theme.primary[50]};
  padding: 10px 16px;
  box-shadow: ${({active, theme}) => active ? theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : '0px 0px 0px 3px #E0EAFF' : ''};
  border-radius: 4px;
  border: ${({theme, active}) => active ? theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[200]}` : ''};
  color: ${({theme}) => theme.primary[700]};
  &:hover{
    cursor: pointer;
    color: ${({theme}) => theme.primary[500]};
  }
  /* &:target{
    border: ${({theme}) =>  `1px solid ${theme.primary[300]}`};
    box-shadow: '0px 0px 0px 3px #E0EAFF';
  } */
`

export const ImgArrow = styled.img<ActiveProp>`
  transform: ${({active}) => active ? 'rotate(180deg)' : ''};
`
export const Title = styled(BodyDefault)<ActiveProp>`
  white-space: nowrap;
  color:${({theme, active}) => active ? theme.primary[700] : theme.palette[900]};
`

export const OptionsWrapper = styled.div<ActiveProp>`
  /* top: 49px; */
  margin-top: ${({active}) => active ? '10px' : '0px'};;
  /* position: absolute; */
  margin-bottom: ${({active}) => active ? '10px' : '0px'};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 6;
  border-radius: 4px;
  padding: ${({active}) => active ? '20px' : '0px'};
  background-color: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-shadow: ${({theme}) => theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : '0px 0px 0px 3px #E0EAFF'};
  height:${({active}) => active ? '' : '0px'};
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  transform-origin: top;
  transition: 0.1s transform ease-in-out;
  transition: 0.2s opacity, height ease-in-out;
  opacity:${({active}) => active ? '1' : '0'};
`
