import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import uk from './locales/ua.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    uk: {
      translation: uk,
    },
  },
  lng: localStorage.getItem('lang') || 'uk',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  debug: true,
  react: {
    wait: true,
  },
});

export default i18n;
