import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { Button, ModalVariables, StartPage, TopProgress} from "../../components";
import Illustration from './illustration.svg'
import IllustrationDark from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { networkService, routerService } from "../../api";
import { useUi } from "../../context/ui.context";
import {
  StateWrapper,
  GreenDot,
  Greentext,
} from './styles'
import { useRouterCustom } from "../../context/routerContext";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { useAuth } from "../../context/user.context";
import { handleStatusName } from "../../utils/handle-status-name";
import rootConfig from "../../config";
import { compareArrays } from "../../utils/findIntersection";

const Routers:FC = () => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user, quotas} = useAuth()
  const {
    setRouterOptions,
    setInterfaceOptions,
    routers,
    setRouters,
  } = useRouterCustom()
  // const [routers, setRouters] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [paginationData, setPaginationData] = useState<any>()
  const [deletedRouter, setDeletedRouter] = useState<any>()
  const [editedRouter, setEditedRouter] = useState<any>()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(routers, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])

  const updateRouters = () => {
    // setLoading(true)
    routerService.getRouters({
      page:page||1,
      ordering,
      pageSize:pageSize||10
    })
    .then((res) => {
      setPaginationData(res.data)
      setRouters(res.data.objects.map((router:any) => {
        return {
          ...router,
          name:router.name,
          status: 
          (<StateWrapper>
            <GreenDot active={router.status === "ACTIVE"}/>
            <Greentext active={router.status === "ACTIVE"}>
              {handleStatusName(router.status, t)}
            </Greentext>
          </StateWrapper>) ,
          network_name:router.network_name,
          region:router.region,
          navigate:router.id,
          id:router.id,
          
        }
      }))
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      navigate('/home')
    })
    .finally(() => {
      setPageLoading(false)
      // setLoading(false)
      // setActiveAnimation(false)
      // setViewModal(false)
    })
  }
  useEffect(() => {
    routerService.createOptionsRouter()
    .then((res) => {
      setRouterOptions(res.data)
      networkService.getSubnets()
      .then((res) => {
        setInterfaceOptions(res.data.objects)
      })
    })
  },[])
  useEffect(() => {
    updateRouters()
    const interval = setInterval(() => {
      updateRouters()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const _deleteRouters = async () => {
    setLoading(true)
    try{
      if(allChecked) {
        let promises:any[] = []
        await routers.map(async(item:any) => {
          let promise = await routerService.deleteRouter(item.id)
          await promises.push(promise)
        })
        await Promise.all(promises)
        await updateRouters()
        await setAllChecked(false)
      } else if (checked?.length > 0){
        let promises:any[] = []
        await checked.map((item:any) => {
            
          let promise = routerService.deleteRouter(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
        await updateRouters()
        await setChecked([])
      } else if (deletedRouter) {
          
        await routerService.deleteRouter(deletedRouter.id)
        await updateRouters()
      }
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      updateRouters()
      setPageLoading(false)
    } finally {
      setViewModal(false)
      setActiveAnimation(false)
      setLoading(false)
    }
  }
  const closeModal = (props:any) => {
    if(modalType === 'delete'){
      _deleteRouters()
    } else {
      if(editedRouter) {
        routerService.checkRouter(props.data.name)
        .then((res:any) => {
          // if(!res.data.router) {
            setLoadingCreate(true)
            routerService.editRouter(props.data.id, props.data)
            .then((res) => {
              viewAlert({
                severity:'info',
                message:`Router ${props.data.name} updated`
              })
              updateRouters()
              setLoadingCreate(false)
              props.handleClose()
              setActiveAnimation(false)
              setViewModal(false)
            })
          // } else {
          //   props.setError({
          //     name:`Router ${props.data.name} already exists`
          //   })
          //   setLoadingCreate(false)
          // }
        })
        .catch((err) => {
          if(err?.response?.data?.detail){
            viewAlert({
              severity:'error',
              message:err.response.data.detail,
            })
          }
          setLoadingCreate(false)
        })
      } else {
        setLoadingCreate(true)
        routerService.checkRouter(props.data.name)
        .then((res:any) => {
          if(!res.data.router) {
            routerService.createRouter(props.data)
            .then(async(res:any) => {
              viewAlert({
                severity:'info',
                message:`${t("ROUTER_CREATED_SUCCESSFULLY")}`
              })
              routerService.checkRouter(res.data.name)
              .then(async(router) => {
                try{
                  let promises:any[] = []
                  await props.subnets.map((sub:any) => {
                    let subData = {
                      ip:'',
                      // ip:sub.cidr.indexOf('0/24') !== -1 ? sub.cidr.replace('0/24', '1') : sub.cidr.replace('/24', ''),
                      subnet:sub.value
                    }
                    let promise = routerService.addInterfaceToRouter(router.data.router, subData)
                    promises.push(promise)
                  })
              
                  await Promise.all(promises)
                  await props.handleClose()
                  await updateRouters()
                  await setLoadingCreate(false)
                  // break
                } catch (e:any){
                    viewAlert({
                      severity:'error',
                      message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
                    })
                    await props.handleClose()
                    await updateRouters()
                    await setLoadingCreate(false)
                    // break
                } finally {
                  setActiveAnimation(false)
                  setViewModal(false)
                }
              })
            })
            .catch((err) => {
              if(err?.response?.data?.detail){
                viewAlert({
                  severity:'error',
                  message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
                }) 
              } else { 
                props.setErrors(err.response?.data)
                setLoadingCreate(false)
              }
            })
          } else {
            props.setError({
              name:`Router ${props.data.name} already exists`
            })
            setLoadingCreate(false)
          }
          
        })
      }
    }
  }
  const closeModalCancel = () => {
    setLoadingCreate(false)
    setViewModal(false)
    setEditedRouter(null)
    setActiveAnimation(false)
  }

  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (router:any) => {
        openModal('create-router')
        setEditedRouter(router)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(router:any) => {
        openModal('delete')
        setAlertTitle(t("ROUTER"))
        setAlertSubTitle([router])
        setDeletedRouter(router)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name',
    },
    {
      title:t("STATUS"),
      key:'status',
      sorted:false
    },
    {
      title:t("NETWORK_NAME"),
      key:'network_name',
      sorted:false
    },
    // {
    //   title:t("PLACEMENT_REGION"),
    //   key:'region',
    //   sorted:true
    // },
    {
      title:'',
      key:'props'
    },
  ]
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas.network.router.limit === quotas.network.router.in_use){
      viewAlert({
        severity:'error',
        message:`${t('ROUTER_QUOTA_EXCEEDED')}`,
      })
    } else {
      openModal('create-router')
    }
  }
  return (
    <>
      <TopProgress loading={pageLoading}/>
      <StartPage
        title={`${t("ROUTERS")}`}
        noResourceText={`${t("YOU_HAVE_NO_ROUTERS")}`}
        createResourceButton={`${t('CREATE_ROUTER')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={routers?.length > 0}
        learnMoreAboutResource={`${t('LEARN_MORE_ROUTERS')}`}
        howCreateVideoText={`${t('HOW_CREATE_ROUTER')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/routers`}
        howCreateVideoUrl="https://www.youtube.com/embed/XSceiIpejm4"
        pageLoading={pageLoading}
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("ROUTERS"))
                setAlertSubTitle(allChecked ? routers : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        videoImage={Illustration}
        videoImageDark={IllustrationDark}
        tableData={{
          columns:Columns,
          data:routers,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          paginationData:paginationData,
          updateData:updateRouters,
          // loading:loading,
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        loading={modalType === 'create-router' ? loadingCreate : loading}
        alertSubTitle={alertSubTitle}
        dataToEdit={editedRouter}
      />
    </>
  )
}


export default Routers

