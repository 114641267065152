import React, { FC,useRef,useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  BodyWrapper,
} from './styles'
import {
  InfoBlock,
  BackArrow,
  Button,
  TopProgress,
  ModalVariables,
  InfoSkeleton,
  DropOptions
} from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import MenuButton from '../../../components/button/menu-button'
import { icons } from '../../../icons'
import { PropsContainer } from '../../../components/table/styles'
import { authService, imageService, portService } from '../../../api'
import { useUi } from '../../../context/ui.context'
import {formatFileSize} from '../../../utils/sizes'
import {timeConverterMMDDYYHHMM} from '../../../utils/dates'
import { handleOsIcons } from '../../../hooks/useOsIcons'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
// import { saveAs } from 'file-saver';
import Skeleton from 'react-loading-skeleton'
import config from '../../../config'
import { InstanceDot, InstanceHeadInfo, InstanceStatus } from '../../Instances/components/instance-page/styles'
import { handleStatusName } from '../../../utils/handle-status-name'


const PortPage:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {portId} = useParams()
  const {viewAlert} = useUi()
  const [options, setOptions] = useState<boolean>(false)
  const [port, setPort] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const {setDataToLs} = useLocalStorage()
  const dropsRef:any = useRef()
  const optionsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }
  const _updatePort = () => {
    if(portId) {
      portService.getPort(portId)
      .then((res) => {
        setPort(res.data)
        setPageLoading(false)
      })
      .catch((err) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: err.response?.data?.detail
          })
        }
        navigate('/ports')
        setPageLoading(false)
      })
    }
  }
  useEffect(() => {
    _updatePort()
    const interval = setInterval(() => {
      _updatePort()
    },5000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const specifications = [
    {
      title:t("ID"),
      id:'1',
      value:port?.id,
      copy:true,
    },
    {
      title:t("ADMIN_STATE"),
      id:'2',
      value:port?.admin_state_up ? t("YES") : t("NO")
    },
    {
      title:t("MAC_ADDRESS"),
      id:'3',
      value:port?.mac_address
    },
    {
      title:t("DEVICE_ID"),
      id:'4',
      value:port?.device_id,
      copy:true
    },
    {
      title:t("DEVICE_OWNER"),
      id:'5',
      value:port?.device_owner
    },
    {
      title:t("NETWORK"),
      id:'6',
      value:port?.network,
      copy:true
    },
    // {
    //   title:t("SECURITY_GROUPS"),
    //   id:'7',
    //   value:port?.network,
    // },
    {
      title:t("CREATED_AT"),
      id:'8',
      value:timeConverterMMDDYYHHMM(port?.created_at)
    },
  ]

  const items = [
    {
      title:t('EDIT'),
      id:'3',
      icon:icons.Edit,
      onClick: () => {
        navigate(`edit`)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        openModal('delete')
        setAlertTitle(t("PORT"))
        setAlertSubTitle([port])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  
  const closeModal = async (values:any) => {
    if(port){
      let res:any;
      try {
        setLoading(true);
        switch (modalType) {
          case 'delete':
            await portService.deletePort(port.id);
            break; 
        }
        setViewModal(false)
        setActiveAnimation(false)
        if(modalType === 'delete'){
          navigate('/ports')
        }
      } catch (e: any) {
        console.log('e',e);
        if (e?.response?.data.detail) {
          viewAlert({
            severity: 'error',
            message: e.response.data.detail,
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }
  if(pageLoading || !port) return (
  // if(true) return (
    <Container>
      <TopProgress loading={pageLoading}/>
      <BodyWrapper>
        <InfoSkeleton size={9}/>
        <InfoSkeleton size={4}/>
      </BodyWrapper>
    </Container>
  )
  return(
    <Container>
      <BackArrow onClick={() => navigate('/ports')} title={t("BACK_TO_PORTS")}/>
      <HeadWrapper>
        <div>
          <InstanceName>
            {port?.name || port?.id}
          </InstanceName>
          <InstanceHeadInfo style={{margin:"0px"}}>
            <InstanceDot active={port.status}/>
            <InstanceStatus active={port.status}>
              {handleStatusName(port.status, t)}
            </InstanceStatus>
          </InstanceHeadInfo>
        </div>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              isFill
              title={t("MENU")}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          title={t("PORT_SPECIFICATIONS")}
          customStyles={{ width:"545px", flex:'auto 1', marginRight:'10px'}}
        />
        <InfoBlock
          data={port?.fixed_ips?.map((ip:any) => {
            return {
              title:ip?.ip_address
            }
          })}
          title={t("FIXED_IPS")}
          customStyles={{ width:"545px", flex:'auto 1', marginRight:'10px'}}
        />
      </TopInfoWrapper>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={port}
      />
    </Container>
  )
}
export default PortPage