import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-top: 20px;
  width:100%;
  padding-right: 20px;
  flex-wrap: wrap;
  flex-direction: column;
`
