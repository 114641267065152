import styled from 'styled-components'
import { BodyDefault, HeadLine2, VerySmall } from '../../../../../components/typography/styled'
import SVG from "react-inlinesvg";

type ActiveProp = {
  active?:boolean,
  disable?:boolean,
  hover?:boolean,
}


export const Container = styled.div`
  display: flex;
  padding-top:28px;
  flex-direction: column;
  margin-right: 10px;
`

export const Title = styled(HeadLine2)`
  color:${({theme}) => theme.palette[850]};
  margin-bottom:18px;
`

export const ButtonsWrapper = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  &>div{
    display:flex;
    flex-direction: row;
  }
`
export const PortContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 6px 10px;
  width: 100%;
  /* gap: 50px; */
  background-color:${({theme}) => theme.palette[200]};
  border-radius: 4px;
`

export const StyledPortItem = styled.div`
  display: flex;
  flex-direction: column;
  gap:2px;
  flex:1 auto;
  align-items: flex-start;
`

export const PortTitle = styled(VerySmall)`
  color:${({theme}) => theme.palette[850]};
`
export const PortSubtitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
`
export const BodyText = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
`

export const StyledIcon = styled(SVG)<ActiveProp>`
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  opacity: ${({hover, disable}) => hover || disable ? '0.7' : ''};
  filter: ${({active, disable}) => active && !disable ? 'brightness(77%)' : ''};
  & path {
    stroke:${({theme}) => theme.palette[800]};
  }
  &:hover {
    cursor: ${({disable}) => disable ? '' : 'pointer'};
    opacity: ${({hover}) => hover ? '' : '0.7'};
  }
  &:active{
    filter: ${({disable}) => !disable ? 'brightness(77%)' : ''};
  }
`