import React, {FC, useEffect, useState} from "react";
import {
  Container,
  BodyWrapper,
} from './styles'
import { TableSkeleton, TopProgress } from "../../../../components";
import { InfoContainer } from "../../../../components/info-block/styles";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";

const HistorySkeleton:FC<any> = ({pageLoading}) => {
  const theme:any = useTheme()
  return (
    <Container style={{gap:'10px'}}>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={235} height={40}
      />
      <BodyWrapper style={{marginTop:'10px'}}>
        <div style={{
          display:"flex",
          flexDirection:"column",
          gap:'5px',
        }}>
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={80} height={25}
          />
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={225} height={40}
          />
        </div>
        <div style={{
          display:"flex",
          flexDirection:"column",
          gap:'5px'
        }}>
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={80} height={25}
          />
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={225} height={40}
          />
        </div>
      </BodyWrapper>
      <div style={{
        display:"flex",
        flexDirection:'column',
        width:'100%',
        gap:'10px',
        flexGrow:'1',
        flexBasis:'0'
      }}>
        {['1','2','3','4','5','6','7','8','9'].map((_) => (
           <InfoContainer
           style={{
             display:'flex',
             flexDirection:'row',
             justifyContent:'space-between',
             alignItems:'center',
             flex:'1 1',
             marginBottom:'0px',
             flexGrow:'1',
             flexBasis:'0',
             boxSizing:'border-box',
           }}>
              <Skeleton
                baseColor={theme.palette[300]}
                highlightColor={theme.palette[20]}
                width={95} style={{marginBottom:"5px"}} height={25}
              />
              <Skeleton
                baseColor={theme.palette[300]}
                highlightColor={theme.palette[20]}
                width={55} style={{marginBottom:"5px"}} height={15}
              />
           </InfoContainer>
        ))}
      </div>
    </Container>
  )
}

export default HistorySkeleton