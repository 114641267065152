import React, { FC, useEffect, useState } from "react";
import {
  Container,
  PropsWrapper,
  DarkForm,
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { randomNameGenerator } from "../../../../utils/create-random-name";
import { Button, Input } from "../../../../components";
import { icons } from "../../../../icons";
import { useTranslation } from "react-i18next";
import { ErrorText } from "../../../Operations/operation-page/styles";
import { useNavigate } from "react-router-dom";
import { imageService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";


type ImageProp = {
  image: any,
  imageProps:any[]
}
function objectToArray(obj: any) {
  return Object.entries(obj).map(([key, value]) => ({ key, value }));
}
function arrayToObject(arr: { key: string, value: any }[]): { [key: string]: any } {
  return arr.reduce((obj: any, item: any) => {
    obj[item.key] = item.value;
    return obj;
  }, {});
}

const Properties: FC<ImageProp> = ({ image, imageProps }) => {
  const [t] = useTranslation()
  const { viewAlert } = useUi()
  const [properties, setProperties] = useState<any[]>(imageProps)
  const [initialValues, setInitialValues] = useState<any>({
    properties: imageProps
  })
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  const [errorsIps, setErrorsIps] = useState<any[]>([])

  const onSubmit = (values: any) => {
    setLoading(true)
    const data = {
      ...image,
      properties: arrayToObject(values.properties)
    }
    console.log('data', data);
    imageService.editImage(image.id, data)
      .then((res) => {
        viewAlert({
          severity: 'info',
          message: `${t('IMAGE_SUCCESSFULLY_EDITED')}`
        })
        setLoadingComplete(true)
        setTimeout(() => {
          setLoading(false)
          setLoadingComplete(false)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data.detail
            })
          }
          navigate(`/images/${image.id}`)
        }, 1000)

      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          viewAlert({
            severity: 'error',
            message: e.response.data?.detail
          })
          setLoading(false)
        } else {
          setErrorsIps(e?.response?.data)
          setErrors(e?.response?.data)
          setLoading(false)
        }
      })
  }


  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    setFieldValue,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      properties: yup.array().of(
        yup.object().shape({
          key: yup.string(),
          value: yup.string(),
        })
      ),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const addObjToArr = () => {
    const newItem = { key: '', value: '', id: randomNameGenerator({ name: 'prop', findArr: properties }) }
    setProperties((prev) => [...prev, newItem])
    setFieldValue("properties", properties);
  }
  const removeObj = (id: string, index?: any) => {
    const newItems = values.properties.map((prop:any) => ({
      ...prop,
      value: prop.id === id ? '' : prop.value
    }));
    setFieldValue("properties", newItems);
    setProperties((prev) => prev.filter((ip: any) => ip.id !== id))
  }
  const handleInputChange = (index:any, field:any, value:any, data:any[], setData:(data:any[]) => void, yupField:any) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setFieldValue(yupField, updatedData);
  };
  return (
    <Container>
      <PropsWrapper>
        {properties.length > 0 && properties.map((field: any, index: any) => (
          <DarkForm key={field.id}>
            <Input
              fieldName={`properties[${index}].key`}
              title={t("PROPERTY_KEY")}
              defaultValue={properties[index].key}
              placeholder=''
              {...formikProps}
              handleChange={(e:any) => handleInputChange(index, 'key', e.target.value, properties, setProperties, 'properties')}
              containerCustom={{ width: '100%', flex: 'auto 1' }}
              arrayFields={true}
              arrayName={'properties'}
              arrayIndex={index}
              arrayFieldName={'key'}
            />
            <Input
              fieldName={`properties[${index}].value`}
              title={t("PROPERTY_VALUE")}
              defaultValue={properties[index].value}
              placeholder=''
              {...formikProps}
              handleChange={(e:any) => handleInputChange(index, 'value', e.target.value, properties, setProperties, 'properties')}
              containerCustom={{ width: '100%', flex: 'auto 1' }}
              arrayFields={true}
              arrayName={'properties'}
              arrayIndex={index}
              arrayFieldName={'value'}
            />
            <Button
              onClick={() => {
                // handleInputChange(index, 'value', '', properties, setProperties, 'properties')
                removeObj(field.id, index)
              }}
              variant='icon'
              size='display'
              customStyles={{ marginTop: '24px' }}
            >
              <img src={icons.DeleteIcon} />
            </Button>
            <ErrorText>
              {errorsIps?.length > 0 && errorsIps?.[index]?.ip_address}
            </ErrorText>
          </DarkForm>
        ))}
      </PropsWrapper>
      <Button
        variant="stroke"
        size="display"
        icon={icons.PlusDefault}
        onClick={() => {
          addObjToArr()
        }}
        title={t("ADD_IMAGE_PROPERTY")}
      />
      <div style={{ display: 'flex' }}>
        <Button
          variant="primary"
          size="display"
          customStyles={{ marginRight: "10px" }}
          onClick={handleSubmit}
          loading={loading}
          loadingComplete={loadingComplete}
          title={t("EDIT")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate("/images")}
          title={t("CANCEL")}
        />
      </div>
    </Container>
  )
}

export default Properties