import React, {FC, useState} from 'react';
import {
  Container,
  TitleWrapper,
  HeadCardContainer,
  Datetext,
  AttachmentsWrapper,
  AttachmentContainer,
  AttachmentImg,
  AttachmentTitleWrapper,
  AttachmentTitleRow,
  AttachmentName,
  AttachmentSize,
  Attachments,
  DivMessage
} from './styles'
import Gravatar from 'react-gravatar'
import { Typography, PrevImage } from '../../../../../components';
import { useTheme } from 'styled-components';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import { icons } from '../../../../../icons';
import StyledIcon from '../../../../../components/styled-icon'
import { formatFileSize } from '../../../../../utils/sizes';
type MessageProps = {
  message: string,
  email?: string,
  displayName: string,
  time: any,
  active?: boolean,
  attachments:any[],
}

const TicketMessageComponent:FC<MessageProps> = (props) => {
  const {
    message,
    email,
    displayName,
    time,
    active,
    attachments
  } = props
  const theme:any = useTheme()
  const [t] = useTranslation()
  return(
    <Container active={active}>
      <HeadCardContainer>
        <Gravatar
          email={email}
          size={40}
          style={{borderRadius: '100%'}}
        />
        <TitleWrapper>
          <Typography variant='body-medium'>
            {displayName}
          </Typography>
          <Datetext>
            {moment(time * 1000).fromNow()}
          </Datetext>
        </TitleWrapper>
      </HeadCardContainer>
      <DivMessage dangerouslySetInnerHTML={{__html:message}}/>
      {attachments?.length > 0 && 
        <Attachments>
          <Typography variant='h3'>{t("ATTACHMENTS")}</Typography>
          <AttachmentsWrapper>
            {attachments.map((attachment:any) => (
              <AttachmentComponent
                size={attachment.upload.size}
                name={attachment.original_name}
                url={attachment.direct_frontend_url}
              />
            ))}
          </AttachmentsWrapper>
        </Attachments>
      }
    </Container>
  )
}

export default TicketMessageComponent

export const AttachmentComponent:FC<any> = (props) => {
  const {
    size,
    name,
    url,
    isUpload,
    handleDelete,
  } = props
  const imgTypes = ['png', 'jpg', 'jpeg', 'svg']
  const [viewImage, setViewImage] = useState<boolean>(false)
  return (
    <AttachmentContainer style={isUpload ? {alignItems:"flex-start"} : {}}>
      {/* <img src={`https://support.onepointcollab.com/en/admin/ticket/attachment/${attachment.upload.hash}/preview`}/> */}
      <AttachmentTitleWrapper onClick={() => setViewImage(true)}>
        <AttachmentImg src={imgTypes.some((char:any) => name.endsWith(char)) ? url : icons.Document}/>
        <AttachmentTitleRow>
          <AttachmentName>
            {name}
          </AttachmentName>
          <AttachmentSize>
            {isUpload ? formatFileSize(size) : size}
          </AttachmentSize>
        </AttachmentTitleRow>
      </AttachmentTitleWrapper>
      {isUpload ? 
        <StyledIcon onClick={() => {
            // window.location.href = url
            handleDelete(name)
          }} active={false} src={icons.CloseIconBold}
        />
          :
        <StyledIcon onClick={() => {
            window.location.href = url
          }} active={false} src={icons.IcDownload}
        />
      }
      {imgTypes.some((char:any) => name.endsWith(char)) ? 
        <PrevImage handleClose={() => {
          setViewImage(false)}} activeProp={viewImage} url={url}
        />
      : null}
    </AttachmentContainer>
  )
}