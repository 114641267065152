interface Measure {
  bytes_in: number;
  bytes_out: number;
  packets_in: number;
  packets_out: number;
  date: string;
}

function roundToHalf(value: number): number {
  const roundedValue = Math.round(value);
  return roundedValue;
}

export function calculateScales(data: any[],): any {
  const packetsScale: number[] = [];
  const bytesScale: number[] = [];

  if (data.length === 0) {
    return [packetsScale, bytesScale];
  }

  // Находим максимальные значения packets_in, packets_out, bytes_in и bytes_out
  const maxPacketsIn = Math.max(...data.map((measure) => measure.bwrate_in));
  const maxPacketsOut = Math.max(...data.map((measure) => measure.bwrate_out));
  const maxBytesIn = Math.max(...data.map((measure) => measure.traffic_in));
  const maxBytesOut = Math.max(...data.map((measure) => measure.traffic_out));

  // Определяем количество точек (в данном случае, 11)
  const numPoints = 8;

  // Вычисляем шаг для шкалы, используя максимальные значения из обоих полей
  const packetsStep = Math.max(maxPacketsIn, maxPacketsOut) / (numPoints - 1);
  const bytesStep = Math.max(maxBytesIn, maxBytesOut) / (numPoints - 1);

  // Создаем шкалы
  for (let i = 0; i < numPoints; i++) {
    const packetsValue = i * packetsStep;
    const bytesValue = i * bytesStep;

    // Округляем до половины с учетом знака
    const roundedPackets = roundToHalf(packetsValue);
    const roundedBytes = roundToHalf(bytesValue);
    const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
    const sizes1 = ['', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    //@ts-ignore
    packetsScale.push(transformValues(packetsValue, 2,sizes));
    //@ts-ignore
    bytesScale.push(transformValues(roundedBytes, 2 ,sizes1));
  }

  return [packetsScale?.reverse(), bytesScale?.reverse()];
}



function transformValues(value:any, decimals:any, sizes:any) {
  const dm = decimals || 2;
  if (value <= 1) {
    return value.toFixed(dm) + ' ' + sizes[0];
  }
  const k = 1000;
  const i = Math.floor(Math.log(value) / Math.log(k));
  return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function createPercentageArray(length:any) {
  if (length < 1) {
    return [];
  }

  const step = 100 / (length - 1);
  const result = [];

  for (let i = 0; i < length; i++) {
    result.push(i * step);
  }

  return result;
}

export function createPercentageArrays(data: any[], fieldName: string): [number[], number[], string, number][] {
  const step = Math.floor(data.length / 100);
  const maxPacketsIn = Math.max(...data.map((measure) => measure.bwrate_in));
  const maxPacketsOut = Math.max(...data.map((measure) => measure.bwrate_out));
  const maxBytesIn = Math.max(...data.map((measure) => measure.traffic_in));
  const maxBytesOut = Math.max(...data.map((measure) => measure.traffic_out));


  const packetsStep = Math.max(maxPacketsIn, maxPacketsOut);
  const bytesStep = Math.max(maxBytesIn, maxBytesOut);

  const isBytes = fieldName === 'bytes_in' || fieldName === 'bytes_out'
  const resultArrays: any = data.map((measure:any, index:any) => {
    const percentValues = createPercentageArray(data?.length);
    const percentValue = measure[fieldName] === 0 ? 0 : (measure[fieldName] / (isBytes ? bytesStep : packetsStep)) * 100;

    return [percentValues[index], percentValue, measure.time, measure[fieldName].toFixed(2)];
  });
  // console.log('resultArrays',resultArrays);
  return resultArrays;
}

