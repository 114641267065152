import styled from 'styled-components'
import { HeadLine2 } from '../../components/typography/styled'

export const RightSide = styled.div`
  background-position: center;
  /* background-size: 100%; */
  display: flex;
  box-sizing: border-box;
  position: relative;
  background-color: ${({theme}) => theme.success};
  justify-content: center;
  /* align-items: center; */
  width:90vw;
  height: 100%;
  padding: 82px;
  border-radius: 30px;
  background-repeat: no-repeat;
  @media screen and (max-width: 1100px) {
    display: none;
  }
  @media screen and (max-height: 450px) {
    background-image: none !important;
  }
`

export const Loader = styled.div`
   @keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}
	width: 70px;
  height: 70px;
	border:solid 4px #4671F6;
	border-radius: 50%;
	border-right-color: transparent;
	border-bottom-color: transparent;
  transition: all 0.5s ease-in;
  animation-name: rotate; 
  animation-duration: 1.0s; 
  animation-iteration-count:  infinite;
  animation-timing-function: linear; 
  position: absolute;
`

export const AnimationWrapper = styled.div`
  display: flex;
  align-items: center;
  position:relative;
  text-align: center;
  margin-bottom: 20px;
`

export const AnimationBackground = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
	border:solid 4px #DBDBDB;
`

export const Timer = styled(HeadLine2)`
  color:${({theme}) => theme.grey[800]};
  position: absolute;
  left:9%;
`