import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 30px 30px 20px;
  width: 100%;
  height:100%;
  gap:20px;
  /* position: relative; */
`