import styled from "styled-components";
import { BodyDefault } from "../../components/typography/styled";

export const Container = styled.div`
  padding: 10px 30px 10px 20px;
`
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-top:60px;
`
export const GreyText = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
  margin:20px 0px;
`

export const BlueText = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
`

export const LinkWrapper = styled.div`
  display: flex;
  gap:10px;
  margin-top: 20px;
  &:hover {
    cursor:pointer;
  }
`