import React, { FC } from "react";
import {
  MetricData,
  VerticalBorder
} from './styles'
import moment from 'moment'
// import 'moment-round'

interface DateViewProps {
  hours:string;
  data: any[];
}

// function parseISO8601Date(dateStr:any) {
//   return new Date(dateStr).getTime();
// }
// function roundDate(date:Date) {
//   //@ts-ignore
//   return date.getMinutes() > 30 ? date.setMinutes(0) : date.setMinutes(0)?.setHours(date.getHours() + 1)
// }
function parseISO8601Date(date:any) {
  const roundedDate = new Date(date);

  const minutes = roundedDate.getMinutes();

  if (minutes >= 30) {
    roundedDate.setHours(roundedDate.getHours() + 1);
  }
    roundedDate.setMinutes(0);

  // roundedDate.setSeconds(0);
  // roundedDate.setMilliseconds(0);

  return roundedDate.getTime();
  // return new Date(date).getTime();
}

// function filterAndStepDates(dates:any,type:string) {
//   let prevDate = parseISO8601Date(dates[0][2]);
//   const result = [];
//   const maxResultLength = 10;
//   for (let i = 1; i < dates.length; i++) {
//     const currentDate = parseISO8601Date(dates[i][2]);
//     const diffInHours = (currentDate - prevDate) / (60 * 60 * 1000);
//     const diffInDay = (currentDate - prevDate) / (24 * 60 * 60 * 1000);
//     if(type === 'hour'){
//       if (diffInHours >= 1.9) {
//         result.push(new Date(prevDate).toISOString());
//         prevDate = currentDate;
//       }
//     }
//     if(type === 'day'){
//       if (diffInHours >= 23) {
//         result.push(new Date(prevDate).toISOString());
//         prevDate = currentDate;
//       }
//     }
//     if(type === 'month'){
//       if (diffInHours >= 23) {
//         result.push(new Date(prevDate).toISOString());
//         prevDate = currentDate;
//       }
//     }


//   }
//   return [...result, ''];
// }



function filterAndStepDates(dates:any, type:any) {
  let prevDate = parseISO8601Date(dates[0][2]);
  const result = [];
  const maxResultLength = 10;

  if (dates.length <= maxResultLength) {
    // Если исходный массив уже короткий, просто возвращаем его
    return dates.map((date:any) => new Date(parseISO8601Date(date[2])).toISOString());
  }

  const step = Math.floor(dates.length / maxResultLength);

  for (let i = 1; i < dates.length; i += step) {
    const currentDate = parseISO8601Date(dates[i][2]);
    const diffInHours = (currentDate - prevDate) / (60 * 60 * 1000);

    if (type === 'hour' && diffInHours >= 1.9) {
      result.push(new Date(prevDate).toISOString());
      prevDate = currentDate;
    }

    if (type === 'day' && diffInHours >= 23) {
      result.push(new Date(prevDate).toISOString());
      prevDate = currentDate;
    }

    if (type === 'month' && diffInHours >= 23) {
      result.push(new Date(prevDate).toISOString());
      prevDate = currentDate;
    }
  }

  // Добавляем последнюю дату
  result.push(new Date(prevDate).toISOString());

  return result;
}


const DateView:FC<DateViewProps> = ({hours, data}) => {
  switch(hours){
    case '1':
      return(
        <>
          {[...data].map((item:any) => (
            <MetricData
              style={{
                // position: 'relative',
                // justifyContent:'center',
                // alignItems: 'center',
                display: 'flex'
              }}
            >
              <VerticalBorder/>
              {item === '' ? '' : moment(item[2]).format('LT')}
            </MetricData>
          ))}
        </>
      )
    case '11111':
      return(
        <>
          {[...data].map((item:any, index:any) => index % 2 === 0 &&(
            <MetricData
              style={{
                // position: 'relative',
                // justifyContent:'center',
                // alignItems: 'center',
                // display: 'flex'
              }}
            >
              <VerticalBorder/>
              {item === '' ? '' : moment(item[2]).format("MMM D")}
              {/* {item === '' ? '' : moment(item[2]).format('ll')} */}
            </MetricData>
          ))}
        </>
      )
    case '24h':
      return(
        <>
          {filterAndStepDates(data, 'hour').map((item:any, index:any) => (
            <MetricData
            style={{display: 'flex', justifyContent:'center', flex:'1 1'}}
          >
            <VerticalBorder/>
              {item === '' ? '' : moment(item).format('LT')}
            </MetricData>
          ))}
        </>
      )
    case '24':
      return(
        <>
          {filterAndStepDates(data, 'hour').map((item:any, index:any) => (
            <MetricData
            style={{display: 'flex', justifyContent:'center', flex:'1 1'}}
          >
            <VerticalBorder/>
              {item === '' ? '' : moment(item).format('LT')}
            </MetricData>
          ))}
        </>
      )
      case '14days':
        return(
          <>
            {filterAndStepDates(data, 'day').map((item:any) => (
              <MetricData>
                <VerticalBorder/>
                {item === '' ? '' : moment(item).format('ll')}
              </MetricData>
            ))}
          </>
        )
    case '168':
      return(
        <>
          {filterAndStepDates(data, 'day').map((item:any) => (
            <MetricData>
              <VerticalBorder/>
              {item === '' ? '' : moment(item).format('ll')}
            </MetricData>
          ))}
        </>
      )
      // 
      // 
    case '30days':
      return(
        <>
          {filterAndStepDates(data, 'month').map((item:any) => (
            <MetricData>
              <VerticalBorder/>
              {item === '' ? '' : moment(item).format('ll')}
            </MetricData>
          ))}
        </>
      )
    case '720':
      return(
        <>
          {filterAndStepDates(data, 'month').map((item:any) => (
            <MetricData>
              <VerticalBorder/>
              {item === '' ? '' : moment(item).format('ll')}
            </MetricData>
          ))}
        </>
      )
    case 'all':
      return(
        <>
          {filterAndStepDates(data, 'month').map((item:any) => (
            <MetricData>
              <VerticalBorder/>
              {item === '' ? '' : moment(item).format('ll')}
            </MetricData>
          ))}
        </>
      )
    default:
      return <></>
  }

}

export default DateView;