import React, { FC, useState } from "react";
import {
  InfoContainer,
  InfoTitle,
  InfoName,
  InfoItem,
  InfoValue,
  DropInfo,
  TitleWrapper,
  CountItems,
  InfoNameGrey,
  InfoNameBold,
  NameBorder,
  Divider,
  InfoSubtitle,
  CopyIcon,
  BodyValue,
  PropContainer,
  Prop,
  PropsWrapper,
  CopyContainer,
  InfoValueText,
  StyledIconCopy
} from './styles'
import { Toggle, Button, Input, Counter } from '..'
import { useQueryParam, StringParam } from 'use-query-params';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { icons } from "../../icons";
import copy from 'copy-to-clipboard';

import { ChevronIc } from "../header/styles";
import { BodyDefault, BodySmall } from "../typography/styled";

type InfoProps = {
  data: any[],
  title?: any,
  customStyles?: any,
  dropped?: boolean,
  noTitles?: boolean,
  customDropped?: any,
  customAddComponent?: any,
}
const InfoBlock: FC<InfoProps> = (props) => {
  const {
    data,
    title,
    customStyles,
    dropped,
    noTitles,
    customDropped,
    customAddComponent,
  } = props
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [drop, setDrop] = useState<boolean>(false)
  return (
    <InfoContainer customStyles={customStyles}>
      <TitleWrapper
        dropped={dropped}
        style={dropped ? drop ? { marginBottom: "10px" } : {} : title ? { marginBottom: "10px" } : {}}
        onClick={() => setDrop(!drop)}
      >
        {dropped ?
          <Button
            onClick={() => setDrop(!drop)}
            variant='icon'
            size='small'
            active={drop}
            // disabled
            customStyles={
              drop ?
                { width: '22px', height: "22px", margin: '0px 10px 0px 0px' }
              :
                { width: '22px', height: "22px", margin: '0px 10px 0px 0px' }
            }
          >
            <ChevronIc active={drop} src={icons.Chevron} />
          </Button>
          : null}
        {title ?
          <InfoTitle>
            {title}
          </InfoTitle>
        : null}
        {dropped && !customDropped ?
          <CountItems>
            {data.length}&nbsp;{t("ITEMS")}
          </CountItems>
          : null}
      </TitleWrapper>
      <DropInfo dropped={dropped} active={dropped ? drop : true}>
        {customDropped ? customDropped : data.map((item: any) => (
          <InfoItemView noTitles={noTitles} item={item} />
        ))}
      </DropInfo>
      <>
        {customAddComponent}
      </>
    </InfoContainer>
  )
}

export default InfoBlock

const InfoItemView: FC<any> = ({ item, noTitles }) => {
  const [copyText, setCopyText] = useState<boolean>(false)
  const [t] = useTranslation()
  const setIsCopied = () => {
    setCopyText(true)
    setTimeout(() => {
      setCopyText(false)
    },1500)
  }
  if (item.type === 'divider') return <Divider />
  if (item.type === 'subtitle') return <InfoSubtitle>{item.title}</InfoSubtitle>
  return (
    <InfoItem>
      {noTitles ? null :
        <InfoName>
          {item.title}
        </InfoName>
      }
      <BodyValue onClick={item.copy ? () => {
          setIsCopied()
          copy(item.value)}
         : () => {}}>
        <InfoValue copy={item.copy}>
          {item.custom ? item.custom : item.left ? item.options ? item.optionsColumn ?
            <div>
              {item.options.map((it: string) => (
                <InfoValue>
                  {it}
                </InfoValue>
              ))}
            </div>
            :
            <PropsWrapper>
              {item.options && item.options.map((it: string) => (
                <PropContainer>
                  <Prop>
                    {it}
                  </Prop>
                </PropContainer>
              ))}
            </PropsWrapper>
            : null : null}
          {item.icon ?
            <img style={{ marginRight: "4px" }} src={item?.icon} />
            : null}
          <InfoValueText isLong={item?.isLong} copy={item.copy}>
            {item.value}
          </InfoValueText>
          {item.options && !item.left ?
            <PropsWrapper style={{ marginLeft: '6px' }}>
              {item.options.map((it: string) => (
                <PropContainer>
                  <Prop>
                    {it}
                  </Prop>
                </PropContainer>
              ))}
            </PropsWrapper>
            : null}
        </InfoValue>

        {item.copy && item.value && item.value !== '-' ? 
          <div style={{position:'relative'}}>
            {item.copy && copyText ?
              <CopyContainer>
                <BodySmall>
                  {t("COPIED")}
                </BodySmall>
              </CopyContainer>
            : null}
            <CopyIcon style={{ marginLeft: '6px', cursor:'pointer' }} src={icons.Copy} />
          </div>
        : null}
      </BodyValue>
    </InfoItem>
  )
}