import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding:20px 0px;
`

export const LabelWrapper = styled.div`
  gap:6px;
  display: flex;
  flex-direction: column;
`