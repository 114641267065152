import styled from 'styled-components'

export const Container = styled.div`
  padding-top:20px;
  padding-right:20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap:20px;
`
export const DarkForm = styled.div`
  display: flex;
  background-color: ${({theme}) => theme.palette[200]};
  align-items: flex-start;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
  /* width:100%; */
  padding: 14px;
  gap:10px;
`
