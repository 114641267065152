export default {
  colors:{
    10:'#242424',
    20:'#393939',
    30:'#393939',

    
    25: '#F9FAFE',
    40: '#F1F4F9',
    50: '#E8EEF9',
    100: '#242424',
    200: '#393939',
    300: '#2D2D2D',
    350: '#393939',
    400: '#505050',
    600: '#6D6D6D',
    650: '#A7A7A7',
    700: '#A7A7A7',
    800: '#E3E3E3',
    850: '#FCFBFC',
    900: '#FCFBFC',
  }
}