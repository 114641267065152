import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BackArrow,
  Input,
  Select,
  Button,
  RightInformation,
  TopProgress
} from '../../../components'
import {
  Container,
  TitleBig,
  CreateContainer,
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { securityService } from '../../../api'
import { useUi } from '../../../context/ui.context'



const CreateSecurityGroup:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const {groupId} = useParams()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [regions, setRegions] = useState<any[]>([])
  const [securityGroup, setSecurityGroup] = useState<any>()
  const [initialValues, setInitialValues ] = useState<any>({
    name:'',
    region:'',
    description:''
  })
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      name:values.name,
      region:values.region,
      description:values.description,
    }
    if(groupId) {
      securityService.editGroup(groupId, data)
        .then(() => {
          setPageLoading(true);
          setTimeout(() => {
            setPageLoading(false);
            setLoading(false)
            navigate("/security-groups")
          },1000)
        })
        .catch((e:any) => {
          if(e?.response?.data.detail){
            viewAlert({
              severity:'error',
              message:e.response.data.detail,
            })
          } else if(e?.response?.data) {
            setErrors(e.response.data)
          } else {
            viewAlert({
              severity:'error',
              message:`${t("ERROR_OCCURRED")}`,
            })
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      securityService.createGroup(data)
      .then((res:any) => {
        setPageLoading(true);
        setTimeout(() => {
          setPageLoading(false);
          navigate("/security-groups")
        },1000)
      })
      .catch((e:any) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        } else if(e?.response?.data) {
          setErrors(e.response.data)
        } else {
          viewAlert({
            severity:'error',
            message:`${t("ERROR_OCCURRED")}`,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setErrors,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required(`${t('REQUIRED_FIELD')}`)
        .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
        .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      region: yup.string().required(`${t('REQUIRED_FIELD')}`),
      description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });


  useEffect(() => {
    securityService.getCreateOptions()
    .then((res:any) => {
      setRegions(res.data.regions.map((reg:any) => {
        return{
          value:reg.id,
          label:reg.id
        }
      }))
      if(groupId){
        securityService.getGroupById(groupId)
        .then((res) => {
          setSecurityGroup(res.data)
          setPageLoading(false)
          setInitialValues({
            name:res.data.name,
            region:res.data.region,
            description:res.data.description,
          })
        })
        .catch((err) => {
          viewAlert({
            severity:"error",
            message:`${t("UNABLE_TO_FIND_SECURITY_GROUP")}`
          })
          navigate("/security-groups")
        })
      } else {
        setPageLoading(false)
        handleChange('region')(res.data.regions?.[0]?.id)
      }
      
    })
    .catch((e:any) => {
      console.log('err get create options', e);
    })
  },[])

  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading} />
  return(
    <CreateContainer>
      <Container>
        <BackArrow
          onClick={() => navigate('/security-groups')}
          title={t("BACK_TO_SECURITY_GROUPS")}
        />
        <TitleBig>{groupId ? `${t("EDIT")} ${securityGroup.name}` : t("CREATE_A_NEW_SECURITY_GROUP")}</TitleBig>
        <div style={{display:'flex', flexDirection:'column', gap:'20px'}}>
          <Input
            fieldName='name'
            title={t("NAME")}
            customStyles={{width:'360px'}}
            placeholder=''
            {...formikProps}
          />
          {/* <Select
            toolTip={false}
            data={regions}
            selectedValue={values['region']}
            onChange={(reg) => handleChange('region')(reg)}
            title={t("PLACEMENT_REGION")}
            customStyles={{width:'360px'}}
          /> */}
          <Input
            isTextArea
            fieldName='description'
            title={t("DESCRIPTION")}
            customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
            placeholder=''
            {...formikProps}
          />
        </div>
        <div style={{display:'flex', marginTop:'40px'}}>
          <Button
            variant="primary"
            size="display"
            customStyles={{marginRight:"10px"}}
            onClick={handleSubmit}
            loading={loading}
            loadingComplete={loadingComplete}
            title={t("CREATE")}
          />
          <Button
            variant="stroke"
            size="display"
            onClick={() => navigate("/security-groups")}
            title={t("CANCEL")}
          />
        </div>
      </Container>
      <RightInformation
        customStyles={{marginTop:'101px'}}
        info={t("INFO_CREATE_SECURITY_GROUP")}
      />
    </CreateContainer>
  )
}

export default CreateSecurityGroup