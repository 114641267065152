import styled from "styled-components";
import { BodySmall, HeadLine1 } from '../../../../components/typography/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 30px 30px 30px;
  /* position: relative; */
`
export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
`

export const RightHeadContainer = styled.div`
  display:flex;
  position: relative;
`

export const InstanceName = styled(HeadLine1)`
  color:${({theme}) => theme.palette[800]};
  word-break:break-all;
  margin-top:10px;
  margin-bottom:10px;
`
export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  width: 100%;
  display: flex;
  margin-top:50px;
  &>div{
    position: absolute;
    bottom:-1px;
    left:0px;
  }
`
export const InstanceHeadInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`