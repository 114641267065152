import styled from "styled-components";
import { HeadLine1 } from "../../components/typography/styled";

export const Container = styled.div`
display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 20px;
  position: relative;
`
export const InstanceName = styled(HeadLine1)`
  color:${({theme}) => theme.palette[800]};
  margin-top:10px;
  margin-bottom:10px;
`
export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  display: flex;
  margin-right:10px;
  margin-top:50px;
  &>div{
    position: absolute;
    bottom:-1px;
    left:0px;
  }
`

export const TabContainer = styled.div`
  margin-top:20px;
`


export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px;
  width: 400px;
  /* height: 408px; */
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette[10]};
  border-radius: 8px;
`

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-start;
`
export const BodyModal = styled.div`
  /* display:flex; */
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding:0px 10px;
  width:100%;
`
export const Icon = styled.img`
  &:hover{
    cursor: pointer;
  }
`
export const PasswordWrapper = styled.div`
  position: relative;
  width:100%;
  margin-bottom: 10px;
`

export const LabelWrapper = styled.div`
  gap:6px;
  display: flex;
  flex-direction: column;
`