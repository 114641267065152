import styled from "styled-components";
import { HeadLine1 } from "../../../../components/typography/styled";
type ActiveOverlay = {
  active: boolean
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 10px 30px 50px 30px; */
  position: relative;
`

export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  margin-top: 20px;
  margin-bottom: 20px;
`
export const FormWrapper = styled.div`
  display:flex;
  width: 360px;
  flex-direction: column;
  gap:20px;
`
export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  display: flex;
  margin-right:10px;
  margin-top:50px;
  margin-bottom:20px;
  &>div{
    position: absolute;
    bottom:-1px;
    left:0px;
  }
`
export const CreatedOverlay = styled.div<ActiveOverlay>`
  position:absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: ${({active}) => active ? 'flex' : 'none'};
  background: ${({active}) => active ? 'rgba(255, 255, 255, 0.7)' : ''};
`