import styled from "styled-components";


export const BodyForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding-right: 30px; */
  box-sizing: border-box;
  /* align-items: flex-start; */
  /* justify-content: space-between; */
  /* height: 852px; */
`

export const InputsWrapper = styled.div`
  display:flex;
  flex-direction: column;
  width: 390px;
  justify-content: space-between;
  margin-top: 20px;
  gap:20px;
  margin-bottom:20px;
`
