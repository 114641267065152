import styled, { css } from 'styled-components'

type StyleButtonProps = {
  size?: 'display' | 'mid' | 'small' | 'custom',
  customStyles?:any,
  isIcon?:boolean
  active?:boolean,
}
function styleMyEl(size:any, customStyles?:string,isIcon?:boolean) {
  switch (size) {
    case 'display':
      return css`
        /* width: 106px; */
        padding:${isIcon ? '10px 16px 10px 13px' : '10px 16px'};
        height: 40px;
        ${customStyles}
      `;
    case 'mid':
      return css`
        /* width: 106px; */
        padding:${isIcon ? '10px 16px 10px 13px' : '10px 16px'};
        height: 36px;
        ${customStyles}
      `;
    case 'small':
      return css`
        /* width: 94px; */
        padding:${isIcon ? '0px 10px 0px 7px' : '0px 10px'};
        height: 30px;
        ${customStyles}
      `;
    case 'custom':
      return css`
        ${customStyles}
      `;
    default:
      return css`
        /* width: 94px; */
        height: 30px;
        ${customStyles}
      `;
  }
}
const StrokeButton = styled.button<StyleButtonProps>`
  display: flex;
  align-items: center;
  
  background: ${({theme}) => theme.palette[10]};
  color: ${({theme}) => theme.isDark ? theme.palette[850] : theme.palette[850]};
  /* border: ${({theme}) => `1px solid ${theme.palette[20]}`}; */
  border-radius: 4px;
  /* justify-content: center; */
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); */
  border: ${({theme, active}) => active ? theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[200]}` : `1px solid ${theme.palette[20]}`};
  box-shadow:${({theme, active}) => active ?  theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF` : ''};
  ${({size, customStyles, isIcon}) => styleMyEl(size, customStyles, isIcon)};
  &:hover{
    color: ${({theme}) => theme.palette[900]};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  &:active{
    /* border-color: ${({theme}) => theme.primary[200]}; */
    color: ${({theme}) => theme.palette[900]};
    border: ${({theme}) => theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[200]}`};
    box-shadow: ${({theme}) => theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`};
  }
  &:disabled{
    background: ${({theme}) => theme.grey[100]};
    color:#858A94;
    border-color: #DBDBDB;
  }
`

export default StrokeButton