export const  timeConverterMMDDYYHHMM = (timestamp:any) => {
  const utc = Date.parse(timestamp)
  const a = new Date(timestamp);
  const monthsUa = ['Січ','Лют','Бер','Квіт','Трав','Черв','Лип','Сер','Вер','Жов','Лис','Груд'];
  const monthsEn = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  return `${localStorage.getItem('lang') === 'uk' ? monthsUa[a.getMonth()] : monthsEn[a.getMonth()]}   ${a.getDate()} ${a.getFullYear()},
  ${a.getHours().toString().length > 1 ?
    a.getHours():'0' + (a.getHours())
  }:${a.getMinutes().toString().length > 1 ?
    a.getMinutes()
    : 
    '0' + (a.getMinutes())
  }`;
}
export function convertUTCtoLocalDate(utcDate: Date): string {
  const options:any = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const localDate = new Date(utcDate.toLocaleString('en-US', options));
  const day = localDate.getDate();
  const month = localDate.getMonth() + 1;
  const year = localDate.getFullYear();
  return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
}

export function filterByToday(data: any[], dateFieldName: string): any[] {
  // Получаем текущую дату
  const today = new Date();
  // Приводим текущую дату к формату "YYYY-MM-DD"
  const todayString = today.toISOString().split('T')[0];
  
  // Фильтруем данные, оставляя только те, у которых дата соответствует сегодняшнему дню
  const filteredData = data.filter(item => {
    const createdAt = new Date(item[dateFieldName]);
    const createdAtString = createdAt.toISOString().split('T')[0];
    return createdAtString === todayString;
  });

  return filteredData;
}