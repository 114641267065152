interface Measure {
  bytes_in: number;
  bytes_out: number;
  packets_in: number;
  packets_out: number;
  date: string;
}

function roundToHalf(value: number): number {
  const roundedValue = Math.round(value);
  return roundedValue;
}

export function calculateScales(data: Measure[],): any {
  const packetsScale: number[] = [];
  const bytesScale: number[] = [];

  if (data.length === 0) {
    return [packetsScale, bytesScale];
  }

  // Находим максимальные значения packets_in, packets_out, bytes_in и bytes_out
  const maxPacketsIn = Math.max(...data.map((measure) => measure.packets_in));
  const maxPacketsOut = Math.max(...data.map((measure) => measure.packets_out));
  const maxBytesIn = Math.max(...data.map((measure) => measure.bytes_in));
  const maxBytesOut = Math.max(...data.map((measure) => measure.bytes_out));

  // Определяем количество точек (в данном случае, 11)
  const numPoints = 8;

  // Вычисляем шаг для шкалы, используя максимальные значения из обоих полей
  const packetsStep = Math.max(maxPacketsIn, maxPacketsOut) / (numPoints - 1);
  const bytesStep = Math.max(maxBytesIn, maxBytesOut) / (numPoints - 1);

  // Создаем шкалы
  for (let i = 0; i < numPoints; i++) {
    const packetsValue = i * packetsStep;
    const bytesValue = i * bytesStep;

    // Округляем до половины с учетом знака
    const roundedPackets = roundToHalf(packetsValue);
    const roundedBytes = roundToHalf(bytesValue);
    const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
    const sizes1 = ['', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    //@ts-ignore
    packetsScale.push(transformValues(packetsValue, 2,sizes));
    //@ts-ignore
    bytesScale.push(transformValues(roundedBytes, 2 ,sizes1));
  }

  return [packetsScale?.reverse(), bytesScale?.reverse()];
}


export function generateCPUScaleData(cpuData:any) {
  if (!Array.isArray(cpuData) || cpuData.length === 0) {
    console.error("Данные отсутствуют или представлены в неправильном формате.");
    return;
  }

  // Находим максимальное значение в массиве данных
  const maxValue = Math.max(...cpuData?.map((meas:any) => meas.value));
  const numberOfTicks = Math.min(8, maxValue / 10 + 1);
  // Генерируем Y-шкалу в процентах от 0% до максимального значения
  const yScale = [];
  for (let i = 0; i < numberOfTicks; i += 10) {
    yScale.push((maxValue / numberOfTicks) * i);
  }
  return yScale.reverse();
}

export function convertToPercentageCpu(dataArray:any) {
  if (dataArray.length === 0) {
    return [];
  }
  const dataLength = dataArray.length;
  
  if (dataLength === 0) {
    return [];
  }

  const max = Math.max(...dataArray?.map((meas:any) => meas.value));
  let maxScale;

  if (max < 150) {
    maxScale = 10;
  } else if (max < 300) {
    maxScale = 50;
  } else {
    maxScale = 100;
  }

  const scaledDataY = [];
  const stepY = Math.ceil(max / maxScale);
  
  for (let i = 0; i <= maxScale; i++) {
    const scaledValue = i * stepY;
    scaledDataY.push(scaledValue);
  }

  const maxData = Math.max(...scaledDataY);
  if (maxData === 0) {
    return dataArray.map(() => 0); // Все значения 0% если максимальное значение равно 0.
  }
  //@ts-ignore
  const step = 100 / (dataArray.length - 1);
  //@ts-ignore
  const scaledData = dataArray.sort((a, b) => (new Date(a.date)).getTime() - (new Date(b.date)).getTime()).map((metric:any, index:any) => {
    return [index * step, ((metric?.value / maxData) * 100)?.toFixed(1), metric.date, `${metric?.value.toFixed(2)}%`]
  });
  return scaledData;
}

// export function generateYScale(dataArray:any[]) {
//   const dataLength = dataArray.length;
  
//   if (dataLength === 0) {
//     return [];
//   }

//   const max = Math.max(...dataArray?.map((meas:any) => meas.value));
//   let maxScale;

//   if (max < 150) {
//     maxScale = 10;
//   } else if (max < 300) {
//     maxScale = 50;
//   } else {
//     maxScale = 100;
//   }

//   const scaledData = [];
//   const step = Math.ceil(max / maxScale);
  
//   for (let i = 0; i <= dataLength; i++) {
//     const scaledValue = i * step;
//     scaledData.push(`${scaledValue}%`);
//   }

//   if (scaledData.length > 9) {
//     const stepSize = Math.ceil(scaledData.length / 9);
//     const scaledDataLimited = scaledData.filter((_, index) => index % stepSize === 0);
//     return scaledDataLimited.reverse();
//   }
//   return scaledData.reverse();
// }
export function generateYScale(percentArray: any[]): any[] {
  // Сортируем массив процентов по возрастанию
  const sortedPercentArray = percentArray.sort((a, b) => a.value - b.value);

  // Находим максимальное значение
  const maxPercent = sortedPercentArray[sortedPercentArray.length - 1]?.value;

  // Если максимальное значение больше 50, округляем его до десятков
  const roundedMaxPercent = maxPercent > 50 ? Math.ceil(maxPercent / 10) * 10 : maxPercent;

  // Вычисляем шаг между процентами
  const step = Math.ceil(roundedMaxPercent / 7);

  // Создаем массив из 8 процентов
  const resultArray: any[] = [];
  for (let i = 0; i < 8; i++) {
    const value = i * step;
    // Если значение больше 50, округляем его до десятков
    const roundedValue = value > 50 ? Math.ceil(value / 10) * 10 : value;
    resultArray.push(`${roundedValue}%`);
  }

  return resultArray.reverse();
}




function transformValues(value:any, decimals:any, sizes:any) {
  const dm = decimals || 2;
  if (value <= 1) {
    return value.toFixed(dm) + ' ' + sizes[0];
  }
  const k = 1000;
  const i = Math.floor(Math.log(value) / Math.log(k));
  return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function createPercentageArray(length:any) {
  if (length < 1) {
    return [];
  }

  const step = 100 / (length - 1);
  const result = [];

  for (let i = 0; i < length; i++) {
    result.push(i * step);
  }

  return result;
}

export function createPercentageArrays(data: Measure[], fieldName: keyof Measure): [number[], number[], string, number][] {

  
  // const step = Math.max(1, Math.ceil(data.length / 8));
  const step = Math.floor(data.length / 20);
  const shortenedData = data.filter((_, index) => index % step === 0).slice(0, 8);

  const maxFieldValue = Math.max(...data.map((measure:any) => measure[fieldName]));
  
  const maxPacketsIn = Math.max(...data.map((measure) => measure.packets_in));
  const maxPacketsOut = Math.max(...data.map((measure) => measure.packets_out));
  const maxBytesIn = Math.max(...data.map((measure) => measure.bytes_in));
  const maxBytesOut = Math.max(...data.map((measure) => measure.bytes_out));


  const packetsStep = Math.max(maxPacketsIn, maxPacketsOut);
  const bytesStep = Math.max(maxBytesIn, maxBytesOut);

  const isBytes = fieldName === 'bytes_in' || fieldName === 'bytes_out'
  const resultArrays: any = data.map((measure:any, index:any) => {
    const percentValues = createPercentageArray(data?.length);
    const percentValue = measure[fieldName] === 0 ? 0 : (measure[fieldName] / (isBytes ? bytesStep : packetsStep)) * 100;

    return [percentValues[index], percentValue, measure.date, measure[fieldName].toFixed(2)];
  });
  return resultArrays;
}

// export function createPercentageArrays(data: Measure[], fieldName: keyof Measure): [number[], number[], string, number][] {
//   const maxResultLength = 20;
  
//   // Проверяем, нужно ли ограничивать количество элементов
//   const shouldLimit = data.length > 100;
//   const step = shouldLimit ? Math.max(1, Math.ceil(data.length / maxResultLength)) : 1;
//   const shortenedData = shouldLimit ? data.filter((_, index) => index % step === 0).slice(0, maxResultLength) : data;

//   const maxFieldValue = Math.max(...data.map((measure: any) => measure[fieldName]));

//   const maxPacketsIn = Math.max(...data.map((measure) => measure.packets_in));
//   const maxPacketsOut = Math.max(...data.map((measure) => measure.packets_out));
//   const maxBytesIn = Math.max(...data.map((measure) => measure.bytes_in));
//   const maxBytesOut = Math.max(...data.map((measure) => measure.bytes_out));

//   const packetsStep = Math.max(maxPacketsIn, maxPacketsOut);
//   const bytesStep = Math.max(maxBytesIn, maxBytesOut);

//   const isBytes = fieldName === 'bytes_in' || fieldName === 'bytes_out';
//   const resultArrays: any = shortenedData.map((measure: any, index: any) => {
//     const percentValues = createPercentageArray(shortenedData?.length);
//     const percentValue =
//       measure[fieldName] === 0 ? 0 : (measure[fieldName] / (isBytes ? bytesStep : packetsStep)) * 100;

//     return [percentValues[index], percentValue, measure.date, measure[fieldName].toFixed(2)];
//   });
//   console.log('resultArrays', resultArrays);
//   return resultArrays;
// }

