import React, { FC, useEffect, useState } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  HideContainer,
  InfoBlock,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { BodySmall, HeadLine3 } from "../../typography/styled";
import Counter from "../../counter";
import { useAuth } from "../../../context/user.context";
import Toggle from "../../toggle";
import { kubernetesService } from "../../../api";
import { useInstance } from "../../../context/instanceContext";
import { ErrorContainer, ErrorText } from "../../../pages/Instances/components/create-instance/configure/styles";
import ErrorIc from '../../../pages/Instances/components/create-instance/configure/ic-error.svg'
import TopProgress from "../../TopBarProgress";
import { useUi } from "../../../context/ui.context";
            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any, setErrors?:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
}  

const ScalePool:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    // loading
  } = props
  const {quotas} = useAuth()
  const {
    viewAlert
  } = useUi()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    count:instance?.countEdit,
    max_count:instance?.max_count || 1,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [autoscale, setAutoscale] = useState<boolean>(instance?.autoscale || false)
  const {flavors, getCreateOptions, pageLoading} = useInstance()
  const maxCountInstances = (quotas:any, value?:any) => {
    const flavorObj = flavors.find((flav:any) => flav.name === instance.flavor)
    if(!flavorObj){
      return quotas?.core?.instances?.limit - quotas?.core?.instances?.in_use
    }
    let maxCores = (quotas?.core?.cores?.limit - quotas?.core?.cores?.in_use) / flavorObj?.vcpus
    let maxRam = (quotas?.core?.ram?.limit - quotas?.core?.ram?.in_use) / flavorObj?.memory_mb
    let maxInstances = quotas?.core?.instances?.limit - quotas?.core?.instances?.in_use
    return Math.min(Math.floor(maxCores), Math.floor(maxRam), Math.floor(maxInstances)) + (autoscale ? (instance?.max_count || instance?.countEdit) : instance?.countEdit)
  }
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      count:+values.count,
      autoscale:autoscale,
      max_count: autoscale ? +values.max_count : null
    }
    kubernetesService.editNodePool(instance?.id, data)
    .then(() => {
      setLoadingComplete(true)
      setTimeout(() => {
        closeModalCancel()
        setLoading(false)
        setLoadingComplete(false)
      }, 1500)
    })
    .catch((err) => {
      console.log('err', err?.response?.data);
      setLoading(false)
      viewAlert({
        severity:'error',
        message: err.response.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    })

  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      max_count: yup.number().required(`${t('REQUIRED_FIELD')}`)
      .positive()
      .integer()
      .min(1, `${t("COUNT_MUST_BE_GREATER_OR_EQUAL")} 1`)
      .max(10, `${t("COUNT_MUST_BE_LESS_THAN_OR_EQUAL")} 10`),
      count: yup.number().required(`${t("REQUIRED_FIELD")}`)
      .min(1, `${t("MIN_COUNT_NUMBER")} 1`)
      // .max(maxCountInstances(quotas), `${t("MAX_COUNT_MACHINES")} ${maxCountInstances(quotas)}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  useEffect(() => {
    setInitialValues({
      count:instance?.countEdit,
      max_count:instance?.max_count || 1,
    })
    setAutoscale(instance?.autoscale || false)
  },[instance])
  const formikProps = {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange
  }
  useEffect(() => {
    getCreateOptions()
  },[])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("SCALE_NODE_POOL")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <InfoBlock>
          <img src={icons.IconInfo}/>
          <BodySmall>
            {t("CLUSTER_SCALE_INFO")}
          </BodySmall>
        </InfoBlock>
        <HideContainer active={!autoscale}>
          <Counter
            // customStyles={{marginBottom:"20px"}}
            // toolTip={true}
            // tooltipText={`${t("NUMBER_OF_WORKER_NODES")}`}
            title={t("NODE_COUNT")}
            value={values['count']}
            minCount={1}
            hideItems={`${t("ITEMS")}`}
            onChange={(v:any) => {
              handleChange('count')(v.toString())
            }}
            fieldName='count'
            {...formikProps}
          />
        </HideContainer>
        <Toggle
          title={t("AUTOSCALE")}
          value={autoscale}
          onChange={() => setAutoscale(!autoscale)}
        />
        <HideContainer active={autoscale}>
          <Counter
            // customStyles={{marginBottom:"20px"}}
            // toolTip={true}
            // tooltipText={`${t("NUMBER_OF_WORKER_NODES")}`}
            title={t("MIN_NODE_COUNT")}
            value={values['count']}
            minCount={1}
            hideItems={`${t("ITEMS")}`}
            onChange={(v:any) => {
              handleChange('count')(v.toString())
            }}
            fieldName='count'
            {...formikProps}
          />
          <Counter
            // customStyles={{marginBottom:"20px"}}
            // toolTip={true}
            // tooltipText={`${t("NUMBER_OF_WORKER_NODES")}`}
            title={t("MAX_NODE_COUNT")}
            value={values['max_count']}
            minCount={1}
            hideItems={`${t("ITEMS")}`}
            onChange={(v:any) => {
              handleChange('max_count')(v.toString())
            }}
            fieldName='max_count'
            {...formikProps}
          />
        </HideContainer>
        {maxCountInstances(quotas) < (autoscale ? values['max_count'] : values['count']) &&
          <ErrorContainer style={{margin:'0px'}} isError={true}>
            <img src={ErrorIc}/>
            <ErrorText>{t("ERROR_SCALE_POOL_CREATE_QUOTAS")}</ErrorText>
          </ErrorContainer>
        }
        <div style={{display:"flex", gap:'6px', marginTop:'0px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            disabled={maxCountInstances(quotas) < (autoscale ? values['max_count'] : values['count'])}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("CONFIRM")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default ScalePool