import styled from 'styled-components'

type ActiveProps = {
  active?:boolean,
  noMargin?:boolean
}
type ContainerProp = {
  customStyles?:any,
}

export const Container = styled.div<ContainerProp>`
  display: flex;
  ${({customStyles}) => customStyles}
`

export const StyledToggle = styled.div<ActiveProps>`
  box-sizing: border-box;
  padding: 2px;
  width: 50px;
  height: 24px;
  border-radius: 20px;
  margin-right: ${({noMargin}) => noMargin ? '0px' : '10px'};
  background-color: ${({theme, active}) => active ? '#4671F6' : theme.grey[300]};
  &:hover{
    cursor: pointer;
  }
`

export const DotPoint = styled.div<ActiveProps>`
  transition-duration: 500ms;
  transform:${({active}) => active ? 'translate(26px)' : ''};
  width: 20px;
  height: 20px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #FFFF;
  border-radius: 100%;
  animation: 0.5ms;
`
export const ToolTipImg = styled.img`
  margin-left:'6px';
  &:hover{
    cursor: pointer;
  }
`