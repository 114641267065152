import styled from 'styled-components'
import { BodyDefault } from '../typography/styled'

type ActiveProp = {
  disabled?:boolean,
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 6;
`

export const DropItem = styled.div<ActiveProp>`
  display: flex;
  padding: 8px 12px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 238px;
  opacity:${({disabled}) => disabled ? '0.5' : ''};
  align-items: center;
  &:hover{
    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
    background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[100]};
  }
  &:active{
    background-color: ${({theme}) => theme.isDark ? theme.palette[400] : theme.grey[200]};
    transition: 0.1s background-color ease-in-out;
  }
`
export const Divider = styled.div`
  border: ${({theme}) => `1px solid ${theme.palette[350]}`};
  width:237px;
  margin-top:4px;
  margin-bottom:4px;
`
export const DropTitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  margin-left:6px;
`