import React, { createContext, useState } from 'react';

export const RouterContext = createContext<RouterContextProps>({} as RouterContextProps);

interface RouterContextProps {
  routerOptions:any,
  setRouterOptions:(props:any) => void,
  interfaceOptions:any,
  setInterfaceOptions:(props:any) => void,
  routers:any[],
  setRouters:(routers:any[]) => void,
}

type ContextProps = {
  children?:any
}

const RouterContextProvider: React.FC<ContextProps> = (props) => {
  const [routerOptions, setRouterOptions] = useState<boolean>(false)
  const [interfaceOptions, setInterfaceOptions] = useState<boolean>(false)
  const [routers, setRouters] = useState<any[]>([])
  return (
    <RouterContext.Provider 
      value={{
        routerOptions,
        setRouterOptions,
        interfaceOptions,
        setInterfaceOptions,
        routers,
        setRouters,
      }}
      {...props}
    />
  );
};

const useRouterCustom = () => {
  const context = React.useContext(RouterContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context as RouterContextProps;
};

export { RouterContextProvider, useRouterCustom };