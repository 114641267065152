import styled from "styled-components";
import { BodyDefault } from "../../../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0px 30px 50px 30px;
  gap:20px;
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap:20px;
`

export const TextBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap:20px;
`

export const InfoText = styled(BodyDefault)`
  color:${({theme}) => theme.grey[700]};
`