import { FC } from "react";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barColors: {
    "0": "#4671F6",
    "1.0": "#4671F6"
  },
  shadowBlur: 0,
  barThickness:3,
});
type LoaderProp = {
  loading?:boolean
}
const TopProgress:FC<LoaderProp> = ({loading}) => {
  return <>{loading && <TopBarProgress />}</>;
};

export default TopProgress