import styled from 'styled-components'


export const Container = styled.div`
  &lds-spinner div:after{
    background:${({theme}) => theme.base.black}
  }
`

export const ToolTipView = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 10px;
  z-index: 13;
  position: absolute;
  /* height: 76px; */
  /* left:70px; */
  /* left:90px; */
  width:250px;
  left:25px;
  top: 4px;
  background: ${({ theme }) => theme.palette[10]};
  color:${({ theme }) => theme.palette[850]};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`
export const SpinnerLds = styled.div`
  color: official;
  display: inline-block;
  position: relative;
  top:-14px;
  transform: scale(0.25);
  &>div{
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  &>div::after{
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 4px;
    height: 18px;
    border-radius: 20%;
    background: ${({theme}) => theme.base.black};
  }
  &:hover{
    cursor:pointer;
  }
  &>div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  &>div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  &>div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  &>div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  &>div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  &>div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  &>div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  &>div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  &>div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  &>div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  &>div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  &>div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

`


