import React, { FC, useEffect, useState } from "react";
import {
  Container,
  Headers,
  HeadersWrapper,
  HeadersColumnWrapper,
  Divider,
} from './styles'
import { Button, Input, Select, RevealButton, Typography, CheckBox } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from 'use-query-params';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useBalancer } from "../../../../context/balancerContext";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
const PoolRemembers:FC<any> = ({internetAcc}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [admin, setAdmin] = useState<boolean>(true)
  const {
    setListenerDetails,
    listenersProtocols
  } = useBalancer()

  const {
    setDataToLs,
    getDataToLs
  } = useLocalStorage()
  const [initialValues, setInitialValues] = useState<any>({})
  const onSubmit = (values:any) => {
    const data = {
      ...values,
      admin_state_up:admin,
    }
    setDataToLs({key:'balancer.listener_details', data})
    setListenerDetails(data)
    setStep('pool-details')
  }
  useEffect(() => {
    setInitialValues({
      // admin_state_up:getDataToLs({key:'balancer.listener_details'})?.admin_state_up||true,
      connection_limit:getDataToLs({key:'balancer.listener_details'})?.connection_limit||-1,
      description:getDataToLs({key:'balancer.listener_details'})?.description||"",
      name:getDataToLs({key:'balancer.listener_details'})?.name||"",
      protocol:getDataToLs({key:'balancer.listener_details'})?.protocol||"",
      protocol_port:getDataToLs({key:'balancer.listener_details'})?.protocol_port||"",
      timeout_client_data:getDataToLs({key:'balancer.listener_details'})?.timeout_client_data||50000,
      timeout_member_connect:getDataToLs({key:'balancer.listener_details'})?.timeout_member_connect||5000,
      timeout_member_data:getDataToLs({key:'balancer.listener_details'})?.timeout_member_data||50000,
      timeout_tcp_inspect:getDataToLs({key:'balancer.listener_details'})?.timeout_tcp_inspect||'0',
      x_forwarded_for:getDataToLs({key:'balancer.listener_details'})?.x_forwarded_for||false,
      x_forwarded_port:getDataToLs({key:'balancer.listener_details'})?.x_forwarded_port||false,
      x_forwarded_proto:getDataToLs({key:'balancer.listener_details'})?.x_forwarded_proto||false,
      x_ssl_client_cn:getDataToLs({key:'balancer.listener_details'})?.x_ssl_client_cn||false,
      x_ssl_client_dn:getDataToLs({key:'balancer.listener_details'})?.x_ssl_client_dn||false,
      x_ssl_client_has_cert:getDataToLs({key:'balancer.listener_details'})?.x_ssl_client_has_cert||false,
      x_ssl_client_not_after:getDataToLs({key:'balancer.listener_details'})?.x_ssl_client_not_after||false,
      x_ssl_client_not_before:getDataToLs({key:'balancer.listener_details'})?.x_ssl_client_not_before||false,
      x_ssl_client_sha1:getDataToLs({key:'balancer.listener_details'})?.x_ssl_client_sha1||false,
      x_ssl_client_verify:getDataToLs({key:'balancer.listener_details'})?.x_ssl_client_verify||false,
      x_ssl_issuer:getDataToLs({key:'balancer.listener_details'})?.x_ssl_issuer||false,
    })
  },[])
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
      protocol_port: yup.number().min(1, `${t("MIN_COUNT_NUMBER")} 1`).required(`${t('REQUIRED_FIELD')}`),
      protocol: yup.string().required(`${t('REQUIRED_FIELD')}`),
      timeout_client_data: yup.number(),
      timeout_tcp_inspect: yup.number(),
      timeout_member_connect: yup.number(),
      timeout_member_data: yup.number(),
      connection_limit: yup.number(),
      // x_forwarded_for: yup.boolean(),
      // x_forwarded_port: yup.boolean(),
      // x_forwarded_proto: yup.boolean(),
      // x_ssl_client_cn: yup.boolean(),
      // x_ssl_client_dn: yup.boolean(),
      // x_ssl_client_has_cert: yup.boolean(),
      // x_ssl_client_not_after: yup.boolean(),
      // x_ssl_client_not_before: yup.boolean(),
      // x_ssl_client_sha1: yup.boolean(),
      // x_ssl_client_verify: yup.boolean(),
      // x_ssl_issuer: yup.boolean(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  useEffect(() => {
    if (values['protocol'] === 'HTTP') {
      handleChange('protocol_port')('80');
    }
    if (values['protocol'] === 'HTTPS') {
      handleChange('protocol_port')('443');
    }
  },[values['protocol']])
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const handleChangeCheckbox = (fieldName:string) => {
    handleChange(fieldName)(values[fieldName] === 'true' ? 'false' : 'true')
  }
  console.log('values', values);
  return (
    <Container>
      <Input
        fieldName='name'
        title={t("NAME")}
        placeholder=''
        customStyles={{width:'360px'}}
        {...formikProps}
      />
      <Input
        isTextArea
        fieldName='description'
        title={t("DESCRIPTION")}
        placeholder=''
        customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
        {...formikProps}
      />
      <div style={{display:'flex'}}>
        <Select
          required
          toolTip={false}
          data={listenersProtocols}
          fieldName='protocol'
          selectedValue={values['protocol']}
          onChange={(e) => handleChange('protocol')(e)}
          title={t("PROTOCOL")}
          customStyles={{width:'360px', marginRight:'20px'}}
          {...formikProps}
        />
        <Input
          required
          fieldName='protocol_port'
          title={t("PORT")}
          placeholder=''
          customStyles={{width:'170px'}}
          {...formikProps}
        />
      </div>
      <RevealButton
        title={t("ADDITIONAL_SETTINGS")}
      >
        <div style={{display:'flex', marginBottom:"20px", gap:'20px'}}>
          <Input
            fieldName='timeout_client_data'
            title={t("CLIENT_DATA_TIMEOUT")}
            type={'number'}
            placeholder=''
            customStyles={{width:'245px'}}
            {...formikProps}
          />
          <Input
            fieldName='timeout_tcp_inspect'
            title={t("TCP_INSPECT_TIMEOUT")}
            type={'number'}
            placeholder=''
            customStyles={{width:'245px'}}
            {...formikProps}
          />
          <Input
            fieldName='timeout_member_connect'
            title={t("MEMBER_CONNECT_TIMEOUT")}
            type={'number'}
            placeholder=''
            customStyles={{width:'245px'}}
            {...formikProps}
          />
        </div>
        <div style={{display:'flex', marginBottom:"20px", gap:'20px'}}>
          <Input
            fieldName='timeout_member_data'
            title={t("MEMBER_DATA_TIMEOUT")}
            type={'number'}
            placeholder=''
            customStyles={{width:'245px'}}
            {...formikProps}
          />
          <Input
            fieldName='connection_limit'
            title={t("CONNECTION_LIMIT")}
            type={'number'}
            placeholder=''
            customStyles={{width:'245px'}}
            {...formikProps}
          />
        </div>
        {values['protocol'] === 'HTTP' || values['protocol'] === 'TERMINATED_HTTPS' ?
          <Headers>
            <Typography variant="h3">
              {t("INSERT_HEADERS")}
            </Typography>
            <HeadersWrapper>
              <HeadersColumnWrapper>
                <CheckBox
                  title={'X-Forwarded-For'}
                  inYup
                  onChange={() => handleChangeCheckbox('x_forwarded_for')}
                  value={values['x_forwarded_for']}
                />
                <CheckBox
                  title={'X-Forwarded-Proto'}
                  inYup
                  onChange={() => handleChangeCheckbox('x_forwarded_proto')}
                  value={values['x_forwarded_proto']}
                />
              </HeadersColumnWrapper>
              <HeadersColumnWrapper>
                <CheckBox
                  title={'X-Forwarded-Port'}
                  inYup
                  onChange={() => handleChangeCheckbox('x_forwarded_port')}
                  value={values['x_forwarded_port']}
                />
              </HeadersColumnWrapper>
            </HeadersWrapper>
            {values['protocol'] === 'TERMINATED_HTTPS' && 
              <>
                <Divider/>
                <HeadersWrapper>
                  <HeadersColumnWrapper>
                    <CheckBox
                      title={'X-SSL-Client-Verify'}
                      inYup
                      onChange={() => handleChangeCheckbox('x_ssl_client_verify')}
                      value={values['x_ssl_client_verify']}
                    />
                    <CheckBox
                      title={'X-SSL-Client-DN'}
                      inYup
                      onChange={() => handleChangeCheckbox('x_ssl_client_dn')}
                      value={values['x_ssl_client_dn']}
                    />
                    <CheckBox
                      title={'X-SSL-Issuer'}
                      inYup
                      onChange={() => handleChangeCheckbox('x_ssl_issuer')}
                      value={values['x_ssl_issuer']}
                    />
                    <CheckBox
                      title={'X-SSL-Client-Not-Before'}
                      inYup
                      onChange={() => handleChangeCheckbox('x_ssl_client_not_before')}
                      value={values['x_ssl_client_not_before']}
                    />
                  </HeadersColumnWrapper>
                  <HeadersColumnWrapper>
                    <CheckBox
                      title={'X-SSL-Client-Has-Cert'}
                      inYup
                      onChange={() => handleChangeCheckbox('x_ssl_client_has_cert')}
                      value={values['x_ssl_client_has_cert']}
                    />
                    <CheckBox
                      title={'X-SSL-Client-CN'}
                      inYup
                      onChange={() => handleChangeCheckbox('x_ssl_client_cn')}
                      value={values['x_ssl_client_cn']}
                    />
                    <CheckBox
                      title={'X-SSL-Client-SHA1'}
                      inYup
                      onChange={() => handleChangeCheckbox('x_ssl_client_sha1')}
                      value={values['x_ssl_client_sha1']}
                    />
                    <CheckBox
                      title={'X-SSL-Client-Not-After'}
                      inYup
                      onChange={() => handleChangeCheckbox('x_ssl_client_not_after')}
                      value={values['x_ssl_client_not_after']}
                    />
                  </HeadersColumnWrapper>
                </HeadersWrapper>
              </>
            }
          </Headers>
        :null}
      </RevealButton>
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep('balancer-details')}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default PoolRemembers