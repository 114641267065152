import { BodyDefault, HeadLine1, BodyMedium } from "../../../../components/typography/styled";
import Skeleton from 'react-loading-skeleton'
import styled, { useTheme } from "styled-components";

type StatusProp = {
  status?:string
}
type StatusBalance = {
  isRed?:boolean,
}
export const statusColor = (status?: string) => {
  switch (status) {
    case "unpaid":
      return "#E85048";
    case "suspended":
      return "#E85048";
    case "paid":
      return "#229B61";
    case "active":
      return "#229B61";
    default:
      return "";
  }
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  gap:10px;
`

export const TopBalanceWrapper = styled.div`
  display:flex;
  gap:10px;
`

export const SubTitleText = styled(BodyDefault)`
  color:${({theme}) => theme.grey[700]};
`

export const InfoWrapper = styled.div`
  display:flex;
  flex-direction:column;
  gap:20px;
  align-items:flex-start;
`

export const ButtonWrapper = styled.div`
  display:flex;
  gap:10px;
`

export const PriceTitle = styled(HeadLine1)<StatusBalance>`
  color:${({isRed, theme}) => isRed ? theme.errors[600] : ''};
`

export const StatusText = styled(BodyMedium)<StatusProp>`
  color: ${({status}) => statusColor(status)};
  text-transform:capitalize;
`

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  /* margin-top: 10px; */
  gap:10px;
`

export const RemainingContainer = styled.div`
  padding: 3px 4px;
  margin-left:10px;
  box-sizing:border-box;
  display: flex;
  flex-direction: row;
  gap:6px;
  align-items:center;
  background: ${({theme}) => theme.palette[200]};
  border-radius: 4px;
`