import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,
} from './styles'
import { Button, ModalVariables, Table } from '../../../../../components'
import { icons } from '../../../../../icons'
import { randomNameGenerator } from '../../../../../utils/create-random-name'
import { networkService } from '../../../../../api'
import { useParams } from 'react-router-dom'
import { useUi } from '../../../../../context/ui.context'
import { compareArrays } from '../../../../../utils/findIntersection'
import { BodyDefault } from '../../../../../components/typography/styled'

type SubnetProps = {
  subnets:any[],
  external:boolean,
  updateFunc:() => void,
  pageLoading:boolean
  setPageLoading:(set:boolean) => void,
  editedSubnet:any,
  setEditedSubnet:any,
  viewModal:any,
  setViewModal:any,
  activeAnimation:any,
  setActiveAnimation:any,
  modalType:any,
  setModalType:any,
  openModal:any,
}
const Subnet:FC<SubnetProps> = (props) => {
  const {
    subnets,
    external,
    updateFunc,
    pageLoading,
    setPageLoading,
    editedSubnet,
    setEditedSubnet,
    viewModal,
    setViewModal,
    activeAnimation,
    setActiveAnimation,
    modalType,
    setModalType,
    openModal,
  } = props
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(subnets, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [deletedSub, setDeletedSub] = useState<any>()
  const {id} = useParams()

  
  const _deleteSubnets = async () => {
    setLoading(true)
    if(allChecked) {
      let promises:any[] = []
      await subnets.map(async(item:any) => {
        let promise = networkService.deleteSubnet(item.id)
        promises.push(promise)
      })
      await Promise.all(promises)
      .then((res) => {
        updateFunc()
        setAllChecked(false)
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
        updateFunc()
      })
      .finally(() => {
        setPageLoading(false)
        setLoading(false)
        
      })
    } else if (checked?.length > 0){
      let promises:any[] = []
      await checked.map(async(item) => {
        let promise = networkService.deleteSubnet(item.id)
        promises.push(promise)
      })
      await Promise.all(promises)
      .then((res) => {
        updateFunc()
        setChecked([])
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
        updateFunc()
      })
      .finally(() => {
        setPageLoading(false)
        setLoading(false)
      })
    } else if (deletedSub) {
      networkService.deleteSubnet(deletedSub.id)
      .then((res) => {
        updateFunc()
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
        updateFunc()
      })
      .finally(() => {
        setPageLoading(false)
        setLoading(false)
      })
    }
  }

  const closeModal = (props:any) => {
    if(modalType==='delete'){
      _deleteSubnets()
    } else {
      setLoading(true)
      if(editedSubnet?.id){
        networkService.editSubnet(props.id, {
          ...props
        })
        .then((res) => {
          updateFunc()
          setLoading(false)
          setViewModal(false)
          setActiveAnimation(false)
          // setEditedSubnet(null)
        })
        .catch((e) => {
          if(e?.response?.data?.detail){
            viewAlert({
              severity:'error',
              message:e?.response?.data?.detail
            })
          }
          setLoading(false)
        })
    
      } else {
        networkService.createSubnet({
          ...props,
          network_id:id,
        })
        .then((res) => {
          updateFunc()
          setLoading(false)
          setViewModal(false)
          setActiveAnimation(false)
        })
        .catch((e) => {
          if(e?.response?.data?.detail){
            viewAlert({
              severity:'error',
              message:e?.response?.data?.detail
            })
          }
          setLoading(false)
        })
      }
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (id:any) => {
        setEditedSubnet(id)
        openModal('create-subnet')
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(id:any) => {
        openModal('delete')
        setAlertTitle(t("SUBNET"))
        setAlertSubTitle([id])
        setDeletedSub(id)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("NETWORK_ADDRESS"),
      key:'cidr'
    },
    {
      title:t("IP_VERSION"),
      key:'ip_version'
    },
    {
      title:t("GATEWAY_IP"),
      key:'gateway_ip'
    },
    {
      title:'',
      key:'props'
    },
  ]
  return(
    <Container>
      <TopInfoWrapper>
        {external ? null :
          <div style={{display:'flex'}}>
            <Button
              variant="primary"
              size="display"
              onClick={() => {
                setEditedSubnet({
                  name:randomNameGenerator({name:'subnet', findArr:[]}),
                  cidr:`10.0.${subnets.length + 1}.0/24`,
                  ip_version:'4',
                  setGatewayIp: "default",
                  enable_dhcp:true,
                  dns_nameservers:[],
                  host_routes:[],
                  // network_id:res.data.id,
                  _id:randomNameGenerator({name:'', findArr:[]}),
                  network_mask:0,
                  network_address_source:'manually',
                  allocation_pools:[],
            
                  //data we don't have fields
                  ipv6_ra_mode:null,
                  ipv6_address_mode:null,
                  gateway_ip:'',
              })
                openModal('create-subnet')}}
              title={t("CREATE_SUBNET")}
              customStyles={{marginBottom:'20px'}}
            />
            <Button
              onClick={() => {
                setAlertTitle(t("SUBNETS"))
                setAlertSubTitle(allChecked ? subnets : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 6px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        {/* <Button
          variant='stroke'
          size='display'
          onClick={() => {}}
          title={t("FILTER")}
          icon={icons.Filter}
        /> */}
      </TopInfoWrapper>
      {subnets.length > 0 ?
        <Table
          columns={Columns}
          rows={subnets}
          settings={items}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          checked={checked}
          setChecked={editChecked}
          hideOther
        />
        :
        <BodyDefault>
          {t("NO_SUBNETS")}
        </BodyDefault>
      }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        dataToEdit={editedSubnet}
        loading={loading}
      />
    </Container>
  )
}
export default Subnet

