import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, InfoBlock, ModalVariables, Tabs, TopProgress } from "../../../../components";
import DropOptions from "../../../../components/header/components/drop-options";
import { PropsContainer } from "../../../../components/table/styles";
import { icons } from "../../../../icons";
import {
  ContainerGlobal,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  InstanceHeadInfo,
  TopInfoWrapper,
  Container
} from './styles'
import { loadBalancerService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { handleStatusName } from "../../../../utils/handle-status-name";
import { GreenDot, Greentext, StateWrapper } from "../../../Instances/styles";
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";


const RulePolicyPage:FC = () => {
  const navigate = useNavigate()
  const {poolId, policyId, lbId, ruleId} = useParams()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const [rule, setRule] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)

  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [modalType, setModalType] = useState<any>('')
  const {viewAlert} = useUi()

  const updateRule = () => {
    // setPageLoading(true)
    if(policyId && ruleId){
      loadBalancerService.getPolicyRuleById(policyId, ruleId)
      .then((res) => {
        setRule(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
        // navigate('/not-found')
      })
    }
  }
  useEffect(() => {
    updateRule()
    const interval = setInterval(() => {
      updateRule()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])

  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(!poolId) return;
    if(modalType === 'delete'){
      setLoading(true);
      loadBalancerService.deleteHealthMonitor(poolId, rule.id)
      .then((res) => {
        setViewModal(false)
        setActiveAnimation(false)
        navigate(`/load-balancer-pool/${lbId}/${poolId}/health-monitors`)
        viewAlert({
          severity:'info',
          message:`${t("RULE_DELETED_SUCCESS")}`
        })
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }

  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: () => navigate(`edit`),
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        setAlertTitle(t("RULE"))
        openModal('delete')
        setAlertSubTitle([rule])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <ContainerGlobal>
      <BackArrow onClick={() => navigate(`/load-balancer-pool/${lbId}/${poolId}/health-monitors`)} title={t("GO_BACK")}/>
      <HeadWrapper>
        <InstanceName>
          {rule?.name || rule.id} 
        </InstanceName>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              active={options}
              title={`${t("MENU")}`}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <InstanceHeadInfo>
        <StateWrapper>
        <GreenDot active={rule.operating_status}/>
          <Greentext active={rule.operating_status}>
          {handleStatusName(rule.operating_status, t)}
          </Greentext>
        </StateWrapper>
      </InstanceHeadInfo>
      <MonitorInfo rule={rule} />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={instance}
        instanceId={rule.id}
        // instance={instance}
        loading={loading}
      />
    </ContainerGlobal>
  )
}

export default RulePolicyPage



const MonitorInfo:FC<any> = ({rule}) => {
  const [t] = useTranslation()

  const ruleInfo = [
    {
      title:t("ID"),
      id:'1',
      value:rule?.id || '-',
      copy:true,
    },
    {
      title:t("TYPE"),
      id:'2',
      value:rule?.type || 'n/a'
    },
    {
      title:t("OPERATING_STATUS"),
      id:'3',
      value: (
      <StateWrapper>
        <GreenDot active={rule.operating_status}/>
        <Greentext active={rule.operating_status}>
          {handleStatusName(rule.operating_status, t)}
        </Greentext>
      </StateWrapper>) 
    },
    {
      title:t("PROVISIONING_STATUS"),
      id:'4',
      value: (
      <StateWrapper>
        <GreenDot active={rule.provisioning_status}/>
        <Greentext active={rule.provisioning_status}>
          {handleStatusName(rule.provisioning_status, t)}
        </Greentext>
      </StateWrapper>)
    },
    {
      title:t("ADMIN_STATE"),
      id:'14',
      value:rule?.admin_state_up ? 'Yes' : 'No'
    },
    {
      title:t("CREATED_AT"),
      id:'6',
      value: timeConverterMMDDYYHHMM(rule?.created_at)
    },
    {
      title:t("UPDATED_AT"),
      id:'7',
      value:timeConverterMMDDYYHHMM(rule?.updated_at)
    },
    {
      title:t("INVERT"),
      value:rule?.invert ? 'Yes' : 'No',
      id:'8',
    },
    {
      title:t("COMPARE_TYPE"),
      value:rule?.compare_type|| '-',
      id:'7',
    },
    {
      title:t("KEY"),
      id:'11',
      value:rule?.key || '-',
    },
    {
      title:t("VALUE"),
      id:'16',
      value:rule?.value || '-',
    },
  ]
  return (
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={ruleInfo}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1'}}
        />
      </TopInfoWrapper>
    </Container>
  )
}