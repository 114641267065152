import React, { FC, useEffect, useState } from "react";
import {
  Container,
  DarkForm
} from './styles'
import { Button, Input, Select, RevealButton, Table } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from 'use-query-params';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { InfoContainer, InfoTitle } from "../../../../components/info-block/styles";
import { icons } from "../../../../icons";
import { instanceService } from "../../../../api";
import { useBalancer } from "../../../../context/balancerContext";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { Prop, PropContainer } from "../../../Instances/styles";



const ListenerDetails:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {setDataToLs, getDataToLs} = useLocalStorage()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [admin, setAdmin] = useState<boolean>(false)
  const [initialValues, setInitialValues ] = useState<any>({
    remembers:getDataToLs({key:'balancer.pool_remembers'})||[],
  })
  const [poolRemembers, setPoolRemembers] = useState<any[]>(getDataToLs({key:'balancer.pool_remembers'})||[])
  
  const {
    subnets,
    flavors,
    zones,
    setPoolRemembersData,
    instances,
    setInstances
  } = useBalancer()
 
  const onSubmit = (values:any) => {
    setDataToLs({key:'balancer.pool_remembers', data:poolRemembers})
    setPoolRemembersData(poolRemembers)
    setStep('monitor-details')
  }
  
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      remembers: yup.array().of(
        yup.object().shape({
          address: yup.string().required(`${t('REQUIRED_FIELD')}`),
          protocol_port: yup.number().required(`${t('REQUIRED_FIELD')}`),
          subnet_id: yup.string().required(`${t('REQUIRED_FIELD')}`),
          weight: yup.string().required(`${t('REQUIRED_FIELD')}`),
        })
      ),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  
  const columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("IP_ADDRESS"),
      key:'access_ip'
    },
    {
      title:'',
      key:'props',
    },
  ]
  const handleInputChange = (index:any, field:any, value:any) => {
    const updatedData = [...poolRemembers];
    updatedData[index][field] = value;
    setPoolRemembers(updatedData);
    setFieldValue('remembers', updatedData);
  };
  const addNewItem = (instance?:any) => {
    setPoolRemembers((prev:any) => {
      setFieldValue('remembers', [...prev, 
        {
          address:instance?.access_ip || "",
          protocol_port: '',
          subnet_id: instance?.access_subnet_id || "",
          weight: '1',
        }
      ]);
      return [...prev, 
        {
          address:instance?.access_ip || "",
          protocol_port: '',
          subnet_id: instance?.access_subnet_id || "",
          weight: '1',
        }
      ]
    })
    
  };
  const getInstances = () => {
    instanceService.getInstanceList({
      page:1,
      pageSize:20
    })
    .then((res) => {
      setInstances(res.data.objects.map((instance:any) => {
        return {
          ...instance,
          navigate:`/instances/${instance.id}`,
          name:(
            <div style={{display:'flex', flexDirection:'column'}}>
              <div>
                {instance?.name}
              </div>
              <div style={{
                display:'flex',
                flexWrap:"wrap",
                alignItems:'center',
                gap:'4px',
                }}>
                  {instance?.tags?.map((tag:any) => (
                    <PropContainer key={tag?.id}>
                      <Prop>
                        {tag?.name}
                      </Prop>
                    </PropContainer>
                  ))}
                </div>
              </div>
            ),
          props:(
            <Button
              variant="primary"
              size="small"
              customStyles={{padding:"10px", height:'25px'}}
              onClick={() => addNewItem(instance)}
              title={t("ADD")}
            />
          )
        }
      }))
    })
  }
  useEffect(() => {
    getInstances()
  },[])
  const removeItem = (index:any) => {
    const updatedData = [...poolRemembers];
    updatedData.splice(index, 1);
    setPoolRemembers(updatedData);
    setFieldValue('remembers', updatedData);
  };
  return (
    <Container>
      <InfoContainer customStyles={{width:'100%'}}>
        <InfoTitle>
          {t("ALLOCATED_MEMBERS")}
        </InfoTitle>
        <Button
          variant="stroke"
          size="display"
          icon={icons.PlusDefault}
          customStyles={{marginTop:"10px"}}
          //@ts-ignore
          onClick={addNewItem}
          title={t("ADD_EXTERNAL_MEMBER")}
        />
        {poolRemembers?.map((item:any, index:any) => (
          <DarkForm>
            <Input
              fieldName={`remembers[${index}].address`}
              required
              toolTip={false}
              title={t("IP_ADDRESS")}
              placeholder=''
              value={item.address}
              customStyles={{width:'241px', flex:'auto 1'}}
              {...formikProps}
              handleChange={(e:any) => handleInputChange(index, 'address', e.target.value)}
            />
            <Select
              toolTip={false}
              required
              data={subnets}
              selectedValue={item.subnet_id}
              // onChange={() => {}}
              title={t("SUBNET")}
              customStyles={{width:'241px', flex:'auto 1'}}
              fieldName={`remembers[${index}].subnet_id`}
              onChange={(e:any) => handleInputChange(index, 'subnet_id', e)}

            />
            <Input
              required
              fieldName={`remembers[${index}].protocol_port`}
              toolTip={false}
              title={t("PORT")}
              placeholder=''
              customStyles={{width:'150px', flex:'auto 1'}}
              {...formikProps}
              arrayFields={true}
              arrayName={'remembers'}
              value={item.protocol_port}
              arrayIndex={index}
              arrayFieldName={'protocol_port'}
              handleChange={(e:any) => handleInputChange(index, 'protocol_port', e.target.value)}
            />
            <Input
              fieldName={`remembers[${index}].weight`}
              required
              toolTip={false}
              title={t("WEIGHT")}
              placeholder=''
              value={item.weight}
              customStyles={{width:'150px', flex:'auto 1'}}
              {...formikProps}
              handleChange={(e:any) => handleInputChange(index, 'weight', e.target.value)}
            />
            <Button
              onClick={() => removeItem(index)}
              variant='icon'
              size='display'
              // disabled
              customStyles={{marginTop:'24px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </DarkForm>
        ))}
      </InfoContainer>
      <div style={{width:'100%'}}>
        <RevealButton
          customStyles={{width:'215px'}}
          title={t("ADD_EXISTING_INSTANCES")}
        >
          <Table
            columns={columns}
            rows={instances}
            hideOther
            hideCheckbox
            
          />
        </RevealButton>
      </div>      
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep('pool-details')}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default ListenerDetails
