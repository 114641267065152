import React, { FC, useEffect, useState } from "react";
import {
  Container,
  HideContainer
} from './styles'
import { Button, Input, MultiInput, Select, Toggle } from "../../../../components";
import { useKubernetes } from "../../../../context/kubernetesContext";
import { useBalancer } from "../../../../context/balancerContext";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { StringParam, useQueryParam } from "use-query-params";
import { icons } from "../../../../icons";
import { WarningBlock, WarningText } from "../../../Instances/components/create-instance/configure/styles";


const NetworkingPage:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {
    setNetworkingData,
    networks
  } = useKubernetes()
  const {setDataToLs, getDataToLs} = useLocalStorage()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [admin, setAdmin] = useState<boolean>(true)
  const [restriction, setRestriction] = useState<boolean>(getDataToLs({key:'cluster.networking'})?.restriction || false)
  const [initialValues, setInitialValues ] = useState<any>({
    network:getDataToLs({key:'cluster.networking'})?.network || networks?.[0]?.value || '',
    api_range:getDataToLs({key:'cluster.networking'})?.api_range ||''
  })
  const [apiRanges, setApiRanges] = useState<string[]>(getDataToLs({key:'cluster.networking'})?.api_ranges || [])

  const onSubmit = (values:any) => {
    if(apiRanges.length === 0 && restriction) {
      //@ts-ignore
      setErrors({api_range:t("NOT_EMPTY_IP_LIST")})
      return
    }
    setStep('configure-worker')
  }
  
  const {
    subnets,
    flavors,
    zones,
    setLoadDetails
  } = useBalancer()

  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setErrors,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      // name: yup.string().matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      network: yup.string().required(`${t('REQUIRED_FIELD')}`),
      api_range: yup.string()
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const changeTags = (tag:string) => {
    const founded = apiRanges.find((item:any) => item === tag)
    if(founded || tag === '' || tag === undefined) {
      setApiRanges((prev:any) => {
        return prev.filter((item:any) => item !== tag)
      })
    } else {
      setApiRanges((prev) => [...prev, tag])
      handleChange('api_range')('')
    }
  }

  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  useEffect(() => {
    setDataToLs({key:'cluster.networking', data:{...values, restriction:restriction, api_ranges:apiRanges}})
    setNetworkingData({...values, restriction:restriction, api_ranges:apiRanges})
  },[values, restriction, apiRanges])
  return (
    <Container>
      <Select
        required
        toolTip={true}
        tooltipText={`${t("CONTAINER_NETWORK_INTERFACE_DESCRIPTION")}`}
        data={networks}
        disabled={networks?.length <= 0}
        selectedValue={values['network']}
        onChange={(e) => handleChange('network')(e)}
        placeholder={networks?.length <= 0 ? t("NO_DATA") : t("CHOOSE")}
        title={t("CONTAINER_NETWORK_INTERFACE")}
        customStyles={{width:'360px'}}
        fieldName='network'
        {...formikProps}
      />
      <Toggle
        title={t("RESTRICTION_API")}
        toolTip={true}
        tooltipText={`${t("RESTRICTION_API_DESCRIPTION")}`}
        value={restriction}
        onChange={() => setRestriction(!restriction)}
      />
      <HideContainer active={restriction}>
        {/* <Input
          // required
          isTextArea
          fieldName='api_ranges'
          // toolTip={true}
          title={t("RESTRICTION_API_IP_RANGES")}
          customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
          // readOnly
          placeholder=''
          {...formikProps}
        /> */}
        {/* <Input
          toolTip={true}
          toolTipText=""
          customStyles={{paddingLeft:'35px'}}
          placeholder={t("ADD_A_TAG")}
          fieldName='tag'
          tags={apiRanges}
          icon={icons.IconTag}
          title={t("TAGS")}
          changeTags={changeTags}
          {...formikProps}
        /> */}
      <div style={{display:'flex', flexDirection:"column", gap:"20px", width:'360px'}}>
        {/* <WarningBlock>
          <img src={icons.Warning}/>
          <WarningText>
            {t("INFO_CREATE_IPS")}
          </WarningText>
        </WarningBlock> */}
        <MultiInput
          toolTip={true}
          isRestrictionIp
          toolTipText={`${t("INFO_CREATE_IPS")}`}
          customStyles={{paddingLeft:'35px'}}
          placeholder={t("ADD_IP")}
          fieldName='api_range'
          tags={apiRanges}
          icon={icons.IconTag}
          title={t("RESTRICTION_API_IP_RANGES")}
          changeTags={changeTags}
          {...formikProps}
        />
      </div>
      </HideContainer>
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep('configure-master')}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default NetworkingPage