import React, {FC, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Label, ModalVariables, Table, TopProgress } from '../../../../components'
import { BodyDefault } from '../../../../components/typography/styled'
import { useUi } from '../../../../context/ui.context'
import { icons } from '../../../../icons'
import {
  Container,
  CopyIcon,
  TokenBody
} from './styles'
import { ClipBoardWrapper } from '../../../../components'
import {apiAccessService, authService} from '../../../../api'
import { timeConverterMMDDYYHHMM } from '../../../../utils/dates'
import rootConfig from '../../../../config'

const ApiAccessPage:FC<any> = () => {
  const [t] = useTranslation()
  const [tokens, setTokens] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [selectedToken, setSelectedToken] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const {viewAlert} = useUi()
  const getTokens = () => {
    apiAccessService.getTokens()
    .then((res) => {
      setTokens(res.data.objects.map((token:any) => {
        return {
          ...token,
          name: token.name,
          tokenClear:token?.token,
          created_at:timeConverterMMDDYYHHMM(token.created_at),
          token: token?.token ? (
            <ClipBoardWrapper toCopy={token?.token}>
              <div style={{display:'flex', gap:'6px', alignItems:'center', width:'20vw'}}>
                <TokenBody>
                  {token?.token}
                </TokenBody>
                <CopyIcon style={{ marginLeft: '6px', cursor:'pointer' }} src={icons.Copy} />
              </div>
            </ClipBoardWrapper>
          ) :
          (<Label 
            title={'-'}
            tooltipText={t("OPENSTACK_TOKEN_INFO")}
          />),
          idView: (
            <ClipBoardWrapper toCopy={token?.id}>
              <div style={{display:'flex', gap:'6px', alignItems:'center', width:'20vw'}}>
                <TokenBody>
                  {token?.id}
                </TokenBody>
                <CopyIcon style={{ marginLeft: '6px', cursor:'pointer' }} src={icons.Copy} />
              </div>
            </ClipBoardWrapper>
          ),
          dropOptions:token.type === 'OpenStack' ?
          [
            {
              title:t("DOWNLOAD_YAML"),
              id:'4',
              onClick:async (token:any) => {
                const res = await authService.generateOtpToken()
                const url = `${rootConfig.apiUrl}api/tokens/${token.id}/download_yaml_file/?fleio-token=${res.data.otp_token}`
                window.location.href = url
              },
              icon:icons.Download,
              divider:false
            },
            {
              title:t("DOWNLOAD_OPENRC"),
              id:'3',
              onClick:async (token:any) => {
                const res = await authService.generateOtpToken()
                const url = `${rootConfig.apiUrl}api/tokens/${token.id}/download_openrc_file/?fleio-token=${res.data.otp_token}`
                window.location.href = url
              },
              icon:icons.Download,
              divider:false
            },
            {
              title:t("DELETE"),
              id:'4',
              onClick:(token:any) => {
                openModal('delete')
                setAlertTitle(t("TOKEN"))
                setAlertSubTitle([token])
                setSelectedToken(token)
              },
              icon:icons.DeleteIcon,
              divider:false
            },
          ] : [
            {
              title:t("DELETE"),
              id:'4',
              onClick:(token:any) => {
                openModal('delete')
                setAlertTitle(t("TOKEN"))
                setAlertSubTitle([token])
                setSelectedToken(token)
              },
              icon:icons.DeleteIcon,
              divider:false
            },
          ]
        }
      }))
      setPageLoading(false)
    })
    .catch((err) => {
      if(err?.response?.data?.detail){
        viewAlert({
          severity: 'error',
          message: err?.response?.data?.detail
        })
      }
    })
  }
  useEffect(() => {
    getTokens()
    const interval = setInterval(() => {
      getTokens()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }

  const closeModal = async (values:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'add-api-token':
          res = await apiAccessService.createToken(values)
          await getTokens()
          await setLoadingComplete(true)
          await setTimeout(() => {
            setLoading(false)
            setLoadingComplete(false)
            setModalType('')
            setViewModal(false)
            setActiveAnimation(false)
          },1000)
          break;
        case 'delete':
          res = await apiAccessService.revokeToken(selectedToken.id);
          await getTokens()
          await setLoadingComplete(true)
          await setTimeout(() => {
            setLoading(false)
            setLoadingComplete(false)
            setModalType('')
            setViewModal(false)
            setActiveAnimation(false)
          },1000)
          break; 

      }

    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      setLoading(false);

    } 
  }

  const Columns = [
    {
      title:t('ID'),
      key:'idView'
    },
    {
      title:t('NAME'),
      key:'name'
    },
    {
      title:t("TOKEN"),
      key:'token'
    },
    {
      title:t("Type"),
      key:'type'
    },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
    {
      title:'',
      key:'props'
    }
  ]

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <div style={{display:'flex', alignItems:'flex-start'}}>
        <Button
          title={t("ADD_API_TOKEN")}
          size='display'
          variant='primary'
          onClick={() => {
            openModal('add-api-token')
          }}
        />
      </div>
        {tokens?.length > 0 ?
          <Table
            hideCheckbox
            hideOther
            columns={Columns}
            rows={tokens}
          />
        : 
          <BodyDefault>
            {t("THERE_ARE_NO_API_TOKENS_YET")}
          </BodyDefault>
        }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        loadingComplete={loadingComplete}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
      />
    </Container>
  )
}

export default ApiAccessPage