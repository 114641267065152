import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  IdVolumeWrapper
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import { volumeService } from "../../../api";
import TopProgress from "../../TopBarProgress";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  alertTitle?:any;
  alertSubTitle?:any;
  loading?:boolean;
  instance?:any;
}  

const RevertVolume:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    loading,
    instance
  } = props
  const [t] = useTranslation()
  const [snapshot, setSnapshot] = useState<string>('')
  const [snapshotId, setSnapshotId] = useState<string>('')
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const getSnapshot = () => {
    volumeService.getSnapshotToRevert(instance.id)
    .then((res) => {
      setSnapshot(`${res.data.name} - ${res.data.id}`)
      setSnapshotId(res.data.id)
    })
    .catch((err) => {
      if(err?.response?.data?.detail){
        setSnapshot(err?.response?.data?.detail)
        setSnapshotId('')
      }
    })
    .finally(() => {
      setPageLoading(false)
    })
  }
  const onSubmit = () => {
    closeModal({snapshot_id:snapshotId})
    // setSnapshot('')
    // setSnapshotId('')
  }
  useEffect(() => {
    getSnapshot()
  },[instance])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {`${t("REVERT_VOLUME")} ${alertTitle}`}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Typography variant="body">{t("LAST_SNAPSHOT")}</Typography>
        <IdVolumeWrapper>
          <Typography variant='small'>
            {snapshot}
          </Typography>
        </IdVolumeWrapper>
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '247px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            disabled={!snapshotId}
            customStyles={{ width: '247px', justifyContent:'center' }}
            onClick={onSubmit}
            title={t("REVERT_VOLUME")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default RevertVolume