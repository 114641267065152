import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  PageBody,
  Form,
  AdditionalContainer,
  ButtonsWrapper,
} from './styles'
import {Typography, Input, Select, Button, Label, TopProgress, BackArrow} from '../../../components'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { clientService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

const EditClient:FC = () => {
  const [t] = useTranslation()
  const {id} = useParams()
  const {
    viewAlert,
  } = useUi()
  const navigate = useNavigate()
  const [initialValues, setInitialValues ] = useState<any>({
    firstName:'',
    lastName:'',
    country:'',
    company: '',
    phone: '',
    vat_id: '',
  })
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [pageloading, setPageLoading] = useState<boolean>(true)
  
  useEffect(() => {
    if(id){
      clientService.getClient(id)
      .then((res) => {
        setInitialValues({
          firstName:res.data.first_name,
          lastName:res.data.last_name,
          country:res.data.country,
          company: res.data.company,
          phone: res.data.phone,
          vat_id: res.data.vat_id,
        })
        clientService.getOptions()
        .then((res) => {
          const list = res.data.countries
          const index = list.findIndex((it:any) => it.value === 'UA')
          const item = list.find((it:any) => it.value === 'UA')
          list.splice(index, 1)
          list.unshift(item)
          setCountries(list)
        })
        .catch((e) => {
          console.log('err', e?.response?.data?.message);
        })
        .finally(() => {
          setPageLoading(false)
        })
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
        setPageLoading(false)
      })
    }
  },[])
  const [countries, setCountries] = useState<any[]>([])
  const {setDataToLs} = useLocalStorage()
  const onSubmit = (values:any) => {
    setLoading(true)
    setTimeout(() => {
      const data = {
        address1:"Bogdana 28",
        address2:"",
        city:"Kyiv",
        company:values?.company,
        country:values.country,
        custom_fields:[],
        first_name:values.firstName,
        last_name:values.lastName,
        phone:values?.phone,
        state:"Kyivska",
        vat_id:values?.vat_id || "",
        zip_code:"00000",
      }
      clientService.updateClient(id, data)
      .then((res) => {
        setLoadingComplete(true)
        setTimeout(() => {
          setLoadingComplete(false)
          setLoading(false)
          navigate('/clients')
        }, 1000)
      })
      .catch((e) => {
        console.log('err create client', e);
        if(e.response.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        } else {
          setErrors(e.response.data)
        }
        setLoading(false)
      })
    },500)
  }

  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      firstName: yup.string().required(`${t('REQUIRED_FIELD')}`),
      lastName: yup.string().required(`${t('REQUIRED_FIELD')}`),
      country: yup.string().required(`${t('REQUIRED_FIELD')}`),
      company: yup.string(),
      phone: yup.string().required(`${t('REQUIRED_FIELD')}`),
      vat_id: yup.string()
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  }

  return(
    <Container>
      <TopProgress loading={pageloading}/>
      {pageloading ? null : 
        <>
          <BackArrow customStyles={{marginBottom:'10px'}} onClick={() => navigate('/clients')} title={t("BACK_TO_CLIENTS")}/>
          <Typography variant='h1'>
            {t("EDIT_CLIENT")}
          </Typography>
          <PageBody>
            <Form>
              <Input
                required
                fieldName='firstName'
                title={t("FIRST_NAME")}
                customStyles={{width:'360px'}}
                placeholder=''
                {...formikProps}
              />
              <Input
                required
                fieldName='lastName'
                title={t("LAST_NAME")}
                customStyles={{width:'360px'}}
                placeholder=''
                {...formikProps}
              />
              <Select
                required
                toolTip={false}
                data={countries}
                selectedValue={values['country']}
                onChange={(e:string) => handleChange('country')(e)}
                title={t("COUNTRY")}
                hasSearch
                customStyles={{width:'360px'}}
                fieldName='country'
                {...formikProps}
              />
              <Input
                required
                fieldName='phone'
                title={t("PHONE")}
                customStyles={{width:'360px'}}
                placeholder=''
                phoneNumber
                {...formikProps}
              />
              <AdditionalContainer>
                <Typography variant='h3'>
                  {t("ADDITIONAL_INFO")}
                </Typography>
                <Input
                  fieldName='company'
                  title={t("COMPANY")}
                  customStyles={{}}
                  placeholder=''
                  {...formikProps}
                />
                <Input
                  fieldName='vat_id'
                  title={t("VAT_ID")}
                  customStyles={{}}
                  placeholder=''
                  {...formikProps}
                />
                <Label thinText reverse title={t("OPTIONAL_INFO_LABEL")}/>
              </AdditionalContainer>
              <ButtonsWrapper>
                <Button
                  variant="primary"
                  size="display"
                  onClick={handleSubmit}
                  loading={loading}
                  loadingComplete={loadingComplete}
                  title={t("SAVE")}
                />
                <Button
                  variant="stroke"
                  size="display"
                  onClick={() => navigate('/clients')}
                  title={t("CANCEL")}
                />
              </ButtonsWrapper>
            </Form>
          </PageBody>
        </>
      }
    </Container>
  )
}

export default EditClient