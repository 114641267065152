import React, { FC, useEffect, useState } from "react";
import { AlertProps } from "../../context/ui.context";
import { icons } from "../../icons";
import {
  Container,
  IconBackground,
  Title,
  TitleWrapper,
  Content,
  CloseIcon,
  ProgressBar,
  AlertCustom
} from './styles'
import Warning from './warning.svg'
import Error from './error.svg'
import Info from './info.svg'
import ImageIcon from '../side-bar/icons/images-hover.svg'
import {ThemeProvider} from 'styled-components'
import theme from '../../themes'
import Typography from "../typography";
import { useTranslation } from "react-i18next";
import { VerySmall } from "../typography/styled";

const Alert:FC<any> = (props) => {

  const {
    show,
    severity,
    message,
    deleteItem,
    timeout = 10000,
    canClose = true,
    progress,
    customStyles
    // custom
  } = props
  const [t] = useTranslation()
  const [closing, setClosing] = useState(false);
  const [isSuccess, setSuccess] = useState<boolean>(false)
  useEffect(() => {
    if (timeout && timeout != 0) {
      const timer = setTimeout(() => {
        handleClose();
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [timeout]);

  const handleClose = () => {
    if (!canClose) {
      return;
    }
    setClosing(true);
    setTimeout(() => {
      deleteItem();
    }, 250);
  };
  const selectedIcon = (type:string) => {
    switch(type){
      case 'info':
        return Info
      case 'error':
        return Error
      case 'warning':
        return Warning
      case 'image':
        return ImageIcon
      case 'file':
        return Info
      default:
        return Info
    }
  } 
  const selectedSeverityText = (type:string) => {
    switch(type){
      case 'info':
        return t("INFO")
      case 'error':
        return t("ERROR")
      case 'warning':
        return t("WARNING")
      case 'image':
        return t("UPLOAD_IMAGE")
      case 'file':
        return t("UPLOAD_FILE")
    }
  } 
  useEffect(() => {
    if(progress === 100){
      setSuccess(true)
      setTimeout(() => {
        deleteItem();
      },5000)
    }
  },[progress])
  return (
    <ThemeProvider  theme={theme()}>
    {/* @ts-ignore */}
      <AlertCustom style={customStyles} closing={closing} isDark={props.isDark}>
        <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
          <>
          <IconBackground isDark={props.isDark} type={severity}>
            <img src={selectedIcon(severity)}/>
          </IconBackground>
          <TitleWrapper>
            <Title isDark={props.isDark}>
              {selectedSeverityText(severity)}
            </Title>
            {severity === 'image' ?
              <div style={{display:'flex', alignItems:'center',gap:'6px'}}>
                <VerySmall style={props.isDark ? {color:'#FFF'} : {color:"#000"}}>
                    {progress}% 
                </VerySmall>
                <Content isDark={props.isDark}>
                  {isSuccess ? t("SUCCESSFULY_UPLOAD_IMAGE") : null}
                </Content>
              </div>
            : severity === 'file' ?
              <div style={{display:'flex', alignItems:'center',gap:'6px'}}>
                <VerySmall style={props.isDark ? {color:'#FFF'} : {color:"#000"}}>
                    {progress}% 
                </VerySmall>
                <Content isDark={props.isDark}>
                  {isSuccess ? t("SUCCESSFULY_UPLOAD_FILE") : null}
                </Content>
              </div>
            :
              <Content isDark={props.isDark}>
                {message}
              </Content>
            }
          </TitleWrapper>
          </>
          {canClose &&
            <CloseIcon isDark={props.isDark} onClick={handleClose} src={icons.CloseIcon}
          />}
        </div>
        {severity === 'image' || severity === 'file' ?
        <ProgressBar success={isSuccess} value={progress} max="100" />
        : null}
      </AlertCustom>
    </ThemeProvider>
  )
}


export default Alert