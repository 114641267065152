import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  BodyWrapper,
  WarningBlock,
  NotesContainer,
  NotesWrapper,
  NotesText,
  SelectWrapper,
  Divider,
  CheckBoxWrapper
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { BodyDefault, BodySmall, HeadLine3 } from "../../typography/styled";
import Select from "../../select";
import CheckBox from "../../checkbox";
import { useInstance } from "../../../context/instanceContext";
import TopProgress from "../../TopBarProgress";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { instanceService } from "../../../api";
import { useUi } from "../../../context/ui.context";
            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle?:any;
  alertSubTitle?:any;
  loading?:boolean;
  instance?:any;
}  

const InstanceResize:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    alertSubTitle,
    loading,
    instance,
  } = props
  const {
    viewAlert
  } = useUi()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    flavor:instance?.flavor?.id,
  })
  const [confirmResize, setConfirmResize] = useState<boolean>(false)
  const onSubmit = (values:any) => {
    if(instance.flavor.id === values.flavor){
      closeModalCancel()
    } else {
      closeModal(values.flavor)
    }
  }
  useEffect(() => {
    setInitialValues({
      flavor:instance?.flavor?.id,
    })
  },[instance])
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      flavor: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const {
    flavors,
    pageLoading,
    getCreateOptions
  } = useInstance()
  // const getCreateOptions = () => {
  //   instanceService.getResizeOptions(instance.id)
  //   .then((res) => {

  //   })
  //   .catch((err) => {
  //     if(err?.response?.data?.detail){
  //       viewAlert({
  //         severity:'error',
  //         message:err?.response?.data?.detail
  //       })
  //     }
  //     closeModalCancel()
  //   })
  // }
  useEffect(() => {
    getCreateOptions()
  },[instance])

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("RESIZE_INSTANCE")} «{alertTitle}»
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <BodyWrapper>
          <WarningBlock>
            <Icon src={icons.Warning}/>
            <BodySmall style={{color:"#000"}}>
              {t("INSTANCE_RESIZE_WARNING")}
            </BodySmall>
          </WarningBlock>
          {/* <NotesContainer>
            <NotesWrapper>
              <NotesText>
                {t("NOTES")}
              </NotesText>
              <BodyDefault>
                {t("INSTANCE_RESIZE_NOTES_1")}
              </BodyDefault>
            </NotesWrapper>
            <BodyDefault>
              {t("INSTANCE_RESIZE_NOTES_2")}
            </BodyDefault>
          </NotesContainer> */}
          {/* <Typography variant="body">{alertSubTitle}</Typography> */}
          <SelectWrapper>
            <Select
              required
              title={t("FLAVOR")}
              selectedValue={values['flavor']}
              customStyles={{width:'330px'}}
              test_id={'instance-flavor-select'}
              data={flavors.map((flavor:any, index:any) => {
                // const founded = flavors.findIndex((flav:any) => flav.id === instance?.flavor?.id)
                return{
                  ...flavor,
                  // blocked:founded > index,
                  // blockedDown:founded > index,
                }
              })}
              onChange={(e) => handleChange('flavor')(e)}
              fieldName='flavor'
              {...formikProps}
            />
          </SelectWrapper>
          <Divider/>
          <CheckBoxWrapper 
            onClick={() => setConfirmResize(!confirmResize)}
          >
            <CheckBox
              value={confirmResize}
              customStyles={{alignItems:'flex-start'}}
              test_id="confirm-resize-checkbox"
              onChange={() => setConfirmResize(!confirmResize)}
              title={
              <BodyDefault>
                {t("INSTANCE_RESIZE_CONFIRM_TEXT")}
              </BodyDefault>}
            />
          </CheckBoxWrapper>
        </BodyWrapper>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            disabled={!confirmResize || values['flavor'] === instance?.flavor?.id}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("RESIZE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default InstanceResize