import React, { FC, useState } from "react";
import {
  Container,
  NavItem,
  NavTitle,
  NavDesc,
  CreateWrapper,
  IconCreate,
  CreateLink,
  BackgroundIcon,
  ContentWrapper
} from "./styles";
import InstanceIcon from './instances-default.svg'
import InstanceHoverIcon from './instances-hover.svg'
import VolumesIcon from './volumes-default.svg'
import VolumesHoverIcon from './volumes-hover.svg'
import NetworksIcon from './network-default.svg'
import NetworksHoverIcon from './network-hover.svg'
import KubernetesIcon from './kubernetes-default.svg'
import KubernetesHoverIcon from './kubernetes-hover.svg'
import { useUi } from "../../../../context/ui.context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context/user.context";
import { ModalVariables } from "../../../../components";

type ItemType = {
  item:any
}

const HomeNavPanel:FC = () => {
  const [t] = useTranslation()
  const {user, quotas, appMenu} = useAuth()

  const navigate = useNavigate()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const openModal = (modal:any) => {
      setModalType(modal)
      setViewModal(true)
      setActiveAnimation(true)
    }
  const closeModalCancel = () => {
      setViewModal(false)
      setActiveAnimation(false)
    }
  const {
    setViewModalComplete,
    setActiveAnimationComplete,
    viewAlert,
  } = useUi()
  const _createFuncInstance = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas?.core?.instances?.limit === quotas?.core?.instances?.in_use){
      viewAlert({
        severity:'error',
        message:`${t('INSTANCE_QUOTA_EXCEEDED')}`,
      })
    } else {
      navigate('/instances/create?step=configure')
    }
  }
  const _createFuncVolume = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas?.volume?.volumes?.limit === quotas?.volume?.volumes?.in_use){
      viewAlert({
        severity:'error',
        message:`${t('VOLUMES_QUOTA_EXCEEDED')}`,
      })
    } else {
      navigate('/volumes/create')
    }
  }
  const _createFuncNetwork = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas?.network?.network?.limit === quotas?.network?.network?.in_use){
      viewAlert({
        severity:'error',
        message:`${t('NETWORK_QUOTA_EXCEEDED')}`,
      })
    } else {
      navigate('/networks/create')
    }
  }
  const _createFuncKubernetes = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } 
    else {
      if(user?.user?.access_kubernetes){
        openModal('cluster-alert')
      } else {
        navigate('/kubernetes')
      }
    }
  }
  const closeModal = async () => {
    try {
      switch (modalType) {
        case 'cluster-alert':
          navigate('/kubernetes/create')
      }
      if(modalType !== 'delete'){
        setViewModal(false)
        setActiveAnimation(false)
      }
    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    }

  }
  const data = [
    {
      title:t('INSTANCES'),
      desc:t("INSTANCE_NAV_DESC"),
      createText:t("CREATE_INSTANCE"),
      id:'1',
      image:InstanceIcon,
      hover:InstanceHoverIcon,
      nav:() => _createFuncInstance(),
      slug:'openstack.instances',
    },
    {
      title:t('VOLUMES'),
      desc:t("VOLUME_NAV_DESC"),
      createText:t("CREATE_VOLUME"),
      id:'2',
      image:VolumesIcon,
      hover:VolumesHoverIcon,
      nav:() => _createFuncVolume(),
      slug:'openstack.volumes',
    },
    {
      title:t('NETWORKS'),
      desc:t("NETWORK_NAV_DESC"),
      createText:t("CREATE_NETWORK"),
      id:'3',
      image:NetworksIcon,
      hover:NetworksHoverIcon,
      nav:() => _createFuncNetwork(),
      slug:'openstack.networks',
    },
    {
      title:t('KUBERNETES_CLUSTERS'),
      desc:t("KUBERNETES_NAV_DESC"),
      createText:t("CREATE_KUBERNETES_CLUSTER"),
      id:'4',
      image:KubernetesIcon,
      hover:KubernetesHoverIcon,
      nav:() => _createFuncKubernetes(),
      slug:'openstack.coe.clusters',
    }
  ]
  if(data?.filter((nav:any) => appMenu.includes(nav.slug))?.length === 0 ) return <div style={{height:"10px"}}/>
  return (
    <Container>
      {data?.filter((nav:any) => appMenu.includes(nav.slug)).map((item) => (
        <Component key={item.id} item={item}/>
      ))}
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
      />
    </Container>
  )
}


export default HomeNavPanel

const Component:FC<ItemType> = ({item}) => {
  const {themeDark} = useUi()
  return(
    <NavItem onClick={item?.nav} key={item.id}>
      <ContentWrapper>
        <div>
          <NavTitle>
            {item.title}
          </NavTitle>
          <NavDesc>
            {item.desc}
          </NavDesc>
        </div>
        <CreateWrapper>
          <IconCreate src={themeDark ? item.image : item.hover}/>
          <CreateLink>
            {item.createText}
          </CreateLink>
        </CreateWrapper>
      </ContentWrapper>
      <BackgroundIcon src={themeDark ? item.image : item.hover}/>
    </NavItem>
  )
}

