import React, {FC, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { instanceService } from '../../../../../api'
import { Button, ModalVariables, TopProgress } from '../../../../../components'
import { InfoContainer, InfoTitle } from '../../../../../components/info-block/styles'
import { BodyDefault } from '../../../../../components/typography/styled'
import { useUi } from '../../../../../context/ui.context'
import { icons } from '../../../../../icons'
import {
  Container,
  RulesWrapper,
  HeadContainer,
  RuleContainer
} from './styles'

type SecurityProps = {
  instance:any,
}

const SecurityGroupsInstancePage:FC<SecurityProps> = ({instance}) => {
  const [t] = useTranslation()
  const {instanceId} = useParams()
  const navigate = useNavigate()
  const [groups, setGroups] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [deletedGroup, setDeletedGroup] = useState<string>('')
  const [alertTitle, setAlertTitle] = useState<string>('')
  const {viewAlert} = useUi()
  const getGroups = () => {
    if(instanceId){
      instanceService.getSecurityGroups(instanceId)
      .then((res) => {
        setGroups(res.data.security_groups)
        setPageLoading(false)
      })
      .catch((err) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: err?.response?.data?.detail
          })
        }
      })
    }
  }
  useEffect(() => {
    getGroups()
    const interval = setInterval(() => {
      getGroups()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(modalType === 'associate-security' && instanceId){
      setLoading(true)
      instanceService.associateSecurityGroup(instanceId, values)
      .then((res) => {
        viewAlert({
          severity: 'info',
          message: `${t("SECURITY_GROUP_SUCCESSFULLY_ASSOCIATED")}`
        })
        getGroups()
      })
      .catch((err) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: err?.response?.data?.detail
          })
        }
      })
      .finally(() => {
        setViewModal(false)
        setActiveAnimation(false)
        setLoading(false)
      })
    }
    if(modalType === 'universal' && instanceId){
      setLoading(true)
      const data = {
        group: deletedGroup
      }
      instanceService.dissociateSecurityGroup(instanceId, data)
      .then((res) => {
        viewAlert({
          severity: 'info',
          message: `${t("SECURITY_GROUP_SUCCESSFULLY_DISSOCIATED")}`
        })
        getGroups()
      })
      .catch((err) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: err?.response?.data?.detail
          })
        }
      })
      .finally(() => {
        setViewModal(false)
        setActiveAnimation(false)
        setLoading(false)
      })
    }
  }
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <Button
        title={t("ASSOCIATE")}
        size='display'
        variant='primary'
        onClick={() => {
          setAlertTitle(instance.name)
          openModal('associate-security')
        }}
      />
      {groups.map((group:any) => (
        <InfoContainer>
          <HeadContainer>
            <InfoTitle  style={{cursor:'pointer'}}  onClick={() => navigate(`/security-groups/${group.id}`)}>
              {t("GROUP")}: {group.name}
            </InfoTitle>
            <Button
              onClick={() => {
                setAlertTitle(`${t("ARE_YOU_SHURE_WANT_TO_DISSOCIATE_SECURITY_GROUP")} «${group.name}»`)
                setDeletedGroup(group.id)
                openModal('universal')
              }}
              variant='icon'
              size='display'
              // disabled
              customStyles={{width:'30px', height:'30px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </HeadContainer>
          <RulesWrapper>
            {group.security_group_rules.map((rule:any, index:any) => (
              <RuleContainer isLast={group?.security_group_rules?.length === index+1}>
                <BodyDefault>
                  {rule.display}
                </BodyDefault>
              </RuleContainer>
            ))}
          </RulesWrapper>
        </InfoContainer>
      ))}
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        instanceId={instanceId}
        alertTitle={alertTitle}
        loading={loading}
      />
    </Container>
  )
}

export default SecurityGroupsInstancePage