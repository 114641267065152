import React, {FC, useEffect, useState} from "react";
import {
  Container,
  WrapperRow,
  Title,
  HideWrapper,
  BlueText,
  LeftSide,
  BodyText,
  BodyLink,
  QuotasContainer,
  StateWrapper,
  GreenDot,
  Greentext
} from '../Instances/styles'
import { Diagrams } from "../HomePage/components";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import StyledIcon from "../../components/styled-icon";
import { Button, ModalVariables, StartPage, Table, TutorialVodeo } from "../../components";
import TutorImg from './illustration.svg'
import TutorDarkImg from './illustration-dark.svg'
import { useTheme } from "styled-components";
import { useNavigate } from "react-router";
import { useUi } from "../../context/ui.context";
import { useAuth } from "../../context/user.context";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { compareArrays } from "../../utils/findIntersection";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import rootConfig from "../../config";
import { timeConverterMMDDYYHHMM } from "../../utils/dates";
import { portService } from "../../api";
import { handleStatusName } from "../../utils/handle-status-name";

const Ports:FC = () => {
  const [ports, setPorts] = useState<any[]>([])
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam);
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [paginationData, setPaginationData] = useState<any>()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedPort, setSelectedPort] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user, quotas} = useAuth()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      setAllChecked(false)
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(ports, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const {setDataToLs} = useLocalStorage()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const theme:any = useTheme()


  const _updatePorts = () => {
    // setLoading(true)
    portService.getPorts({
      page:page || 1,
      page_size:pageSize || 10,
      ordering:ordering,
    })
    .then((res) => {
      setPorts(res.data.objects.map((port:any) => {
        return {
          ...port,
          navigate: port.id,
          fixed_ips:(
            <>
              {port?.fixed_ips?.map((ip:any) => (
                <>
                  {ip?.ip_address || ip?.subnet_id}
                </>
              ))}
            </>
          ),
          name: port.name || port.id,
          admin_state:port?.admin_state_up ? `${t("YES")}` : `${t("NO")}`,
          created_at:timeConverterMMDDYYHHMM(port.created_at),
          status:(
            <StateWrapper>
              <GreenDot active={port.status}/>
              <Greentext active={port.status}>
                {handleStatusName(port.status,t)}
              </Greentext>
            </StateWrapper>),
        }
      }))
      setPaginationData(res.data)
      setPageLoading(false)
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      navigate('/home')
      setPageLoading(false)
    })
  }
  useEffect(() => {
    _updatePorts()
    const interval = setInterval(() => {
      _updatePorts()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }

  const items = [
    {
      title:t("EDIT"),
      id:'3',
      icon:icons.Edit,
      onClick: (port:any) => {
        navigate(`${port.id}/edit`)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(port:any) => {
        openModal('delete')
        setAlertTitle(t("PORT"))
        setAlertSubTitle([port])
        setSelectedPort(port)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]

  const Columns = [
    {
      title:t("NAME"),
      key:'name',
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    {
      title:t("FIXED_IPS"),
      key:'fixed_ips'
    },
    {
      title:t("MAC_ADDRESS"),
      key:'mac_address'
    },
    {
      title:t("DEVICE_OWNER"),
      key:'device_owner'
    },

    {
      title:t("ADMIN_STATE"),
      key:'admin_state'
    },
    {
      title:'',
      key:'props'
    },
  ]

  const closeModal = async () => {
    setLoading(true)
    try{
      if(allChecked) {
        let promises:any[] = []
        await ports.map((item:any) => {
          let promise = portService.deletePort(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
        await _updatePorts()
        await setAllChecked(false)
      } else if (checked?.length > 0){
        let promises:any[] = []
        await checked.map((item:any) => {
          let promise = portService.deletePort(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
        await _updatePorts()
        await setChecked([])
      } else if (selectedPort) {
        await portService.deletePort(selectedPort.id)
        await _updatePorts()
      }
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      _updatePorts()
      setPageLoading(false)
      setLoading(false)
    } finally {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }


  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else {
      navigate('create')
    }
  }
  return (
    <>
      <StartPage
        title={`${t("PORTS")}`}
        noResourceText={`${t("NO_PORTS")}`}
        createResourceButton={`${t('CREATE_PORT')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={ports.length > 0}
        learnMoreAboutResource={`${t('LEARN_MORE_PORTS')}`}
        howCreateVideoText={`${t('HOW_CREATE_PORT')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/ports`}
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("PORTS"))
                setAlertSubTitle(allChecked ? ports : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        videoImage={TutorImg}
        videoImageDark={TutorDarkImg}
        pageLoading={pageLoading}
        tableData={{
          columns:Columns,
          data:ports,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          paginationData:paginationData,
          updateData:_updatePorts
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={selectedPort}
      />
    </>
  )
}


export default Ports



