import React, { FC, useEffect, useState } from "react";
import {
  Container,
  Headers,
  HeadersWrapper,
  HeadersColumnWrapper,
  Divider,
} from './styles'
import {
  Button,
  Input,
  Select,
  RevealButton,
  Typography,
  CheckBox,
  DonutArea,
  RadioButtonGroup,
  Counter,
  TopProgress,
  ModalVariables,
} from "../../../../components";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from 'use-query-params';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useBalancer } from "../../../../context/balancerContext";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { useInstance } from "../../../../context/instanceContext";
import { useKubernetes } from "../../../../context/kubernetesContext";
import { randomNameGenerator } from "../../../../utils/create-random-name";
import { useAuth } from "../../../../context/user.context";
import { ErrorContainer } from "../../../Instances/components/create-instance/configure/styles";
import { ErrorText } from "../../../HomePage/styles";
import ErrorIc from './ic-error.svg'
import { kubernetesService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";

const ConfigureMaster:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {user, quotas} = useAuth()
  const {viewAlert} = useUi()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loadingGetName, setLoadingGetName] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [typeValid, setTypeValid] = useState<any>()
  const [modalType, setModalType] = useState<any>('')
  const {
    clusterData,
    setClusterData,
    kuberVersions,
    
  } = useKubernetes()
  const {
    setDataToLs,
    getDataToLs
  } = useLocalStorage()
  const {
    getCreateOptions,
    flavors,
    sshKeys,
    pageLoading,
    loadingRefresh,
    
  } = useInstance()

  const [initialValues, setInitialValues] = useState<any>({
    name: getDataToLs({key:'cluster.configure_master'})?.name ||randomNameGenerator({name:'cluster', findArr:[]}),
    cluster_version:clusterData?.cluster_version || getDataToLs({key:'cluster.configure_master'})?.cluster_version || kuberVersions?.[0]?.value || '',
    flavor:clusterData?.flavor || getDataToLs({key:'cluster.configure_master'})?.flavor || flavors.filter((flav:any) => flav.memory_gb >= 4)[0]?.value ||  '',
    count_master:clusterData?.count_master || getDataToLs({key:'cluster.type'})?.masterCount || getDataToLs({key:'cluster.configure_master'})?.count_master || 1,
    ssh:clusterData?.ssh || getDataToLs({key:'cluster.configure_master'})?.ssh ||'',
    os:clusterData?.os ||
    getDataToLs({key:'cluster.type'})?.os ||
    getDataToLs({key:'cluster.configure_master'})?.os || '' 
  })
  const onSubmit = (values:any) => {
    setLoadingGetName(true)
    kubernetesService.checkName(values.name)
    .then((res) => {
      if(res.data.cluster_name_available){
        setStep('networking')
      } else {
        setErrors({name:`${t("CLUSTER_NAME_ALREADY_EXISTS")}`})
      }
    })
    .catch((err:any) => {
      viewAlert({
        severity:'error',
        message: err?.response?.data?.detail || t("ERROR_OCCURRED")
      })
    })
    .finally(() => {
      setLoadingGetName(false)
    })
    // setStep('networking')

  }
  const maxCountInstances = (quotas:any, value?:any) => {
    const flavorObj = flavors.find((flav:any) => flav.id === typeValid)
    if(!flavorObj){
      return quotas?.core?.instances?.limit - quotas?.core?.instances?.in_use
    }
    let maxCores = (quotas?.core?.cores?.limit - quotas?.core?.cores?.in_use) / flavorObj?.vcpus
    let maxRam = (quotas?.core?.ram?.limit - quotas?.core?.ram?.in_use) / flavorObj?.memory_mb
    let maxInstances = quotas?.core?.instances?.limit - quotas?.core?.instances?.in_use
    return Math.min(Math.floor(maxCores), Math.floor(maxRam), Math.floor(maxInstances) )
  }
  const regName = /^[a-z0-9]+([-a-z0-9]*[a-z0-9]+)*(\.[a-z0-9]+([-a-z0-9]*[a-z0-9]+)*)*$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setErrors
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().matches(regName,`${t("ONLY_ENGLISH_LETTERS_SMALL")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`).required(`${t('REQUIRED_FIELD')}`),
      cluster_version: kuberVersions?.length > 0 ? yup.string().required(`${t('REQUIRED_FIELD')}`) : yup.string(),
      flavor: yup.string().required(`${t('REQUIRED_FIELD')}`),
      count_master:yup.number().required(`${t('REQUIRED_FIELD')}`)
        .positive()
        .integer()
        .min(1, `${t("COUNT_MUST_BE_GREATER_OR_EQUAL")} 1`)
        .test('is-odd', `${t("COUNT_MUST_ODD")}`, (value:any) => value % 2 !== 0)
        .max(maxCountInstances(quotas), `${t("MAX_COUNT_MACHINES")} ${maxCountInstances(quotas)}`),

      // ssh: yup.string(),
      os: yup.string().required(`${t('REQUIRED_FIELD')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  useEffect(() => {
    setDataToLs({key:'cluster.configure_master', data:values})
    setClusterData(values)
  },[values])
  // useEffect(() => {
    
  // },[values['cluster_version']])
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  useEffect(() => {
    setTypeValid(values['flavor'])
  },[values['flavor']])
  const defaultDataDisk = [
    {
      part1:10,
      part2:32,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_READING"),
      value:50,
      valueMax:160
    },
    {
      part1:4,
      part2:10.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_WRITING"),
      value:20,
      valueMax:54
    }
  ]
  const defaultOperationDisk = [
    {
      part1:4.4,
      part2:22.2,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_IOPS_READING"),
      value:2000,
      valueMax:10000
    },
    {
      part1:1.5,
      part2:28.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_IOPS_WRITING"),
      value:700,
      valueMax:13000
    }
  ]
  const closeModal = () => {
    // if(modalType === 'delete'){
    //   _deleteInstances()
    // }
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const buttons = [
    {
      value:'new',
      id:'1',
      title:"SSD"
    },
    {
      value:'new1',
      id:'2',
      title:"HighIOPS",
      disabled:true,
    },
  ]
  if(pageLoading) return (
    <>
      <TopProgress loading={pageLoading}/>
      {/* <ContentLoader backgroundColor={theme.palette[10]} viewBox="0 0 500 800" height={800} width={500}>
        <rect x="0" y="45" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="125" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="205" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="285" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="365" rx="4" ry="4" width="120" height="40" />
        <rect x="100" y="445" rx="4" ry="4" width="80" height="40" />
        <rect x="0" y="445" rx="4" ry="4" width="80" height="40" />
      </ContentLoader> */}
    </>
  )
  return (
    <Container>
      <Input
        fieldName='name'
        title={t("NAME_CLUSTER")}
        placeholder=''
        customStyles={{width:'360px'}}
        {...formikProps}
      />
      <Select
        toolTip={false}
        data={kuberVersions}
        disabled={kuberVersions?.length <= 0}
        selectedValue={values['cluster_version']}
        onChange={(e) => {
          handleChange('os')('')
          handleChange('cluster_version')(e)}}
        placeholder={kuberVersions?.length <= 0 ? t("NO_DATA") : t("CHOOSE")}
        title={t("VERSION_KUBERNETES")}
        customStyles={{width:'360px'}}
        fieldName='cluster_version'
        {...formikProps}
      />
      <Select
        toolTip={false}
        data={kuberVersions.find((v:any) => v.value === values['cluster_version'])?.images}
        disabled={kuberVersions.find((v:any) => v.value === values['cluster_version'])?.images?.length <= 0}
        selectedValue={values['os']}
        onChange={(e) => handleChange('os')(e)}
        title={t("OS")}
        customStyles={{width:'360px'}}
        fieldName='os'
        {...formikProps}
      />
      <Select
        toolTip={false}
        data={flavors.filter((flav:any) => flav.memory_gb >= 4)}
        disabled={flavors?.length <= 0}
        selectedValue={values['flavor']}
        onChange={(e) => handleChange('flavor')(e)}
        placeholder={flavors?.length <= 0 ? t("NO_FLAVORS_AVAILABLE") : t("CHOOSE")}
        title={t("TYPE_MASTER_NODE")}
        customStyles={{width:'360px'}}
        fieldName='flavor'
        {...formikProps}
      />
      {/* <SelectWrapper>
        <SelectRow>
          <div style={{width:'360px'}}>
            <Select
              toolTip={false}
              data={sshKeys}
              selectedValue={values['ssh']}
              test_id={'instance-ssh-select'}
              onChange={(e) => {
                handleChange('ssh')(e)}}
              title={t("SSH_KEY")}
              disabled={sshKeys.length === 0}
              {...formikProps}
              fieldName='ssh'
            />
          </div>
          <RefreshWrapper>
            <RotateImg active={loadingRefresh} src={Refresh}/>
            <RefreshText onClick={getCreateOptions}>
              {t("REFRESH_LIST")}
            </RefreshText>
          </RefreshWrapper>
        </SelectRow>
        <div style={{display:'flex', marginTop:'10px',}}>
          <Button
            variant="primary"
            size="small"
            customStyles={{marginRight:"10px", padding:'9px'}}
            onClick={() => openModal('generate-ssh')}
            title={t("CREATE_NEW_KEY")}
          />
          <Button
            variant="stroke"
            size="small"
            onClick={() => openModal('create-ssh')}
            title={t("ADD_PUBLIC_SSH")}
          />
        </div>
      </SelectWrapper> */}
      <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
        <RadioButtonGroup
          toolTip={false}
          title={t("DISK_TYPE")}
          buttons={buttons}
          // disabled={}
          selectedButton={values['type'] || buttons?.[0]?.value}
          onChange={(e) => {}}
        />
      </div>
      <div style={{display:'flex',flexWrap:'wrap', gap:'20px'}}>
        <DonutArea
          title={t("BANDWIDTH")}
          subtitle={t("MB_S")}
          isLeft
          data={defaultDataDisk}
        />
        <DonutArea
          title={t("OPERATIONS_PER_SEC")}
          subtitle={t("IOPS")}
          data={defaultOperationDisk}
        />
      </div>
      <Counter
        // customStyles={{marginBottom:"20px"}}
        // toolTip={true}
        // tooltipText={`${t("NUMBER_OF_MASTER_NODES")}`}
        title={t("NUMBER_OF_MASTER_NODES")}
        value={values['count_master']}
        minCount={1}
        hideItems={`${t("ITEMS")}`}
        onChange={(v:any) => {
          handleChange('count_master')(v.toString())
        }}
        fieldName='count_master'
        {...formikProps}
      />
      {/* <Counter
        // customStyles={{marginBottom:"20px"}}
        toolTip={true}
        tooltipText={`${t("STORAGE_SIZE_TOOLTIP")}`}
        title={t("STORAGE")}
        value={values['size']}
        minCount={1}
        hideItems={'GB'}
        onChange={(v:any) => {
          handleChange('size')(v.toString())
        }}
        fieldName='size'
        {...formikProps}
      /> */}
      {maxCountInstances(quotas) < values['count_master'] &&
        <ErrorContainer isError={true}>
          <img src={ErrorIc}/>
          <ErrorText>{t("ERROR_CLUSTER_CREATE_QUOTAS")}</ErrorText>
        </ErrorContainer>
      }
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          loading={loadingGetName}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep('cluster-type')}
          title={t("BACK")}
        />
      </div>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
      />
    </Container>
  )
}

export default ConfigureMaster