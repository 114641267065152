import React, { FC, useEffect, useState } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  CheckBoxWrapper,
  RolesContainer,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import CheckBox from "../../checkbox";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/user.context";
import { clientService } from "../../../api";
import { useUi } from "../../../context/ui.context";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:() => void;
  obj?:any,
}  


const EditRoles:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    obj,
  } = props
  const {
    availableNotifications,
    availableRoles,
    clientForInvite
  } = useAuth()
  const [t] = useTranslation()
  const [selectedRoles, setSelectedRoles] = useState<any[]>(obj._roles.map((item:any) => item.id))
  const {id} = useParams()
  const {viewAlert} = useUi()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  useEffect(() => {
    setSelectedRoles(obj._roles.map((item:any) => item.id))
  },[obj])
  const onSubmit = () => {
    if(selectedRoles.length === 0){
      viewAlert({
        severity:'error',
        message:`${t("AT_LEAST_ONE_ROLE_MUST_BE_SELECTED")}`
      })
      return
    }
    setLoading(true)
    const data = {
      id: clientForInvite.id,
      roles:selectedRoles,
      user_id: obj.id
    }
    clientService.updateUserRoles(id, data)
    .then((res) => {
        setLoadingComplete(true)
        setTimeout(() => {
          setLoadingComplete(false)
          setLoading(false)
          closeModal()
        },800)

    })
    .catch((e) => {
      setLoading(false)
      setLoadingComplete(false)
      if(e?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:e?.response?.data?.detail
        })
      }
    })
  }
  const editSelectedRoles = (id:string) => {
    const founded = selectedRoles.find((item:any) => item === id)
    if(founded) {
      setSelectedRoles((prev:any) => {
        return prev.filter((item:any) => item !== id)
      })
    } else {
      setSelectedRoles([...selectedRoles, id])
    }
  } 

  // const roles = [
  //   {title:t("OWNER"), id:1},
  //   {title:t("PROGRAMMER"), id:2},
  //   {title:t("ACCOUNTER"), id:3},
  //   {title:t("MEMBER"), id:4},
  //   {title:t("ONLY_NOTIFICATIONS"), id:5},
  // ]

  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {t("EDIT_USER_ROLES_FOR")}&nbsp;
          {obj.full_name}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Typography variant='body-medium'>
          {t("SELECT_USER_ROLES")}
        </Typography>
        <RolesContainer>
          {availableRoles.map((item:any) => (
            <CheckBoxWrapper onClick={() => editSelectedRoles(item.id)}>
              <CheckBox
                title={item.display_name}
                value={selectedRoles.find((role) => role === item.id)} 
                onChange={() => editSelectedRoles(item.id)}
              />
            </CheckBoxWrapper>
          ))}
        </RolesContainer>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={onSubmit}
            title={t("SAVE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default EditRoles