import styled from "styled-components";
import { BodyDefault, BodyMedium, BodySmall, HeadLine2 } from "../../../../components/typography/styled";
import SVG from "react-inlinesvg";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width:50%;
  margin-top: 30px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom:10px;
  width: 100%;
`

export const Title = styled(HeadLine2)`
  color:${({theme}) => theme.palette[850]};
`
export const Link = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
  &:hover{
    cursor:pointer;
  }
`

export const Day = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  border-radius: 4px;
  margin-bottom: 10px;
  gap: 10px;
  width: 53px;
  height: 28px;
  background:${({theme}) => theme.palette[200]};
`

export const DayText = styled(BodySmall)`
  color:${({theme}) => theme.palette[800]};
`

export const LogItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0px;
  gap: 66px;
  
  /* width: 535px; */
  min-height: 32px;
  border-bottom: ${({theme}) => `1px solid ${theme.palette[300]}`};
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`

export const Time = styled(BodyDefault)`
  color:${({theme}) => theme.palette[700]};
  margin-right: 20px;
`
export const SettingsIcon = styled(SVG)`
  & path {
    fill:${({theme}) => theme.isDark ? theme.palette[800] : ''};
  }
  width:18px;
  height:18px;
  margin-right:4px;
`
export const LogName = styled(BodyMedium)`
  color:${({theme}) => theme.palette[850]};
`
export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  justify-content: space-between;
`

export const Content = styled(BodyDefault)`
  color:${({theme}) => theme.palette[700]};
  width: 45%;
`