import React, { FC, useEffect, useRef, useState } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  PasswordWrapper,
  Divider
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { HeadLine3 } from "../../typography/styled";
import { useUi } from "../../../context/ui.context";
import MatchPassword from "../../password-matches";
import Input from "../../input";


type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  instanceId?:string;
  loading?:boolean;
  instance?:any;
  alertTitle?:string;
  loadingComplete?:boolean;
}  


const ChangePasswordUser:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instanceId,
    loading,
    loadingComplete,
    instance,
    alertTitle,
  } = props

  const [t] = useTranslation()
  const InputRef:any = useRef()
  const [activeConfirm, setActiveConfirm] = useState<boolean>(false)
  const [activeButton, setActiveButton] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({
    old_password:'',
    password:'',
    password_confirm:'',
  
  })
  const onSubmit = (values:any) => {
    closeModal(values)
  }

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      old_password:instance?.local_password_exist ? yup.string().required(`${t('REQUIRED_FIELD')}`).max(24, `${t('PASSWORD_VALIDATE_MAX')}`) : yup.string(),
      password: yup.string().required(`${t('REQUIRED_FIELD')}`).max(24, `${t('PASSWORD_VALIDATE_MAX')}`),
      password_confirm: yup.string().required(`${t('PASSWORD_CONFIRM_REQUIRED')}`)
      .oneOf([yup.ref('password'), null], `${t('PASSWORDS_MATCH_ERROR')}`),
      otp:instance.google ? yup.string().required(`${t("REQUIRED_FIELD")}`) : yup.string()
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {instance?.local_password_exist ? t("CHANGE_PASSWORD") : t("CREATE_PASSWORD")}
        </HeadLine3>
        <Icon 
          onClick={closeModalCancel}
          src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        {instance?.local_password_exist && 
          <>
            <Input
              title={t('PASSWORD_OLD')}
              fieldName='old_password'
              placeholder={''}
              onBlur={handleBlur}
              isPassword
              {...formikProps}
            />
            <Divider/>
          </>
        }
        <PasswordWrapper>
          <Input
            title={t('PASSWORD_NEW')}
            fieldName='password'
            placeholder={''}
            onFocus={() => setActiveConfirm(true)}
            onFocusOut={() => setActiveConfirm(false)}
            onBlur={handleBlur}
            ref={InputRef}
            isPassword
            {...formikProps}
          />
          <MatchPassword
            setEnabled={setActiveButton}
            validateString={values['password']}
            active={activeConfirm}
          />
        </PasswordWrapper>
        <Input
          title={t('PASSWORD_REPEAT')}
          fieldName='password_confirm'
          placeholder={''}
          onBlur={handleBlur}
          isPassword
          {...formikProps}
        />
        {instance.google &&
        <Input
          required
          title={t('OTP_CODE')}
          fieldName='otp'
          placeholder={t('OTP_CODE')}
          customStyles={{width:'360px'}}
          {...formikProps}
        />
        }
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            disabled={!activeButton}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={instance?.local_password_exist ? t("CHANGE_PASSWORD") : t("CREATE_PASSWORD")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default ChangePasswordUser