import React, { FC } from 'react';
import styled from 'styled-components'
import firstIc from './first_icon_node.svg'
import middleIc from './middle_icon_node.svg'
import lastIc from './last_icon_node.svg'
import firstSoloIc from './first_solo.svg'

const ImageMiddle = styled.img`
  position:absolute;
  top:-23px;
`
const NameWrapper = styled.div`
  margin-left:25px;
`
const ViewNodeName:FC<any> = ({i, node, n}) => (
  <div>
    {i === 0 && node?.nodes?.length === 1 && <><ImageMiddle style={{ top: '3px' }} src={firstSoloIc} /> <NameWrapper>{n?.name}</NameWrapper></>}
    {i === 0 && node?.nodes?.length !== 1 && <><ImageMiddle style={{ top: '3px' }} src={firstIc} /> <NameWrapper>{n?.name}</NameWrapper></>}
    {i !== 0 && i !== (node?.nodes?.length - 1) && <><ImageMiddle style={{ }} src={middleIc} /> <NameWrapper>{n?.name}</NameWrapper></>}
    {i !== 0 && i === (node?.nodes?.length - 1) && <><ImageMiddle style={{ }} src={lastIc} /> <NameWrapper style={{ marginLeft: '25px' }}>{n?.name}</NameWrapper></>}
  </div>
)

export default ViewNodeName