import React, { FC, useState, useRef, useEffect } from "react";
import {
  Container,
  DropItem,
  Divider,
  DropTitle,
} from './styles'
import StyledIcon from "../styled-icon";
import { useLocation, useNavigate } from "react-router-dom";

type DropItemType = {
  title:string,
  id:string,
  icon:any,
  divider:boolean,
  disabled?:boolean,
}
type DropType = {
  active?:boolean,
  handleClose?:any,
  options?:any[],
  itemToEdit?:any,
  checkFiled?:any,
  limitedItems?:any[]
  ref?:any,
}
const DropOptions:FC<DropType> = ({options, itemToEdit, limitedItems, handleClose, ref}) => {
  return (
      <Container>
        {
          itemToEdit?.invitation ? 
          limitedItems?.map((item:DropItemType) => (
            <DropItemComponent handleClose={handleClose} itemToEdit={itemToEdit} key={item.id} item={item}/>
              
          )) :
          options?.map((item:DropItemType) => (
            <DropItemComponent handleClose={handleClose} itemToEdit={itemToEdit} key={item.id} item={item}/>
              
          ))
        }
      </Container>
    
  )
}

export default DropOptions

const DropItemComponent:FC<any> = ({item, itemToEdit, handleClose}) => {
  const [hover, setHover] = useState<boolean>(false)
  const navigate = useNavigate()
  const [activeIcon, setActiveIcon] = useState<boolean>(false)

 

  return (
    <>
      <DropItem
        disabled={item?.disabled}
        onClick={() => {
          if(item?.disabled){
            return
          }
          if(item.onClick){
            item.onClick(itemToEdit)
            handleClose()
          }
          if(item.navigate){
            navigate(item.navigate)
          }
        }}
        key={item.id}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseDown={() => setActiveIcon(true)}
        onMouseUp={() => setActiveIcon(false)}

      >
        <div style={{display:'flex'}}>
          <StyledIcon toChangeColor={item?.toChangeColor} active={activeIcon} hover={hover} src={item.icon}/>
          <DropTitle>
            {item.title}
          </DropTitle>
        </div>
      </DropItem>
      {item.divider ? 
        <Divider/>
      :
        null
      }
    </>
  )
}
