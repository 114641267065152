import styled, { css } from 'styled-components'

type StyleButtonProps = {
  size?: 'display' | 'mid' | 'small' | 'custom',
  customStyles?:any,
  isDelete?:boolean,
  loadingComplete?:boolean,
  loading?:boolean,
}
function styleMyEl(size:any, customStyles?:any) {
  switch (size) {
    case 'display':
      return css`
        /* width: 80px; */
        height: 40px;
        ${customStyles}
      `;
    case 'mid':
      return css`
        /* width: 80px; */
        height: 36px;
        ${customStyles}
      `;
    case 'small':
      return css`
        /* width: 68px; */
        height: 30px;
        ${customStyles}
      `;
    case 'custom':
      return css`
        ${customStyles}
      `;
    default:
      return css`
        /* width: 68px; */
        height: 30px;
        ${customStyles}
      `;
  }
}
const PrimaryButton = styled.button<StyleButtonProps>`
  box-sizing: border-box;
  /* background: ${({theme}) => theme.primary[600]}; */
  background: ${({theme, isDelete, loadingComplete}) => loadingComplete ? theme.success : isDelete ? '#E85048' : '#4671F6'};
  border-radius: 4px;
  border:none;
  /* letter-spacing: 0.45px; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 10px 16px;
  font-family:Inter;
  color: #FFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  ${({size, customStyles}) => styleMyEl(size, customStyles)};
  &:hover{
    background: ${({theme, isDelete}) => isDelete ? '#F96E67' : theme.primary[400]};
    cursor: pointer;
  }
  &:active{
    background: ${({theme, isDelete}) => isDelete ? '#CD3A32' : theme.primary[500]};

  }
  &:disabled{
    background: ${({theme, loadingComplete, loading}) => loadingComplete || loading ? '' : theme.isDark ? theme.palette[200] : '#DBDBDB'};
  }
`

export default PrimaryButton
