import React, { FC, useEffect, useState } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { useUi } from "../../../context/ui.context";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  alertTitle?:string;
  loading?:boolean;
  instance?:any;
}  


const ResetStateVolumeSnapshot:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instance,
    loading
  } = props
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    state:'',
  })
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  useEffect(() => {
    setInitialValues({
      state:instance.status,
    })
  },[instance])
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      state: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  

  const statuses = [
    {
      value: 'available',
      label:t("AVAILABLE"),
    },
    {
      value: 'error',
      label:t("ERROR"),
    },
  ]
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {t("UPDATE_VOLUME_SNAPSHOT")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Select
          required
          toolTip={true}
          data={statuses}
          selectedValue={values['state']}
          onChange={(e) => {
            handleChange('state')(e)}}
          title={t("NEW_STATE")}
          customStyles={{width:'360px'}}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("UPDATE_SNAPSHOT")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default ResetStateVolumeSnapshot