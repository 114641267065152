import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import {
  Container,
  Title,
  SubTitle,
  TitleWrapper,
  ErrorContainer,
  ErrorText,
  UploadedFileContainer,
  SubTitleWrapper,
  UploadInfoWrapper,
  FileNameWrapper,
  ProgressWrapper,
  ProgressBar,
  DragWrapper,
  FileText,
  BackGround,
  ImageDeleteFile,
  Overlay,
  Zirochka
} from './styles'
import DocumentIcon from './document.svg'
import UploadedDocumentIcon from './uploaded-document.svg'
import Typography from '../typography'
import AlertIcon from './error.svg'
import { icons } from '../../icons'
import StyledIcon from '../styled-icon'
import { formatFileSize } from '../../utils/sizes'
import { BodySmallMedium } from '../typography/styled'
import { useTranslation } from 'react-i18next'



type DragOptions = {
  onChange:(file:any) => void,
  customStyles?:any,
  uploadedFile?:any,
  setStringSSh?:any,
  isSsh?:boolean,
  isImage?:boolean,
  formats?:string,
  fileValidation?:any,
  required?:boolean,
  requiredListener?:boolean,
}
function endsWithOptions(str: string, options: string[]): boolean {
  for (const option of options) {
    if (str.endsWith(option)) {
      return true;
    }
  }
  return false;
}
const DragAndDrop:FC<DragOptions> = (props) => {
  const {
    onChange,
    customStyles,
    uploadedFile,
    setStringSSh,
    isSsh,
    formats,
    fileValidation,
    isImage,
    required,
    requiredListener
  } = props
  // const inputRef:any = useRef()
  const [dragActive,  setDragActive] = React.useState(false);
  const [error, setError] = useState<string>('')
  const [file, setFile] = useState<any>()
  const [prev, setPrev] = useState<any>()
  const containerRef:any = useRef()
  const [t] = useTranslation()
  const handleDrag = (e:any) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragIn = (e:any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragActive(true)
    }
  }
  const handleDragOut = (e:any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
  }
  const handleDrop = (e:any, isSelect?:boolean) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if(!isSelect){
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        if(!e.dataTransfer.files[0].name.endsWith('.pub') && isSsh){
          setError(`${t("ERROR_UPLOAD_FILE_FORMAT_NOT_SUPPORTED")}`)
          return
        }
        if(isImage && !endsWithOptions(e.dataTransfer.files[0].name, fileValidation)){
          setError(`${t("ERROR_UPLOAD_FILE_FORMAT_NOT_SUPPORTED")}`)
          return
        }
        if(e.dataTransfer.files[0].name.endsWith('.pub')){
          setError("")
        }
        const reader = new FileReader();
        const file = e.dataTransfer.files[0]
        reader.onloadend = () => {
          setPrev(reader.result)
          //@ts-ignore
          let updated = reader.result?.replace('data:application/x-mspublisher;base64,', '')?.replace('data:application/octet-stream;base64,', '')
          //@ts-ignore
          const originalString = decodeURIComponent(escape(window.atob(updated)));
          setStringSSh(originalString)
        }
        reader.readAsDataURL(file)
        onChange(e.dataTransfer.files[0])
        e.dataTransfer?.clearData()
      }
    } else {
        if(e.target.files[0]){
          setError("")
        }
        if(!e.target.files[0].name.endsWith('.pub') && isSsh){
          setError(`${t("ERROR_UPLOAD_FILE_FORMAT_NOT_SUPPORTED")}`)
          return
        }
        if(isImage && !endsWithOptions(e.target.files[0].name, fileValidation)){
          setError(`${t("ERROR_UPLOAD_FILE_FORMAT_NOT_SUPPORTED")}`)
          return
        }
        const reader = new FileReader();
        const file = e.target.files[0]
        reader.onloadend = () => {
          setPrev(reader.result)
          //@ts-ignore
          let updated = reader.result?.replace('data:application/x-mspublisher;base64,', '')?.replace('data:application/octet-stream;base64,', '')
          //@ts-ignore
          const originalString = decodeURIComponent(escape(window.atob(updated)));
          setStringSSh(originalString)
        }
        reader.readAsDataURL(file)
        onChange(e.target.files[0])
        e?.target?.clearData()
      }

  }
  useEffect(() => {
    let div = containerRef.current
      div?.addEventListener('dragenter', handleDragIn)
      div?.addEventListener('dragleave', handleDragOut)
      div?.addEventListener('dragover', handleDrag)
      div?.addEventListener('drop', handleDrop)
		return () => {
			div?.removeEventListener('dragenter', handleDragIn)
      div?.removeEventListener('dragleave', handleDragOut)
      div?.removeEventListener('dragover', handleDrag)
      div?.removeEventListener('drop', handleDrop)
		};
  });
  // function encryptMessage(message?:any, publicKey?:any) {
  //   const jsEncrypt = new JSEncrypt();
  //   jsEncrypt.setPublicKey(publicKey);
   
  //   return jsEncrypt.encrypt(message);
  // }
  const handleDeleteFile = () => {
    onChange(null)
    setDragActive(false)
    setStringSSh('')
  }
  return (
    <DragWrapper customStyles={customStyles}>
      <FileText>
        {t("FILE")}{required ? <Zirochka>*</Zirochka> : null}
      </FileText>
      {uploadedFile ?
      
        <UploadedFileContainer>
          <ProgressWrapper>
            <UploadInfoWrapper>
              <img src={UploadedDocumentIcon}/>
              <FileNameWrapper>
                <BodySmallMedium style={{wordBreak:'break-all'}}>
                  {uploadedFile.name}
                </BodySmallMedium>
                <SubTitleWrapper>
                  <Typography variant='very-small'>
                    {formatFileSize(uploadedFile.size)}
                  </Typography>
                </SubTitleWrapper>
              </FileNameWrapper>
            </UploadInfoWrapper>
          </ProgressWrapper>
          <StyledIcon onClick={handleDeleteFile} src={icons.CloseIcon}/>
        </UploadedFileContainer>
      :
      <BackGround   active={dragActive}>
      <label  style={{width: '550px'}} htmlFor='file-input'>
          <Container
            isError={error.length > 0}
            active={dragActive}
            onDragEnter={handleDrag}
          >
              <input style={{display:'none'}} type='file' id="file-input" onChange={(e) => handleDrop(e, true)} />
              <img src={DocumentIcon}/>
              <TitleWrapper style={{display:'flex'}}>
              <Title>{t("CLICK_TO_UPLOAD")}</Title> &nbsp;
              <Typography variant='body'>
                {t("OR_DRAG")}
              </Typography>
              </TitleWrapper>
              <SubTitle>{formats ? formats : t("INFO_FILE_SIZE")}</SubTitle>
              <ErrorContainer isError={error.length > 0}>
                <img src={AlertIcon}/>
                <ErrorText>{t("ERROR_FILE_UPLOAD")}</ErrorText>
              </ErrorContainer>
          </Container>
        </label>
        <Overlay htmlFor='file-input' ref={containerRef} />
        </BackGround>
      // <div className="container">
      //   <div {...getRootProps({style})}>
      //     <input {...getInputProps()} />
      //     <p>Drag 'n' drop some files here, or click to select files</p>
      //   </div>
      // </div>
      // <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
      //   {({getRootProps, getInputProps}) => (
      //     <section>
      //       <div {...getRootProps()}>
      //         <input {...getInputProps()} />
      //         <p>Drag 'n' drop some files here, or click to select files</p>
      //       </div>
      //     </section>
      //   )}
      // </Dropzone>
      }
      {requiredListener && <ErrorText style={{marginTop:'6px'}}>{t("FILE_REQUIRED")}</ErrorText>}
    </DragWrapper>
  )
}

export default DragAndDrop