import React, { FC, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  BodyTextGrey,
  InvoiceIdWrapper
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { BodySmall, HeadLine3 } from "../../typography/styled";
import IconSuccess from './icon.svg'
import InvoiceIcon from './ic-invoice.svg'     
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:() => void;
  alertSubTitle?:any;
}  

const PaymentSuccess:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertSubTitle
  } = props
  const [t] = useTranslation()

  return(
    <Container>
      <HeadWrapper>
        <div/>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <img src={IconSuccess}/>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px', width:'250px'}}>
          {t("COMPLETE_PAYMENT_TITLE")}
        </HeadLine3>
        <BodyTextGrey>
          {t("COMPLETE_PAYMENT_BODY")}
        </BodyTextGrey>
        <InvoiceIdWrapper>
          <img src={InvoiceIcon}/>
          <BodySmall>{alertSubTitle}</BodySmall>
        </InvoiceIdWrapper>
        <div style={{display:"flex", gap:'6px', marginTop:'30px', width:'100%'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ flex:'1 auto', justifyContent:'center' }}
            onClick={closeModal}
            title={t("CONTINUE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default PaymentSuccess