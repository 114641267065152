import React, { FC, useEffect, useRef, useState } from "react";
import {
  MetricSettingsTitle,
  RadioWrapper,
  HeadWrapper,
  LeftSideData,
  MetricData,
  ChartWrapper,
  AreaContainer,
  BottomData,
  Area,
  ChartDot,
  AreaTop,
  GraphWrapper,
  LegendItem,
  TraficText,
  TraficDot,
  TraficName,
  LegendWrapper,
  LabelMetric,
  Area2,
  Overlay,
  Spinner,
} from './styles'
import { InfoContainer, InfoTitle } from "../info-block/styles";
import { useTranslation } from "react-i18next";
import RadioButtonGroup from "../radiobuttons";
import Select from "../select";
import { useTheme } from "styled-components";
import { convertUTCtoLocalDate, timeConverterMMDDYYHHMM } from "../../utils/dates";
import Typography from "../typography";
import LoaderSpinner from "../spinner";
import moment from 'moment'
import DateView from "./dateView";
import { BodyDefault } from "../typography/styled";

type MetricProps = {
  title:string,
  data:any[],
  dataSecond?:any[],
  customStyles?:any,
  isCpu?:boolean,
  updateData:(hours:string, type?:any) => void,
  scales?:any[],
  loading:boolean,
  scalesCpu?:any[],
  wihoutDates?:boolean,
  hideSelect?:boolean,
  customTabs?:any[],
  customDataSelect?:any[],
  isBareMetal?:boolean,
  affiliate?:boolean,
}

const MetricsChart:FC<MetricProps> = (props) => {
  const {
    title,
    data,
    customStyles,
    isCpu,
    dataSecond=[],
    updateData,
    scales,
    loading,
    scalesCpu,
    wihoutDates,
    hideSelect,
    customTabs,
    customDataSelect,
    isBareMetal,
    affiliate
  } = props;
  const [t] = useTranslation()
  const [period, setPeriod] = useState<string>(affiliate ? '11111' : customTabs ? customTabs?.[0]?.value : '1')
  const [selectData, _] = useState<any[]>(customDataSelect ? customDataSelect : [{label:t('PACKETS'), value:'1'}, {label:t('BYTES'), value:'2'}])
  const [networkType, setNetworkType] = useState<string>('1')


  const [checked, setChecked] = useState<any[]>([])

  const editChecked = (met:any) => {
    if(met === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item === met)
    if(founded) {
      setChecked((prev:any) => {
        return prev.filter((item:any) => item !== met)
      })
    } else {
      setChecked((prev) => [...prev, met])
    }
  } 
  const tabs = [
    {
      title:t("LAST_HOUR"),
      value:'1',
      id:'1'
    },
    {
      title:t("24_HOURS"),
      value:'24',
      id:'2'
    },
    {
      title:t("7_DAYS"),
      value:'168',
      id:'3'
    },
    {
      title:t("30_DAYS"),
      value:'720',
      id:'4'
    }
  ]
  useEffect(() => {
    if(networkType === '1') {
      updateData(period)
    } else {
      updateData(period, '2')
    }
    // setChecked([])
  },[period, networkType])
  // if(isCpu && data?.length === 0) return(
  //   <BodyDefault>
  //     {t("HAS_NO_METRICS_YET")}
  //   </BodyDefault>
  // )
  // if(!isCpu && data?.length === 0 && dataSecond?.length === 0) return(
  //   <BodyDefault>
  //     {t("HAS_NO_METRICS_YET")}
  //   </BodyDefault>
  // )
  return(
    <InfoContainer
      customStyles={{
        display:'flex',
          ...customStyles
        }}
      >
      <HeadWrapper>
        <InfoTitle>
          {title}
        </InfoTitle>
        {(!isCpu && !hideSelect && !isBareMetal) &&
          <Select
            data={selectData}
            title=''
            toolTip={false}
            isSmall
            onChange={setNetworkType}
            selectedValue={networkType}
          />
        }
      </HeadWrapper>
      {wihoutDates ? null :
        <>
          <MetricSettingsTitle>
            {t("PERIOD")}
          </MetricSettingsTitle>
          <RadioWrapper>
            
          <Overlay active={loading}/>
            <RadioButtonGroup
              buttons={tabs}
              title=''
              isSmall
              toolTip={false}
              selectedButton={period}
              onChange={setPeriod}
              // disabled={data?.length === 0}
            />
          </RadioWrapper>
        </>
      }
      {(data?.length === 0 && (isCpu || (!isCpu && dataSecond?.length === 0))) ? (
        <>
          {loading ?
            <Spinner/>
          :
            <BodyDefault style={{marginTop:'20px'}}>
              {t("HAS_NO_METRICS_YET")}
            </BodyDefault>
          }
        </>
        ) 
      :
        <ChartWrapper>
          <Overlay active={loading}>
            <Spinner/>
          </Overlay>
          <AreaContainer>
            <GraphWrapper>
              <div style={period === '1' ? {display:'flex',gap:'10px', marginRight:'55px'} : {display:'flex',gap:'10px', marginRight:'50px'}}>
                <LeftSideData>
                  {isCpu ? 
                    scalesCpu?.map((item:any) => (
                      <MetricData>
                        {item}
                      </MetricData>
                    ))
                  :
                    networkType === '1' ?
                      scales?.[0]?.map((item:any) => (
                        <MetricData>
                          {item}
                        </MetricData>
                      ))
                    :
                      scales?.[1]?.map((item:any) => (
                        <MetricData>
                          {item}
                        </MetricData>
                      ))
                  }
                </LeftSideData>
                <AreaTop>
                  <div>
                    {!checked.find((item) => item === (networkType === '1' ? `Packets in` : `Bytes in`)) &&
                      data?.length > 1 &&
                      <Area data={data.map((item:any) => `${item[0]}% ${100 - item[1]}%` )} />
                    }
                    {/* <Area2 data={data.map((item:any) => `${item[0]}% ${100 - item[1]}%` )} /> */}
                    {!checked.find((item) => item === (networkType === '1' ? `Packets in` : `Bytes in`)) &&
                      data.map((item:any, index:any) => (
                      <LineChart
                        label={affiliate ? t("CONVERSIONS_IN") : isBareMetal ? `In` : isCpu ? 'CPU utilization' : networkType === '1' ? 'Packets in' : 'Bytes in'}
                        isCpu={isCpu}
                        item={item}
                        index={index}
                        labelOrientation={data?.length / 2 > index}
                      />
                    ))}
                    {!checked.find((item) => item === (networkType === '1' ? `Packets out` : `Bytes out`)) &&
                      dataSecond?.length > 0 && dataSecond?.map((item:any, index:any) => (
                        <LineChart
                          label={affiliate ? t("COMMISSIONS_IN") : isBareMetal ? 'Out' : networkType === '1' ? 'Packets out' : 'Bytes out'}
                          isCpu={isCpu}
                          second
                          item={item}
                          index={index}
                          labelOrientation={dataSecond?.length / 2 > index}
                        />
                    ))}
                  </div>
                </AreaTop>
              </div>
              <BottomData>
                {!loading &&
                  <DateView hours={period} data={data}/>
                }
              </BottomData>
            </GraphWrapper>
              {isCpu ?
                <LegendWrapper>
                  <LegendItem>
                    <TraficDot/>
                    <TraficName>
                      CPU
                    </TraficName>
                  </LegendItem>
                </LegendWrapper>
              :
                <LegendWrapper>
                  <LegendItem onClick={() => editChecked(networkType === '1' ? `Packets in` : `Bytes in`)}>
                    <TraficDot/>
                    <TraficName inactive={checked.find((item) => item === (networkType === '1' ? `Packets in` : `Bytes in`))}>
                      {affiliate ? t("CONVERSIONS_IN") : networkType === '1' ? isBareMetal ? `In` : `Packets in` : `Bytes in`}
                    </TraficName>
                  </LegendItem>
                  <LegendItem onClick={() => editChecked(networkType === '1' ? `Packets out` : `Bytes out`)}>
                    <TraficDot second/>
                    <TraficName inactive={checked.find((item) => item === (networkType === '1' ? `Packets out` : `Bytes out`))}>
                      {affiliate ? t("COMMISSIONS_IN") : networkType === '1' ? isBareMetal ? `Out` : `Packets out` : `Bytes out`}
                    </TraficName>
                  </LegendItem>
                </LegendWrapper>
              }
          </AreaContainer>
        </ChartWrapper>
      }
    </InfoContainer>
  )
}

export default MetricsChart


const LineChart:FC<any> = (props) => {
  const [hover, setHover] = useState<boolean>(false)
  return (
    <>
      <ChartDot
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
        id={`${props?.isCpu ? props.second ? 'areaCpu1-' : 'areaCpu2-' : props.second ? 'area1-' : 'area2-'}${props.index}`}
        second={props.second}
        style={{bottom:`${props.item[1]}%`, left:`${props.item[0]}%`}}
      >
        <div>
          
          <LineBetweenDots
            isCpu={props?.isCpu}
            second={props.second}
            item1={props.index}
            item2={props.index + 1}
          />
        </div>
      </ChartDot>
      {hover &&
        <LabelMetric style={props?.labelOrientation ? {bottom:`calc(${props.item[1]}% + 6px)`, left:`${props.item[0]}%`} : {bottom:`calc(${props.item[1]}% + 6px)`, left:`calc(${props.item[0]}% - 146px)`}} orientation={props?.labelOrientation}>
          <Typography variant="small-medium">{timeConverterMMDDYYHHMM(props?.item[2])}</Typography>
            
          <div style={{display:'flex', alignItems:'center', gap:'6px'}}>
            <TraficDot second={props.second}/>
            <Typography variant="small">{`${props?.item?.[3]} ${props?.label}`}</Typography>
          </div>
        </LabelMetric>
      }
    </>
  )
}

const getElement = (id:string) => {
  const element = document.getElementById(id)
  return element
}
function elemPosition(elem:any) {
  let pos = {
    x: window.pageXOffset + elem?.getBoundingClientRect().right,
    y: window.pageYOffset + elem?.getBoundingClientRect().bottom
  };
  return pos;
}

function centerElem(elem:any) {

  let centerX = 2 + elemPosition(elem).x;
  let centerY = 2 + elemPosition(elem).y;
  let pos = {
    y: centerY,
    x: centerX

  };
  return pos;
}

const LineBetweenDots:FC<any> = (props) => {
  const theme:any = useTheme()
  const [lineHeight, setLineHeight] = useState<any>()
  const [tang, setTang] = useState<any>()
  const [arcTg, setArcTg] = useState<any>()
  const [angle, setAngle] = useState<any>()
  const [bottom1, setBottom1] = useState<any>()
  const [start, setStart] = useState<any>()
  const [final, setFinal] = useState<any>()
  const [resized, setResized] = useState<boolean>(true)
  const resize = () => {
    setFinal({
      x: centerElem(getElement(`${props?.isCpu ? props.second ? 'areaCpu1-' : 'areaCpu2-' : props.second ? 'area1-' : 'area2-'}${props.item2}`)).x,
      y: centerElem(getElement(`${props?.isCpu ? props.second ? 'areaCpu1-' : 'areaCpu2-' : props.second ? 'area1-' : 'area2-'}${props.item2}`)).y
    })
    setStart({
      x: centerElem(getElement(`${props?.isCpu ? props.second ? 'areaCpu1-' : 'areaCpu2-' : props.second ? 'area1-' : 'area2-'}${props.item1}`)).x,
      y: centerElem(getElement(`${props?.isCpu ? props.second ? 'areaCpu1-' : 'areaCpu2-' : props.second ? 'area1-' : 'area2-'}${props.item1}`)).y
    })
    setTimeout(() => {
      setBottom1(start?.y > final?.y ? start?.y - final?.y : final?.y - start?.y)
      setLineHeight(Math.sqrt(Math.pow((final?.x - start?.x), 2) + Math.pow((final?.y - start?.y), 2)))
      setTang((start?.y - final?.y) / (start?.x - final?.x))
      setArcTg(Math.atan(tang)); 
      setAngle((arcTg * 180 / Math.PI) + 90)
    })
    setResized(false)
  }
  useEffect(() => {
    resize()
		window.addEventListener('resize', resize);
		return () => {
      window.removeEventListener('resize', resize);
		};
  });
  
  if(angle && !resized ) return (
    <div
      style={{
        pointerEvents: 'none',
        position:'absolute',
        // zIndex:-2,
        width:`2px`,
        backgroundColor:props.second ? theme.isDark ? theme.base.black : '#081D42' :'#4671F6',
        transformOrigin: `left bottom`,
        bottom:bottom1 + '%',
        height:`${lineHeight}px`,
        transform:`translate(2px,2px) rotate(${angle -0.1}deg)`,
      }}
    />
  )
  return(
    <>
      {/* {? 
        
      : null} */}
    </>
  )
}