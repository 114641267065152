import React, { FC, useEffect, useState } from "react";
import {
  Container,
  NavWrapper
} from './styles'
import { Button, Typography, TopProgress, Tabs } from "../../components";
import { useTranslation } from "react-i18next";
import { affiliateService } from "../../api";
import { useUi } from "../../context/ui.context";
import {
  calculateScales,
  createPercentageArrays,
} from './metricsFuncs'
import { formatCurrency } from "../../utils/display-price";
import { useAuth } from "../../context/user.context";
import { filterByToday, timeConverterMMDDYYHHMM } from "../../utils/dates";
import { Route, Navigate, Routes, useLocation } from "react-router-dom";
import {Overview, Conversions, Customers, NoAffiliate, InfoAffiliate} from './components'
import {
  StateWrapper,
  GreenDot,
  Greentext
} from "../Instances/styles";
import { handleStatusName } from "../../utils/handle-status-name";


const Affiliate:FC<any> = () => {
  const [t] = useTranslation()
  const {user} = useAuth()
  const [programData, setProgramData] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  
  const [networkMetrics, setNetworkMetrics] = useState<any[]>([])
  const [networkMetricsOut, setNetworkMetricsOut] = useState<any[]>([])
  const [loadNetwork, setLoadNetwork] = useState<boolean>(false)
  const [scales, setScales] = useState<any[]>([])
  const [countries, setCountries] = useState<any[]>([])
  const {viewAlert} = useUi()
  const [activeTab, setActiveTab] = useState<string>()
  const location = useLocation()
  const getRefData = async () => {
    try{
      const refRes = await affiliateService.getRef()
      const comissionRes = await affiliateService.getComissions()
      const conversionRes = await affiliateService.getConversions()
      const customersRes = await affiliateService.getCustomers()
      const balanceRes = await affiliateService.getBalance()
      setProgramData({
        info:refRes?.data,
        balance:formatCurrency(balanceRes.data?.balances[user.user.clients?.[0]?.currency || 'EUR'], user.user.clients?.[0]?.currency || 'UAH'),
        comission:comissionRes?.data,
        // comission:comissionRes?.data?.map((conversion:any) => {
        //   return{
        //     ...conversion,
        //     customer_id:conversion.customer?.customer_id,
        //     created:timeConverterMMDDYYHHMM(conversion.created_at)
        //   }
        // }),
        customers:customersRes?.data?.customers?.map((customer:any) => {
          return {
            ...customer,
            created:timeConverterMMDDYYHHMM(customer.created_at),
            status:(<StateWrapper>
              <GreenDot active={customer.status}/>
              <Greentext active={customer.status}>
                {handleStatusName(customer.status, t)}
              </Greentext>
            </StateWrapper>)
          }
        }),
        conversion:conversionRes?.data?.map((conversion:any) => {
          return{
            ...conversion,
            customer_id:conversion.customer?.customer_id,
            created:timeConverterMMDDYYHHMM(conversion.created_at)
          }
        }),
        comissionAmountDaily:formatCurrency(filterByToday(comissionRes?.data, 'created_at').reduce((total:any, item:any) => total + item.amount, 0)?.toFixed(2), user.user.clients?.[0]?.currency || 'UAH'),
        comissionAmount:formatCurrency(comissionRes?.data.reduce((total:any, item:any) => total + item.amount, 0)?.toFixed(2), user.user.clients?.[0]?.currency || 'UAH')
      })
      setScales(calculateScales(comissionRes?.data))
      setCountries(conversionRes.data.map((conv:any) => {
        return {
          country: conv?.country?.toLowerCase() || 'ua',
          value: 1
        }
      }))
      setNetworkMetrics(createPercentageArrays(comissionRes?.data, 'amount'))
      setNetworkMetricsOut(createPercentageArrays(comissionRes?.data, 'conversion_sub_amount'))
    } catch (e:any) {
      console.error('error:',e);
      // viewAlert({
      //   severity: 'error',
      //   message: e?.response?.data?.detail || t("ERROR_OCCURRED")
      // })
    } finally {
      setPageLoading(false)
    }

  }

  useEffect(() => {
    getRefData()
  }, [])

  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },
    {
      title:t("CONVERSIONS"),
      slug:'conversions',
      id:'2'
    },
    {
      title:t("CUSTOMERS"),
      slug:'customers',
      id:'3'
    },
    {
      title:t("INFO"),
      slug:'info',
      id:"4"
    }
  ])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  // if(true) return (<NoAffiliate setPageLoading={setPageLoading} getRefData={getRefData}/>)
  if(!programData) return (<NoAffiliate setPageLoading={setPageLoading} getRefData={getRefData}/>)
  return(
    <Container>
      <Typography variant="h1">
        {t("AFFILIATE")}
      </Typography>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview 
          programData={programData}
          scales={scales}
          networkMetricsOut={networkMetricsOut}
          networkMetrics={networkMetrics}
          loadNetwork={loadNetwork}
          countries={countries}
          />}/>
        <Route path='/conversions' element={<Conversions conversions={programData.conversion}/>}/>
        <Route path='/customers' element={<Customers customers={programData.customers}/>}/>
        <Route path='/customers' element={<Customers customers={programData.customers}/>}/>
        <Route path='/info' element={<InfoAffiliate />}/>
      </Routes>
    </Container>
  )
}


export default Affiliate