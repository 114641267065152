import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  BackArrow,
  Input,
  Select,
  Button,
  TopProgress,
  LoaderSpinner
} from '../../../components'
import {
  Container,
  TitleBig,
  CreateContainer,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { floatingIpService } from '../../../api'
import { useUi } from '../../../context/ui.context'
import { useTheme } from 'styled-components'
import {formatCurrency, formatCurrencyString } from '../../../utils/display-price'
import { useAuth } from '../../../context/user.context'


const CreateFloatingIp:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const {user} = useAuth()
  const theme:any = useTheme()
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()
  const [initialValues, setInitialValues ] = useState<any>({
    description:'',
  })
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [networks, setNetworks] = useState<any[]>([])
  const [regions, setRegions] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const onSubmit = (values:any) => {
    setLoading(true)
    floatingIpService.createFloatingIP(values)
    .then((res:any) => {
      setLoadingComplete(true)
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
        navigate(-1)
      },1000)
    })
    .catch((err) => {
      if(err.response?.data?.detail){
        viewAlert({
          severity:'error',
          message: err.response?.data?.detail
        })
      } else if(err?.response?.data) {
        setErrors(err.response.data)
      }
      setLoading(false)
    })
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
      region: yup.string().required(),
      floating_network: yup.string().required(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  useEffect(() => {
    floatingIpService.getCreateOptions()
    .then(res => {
      setNetworks(res.data.networks.map((net:any) => {
        return { value: net.id, label: net.name }
      }))
      handleChange('floating_network')(res.data.networks[0]?.id)
      setRegions(res.data.regions.map((reg:any) => {
        return { value: reg.id, label: reg.id }
      }))
      handleChange('region')(res.data.regions[0]?.id)
      setPageLoading(false)
    })
    .catch(err => {});
  },[])

  const getCurrentPrice = () => {
    let queries:any = {
      region: values['region'],

    }

    if(values['region']){
      setPriceLoading(true)
      floatingIpService.getEstimate(queries)
      .then((res) => {
        setPrice(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
        setPriceMonth(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
        setPriceLoading(false)
      })
    }
  }
  
  useEffect(() => {
    getCurrentPrice()
  },[values['region']])

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <CreateContainer>
      <Container>
        <BackArrow
          onClick={() => navigate('/floating-ips')}
          title={t("BACK_TO_FLOATING_IPS")}
        />
        <TitleBig>{t("CREATE_A_NEW_FLOATING_IP")}</TitleBig>
          <div style={{marginTop:'20px', marginBottom:'20px'}}>
            <Select
              toolTip={false}
              required
              data={networks}
              selectedValue={values['floating_network']}
              onChange={(e) => handleChange('floating_network')(e)}
              title={t("NETWORK")}
              customStyles={{marginBottom:'20px', width:'360px'}}
            />
            {/* <Select
              toolTip={false}
              data={regions}
              selectedValue={values['region']}
              onChange={(e) => handleChange('region')(e)}
              title={t("PLACEMENT_REGION")}
              customStyles={{marginBottom:'20px', width:'360px'}}
            /> */}
            <Input
              isTextArea
              fieldName='description'
              title={t("DESCRIPTION")}
              customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
              placeholder=''
              {...formikProps}
            />
          </div>
        <div style={{display:'flex', marginTop:'0px'}}>
          <Button
            variant="primary"
            size="display"
            customStyles={{marginRight:"10px"}}
            onClick={handleSubmit}
            title={t("CREATE")}
            loading={loading}
            loadingComplete={loadingComplete}
          />
          <Button
            variant="stroke"
            size="display"
            // customStyles={{width: '81px'}}
            onClick={() => navigate("/floating-ips")}
            title={t("CANCEL")}
          />
        </div>
      </Container>
      <EstimateContainer>
        <EstimateTitle>
          {t("ESTIMATED_COST")}
        </EstimateTitle>
        {priceLoading ? 
          <LoaderSpinner customStyles={{color:theme.base.black}}/>
        :
          price ? 
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              <div style={{display:'flex', alignItems:'flex-end'}}>
                <EstimateSum>
                  {`${formatCurrency(price, user.user.clients?.[0]?.currency || 'UAH')}`}
                </EstimateSum>
                <PerHour>{t("HOURS")}</PerHour>
              </div>
              <div style={{display:'flex', alignItems:'flex-end'}}>
                <EstimateSum>
                  {`${formatCurrency(priceMonth, user.user.clients?.[0]?.currency || 'UAH')}`}
                </EstimateSum>
                <PerHour>{t("MONTH")}</PerHour>
              </div>
            </div>
          :
          <PerHour>
            {t("SELECT_OPTIONS_TO_DISPLAY_PRICE")}
          </PerHour>
        }
      </EstimateContainer>
    </CreateContainer>
  )
}

export default CreateFloatingIp