import React, {FC, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { imageService, instanceService } from '../../../../../api'
import { Button, ModalVariables, Table, TopProgress, Typography } from '../../../../../components'
import { InfoContainer, InfoTitle } from '../../../../../components/info-block/styles'
import { BodyDefault } from '../../../../../components/typography/styled'
import StyledIcon from "../../../../../components/styled-icon";
import { useUi } from '../../../../../context/ui.context'
import { icons } from '../../../../../icons'
import {
  Container,
  RulesWrapper,
  HeadContainer,
  RuleContainer,
  Divider
} from './styles'
import { handleStatusName } from '../../../../../utils/handle-status-name'
import { timeConverterMMDDYYHHMM } from '../../../../../utils/dates'
import { BodyLink, HideWrapper } from '../../../styles'
type SecurityProps = {
  instance:any,
}

const BackupsInstancePage:FC<SecurityProps> = ({instance}) => {
  const [t] = useTranslation()
  const {instanceId} = useParams()
  const navigate = useNavigate()
  const [backups, setBackups] = useState<any[]>([])
  const [schedules, setSchedules] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<string>('')
  const [selectedBackup, setSelectedBackup] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const {viewAlert} = useUi()
  const getBackups = () => {
    if(instanceId){
      instanceService.getInstanceBackups(instanceId)
      .then((res) => {
        setBackups(res.data.backups.map((backup:any) => {
          return {
            ...backup,
            name: backup.name,
            backup_type: t(`${backup.backup_type.toUpperCase()}`),
            navigate:`/images/${backup.id}`,
          }
        }))
        setSchedules(res.data.schedules.map((schedule:any) => {
          return {
            ...schedule,
            name: schedule.name,
            backup_type: t(`${schedule.backup_type.toUpperCase()}`),
            run_at: timeConverterMMDDYYHHMM(schedule.run_at),
          }
        }))
        setPageLoading(false)
      })
      .catch((err) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: err?.response?.data?.detail
          })
        }
      })
    }
  }
  useEffect(() => {
    getBackups()
    const interval = setInterval(() => {
      getBackups()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = async (values:any, handleClose:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'create-instance-backup':
          res = await instanceService.createInstanceBackup(instance.id, values)
          await setLoadingComplete(true)
          await getBackups()
          await setTimeout(() => {
            setLoadingComplete(false)
            setLoading(false)
            setViewModal(false)
            setActiveAnimation(false)
            handleClose()
          }, 1000)
          break;
        case 'create-backup-schedule':
          res = await instanceService.createInstanceBackupSchedule(values)
          await setLoadingComplete(true)
          await getBackups()
          await setTimeout(() => {
            setLoadingComplete(false)
            setLoading(false)
            setViewModal(false)
            setActiveAnimation(false)
            handleClose()
          }, 1000)
          break;
        case 'delete':
          res = await instanceService.deleteBackupSchedule(selectedBackup.id);
          await getBackups()
          setLoadingComplete(true)
          setTimeout(() => {
            setLoadingComplete(false)
            setLoading(false)
            setViewModal(false)
            setActiveAnimation(false)
          }, 1000)
          break; 
      }

    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      setLoading(false);
    }
  }

  const Columns = [
    {
      title:t('NAME'),
      key:'name'
    },
    {
      title:t("TYPE"),
      key:'backup_type'
    },
  ]
  const ColumnsSchedule = [
    {
      title:t('NAME'),
      key:'backup_name'
    },
    {
      title:t("TYPE"),
      key:'backup_type'
    },
    {
      title:t("ROTATION"),
      key:'rotation'
    },
    {
      title:t("NEXT_RUN_UTC"),
      key:'run_at'
    },
    {
      title:'',
      key:'props'
    }
  ]
  const items = [
    {
      title:t("DELETE"),
      id:'4',
      onClick:(image:any) => {
        openModal('delete')
        setAlertTitle(`${t("BACKUP_SCHEDULE")}`)
        setAlertSubTitle([image])
        setSelectedBackup(image)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <Typography variant='h2'>
        {t("BACKUPS")}
      </Typography>
      <div style={{display:'flex', alignItems:'flex-start'}}>
        <Button
          title={t("CREATE_BACKUP")}
          size='display'
          variant='primary'
          onClick={() => {
            setAlertTitle(instance.name)
            openModal('create-instance-backup')
          }}
        />
      </div>
      {backups?.length > 0 ?
        <Table
          // settings={items}
          hideCheckbox
          hideOther
          columns={Columns}
          rows={backups}
        />
      : 
      <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
        <BodyDefault>
          {t("HAS_NO_BACKUPS")}
        </BodyDefault>
        <HideWrapper onClick={() => window.open('')}>
          <StyledIcon src={icons.LinkBlue}/>
          <BodyLink>{t("LEARN_MORE_ABOUT_BACKUPS")}</BodyLink>
        </HideWrapper>
      </div>

      }
      <Divider/>
      <Typography variant='h2'>
        {t("BACKUPS_SCHEDULES")}
      </Typography>
      <div style={{display:'flex', alignItems:'flex-start'}}>
        <Button
          title={t("CREATE_BACKUP_SCHEDULE")}
          size='display'
          variant='primary'
          onClick={() => {
            setAlertTitle(instance.name)
            openModal('create-backup-schedule')
          }}
        />
      </div>
      {schedules?.length > 0 ?
        <Table
          settings={items}
          hideCheckbox
          hideOther
          columns={ColumnsSchedule}
          rows={schedules}
        />
      : 
        <BodyDefault>
          {t("HAS_NO_BACKUP_SCHEDULS")}
        </BodyDefault>
      }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        instanceId={instanceId}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
      />
    </Container>
  )
}

export default BackupsInstancePage