import styled from "styled-components";
import SVG from "react-inlinesvg";
import { BodyDefault } from "../../../../components/typography/styled";

type ActiveProp = {
  active?:boolean
  copy?:boolean,
  dropped?:boolean,
  isLong?:boolean,

}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 20px 10px 0px 0px;
  width: 100%;
  /* align-items: flex-start; */
  gap: 10px;
`
export const TokenBody = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  display: block;
  /* margin-left: 12px; */
  margin-right: 4px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`
export const CopyIcon = styled(SVG)<ActiveProp>`
  padding: 3px;
  border-radius: 4px;
  background: ${({theme}) => theme.grey[300]} ;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  & path {
    stroke:${({theme}) => theme.palette[800]};
  }
  &:hover {
    cursor:pointer;
    opacity: 0.7;
  }
  &:active{
    filter: brightness(77%);
  }
`