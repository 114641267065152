import React, { FC } from "react";
import {Spinner} from './styles'




const LoaderSpinner:FC<any> = ({customStyles}) => {
  return (
    <Spinner customStyles={customStyles}><div></div></Spinner>
  )
}

export default LoaderSpinner;