import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Button, Table, ModalVariables, TopProgress, Label } from "../../../../components";
import {
  Container,
  ButtonsWrapper
} from './styles'
import { icons } from "../../../../icons";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { volumeService } from "../../../../api";
import { useParams } from "react-router-dom";
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";
import { useUi } from "../../../../context/ui.context";
import { compareArrays } from "../../../../utils/findIntersection";


type OverviewProps = {
  customers:any
}


const Customers:FC<OverviewProps> = ({customers}) => {
  const {volumeId} = useParams()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam);
  const [paginationData, setPaginationData] = useState<any>()

  const Columns = [
    {
      title:t("CREATED_AT"),
      key:'created'
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    {
      title:t("CUSTOMER_ID"),
      key:'customer_id'
    },
    {
      title:t("COUNTRY"),
      key:'country'
    },

  ]


  return(
    <Container>
      {
        customers.length === 0 ? 
          <>
            <Typography variant="body">{t("THERE_ARE_NO_CUSTOMERS_YET")}</Typography>
          </>
        :
          <>
            <Table
              columns={Columns}
              rows={customers}
              paginationData={paginationData}
              hideCheckbox
              hideOther
            />
          </>
      }

    </Container>
  )
}

export default Customers
