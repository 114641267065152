import styled from "styled-components";
import { HeadLine1, BodyDefault, BodyMedium } from "../../components/typography/styled";

type ItemProp = {
  withIcon?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 10px 30px 50px 20px;
  position: relative;
  /* align-items: flex-start; */
`
export const HeaderRow = styled.div`
  display:flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  /* margin-top: 20px; */
  margin-bottom: 20px;
`
export const BodyText = styled(BodyDefault)` 
  margin-bottom: 20px;
  color:${({theme}) => theme.palette[850]};
`
export const BodyContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap:wrap;
  /* margin-right:20px; */
  gap:20px;
  flex-basis:0;
  flex-grow: 1;
  margin-top:30px;
`
export const BodyContentRight = styled.div`
  display: flex;
  flex-grow: 0;
  /* margin-left:20px; */
  width: 100%;
  /* flex: none; */
  flex-basis:0;
  flex-grow: 1;
  flex-direction: column;
  gap:20px;
`
export const TutorItem = styled.div<ItemProp>`
  box-sizing: border-box;
  min-height: 92px;
  background:${({theme}) => theme.palette[100]};
  border-radius: 8px;
  /* flex: none; */
  overflow: hidden;
  /* width: 100%; */
  padding:${({withIcon}) => withIcon ? '14px 20px 0px 20px' : "14px 20px 14px 20px"};
  /* order: 0;
  position: relative;
  align-self: stretch;
  
  flex-grow: 0; */
  display: flex;
  flex:1 1;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-origin: content-box;
  /* background-size:70%; */

  @media screen and (max-width: 1000px) {
    background-image: none !important;
    background-size:0%;
  }

  &:hover {
    cursor: pointer;
    /* background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[200]}; */
  }
  &:active{
    background-color: ${({theme}) => theme.isDark ? theme.palette[400] : theme.grey[300]};
    transition: 0.1s background-color ease-in-out;
  }
`
export const TutorTitle = styled(BodyMedium)`
  color:${({theme}) => theme.primary[600]};
  margin-bottom:4px;
`

export const TutorTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width:70%;
`

export const TutorContent = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
`
export const TutorIcon = styled.img`
  /* position: absolute; */
  left:25px;
  /* width: 88px;
  height: 88px; */
  /* height: 191px; */
  /* right:34px; */
  bottom:0px; 
`

export const FilterContainer = styled.div`
  display:flex;
  width:100%;
  border-radius: 8px;
  padding:20px;
  box-sizing: border-box;
  flex-direction: column;
  box-shadow: ${({theme}) => theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`};
  margin-top:10px;
`
export const InputWrapperRow = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`
export const InputWrapper = styled.div`
  display: flex;
  width:100%;
  position: relative;
  /* flex-direction: column; */
  flex-grow:1;
  /* order:0; */
  /* flex:1 auto; */
`
export const CloseIconContainer = styled.div`
  border-left:1px solid ${({theme}) => theme.grey[350]};
  display: flex;
  position:absolute;
  right:0px;
  bottom:6px;
  width:36px;
  height:40px;
  justify-content: center;
  align-items: center;
  &:hover{
    cursor:pointer;
  }
`
export const CloseIcon = styled.img`
  width:16px;
  height:16px;
`