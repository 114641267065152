import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap:20px;
  align-items: flex-start;
`


export const PropsWrapper = styled.div`
  display: flex;
  width:100%;
  flex-direction:column;
  gap:10px;

`
export const DarkForm = styled.div`
  display: flex;
  background-color: ${({theme}) => theme.palette[200]};
  border-radius: 8px;
  align-content:stretch;
  flex-wrap:nowrap;
  /* flex: 1; */
  /* width:100%; */
  padding: 14px;
  gap:10px;
`
