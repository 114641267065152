import styled from "styled-components";
import { BodySmall, BodySmallMedium, VerySmall } from "../../../../../components/typography/styled";


type ActiveProp = {
  active?:boolean;
}

export const Container = styled.div<ActiveProp>`
  display: flex;
  width:100%;
  padding:20px;
  box-sizing: border-box;
  flex-direction: column;
  gap:10px;
  border-radius:8px;
  border: ${({theme, active}) => active ? '': `1px solid ${theme.palette[20]}`};
  background: ${({theme, active}) => active ? theme.isDark ? theme.palette[200] : theme.primary[40] : theme.palette[10]};
  /* box-shadow:${({theme, active}) => active ?  theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF` : ''}; */
`

export const TitleWrapper = styled.div`
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HeadCardContainer = styled.div`
  display:flex;
  flex-direction: row;
  align-items: center;
  gap:14px;
  width: 100%;
`

export const Datetext = styled(BodySmall)`
  color:${({theme}) => theme.palette[600]};
`
export const Attachments = styled.div`
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: -20px;
  border-top:${({theme}) => `1px solid ${theme.palette[20]}`};
  padding:20px;
  gap:10px;
`

export const AttachmentsWrapper = styled.div`
  display:flex;
  flex-direction: row;
  gap:10px;
  flex-wrap: wrap;
`

export const AttachmentContainer = styled.div`
  width: 363px;
  /* height: 129px; */
  /* overflow: hidden; */
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding:10px;
  gap:10px;
  justify-content: space-between;
  border:${({theme}) => `1px solid ${theme.palette[20]}`};
  border-radius:4px;
  background-color: ${({theme}) => theme.palette[10]};
  &:hover{
    cursor: pointer;
    background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[100]};

  }
`

export const AttachmentImg = styled.img`
  object-fit: cover;
  width:28px;
  margin-right:8px;
  border-radius: 4px;
  height:28px;
  border:${({theme}) => `1px solid ${theme.palette[20]}`};
`

export const AttachmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding:10px; */
`

export const AttachmentTitleRow = styled.div`
  flex-direction: column;
  max-width:260px;
  display: flex;
  /* align-items: center; */
  /* gap:8px; */
`

export const AttachmentName = styled(BodySmallMedium)`
  color:${({theme}) => theme.primary[600]};
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`

export const AttachmentSize = styled(VerySmall)`
  color:${({theme}) => theme.palette[600]};
`

export const DivMessage = styled.div`
  color:${({theme}) => theme.base.black};
  &>p{
    word-break: break-all;
  }

`