import styled from "styled-components";
import { BodyDefault, VerySmall } from "../../../../components/typography/styled";



export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
`

export const OptionsWrapper = styled.div`
  display: flex;
  gap:10px;
  margin-top:20px;
  align-items: center;
  margin-bottom:10px;
`

export const PeriodWapper = styled.div`
  display: flex;
  gap:10px;
  align-items: center;
  margin-bottom:8px;
`
export const PeriodContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

`
export const DateRangeCOntainer = styled.div`
  display: flex;
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette[200]};
  border-radius: 4px;
  gap:6px;
`

export const ArrowImage = styled.img`

`

export const ResourceNameContainer = styled.div`
  display: flex;
  gap:4px;
  background-color: ${({theme}) => theme.palette[200]};
  border-radius: 4px;
  padding: 4px 6px;
  align-items: center;
`

export const ResourceName = styled(VerySmall)`
  color:${({theme}) => theme.palette[600]};
`

export const TotalPriceWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette[200]};
  align-items:flex-end;
  display: flex;
  /* justify-content: flex-end; */
  width:100%;
`

export const TotalPriceContainer = styled.div`
  display: flex;
  padding:10px 0px 10px 0px;
  flex-direction: column;
  flex:1 auto;
  text-align: center;
  justify-content: center;
`

export const CustomBodyTable = styled.div`
  display: flex;
  flex-grow:0;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  /* border-top: ${({theme}) => `1px solid ${theme.grey[300]}`}; */
  gap:4px;
  padding: 11px 0px 11px 32px;
`

export const CustomBodyTitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[600]};
`

export const Divider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({theme}) => theme.palette[400]};
  /* margin: 0 10px; */
`;
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  /* margin-top: 10px; */
  gap:10px;
`