import styled from "styled-components";
import { BodyDefault, BodySmall, HeadLine1, VerySmall } from "../../../../../components/typography/styled";


export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
export const BodyForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
  /* height: 852px; */
`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  margin-bottom: 20px;
  margin-top: 20px;
`

export const InputsWrapper = styled.div`
  display:flex;
  flex-direction: column;
  width: 390px;
  justify-content: space-between;
  margin-top: 20px;
  gap:20px;
  margin-bottom:20px;
`


export const PropContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 3px 4px;
  height:21px;
  background-color: ${({theme}) => theme.palette[300]};
  border-radius: 4px;
  align-items: center;
`
export const Prop = styled(VerySmall)`
  color:${({theme}) => theme.palette[850]};
`
export const PasswordWrapper = styled.div`
  position: relative;
`

export const WindowsAlertContainer = styled.div`
  background-color: ${({theme}) => theme.palette[200]};
  display: flex;
  flex-direction: column;
  padding:15px;
  gap:10px;
  border-radius:8px;
`

export const DocLinkContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  /* width: 330px;
  height: 32px; */
  background: ${({theme}) => theme.base.white};
  border:  1px solid ${({theme}) => theme.palette[350]};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  &:hover{
    cursor: pointer;
  }
`
export const LinkStyled = styled(BodySmall)`
  color:${({theme}) => theme.primary[600]};
`
export const ImgDoc = styled.img`
  width:24px;
  height:24px;

`

type ErrorProp = {
  isError?:boolean
}
export const ErrorContainer = styled.div<ErrorProp>`
  display: ${({isError}) => isError ? 'flex' : 'none'};
  width: 100%;
  /* height: 30px; */
  background: #FFE6E6;
  border-radius: 4px;
  flex-direction: row;
  box-sizing: border-box;
  /* justify-content: space-between; */
  align-items:flex-start;
  padding: 8px 10px;
  margin-top:18px;
`

export const ErrorText = styled(BodySmall)`
  color: #FF3D3D;
  margin-left:6px;
`

export const WarningBlock = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  padding:6px 8px;
  gap:6px;
  background-color: #FFF5D2;
  /* height: 72px; */
  width: 100%;
  border-radius: 4px;
  /* margin-bottom:10px; */
`

export const WarningText = styled(BodyDefault)`
  color:${({theme}) => theme.isDark ? theme.base.white : theme.base.black};
`