import styled from 'styled-components'
import SVG from "react-inlinesvg";

type SvgProps = {
  disabled:boolean
}

const StyledIcon = styled(SVG)<SvgProps>`
  &:hover{
    cursor:pointer;
    color:${({theme}) => theme.grey[700]};
    & path {
      stroke: ${({theme}) => theme.grey[700]};
    };
  } 
`

export default StyledIcon