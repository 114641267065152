import styled from "styled-components";
import { BodyDefault, BodyMedium, HeadLine3 } from "../../../typography/styled";
type ActiveProp = {
  active?:boolean;
  unread?:boolean;
}


export const TopComponent = styled.div`
  display: flex;
  justify-content: space-between;
  width:100%;
  margin-bottom: 10px;
`

export const HeadLine = styled(HeadLine3)`
  color:${({theme}) => theme.palette[900]};
`
export const MarkTextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  &:hover{
    cursor: pointer;
  }
`
export const RightHeadText = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
  margin-left: 8px;
  &:hover{
    cursor: pointer;
  }
`

export const TitleMedium = styled(BodyMedium)<ActiveProp>`
  color:${({theme, active}) => {
    return active ? theme.primary[600] : theme.palette[850]
  }};
  transition: 0.1s color ease-in-out;
`
export const Title = styled(BodyDefault)<ActiveProp>`
  color:${({theme, active}) => {
    return active ? theme.primary[600] : theme.palette[850]
  }};
  transition: 0.1s color ease-in-out;
`
export const SubTitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[600]};
`

export const NotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  max-height: 340px;
  padding:10px 0px 10px 0px;
  box-sizing: border-box;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 2px 2px ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[400]};
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  ::-webkit-scrollbar-button{
    display: none;
  }
  ::after {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: none;
    bottom: 38px;
    z-index:20;
    background:${({theme}) => theme.isDark ? 'linear-gradient(to top, rgba(40, 40, 40, 1), rgba(40, 40, 40, 0.1))' : 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1))'};
    
  }
  ::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: none;
    top: 50px;
    z-index:20;
    background:${({theme}) => theme.isDark ? 'linear-gradient(to bottom, rgba(40, 40, 40, 1), rgba(40, 40, 40, 0.1))' : 'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1))'};
    
  }
`

export const NotificationTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`
export const NotificationRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
`
export const NotificationCard = styled.div<ActiveProp>`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  z-index: 6;
  border-radius: 10px;
  margin-top: 10px;
  padding: 7px;
  background-color: ${({theme, active}) => active ? theme.palette[200] : ''};
  transition: 0.1s background-color ease-in-out;
  &:hover {
    cursor: pointer;
  }
`

export const NotificationIconContainer = styled.img`
  width: 70px;
  height: 70px;
  padding: 10px;
  box-sizing: border-box;
  background:${({theme}) => theme.palette[100]};
`

export const NoNotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
  /* justify-content: center; */
  min-height:180px;
  align-items: center;
  text-align: center; 
`