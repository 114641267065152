import React, {FC, useState} from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route } from 'react-router-dom';
import SelectDepartmentPage from './select-department'
import SubmitTicket from './submit-ticket'
const CreateTicketPage:FC = () => {
  const [department, setDepartment] = useState<string>('')
  const [t] = useTranslation()
  const departments = [
    {
      title:t("SUPPORT_ONE_VOIP"),
      slug:'one-voip',
      description:null
    },
    {
      title:t("SUPPORT_ONE_CLOUD"),
      slug:'one-cloud',
      description:t("SUPPORT_ONE_CLOUD_DESCRIPTION")
    },
    {
      title:t("NETWORKING_INSTANCES"),
      slug:'networking',
      description:t("CLOUD_COMPUTE")
    },
  ]
  return(
    <Routes>
      <Route path="/" element={<SelectDepartmentPage  department={department} setDepartment={setDepartment} />} />
      <Route path="/submit" element={<SubmitTicket department={department} />} />
    </Routes>
  )
}


export default CreateTicketPage