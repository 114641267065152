import React, { FC, useEffect, useState } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { useUi } from "../../../context/ui.context";
import CheckBox from "../../checkbox";
import Input from "../../input";
import { RestoreVolumeBackups } from "../modal-types";
import { volumeService } from "../../../api";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  alertTitle?:string;
  loading?:boolean;
  dataToEdit?:any;
  backupId?:any;
}  


const RestoreVolumeBackup:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    loading,
    backupId
  } = props
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    name:'',
  })
  const [isNewVolume, setIsNewVolume] = useState<boolean>(false)
  const [availableVolumes, setAvailableVolumes] = useState<any[]>([])
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  useEffect(() => {
    if(backupId){
      volumeService.getVolumesAvailableForRestoration(backupId)
      .then((res) => {
        setAvailableVolumes(res.data.objects.map((volume:any) => {
          return {
            ...volume,
            label: `${volume.name} - ${volume.id}`,
            value: volume.id
          }
        }))
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:"error",
            message:e.response?.data?.detail
          })
        }
      })
    }
  },[backupId])
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: isNewVolume ? yup.object().shape({
      name: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }) : 
    yup.object().shape({
      volume_id: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  

  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {t("RESTORE_VOLUME")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <CheckBox
          // customStyles={{marginBottom:"20px"}}
          title={t("RESTORE_TO_A_NEW_VOLUME")}
          value={isNewVolume}
          onChange={() => setIsNewVolume(!isNewVolume)}
          test_id="checkbox_new_volume"
        />
        {isNewVolume ?
          <Input
            required
            title={t('NAME')}
            fieldName='name'
            placeholder={''}
            onBlur={handleBlur}
            customStyles={{width:'360px'}}
            {...formikProps}
          />
        :
          <Select
            required
            toolTip={true}
            data={availableVolumes}
            selectedValue={values['volume_id']}
            test_id={'volume_id-select'}
            onChange={(e) => {
              handleChange('volume_id')(e)}}
            title={t("VOLUME")}
            customStyles={{width:'360px'}}
          />
        }
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("RESTORE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default RestoreVolumeBackup