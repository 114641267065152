import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider,
  IpContainer,
  IpsWrapper,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik, FieldArray } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { bareMetalService, instanceService } from "../../../api";
import TopProgress from "../../TopBarProgress";
import { useUi } from "../../../context/ui.context";
import Input from "../../input";
import { randomNameGenerator } from "../../../utils/create-random-name";
import { ErrorText } from "../../input/styles";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any, setErrorsIps?:any) => void;
  instanceId?:string;
  alertTitle?:string;
  loading?:boolean;
  viewModal?:boolean
  instance?:any
}  


const BareMetalDissociateFloating:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instanceId,
    alertTitle,
    loading,
    viewModal,
    instance
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    ip:'',
  })
  const [ips, setIps] = useState<any[]>([])
  const {
    viewAlert
  } = useUi()
  console.log('kek', instance);
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const _getOptions = () => {
    setIps(instance.floating_ips.map((ip:any) => {
      return {
        value:ip.ip,
        label:ip.ip,
      }
    }))
    setPageLoading(false)
  } 
  useEffect(() => {
    _getOptions()
  },[])
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    setFieldValue,
    handleBlur,
  } = useFormik({
    // validateOnChange:false,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      ip : yup.string().required(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
      <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {`${t("DISSOCIATE_FLOATING_FROM_SERVER")} «${alertTitle}»`}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Select
          required
          toolTip={true}
          data={ips}
          selectedValue={values['ip']}
          onChange={(e) => handleChange('ip')(e)}
          fieldName='ip'
          title={t("FLOATING_IPS")}
          customStyles={{width:'360px'}}
          {...formikProps}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            loading={loading}
            title={t("DISSOCIATE_IP")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default BareMetalDissociateFloating