import styled from "styled-components";
import { BodyDefault, HeadLine1 } from "../../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const CreateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 30px 30px 30px;
`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  margin-top: 20px;
  margin-bottom: 20px;
`
export const FormWrapper = styled.div`
  display:flex;
  flex-direction: column;
  gap:20px;
`