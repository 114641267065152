import styled from "styled-components";
import { BodySmall, HeadLine1 } from '../../../components/typography/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 30px 30px;
  position: relative;
`
export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  margin-top:10px;
  width:100%;
  gap:50px;
  margin-right: 10px;
`

export const RightHeadContainer = styled.div`
  display:flex;
  margin-right: 10px;
  position: relative;
`

export const InstanceName = styled(HeadLine1)`
  color:${({theme}) => theme.palette[800]};
  /*  */
  margin-bottom:10px;
  /* max-width:500px; */
  word-break: break-word;
`
export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  display: flex;
  margin-right:10px;
  margin-top:50px;
  &>div{
    position: absolute;
    bottom:-1px;
    left:0px;
  }
`