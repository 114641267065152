import React, { FC, useState, useEffect, useRef } from 'react'
import {
  Container,
  ToolTipImg,
  StyledInput,
  ErrorText,
  Eye,
  StyledTextArea,
  IconInput,
  PropContainer,
  Prop,
  DeleteTag,
  DropDown,
  SelectInput
} from './styles'
import { BodyMedium } from '../typography/styled'
import { icons } from '../../icons'
import Label from '../label'
import StyledIcon from '../styled-icon'
import { BlueText } from '../../pages/SignIn/styles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask';
type InputProps = {
  values?:any,
  title:string,
  toolTip?:boolean,
  toolTipText?:string,
  placeholder:string,
  handleChange?:any,
  touched?:any,
  errors?:any,
  fieldName?:any,
  customStyles?:any,
  onBlur?:any,
  handleBlur?:any,
  onFocus?:any,
  onFocusOut?:any,
  ref?:any,
  containerCustom?:any,
  readOnly?:boolean,
  defaultValue?:any,
  type?:string,
  value?:any,
  required?:boolean,
  arrayFields?:boolean
  arrayName?:any,
  arrayIndex?:any,
  arrayFieldName?:any,
  manualErr?:any,
  manualTouched?:any,
  icon?:string,
  tags?:any,
  changeTags?:(tag:string) => void;
  isRestrictionIp?:boolean;
  test_id?:any,
}

const MultiInput:FC<InputProps> = (props) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [localError, setLocalError] = useState<any>(false)
  const inputRef:any = useRef()
  const [active, setActive] = useState<boolean>(false)
  const {
    values,
    handleChange,
    touched,
    errors,
    fieldName,
    title,
    toolTip=false,
    test_id,
    placeholder,
    customStyles,
    containerCustom,
    toolTipText,
    required,
    arrayFields=false,
    arrayName,
    arrayIndex,
    arrayFieldName,
    manualErr,
    manualTouched,
    icon,
    tags,
    changeTags,
    isRestrictionIp
  } = props;
  const fieldValue = values?.[fieldName];
  const fieldError = manualErr ? manualErr : arrayFields ? errors?.[arrayName]?.[arrayIndex]?.[arrayFieldName] : errors?.[fieldName];
  const fieldTouched = manualTouched ? manualTouched : arrayFields ? touched?.[arrayName]?.[arrayIndex]?.[arrayFieldName] : touched?.[fieldName];
  const TagRef:any = useRef()
  const ipPattern = /^(?!0)(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])$/;
  const ipPatternMask = /^(?!0)(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\/(32|24|22|16)$/; 

  useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
      document.removeEventListener('click', handleClickOutside, true);
		};
  },[]);

  const handleClickOutside = (event:any) => {
    if (selectRef && !selectRef.current.contains(event.target)){
      setActive(false)
    }
  }
  const handleSpacebar = (event:any) => {
    if (
      event.keyCode === 8 &&
      fieldValue?.length === 0 &&
      changeTags &&
      tags?.length > 0
    ) {
      changeTags(tags?.[tags?.length - 1])
    }
    if ((event.code === 'Comma' && changeTags) || (event.code === 'Space' && changeTags)) {
      if(isRestrictionIp && !ipPattern.test(fieldValue.includes('/') ? 
          fieldValue.slice(0, -3)?.replaceAll(' ', '')?.replaceAll(',', '')
        :
          fieldValue?.replaceAll(' ', '')?.replaceAll(',', ''))){
        setLocalError(`${t("RESTRICTION_IP_VALIDATE")}`)
        return
      }
      if(isRestrictionIp && !ipPatternMask.test(fieldValue?.replaceAll(' ', '')?.replaceAll(',', ''))){
        if(fieldValue.includes('/')){
          setLocalError(`${t("RESTRICTION_IP_VALIDATE")}`)
          return
        } else {
          changeTags(fieldValue?.replaceAll(' ', '')?.replaceAll(',', '')+ '/32')
          setLocalError('')
          inputRef.current.value = ''
          handleChange('')
          return
        }
      }
      if(fieldValue?.length >= 20){
        setLocalError(`${t("STRING_VALIDATE_MAX")} 20 ${t("CHARACTERS")}`)
        return
      } else {
        changeTags(fieldValue?.replaceAll(' ', '')?.replaceAll(',', ''))
        setLocalError('')
        inputRef.current.value = ''
        handleChange('')
      }
    }
  };
  // useEffect(() => {
  //   setLocalError('')
  // },[tags])
  useEffect(() => {
    if(tags){
      window.addEventListener('keydown', handleSpacebar);
      return () => {
        window.removeEventListener('keydown', handleSpacebar);
      };
    }
  });
  const selectRef:any = useRef()
  const handleKeyDown = (event:any) => {
    // if (event.code === 'Comma'|| event.code === 'Space') {
    if (event.code === 'Comma') {
      event.preventDefault(); 
    }
  };
  return(
    <Container ref={selectRef} containerCustom={containerCustom}>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
        <Label required={required} title={title} toolTip={toolTip} tooltipText={toolTipText} />
      </div>
      {icon ? 
        <IconInput>
          <img src={icon}/>
        </IconInput>
      : null}
      <DropDown
        // style={customDropStyles}
        // ref={dropRef}
        active={active}
        // isSelectedValue={selectedValue}
        error={fieldError}
        onPaste={(e:any) => {
          if(e.clipboardData.getData('Text')){
            //@ts-ignore
            e.clipboardData.getData('Text').split(',').map((it:string) => {
              if(isRestrictionIp && !ipPattern.test(it.includes('/') ? it.slice(0, -3) : it)){
                return
              }
              if(isRestrictionIp && !ipPatternMask.test(it)){
                //@ts-ignore
                changeTags(it+ '/32')
                setLocalError('')
                inputRef.current.value = ''
                handleChange('')
                return
              }
              if(fieldValue?.length >= 20){
                setLocalError(`${t("STRING_VALIDATE_MAX")} 20 ${t("CHARACTERS")}`)
                return
              } else {
                //@ts-ignore
                changeTags(it)
                setLocalError('')
                inputRef.current.value = ''
                handleChange('')
              }
            })
          }
        }}
        data-test-id={test_id}
        onClick={() => {
          if(!active){
            inputRef.current.focus()
            setActive(!active)
          }
          if(!active){
            // onChange('')
            setActive(!active)
            // inputRefSearch.current.focus()
          }
          if(!active){
            setActive(!active)
          } else {
            setActive(!active)
          }
        }}
      >
        <div style={{
          display:'flex',
          flexWrap:"wrap",
          alignItems:'center',
          gap:'4px',
          }}>
          {tags?.map((tag:string) => (
            <PropContainer>
              <Prop>
                {tag}
              </Prop>
              {changeTags &&
                <DeleteTag onClick={() => changeTags(tag)} style={{width:'8px', height:'8px'}} src={icons.CloseIcon}/>
              }
              </PropContainer>
          ))}
        </div>
        <SelectInput onPaste={e => e.preventDefault()} onKeyDown={handleKeyDown} name={fieldName} ref={inputRef} onChange={handleChange} value={fieldValue}/>
      </DropDown>
      <ErrorText>
        {(fieldTouched && fieldError) || localError}
      </ErrorText>
    </Container>
  )
}

export default MultiInput