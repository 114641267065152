import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";

const loadBalancerService = {
  getList:(queries:QueriesApi) => ApiClient.get('openstack/lbaas-load-balancers', {
    params:{
      ...queries
    }
  }),
  getPrice:() => ApiClient.get('get_load_balancer_price'),
  getCreateOptions:() => ApiClient.get('openstack/lbaas-load-balancers/create_options'),
  create:(data:Object) => ApiClient.post('openstack/lbaas-load-balancers',data),
  getAssociateOptions:(id:string) => ApiClient.get(`openstack/lbaas-load-balancers/${id}/associate-floating-ip-options`),
  deleteBalancer:(id:string) => ApiClient.delete(`openstack/lbaas-load-balancers/${id}`),
  getLbById:(id:string) => ApiClient.get(`openstack/lbaas-load-balancers/${id}`),
  edit:(id:string, data:Object) => ApiClient.put(`openstack/lbaas-load-balancers/${id}`, data),
  associateFloatingIp:(id:string, data:Object) => ApiClient.post(`openstack/lbaas-load-balancers/${id}/associate-floating-ip`, data),
  dissociateFloatingIp:(id:string) => ApiClient.post(`openstack/lbaas-load-balancers/${id}/dissociate-floating-ip`),
  getListeners:(id:string) => ApiClient.get(`openstack/lbaas-listeners?page_size=100&loadbalancer_id=${id}`),
  getPools:(id:string, listener?:string|any) => ApiClient.get(`openstack/lbaas-pools`,{
    params:{
      page_size:100,
      loadbalancer_id:id,
      listener_id:listener
    }
  }),
  deleteListener:(lbId:string, listener:string) => ApiClient.delete(`openstack/lbaas-listeners/${listener}?loadbalancer_id=${lbId}`),
  deletePool:(lbId:string, pool:string) => ApiClient.delete(`openstack/lbaas-pools/${pool}?loadbalancer_id=${lbId}`),
  createListener:(listener:Object) => ApiClient.post(`openstack/lbaas-listeners`, listener),
  createPool:(pool:Object) => ApiClient.post(`openstack/lbaas-pools`, pool),
  getListenerById:(id:string) => ApiClient.get(`openstack/lbaas-listeners/${id}`),
  getPoolById:(id:string) => ApiClient.get(`openstack/lbaas-pools/${id}`),
  getPolicies:(listenerId:string, active_client:any) => ApiClient.get(`openstack/lbaas-l7policies?page_size=100&listener_id=${listenerId}`),
  getPoliciesRulesCreateOptions:() => ApiClient.get(`openstack/lbaas-l7rules/create_options`),
  getPoolHealthMonitors:(poolId:string) => ApiClient.get(`openstack/lbaas-health-monitors?page_size=100&pool_id=${poolId}`),
  deleteHealthMonitor:(pool_id:string, monitor_id:string) => ApiClient.delete(`openstack/lbaas-health-monitors/${monitor_id}?pool_id=${pool_id}`),
  getPoolMembers:(poolId:string) => ApiClient.get(`openstack/lbaas-members?page_size=100&pool_id=${poolId}`),
  deleteMember:(pool_id:string, member:string) => ApiClient.delete(`openstack/lbaas-members/${member}?pool_id=${pool_id}`),
  getPoolMemberCreateOptions:() => ApiClient.get(`openstack/lbaas-members/create_options`),
  createPoolMember:(pool_id:string, data:Object) => ApiClient.post(`openstack/lbaas-members?pool_id=${pool_id}`, data),
  createHealthMonitor:(pool_id:string, data:Object) => ApiClient.post(`openstack/lbaas-health-monitors?pool_id=${pool_id}`, data),
  getMonitorById:(monitorId:string, pool_id:string) => ApiClient.get(`openstack/lbaas-health-monitors/${monitorId}?pool_id=${pool_id}`),
  editHealthMonitor:(pool_id:string, data:any) => ApiClient.put(`openstack/lbaas-health-monitors/${data?.id}?pool_id=${pool_id}`,data),
  getMemberById:(memberId:string, pool_id:string) => ApiClient.get(`openstack/lbaas-members/${memberId}?pool_id=${pool_id}`),
  editMember:(pool_id:string, data:any) => ApiClient.put(`openstack/lbaas-members/${data.id}?pool_id=${pool_id}`, data),
  editPool:(pool_id:string, data:any) => ApiClient.put(`openstack/lbaas-pools/${pool_id}`,data),
  getPoliciesCreateOptions:() => ApiClient.get(`openstack/lbaas-l7policies/create_options`),
  createPolicy:(data:Object) => ApiClient.post(`openstack/lbaas-l7policies`, data),
  editPolicy:(data:any) => ApiClient.put(`openstack/lbaas-l7policies/${data.id}`, data),
  deletePolicy:(id:any) => ApiClient.delete(`openstack/lbaas-l7policies/${id}`),
  getPolicyById:(policyId:string, listenerId:string) => ApiClient.get(`openstack/lbaas-l7policies/${policyId}?listener_id=${listenerId}`),
  getPolicyRules:(policyId:string) => ApiClient.get(`openstack/lbaas-l7rules?page_size=100&policy_id=${policyId}`),
  getPolicyRuleById:(policyId:string, ruleId:string) => ApiClient.get(`openstack/lbaas-l7rules/${ruleId}?page_size=100&policy_id=${policyId}`),
  getRulesCreateOptions:() => ApiClient.get(`openstack/lbaas-l7rules/create_options`),
  createPolicyRule:(policyId:string, data:any) => ApiClient.post(`openstack/lbaas-l7rules?policy_id=${policyId}`, data),
  editPolicyRule:(policyId:string, data:any) => ApiClient.put(`openstack/lbaas-l7rules/${data.id}?policy_id=${policyId}`, data),
  deleteRule:(ruleId:string, policyId:string) => ApiClient.delete(`openstack/lbaas-l7rules/${ruleId}?policy_id=${policyId}`),
  editListener:(listenerId:string, data:Object) => ApiClient.put(`openstack/lbaas-listeners/${listenerId}`, data),
}

export default loadBalancerService