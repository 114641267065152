import React, {FC, useEffect, useState} from "react";
import {
  Container,
  BodyWrapper,
} from './styles'
import { TableSkeleton, TopProgress } from "../../../../components";
import { InfoContainer } from "../../../../components/info-block/styles";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";


const BillingOverviewSkeleton:FC<any> = ({pageLoading}) => {
  const theme:any = useTheme()
  return (
    <Container>
      <TopProgress loading={pageLoading}/>
      {/* <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={135} height={25}
      /> */}
      <BodyWrapper>
        <InfoContainer
          style={{
            display:'flex',
            flex:'auto 1',
            marginBottom:'0px',
            gap:'10px',
            flexGrow:'1',
            flexBasis:'0'
          }}>
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={95} style={{marginBottom:"5px"}} height={25}
          />
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={115} style={{marginBottom:"5px"}} height={20}
          />
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={105} style={{marginBottom:"5px"}} height={30}
          />
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={105} style={{marginBottom:"5px"}} height={40}
          />
        </InfoContainer>
        <InfoContainer
          style={{
            display:'flex',
            flexDirection:'column',
            flex:'1 1',
            marginBottom:'0px',
            flexGrow:'1',
            flexBasis:'0',
          }}>
            <Skeleton
              baseColor={theme.palette[300]}
              highlightColor={theme.palette[20]}
              width={95} style={{marginBottom:"5px"}} height={25}
            />
            <Skeleton
              baseColor={theme.palette[300]}
              highlightColor={theme.palette[20]}
              width={115} style={{marginBottom:"5px"}} height={20}
            />
            <Skeleton
              baseColor={theme.palette[300]}
              highlightColor={theme.palette[20]}
              width={105} style={{marginBottom:"5px"}} height={30}
            />
        </InfoContainer>
      </BodyWrapper>
      <InfoContainer
        style={{
          display:'flex',
          flexDirection:'column',
          flex:'1 1',
          marginBottom:'0px',
          flexGrow:'1',
          flexBasis:'0',
          boxSizing:'border-box',
      }}>
        <Skeleton
          baseColor={theme.palette[300]}
          highlightColor={theme.palette[20]}
          width={95} style={{marginBottom:"5px"}} height={15}
        />
        <TableSkeleton
          rows={1}
          columns={4}
          // columnsData={Columns}
        />
      </InfoContainer>
      <InfoContainer
        style={{
          display:'flex',
          flexDirection:'column',
          flex:'1 1',
          marginBottom:'0px',
          flexGrow:'1',
          flexBasis:'0',
          boxSizing:'border-box',
      }}>
        <Skeleton
          baseColor={theme.palette[300]}
          highlightColor={theme.palette[20]}
          width={95} style={{marginBottom:"5px"}} height={15}
        />
        <TableSkeleton
          rows={10}
          columns={4}
          // columnsData={Columns}
        />
      </InfoContainer>
    </Container>
  )
}

export default BillingOverviewSkeleton