import styled from "styled-components";
import { BodyDefault } from "../typography/styled";
type ContainerProp = {
  customStyles?:any,
}
export const Container = styled.div<ContainerProp>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({customStyles}) => customStyles}
`

export const CounterWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  padding: 0px 10px;
  /* max-width: 100%; */
  height: 40px;
  background-color: ${({theme}) => theme.palette[10]};
  border:${({theme}) => `1px solid ${theme.grey[350]}`};
  border-radius:4px;
`

export const Input = styled.input`
  max-width: 45px;
  border:none;
  outline: none;
  background-color: ${({theme}) => theme.palette[10]};
  color: ${({theme}) => theme.base.black};
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  appearance: none;
  -moz-appearance: textfield; /* Firefox */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    display: none;
  }
  &:focus{
    border: none;
    box-shadow: none;
  }
  &:active{
    border: none;
    box-shadow: none;
  }
`
export const StyledText = styled(BodyDefault)`
  color:${({theme}) => theme.grey[600]};
  margin-right:6px;
`