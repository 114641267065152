import IconInfo from './info.svg'
import MinusDefault from './minus-default.svg'
import PlusDefault from './plus-default.svg'
import SearchIcon from './search.svg'
import CloseIcon from './close.svg'
import Instances from './instances.svg'
import Document from './document-small.svg'
import Settings from './setting.svg'
import ShowPassword from './show-password.svg'
import HidePassword from './hide-password.svg'
import LogoType from './logo-black.svg'
import HideEyeBlue from './hide-eye-blue.svg'
import LinkBlue from './link-blue.svg'
import PauseIcon from './pause.svg'
import PlayIcon from './play.svg'
import DeleteIcon from './delete.svg'
import Chevron from './chevron.svg'
import More from './more.svg'
import Password from './password.svg'
import Rebuild from './rebuild.svg'
import Rename from './rename.svg'
import Power from './power.svg'
import Resize from './resize.svg'
import Restart from './restart.svg'
import Edit from './edit.svg'
import Filter from './filter.svg'
import Upload from './upload.svg'
import Copy from './copy.svg'
import Support from './support-blue.svg'
import SupportGrey from './support-default.svg'
import Notification from './notification.svg'
import Clients from './clients.svg'
import Warning from './warning.svg'
import Error from './error.svg'
import Info from './info.svg'
import CheckMark from './check-mark.svg'
import ArrowBack from './arrow-back.svg'
import CloseWithBack from './close-with-back.svg'
import Rescue from './rescue.svg'
import HelpIcon from './helpIcon.svg'
import BackArrowRevert from './arrow-back-revert.svg'
import TwoMark from './twomark.svg'
import Shelve from './shelve.svg'
import Unshelve from './unshelve.svg'
import PowerOnBack from './power-with-back.svg'
import Download from './download.svg'
import Launch from './launch.svg'
import CopyProps from './copy-props.svg'
import ArrowRight from './arrow-right.svg'
import ArrowLeft from './arrow-left.svg'
import CloseIconBold from './close-bold.svg'
import IcDownload from './ic-download.svg'
import IcNotification from './ic-notification.svg'
import ChangeInstanceType from './change_type.svg'
import IconTag from './ic-tag.svg'
import ExportIc from './export.svg'
import EyeIc from './eye.svg'
import DocumentCodeIc from './document-code.svg'
import CodeCircleIc from './code-circle.svg'
import ToggleIcon from './toggle-off-circle.svg'

export const icons = {
  IconInfo,
  MinusDefault,
  PlusDefault,
  SearchIcon,
  CloseIcon,
  Instances,
  Document,
  Settings,
  ShowPassword,
  HidePassword,
  LogoType,
  HideEyeBlue,
  LinkBlue,
  PauseIcon,
  PlayIcon,
  DeleteIcon,
  Chevron,
  More,
  Password,
  Rebuild,
  Rename,
  Power,
  Resize,
  Restart,
  Edit,
  Filter,
  Upload,
  Copy,
  Support,
  SupportGrey,
  Notification,
  Clients,
  Warning,
  Error,
  Info,
  CheckMark,
  ArrowBack,
  CloseWithBack,
  Rescue,
  HelpIcon,
  BackArrowRevert,
  TwoMark,
  Shelve,
  Unshelve,
  PowerOnBack,
  Download,
  Launch,
  CopyProps,
  ArrowRight,
  ArrowLeft,
  CloseIconBold,
  IcDownload,
  IcNotification,
  ChangeInstanceType,
  IconTag,
  ExportIc,
  EyeIc,
  DocumentCodeIc,
  CodeCircleIc,
  ToggleIcon,
}