import React, {FC, useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { StartPage, Button, ModalVariables } from "../../components";
import TutorImg from './illustration.svg'
import TutorDarkImg from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { timeConverterMMDDYYHHMM } from "../../utils/dates";
import { volumeService } from "../../api";
import { useUi } from "../../context/ui.context";
import { useAuth } from "../../context/user.context";
import rootConfig from "../../config";
import { compareArrays } from "../../utils/findIntersection";
import { StateWrapper, GreenDot, Greentext } from "../Instances/styles";

const Volumes:FC = () => {
  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user, quotas} = useAuth()
  const [volumes, setVolumes] = useState<any[]>([])
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam);
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [paginationData, setPaginationData] = useState<any>()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedVolume, setSelectedVolume] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(volumes, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const [t] = useTranslation()
  const navigate = useNavigate()
  const _updateVolumes = () => {
    // setLoading(true)
    volumeService.getVolumeList({
      page:page || 1,
      page_size:pageSize || 10,
      ordering:ordering,
    })
    .then((res) => {
      setVolumes(res.data.objects.map((volume:any) => {
        return {
          ...volume,
          navigate: volume.id,
          sizeString:`${volume.size} GB`,
          name: volume.name || volume.id,
          created_at:timeConverterMMDDYYHHMM(volume.created_at),
          status:(
            <StateWrapper>
              <GreenDot active={volume.status}/>
              <Greentext style={{textTransform:"capitalize"}} active={volume.status}>
                {volume.status}
              </Greentext>
            </StateWrapper>) ,
        }
      }))
      setPaginationData(res.data)
      setPageLoading(false)
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      navigate('/home')
      setPageLoading(false)
    })
  }
  const items = [
    {
      title:t("RENAME"),
      id:'2',
      icon:icons.Edit,
      onClick: (volume:any) => {
        openModal('rename')
        setSelectedVolume(volume)
        setAlertTitle(`${t("RENAME_VOLUME")} «${volume.name || volume.id}»`)
      },
      divider:false
    },
    {
      title:t("EXTEND_VOLUME"),
      id:'3',
      icon:icons.Resize,
      toChangeColor:true,
      divider:false,
      onClick: (volume:any) => {
        openModal('extend')
        setSelectedVolume(volume)
        setAlertTitle(`${t("EXTEND_VOLUME_TITLE")} «${volume.name || volume.id}»`)
      },
    },
    {
      title:t("EDIT_BOOTABLE_STATUS"),
      id:'5',
      icon:icons.PowerOnBack,
      onClick: (volume:any) => {
        openModal('change-status')
        setSelectedVolume(volume)
        setAlertTitle(`${t("CHANGE_BOOTABLE_STATUS_FOR_VOLUME")} «${volume.name || volume.id}»`)
      },
      divider:false
    },
    {
      title:t("REVERT_TO_SNAPSHOT"),
      id:'6',
      icon:icons.BackArrowRevert,
      onClick: (volume:any) => {
        openModal('revert-volume')
        setSelectedVolume(volume)
        setAlertTitle(volume.name || volume.id)
      },
      divider:false
    },
    {
      title:t("UPLOAD_TO_IMAGE"),
      id:'7',
      icon:icons.Upload,
      onClick: (volume:any) => {
        openModal('upload-volume-to-image')
        setAlertTitle(`${t("UPLOAD")} «${volume.name || volume.id}» ${t("TO_IMAGE")}`)
        setSelectedVolume(volume)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(volume:any) => {
        openModal('delete')
        setAlertTitle(t("VOLUME"))
        setAlertSubTitle([volume])
        setSelectedVolume(volume)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const _deleteVolume = async () => {
    setLoading(true);
    try {
      if (allChecked) {
        await Promise.all(volumes.map((item) => volumeService.deleteVolume(item.id)));
        _updateVolumes();
        setAllChecked(false);
        setChecked([]);
      } else if (checked?.length > 0) {
        await Promise.all(checked.map((item) => volumeService.deleteVolume(item.id)));
        _updateVolumes();
        setChecked([]);
      } else if (selectedVolume) {
        await volumeService.deleteVolume(selectedVolume.id);
        _updateVolumes();
      }
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      setChecked([]);
    } finally {
      setViewModal(false);
      setLoading(false);
      setActiveAnimation(false);
    }
  };
  useEffect(() => {
    _updateVolumes()
    const interval = setInterval(() => {
      _updateVolumes()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = async (values:any, setErrors?:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'revert-volume':
          res = await volumeService.revertToSnapshotVolume(selectedVolume.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'upload-volume-to-image':
          res = await volumeService.uploadToImage(selectedVolume.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'change-status':
          res = await volumeService.botableVolume(selectedVolume.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'rename':
          res = await volumeService.renameVolume(selectedVolume.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'extend':
          res = await volumeService.extendVolume(selectedVolume.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'delete':
          _deleteVolume()
      }
      if(modalType !== 'delete'){
        setViewModal(false)
        setActiveAnimation(false)
      }
    } catch (e: any) {
      if (e?.response?.data?.detail || e?.response?.data?.non_field_errors) {
        viewAlert({
          severity: 'error',
          message: e.response?.data?.detail || e?.response?.data?.non_field_errors,
        });
      } else {
        setErrors(e.response.data)
      }
    } finally {
      if(modalType !== 'delete'){
        setLoading(false);
      }
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    {
      title:t("AVAILABLE_ZONE"),
      key:'availability_zone'
    },
    {
      title:t("SIZE"),
      key:'sizeString'
    },
    {
      title:'',
      key:'props'
    },
  ]
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas.volume.volumes.limit === quotas.volume.volumes.in_use){
      viewAlert({
        severity:'error',
        message:`${t('VOLUMES_QUOTA_EXCEEDED')}`,
      })
    } else {
      navigate('create')
    }
  }
  return (
    <>
      <StartPage
        title={`${t("VOLUMES")}`}
        noResourceText={`${t("NO_VOLUMES")}`}
        createResourceButton={`${t('CREATE_VOLUME')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={volumes && volumes.length > 0}
        learnMoreAboutResource={`${t('LEARN_MORE_VOLUMES')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/volumes`}
        howCreateVideoText={`${t('HOW_CREATE_VOLUME')}`}
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("VOLUMES"))
                setAlertSubTitle(allChecked ? volumes : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        videoImage={TutorImg}
        videoImageDark={TutorDarkImg}
        pageLoading={pageLoading}
        tableData={{
          columns:Columns,
          data:volumes,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          paginationData:paginationData,
          updateData:_updateVolumes
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={selectedVolume}
      />
    </>
  )
}


export default Volumes
