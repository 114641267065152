import styled from 'styled-components'
import SVG from "react-inlinesvg";
type ActiveProp = {
  active?:boolean,
  disable?:boolean,
  hover?:boolean,
  isFill?:boolean,
  isMenu?:boolean,
}
const StyledButtonIcon = styled(SVG)<ActiveProp>`
  width: 20px;
  /* opacity: ${({hover, disable}) => hover || disable ? '1.2' : ''}; */
  & path {
      fill:${({theme, isFill}) => isFill ? theme.isDark ? theme.palette[900] : theme.palette[600] : null};
      stroke: ${({theme, isFill, isMenu}) => isMenu ? null : isFill ? theme.palette[850] : null};
      /* fill:${({theme, hover}) => hover ? theme.palette[900] : theme.palette[800]}; */
    };
  filter: ${({active, disable}) => active && !disable ? 'brightness(120%)' : ''};
  &:hover {
    cursor: pointer;
    & path {
      /* stroke: black; */
    };
    /* opacity: ${({hover}) => hover ? '' : '1.2'}; */
  }
  &:active{
    filter: ${({disable}) => !disable ? 'brightness(77%)' : ''};
  }
`

export const Spinner = styled.div<{isSecondary?:boolean}>`
  width: ${({isSecondary}) => isSecondary ? '20px' : '24px'};
  height: ${({isSecondary}) => isSecondary ? '20px' : '24px'};
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: ${({isSecondary, theme}) => isSecondary ? `1.5px solid ${theme.primary[700]}` : '1.5px solid #FFF'};
    animation: prixClipFix 2s linear infinite ;
  }

  @keyframes rotate {
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }
`
export const SpinnerBlack = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right:13px;
  position: relative;
  animation: rotate 1s linear infinite;
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 1.5px solid #000;
    animation: prixClipFix 2s linear infinite ;
  }

  @keyframes rotate {
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }
`
export const SuccessCheckmark = styled.svg<{isSecondary?:boolean}>`
  *{
    padding:0;margin:0
    }
    
    display:flex;
    justify-content:center;
    align-items:center;
    /* background-color:#eee; */
    .checkmark__circle{
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 3;
      stroke-miterlimit: 10;
      stroke: #fff;
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      }
      .checkmark{
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;

        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px #7ac142;
        animation: fill .3s ease-in-out .4s forwards, scale .2s ease-in-out .4s both
        }
        .checkmark__check{
          transform-origin: 50% 50%;
          stroke-dasharray: 48;
          stroke-dashoffset: 48;
          animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards
          }
          @keyframes stroke{
            100%{stroke-dashoffset: 0}
            }
          @keyframes scale{
            0%,
            100%{
              transform: none
            }
            50%{
              transform: scale3d(1.1, 1.1, 1)
            }
          }
          @keyframes fill{
            100%{
              box-shadow: inset 0px 0px 0px 30px #7ac142
            }
          }
`

export default StyledButtonIcon