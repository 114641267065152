import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";

type MetricQuery = {
  hours:string,
  metric:string,
}
const stringreplacer = (tags:any[]=[]) => {
  console.log('tags', tags);
  return `${tags?.map((tag:any, ind:any) => `tags__name:${tag}`)}`
}
const instanceService = {
  getInstanceList: (queries:QueriesApi) => ApiClient.get(`openstack/instances?page_size=${queries.pageSize}&ordering=${queries.ordering}&page=${queries.page}&tags=${queries?.tags ? queries?.tags.map((tag:any) => `${tag}`) : ''}`, {
    params: {
      // filtering:stringreplacer(queries?.tags).replace(',', '+')
      //@ts-ignore
      // tags:queries && queries?.tags.map((tag:any) => `${tag},`)
      // filtering:`tags__name: rere+tags__name:ddd`
      // filtering: `${queries?.tags && queries?.tags?.map((tag:any, ind:any) => `tags__name:${tag.name}${queries?.tags && queries?.tags?.length === ind+1 && queries?.tags?.length !== 1 ? '' : ''}`)}`
    }
  }),
  getInstanceDetail: (instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}`),
  createInstance: (data:Object) => ApiClient.post(`openstack/instances`, data),
  updateInstance: (instanceId:string, data:Object) => ApiClient.put(`openstack/instances/${instanceId}`, data),
  deleteInstance: (instanceId:string) => ApiClient.delete(`openstack/instances/${instanceId}`),
  getConsoleOutput: (instanceId:string, length:string|number = 30) => ApiClient.get(`openstack/instances/${instanceId}/get_console_output?length=${length}`),
  getMetrics: (instanceId:string, queries:MetricQuery) => ApiClient.get(`openstack/instances/${instanceId}/measures?hours=${queries.hours}&metric=${queries.metric}`),
  getConsoleUrl:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/get_console_url`),
  checkNetwork:(name:string) => ApiClient.get(`check_network?name=${name}`),
  getPortById:(id:string) => ApiClient.get(`openstack/ports/${id}`),
  // attachPortToInstance:(data:Object) => ApiClient.post(`openstack/ports`, data),
  getAssociateIpOptions:(id:string) => ApiClient.get(`openstack/instances/${id}/associate_ip_create_options`),
  removeIp:(id:string, data:Object) => ApiClient.post(`openstack/ports/${id}/remove_ip`, data),
  addFloatingIp:(instanceId:string, data:Object) => ApiClient.post(`openstack/instances/${instanceId}/add_floating_ip`, data),
  getCreateOptions:() => ApiClient.get('openstack/instances/create_options'),
  getPrice:(queries:any) => ApiClient.get(`openstack/instances/get_price?region=${queries.region}&client_id=${queries.clientId}&flavor_id=${queries.flavorId}${queries.networks.map((net:any) => `&network_ids=${net}`)}`, {
    params:{
      volume_type:queries.volume_type,
      volume_size:queries.volume_size,
      floating_ip_network: queries?.floating_ip_network,
      number_of_instances: queries?.number_of_instances,
    }
  }),
  getPriceOnlyFlavor:(id:any) => ApiClient.get(`openstack/instances/get_price?flavor_id=${id}`),
  getFlavorsAssignedToImage:(queries:any) => ApiClient.get(`openstack/instances/get_flavors_assigned_to_image`,{
    params:{
      image_id:queries.image_id,
      only_compatible_flavors:true,
      selected_client_id:queries.selected_client_id,
    }
  }),
  addPortCreateOptions:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/add_port_options`),
  detachPort:(id:string, data:any) => ApiClient.post(`openstack/instances/${id}/detach_port`, data),
  attachPortToInstance:(id:string, data:any) => ApiClient.post(`openstack/instances/${id}/attach_port`, data),
  addIpOptions:(id:string) => ApiClient.get(`openstack/instances/${id}/add_ip_options`),
  getAttachableVolumes:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/get_attachable_volumes?page_size=1000`),
  attachVolume:(instanceId:string, data:Object) => ApiClient.post(`openstack/instances/${instanceId}/attach_volume`, data),
  detachVolume:(instanceId:string, data:Object) => ApiClient.post(`openstack/instances/${instanceId}/detach_volume`, data),
  stopInstance:(instanceId:string) => ApiClient.post(`openstack/instances/${instanceId}/stop`),
  startInstance:(instanceId:string) => ApiClient.post(`openstack/instances/${instanceId}/start`),
  rebootInstance:(instanceId:string) => ApiClient.post(`openstack/instances/${instanceId}/reboot`),
  revertResizeInstance:(instanceId:string) => ApiClient.post(`openstack/instances/${instanceId}/revert_resize`),
  confirmResizeInstance:(instanceId:string) => ApiClient.post(`openstack/instances/${instanceId}/confirm_resize`),
  resetStateInstance:(instanceId:string) => ApiClient.post(`openstack/instances/${instanceId}/reset_state`),
  renameInstance:(instanceId:string, data:any) => ApiClient.post(`openstack/instances/${instanceId}/rename`, data),
  getResizeOptions:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/resize_options`),
  resizeInstance:(instanceId:string, data:any) => ApiClient.post(`openstack/instances/${instanceId}/resize`, data),
  dissociateIpOptions:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/dissociate_ip_create_options`),
  dissociateFloatingIp:(instanceId:string, data:any) => ApiClient.post(`openstack/instances/${instanceId}/remove_floating_ip`, data),
  getRescueOptions:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/rescue`),
  rescueInstance:(instanceId:string, data:any) => ApiClient.post(`openstack/instances/${instanceId}/rescue`, data),
  unrescueInstance:(instanceId:string) => ApiClient.post(`openstack/instances/${instanceId}/unrescue`),
  getRebuildOptions:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/rebuild`),
  rebuildInstance:(instanceId:string, data:any) => ApiClient.post(`openstack/instances/${instanceId}/rebuild`, data),
  changeRootPassword:(instanceId:string, data:any) => ApiClient.post(`openstack/instances/${instanceId}/change_password`, data),
  shelveInstance:(instanceId:string) => ApiClient.post(`openstack/instances/${instanceId}/shelve`),
  unshelveInstance:(instanceId:string) => ApiClient.post(`openstack/instances/${instanceId}/unshelve`),
  associateSecurityGroup:(instanceId:string, data:Object) => ApiClient.post(`openstack/instances/${instanceId}/associate_security_group`, data),
  getSecurityGroups:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/list_security_groups`),
  getAssociateSecurityOptions:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/associate_security_group_create_options`),
  dissociateSecurityGroup:(instanceId:string, data:Object) => ApiClient.post(`openstack/instances/${instanceId}/dissociate_security_group`, data),
  getInstanceSnapshots:(instanceId:string) => ApiClient.get(`openstack/images/get_snapshots_for_instance?instance_uuid=${instanceId}`),
  getInstanceBackups:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/get_instance_backups_and_backup_schedules`),
  getPriceInstanceSnapshot:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/get_snapshot_price_per_cycle`),
  getPriceInstanceBackup:(instanceId:string) => ApiClient.get(`openstack/instances/${instanceId}/get_backup_price`),
  createInstanceSnapshot:(instanceId:string, data:any) => ApiClient.post(`openstack/instances/${instanceId}/create_snapshot`, data),
  createInstanceBackup:(instanceId:string, data:any) => ApiClient.post(`openstack/instances/${instanceId}/create_backup`, data),
  createInstanceBackupSchedule:(data:any) => ApiClient.post(`osbackup`, data),
  deleteBackupSchedule:(scheduleId:string) => ApiClient.delete(`osbackup/${scheduleId}`),
  getTags:() => ApiClient.get('all-tags?page_size=100'),
  addTag:(instanceId:string, tag:any) => ApiClient.post(`openstack/instances/${instanceId}/tag/`, tag),
  deleteTag:(instanceId:string, tagId:any) => ApiClient.delete(`openstack/instances/${instanceId}/tag/${tagId}/`),
  unmountIsoAndReboot:(instanceId:string) => ApiClient.post(`openstack/instances/${instanceId}/unrescue`),
}

export default instanceService;