import ApiClient from './apiClient'
import { QueriesApi } from './apiTypes'


const volumeBackupService = {
  getBackupList:(queries:QueriesApi) => ApiClient.get('openstack/volumebackups', {
    params:{
      ...queries
    }
  }),
  deleteBackup:(backupId:string) => ApiClient.delete(`openstack/volumebackups/${backupId}`),
  getBackup:(backupId:string) => ApiClient.get(`openstack/volumebackups/${backupId}`),
}

export default volumeBackupService