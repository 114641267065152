import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { Button, ModalVariables, StartPage, Table, TableSkeleton, TopProgress} from "../../components";
import { useNavigate } from "react-router";
import {
  Container,
  WrapperRow,
  Title,
  BodyText,
  GreenDot,
  Greentext,
  StateWrapper,
} from '../Instances/styles'
import { useUi } from "../../context/ui.context";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { operationService } from "../../api";
import { useAuth } from "../../context/user.context";
import {timeConverterMMDDYYHHMM} from '../../utils/dates'
import { handleStatusName } from "../../utils/handle-status-name";




const Operations:FC = () => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [paginationData, setPaginationData] = useState<any>()
  const [operations, setOperations] = useState<any[]>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedOperation, setSelectedOperation] = useState<any>()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {user} = useAuth()
  const {
    viewAlert
  } = useUi()
  const _updateOperations = () => {
    operationService.getList({
      page:page || 1,
      pageSize:pageSize || 10,
      ordering:ordering,
    }, user.user.active_client)
    .then((res) => {
      setOperations(res.data.objects.map((operation:any) => {
        return {
          ...operation,
          navigate: operation.id,
          hideProps:operation.status === 'succeeded',
          dropOptions: operation.status === 'in progress' ? settingsActive : settings,
          created_at:timeConverterMMDDYYHHMM(operation.created_at),
          status:(
          <StateWrapper>
            <GreenDot active={operation.status}/>
            <Greentext style={{textTransform:"capitalize"}} active={operation.status}>
              {handleStatusName(operation.status, t)}
            </Greentext>
          </StateWrapper>) ,
        }
      }))
      setPaginationData(res.data)
      setPageLoading(false)
    })
    .catch((e) => {
      console.log('error', e);
    })
  }
  useEffect(() => {
    _updateOperations()
    const interval = setInterval(() => {
      _updateOperations()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
    const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = async () => {
    let res:any
    try {
      setLoading(true);
      switch (modalType) {
        case 'universal':
        if (alertTitle === `${t('ABORT_OPERATION')}`) {
          res = await operationService.abortOperation(selectedOperation.id)
        } else if (alertTitle === t("RETRY_OPERATION")){
          res = await operationService.retryOperationStart(selectedOperation.id)
        }
        break;

      }
      if(res?.data?.detail){
        viewAlert({
          severity:'info',
          message: res.data?.detail
        })
        _updateOperations()
      }
      await setLoadingComplete(true)
      await setTimeout(() => {
        setLoading(false)
        setViewModal(false)
        setLoadingComplete(false)
        setActiveAnimation(false)
      },1500)

    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      setLoading(false);
    }
  }
  const settings = [
    {
      title:'Retry',
      onClick:(operation:any) => {
        setSelectedOperation(operation)
        setAlertTitle(t("RETRY_OPERATION"))
        setAlertSubTitle(`${t("ARE_YOU_SHURE_YOU_WANT_TO_RETRY_OPERATION")} ${operation.id}`)
        openModal('universal')
      },
      id:'3',
      icon:icons.Restart,
      divider:false
    },
  ]

  const settingsActive = [
    {
      title:'Abort',
      onClick:(operation:any) => {
        setSelectedOperation(operation)
        setAlertTitle(t("ABORT_OPERATION"))
        setAlertSubTitle(`${t("ABORT_OPERATION_ALERT")} ${operation.id}`)
        openModal('universal')
      },
      id:'3',
      icon:icons.CloseIconBold,
      divider:false
    },
  ]
  const Columns = [
    {
      title:'ID',
      key:'id'
    },
    {
      title:t("OPERATION_STATUS"),
      key:'status'
    },
    {
      title:t("OPERATION_TYPE"),
      key:'operation_type'
    },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
    {
      title:'',
      key:'props'
    },
  ]
  
  return (
    <Container>
      {/* <TopProgress loading={loading}/> */}
      <WrapperRow>
        <Title>
          {t("OPERATIONS")}
        </Title>
      </WrapperRow>
      {pageLoading ? 
        <>
          <TopProgress loading={pageLoading}/> 
            <TableSkeleton
              rows={10}
              columns={Columns?.length || 5}
              columnsData={Columns}
            />
        </> : 
        operations.length > 0 ?
          <WrapperRow style={{flexDirection:'column', marginTop:'20px'}}>
            <Table
              columns={Columns}
              rows={operations}
              settings={settings}
              hideCheckbox
              // allChecked={tableData?.allChecked}
              // setAllChecked={tableData?.setAllChecked}
              // checked={tableData?.checked}
              // setChecked={tableData?.setChecked}
              paginationData={paginationData}
              updateData={_updateOperations}
            />
          </WrapperRow>
      :
        <WrapperRow style={{marginTop:'20px'}}>
            {/* @ts-ignore */}
          <BodyText >
            {t("YOU_HAVE_NO_OPERATIONS")}
          </BodyText>
        </WrapperRow>
      }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        loadingComplete={loadingComplete}
      />
    </Container>
  )
}


export default Operations

