import styled from "styled-components";
import { BodyMedium, BodySmall } from "../typography/styled";
type ActiveProp = {
  active?:boolean,
  size?:'small' | 'display',
  containerWidth?:string,
  hover?:boolean,
  isSteps?:boolean,
  last?:any,
  greyBack?:boolean,
}
export const Container = styled.div<ActiveProp>`
  display: flex;
  background-color: ${({theme, greyBack}) => greyBack ? theme.palette[10] : theme.base.white};
  align-items: flex-end;
  height:40px;
  gap:${({isSteps}) => isSteps ? '10px' : ''};
  padding: ${({size}) => size === 'small' ? '0px' : '20px 0px 0px'};
  &:hover{
    cursor: ${({isSteps}) => isSteps ? '' : 'pointer'};
  }
`

export const TabText = styled(BodyMedium)<ActiveProp>`
  color:${({theme, active, hover, isSteps}) => active ? theme.primary[600] : hover ? theme.palette[900] : theme.grey[700]};
  margin-right: ${({size, isSteps}) => isSteps ? '6px' : ''};
  
`

export const TabWrapper = styled.div<ActiveProp>`
  flex: ${({size, isSteps}) => isSteps ? '1' : ''};;
  display: flex;
  align-items: flex-end;
  /* width:100%; */
  /* margin-right: ${({isSteps, last}) => isSteps && !last ? '10px' : ''}; */
  justify-content: ${({size, isSteps}) => !isSteps ? 'space-between' : 'flex-start'};
  padding: ${({size, isSteps}) => size === 'small' ? '10px 0px' : isSteps ? "10px 10px 10px 0px" : '10px 20px'};
  border-bottom:${({active, theme, isSteps}) => {
    return isSteps ? 
    active ? `2px solid ${theme.primary[600]}` : `2px solid ${theme.grey[350]}`
    :
    active ? `2px solid ${theme.primary[600]}` : `1px solid ${theme.grey[350]}`
  }};
  /* transition:0.3s all ease-in-out; */
`
export const AddInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 0px;
  gap: 10px;
  width:100%;
  border-bottom: 1px solid #E8E8E8;
`

export const AddInfoText = styled(BodySmall)<ActiveProp>`
  color:${({theme, active}) => active ? theme.primary[850] : theme.palette[700]};
`