import styled from "styled-components";
import { BodyDefault } from "../../typography/styled";
type ImgProps = {
  active?:boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 20px;
  box-sizing: border-box;
  width: 400px;
  /* height: 350px; */
  left: 540px;
  top: 305px;
  background: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`
export const BodyModal = styled.div`
  display:flex;
  flex-direction: column;
  padding:0px 10px;
  gap:20px;
  width: 360px;

`

export const Icon = styled.img`
  &:hover{
    cursor: pointer;
  }
`
export const SelectWrapper = styled.div`
  /* display: flex; */
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  /* min-height: 144px; */
  border: ${({theme}) => `1px solid ${theme.palette[20]}`};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom:20px;
`
export const SelectRow = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
`
export const RefreshWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-left: 8px;
  gap:8px;
  height: 52px;
  &:hover{
    cursor: pointer;
  }

`
export const RefreshText = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
`
export const RotateImg = styled.img<ImgProps>`
  animation: ${({active}) => active ? 'rotating 0.8s linear infinite' : ''};
  @keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`