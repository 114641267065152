import React, { FC, useState } from 'react';
import {
  Container,
  BigInfo,
  Body,
  ItemsWrapper,
  Item,
  ImgBig,
  ItemTitle,
  LinkContainer,
  RuleItem,
  FoundedBlock,
  YoursBlock,
} from './styles'
import { Button, Typography } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { affiliateService } from '../../../../api';
import { useUi } from '../../../../context/ui.context';
import { useTheme } from 'styled-components';
import BigImg from './big-default.svg'
import BigImgDark from './big-dark.svg'
import { ArrowRight, MoneyIc, MoneyWithdrawIc, ShareIcon } from '../../../../icons-components/affiliate';


const InfoAffiliate:FC<any> = () => {
  const theme:any = useTheme()
  const [t] = useTranslation()
  return (
    <Container>
      <Body>
        <BigInfo>
          <div style={{display:'flex', flexDirection:'column', gap:'6px'}}>
            <Typography  variant='h2'>
              <span>{t("GET")}</span>
              <span style={{margin:'0px',color:theme.primary[600]}}> 10% </span>
              <span>{t("FROM_EVERY_DEPOSIT")}</span>
            </Typography>
            <Typography variant='body'>
              {t("DEPOSIT_PERCENT_DESCRIPTION")}
            </Typography>
          </div>
          <ImgBig src={theme.isDark ? BigImgDark : BigImg}/>
        </BigInfo>
        <ItemsWrapper>
          <Typography variant='h2'>
            {t("RULES")}
          </Typography>
          <Item>
            <ShareIcon styles={{minWidth:"32px"}}/>
            <ItemTitle>
              <Typography variant='body'>
                {t("SHARE_LINK_TITLE")}
              </Typography>
              <LinkContainer>
                https://{window.location.hostname}?ref=
                <span style={{filter:'blur(2.2px)'}}>yjysnwm</span>
              </LinkContainer>
            </ItemTitle>
          </Item>
          <Item>
            <MoneyIc styles={{minWidth:"32px"}}/>
            <ItemTitle>
              <Typography variant='body'>
                {t("CONDITION_TITLE")}
              </Typography>
              <RuleItem>
                <FoundedBlock>{t("REFERRAL_FOUNDED")}</FoundedBlock>
                <ArrowRight color={theme.palette[900]}/>
                <YoursBlock>10% {t("IS_YOURS")}</YoursBlock>
              </RuleItem>
              <Typography variant='body'>
                {t("CONDITION2_TITLE")}
              </Typography>
            </ItemTitle>
          </Item>
          <Item>
            <MoneyWithdrawIc styles={{minWidth:"32px"}}/>
            <ItemTitle>
              <Typography variant='body'>
                {t("CONTACT_US_TO_WITHDRAW")}
              </Typography>
              <LinkContainer>
                {t("WITHDRAW_AMOUNT_FROM")} 10$
              </LinkContainer>
            </ItemTitle>
          </Item>
        </ItemsWrapper>
      </Body>

      {/*  */}
  </Container>
  )
}

export default InfoAffiliate