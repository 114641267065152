interface MyObject {
  id?: number;
  name: string;
}

export const findDuplicateObjects = (array:any) => {
  const result:any = [];
  // for (let i = 1; i < array.length; i++) {
  //   if (array[i].name === array[i - 1].name) {
  //     result.push({...array[i], index:i});
  //   }
  // }
  for (let i = 0; i < array.length - 1; i++) {
    for (let j = i + 1; j < array.length; j++) {
      if (array[i].name === array[j].name) {
        result.push({...array[i], index:i}, {...array[j], index:j});
      }
    }
  }
  return result;
}


export const compareArraysTags = (array1: MyObject[], array2: MyObject[]): MyObject[] => {
  const missingElementsArray: MyObject[] = array1.filter(obj1 => {
    return !array2.some(obj2 => obj1.id === obj2.id);
  });

  return missingElementsArray;
}


export const findIntersection = (array1:any, array2:any, field:any) => {
  const intersection = [];
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      if (array1[i][field] === array2[j][field]) {
        intersection.push(array1[i]);
      }
    }
  }
  return intersection;
}

export function findIntersectionByFields(array1:any, array2:any, fields:any) {
  return array1.filter((obj1:any) =>
    array2.some((obj2:any) => 
      fields.every((field:any) => obj1[field] === obj2[field])
    )
  );
}


export function compareArrays(arr1:any[], arr2:any[]) {
  for (let obj1 of arr1) {
    let found = false;
    for (let obj2 of arr2) {
      if (obj1.id === obj2.id) {
        found = true;
        break;
      }
    }
    if (!found) {
      return false;
    }
  }
  return true;
}