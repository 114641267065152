import React, { FC, useEffect, useState } from "react";
import {Container} from './styles'
import { Button, Input, Select, Toggle, TopProgress } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { loadBalancerService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { Title } from "../../../Instances/styles";


const PoolDetails:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {poolId, lbId} = useParams()
  const [lbPool, setLbPool] = useState<any>()
  const {viewAlert} = useUi()
  const [admin, setAdmin] = useState<boolean>(true)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [algo, setAlgo] = useState<any[]>([])
  const [sessionPersistance, setSessionPersistance] = useState<any[]>([])
  const [initialValues, setInitialValues] = useState<any>({
    name:'',
    description:'',
    lb_algorithm:'',
    persistance:'',
    cookie_name:'',
  })

  const getCreateOptions = async () => {
    try{
      const lboptions = await loadBalancerService.getCreateOptions()
        await setSessionPersistance(
          Object.keys(lboptions.data.session_persistence_types).map((type) => {
            return {
              value:type,
              label:lboptions.data.session_persistence_types[type],
            }
          })
        )
        await setAlgo(lboptions.data.pool_supported_algorithms.map((algo:any) => {
          return {
            label: lboptions.data.pool_algorithms[algo],
            value: algo,
          }
        }))
      if(poolId){
        const poolRes = await loadBalancerService.getPoolById(poolId)
          setLbPool(poolRes.data)
          setPageLoading(false)
          setAdmin(poolRes?.data?.admin_state_up)
          setInitialValues({
            name:poolRes?.data?.name || '',
            description:poolRes?.data?.description || '',
            lb_algorithm:poolRes?.data?.lb_algorithm || '',
            persistance:poolRes?.data?.session_persistence?.type || '',
            cookie_name:poolRes?.data?.session_persistence?.cookie_name || '',
          })
      }
      
    } catch(err:any) {
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    } finally {
      setPageLoading(false)
    }
    
  }
  useEffect(() => {
    getCreateOptions()
  },[])

  const onSubmit = (values:any) => {
    console.log('submit');
    setLoading(true)
    const data = {
      ...values,
      admin_state_up:admin,
      session_persistence:{
        type:values.persistance
      }
    }
    if(values.persistance === 'APP_COOKIE'){
      data.session_persistence.cookie_name = values.cookie_name
    }
    loadBalancerService.editPool(lbPool?.id, data)
    .then((res) => {
      setLoadingComplete(true)
        setTimeout(() => {
          navigate(-1)
          viewAlert({
            severity:'info',
            message:`${t("POOL_EDITED_SUCCESS")}`,
          })
        }, 1500)
    })
    .catch((err) => {
      setErrors(err?.response?.data)
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
      setLoading(false)
    })
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema:
      yup.object().shape({
        name: yup.string().required(`${t('REQUIRED_FIELD')}`).matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
        description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
        lb_algorithm: yup.string().required(`${t('REQUIRED_FIELD')}`),
        persistance: yup.string(),
        cookie_name: yup.string().when("persistance", {
          is: (persistance:any) => persistance === 'APP_COOKIE',
          then: yup.string().required(`${t('REQUIRED_FIELD')}`)
        }),
      }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      <Title>{t("EDIT_FOR")} «{lbPool?.name || lbPool?.id}»</Title>
      <Input
        required
        fieldName='name'
        toolTip={false}
        title={t("NAME")}
        placeholder=''
        customStyles={{width:'360px'}}
        {...formikProps}
      />
      <Input
        isTextArea
        toolTip={false}
        fieldName='description'
        title={t("DESCRIPTION")}
        placeholder=''
        customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
        {...formikProps}
      />
      <Select
        required
        toolTip={false}
        data={algo}
        selectedValue={values['lb_algorithm']}
        onChange={(e) => handleChange('lb_algorithm')(e)}
        title={t("ALGORITHM")}
        customStyles={{ width:'360px'}}
        fieldName="lb_algorithm"
        {...formikProps}
      />
      <div style={{display:'flex', gap:'20px'}}>
      <Select
        toolTip={false}
        data={sessionPersistance}
        selectedValue={values['persistance']}
        onChange={(e) => handleChange('persistance')(e)}
        title={t("SESSION_PERCENTILE")}
        customStyles={{width:'360px'}}
      />
        {values['persistance'] === 'APP_COOKIE' ? 
          <Input
            required
            fieldName='cookie_name'
            toolTip={false}
            title={t("COOKIE_NAME")}
            placeholder=''
            customStyles={{width:'170px'}}
            {...formikProps}
          />
      : null}
      </div>
      <Toggle value={admin} onChange={() => setAdmin(!admin)} title={t("ADMIN_STATE_UP")}/>
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("SAVE")}
          loading={loading}
          loadingComplete={loadingComplete}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => {}}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default PoolDetails