import React, { FC, useEffect, useRef, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { instanceService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import TopProgress from "../../TopBarProgress";
import MatchPassword from "../../password-matches";
import Input from "../../input";


type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  instanceId?:string;
  loading?:boolean;
  instance?:any;
  alertTitle?:string;
}  


const PasswordConfirm:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    loading,
    instance
  } = props

  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const InputRef:any = useRef()
  const [activeConfirm, setActiveConfirm] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({
    password:'',
  })
  const onSubmit = (values:any) => {
    closeModal(values)
  }

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      password: yup.string().required(`${t("REQUIRED_FIELD")}`),
      otp: instance.google ? yup.string().required(`${t("REQUIRED_FIELD")}`) : yup.string()
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {t("CONFRIM_PASSWORD")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Input
          required
          title={t('PASSWORD')}
          fieldName='password'
          placeholder={t('PASSWORD')}
          onFocus={() => setActiveConfirm(true)}
          onFocusOut={() => setActiveConfirm(false)}
          customStyles={{width:'360px'}}
          onBlur={handleBlur}
          ref={InputRef}
          isPassword
          {...formikProps}
        />
        {instance.google &&
        <Input
          required
          title={t('OTP_CODE')}
          fieldName='otp'
          placeholder={t('OTP_CODE')}
          onFocus={() => setActiveConfirm(true)}
          onFocusOut={() => setActiveConfirm(false)}
          customStyles={{width:'360px'}}
          {...formikProps}
        />
        }
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("CONFIRM")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default PasswordConfirm