import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { instanceService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import TopProgress from "../../TopBarProgress";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  instanceId?:string;
  alertTitle?:string;
  loading?:boolean;
}  


const DissociateIp:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instanceId,
    alertTitle,
    loading
  } = props
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [ips, setIps] = useState<any[]>([])
  const [initialValues, setInitialValues] = useState<any>({
    ip:'',
  })
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  useEffect(() => {
    if(instanceId){
      instanceService.dissociateIpOptions(instanceId)
      .then((res) => {
        setIps(res.data.ips.map((ip:any) => {
          return {
            value:ip.floating_ip_address,
            label:ip.floating_ip_address
          }
        }))
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message:e?.response?.data?.detail
          })
          closeModalCancel()
        }
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
  },[])
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      ip: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {t("DISSOCIATE_IP_FROM")} «{alertTitle}»
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Select
          required
          toolTip={true}
          data={ips}
          test_id="floating_ip-select"
          selectedValue={values['ip']}
          onChange={(e) => {
            handleChange('ip')(e)}}
          title={t("ASSOCIATED_FLOATING_IPS")}
          customStyles={{width:'360px'}}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("DISSOCIATE_IP")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default DissociateIp