import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  InvoiceContainer,
  InfoComponent,
  InvoicesWrapper,
  LinkText,
  PriceWrapper,
  PriceContainer
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import { useAuth } from "../../../context/user.context";
import { billingService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import CheckBox from "../../checkbox";
import {formatCurrency} from '../../../utils/display-price'
import TopProgress from "../../TopBarProgress";
import Label from "../../label";
import { useNavigate } from "react-router-dom";
            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
}  

const PayMultipleInvoices:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    loading
  } = props
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {viewAlert} = useUi()
  const {user} = useAuth()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [invoices, setInvoices] = useState<any[]>([])
  const onSubmit = () => {
    closeModal(invoices)
  }

  const handleChangeStatus = (id:string|number) => {
    setInvoices((prev:any) => {
      return prev.map((item:any) => {
        return item.id === id ? {...item, selected:!item.selected} : item
      })
    })
  }
  useEffect(() => {
    billingService.getUnpaidInvoices(alertTitle)
    .then((res) => {
      if(res.data.totalCount === 1){
        closeModalCancel()
        setTimeout(() => {
          navigate(`/billing/invoices/${res.data.objects?.[0]?.id}`)
        })
      } else {
        setInvoices(res.data.objects.map((obj:Object) => {
          return {
            ...obj,
            selected:true,
          }
        }))
      }
    })
    .catch((err) => {
      if(err?.response?.data?.detail) {
        viewAlert({
          severity: 'error',
          message:err.response.data.detail
        })
      }
      closeModalCancel()
    })
    .finally(() => {
      setPageLoading(false)
    })
  },[])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("PAY_MULTIPLE_INVOICES")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>

      <BodyModal>
        <InfoComponent>
          <Label
            title={t("SELECTING_MULTIPLE_INVOICES_INFO")}
            reverse
            thinText
          />
        </InfoComponent>
        <InvoicesWrapper>
          <Typography variant='body-medium'>
            {t("SELECT_THE_INVOICES_YOU_WANT_TO_PAY")}
          </Typography>
          {invoices.map((invoice) => (
            <InvoiceContainer
              // onClick={() => handleChangeStatus(invoice.id)}
                key={invoice.id}
              >
              <PriceWrapper>
                <CheckBox
                  customStyles={{margin:''}}
                  title={''}
                  value={invoice.selected}
                  onChange={() => handleChangeStatus(invoice.id)}
                />
                <Typography variant='body-medium'>
                  {invoice.id}
                </Typography>
                <PriceContainer>
                  <Typography variant='very-small'>
                    {formatCurrency(invoice.balance, invoice.currency)}
                  </Typography>
                </PriceContainer>
              </PriceWrapper>
              <LinkText to={`/billing/invoices/${invoice?.id}`}>
                {t("DETAILS")}
              </LinkText>
            </InvoiceContainer>
          ))}
        </InvoicesWrapper>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={onSubmit}
            title={`${t("PAY")} ${alertTitle}`}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default PayMultipleInvoices