import React, { FC, useEffect, useState } from "react";
import {
  Container
} from './styles'
import { Table, TopProgress, Typography } from "../../../../components";
import { useTranslation } from "react-i18next";
import { bareMetalService } from "../../../../api";
import { useParams } from "react-router-dom";
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";



const SystemLogs:FC<any> = () => {
  const [t] = useTranslation()
  const [logs, setLogs] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const {baremetalId} = useParams()
  useEffect(() => {
    bareMetalService.getLogs(baremetalId)
    .then((res:any) => {
      setLogs(res.data.map((log:any) => {
        return {
          ...log,
          created_at:timeConverterMMDDYYHHMM(log.created_at)
        }
      }))
    })
    .catch((err:any) => {
      console.log('err',err);
    })
    .finally(() => {
      setPageLoading(false)
    })
  },[])

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      <Typography variant="body">
        {t("LOGS_TITLE")}
      </Typography>
      <Table
        columns={[
          {
            title:t("DATE"),
            key:'created_at',
          },
          {
            title:t("NAME"),
            key:'description',
          },
          // {
          //   title:t("IP_ADDRESS"),
          //   key:'user_ip',
          // },
        ]}
        rows={logs}
        hideOther
        hideCheckbox
        // paginationData={paginationData}
        // settings={items}
        // allChecked={allChecked}
        // setAllChecked={setAllChecked}
        // checked={checked}
        // setChecked={editChecked}
        // updateData={_updateBackups}
      />
    </Container>
  )
}

export default SystemLogs