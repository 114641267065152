import React, { FC, useEffect, useState } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { useUi } from "../../../context/ui.context";
import CheckBox from "../../checkbox";
import Input from "../../input";
import { RestoreVolumeBackups } from "../modal-types";
import { imageService, volumeService } from "../../../api";
import TopProgress from "../../TopBarProgress";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  alertTitle?:string;
  loading?:boolean;
  dataToEdit?:any;
  backupId?:any;
}  


const CopyImage:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    loading,
    backupId,
    alertTitle
  } = props
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    destination_name:alertTitle || '',
  })
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [regions, setRegions] = useState<any[]>([])
  const [deleteSource, setDeleteSource] = useState<boolean>(false)
  const onSubmit = (values:any) => {
    const data = {
      ...values,
      destination_region_id:regions?.[0]?.id,
      delete_source_image:deleteSource
    }
    closeModal(data)
  }
  useEffect(() => {
    imageService.getRegions()
    .then((res) => {
      setRegions(res.data.objects.map((region:any) => {
        return {
          ...region,
          label: region.id,
          value: region.id
        }
      }))
      setPageLoading(false)
    })
    .catch((e) => {
      if(e?.response?.data?.detail){
        viewAlert({
          severity:"error",
          message:e.response?.data?.detail
        })
      }
      closeModalCancel()
    })
  },[])
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      destination_name: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  

  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px', wordBreak:'break-word'}}>
          {t("COPY_IMAGE")} «{alertTitle}»
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Input
          required
          title={t('NAME')}
          fieldName='destination_name'
          placeholder={''}
          onBlur={handleBlur}
          customStyles={{width:'360px'}}
          {...formikProps}
        />
        <CheckBox
          title={t("DELETE_SOURCE_IMAGE")}
          value={deleteSource}
          onChange={() => setDeleteSource(!deleteSource)}
          test_id="delete-default-image"
        />
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("DOUBLE_IMAGE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default CopyImage