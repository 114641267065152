import React, { FC } from "react";
import {
  Container,
  TitleWrapper,
  Title,
  LinkStyled,
  TutorItem,
  TutorTitle,
  TutorTitleWrapper,
  TutorContent,
  TutorIcon,
} from "./styles";
import TutorialIcon from './tutorial.svg'
import DocIcon from './document.svg'
import QuestionIcon from './question.svg'
import TutorialIconDark from './dark-tutorial.svg'
import DocIconDark from './dark-document.svg'
import QuestionIconDark from './dark-question.svg'
import { useUi } from "../../../../context/ui.context";
import { useTranslation } from "react-i18next";
import rootConfig from "../../../../config";
type ItemProps = {
  item:any
}
const Tutorials:FC = () => {
  const [t] = useTranslation()
  const data = [
    {
      title:t("PRODUCT_DOCS"),
      id:'1',
      content:t("PRODUCT_DOCS_DECRIPTION"),
      icon:DocIcon,
      dark:DocIconDark
    },
    {
      title:t("TUTORIALS"),
      id:'2',
      content:t("TUTORIALS_DECRIPTION"),
      icon:TutorialIcon,
      dark:TutorialIconDark
    },
    {
      title:t("QUESTIONS"),
      id:'3',
      content:t("QUESTIONS_DECRIPTION"),
      icon:QuestionIcon,
      dark:QuestionIconDark
    },
  ]
  return (
    <Container>
      <TitleWrapper>
        <Title>
          {t("GETTING_STARTED")}
        </Title>
        {/* <LinkStyled onClick={() => window.open('https://docs.onecloudplanet.com/')}>
          {t("GO_TO_FAQ")}
        </LinkStyled> */}
      </TitleWrapper>
      <div style={{display:'flex', flexWrap:"wrap", gap:'10px'}}>
        {data.map((item:any) => (
          <TutorItemView key={item.id} item={item}/>
        ))}
      </div>
    </Container>
  )
}


export default Tutorials

const TutorItemView:FC<ItemProps> = ({item}) => {
  const {themeDark} = useUi()
  return(
    <TutorItem onClick={() => window.open(rootConfig.docs_url)} key={item.id}>
      <TutorTitleWrapper>
        <TutorTitle>
          {item.title}
        </TutorTitle>
        <TutorContent>
          {item.content}
        </TutorContent>
      </TutorTitleWrapper>
      <TutorIcon src={themeDark ? item.dark : item.icon}/>
    </TutorItem>
  )
}

