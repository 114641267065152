import styled from "styled-components";
import { BodyDefault, VerySmall } from "../typography/styled";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

type RowProps = {
  back?:boolean,
  isProps?:boolean,
  hover?:boolean,
  hasDisplayTask?:boolean,
}
type ActiveProp = {
  active?:boolean,
  isSmall?:any
  ref?:any
}
type TitleProp = {
  withBack?:boolean
  isNotifications?:boolean,
  isRead?:boolean,
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border:${({theme}) => `1px solid ${theme.palette[20]}`};
  border-radius:4px;
  width:100%;
  /* overflow: hidden; */
`

export const Head = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  padding-left: 10px;
  background-color: ${({theme}) => theme.palette[200]};
`

export const TableTitle = styled(VerySmall)`
  color:${({theme}) => theme.palette[900]};
  margin-right: 2px;
`

export const BodyTitle = styled(BodyDefault)<TitleProp>`
  color:${({theme}) => theme.base.black};
  letter-spacing: 0px;
  background-color: ${({withBack, theme}) => withBack ? theme.grey[300] : ''};
  border-radius: 4px;
  padding:${({withBack}) => withBack ? '3px 4px' : ''};
  font-size:${({withBack}) => withBack ? '12px' : ''};
  line-height:${({withBack}) => withBack ? '15px' : ''};
  font-weight: ${({isNotifications, isRead}) => isNotifications && !isRead ? '500' : ''};
  box-sizing: border-box;
  /* word-break: break-word; */
  max-width: 400px;
  padding-right: 20px;
  text-overflow: ellipsis;

`
export const BodyName = styled(BodyDefault)<TitleProp>`
  color:${({theme}) => theme.primary[600]};
  letter-spacing: 0px;
  min-width: 100%;
  display: flex;
  align-items: center;
  font-weight: ${({isNotifications}) => isNotifications ? '500' : ''};
  gap:8px;
  margin-left: ${({isRead}) => isRead ? '16px' : '0px'};
  max-width:250px;
  word-break: break-word;
  padding-right: 20px;
  box-sizing: border-box;
`

export const BodyNameLink = styled(Link)<TitleProp>`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin:0px;
  padding:0px;
  color:${({theme}) => theme.primary[600]};
  letter-spacing: 0px;
  min-width: 100%;
  display: flex;
  align-items: center;
  font-weight: ${({isNotifications}) => isNotifications ? '500' : ''};
  gap:8px;
  margin-left: ${({isRead}) => isRead ? '16px' : '0px'};
  max-width:250px;
  word-break: break-word;
  padding-right: 20px;
  box-sizing: border-box;
  text-decoration: none;
  &:hover {
    cursor:pointer;
    /* background-color:${({theme}) => theme.grey[100] }; */
  }
`
export const ColumnWrapper = styled.div`
  display:flex;
  flex-direction: column;
  /* flex: auto 1; */
`
export const Row = styled.td<RowProps>`
  background-color: ${({theme, hover, hasDisplayTask}) => hasDisplayTask ? theme.isDark ? 'rgba(58, 54, 31, 1)' : 'rgba(255, 247, 225, 1)' : hover ? theme.grey[100] :  theme.base.white};
  border-bottom: ${({theme, back}) => !back ? `1px solid ${theme.grey[300]}` : ''};
  border-top: ${({theme, back}) => !back ? `1px solid ${theme.grey[300]}` : ''};
  /* display: flex; */
  align-items:flex-start;
  flex-direction: column;
  /* width:100%; */
  /* flex: auto 1; */
  /* align-items: flex-start; */
  /* box-sizing:border-box; */
  justify-content: ${({isProps}) => isProps ? 'flex-end' : 'flex-start'};
  /* transform: ${({isProps}) => isProps ? 'translate(50%)' : ''}; */
  min-height: 42px;
  padding:11px 11px 11px 10px;
  /* box-sizing:border-box; */
  position:relative;
  vertical-align:top;

`
export const ListWrapper = styled.div<TitleProp>`
  display: flex;
  flex-direction: ${({withBack, theme}) => withBack ? 'row' : 'column'};
  gap: ${({withBack, theme}) => withBack ? '6px' : ''};
  flex-wrap: wrap;
  /* margin-bottom:11px; */
  /* height:42px; */
`
export const TableTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  &:hover{
    cursor: pointer;
  }
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
  width: 100%;
`

export const PropsContainer = styled.div<ActiveProp>`
  box-sizing: border-box;
  padding: 4px 0px;
  right:13px;
  /* top: 30px; */
  z-index: 10;
  position: absolute;
  background-color: ${({theme}) => theme.palette[10]};
  width: 240px;
  /* height: 224px; */
  border:${({theme}) => `1px solid ${theme.grey[350]}`};
  border-radius:4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.1);
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  opacity:${({active}) => active ? '1' : '0'};
  transform-origin: top right;
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
`

export const TableCustom = styled.table`
  width: 100%;
  border-collapse:collapse!important;
  border:${({theme}) => `1px solid ${theme.palette[20]}`};
  border-radius:4px!important;
  /* display:flex; */
  /* flex-direction:column; */

`;

export const Tr = styled.tr`
  /* display: flex;
  flex-direction: column; */
`


type ActivePropImg = {
  active?:boolean,
  disable?:boolean,
  hover?:boolean,
}
export const StyledIconMore = styled(SVG)<ActivePropImg>`
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  & path {
    fill: ${({ theme }) => theme.base.black};
  }
  opacity: ${({hover, disable}) => hover || disable ? '0.7' : ''};
  filter: ${({active, disable}) => active && !disable ? 'brightness(77%)' : ''};
  &:hover {
    cursor: pointer;
    opacity: ${({hover}) => hover ? '' : '0.7'};
  }
  &:active{
    filter: ${({disable}) => !disable ? 'brightness(77%)' : ''};
  }
`
