import styled from "styled-components";
import { BodyDefault, BodySmall, HeadLine1 } from '../../../components/typography/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 30px 30px;
  position: relative;
`
export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
`

export const RightHeadContainer = styled.div`
  display:flex;
  margin-right: 10px;
  position: relative;
`

export const InstanceName = styled(HeadLine1)`
  color:${({theme}) => theme.palette[800]};
  margin-top:10px;
  margin-bottom:10px;
  max-width:500px;
  word-break: break-word;
`
export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  display: flex;
  margin-top:50px;
  &>div{
    position: absolute;
    bottom:-1px;
    left:0px;
  }
`
export const InstanceHeadInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
export const InstanceDot = styled.div`
  width:12px;
  height:12px;
  background-color: #229B61;
  border-radius: 100%;
`
export const InstanceStatus = styled(BodySmall)`
  color:#229B61;
  margin-left: 6px;
  margin-right: 20px;
`

export const InstanceType = styled(BodySmall)`
  margin-right: 6px;
  color:${({theme}) => theme.palette[650]};
`
export const NetworkContainer = styled.div`
  display:flex;
  flex-direction: column;
  flex-wrap:wrap;
  /* flex:0 0; */
  padding: 6px 8px;
  flex: none;
  order: 5;
  box-sizing: border-box;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
`
export const Subnet = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
`