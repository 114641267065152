import React, { FC, useState, useEffect } from 'react'
import {
  ConfirmPassordContainer,
  PasswordDash,
  ConfirmPasswordTitle,
  ConfirmPasswordItem,
  DotItem,
  Rect,
  ItemWrapper,
  CheckedBackground,
  DotWrapper,
  ConfirmPasswordItemsTitle,
} from './styles'
import CheckedIcon from './checked.svg'
import { useTranslation } from 'react-i18next'

type PropTypes = {
  active?:boolean,
  setEnabled:(enable:boolean) => void,
  validateString:string,
}

const MatchPassword:FC<PropTypes> = (props) => {
  const {
    active,
    setEnabled,
    validateString,
  } = props
  const [t] = useTranslation()
  const regLetter = /^(?=.*[a-z])(?=.*[A-Z])/
  const regNumbers = /^(?=.*[0-9])/
  const regSymbols = /^(?=.*[!@#$%^&*()_=+§`~<>?])/
  const [titleStatus, setTitleStatus] = useState<string>(`${t('PASSWORD_CONFIRM_TITLE')}`)
  const [statusConfirm, setStatusConfirm] = useState<any>({
    upper:false,
    number:false,
    longer:false
  })
  const [dashInfo, setDashInfo] = useState<any>({
    type:'',
    count: 0,
  })
  useEffect(() => {
    if(dashInfo.count === 0){
      setTitleStatus(`${t('PASSWORD_CONFIRM_TITLE')}`)
      setEnabled(false)
    }
    if(dashInfo.count === 1){
      setTitleStatus(`${t('WEAK_PASSWORD')}`)
      setEnabled(false)
    }
    if(dashInfo.count === 2){
      setTitleStatus(`${t('AVARAGE_PASSWORD')}`)
      setEnabled(false)
    }
    if(dashInfo.count === 3){
      setTitleStatus(`${t('GOOD_PASSWORD')}`)
      setEnabled(false)
    }
    if(dashInfo.count === 4){
      setTitleStatus(`${t('STRONG_PASSWORD')}`)
      setEnabled(true)
    }
  },[dashInfo])
  useEffect(() => {
    if(validateString.match(regLetter)){
      setStatusConfirm((prev:any) => {
        return {
          ...prev,
          upper:true,
        }
      })
    }
    if(validateString.match(regNumbers) && validateString.match(regSymbols)){
      setStatusConfirm((prev:any) => {
        return {
          ...prev,
          number:true,
        }
      })
    }
    if(validateString.length > 6){
      setStatusConfirm((prev:any) => {
        return {
          ...prev,
          longer:true,
        }
      })
    }
    if(validateString.length > 0){
      setDashInfo((prev:any) => {
        return {
          type:'orange',
          count:1,
        }
      })
    }
    /////// to false 
    if(validateString.match(regLetter) === null){
      setStatusConfirm((prev:any) => {
        return {
          ...prev,
          upper:false,
        }
      })
    }
    if(validateString.match(regNumbers) === null || validateString.match(regSymbols) === null){
      setStatusConfirm((prev:any) => {
        return {
          ...prev,
          number:false,
        }
      })
    }
    if(validateString.length < 6){
      setStatusConfirm((prev:any) => {
        return {
          ...prev,
          longer:false,
        }
      })
    }
    if(validateString.length === 0){
      setDashInfo((prev:any) => {
        return {
          type:'default',
          count:0,
        }
      })
    }
  },[validateString])

  useEffect(() => {
    if(validateString.length === 0) {
      setDashInfo({
        type:'default',
        count:0,
      })
    } else {
      setDashInfo((prev:any) => {
        let count = Object.values([
          statusConfirm.upper,
          statusConfirm.number,
          statusConfirm.longer
        ]).reduce((a:any, item:any) => a + item, 0) + 1;
        return {
          type: count === 2 ? 'yellow' : count >= 3 ? 'green' : 'orange',
          count:count,
        }
      })
    }
  }, [statusConfirm])
  return(
    <ConfirmPassordContainer active={active}>
      <ConfirmPasswordTitle>
        {titleStatus}
      </ConfirmPasswordTitle>
      <ItemWrapper>
        <PasswordDash 
          type={dashInfo.count >= 1 ? dashInfo.type : 'default'}
        />
        <PasswordDash 
          type={dashInfo.count >= 2 ? dashInfo.type : 'default'}
        />
        <PasswordDash 
          type={dashInfo.count >= 3 ? dashInfo.type : 'default'}
        />
        <PasswordDash 
          type={dashInfo.count === 4 ? dashInfo.type : 'default'}
        />
      </ItemWrapper>
      <ConfirmPasswordItemsTitle>
        {t('CONFIRM_LIST')}
      </ConfirmPasswordItemsTitle>
      <ItemWrapper style={{marginTop:'5px'}}>
        {statusConfirm.upper ? 
          <CheckedBackground>
            <img src={CheckedIcon}/>
          </CheckedBackground>
        :
          <DotWrapper>
            <DotItem/>
          </DotWrapper>
        }
        <ConfirmPasswordItem active={statusConfirm.upper}>
          {t('UPPER_CASE_ITEM')}
        </ConfirmPasswordItem>
      </ItemWrapper>
      <ItemWrapper>
        {statusConfirm.number ? 
          <CheckedBackground>
            <img src={CheckedIcon}/>
          </CheckedBackground>
        :
          <DotWrapper>
            <DotItem/>
          </DotWrapper>
        }
        <ConfirmPasswordItem active={statusConfirm.number}>
          {t('SYMBOL_ITEM')}
        </ConfirmPasswordItem>
      </ItemWrapper>
      <ItemWrapper>
        {statusConfirm.longer ? 
          <CheckedBackground>
            <img src={CheckedIcon}/>
          </CheckedBackground>
        :
          <DotWrapper>
            <DotItem/>
          </DotWrapper>
        }
        <ConfirmPasswordItem active={statusConfirm.longer}>
          {t('LONGER_PASSWORD')}
        </ConfirmPasswordItem>
      </ItemWrapper>
      <Rect active={active}/>
    </ConfirmPassordContainer>
  )
}

export default MatchPassword