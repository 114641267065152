import React, { FC } from 'react'
import {Container, StyledCheckBox, CheckBoxImg} from './styles'
import CheckIcon from './check.svg'
import Label from '../label'
import styles from './styles.module.css'
type CheckBoxProps = {
  title:string|any,
  value:boolean|string,
  onChange:() => void,
  disabled?:boolean,
  customStyles?:any,
  toolTip?:boolean,
  withoutText?:boolean,
  toolTipText?:string,
  inYup?:boolean,
  test_id?:string,
}

const CheckBox:FC<CheckBoxProps> = (props) => {
  const {
    title,
    value,
    onChange,
    disabled,
    customStyles,
    toolTip=false,
    toolTipText,
    inYup,
    test_id
  } = props
  
  return(
    <Container onClick={onChange} data-test-id={test_id} customStyles={customStyles}>
      {/* {(inYup ? value === 'true' : value) ? 
        <CheckBoxImg  src={CheckIcon}/>
      : */}
      {/* @ts-ignore */}
        <StyledCheckBox className={styles.checkbox} checked={(inYup ? value === 'true' : value)} type='checkbox' disabled={disabled} />
      {/* } */}
      {title.length === 0 ? null : <Label toolTip={toolTip} title={title} tooltipText={toolTipText} margin='10px' />} 
    </Container>
  )
}

export default CheckBox