import React, { FC, useState, useEffect } from "react";
import {
  Container 
} from './styles'
import {
  calculateScales,
  createPercentageArrays,
} from './metricFuncs'
import { bareMetalService } from "../../../../api";
import MetricsChart from "../../../../components/metrics";
import { useTranslation } from "react-i18next";
import { Button, ModalVariables, TopProgress } from "../../../../components";
import { useParams } from "react-router-dom";
import kek from './trafic.json'


const Metrics:FC= () => {
  const [t] = useTranslation()
  const {baremetalId} = useParams()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [networkMetrics, setNetworkMetrics] = useState<any[]>([])
  const [networkMetricsOut, setNetworkMetricsOut] = useState<any[]>([])
  const [scales, setScales] = useState<any[]>([])
  const [preLoadMetrics, setPreLoadMetrics] = useState<boolean>(true)
  const [loadNetwork, setLoadNetwork] = useState<boolean>(false)
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)


  const updateNetworks = (hours:any='24h', type?:any) => {
    setLoadNetwork(true)
    bareMetalService.getMetrics(baremetalId, {predefined:hours})
    .then((res) => {
      setScales(calculateScales(res?.data?.[0]?.stats))
      setNetworkMetrics(createPercentageArrays(res?.data?.[0]?.stats, type === '2' ? 'bwrate_in' : 'traffic_in'))
      setNetworkMetricsOut(createPercentageArrays(res?.data?.[0]?.stats, type === '2' ? 'bwrate_out' : 'traffic_out'))
      setLoadNetwork(false)
    })
    .catch((err) => {
      console.log('err',err?.response?.data);
      setLoadNetwork(false)
    })
  }
  const _updateMetrics = (hours:any='24h') => {
    bareMetalService.getMetrics(baremetalId, {predefined:hours})
    .then((res:any) => {
      setPreLoadMetrics(false)
      //@ts-ignore
      setScales(calculateScales(res?.data?.[0]?.stats))
      setNetworkMetrics(createPercentageArrays(res?.data?.[0]?.stats, 'bwrate_in'))
      setNetworkMetricsOut(createPercentageArrays(res?.data?.[0]?.stats, 'bwrate_out'))
    })
    .catch((err:any) => {
      console.log('err',err);
      setPreLoadMetrics(false)
    })

  }
  // console.log('kek',kek);
  useEffect(() => {
    _updateMetrics()
    // const interval = setInterval(() => {
    //   _updateMetrics()
    // }, 5000)
    // return () => {
    //   clearInterval(interval)
    // }
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const data = [
    {label:t('BANDWIDTH_RATE'), value:'1'},
    {label:t('TRAFFIC'), value:'2'}
  ]
  const closeModal = async (values:any) => {}
  if(preLoadMetrics) return <TopProgress loading={preLoadMetrics}/>
  // if(networkMetrics?.length === 0 && cpuMetrics?.length === 0) return(
  //   <Container>
  // <BodyDefault>
  //   {t("HAS_NO_METRICS_YET")}
  // </BodyDefault>
  //   </Container>
  // )
  const tabs = [
    {
      title:t("24_HOURS"),
      value:'24h',
      id:'1'
    },
    {
      title:t("14_DAYS"),
      value:'14days',
      id:'2'
    },
    {
      title:t("30_DAYS"),
      value:'30days',
      id:'3'
    },
    {
      title:t("ALL"),
      value:'all',
      id:'4'
    }
  ]
  return(
    <Container>
      <Button
        title={t("BREAKDOWN")}
        onClick={() => openModal('baremetal-breakdown')}
        variant="primary"
        size='display'
      />
      <MetricsChart
        updateData={_updateMetrics}
        title={t("NETWORK_METRIC")}
        scales={scales}
        data={networkMetrics}
        dataSecond={networkMetricsOut}
        loading={loadNetwork}
        customTabs={tabs}
        isBareMetal
        customDataSelect={data}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        instanceId={baremetalId}
        loading={loading}
      />
    </Container>
  )
}

export default Metrics