import styled from 'styled-components'

export const RightSide = styled.div`
  background-position: right center;
  /* background-size: 100%; */
  display: flex;
  box-sizing: border-box;
  position: relative;
  background-color: ${({theme}) => theme.grey[300]};
  justify-content: center;
  /* align-items: center; */
  width:90vw;
  height: 100%;
  padding: 82px;
  border-radius: 30px;
  background-repeat: no-repeat;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`