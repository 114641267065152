export function transformHitsToCategories(hits:any) {
  const categories:any[] = [];

  hits.forEach((hit:any) => {
    const hierarchy = hit.hierarchy;
    const lvl0 = hierarchy.lvl0;
    const lvl1 = hierarchy.lvl1;
    const lvl2 = hierarchy.lvl2;
    const lvl3 = hierarchy.lvl3;

    let category = categories.find(cat => cat.title === lvl0);

    if (!category) {
      category = {
        title: lvl0,
        value: []
      };
      categories.push(category);
    }

    let dataItem = category.value.find((item:any) => item.title === lvl1);

    if (!dataItem) {
      dataItem = {
        title: lvl1,
        url: hit.url,
        url_without_anchor: hit.url_without_anchor,
        anchor: hit.anchor,
        content: hit.content,
        type: hit.type,
        objectID: hit.objectID,
        _highlightResult: hit._highlightResult,
        value: []
      };
      category.value.push(dataItem);
    }

    if (lvl2 && lvl3) {
      let level2Item = dataItem.value.find((item:any) => item.title === lvl2);

      if (!level2Item) {
        level2Item = {
          title: lvl2,
          url: hit.url,
          url_without_anchor: hit.url_without_anchor,
          anchor: hit.anchor,
          content: hit.content,
          type: hit.type,
          objectID: hit.objectID,
          _highlightResult: hit._highlightResult,
          value: []
        };
        dataItem.value.push(level2Item);
      }

      level2Item.value?.push({
        title: lvl3,
        url: hit.url,
        url_without_anchor: hit.url_without_anchor,
        anchor: hit.anchor,
        content: hit.content,
        type: hit.type,
        objectID: hit.objectID,
        _highlightResult: hit._highlightResult
      });
    } else if (lvl2) {
      dataItem.value.push({
        title: lvl2,
        url: hit.url,
        url_without_anchor: hit.url_without_anchor,
        anchor: hit.anchor,
        content: hit.content,
        type: hit.type,
        objectID: hit.objectID,
        _highlightResult: hit._highlightResult
      });
    } else {
      dataItem.value.push({
        title: lvl1,
        url: hit.url,
        url_without_anchor: hit.url_without_anchor,
        anchor: hit.anchor,
        content: hit.content,
        type: hit.type,
        objectID: hit.objectID,
        _highlightResult: hit._highlightResult
      });
    }
  });

  return categories;
}
