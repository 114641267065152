import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoBlock } from '../../../../components'
import {
  Container,
  TopInfoWrapper
} from '../pools/styles'
import {timeConverterMMDDYYHHMM} from '../../../../utils/dates'
import { handleStatusName } from '../../../../utils/handle-status-name'
import { GreenDot, Greentext, StateWrapper } from '../../../Instances/styles'

const Overview:FC<any> = ({lb}) => {
  const [t] = useTranslation()
  const specifications = [
    {
      title:t("ID"),
      id:'1',
      value:lb?.id || '-'
    },
    {
      title:t("NAME"),
      id:'2',
      value:lb?.NAME || 'n/a'
    },
    {
      title:t("DESCRIPTION"),
      id:'3',
      value: lb?.description || 'n/a'
    },
    {
      title:t("PROTOCOL"),
      id:'4',
      value: lb?.protocol || 'n/a'
    },
    {
      title:t("PORT"),
      id:'14',
      value:lb?.protocol_port || 'n/a'
    },
    {
      title:t("OPERATING_STATUS"),
      id:'5',
      value:(<StateWrapper>
              <GreenDot active={lb?.operating_status}/>
              <Greentext active={lb?.operating_status}>
                {handleStatusName(lb?.operating_status, t)}
              </Greentext>
            </StateWrapper>) ,
    },
    {
      title:t("PROVISIONING_STATUS"),
      id:'6',
      value: (<StateWrapper>
              <GreenDot active={lb?.provisioning_status}/>
              <Greentext active={lb?.provisioning_status}>
                {handleStatusName(lb?.provisioning_status, t)}
              </Greentext>
            </StateWrapper>),
    },
    {
      title:t("ADMIN_STATE"),
      id:'7',
      value:lb?.admin_state_up ? 'Yes' : 'No',
    },
    {
      title:t("CREATED_AT"),
      id:'8',
      value:timeConverterMMDDYYHHMM(lb?.created_at)
    },

    {
      title:t("UPDATED_AT"),
      id:'9',
      value:timeConverterMMDDYYHHMM(lb?.updated_at)
    },
    {
      title:t("CONNECTION_LIMIT"),
      id:'10',
      value:lb?.connection_limit || '-',
    },
    // {
    //   title:t("INSERT_HEADERS"),
    //   id:'11',
    //   value:lb?.insert_headers || '-'
    // },
    {
      title:t("DEFAULT_POOL_ID"),
      id:'12',
      value:lb?.default_pool_id || '-',
      copy:true,
    },
    {
      title:t("CLIENT_DATA_TIMEOUT"),
      id:'13',
      value:lb?.timeout_client_data || '-'
    },
    {
      title:t("MEMBER_CONNECT_TIMEOUT"),
      id:'14',
      value:lb?.timeout_member_connect || '-'
    },
    {
      title:t("MEMBER_DATA_TIMEOUT"),
      id:'15',
      value:lb?.timeout_member_data || '-'
    },
    {
      title:t("TCP_INSPECT_TIMEOUT"),
      id:'16',
      value:lb?.timeout_tcp_inspect || '-'
    },

  ]
  return(
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1'}}
        />
      </TopInfoWrapper>
    </Container>
  )
}
export default Overview
