import styled from 'styled-components'
import { BodyDefault, BodyMedium, BodySmall, BodySmallMedium } from '../../../components/typography/styled';
import { statusColor } from '../../../utils/statusColor';

type InstanceActive = {
  active: string;
}
type ImgProps = {
  active?:boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 30px 30px;
  position: relative;
  gap:10px;
  box-sizing: border-box;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-top: 10px;
  gap:10px;
`

export const OperationDot = styled.div<InstanceActive>`
  width:12px;
  height:12px;
  background-color: ${({active}) => statusColor(active)};
  border-radius: 100%;
`
export const OperationStatus = styled(BodySmall)<InstanceActive>`
  color:${({active}) => statusColor(active)};
  margin-left: 6px;
  margin-right: 5px;
  text-transform: capitalize;
`

export const OperationStatusContainer = styled.div`
  display:flex;
  align-items: center;
`

export const StepsTitleWrapper = styled.div`
  display:flex;
  gap:6px;
  align-items: center;
  margin-top: 2px;
`

export const StepsTitle = styled(BodyDefault)`
  color:${({theme}) => theme.grey[700]};
`
export const StepCount = styled.div`
  background-color: ${({theme}) => theme.palette[200]};
  border-radius: 4px;
  padding:2px 7px;
  display: flex;
  box-sizing: border-box;
`

export const StepCountNumber = styled(BodySmallMedium)`
  color:${({theme}) => theme.palette[800]};
`

export const StepsBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
  margin-top:10px;
`

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 8px;
  gap: 10px;
  /* width: 505px; */
  /* height: 40px; */
  /* Grey/200 */
  background: ${({theme}) => theme.palette[200]};
  border-radius: 4px;
  box-sizing: border-box;
`

export const StepName = styled(BodyMedium)`
  text-transform: capitalize;
`
export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 8px;
  gap: 6px;
  background: ${({theme}) =>  theme.errors[100]};
  border-radius: 4px;
`

export const ErrorText = styled(BodySmall)`
  color:${({theme}) =>  theme.errors[600]};
  text-transform: capitalize;
`
export const RotateImg = styled.img<ImgProps>`
  animation: ${({active}) => active ? 'rotating 0.8s linear infinite' : ''};
  @keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`