import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import TopProgress from "../../TopBarProgress";
import { objStorageService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import InfoBlock from "../../info-block";
import { formatFileSizeRounded } from "../../../utils/sizes";
import moment from "moment";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
}  

const MoreBucketInfo:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    loading
  } = props
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [fileData, setFileData] = useState<any>()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  useEffect(() => {
    objStorageService.getFileInfo(instance.bucketId, instance.name)
    .then((res) => {
      setFileData(res.data)
      setPageLoading(false)
    })
    .catch((err) => {
      console.log('err',err?.response?.data?.detail);
      viewAlert({
        severity:'error',
        message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    })
  },[])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("OBJECT_INFORMATION")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <InfoBlock
          customStyles={{width:'100%', display:'flex'}}
          title={''}
          data={[
            {
              title:t("NAME"),
              id:'11',
              value:instance?.name,
            },
            {
              title:'',
              id:'22',
              type:'divider'
            },
            {
              title:t("VISIBILITY"),
              id:'1',
              value:fileData.ACL,
            },
            {
              title:'',
              id:'2',
              type:'divider'
            },
            // {
            //   title:t("CLASS"),
            //   id:'33',
            //   value:'STANDARD'
            // },
            {
              title:t("LAST_MODIFIED"),
              id:'3',
              value: moment(fileData.LastModified).fromNow()
            },
            {
              title:'',
              id:'4',
              type:'divider'
            },
            {
              title:t("SIZE"),
              id:'4',
              value:formatFileSizeRounded(fileData.ContentLength),
            },
            {
              title:'',
              id:'44',
              type:'divider'
            },
            {
              title:t("OBJECT_METADATA"),
              id:'5',
              options:Object.keys(fileData.Metadata).map((key) => {
                return(
                  <div>
                    {key}={fileData.Metadata[key]}
                  </div>
                )
              })
            },
            {
              title:'',
              id:'6',
              type:'divider'
            }
            ,
            {
              title:t("OBJECT_TAGS"),
              id:'6',
              options:fileData.TagSet.map((tag:any) => {
                return(
                  <div>
                    {tag?.Key}={tag?.Value}
                  </div>
                )
              })
            }
          ]}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '100%', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CLOSE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default MoreBucketInfo