import styled from "styled-components";
import { BodyDefault } from "../../../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap:24px;
  align-items: flex-end;
  padding: 0px 30px 20px 30px;
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:6px;
`
export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const Subtitle = styled(BodyDefault)`
  color: ${({theme}) => theme.grey[700]};
`
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap:20px;
  padding: 0px 30px 20px 30px;
`