import React, { FC, useState } from "react";
import {Container} from './styles'
import { Button, Input, Select, Toggle } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from 'use-query-params';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useBalancer } from "../../../../context/balancerContext";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
const PoolDetails:FC<any> = ({isCreatePool}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {
    setDataToLs,
    getDataToLs,
  } = useLocalStorage()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [admin, setAdmin] = useState<boolean>(true)
  const {
    algo,
    sessionPersistance,
    setPoolDetails,
    listenersProtocols
  } = useBalancer()
  
  const [initialValues, setInitialValues ] = useState<any>({
    name:getDataToLs({key:'balancer.pool_details'})?.name ||'',
    description:getDataToLs({key:'balancer.pool_details'})?.description ||'',
    lb_algorithm:getDataToLs({key:'balancer.pool_details'})?.lb_algorithm ||'',
    persistance:getDataToLs({key:'balancer.pool_details'})?.persistance ||'',
    cookie_name:getDataToLs({key:'balancer.pool_details'})?.cookie_name ||'',
  })
  const onSubmit = (values:any) => {
    console.log('submit');
    const data = {
      ...values,
      admin_state_up:admin,
      session_persistence:{
        type:values.persistance
      }
    }
    if(values.persistance === 'APP_COOKIE'){
      data.session_persistence.cookie_name = values.cookie_name
    }
    setDataToLs({key:'balancer.pool_details', data})
    setPoolDetails(data)
    setStep('pool-remembers')
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: isCreatePool ? 
      yup.object().shape({
        protocol: yup.string().required(`${t('REQUIRED_FIELD')}`),
        name: yup.string().required(`${t('REQUIRED_FIELD')}`).matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
        description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
        lb_algorithm: yup.string().required(`${t('REQUIRED_FIELD')}`),
        persistance: yup.string(),
        cookie_name: yup.string().when("persistance", {
          is: (persistance:any) => persistance === 'APP_COOKIE',
          then: yup.string().required(`${t('REQUIRED_FIELD')}`)
        }),
      })
    :
      yup.object().shape({
        name: yup.string().required(`${t('REQUIRED_FIELD')}`).matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
        description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
        lb_algorithm: yup.string().required(`${t('REQUIRED_FIELD')}`),
        persistance: yup.string(),
        cookie_name: yup.string().when("persistance", {
          is: (persistance:any) => persistance === 'APP_COOKIE',
          then: yup.string().required(`${t('REQUIRED_FIELD')}`)
        }),
      }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  return (
    <Container>
      <Input
        required
        fieldName='name'
        toolTip={false}
        title={t("NAME")}
        placeholder=''
        customStyles={{width:'360px'}}
        {...formikProps}
      />
      <Input
        isTextArea
        toolTip={false}
        fieldName='description'
        title={t("DESCRIPTION")}
        placeholder=''
        customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
        {...formikProps}
      />
      <Select
        required
        toolTip={false}
        data={algo}
        selectedValue={values['lb_algorithm']}
        onChange={(e) => handleChange('lb_algorithm')(e)}
        title={t("ALGORITHM")}
        customStyles={{ width:'360px'}}
        fieldName="lb_algorithm"
        {...formikProps}
      />
      {isCreatePool &&
        <Select
          required
          toolTip={false}
          data={listenersProtocols}
          fieldName='protocol'
          selectedValue={values['protocol']}
          onChange={(e) => handleChange('protocol')(e)}
          title={t("PROTOCOL")}
          customStyles={{width:'360px', marginRight:'20px'}}
          {...formikProps}
        />
      }
      <div style={{display:'flex', gap:'20px'}}>
      <Select
        toolTip={false}
        data={sessionPersistance}
        selectedValue={values['persistance']}
        onChange={(e) => handleChange('persistance')(e)}
        title={t("SESSION_PERCENTILE")}
        customStyles={{width:'360px'}}
      />
        {values['persistance'] === 'APP_COOKIE' ? 
          <Input
            required
            fieldName='cookie_name'
            toolTip={false}
            title={t("COOKIE_NAME")}
            placeholder=''
            customStyles={{width:'170px'}}
            {...formikProps}
          />
      : null}
      </div>
      <Toggle value={admin} onChange={() => setAdmin(!admin)} title={t("ADMIN_STATE_UP")}/>
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep('listener-details')}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default PoolDetails