import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 30px;
  position: relative;
`
export const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 245.02px;
  gap:24px;
  background-color: ${({theme}) => theme.grey[100]};
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 20px;
  box-sizing: border-box; 
`