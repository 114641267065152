import styled, { css } from "styled-components";
import { BodyDefault, BodyMedium } from "../../components/typography/styled";

type ActiveProp = {
  active?:boolean;
}
type DashProp = {
  type?:'default' | 'orange' | 'yellow' | 'green'
}

export const Container = styled.div`
  display: flex;
  width: 100%;

`
export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:20px;
  max-width:360px;
`

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.primary[600]};
  width:90vw;
  padding: 60px 83px;
  border-radius: 30px;
  background-position: right bottom;
  /* background-attachment: local; */
  background-repeat: no-repeat;
  background-size: 91%;
  @media screen and (max-height: 800px) {
    background-image: none !important;
  }
  @media screen and (max-width: 1100px) {
    display: none;
  }
`

export const LeftSubTitle = styled(BodyDefault)`
  color:${({theme}) => theme.grey[600]};
  margin-bottom: 20px;
`
export const Terms = styled(BodyDefault)`
  color:${({theme}) => theme.grey[800]};
`
export const TermsWrapper = styled.div`
  display: flex;
  /* white-space: nowrap; */
  flex-wrap: wrap;
  margin-top: 24px;
`

export const RightTitle = styled.h2`
  font-weight: 500;
  font-size: 34px;
  line-height: 41px;
  color:${({theme}) => theme.base.white};
  white-space: nowrap;
  margin:0px;
  margin-top: 23px;
`

export const RightSubTitle = styled(BodyDefault)`
  color: rgba(255, 255, 255, 0.8);
  margin-top: 21px;
`
export const RightImage = styled.img`
  bottom: 0px;
  /* height:100%; */
  z-index: 1;
  width: 1440px;
  @media screen and (max-height: 600px) {
    display: none;
  }
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:100%;

`
export const RightImageWrapper = styled.div`
  /* padding-left: 83px; */
  bottom:0px;
  left:83px;
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 350px);
`

export const WhiteBottom = styled.div`
  position: absolute;
  background-color: ${({theme}) => theme.base.white};
  /* height: 80px; */
  bottom:-80px;
  width: 100%;
  z-index: 9;
`
export const WhiteRight = styled.div`
  position: absolute;
  background-color: ${({theme}) => theme.base.white};
  height: 94%;
  /* bottom:-80px; */
  right:-20px;
  width: 20px;
  z-index: 9;
`

export const PasswordWrapper = styled.div`
  position: relative;
`