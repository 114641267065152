export function fileRequestHandler(source:any, data:any) {
  let headers:any = {};
  let body = data;

  // if (source === 'url') {
  //   headers['Content-Type'] = 'application/x-www-form-urlencoded';
  //   body = JSON.stringify(data);
  // } else if (source === 'file') {
    headers['Content-Type'] = 'multipart/form-data';
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    body = formData;
  // }

  return {
    body: body,
    headers: headers
  };
}