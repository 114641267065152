import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  BackArrow,
  Input,
  Select,
  Button,
  TopProgress,
  LoaderSpinner,
  Typography,
  RadioButtonGroup
} from '../../../components'
import {
  Container,
  TitleBig,
  CreateContainer,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
  StepsWrapper,
  StepContainer,
  InfoText,
  BodyContainer,
  TitleWrapper
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { floatingIpService, objStorageService } from '../../../api'
import { useUi } from '../../../context/ui.context'
import { useTheme } from 'styled-components'
import {formatCurrency, formatCurrencyString } from '../../../utils/display-price'
import { useAuth } from '../../../context/user.context'
import { InfoContainer } from '../../../components/info-block/styles'


const CreateBucket:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const {user} = useAuth()
  const theme:any = useTheme()
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()
  const [initialValues, setInitialValues ] = useState<any>({
    description:'',
  })
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  const [visibility, setVisibility] = useState<any>('private')

  const onSubmit = (values:any) => {
    setLoading(true)
    objStorageService.createBucket({
      "name": values.name,
      "access": visibility
  })
    .then((res:any) => {
      setLoadingComplete(true)
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
        navigate(-1)
      },1000)
    })
    .catch((err) => {
      if(err.response?.data?.detail){
        viewAlert({
          severity:'error',
          message: err.response?.data?.detail
        })
      } else if(err?.response?.data) {
        setErrors(err.response.data)
      }
      setLoading(false)
    })
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  // const getCurrentPrice = () => {
  //   let queries:any = {
  //     region: values['region'],
  //   }

  //   if(values['region']){
  //     setPriceLoading(true)
  //     floatingIpService.getEstimate(queries)
  //     .then((res) => {
  //       setPrice(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
  //       setPriceMonth(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
  //       setPriceLoading(false)
  //     })
  //   }
  // }
  
  // useEffect(() => {
  //   getCurrentPrice()
  // },[values['region']])
  console.log('errors', errors);
  console.log('values', values);
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <CreateContainer>
      <TitleWrapper>
        <BackArrow
          onClick={() => navigate('/object-storage')}
          title={t("BACK_TO_OBJECT_STORAGE_LIST")}
        />
        <TitleBig>{t("CREATE_A_NEW_BUCKET")}</TitleBig>
      </TitleWrapper>
        <BodyContainer>
          <Container>
        <StepsWrapper>
          <StepContainer>
            <Typography variant='h2'>
              1.{t("ENTER_A_NAME")}
            </Typography>
            <Input
              fieldName='name'
              title={''}
              placeholder=''
              {...formikProps}
            />
          </StepContainer>
          <StepContainer>
            <Typography variant='h2'>
              2.{t("SET_YOUR_BUCKET_VISIBILITY")}
            </Typography>
            <InfoContainer style={{display:'flex', flexDirection:'column', gap:'12px', width:'100%', alignItems:'flex-start'}}>
              <InfoText>
                {t("VISIBILITY_INFO")}
              </InfoText>
              <RadioButtonGroup
                title={''}
                buttons={[
                  { value: 'private', title: t('PRIVATE') },
                  { value: 'public-read', title: t('PUBLIC') },
                ]}
                selectedButton={visibility}
                onChange={(e:any) => setVisibility(e)}
              />
            </InfoContainer>
          </StepContainer>
          <StepContainer>
            <Typography variant='h2'>
              3.{t("ESTIMATED_COST")}
            </Typography>
              <InfoText>
                {t("ESTIMATED_COST_INFO")}
              </InfoText>
          </StepContainer>
        </StepsWrapper>
        <div style={{display:'flex', marginTop:'40px'}}>
          <Button
            variant="primary"
            size="display"
            customStyles={{marginRight:"10px"}}
            onClick={handleSubmit}
            title={`+ ${t("CREATE")}`}
            loading={loading}
            loadingComplete={loadingComplete}
          />
          <Button
            variant="stroke"
            size="display"
            // customStyles={{width: '81px'}}
            onClick={() => navigate("/floating-ips")}
            title={t("CANCEL")}
          />
        </div>
        </Container>
      <EstimateContainer>
        <EstimateTitle>
          {t("ESTIMATED_COST")}
        </EstimateTitle>
        {priceLoading ? 
          <LoaderSpinner customStyles={{color:theme.base.black}}/>
        :
          price ? 
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              <div style={{display:'flex', alignItems:'flex-end'}}>
                <EstimateSum>
                  {`${formatCurrency(price, user.user.clients?.[0]?.currency || 'UAH')}`}
                </EstimateSum>
                <PerHour>{t("HOURS")}</PerHour>
              </div>
              <div style={{display:'flex', alignItems:'flex-end'}}>
                <EstimateSum>
                  {`${formatCurrency(priceMonth, user.user.clients?.[0]?.currency || 'UAH')}`}
                </EstimateSum>
                <PerHour>{t("MONTH")}</PerHour>
              </div>
            </div>
          :
          <PerHour>
            {t("SELECT_OPTIONS_TO_DISPLAY_PRICE")}
          </PerHour>
        }
      </EstimateContainer>
      </BodyContainer>
    </CreateContainer>
  )
}

export default CreateBucket