import React, {FC, useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BackArrow, Button, Input, Select, TextEditor, TopProgress } from "../../../../components";
import { InfoContainer } from "../../../../components/info-block/styles";
import { BodyDefault } from "../../../../components/typography/styled";
import { TitleBig } from "../../styles";
import {
  Container,
  BodyTicket,
  DepartmentTitle,
  GreyText,
} from './styles'
import { Editor } from '@tinymce/tinymce-react';
import { icons } from "../../../../icons";
import { useQueryParam, StringParam } from 'use-query-params';
import config from "../../../../config";
import { supportPalService, instanceService } from "../../../../api";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from "../../../../context/user.context";
import { useUi } from "../../../../context/ui.context";
import { AttachmentComponent } from "../../ticket-page/components/ticket-message";
import { AttachmentsWrapper } from "../../ticket-page/components/ticket-message/styles";



type IncomingProps = {
  department?:string,
  setDepartment?:(department:string) => void,
}
type Department = {
  title:string,
  slug:string,
  description:string | null,
}
const SubmitTicket:FC<IncomingProps> = ({department}) => {
  const [departmentQuery, setDepartment] = useQueryParam('department', StringParam);
  const [priorities, setPriorities] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false)
  const navigate = useNavigate()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [state, setState] = useState<any>()
  const {user} = useAuth()
  const [initialValues, setInitialValues] = useState<any>({
    cc:'',
    message:'',
    priority: '',
    subject: ''
  })
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [customFields, setCustomFields] = useState([])
  const [instances, setInstances] = useState([])
  const inputFileRef:any = useRef();
  const [departments, setDepartments] = useState<any[]>([])
  const getDepartments = () => {
    supportPalService.getDepartments()
    .then((res:any) => {
      setDepartments(res.data.data.map((department:any) => {
        return {
          ...department,
          title:department.name,
          slug:department.id
        }
      }))
      setPageLoading(false)
    })
    .catch((err) => {
      setPageLoading(false)
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:err?.response?.data?.detail
        })
      }
    })
  }
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    getDepartments()
    instanceService.getInstanceList({
      page:1,
      ordering:undefined,
      pageSize:50
    })
    .then((res) => {
      setInstances(res.data.objects.map((instance:any) => {
        return {
          ...instance,
          value:instance.id,
          label:`${instance.name}-${instance.access_ip}`
        }
      }))
    })
    .catch((err) => {
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:err?.response?.data?.detail
        })
      }
      // navigate(-1)
      // setPageLoading(false)
    })
    supportPalService.getCustomFields()
    .then((res) => {
      setCustomFields(res.data.data)
    })
    .catch((err) => {
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:err?.response?.data?.detail
        })
      }
      // navigate(-1)
      // setPageLoading(false)
    })
    supportPalService.getPriority()
    .then((res) => {
      setPriorities(res.data.data.map((priority:any) => {
        return{
          // ...priority,
          value:priority.id.toString(),
          label: priority.name,
          // icon:
        }
      }))
      setPageLoading(false)
    })
    .catch((err) => {
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:err?.response?.data?.detail
        })
      }
      navigate(-1)
      setPageLoading(false)
    })
  },[])
  const onSubmit = (values:any) => {
    const customRegion:any = customFields.find((field:any) => field.name === 'region')
    const customInstance:any = customFields.find((field:any) => field.name === 'instance')
    const customUserId:any = customFields.find((field:any) => field.name === 'userFleioId')
    setLoading(true)
    const data:any = {
      // user:'',
      user_email:user.user.email,
      department:departmentQuery,
      status:1,
      priority:values.priority,
      subject:values.subject,
      text:values.message,
      customfield:['','','','','','','','','','','','','','','','','','','',''],
    }
    data.customfield[customRegion?.id || 1] = localStorage.getItem('region_ocp_zone')
    // data.customfield[customUserId?.id || 3] = user.user.id
    data.customfield[customInstance?.id || 6] = values.instance
    if(uploadedFiles.length > 0){
      data.attachment = uploadedFiles.map((file:any) => {
        return {
          filename:file.name,
          contents:file.base
        }
      })
    }
    supportPalService.createTicket(data)
    .then((res:any) => {
      navigate('/support')
      setLoading(false)
    })
    .catch((err) => {
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:err?.response?.data?.detail
        })
      }
      setLoading(false)
      // navigate(-1)
    })
  }

  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      cc: yup.string(),
      message: yup.string().required(`${t('REQUIRED_FIELD')}`)
      .max(800, `${t("STRING_VALIDATE_MAX")} 800 ${t("CHARACTERS")}`),
      priority: yup.string().required(`${t('REQUIRED_FIELD')}`),
      subject: yup.string()
      .required(`${t('REQUIRED_FIELD')}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      instance: yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const handleUploadFiles = (files:any) => {
    const uploaded:any = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file:any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (uploaded.findIndex((f:any) => f.name === file.name) === -1 && reader.result) {
          //@ts-ignore
          const ext = reader?.result?.split(',')
          ext.shift()
          setLoadingFiles(false);
          uploaded.push({
            type:file.type,
            name:file.name,
            size:file.size,
            base:ext.join(''),
            url:URL.createObjectURL(file)
          });
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    })
    setUploadedFiles(uploaded)

  }
  const handleDelete = (name:any) => {
    setUploadedFiles((prev) => prev.filter((f:any) => f.name !== name))
  }
  const handleFileEvent =  (e:any) => {
    if (e.target.files && e.target.files[0]) {
      setLoadingFiles(true)
      const chosenFiles = Array.prototype.slice.call(e.target.files)
      handleUploadFiles(chosenFiles);
    }
  }
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      <BackArrow
        onClick={() => navigate(-1)}
        title={t("BACK_TO_TICKETS")}
      />
      <TitleBig>
        {t("SUBMIT_A_TICKET")}
      </TitleBig>
      <BodyTicket>
        <InfoContainer
          style={{
            display:'flex',
            flexDirection:"row",
            justifyContent:'flex-start',
            alignItems:'center',
            gap:'10px'
          }}>
          <DepartmentTitle>
            {t("DEPARTMENT")}
          </DepartmentTitle>
          <BodyDefault>
            {department || departments.find((dep:any) => dep.id == departmentQuery)?.name}
          </BodyDefault>
          <Button
            size='small'
            variant='secondary'
            title={t("CHANGE")}
            onClick={() => navigate(-1)}
          />
        </InfoContainer>
        <Input
          required
          title={t("SUBJECT")}
          fieldName='subject'
          customStyles={{width:'360px'}}
          {...formikProps}
          placeholder={''}
        />
        <Select
          required
          title={t("PRIORITY")}
          fieldName='priority'
          data={priorities}
          selectedValue={values['priority']}
          onChange={(e:any) => handleChange('priority')(e)}
          {...formikProps}
          customStyles={{width:'360px'}}
        />
        <Select
          title={t("INSTANCE")}
          fieldName='instance'
          data={instances}
          selectedValue={values['instance']}
          onChange={(e:any) => handleChange('instance')(e)}
          {...formikProps}
          customStyles={{width:'360px'}}
        />
        {/* <div>
          <Input
            title={t("CC")}
            fieldName='cc'
            customStyles={{width:'360px', marginBottom:'0px'}}
            placeholder={t("ENTER_EMAIL_ADDRESS")}
            {...formikProps}
          />
          <GreyText>
            {t("YOU_CAN_ADD_OTHER_USERS")}
          </GreyText>
        </div> */}
        <div style={{display:'flex', width:'100%', flexDirection:'column'}}>
          <TextEditor
            onChange={(e:any) => handleChange('message')(e)}
            fieldName={'message'}
            {...formikProps}
          />
        </div>
      </BodyTicket>
      <AttachmentsWrapper>
        {uploadedFiles.map((attachment:any) => (
          <AttachmentComponent 
            size={attachment.size}
            name={attachment.name}
            url={attachment.url}
            handleDelete={handleDelete}
            isUpload
          />
        ))}
      </AttachmentsWrapper>
      <input
        style={{display:'none'}}
        type="file"
        ref={inputFileRef}
        multiple
        onChange={handleFileEvent}
      />
      <Button
        variant="stroke"
        size="display"
        icon={icons.PlusDefault}
        customStyles={{flex:'0', marginBottom:'10px'}}
        loading={loadingFiles}
        onClick={onBtnClick}
        title={t("ADD_ATTACHMENT")}
      />
      <Button
        variant="primary"
        size="display"
        customStyles={{flex:'0', marginBottom:'10px'}}
        onClick={handleSubmit}
        loading={loading}
        title={t("SUBMIT")}
      />
    </Container>
  )
}

export default SubmitTicket