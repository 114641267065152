import styled from "styled-components";
import { BodyDefault, BodyMedium } from "../typography/styled";

type ItemProp = {
  withIcon?: boolean;
  isTutorial?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 50px 30px;
  box-sizing: border-box;
  position: relative;
`


export const TutorItem = styled.div<ItemProp>`
  box-sizing: border-box;
  background:${({theme}) => theme.palette[100]};
  border-radius: 8px;
  display: flex;
  /* width: 100%; */
  padding:${({withIcon}) => withIcon ? '14px 20px 14px 20px' : "14px 20px 14px 20px"};
  overflow: hidden;
  /* flex: none; */
  position: relative;
  flex-grow: 1;
  gap:8px;
  @media screen and (max-width: 1000px) {
    background-size:0%;
  }

  &:hover {
    cursor: ${({isTutorial}) => !isTutorial ? '' : 'pointer'};
    background: ${({theme, isTutorial}) => !isTutorial ? '' : theme.isDark ? theme.palette[200] : theme.grey[200]};
  }

`
export const TutorTitle = styled(BodyMedium)`
  color:${({theme}) => theme.primary[600]};
  margin-bottom:4px;
`

export const TutorTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TutorContent = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
`
export const TutorIcon = styled.img`

`
export const TutorIconBack = styled.img`
  top:16px;
  position: absolute;
  width: 88px;
  height: 88px;
  right:34px;
  /* bottom:-12px; */
`
