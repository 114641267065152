import styled from "styled-components";
import { BodyMedium, BodySmall, HeadLine1 } from '../../../components/typography/styled'
import { statusColor } from "../../../utils/statusColor";

type StatusProp = {
  active?:string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 30px 30px;
  position: relative;
`
export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  margin-top:10px;
  width:100%;
  gap:50px;
  margin-right: 10px;
`

export const RightHeadContainer = styled.div`
  display:flex;
  margin-right: 10px;
  position: relative;
`

export const InstanceName = styled(HeadLine1)`
  color:${({theme}) => theme.palette[800]};
  /*  */
  margin-bottom:10px;
  /* max-width:500px; */
  word-break: break-word;
`
export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  display: flex;
  margin-right:10px;
  margin-top:60px;
  &>div{
    position: absolute;
    bottom:-1px;
    left:0px;
  }
`

export const InstanceHeadInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const StateWrapper = styled.div`
  display: flex;
  gap:6px;
  align-items: center;
`

export const GreenDot = styled.div<StatusProp>`
  max-width:12px;
  max-height:12px;
  min-width:12px;
  min-height:12px;
  background-color: ${({active}) => statusColor(active)};
  border-radius: 100%;
`

export const Greentext = styled(BodyMedium)<StatusProp>`
  color: ${({active}) => statusColor(active)};
`
export const InstanceType = styled(BodySmall)`
  margin-left: 15px;
  margin-right: 6px;
  color:${({theme}) => theme.palette[650]};
`