import styled from "styled-components";
import { BodyMedium, BodyDefault, BodySmall, HeadLine1 } from "../../../../../components/typography/styled";
type NetworkBlockProp = {
  disabled?:boolean,
}
type NetworkLineProp = {
  margin?:number,
  isBlack?:boolean,
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 0px 10px 0px 0px;
  gap: 10px;


`
export const TopInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top:20px;
  gap: 10px;

`
export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
  /* margin-right: 10px; */
`

export const RightHeadContainer = styled.div`
  display:flex;
  margin-right: 10px;
  position: relative;
`

export const InstanceName = styled(HeadLine1)`
  color:${({theme}) => theme.palette[800]};
  margin-top:10px;
  margin-bottom:10px;
`
export const InstanceHeadInfo = styled.div`
  display: flex;
  align-items: center;
`
export const InstanceDot = styled.div`
  width:12px;
  height:12px;
  background-color: #229B61;
  border-radius: 100%;
`
export const InstanceStatus = styled(BodySmall)`
  color:#229B61;
  margin-left: 6px;
  margin-right: 20px;
`

export const InstanceType = styled(BodySmall)`
  margin-right: 6px;
  color:${({theme}) => theme.palette[650]};
`

export const NetworkBlock = styled.div<NetworkBlockProp>`
  box-sizing: border-box;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  padding: 4px 10px;
  position: relative;
  /* width: 145px; */
  height: 32px;
  background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.palette[25]};
  border: ${({theme}) => `2px solid ${theme.isDark ? theme.palette[200] : '#E0E7FC'}`};
  box-shadow: 0px 1px 2px rgba(139, 171, 248, 0.1);
  border-radius: 4px;
  margin-right:4px;
  z-index:4;
`

export const NetworkText = styled(BodyDefault)<NetworkLineProp>`
  color:${({theme, isBlack}) => isBlack ? theme.primary[900] : theme.primary[600]};
  &:hover{
    cursor: pointer;
  }
  /* text-align: center; */
`
export const NetworkGreyText = styled(BodyDefault)<NetworkLineProp>`
  color:${({theme, isBlack}) => isBlack ? theme.primary[900] : theme.primary[600]};
  
  /* text-align: center; */
`
export const NetworkBorder = styled.div`
  width: 14.5px;
  height: 23.5px;
  box-sizing: border-box;
  border-left: ${({theme}) =>  `2px solid ${theme.isDark ? theme.palette[200] : '#8BABF8'}`};
  border-bottom: ${({theme}) =>  `2px solid ${theme.isDark ? theme.palette[200] : '#8BABF8'}`};
  border-bottom-left-radius: 4px;
  position: absolute;
  left: -16px;
  z-index: 1;
  bottom:51%;
  transform-origin: left bottom;
`

export const NetworkLine = styled.div<NetworkLineProp>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin:8px 0px 0px;

  margin-left: ${({margin}) => `calc(30px * ${margin})`};
`
export const NetworkContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* flex:1 1; */
  width:100%;
  align-items: flex-start;
  padding: 10px;
  gap: 6px;
  border: ${({theme}) => `1px solid ${theme.palette[20]}`};
  border-radius: 4px;
`
export const NetworkInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex:auto 1;
  width:100%;
  align-items: flex-start;
  padding: 8px 10px 6px 10px;
  gap: 2px;
  border: ${({theme}) => `1px solid ${theme.palette[20]}`};
  border-radius: 4px;
`
export const IpGroupsWrapper = styled.div`
  width:100%;
  display: flex;
  gap:10px;
`


//////////////////////////////////////////
export const InfoName = styled(BodyDefault)`
  /* width:50%; */
`
export const InfoNameGrey = styled(InfoName)`
  color:${({theme}) => theme.palette[650]};
`
export const InfoNameBold = styled(BodyMedium)`
  /* width:50%; */
`
export const NameBorder = styled.div`
  display: flex;
  border: ${({theme}) => `1px solid ${theme.palette[20]}`};
  border-radius: 4px;
  padding: 3px 4px;
  white-space:nowrap;
  
  /* flex: auto 0; */
  /* flex-grow:1; */
  /* flex-basis:0; */
`

