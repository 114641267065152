import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, ModalVariables, Tabs, TopProgress } from "../../../components";
import MenuButton from "../../../components/button/menu-button";
import {DropOptions} from "../../../components";
import { PropsContainer } from "../../../components/table/styles";
import { icons } from "../../../icons";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper
} from './styles'
import {
  Overview,
  Snapshot,
  Backup,
} from './components'
import { volumeService } from "../../../api";
import { useUi } from "../../../context/ui.context";

const VolumePage:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const {volumeId} = useParams()
  const {viewAlert} = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },
    {
      title:t("SNAPSHOT"),
      slug:'snapshot',
      id:'2'
    },
    {
      title:t("BACKUP"),
      slug:'backup',
      id:'3'
    },
  ])
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>()
  const [volume, setVolume] = useState<any>()

  const _updateVolume = () => {
    if(volumeId) {
      volumeService.getVolume(volumeId)
      .then((res) => {
        setVolume(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: e.response.data?.detail
          })
        }
      })
    }
  }

  useEffect(() => {
    _updateVolume()
    const interval = setInterval(() => {
      _updateVolume()
    },5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }
  const items = [
    {
      title:t("RENAME"),
      id:'2',
      icon:icons.Edit,
      onClick: () => {
        openModal('rename')
        setAlertTitle(`${t("RENAME_VOLUME")} «${volume.name || volume.id}»`)
      },
      divider:false
    },
    {
      title:t("EXTEND_VOLUME"),
      id:'3',
      icon:icons.Resize,
      toChangeColor:true,
      onClick: () => {
        openModal('extend')
        setAlertTitle(`${t("EXTEND_VOLUME")} «${volume.name || volume.id}»`)
      },
      divider:false
    },
    {
      title:t("EDIT_BOOTABLE_STATUS"),
      id:'5',
      icon:icons.PowerOnBack,
      onClick: () => {
        openModal('change-status')
        setAlertTitle(`${t("CHANGE_BOOTABLE_STATUS_FOR_VOLUME")} «${volume.name || volume.id}»`)
      },
      divider:false
    },
    {
      title:t("REVERT_TO_SNAPSHOT"),
      id:'6',
      icon:icons.BackArrowRevert,
      onClick: () => {
        openModal('revert-volume')
        setAlertTitle(volume.name || volume.id)
      },
      divider:false
    },
    {
      title:t("UPLOAD_TO_IMAGE"),
      id:'7',
      icon:icons.Upload,
      onClick: () => {
        openModal('upload-volume-to-image')
        setAlertTitle(`${t("UPLOAD")} «${volume.name || volume.id}» ${t("TO_IMAGE")}`)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        openModal('delete')
        setAlertTitle(t("VOLUME"))
        setAlertSubTitle([volume])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = async (values:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'revert-volume':
          res = await volumeService.revertToSnapshotVolume(volume.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'upload-volume-to-image':
          res = await volumeService.uploadToImage(volume.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'change-status':
          res = await volumeService.botableVolume(volume.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'rename':
          res = await volumeService.renameVolume(volume.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'extend':
          res = await volumeService.extendVolume(volume.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'delete':
          res = await volumeService.deleteVolume(volume.id);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          navigate('/volumes')
          break;
      }
      setViewModal(false)
      setActiveAnimation(false)
    } catch (e: any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setLoading(false);
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow onClick={() => navigate('/volumes')} title={t("BACK_TO_VOLUMES")}/>
      <HeadWrapper>
        <InstanceName>
          {volume.name || volume.id}
        </InstanceName>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              isFill
              title={t("MENU")}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview volume={volume}/>}/>
        <Route path='/snapshot' element={<Snapshot volume={volume}/>}/>
        <Route path='/backup' element={<Backup volume={volume}/>}/>
      </Routes>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={volume}
      />
    </Container>
  )
}

export default VolumePage