import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`
export const CopyContainer = styled.div`
  position:absolute;
  top:-25px;
  left: 0px;
  /* right:-15px; */
  /* display: flex; */
  white-space: nowrap;
  /* width: 100px; */
  border-radius: 4px;
  padding: 2px 4px;
  background: ${({theme}) => theme.isDark ? '#474B53' : theme.palette[400]};
`

export const BodyValue = styled.div`

  &:hover{
    cursor: pointer;
    opacity:  0.7;
  }
`

