import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import { HeadLine3 } from "../../typography/styled";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
}  

const EditVolumeBackup:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    loading
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    name:instance?.name || instance?.id,
    description: instance?.description || '',
  })
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  useEffect(() => {
    setInitialValues({
      name:instance?.name || instance?.id,
      description: instance?.description || '',
    })
  },[instance])
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
      .required(`${t("REQUIRED_FIELD")}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      description: yup.string()
      // .required(`${t("REQUIRED_FIELD")}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {alertTitle}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Input
          required
          title={t('NAME')}
          fieldName='name'
          placeholder={''}
          onBlur={handleBlur}
          {...formikProps}
        />
        <Input
          title={t('DESCRIPTION')}
          fieldName='description'
          placeholder={''}
          onBlur={handleBlur}
          {...formikProps}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'20px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("EDIT")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default EditVolumeBackup