import styled from "styled-components";
import { BodyDefault, BodyMedium, HeadLine1, VerySmall } from "../../components/typography/styled";
import { statusColor } from "../../utils/statusColor";
type StatusProp = {
  active?:string
}

type ActiveProp = {
  active?:boolean,
  isSmall?:any
  ref?:any
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 20px;
  box-sizing: border-box;
  position: relative;
`

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
`

export const Title = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
`

export const HideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  &:hover{
    cursor:pointer;
  }
`
export const BlueText = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
  margin-left: 8px;
  &:hover{
    cursor:pointer;
  }
`
export const LeftSide = styled.div`
  /* display: flex; */
  flex-direction: column;
`
export const BodyText = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
`

export const BodyLink = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
  margin-left: 8px;
`

export const QuotasContainer = styled.div<ActiveProp>`
  box-sizing: border-box;
  display: flex;
  height:${({active}) => active ? '100%' : '0px'};
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  opacity:${({active}) => active ? '1' : '0'};
  transform-origin: top;
  transition-property:  opacity, height;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  
`

export const StateWrapper = styled.div`
  display: flex;
  gap:6px;
  align-items: center;
`

export const GreenDot = styled.div<StatusProp>`
  max-width:12px;
  max-height:12px;
  min-width:12px;
  min-height:12px;
  background-color: ${({active}) => statusColor(active)};
  border-radius: 100%;
`

export const Greentext = styled(BodyMedium)<StatusProp>`
  color: ${({active}) => statusColor(active)};
`


export const PropContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 3px 4px;
  height:21px;
  background-color: ${({theme}) => theme.palette[300]};
  border-radius: 4px;
  align-items: center;
`
export const Prop = styled(VerySmall)`
  color:${({theme}) => theme.palette[850]};
`
export const Divider = styled.div`
  border-top: ${({theme}) => `1px solid ${theme.palette[350]}`};
  width:100%;
`

export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding:12px 10px;
  box-sizing: border-box;
`

export const FilterBody = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-direction:column;
  gap:10px;
  padding:12px 10px;
  box-sizing: border-box;
  max-height: 254px;
  /* min-height: 354px; */
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 2px 2px ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[400]};
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  ::-webkit-scrollbar-button{
    display: none;
  }
`
export const Filter = styled.div`
  display: flex;
  align-items: center;
  
`

export const BlueBack = styled.div`
  background-color: ${({theme}) => theme.primary[600]};
  width:20px;
  height:20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: ${({theme}) => theme.base.white};
  margin-right: 10px;
`