import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  BodyWrapper,
  WarningBlock,
  NotesContainer,
  NotesWrapper,
  NotesText,
  SelectWrapper,
  Divider,
  CheckBoxWrapper,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { BodyDefault, BodySmall, HeadLine3 } from "../../typography/styled";
import Select from "../../select";
import CheckBox from "../../checkbox";
import { useInstance } from "../../../context/instanceContext";
import TopProgress from "../../TopBarProgress";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { instanceService, volumeService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import Input from "../../input";
import LoaderSpinner from "../../spinner";
import { useTheme } from "styled-components";
import { useAuth } from "../../../context/user.context";
import { formatCurrencyString, formatCurrency } from "../../../utils/display-price";   
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle?:any;
  alertSubTitle?:any;
  loading?:boolean;
  loadingComplete?:boolean;
}  

const CreateVolumeBackup:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    loading,
    loadingComplete
  } = props
  const {viewAlert} = useUi()
  const theme:any = useTheme()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    description:'',
    name:'',
  })
  const {user} = useAuth()
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()
  const [force, setForce] = useState<boolean>(false)
  const [incremental, setIncremental] = useState<boolean>(false)
  const [volumes, setVolumes] = useState<any[]>([])

  const getCreateOptions = () => {
    volumeService.getVolumeList({
      page:1,
      pageSize: 100,
      ordering:'',
    })
    .then((res) => {
      setVolumes(res?.data?.objects.map((item:any) => {
        return {
          ...item,
          value:item.id,
          label:item?.name ? `${item?.name} - ${item?.id}` : item?.id
        }
      }))
      setPageLoading(false)
    })
    .catch((err) => {
      console.log('err', err);
    })
  }

  useEffect(() => {
    getCreateOptions()
    const interval = setInterval(() => {
      getCreateOptions()
    }, 50000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const onSubmit = (values:any) => {
    const data = {
      ...values,
      force:force,
      volume_id:values.volume,
      incremental:incremental,
    }
    closeModal(data)
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
      name: yup
      .string()
      .required(`${t("REQUIRED_FIELD")}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      volume: yup
      .string()
      .required(`${t("REQUIRED_FIELD")}`)
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  const getCurrentPrice = () => {
    const selectedVolume = volumes.find((volume) => volume.id === values['volume'])
    console.log('selectedVolume',selectedVolume);
    let queries:any = {
      volume_size: selectedVolume?.size,
      region: selectedVolume?.region,
    }
    setPriceLoading(true)
    volumeService.getEstimateBackup(queries)
    .then((res) => {
      setPrice(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
      setPriceMonth(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
      setPriceLoading(false)
    })
  }
  
  useEffect(() => {
    getCurrentPrice()
  },[values.volume])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("CREATE_VOLUME_BACKUP")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <BodyWrapper>
          <Select
            toolTip={false}
            data={volumes}
            selectedValue={values['volume']}
            onChange={(e) => {
              handleChange('volume')(e)}}
            title={`${t("VOLUME")}`}
            customStyles={{width:'360px'}}
            fieldName='volume'
            {...formikProps}
          />
          <Input
            required
            title={t('NAME')}
            fieldName='name'
            placeholder={''}
            onBlur={handleBlur}
            {...formikProps}
          />
          <CheckBoxWrapper 
            onClick={() => setIncremental(!incremental)}
          >
            <CheckBox
              value={incremental}
              customStyles={{alignItems:'flex-start'}}
              onChange={() => setIncremental(!incremental)}
              title={
              <BodyDefault>
                {t("INCREMENTAL_CHECKBOX_TEXT")}
              </BodyDefault>}
            />
          </CheckBoxWrapper>
          <CheckBoxWrapper 
            onClick={() => setForce(!force)}
          >
            <CheckBox
              value={force}
              customStyles={{alignItems:'flex-start'}}
              onChange={() => setForce(!force)}
              title={
              <BodyDefault>
                {t("FORCE_INFOTEXT_BACKUP")}
              </BodyDefault>}
            />
          </CheckBoxWrapper>
          <Input
            // required
            title={t('DESCRIPTION')}
            fieldName='description'
            placeholder={''}
            onBlur={handleBlur}
            {...formikProps}
          />
          {values['volume'] &&
            <EstimateContainer>
              <EstimateTitle>
                {t("ESTIMATED_COST")}
              </EstimateTitle>
              {priceLoading ? 
                <LoaderSpinner customStyles={{color:theme.base.black}}/>
              :
                price ? 
                  <div style={{display:'flex', gap:'10px',  alignItems:'flex-start'}}>
                    <div style={{display:'flex', alignItems:'flex-end'}}>
                      <EstimateSum>
                        {`${formatCurrency(price, user.user.clients?.[0]?.currency || 'UAH')}`}
                      </EstimateSum>
                      <PerHour>{t("HOURS")}</PerHour>
                    </div>
                    <div style={{display:'flex', alignItems:'flex-end'}}>
                      <EstimateSum>
                        {`${formatCurrency(priceMonth, user.user.clients?.[0]?.currency || 'UAH')}`}
                      </EstimateSum>
                      <PerHour>{t("MONTH")}</PerHour>
                    </div>
                  </div>
                :
                  null
                // <PerHour>
                //   To display the price, select a network
                // </PerHour>
              }
            </EstimateContainer>
          }
        </BodyWrapper>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("CREATE_BACKUP")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default CreateVolumeBackup