import React, {FC, useState, useEffect} from "react";
import {
  Container,
  TitleWrapper,
  SubTitleWrapper,
  AutoPayContainer
} from './styles'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { Button, CheckBox, Input, ModalVariables, Table, TableSkeleton, Toggle, TopProgress, Typography } from "../../../../components";
import { billingService } from "../../../../api";
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";
import { HeadLine1 } from "../../../../components/typography/styled";
import { useTranslation } from "react-i18next";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";
import CryptoJS from 'crypto-js';
import { useAuth } from "../../../../context/user.context";
import { icons } from "../../../../icons";
import { useUi } from "../../../../context/ui.context";
import {
  StateWrapper,
  GreenDot,
  Greentext,
} from "../../../Instances/styles";
import * as yup from 'yup';
import { useFormik } from 'formik';
import ActivateIc from './activated.svg'
import DeactivateIc from './deactivated.svg'
import config from "../../../../config";


  //@ts-ignore
var wayforpay = new window.Wayforpay(); 	
const PaymentMethods:FC = () => {
  const theme:any = useTheme()
  const {
    user,
  } = useAuth()
  const {viewAlert} = useUi()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [paginationData, setPaginationData] = useState<any>()
  const [invoices, setInvoices] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [t] = useTranslation()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [posBalance, setPosBalance] = useState<boolean>(false)
  const [suspendPay, setSuspendPay] = useState<boolean>(false)
  const [completeProforma, setCompleteProforma] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [isPaymentMethod, setIsPaymentMethod] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [cardConf, setCardConf] = useState<any>({})
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedCard, setSelectedCard] = useState<any>()
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [configLoadingComplete, setConfigLoadingComplete] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({
    amount:'',
  })

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const _deleteCard = async () => {
    setLoading(true);
    try {
      await billingService.deletePaymentMethod({id:selectedCard.id})
      setLoadingComplete(true);
      setTimeout(() => {
        setLoadingComplete(false);
        setViewModal(false)
        setLoading(false);
        setActiveAnimation(false)
      }, 1000);
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      setLoading(false);
    }
  };
  const closeModal = async (data:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'confirm-payment-method':
          res = await billingService.confirmPaymentMethod(data)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'universal':
          if (alertTitle === `${t('DEACTIVATE_PAYMENT_METHOD')}`) {
            res = await billingService.changeCardStatus({id:selectedCard.id, activate:false});
          } else if (alertTitle === `${t('ACTIVATE_PAYMENT_METHOD')}`) {
            res = await billingService.changeCardStatus({id:selectedCard.id, activate:true});
          }
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'delete':
          _deleteCard()
          break; 
      }
      if(modalType !== 'delete'){
        setLoadingComplete(true);
        setTimeout(() => {
          setLoadingComplete(false);
          setViewModal(false)
          setLoading(false);
          setActiveAnimation(false)
        }, 1000);
      }
    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      if(modalType !== 'delete'){
        setLoading(false);
      }
    }
  };
  const getConfig = async () => {
    const autopayConfig = await billingService.getAutopayConfig();
    setPosBalance(autopayConfig.data.positive_balance);
    setSuspendPay(autopayConfig.data.suspend_payment);
    setInitialValues({
      amount: autopayConfig.data.amount,
    });
  }
  const getInvoices = async () => {
    try {
      // Получаем конфигурацию автоплатежей
      const autopayConfig = await billingService.getAutopayConfig();
      setCardConf({
        posBalance:autopayConfig.data.positive_balance,
        suspendPay:autopayConfig.data.suspend_payment,
        amount: autopayConfig.data.amount,
      })

  
      // Получаем способы оплаты
      const paymentMethods = await billingService.getPaymentMethods({
        page: page || 1,
        page_size: pageSize || 10,
        ordering: ordering || '-issue_date',
      });
  
      // Форматируем данные по картам
      const formattedCards = paymentMethods.data.cards.map((paymentMethod: any) => ({
        ...paymentMethod,
        created_at: timeConverterMMDDYYHHMM(paymentMethod.created_at),
        firstId: paymentMethod.id,
        dropOptions: [
          {
            title: paymentMethod?.active ? t("DEACTIVATE") : t("ACTIVATE"),
            id: '3',
            icon: paymentMethod?.active ? DeactivateIc : ActivateIc,
            onClick: () => {
              setSelectedCard(paymentMethod);
              openModal('universal');
              setAlertTitle(paymentMethod?.active ? t("DEACTIVATE_PAYMENT_METHOD") : t("ACTIVATE_PAYMENT_METHOD"));
              setAlertSubTitle(paymentMethod?.active ? 
                `${t("DEACTIVATE_PAYMENT_METHOD_DESC")} ${paymentMethod.pan} ${t("CARD")}` : 
                `${t("ACTIVATE_PAYMENT_METHOD_DESC")} ${paymentMethod.pan} ${t("CARD")}`
              );
            },
            divider: false,
          },
          {
            title: t("DELETE"),
            id: '4',
            onClick: (card: any) => {
              openModal('delete');
              setAlertTitle(t("PAYMENT_METHOD"));
              setAlertSubTitle([card]);
              setSelectedCard(card);
            },
            icon: icons.DeleteIcon,
            divider: false,
          },
        ],
        status: (
          <StateWrapper>
            <GreenDot active={paymentMethod.active ? 'active' : 'blocked'} />
            <Greentext active={paymentMethod.active ? 'active' : 'blocked'}>
              {paymentMethod.active ? t('ACTIVE') : t('BLOCKED')}
            </Greentext>
          </StateWrapper>
        ),
      }));
  
      setInvoices(formattedCards);
      setPaginationData(paymentMethods.data);
  
    } catch (error) {
      console.log('error', error);
    } finally {
      setPageLoading(false);
    }
  };
  
  useEffect(() => {
    getConfig()
    getInvoices()
    const interval = setInterval(() => {
      getInvoices()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])

  const ColumnsInvoices = [
    {
      title:'ID',
      key:'firstId'
    },
    {
      title:t("CARDPAN"),
      key:'pan',
    },
    {
      title:t("STATUS"),
      key:'status',
    },
    {
      title:t("DATE_ADDED"),
      key:'created_at'
    },
    {
      title:'',
      key:'verify'
    },
    {
      title:'',
      key:'props'
    },
  ]
  function generateSignature(obj:any) {
    let signatureString = '';
    const keys = Object.keys(obj).filter((key) =>
      key !== 'holdTimeout' &&
      key !== 'clientFirstName' &&
      key !== 'clientLastName' &&
      key !== 'clientEmail' &&
      key !== 'clientPhone' &&
      key !== 'clientCountry' &&
      key !== 'merchantAuthType' &&
      key !== 'merchantTransactionSecureType' &&
      key !== 'transactionType' &&
      key !== 'merchantTransactionType' &&
      key !== 'apiVersion' &&
      key !== 'serviceUrl'
    );
  
    keys.forEach((key, index) => {
        if (index > 0) {
            signatureString += ';';
        }
        const value = obj[key];
        // Если значение является массивом, преобразуем его в строку, разделяя элементы точкой с запятой
        if (Array.isArray(value)) {
            signatureString += value.join(';');
        } else {
            signatureString += value;
        }
    });
    console.log('signatureString', signatureString);
    // Применение хэш-функции HMAC MD5
    return CryptoJS.HmacMD5(signatureString, (process.env.NODE_ENV !== 'production' || window.location.hostname === 'ocp-front-dev.netengi.com') ? '401622deee143dcf6acc5c8e8e63b246d034fa36' : '57b8a224c5d55eac621c66b8694a5c12ae42210b').toString(CryptoJS.enc.Hex);
  }

  const pay = function () { 	
    const data:any = {
      "merchantAccount": (process.env.NODE_ENV !== 'production' || window.location.hostname === 'ocp-front-dev.netengi.com') ? "netengi_ua" : "onecloudplanet_com",
      "merchantAuthType": "SimpleSignature",
      "merchantDomainName": (process.env.NODE_ENV !== 'production' || window.location.hostname === 'ocp-front-dev.netengi.com') ? "cp.netengi.com" : localStorage.getItem('region_ocp_zone') === 'pl-1' ? 'core-pl.ocplanet.cloud' : 'core.ocplanet.cloud',
      "merchantTransactionType": "AUTH",
      "merchantTransactionSecureType": "AUTO",
      "apiVersion": 1,
      "orderReference": `${user?.user?.active_client}_${Date.now()}`,
      "orderDate": Date.now(),
      "serviceUrl": `${config.apiUrl}api/billing/gateway/wayforpay/add_card`,
      "amount": "1",
      "currency": "UAH",
      "productName": ["249882"],
      "productPrice": ["1"],
      "productCount": ["1"],
      "holdTimeout": 3000,
      "clientFirstName": user?.user?.clients?.[0]?.first_name,
      "clientLastName": user?.user?.clients?.[0]?.last_name,
      "clientEmail": user?.user?.email,
      "clientPhone": user?.user?.clients?.[0]?.phone,
      "clientCountry": user?.user?.clients?.[0]?.country

    }
    let signature = generateSignature(data);
    data.merchantSignature = signature
    wayforpay.run(data, 
    function (response:any) {
      console.log('approved', response);
    // on approved				 			
    }, 			
    function (response:any) {
      console.log('declined', response);
    // on declined 			
    }, 			
    function (response:any) {
      console.log('pending or in processing 	', response);
    // on pending or in processing 			
    });
  }
    
  const onSubmit = (values:any) => {
    setConfigLoading(true)
    const data = {
      positive_balance: posBalance,
      suspend_payment: suspendPay,
      amount: values.amount,
    }
    billingService.updateAutopayConfig(data)
    .then((res) => {
      console.log('res', res);
      getConfig()
      setConfigLoadingComplete(true);
      setTimeout(() => {
        setConfigLoadingComplete(false);
        setConfigLoading(false);
      }, 1000);
    })
    .catch((err) => {
      viewAlert({
        severity: 'error',
        message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
      setConfigLoading(false)
    })
  }

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      amount: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return (
    <>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={125} style={{marginBottom:"5px"}} height={30}
      />
      <TableSkeleton
        rows={10}
        columns={5}
        // columnsData={Columns}
      />
    </>
  )



        
  return (
    <Container>
      <TitleWrapper style={{gap:'14px', marginBottom:'20px'}}>
        <HeadLine1>
          {t("CONFIGURE_AUTOPAY")}
        </HeadLine1>
          <AutoPayContainer>
            <Input
              required
              title={`${t('AUTO_CREDIT_DEBIT_SUM')} ${user.user.clients[0]?.currency}`} 
              fieldName='amount'
              placeholder={''}
              type='number'
              onBlur={handleBlur}
              {...formikProps}
            />
            <div style={{display:'flex', flexDirection:'column'}}>
              <CheckBox
                title={t("POSITIVE_BALANCE")}
                toolTip={true}
                toolTipText={`${t("POSITIVE_BALANCE_DESCRIPTION")}`}
                value={posBalance}
                customStyles={{width:"100%", marginBottom:'7px'}}
                onChange={() => setPosBalance(!posBalance)}
              />
              {/* <CheckBox
                title={t("COMPLETE_PROFORMA")}
                toolTip={true}
                toolTipText={`${t("COMPLETE_PROFORMA_DESCRIPTION")}`}
                value={completeProforma}
                onChange={() => setCompleteProforma(!completeProforma)}
              /> */}
              <CheckBox
                customStyles={{width:"100%", marginBottom:'7px'}}
                title={t("SUSPEND_PAY")}
                toolTip={true}
                toolTipText={`${t("SUSPEND_PAY_DESCRIPTION")}`}
                value={suspendPay}
                onChange={() => setSuspendPay(!suspendPay)}
              />
            </div>

            <Button
              title={t("SAVE_SETTINGS")}
              onClick={handleSubmit}
              loading={configLoading}
              variant={'primary'}
              loadingComplete={configLoadingComplete}
              disabled={
                cardConf.posBalance === posBalance &&
                cardConf.suspendPay === suspendPay &&
                cardConf.amount === values['amount']
              }
              customStyles={{whiteSpace:'nowrap'}}
              size="display"
            />
          </AutoPayContainer>
        {/* // } */}
      </TitleWrapper>
      <TitleWrapper>
        <HeadLine1 >
          {t("PAYMENT_METHODS")}
        </HeadLine1>
      </TitleWrapper>
      <SubTitleWrapper>
        {/* //@ts-ignore */}
        <Typography customStyles={{color:theme.grey[700]}} variant="body">
          {t("PAYMENT_SUBTITLE")}
        </Typography>
        <Button
          title={t("ADD_PAYMENT_METHOD")}
          onClick={() => pay()}
          variant={'primary'}
          customStyles={{whiteSpace:'nowrap'}}
          size="display"
        />
      </SubTitleWrapper>


      {invoices?.length > 0 ?
        <Table
          columns={ColumnsInvoices}
          rows={invoices}
          // settings={items}
          hideOther
          hideCheckbox
          paginationData={paginationData}
        />
      :
        <Typography variant="body">
          {t("NO_PAYMENT_METHODS")}
        </Typography>
      }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        loading={loading}
        loadingComplete={loadingComplete}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        confirmButtonText={t("CANCEL")}
        cancelButtonText={t("CONFIRM")}
        instance={selectedCard}
      />
    </Container>
  )
}

export default PaymentMethods

