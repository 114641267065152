import React, { FC, useState, useRef, useEffect } from "react";
import {
  Container,
  PaginationItem,
  SelectPageContainer,
  Rect,
  SelectPageInput,
  ItemWrapper,
} from './styles'
import { usePagination, DOTS } from "../../hooks/usePagination";
import Button from "../button";
import { icons } from "../../icons";
import Arrow from './arrow.svg'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import StyledIcon from "../styled-icon";
type PagesProps = {
  changePage:(page:any) => void;
  currentPage:number;
  totalCount:number;
  pageSize:any;
  siblingCount:any
}

const Pages:FC<PagesProps> = (props) => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  
  const {
    changePage,
    currentPage,
    totalCount,
    pageSize,
    siblingCount
  } = props

  const paginationRange:any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    setPage(currentPage + 1);
  };

  const onPrevious = () => {
    setPage(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Container>
      
      <PaginationItem 
        isArrow
        onClick={() => currentPage === 1 ? null : onPrevious()}
        disabled={currentPage === 1}
      >
        <StyledIcon 
        disable={currentPage === 1}
        src={Arrow}/>
      </PaginationItem>
      {paginationRange.map((pageNumber:any, index:any) => {
        if (pageNumber === DOTS) {
          return (
            <SelectPage/>
          )
        }

        return (
          <PaginationItem 
              active={pageNumber === currentPage}
              onClick={() => setPage(pageNumber)}
            >
              {pageNumber}
            </PaginationItem>
        );
      })}
      <PaginationItem 
        isArrow
        onClick={() => lastPage === currentPage ? null : onNext()}
        disabled={lastPage === currentPage}
      >
        <img
        style={{transform:"rotate(180deg)"}}
        src={Arrow}/>
      </PaginationItem>
    </Container>
  )
}

export default Pages


const SelectPage:FC = () => {
  const [showSelectPage, setShowSelectPage] = useState<boolean>(false)
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [value, setValue] = useState<any>(page)
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    },[]);

    const handleClickOutside = (event:any) => {
      if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
        setShowSelectPage(false)
      }
    }
  return (
    <ItemWrapper>
      <SelectPageContainer ref={optionsRef} active={showSelectPage}>
        {/* @ts-ignore */}
        <SelectPageInput onChange={(e) => setValue(e.target.value)} placeholder="Number"  value={value}/>
        <Button 
          size='custom'
          customStyles={{width:'111px', height:"40px"}}
          variant='primary'
          onClick={() => setPage(value)}
          title='Go to page'
        />
        <Rect/>
      </SelectPageContainer>
      <PaginationItem 
        ref={dropsRef}
        onClick={() => setShowSelectPage(!showSelectPage)}
      >
        ...
      </PaginationItem>
    </ItemWrapper>
  )
}
