import React, {FC, useState, forwardRef} from "react";
import {
  Head,
  TableTitle,
  ColumnWrapper,
  TableTitleWrapper,
} from "./styles";
import { ChevronIc } from "../header/styles";
import CheckBox from "../checkbox";
import { icons } from "../../icons";
import TableRowItem from "./row";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import styled from "styled-components";
const Th = styled.th`

`;
const Tr = styled.tr`


`

const Thead = styled.thead`
/* display: flex; */
/* width: 100%; */
  height: 42px;
  /* padding-left: 10px; */
  background-color: ${({theme}) => theme.palette[200]};
  border-collapse: none;
`

const TableColumnItem:FC<any> = 
  ({
    columns,
    setHover,
    hover,
    hideCheckbox,
    withBackground,
    settings,
    limitedItems,
    hideOther,
    allChecked,
    setAllChecked,
    checked,
    setChecked,
    rows,
  }) => {


  const foundedProps = columns.find((column:any) => column.key === 'props' || column.key === 'eye')
  const columnWidth = `calc(${100 / (foundedProps ? columns.length - 1 : columns.length)}% - 10px)`;

  return(
    <Thead>
      <Tr>
        {columns.map((column:any) => (
          <Item
            hideOther={hideOther}
            column={column}
            checked={checked}
            setChecked={setChecked}
            rows={rows}
            columnWidth={columnWidth}
            allChecked={allChecked}
            setAllChecked={setAllChecked}
            hideCheckbox={hideCheckbox}
          />
        ))}
      </Tr>
    </Thead>
  )
}

export default TableColumnItem

const Item = (props:any) => {
  const {
    column,
    columnWidth,
    rows,
    allChecked,
    setChecked,
    setAllChecked,
    hideOther,
    hideCheckbox,
    editChecked
  } = props;
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [filtered, setFiltered] = useState<boolean>(false)
  return(
    <Th
    style={column.key === 'props' || column.key === 'eye' ? {marginRight:'20px'} : {width:columnWidth}}
    key={column.key}>
      <Head>
      {(column.key === 'floating_ip_address' || column.key === 'direction' || column.key === 'name' || column.key === 'port' || column.key === 'id') && !hideCheckbox ?
      <CheckBox
        value={allChecked}
        test_id={'main-checkbox'}
        title=''
        onChange={() => {
            if(allChecked){
              setChecked(null)
              setAllChecked(false)
            } else {
              rows.filter((row:any) => row.name !== 'external_network' && row.name !== 'external_snovio').map((row:any) => {
                setChecked(row, true)
              })
              setAllChecked(true)
            }
          }
          // setAllChecked
          // () => {
          //   if(allChecked){
          //     setChecked(null)
          //     setAllChecked(false)
          //   } else {
          //     rows.map((row:any) => {
          //       if(!row?.hideCheckbox){
          //         setChecked(row, true)
          //       }
          //     })
          //     setAllChecked(true)
          //   }
          // }
        }
      />
      : null}
      <TableTitleWrapper onClick={() => {
        if(hideOther || !column.sorted){
          return 
        } else {
          setOrdering(filtered ? `-${column.key}` : `${column.key}`)
          setFiltered(!filtered)
        }
      }}>
        <TableTitle>
          {column.title}
        </TableTitle>
        {!column.sorted || hideOther ? null :
          <ChevronIc active={filtered} src={icons.Chevron}/>
        }
      </TableTitleWrapper>
      {/* {column.title} */}
      </Head>
    </Th>
  )
}