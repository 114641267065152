import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { Button, ModalVariables, StartPage, TopProgress} from "../../components";
import Illustration from './illustration.svg'
import IllustrationDark from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { securityService } from "../../api";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { timeConverterMMDDYYHHMM } from "../../utils/dates";
import { useUi } from "../../context/ui.context";
import { useAuth } from "../../context/user.context";
import rootConfig from "../../config";
import { compareArrays } from "../../utils/findIntersection";

const SecurityGroups:FC = () => {
  const [groups, setGroups] = useState<any[]>([])
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [paginationData, setPaginationData] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [deletedGroup, setDeletedGroup] = useState<any>()
  const [editedGroup, setEditedGroup] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])

  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user, quotas} = useAuth()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(groups, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const [t] = useTranslation()
  const navigate = useNavigate()

  const _updateSecurity = () => {
    securityService.getSecurityList({
      page:page||1,
      ordering,
      pageSize:pageSize||10
    })
    .then((res:any) => {
      setPaginationData(res.data)
      setGroups(res.data.objects.map((group:any) => {
        return{
          ...group,
          navigate:group.id,
          created_at: timeConverterMMDDYYHHMM(group.created_at)
        }
      }))
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      navigate('/home')
    })
    .finally(() => {
      setPageLoading(false)
      setLoading(false)
    })
  }
  useEffect(() => {
    _updateSecurity()
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const _deleteGroups = async () => {
    setLoading(true)
    try{
      if(allChecked) {
        let promises:any[] = []
        await groups.map((item:any) => {
          let promise = securityService.deleteGroup(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
        await _updateSecurity()
        await setAllChecked(false)
      } else if (checked?.length > 0){
        let promises:any[] = []
        await checked.map((item:any) => {
          let promise = securityService.deleteGroup(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
        await _updateSecurity()
        await setChecked([])
      } else if (deletedGroup) {
        await securityService.deleteGroup(deletedGroup.id)
        await _updateSecurity()
      }
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      _updateSecurity()
      setPageLoading(false)
      setLoading(false)
    } finally {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setEditedGroup(null)
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (group:any) => {
        navigate(`${group.id}/edit`);
      },
      divider:false
    },
    {
      title:t("ADD_RULE"),
      id:'3',
      icon:icons.PlusDefault,
      onClick: (group:any) => {
        navigate(`${group.id}/create-rule`);
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(router:any) => {
        openModal('delete')
        setAlertTitle(t("SECURITY_GROUP"))
        setAlertSubTitle([router])
        setDeletedGroup(router)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const closeModal = (props:any) => {
    if(modalType === 'delete'){
      _deleteGroups()
    }
  }
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("DESCRIPTION"),
      key:'description'
    },
    // {
    //   title:t("PLACEMENT_REGION"),
    //   key:'region'
    // },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
    {
      title:'',
      key:'props'
    },
  ]
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas.network.security_group.limit === quotas.network.security_group.in_use){
      viewAlert({
        severity:'error',
        message:`${t('SECURITY_GROUP_QUOTA_EXCEEDED')}`,
      })
    } else {
      navigate('create')
    }
  }
  
  return (
    <>
      <TopProgress loading={loading}/>
      <StartPage
        title={`${t("SECURITY_GROUPS")}`}
        noResourceText={`${t("YOU_HAVE_NO_SECURITY_GROUPS")}`}
        createResourceButton={`${t('CREATE_SECURITY_GROUP')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={groups?.length > 0}
        pageLoading={pageLoading}
        learnMoreAboutResource={`${t('LEARN_MORE_SECURITY_GROUPS')}`}
        howCreateVideoText={`${t('HOW_CREATE_SECURITY_GROUP')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/security-groups`}
        howCreateVideoUrl="https://www.youtube.com/embed/a-MrMb5NOfk"
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("SECURITY_GROUPS"))
                setAlertSubTitle(allChecked ? groups : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        videoImage={Illustration}
        videoImageDark={IllustrationDark}
        tableData={{
          columns:Columns,
          data:groups,
          paginationData:paginationData,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          updateData:_updateSecurity
        }}
        hideQuotas
      />
       <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        loading={loading}
        alertSubTitle={alertSubTitle}
        // dataToEdit={editedGroup}
      />
    </>
  )
}


export default SecurityGroups