import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  BackArrow,
  Input,
  Select,
  Button,
  TopProgress,
  LoaderSpinner,
  Typography,
  RadioButtonGroup,
  CheckBox,
  Toggle,
  Counter,
} from "../../../components";
import {
  Container,
  TitleBig,
  CreateContainer,
  StepsWrapper,
  StepContainer,
  InfoText,
  BodyContainer,
  TitleWrapper,
  CheckboxContainer,
  CheckboxWrapper,
  DopBlock,
  DarkForm,
} from "./styles";
import * as yup from "yup";
import { useFormik } from "formik";
import { objStorageService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { useTheme } from "styled-components";
import { useAuth } from "../../../context/user.context";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { icons } from "../../../icons";

const HidedBlock = styled(animated.div)`
  overflow: hidden;
`;

const CreateLifeCycleRule: FC = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { viewAlert } = useUi();
  const { user } = useAuth();
  const theme: any = useTheme();
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    days_after_object_became_current: "90",
    days_after_became_current: "90",
    days_after_upload_begining: "90",
    status:'Enabled'
  });
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [scope, setScope] = useState<string>("1");
  const [rules, setRules] = useState<any[]>([]);
  const [editedRule, setEditedRule] = useState<any>({})
  const [transitionVersion, setTransitionVersion] = useState<boolean>(false);
  const [expireVersion, setExpireVersion] = useState<boolean>(false);
  const [expireUploads, setUploads] = useState<boolean>(false);
  const [filterObjects, setFilterObjects] = useState<any[]>([]);
  const [prefix, setPrefix] = useState<boolean>(false);
  const transitionVersionRef: any = React.useRef();
  const expireVersionRef: any = React.useRef();
  const expireUploadsRef: any = React.useRef();
  const scopeRef: any = React.useRef();
  const { bucketId, ruleId } = useParams();
  const transitionVersionStyles = useSpring({
    from: { height: 0 },
    to: {
      height: transitionVersion ? transitionVersionRef?.current?.scrollHeight : 0,
    },
  });
  const expireVersionStyles = useSpring({
    from: { height: 0 },
    to: {
      height: expireVersion ? expireVersionRef?.current?.scrollHeight : 0,
    },
  });
  const expireUploadStyles = useSpring({
    from: { height: 0 },
    to: {
      height: expireUploads ? expireUploadsRef?.current?.scrollHeight : 0,
    },
  });
  const scopeStyles = useSpring({
    from: { height: 0 },
    to: {
      height: scope === "2" ? scopeRef?.current?.scrollHeight : 0,
    },
  });
  const getCreateOption = async () => {
    try{
      //@ts-ignore
      const res = await objStorageService.getRules(bucketId)
      if(ruleId){
        setEditedRule(res.data.Rules.find((r:any) => r.ID === ruleId))
        setInitialValues({
          name: res.data.Rules.find((r:any) => r.ID === ruleId).ID,
          days_after_object_became_current: res.data.Rules.find((r:any) => r.ID === ruleId).Transition?.Days?.toString(),
          days_after_became_current: res.data.Rules.find((r:any) => r.ID === ruleId)?.AbortIncompleteMultipartUpload?.DaysAfterInitiation?.toString(),
          days_after_upload_begining: res.data.Rules.find((r:any) => r.ID === ruleId)?.NoncurrentVersionExpiration?.NoncurrentDays?.toString(),
          status: res.data.Rules.find((r:any) => r.ID === ruleId).Status,
          prefix: res.data.Rules.find((r:any) => r.ID === ruleId).Prefix || "",
          filterObjects: res.data.Rules.find((r:any) => r.ID === ruleId).Tags || [],
          transition_to: res.data.Rules.find((r:any) => r.ID === ruleId)?.Transition?.StorageClass || '',
        })
        if(res.data.Rules.find((r:any) => r.ID === ruleId).Prefix?.length > 0){
          setScope('2')
          setPrefix(true)
        }
        if(res.data.Rules.find((r:any) => r.ID === ruleId).Tags?.length > 0){
          setScope('2')
          setFilterObjects(res.data.Rules.find((r:any) => r.ID === ruleId).Tags||[])
        }
        if(res.data.Rules.find((r:any) => r.ID === ruleId).Prefix || res.data.Rules.find((r:any) => r.ID === ruleId).Tags?.length > 0) {
          setScope('2')
          setFilterObjects(res.data.Rules.find((r:any) => r.ID === ruleId).Tags||[])
        } else {
          setScope('1')
        }
        if(res.data.Rules.find((r:any) => r.ID === ruleId)?.Transition?.Days){
          setTransitionVersion(true)
        }
        if(res.data.Rules.find((r:any) => r.ID === ruleId)?.NoncurrentVersionExpiration?.NoncurrentDays){
          setExpireVersion(true)
        }
        if(res.data.Rules.find((r:any) => r.ID === ruleId)?.AbortIncompleteMultipartUpload?.DaysAfterInitiation){
          setUploads(true)
        }
      }
      await setRules(res.data.Rules);
      await setPageLoading(false)
    } catch(error){
      console.log("err", error);
    }
  }
  useEffect(() => {
    getCreateOption()
  }, []);
  const onSubmit = (values: any) => {
    setLoading(true);
    const data:any = {
      ID: values.name,
      Prefix: values?.prefix || "",
      Status: values.status,
    };
    if(scope === '2') {
      data.Tags = values.filterObjects
    } 
    if(transitionVersion){
      data.Transition =  {
        Days: values.days_after_object_became_current,
        StorageClass: values.transition_to,
      }
    }
    if(expireVersion){
      data.NoncurrentVersionExpiration = {
        NoncurrentDays: values.days_after_upload_begining,
      }
    }
    if(expireUploads){
      data.AbortIncompleteMultipartUpload = {
        DaysAfterInitiation: values.days_after_became_current,
      }
    }
    // if(!bucketId) return;
    //@ts-ignore
    objStorageService.createRule(bucketId, ruleId ? rules.map((rule: any) => rule.ID === ruleId ? data : rule) : [...rules, data])
      .then((res: any) => {
        setLoadingComplete(true);
        setTimeout(() => {
          setLoading(false);
          setLoadingComplete(false);
          navigate(`/object-storage/buckets/${bucketId}/lifecycle-rules/`);
        }, 1000);
      })
      .catch((err) => {
        if (err.response?.data?.detail) {
          viewAlert({
            severity: "error",
            message: err.response?.data?.detail,
          });
        } else if (err?.response?.data) {
          setErrors(err.response.data);
        }
        setLoading(false);
      });
  };
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t("REQUIRED_FIELD")}`),
      status: yup.string(),
      prefix:
        scope === "1"
          ? yup.string()
          : yup.string().required(`${t("REQUIRED_FIELD")}`),
      filterObjects:
        scope === "1"
          ? yup.string()
          : yup.array().of(
              yup.object().shape({
                Key: yup.string().required(`${t("REQUIRED_FIELD")}`),
                Value: yup.string().required(`${t("REQUIRED_FIELD")}`),
              })
            ),
      transition_to: transitionVersion
        ? yup.string().required(`${t("REQUIRED_FIELD")}`)
        : yup.string(),
      days_after_object_became_current: transitionVersion
        ? yup
            .number()
            .required(`${t("REQUIRED_FIELD")}`)
            .positive()
            .integer()
            .min(1, `${t("MIN_COUNT_NUMBER")} 1`)
        : yup.number(),
      days_after_became_current: expireVersion
        ? yup
            .number()
            .required(`${t("REQUIRED_FIELD")}`)
            .positive()
            .integer()
            .min(1, `${t("MIN_COUNT_NUMBER")} 1`)
        : yup.number(),
      days_after_upload_begining: expireUploads
        ? yup
            .number()
            .required(`${t("REQUIRED_FIELD")}`)
            .positive()
            .integer()
            .min(1, `${t("MIN_COUNT_NUMBER")} 1`)
        : yup.number(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const handleInputChange = (index: any, field: any, value: any) => {
    const updatedData = [...filterObjects];
    updatedData[index][field] = value;
    setFilterObjects(updatedData);
    setFieldValue("filterObjects", updatedData);
  };
  const addNewItem = (instance?: any) => {
    setFilterObjects((prev: any) => {
      setFieldValue("filterObjects", [
        ...prev,
        {
          Key: instance?.access_ip || "",
          Value: "",
        },
      ]);
      return [
        ...prev,
        {
          Key: instance?.access_ip || "",
          Value: "",
        },
      ];
    });
  };
  const removeItem = (index: any) => {
    const updatedData = [...filterObjects];
    updatedData.splice(index, 1);
    setFilterObjects(updatedData);
    setFieldValue("filterObjects", updatedData);
  };
  console.log("errors", errors);
  console.log("values", values);
  console.log("scope", scope);
  console.log("filterObjects", filterObjects);
  if (pageLoading) return <TopProgress loading={pageLoading} />;
  return (
    <CreateContainer>
      <TitleWrapper>
        <BackArrow
          onClick={() => navigate(`/object-storage/buckets/${bucketId}/lifecycle-rules/`)}
          title={t("BACK_TO_OBJECT_STORAGE_LIST")}
        />
        <TitleBig>{ruleId ? `${t("EDIT")} ${editedRule.ID}` : t("NEW_LIFECYCLE_RULE")}</TitleBig>
      </TitleWrapper>
      <BodyContainer>
        <StepsWrapper>
          <StepContainer style={{ maxWidth: "545px" }}>
            <Typography variant="h2">1.{t("ENTER_A_NAME")}</Typography>
            <Input
              fieldName="name"
              title={t("RULE_NAME")}
              required
              placeholder=""
              {...formikProps}
            />
            <InfoText>{t("LIFECYCLE_NAME_DESCRIPTION")}</InfoText>
          </StepContainer>
          <StepContainer>
            <Typography variant="h2">2.{t("SELECT_SCOPE")}</Typography>
            <InfoText>{t("SELECT_SCOPE_DESCRIPTION")}</InfoText>
            <CheckboxContainer
              onClick={() => setScope("1")}
              active={scope === "1"}
            >
              <CheckBox
                title={t("APPLY_ALL_PROJECTS")}
                value={scope === "1"}
                onChange={() => setScope("1")}
              />
            </CheckboxContainer>
            <CheckboxContainer
              onClick={() => setScope("2")}
              active={scope === "2"}
            >
              <CheckBox
                title={t("LIMIT_THE_SCOPE_OF_THE_RULES")}
                value={scope === "2"}
                onChange={() => setScope("2")}
              />
              <InfoText style={{ marginLeft: "22px" }}>
                {t("LIMIT_THE_SCOPE_OF_THE_RULES_DESCRIPTION")}
              </InfoText>
              <HidedBlock ref={scopeRef} style={scopeStyles}>
                <DopBlock
                  style={{
                    marginLeft: "22px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="h3">
                    {t("FILTER_OBJECT_BY_PREFIX")}
                  </Typography>
                  <Button
                    variant="stroke"
                    size="display"
                    icon={icons.PlusDefault}
                    //@ts-ignore
                    onClick={() => setPrefix(true)}
                    title={t("ADD_VALUE")}
                  />
                  {prefix && (
                    <DarkForm>
                      <Input
                        fieldName={`prefix`}
                        required
                        toolTip={false}
                        title={t("PREFIX")}
                        placeholder=""
                        customStyles={{ width: "241px", flex: "auto 1" }}
                        {...formikProps}
                      />
                      <Button
                        onClick={() => setPrefix(false)}
                        variant="icon"
                        size="display"
                        // disabled
                        customStyles={{ marginTop: "24px" }}
                      >
                        <img src={icons.DeleteIcon} />
                      </Button>
                    </DarkForm>
                  )}
                  <Typography variant="h3">
                    {t("FILTER_OBJECT_BY_TAGS")}
                  </Typography>
                  <Button
                    variant="stroke"
                    size="display"
                    icon={icons.PlusDefault}
                    customStyles={{ marginTop: "10px" }}
                    //@ts-ignore
                    onClick={addNewItem}
                    title={t("ADD_VALUE")}
                  />
                  {filterObjects?.map((item: any, index: any) => (
                    <DarkForm>
                      <Input
                        fieldName={`filterObjects[${index}].Key`}
                        required
                        toolTip={false}
                        title={t("KEY")}
                        placeholder=""
                        arrayName={"filterObjects"}
                        arrayFieldName={"Key"}
                        value={item.Key}
                        customStyles={{ width: "241px", flex: "auto 1" }}
                        {...formikProps}
                        arrayFields={true}
                        arrayIndex={index}
                        handleChange={(e: any) =>
                          handleInputChange(index, "Key", e.target.value)
                        }
                      />
                      <Input
                        required
                        fieldName={`filterObjects[${index}].Value`}
                        toolTip={false}
                        title={t("VALUE")}
                        placeholder=""
                        customStyles={{ width: "150px", flex: "auto 1" }}
                        {...formikProps}
                        arrayFields={true}
                        arrayName={"filterObjects"}
                        value={item.Value}
                        arrayIndex={index}
                        arrayFieldName={"Value"}
                        handleChange={(e: any) =>
                          handleInputChange(index, "Value", e.target.value)
                        }
                      />

                      <Button
                        onClick={() => removeItem(index)}
                        variant="icon"
                        size="display"
                        // disabled
                        customStyles={{ marginTop: "24px" }}
                      >
                        <img src={icons.DeleteIcon} />
                      </Button>
                    </DarkForm>
                  ))}
                </DopBlock>
              </HidedBlock>
            </CheckboxContainer>
          </StepContainer>
          <StepContainer>
            <Typography variant="h2">3.{t("RULE_CONFIGURATION")}</Typography>
            <InfoText>{t("RULE_CONFIGURATION_DESCRIPTION")}</InfoText>
            <CheckboxContainer
              style={{ cursor: "default", opacity: "1" }}
              // onClick={() => setTransitionVersion(!transitionVersion)}
              active={transitionVersion}
            >
              <Toggle
                title={t("TRANSITION_CURRENT_VERSION")}
                value={transitionVersion}
                onChange={() => setTransitionVersion(!transitionVersion)}
              />
              <InfoText style={{ marginLeft: "60px" }}>
                {t("TRANSITION_CURRENT_VERSION_DESCRIPTION")}
              </InfoText>
              <HidedBlock
                ref={transitionVersionRef}
                style={transitionVersionStyles}
              >
                <DopBlock>
                  <Select
                    data={[
                      {
                        value: "GLACIER",
                        label: "Glacier",
                      },
                      {
                        value: "ONEZONE",
                        label: "One Zone IA",
                      },
                    ]}
                    customStyles={{ marginTop: "10px" }}
                    title={t("TRANSITION_TO")}
                    selectedValue={values['transition_to']}
                    onChange={(id:any) => handleChange('transition_to')(id)}
                    fieldName='transition_to'
                    {...formikProps}
                  />
                  <Counter
                    title={t("DAYS_AFTER_OBJECT_BECAME_CURRENT")}
                    value={values["days_after_object_became_current"]}
                    minCount={1}
                    onChange={(v: any) => {
                      handleChange("days_after_object_became_current")(
                        v.toString()
                      );
                    }}
                    customStyles={{ marginTop: "10px" }}
                    fieldName="days_after_object_became_current"
                    hideItems
                  />
                </DopBlock>
              </HidedBlock>
            </CheckboxContainer>
            <CheckboxContainer
              style={{ cursor: "default", opacity: "1" }}
              // onClick={() => setExpireVersion(!expireVersion)}
              active={expireVersion}
            >
              <Toggle
                title={t("EXPIRE_CURRENT_VERSIONS")}
                value={expireVersion}
                onChange={() => setExpireVersion(!expireVersion)}
              />
              <InfoText style={{ marginLeft: "60px" }}>
                {t("EXPIRE_CURRENT_VERSIONS_DESCRIPTION")}
              </InfoText>
              <HidedBlock ref={expireVersionRef} style={expireVersionStyles}>
                <DopBlock>
                  <Counter
                    title={t("DAYS_AFTER_BECAME_CURRENT")}
                    value={values["days_after_became_current"]}
                    minCount={1}
                    onChange={(v: any) => {
                      handleChange("days_after_became_current")(v.toString());
                    }}
                    customStyles={{ marginTop: "10px" }}
                    fieldName="days_after_became_current"
                    hideItems
                  />
                </DopBlock>
              </HidedBlock>
            </CheckboxContainer>
            <CheckboxContainer
              style={{ cursor: "default", opacity: "1" }}
              // onClick={() => setUploads(!expireUploads)}
              active={expireUploads}
            >
              <Toggle
                title={t("EXPIRE_INCOMPLETE_MULTIPART_UPLOADS")}
                value={expireUploads}
                onChange={() => setUploads(!expireUploads)}
              />
              <InfoText style={{ marginLeft: "60px" }}>
                {t("EXPIRE_INCOMPLETE_MULTIPART_UPLOADS_DESCRIPTION")}
              </InfoText>
              <HidedBlock ref={expireUploadsRef} style={expireUploadStyles}>
                <DopBlock>
                  <Counter
                    // Days after upload begining
                    title={t("DAYS_AFTER_UPLOAD_BEGINING")}
                    value={values["days_after_upload_begining"]}
                    minCount={1}
                    onChange={(v: any) => {
                      handleChange("days_after_upload_begining")(v.toString());
                    }}
                    customStyles={{ marginTop: "10px" }}
                    fieldName="days_after_upload_begining"
                    hideItems
                  />
                </DopBlock>
              </HidedBlock>
            </CheckboxContainer>
          </StepContainer>
        </StepsWrapper>
        <div style={{ display: "flex", marginTop: "40px" }}>
          <Button
            variant="primary"
            size="display"
            disabled={!transitionVersion && !expireVersion && !expireUploads}
            customStyles={{ marginRight: "10px" }}
            onClick={handleSubmit}
            title={`+ ${t("CREATE")}`}
            loading={loading}
            loadingComplete={loadingComplete}
          />
          <Button
            variant="stroke"
            size="display"
            // customStyles={{width: '81px'}}
            onClick={() => navigate("/floating-ips")}
            title={t("CANCEL")}
          />
        </div>
      </BodyContainer>
    </CreateContainer>
  );
};

export default CreateLifeCycleRule;
