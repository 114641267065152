import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useParams } from "react-router-dom";
import { BackArrow, Button, ModalVariables, Tabs, TopProgress } from "../../../../components";
import MenuButton from "../../../../components/button/menu-button";
import DropOptions from "../../../../components/header/components/drop-options";
import { PropsContainer } from "../../../../components/table/styles";
import { BodyDefault } from "../../../../components/typography/styled";
import { icons } from "../../../../icons";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  InstanceHeadInfo,
  InstanceDot,
  InstanceStatus,
  InstanceType,
  NavWrapper,
  StyledIcon,
  StyledIconMenu
} from './styles'
import Overview from "./overview";
import Networking from './networking'
import Metrics from './metrics'
import Console from './console'
import SystemLogs from './system-logs'
import SnapshotsInstancePage from './snapshots'
import BackupsInstancePage from './backups'
import SecurityGroupsInstancePage from './security-groups'
import { useInstance } from "../../../../context/instanceContext";
import { instanceService } from "../../../../api";
import volumeService from "../../../../api/volumeService";
import { useUi } from "../../../../context/ui.context";
import { handleStatusName } from "../../../../utils/handle-status-name";
import { compareArraysTags } from "../../../../utils/findIntersection";

const InstancePage:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [options, setOptions] = useState(false)
  const {instanceId} = useParams()
  const [instance, setInstance] = useState<any>(null)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [volumes, setVolumes] = useState<any[]>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<string>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>('')
  const _updateInstance = () => {
    if(instanceId) {
      // setLoading(true)
      // instanceService.getSecurityGroups(instanceId)
      instanceService.getInstanceDetail(instanceId)
      .then((res) => {
        setInstance(res.data)
        // if(res.data.storage_details.volume_attachments?.length > 0){
          volumeService.getAttachmentsToInstance(instanceId)
          .then((res) => {
            setPageLoading(false)
            setVolumes(res.data.objects?.filter((att:any) => att?.volume).map((volume:any) => {
              return {
                ...volume,
                // ...volume.volume,
                name:(<div onClick={() => navigate(`/volumes/${volume.volume.id}`)}>{`${volume.volume.id} ${volume?.is_boot ? '(boot)' : ''}`}</div>),
                hideProps:volume?.is_boot,
                size:`${volume?.volume?.size} GB`,
                status: volume.is_boot ? 'In Use' : 'Stopped',
                has_snapshots:volume?.volume?.number_of_snapshots > 0 ? 'Yes' : 'No'
              }
            }))
            
          })
          .catch((err) => {
            viewAlert({
              severity: 'error',
              message: err?.response?.data?.detail || t("ERROR_OCCURRED"),
            })
          })
        // } else {
        //   setPageLoading(false)
        // }
      })
      .catch((err) => {
        viewAlert({
          severity: 'error',
          message: err?.response?.data?.detail || t("ERROR_OCCURRED"),
        })
        navigate('/instances')
      })
      // .finally(() => {
      //   setLoading(false)
      // })
    }
  }
  // clg
  useEffect(() => {
    _updateInstance()
    const interval = setInterval(() => {
      _updateInstance()
    },5000)
    return () => {
      clearInterval(interval)
    }
  },[instanceId])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },
    {
      title:t("NETWORKING"),
      slug:'networking',
      id:'2'
    },
    {
      title:t("METRICS"),
      slug:'metrics',
      id:'8'
    },
    {
      title:t("CONSOLE"),
      slug:'console',
      id:'3'
    },
    {
      title:t("SYSTEM_LOG"),
      slug:'system-log',
      id:'4'
    },
    {
      title:t("SECURITY_GROUPS"),
      slug:'security-groups',
      id:'6'
    },
    {
      title:t("SNAPSHOTS"),
      slug:'snapshots',
      id:'5'
    },
    {
      title:t("BACKUPS"),
      slug:'backups',
      id:'7'
    },
  ])


  const itemsDefault = [
    {
      title:t("REBUILD"),
      id:'1',
      onClick:() => {
        setAlertTitle(instance.name)
        openModal('rebuild-instance')
      },
      icon:icons.Rebuild,
      divider:false
    },
    {
      title:t("RESCUE"),
      id:'2',
      onClick:() => {
        setAlertTitle(instance.name)
        openModal('rescue-instance')
      },
      icon:icons.Rescue,
      divider:false
    },
    {
      title:t("RENAME"),
      id:'3',
      icon:icons.Rename,
      onClick:() => {
        setAlertTitle(`${t("RENAME_INSTANCE")} «${instance.name}»`)
        openModal('rename')
      },
      divider:false
    },
    {
      title:t("SHELVE"),
      id:'4',
      icon:icons.Shelve,
      divider:false,
      onClick:() => {
        setAlertTitle(`${t("SHELVE_INSTANCE")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_SHELVE_INSTANCE")} ${instance.name}?`)
        openModal('universal')
      },
    },
    {
      title:t("ASSIGN_FLOATING_IP"),
      onClick:() => {
        setAlertTitle(instance.name)
        openModal('assign-ip')
      },
      testId:'assign-floating-ip',
      id:'5',
      icon:icons.PlusDefault,
      divider:false
    },
    {
      title:t("DISSOCIATE_IP"),
      onClick:() => {
        setAlertTitle(instance.name)
        openModal('dissociate-ip')
      },
      id:'6',
      icon:icons.MinusDefault,
      divider:false
    },
    {
      title:t("CHANGE_PASSOWRD"),
      id:'7',
      icon:icons.Password,
      divider:false,
      onClick:() => {
        setAlertTitle(instance.name)
        openModal('change-password')
      }
    },
    {
      title:t("CHANGE_TAGS"),
      id:'9',
      icon:icons.IconTag,
      divider:false,
      onClick:(instance:any) => {
        openModal('add-tag')
      }
    },
    {
      title:t("DELETE"),
      id:'8',
      icon:icons.DeleteIcon,
      divider:false,
      onClick:() => {
        setAlertTitle(`${t("INSTANCE")}`)
        setAlertSubTitle([instance])
        openModal('delete')
      },
    },
  ]
  
  const [activeTab, setActiveTab] = useState<string>()
  const location = useLocation()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }


  const instanceStart = async () => {
    await setLoading(true)
    try{
      const res = await instanceService.startInstance(instance.id)
      if(res?.data?.detail){
        await viewAlert({
          severity: 'info',
          message: res.data?.detail
        })
      }
      await setViewModal(false)
      await setActiveAnimation(false)
    } catch(err:any){
      if(err?.response?.data?.detail){
        viewAlert({
          severity: 'error',
          message: err.response?.data?.detail,
        })
      }
    } finally {
      setLoading(false)
    }
  }
  const closeModal = async (values: any) => {
    let res:any; // объявляем переменную res
    try {
      setLoading(true);
      switch (modalType) {
        case 'dissociate-ip':
          res = await instanceService.dissociateFloatingIp(instance.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'change-password':
          res = await instanceService.changeRootPassword(instance.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          // navigate('/instances')
          break;
        case 'delete':
          res = await instanceService.deleteInstance(instance.id)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          navigate('/instances')
          break;
        case 'assign-ip':
          res = await instanceService.addFloatingIp(instance.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'rebuild-instance':
          res = await instanceService.rebuildInstance(instance.id, values);
          if (res.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data.detail,
            });
          }
          break;
        case 'rescue-instance':
          res = await instanceService.rescueInstance(instance.id, values);
          if (res.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data.detail,
            });
          }
          break;
        case 'rename':
          res = await instanceService.renameInstance(instance.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'add-tag':
          const news = values.filter((tag:any) => !tag.id)
          const missingElementsArray = compareArraysTags(instance.tags, values);
          let promisesDelete:any[] = []
          let promisesNew:any[] = []
          await news.map((miss:any) => {
            let promise = instanceService.addTag(instance.id, miss)
            promisesNew.push(promise)
          })
          await missingElementsArray.map((miss) => {
            let promise = instanceService.deleteTag(instance.id, miss.id)
            promisesDelete.push(promise)
          })
          await Promise.all(promisesDelete)
          await Promise.all(promisesNew)
          break;
        case 'universal':
          setLoading(true);
  
          if (alertTitle === `${t('RESET_INSTANCE_STATE')}`) {
            res = await instanceService.resetStateInstance(instance.id);
          } else if (alertTitle === `${t('CONFIRM_INSTANCE_RESIZE')}`) {
            res = await instanceService.confirmResizeInstance(instance.id);
          } else if (alertTitle === `${t('REVERT_INSTANCE_RESIZE')}`) {
            res = await instanceService.revertResizeInstance(instance.id);
          } else if (alertTitle === `${t('STOP_INSTANCE')}`) {
            res = await instanceService.stopInstance(instance.id);
          } else if (alertTitle === `${t('REBOOT_INSTANCE')}`) {
            res = await instanceService.rebootInstance(instance.id);
          } else if (alertTitle === `${t('UNRESCUE_INSTANCE')}`) {
            res = await instanceService.unrescueInstance(instance.id);
          } else if (alertTitle === `${t("UNSHELVE_INSTANCE")}`) {
            res = await instanceService.unshelveInstance(instance.id);
          } else if (alertTitle === `${t("SHELVE_INSTANCE")}`) {
            res = await instanceService.shelveInstance(instance.id);
          } else if (alertTitle === `${t('UNMOUNT_ISO_AND_REBOOT')}`) {
            res = await instanceService.unrescueInstance(instance.id)
          }
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'instance-resize':
          res = await instanceService.resizeInstance(instance.id, {flavor: values});
          if (res.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data.detail,
            });
          }
          break;
        default:
          break;
      }
  
      _updateInstance();
      setViewModal(false);
      setActiveAnimation(false);
    } catch (e: any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  
  
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={!pageLoading && loading}/>
      <BackArrow onClick={() => navigate('/instances')} title={t("BACK_TO_INSTANCES")}/>
      <HeadWrapper>
        <InstanceName>
          {instance.name}
        </InstanceName>
        {instance.display_status === 'Завантажується з ISO' || instance.display_status === 'Booted from ISO' ?
          <RightHeadContainer>  
            <MenuButton
              data-test-id='unmount-iso-button'
              onClick={() => {
                setAlertTitle(`${t("UNMOUNT_ISO_AND_REBOOT")}`)
                setAlertSubTitle(`${t("ARE_YOU_SHURE_YOU_WANT_TO_ANMOUNT_ISO_AND_REBOOT")}`)
                openModal('universal')
              }}
              customStyles={{marginRight:'10px'}}>
              <img src={icons.Rebuild}/>
            </MenuButton>
            <MenuButton 
              data-test-id='delete-button'
              onClick={() => {
                setAlertTitle(`${t("INSTANCE")}`)
                setAlertSubTitle([instance])
                openModal('delete')
              }}
              customStyles={{marginRight:'10px'}}>
              <img src={icons.DeleteIcon}/>
            </MenuButton>
          </RightHeadContainer>
        :
        instance.display_status === 'Shelved Offloaded' || instance.display_status === 'Збережений стан і звільнені ресурси' ?
        <RightHeadContainer>  
          <MenuButton
            data-test-id='unshelve-button'
            onClick={() => {
              setAlertTitle(`${t("UNSHELVE_INSTANCE")}`)
              setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_UNSHELVE_INSTANCE")} ${instance.name}?`)
              openModal('universal')
            }}
            customStyles={{marginRight:'10px'}}>
            <img src={icons.Unshelve}/>
          </MenuButton>
          <MenuButton 
            data-test-id='delete-button'
            onClick={() => {
              setAlertTitle(`${t("INSTANCE")}`)
              setAlertSubTitle([instance])
              openModal('delete')
            }}
            customStyles={{marginRight:'10px'}}>
            <img src={icons.DeleteIcon}/>
          </MenuButton>
        </RightHeadContainer>
        : 
        instance.display_status === 'Rescued' || instance.display_status === 'Відновлено' ?
          <RightHeadContainer>  
            <MenuButton
              data-test-id='button-unrescue'
              onClick={() => {
                setAlertTitle(`${t("UNRESCUE_INSTANCE")}`)
                setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_UNRESCUE_INSTANCE")} ${instance.name}?`)
                openModal('universal')
              }}
              customStyles={{marginRight:'10px'}}>
              <img src={icons.Rebuild}/>
            </MenuButton>
            <MenuButton 
              data-test-id='delete-button'
              onClick={() => {
                setAlertTitle(`${t("INSTANCE")}`)
                setAlertSubTitle([instance])
                openModal('delete')
              }}
              customStyles={{marginRight:'10px'}}>
              <img src={icons.DeleteIcon}/>
            </MenuButton>
          </RightHeadContainer>
        :
        instance.display_status === 'Verify Resize' || instance.display_status === "Підтвердьте зміну розміру" ? 
          <RightHeadContainer>
            <MenuButton
              data-test-id='confirm-resize-button'
              onClick={() => {
                setAlertTitle(`${t("CONFIRM_INSTANCE_RESIZE")}`)
                setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_CONFIRM_INSTANCE")} ${instance.name} ${t("RESIZE")}?`)
                openModal('universal')
              }}
              customStyles={{marginRight:'10px'}}>
              <img src={icons.CheckMark}/>
            </MenuButton>
            <MenuButton 
              data-test-id='revert-instance-button'
              onClick={() => {
                setAlertTitle(`${t("REVERT_INSTANCE_RESIZE")}`)
                setAlertSubTitle(`${t("ARE_YOU_SHURE_REVERT_INSTANCE")} ${instance.name} ${t("RESIZE")}?`)
                openModal('universal')
              }}
              customStyles={{marginRight:'10px'}}>
              <img src={icons.CloseWithBack}/>
            </MenuButton>
            <MenuButton 
              data-test-id='delete-button'
              onClick={() => {
                setAlertTitle(`${t("INSTANCE")}`)
                setAlertSubTitle([instance])
                openModal('delete')
              }}
              customStyles={{marginRight:'10px'}}>
              <img src={icons.DeleteIcon}/>
            </MenuButton>
          </RightHeadContainer>
        :
          instance.display_status === 'Error' || instance.display_status === 'Невідомо' || instance.display_status === 'Помилка' ? 
            <RightHeadContainer>
              <MenuButton
                data-test-id='reset-state-button'
                onClick={() => {
                  setAlertTitle(`${t("RESET_INSTANCE_STATE")}`)
                  setAlertSubTitle(`${t("ARE_YOU_SHURE_RESET_INSTANCE")} ${instance.name} ${t("STATE")}?`)
                  openModal('universal')
                }}
                customStyles={{marginRight:'10px'}}>
                <StyledIconMenu src={icons.Power}/>
              </MenuButton>
              <MenuButton 
                data-test-id='delete-button'
                onClick={() => {
                  setAlertTitle(`${t("INSTANCE")}`)
                  setAlertSubTitle([instance])
                  openModal('delete')
                }}
                customStyles={{marginRight:'10px'}}>
                <img src={icons.DeleteIcon}/>
              </MenuButton>
            </RightHeadContainer>
          :
            <RightHeadContainer>
              <MenuButton
                data-test-id='stop-button'
                onClick={() => {
                  if(instance.display_status === 'Running' || instance.display_status === 'Виконується'){
                    setAlertTitle(`${t("STOP_INSTANCE")}`)
                    setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_STOP_INSTANCE")} ${instance.name}?`)
                    openModal('universal')
                  } else {
                    instanceStart()
                  }
                }}
                customStyles={{marginRight:'10px'}}>
                <StyledIconMenu src={icons.Power}/>
              </MenuButton>
              <MenuButton 
                data-test-id='reboot-button'
                onClick={() => {
                  setAlertTitle(`${t("REBOOT_INSTANCE")}`)
                  setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_REBOOT_INSTANCE")} ${instance.name}?`)
                  openModal('universal')
                }}
                customStyles={{marginRight:'10px'}}>
                <StyledIconMenu src={icons.Restart}/>
              </MenuButton>
              <MenuButton 
                data-test-id='resize-button'
                onClick={() => {
                  openModal('instance-resize')
                  setAlertTitle(instance.name)
                }}
                customStyles={{marginRight:'10px'}}>
                <StyledIconMenu src={icons.Resize}/>
              </MenuButton>
              <div ref={dropsRef}>
                <Button
                  variant="stroke"
                  isFill
                  size='small'
                  icon={icons.More}
                  test_id='menu_button'
                  title={t("MENU")}
                  onClick={() => setOptions(!options)}
                />
              </div>
              <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
                <DropOptions
                  options={itemsDefault}
                />
              </PropsContainer>
            </RightHeadContainer>
          }
      </HeadWrapper>
      <InstanceHeadInfo>
        {instance.display_status === 'Verify Resize' || instance.display_status === "Підтвердьте зміну розміру" ? 
          <img src={icons.Warning}/> 
        : instance?.display_task !== null ? 
            <InstanceDot style={{backgroundColor:'#E8B533'}}/>
          :
            <InstanceDot active={instance.status}/>
        }
        {instance?.display_task !== null ? 
          <InstanceStatus style={{color:'#E8B533'}}>
            {instance.display_status}
          </InstanceStatus>
        :
          <InstanceStatus active={instance.status}>
            {instance.display_status}
          </InstanceStatus>
        }

        <InstanceType>
          {t("TYPE")}
        </InstanceType>
        <BodyDefault>
          {instance?.flavor?.name}
        </BodyDefault>
      </InstanceHeadInfo>
      <NavWrapper>
        <div>
          <Tabs
            tabs={instance?.storage_details?.hide_local_storage ? tabs?.filter((tab) => tab?.slug !== 'backups') : tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path="*" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview volumes={volumes} instance={instance}/>}/>
        <Route path='/networking' element={<Networking updateDataInstance={_updateInstance} instance={instance}/>}/>
        <Route path='/console' element={<Console instance={instance}/>}/>
        <Route path='/security-groups' element={<SecurityGroupsInstancePage instance={instance}/>}/>
        <Route path='/system-log' element={<SystemLogs instance={instance}/>}/>
        <Route path='/metrics' element={<Metrics instance={instance}/>}/>
        <Route path='/snapshots' element={<SnapshotsInstancePage instance={instance}/>}/>
        {!instance?.storage_details?.hide_local_storage &&
          <Route path='/backups' element={<BackupsInstancePage instance={instance}/>}/>
        }
      </Routes>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        alertSubTitle={alertSubTitle}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        instance={instance}
        instanceId={instance.id}
        loading={loading}
        dataToEdit={instance}
      />
    </Container>
  )
}

export default InstancePage
