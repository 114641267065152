import styled from "styled-components";



export const Container = styled.div`
  display: flex;
  flex-direction: column;

`
export const TitleWrapper = styled(Container)`
  
`
export const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
  gap:20px;
  margin-bottom: 20px;
`

export const AutoPayContainer = styled.div`
  display: flex;
  gap:20px;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`