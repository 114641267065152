import React, { FC, useEffect, useState, useRef } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  SelectWrapper,
  SelectRow,
  RefreshWrapper,
  RefreshText,
  RotateImg,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import { HeadLine3 } from "../../typography/styled";
import { bareMetalService, sshService } from "../../../api";
import { handleOsIcons } from "../../../hooks/useOsIcons";
import TopProgress from "../../TopBarProgress";
import Select from "../../select";
import Refresh from './restart.svg'
import MatchPassword from "../../password-matches";
import { PasswordWrapper } from "../../../pages/Instances/components/create-instance/configure/styles";
            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
}  

const ReinstallBareMetal:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    loading
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    password:'',
    disk_layout_id:'',
  })
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [disks, setDisks] = useState<any[]>([])
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  const InputRef:any = useRef()
  const [activeConfirm, setActiveConfirm] = useState<boolean>(false)
  const [activeButton, setActiveButton] = useState<boolean>(false)
  const [osList, setOsList] = useState<any[]>([])
  const [sshKeys, setSshKeys] = useState<any[]>([])
  const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false)
  const getCreateOptions = async () => {
    setLoadingRefresh(true)
    try{
      const resDisks = await bareMetalService.getListOfDisk()
      const resOs = await bareMetalService.getOs()
      const resSsh = await sshService.getSshKeys()
            await setDisks(resDisks.data.disks.map((disk:any) => {
        return {
          label: disk.name,
          value: disk.id.toString(),
          type:"disk_layout",
          min_disks: disk.min_disks
        }
      }))
      setSshKeys(resSsh.data.objects.map((ssh:any) => {
        return {
          label: ssh.name,
          value: ssh.id.toString()
        }
      }))
      setLoadingRefresh(false)
      await setOsList(resOs.data.OS.map((item:any) => {
        return {
          value: `${item.id}`,
          label: item.name,
          icon: handleOsIcons(item.os_distro)
        }
      }))
      await setPageLoading(false)
    } catch(err){

    }
  }
useEffect(() => {
    getCreateOptions()
  },[])
  useEffect(() => {
    setInitialValues({
      password:'',
      os: osList?.find((os:any) => os.label === instance.os)?.value,
      disk_layout_id:instance?.disk_layout_id,
    })
  },[instance, osList])
  const regLetter = /^(?=.*[a-z])(?=.*[A-Z])/
  const regNumbers = /^(?=.*[0-9])/
  const regSymbols = /^(?=.*[!@#$%^&*()_=+§`~<>?])/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      os: yup.string().required(`${t('REQUIRED_FIELD')}`),
      password:yup.string()
        .required(`${t('REQUIRED_FIELD')}`)
        .min(8, `${t("PASSWORD_DID_NOT_PASS")}`)
        .max(24, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regLetter, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regNumbers, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regSymbols, `${t("PASSWORD_DID_NOT_PASS")}`),
      ssh: yup.string(),
      disk_layout_id: yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  console.log('errors', errors);
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  console.log('instance',instance);
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("REINSTALL_SERVER")}&nbsp;{instance.clearName}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Select
          required
          toolTip={false}
          data={osList}
          selectedValue={values['os']}
          onChange={(id:any) => handleChange('os')(id)}
          test_id={'baremetal-os-select'}
          title={t("OS")}
          fieldName='os'
          {...formikProps}
        />
        <Select
          required
          toolTip={false}
          data={disks}
          selectedValue={values['disk_layout_id']}
          onChange={(id:any) => handleChange('disk_layout_id')(id)}
          bootImage={{disksCount:instance?.storage?.count}}
          test_id={'baremetal-disk-layout-select'}
          title={t("DISK_LAYOUT")}
          fieldName='disk_layout_id'
          {...formikProps}
        />
        <PasswordWrapper>
          <Input
            required
            title={t('ROOT_PASSWORD_LABEL')}
            fieldName='password'
            placeholder={t('ROOT_PASSWORD_LABEL')}
            onFocus={() => setActiveConfirm(true)}
            onFocusOut={() => setActiveConfirm(false)}
            onBlur={handleBlur}
            ref={InputRef}
            isPassword
            {...formikProps}
          />
          <MatchPassword
            setEnabled={setActiveButton}
            validateString={values['password']}
            active={activeConfirm}
          />
        </PasswordWrapper>
        <Select
          toolTip={false}
          data={sshKeys}
          selectedValue={values['ssh']}
          test_id={'baremetal-ssh-select'}
          onChange={(e) => {
            handleChange('ssh')(e)}}
          title={t("SSH_KEY")}
          disabled={sshKeys.length === 0}
          {...formikProps}
          fieldName='ssh'
        />
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("REBUILD")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default ReinstallBareMetal