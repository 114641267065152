import React, { FC, useState, useEffect } from "react";
import {
  Container,
  TitleBig,
  BodyWrapper,
  Info,
  InfoText,
} from './styles'
import { Tabs, BackArrow, Label, TopProgress, LoaderSpinner } from "../../../components";
import {useNavigate} from "react-router";
import { useTranslation } from "react-i18next";
import LoadBalancersDetails from "./load-balancers-details";
import { useQueryParam, StringParam } from 'use-query-params';
import ListenerDetails from "./listener-details";
import PoolDetails from "./pool-details";
import PoolRemembers from "./pool-remembers";
import MonitorDetails from "./monitor-derails";
import Finally from "./finally";
import {BalancerContextProvider, useBalancer} from '../../../context/balancerContext'
import {useLocation, useParams} from 'react-router-dom'
import { loadBalancerService } from "../../../api";
import { useAuth } from "../../../context/user.context";
import { useTheme } from "styled-components";
import { EstimateSum, PerHour, EstimateContainer, EstimateTitle  } from "../../Instances/components/create-instance/styles";
import { formatCurrency} from "../../../utils/display-price";

const CreateLoadBalancer:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [step, setStep] = useQueryParam('step', StringParam);
  const {pageLoading} = useBalancer()
  const {lbId} = useParams()
  const [isCreateListener, setIsCreateListener] = useState<boolean>(false)
  const [isCreatePool, setIsCreatePool] = useState<boolean>(false)
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("LOAD_BALANCER_DETAILS"),
      slug:'balancer-details',
      id:'1',
      component:() => <LoadBalancersDetails/>,
      info:t('PROVIDE_INFO_LOAD_BALANCERS'),
      infoHeight:'108px'

    },
    {
      title:t('LISTENER_DETAILS'),
      slug:'listener-details',
      id:'2',
      component:() => <ListenerDetails/>,
      info:t('PROVIDE_INFO_LOAD_BALANCERS'),
      infoHeight:'108px'

    },
    {
      title:t("POOL_DETAILS"),
      slug:'pool-details',
      id:'3',
      component:(isCreatePool?:boolean) => <PoolDetails isCreatePool={isCreatePool}/>,
      info:t('PROVIDE_INFO_LOAD_BALANCERS'),
      infoHeight:'108px'

    },
    {
      title:t("POOL_REMEMBERS"),
      slug:'pool-remembers',
      id:'4',
      component:() => <PoolRemembers/>,
      info:t('PROVIDE_INFO_LOAD_BALANCERS'),
      infoHeight:'108px'
    },
    {
      title:t("MONITOR_DETAILS"),
      slug:"monitor-details",
      id:"5",
      component:() => <MonitorDetails/>,
      info:t("PROVIDE_INFO_LOAD_BALANCERS"),
      infoHeight:'108px'
    }
  ])
  const location = useLocation()
  useEffect(() => {
    setIsCreateListener(location.pathname.indexOf('/load-balancers/create-listener') !== -1)
    setIsCreatePool(location.pathname.indexOf('/load-balancers/create-pool') !== -1)
  },[])
  useEffect(() => {
    if(!step){
      setStep(isCreateListener ? 'listener-details' : isCreatePool ? 'pool-details' : 'balancer-details')
    }

    if(tabs.indexOf((item:any) => item.slug === step) !== -1){
      setStep(isCreateListener ? 'listener-details' : isCreatePool ? 'pool-details' : 'balancer-details')
    }
  }, [isCreateListener, isCreatePool])

  return(
    <Container>
      <BackArrow
        onClick={() => isCreateListener ? navigate(`/load-balancers/${lbId}/listeners`) :  isCreatePool ? navigate(`/load-balancers/${lbId}/pools`) : navigate('/load-balancers')}
        title={isCreateListener ? t("BACK_TO_LISTENERS") : isCreatePool ? t("BACK_TO_POOLS") : t("BACK_TO_LOAD_BALANCERS")}
      />
      <TitleBig>{isCreateListener ? t("CREATE_NEW_LISTENER") : isCreatePool ? t("CREATE_NEW_POOL") : t("CREATE_NEW_LOAD_BALANCER")}</TitleBig>
      <div style={{width:'100%'}}>
        <Tabs
          tabs={isCreateListener ? 
            tabs.filter((tab:any) => tab.id !== '1') 
          : isCreatePool ? tabs.filter((tab:any) => tab.id !== '1' && tab.id !== '2') 
          : tabs}
          size='display'
          checked
          isSteps
          location={step}
        />
      </div>
      <BalancerContextProvider>
        <ListPages
          isCreateListener={isCreateListener}
          isCreatePool={isCreatePool}
          tabs={tabs}
        />
      </BalancerContextProvider>
    </Container>
  )
}

export default CreateLoadBalancer


const ListPages:FC<any> = ({tabs, isCreateListener, isCreatePool}) => {
  const [t] = useTranslation()
  const [step, setStep] = useQueryParam('step', StringParam);
  const {pageLoading} = useBalancer()
  const {user} = useAuth()
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>(0.1/24)
  const [priceMonth, setPriceMonth] = useState<any>(3)
  const theme:any = useTheme()
  const getCurrentPrice = () => {

    loadBalancerService.getPrice()
    .then((res) => {
      setPrice(res.data.estimated_cost_display.load_balancer_prices.find((price:any) =>  price.currency === user.user.clients?.[0]?.currency).price  / 30 / 24 || 0.1 / 24)
      setPriceMonth(res.data.estimated_cost_display.load_balancer_prices.find((price:any) =>  price.currency === user.user.clients?.[0]?.currency).price || 3)
      setPriceLoading(false)
    })
  }
  useEffect(() => {
    getCurrentPrice()
  },[])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <BodyWrapper>
      {tabs.map((item:any) => (
        step === item.slug ? 
          <>
            {item.component && item.component(isCreatePool)}
            <div style={{display:'flex', flexDirection:'column'}}>
              <Info>
                <Label reverse title={t("INFORMATION")}/>
                <InfoText>
                  {item.info}
                </InfoText>
              </Info>
              <EstimateContainer>
                <EstimateTitle>
                  {t("ESTIMATED_COST")}
                </EstimateTitle>
                {priceLoading ? 
                  <LoaderSpinner customStyles={{color:theme.base.black}}/>
                :
                  price ? 
                    <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                      <div style={{display:'flex', alignItems:'flex-end'}}>
                        <EstimateSum>
                          {`${formatCurrency(price.toFixed(3), user.user.clients?.[0]?.currency || 'UAH')}`}
                        </EstimateSum>
                        <PerHour>{t("HOURS")}</PerHour>
                      </div>
                      <div style={{display:'flex', alignItems:'flex-end'}}>
                        <EstimateSum>
                          {`${formatCurrency(priceMonth.toFixed(2), user.user.clients?.[0]?.currency || 'UAH')}`}
                        </EstimateSum>
                        <PerHour>{t("MONTH")}</PerHour>
                      </div>
                    </div>
                  :
                  <PerHour>
                    {t("SELECT_OPTIONS_TO_DISPLAY_PRICE")}
                  </PerHour>
                }
              </EstimateContainer>
            </div>
          </>
        : null
      ))}
      {step === 'finally' ? 
        <Finally
          isCreateListener={isCreateListener}
          isCreatePool={isCreatePool}
         />
      : null}
    </BodyWrapper>
  )
}