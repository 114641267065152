import React, { FC, useEffect, useState } from "react";
import {Container, FormWrapper} from './styles'
import { Button, Input, Select, Toggle, TopProgress } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { instanceService, loadBalancerService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { Title } from "../../../Instances/styles";




const CreateRule:FC<any> = ({isEdit}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {lbId, listenerId, policyId, ruleId} = useParams()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [admin, setAdmin] = useState<boolean>(true)
  const {viewAlert} = useUi()
  const [actions, setActions] = useState<any[]>([])
  const [rule, setRule] = useState<any>()

  const [invert, setInvert] = useState<boolean>(true)
  const [ruleTypes, setRuleTypes] = useState<any[]>([])
  const [ruleCompareTypes, setRuleCompareTypes] = useState<any[]>([])
  const [initialValues, setInitialValues ] = useState<any>({
    type:"",
    compare_type:"",
    value:"",
  })

  const getCreateOptions = async () => {
      // setPageLoading(true)
    if(!lbId) return;
    try{
      const createRes = await loadBalancerService.getPoliciesRulesCreateOptions()
      await setRuleTypes(
        Object.keys(createRes?.data?.rule_types).map((type) => {
          return {
            value:type,
            label:createRes?.data?.rule_types[type],
          }
        })
        
      )
      await setRuleCompareTypes(
        Object.keys(createRes?.data?.rule_compare_types).map((type) => {
          return {
            value:type,
            label:createRes?.data?.rule_compare_types[type],
          }
        })
        
      )
      if(policyId && ruleId){
        const policyRes:any = await loadBalancerService.getPolicyRuleById(policyId, ruleId)
        await setRule(policyRes?.data)
        await setInitialValues({
          type:policyRes?.data?.type,
          compare_type:policyRes?.data?.compare_type,
          value:policyRes?.data?.value,
        })
        await setAdmin(policyRes?.data?.admin_state_up)
        await setInvert(policyRes?.data?.invert)
      }
    } catch (err:any) {
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    } finally {
      setPageLoading(false)
    }
  }
  useEffect(() => {
    getCreateOptions()
  },[])



  const onSubmit = (values:any) => {
    if(!policyId) return;
    setLoading(true)
    const data:any = {
      ...values,
      invert:invert,
      listener_id:listenerId,
      admin_state_up:admin,
    }
    loadBalancerService.createPolicyRule(policyId, data)
    .then((res) => {
      setLoadingComplete(true)
        setTimeout(() => {
          navigate(`/load-balancer-listener/${lbId}/${listenerId}/policies/${policyId}/rules`)
          viewAlert({
            severity:'info',
            message:isEdit ? `${t("POLICY_RULE_EDITED_SUCCESS")}` : `${t("POLICY_RULE_CREATED_SUCCESS")}`,
          })
        }, 1500)
    })
    .catch((err) => {
      setErrors(err?.response?.data)
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
      setLoading(false)
    })
  }

  const onEdit = (values:any) => {
    if(!policyId) return;
    setLoading(true)
    const data:any = {
      ...rule,
      ...values,
      invert:invert,
      listener_id:listenerId,
      admin_state_up:admin,
    }
    loadBalancerService.editPolicyRule(policyId, data)
    .then((res) => {
      setLoadingComplete(true)
        setTimeout(() => {
          navigate(`/load-balancer-listener/${lbId}/${listenerId}/policies/${policyId}/rules`)
          viewAlert({
            severity:'info',
            message:`${t("POLICY_RULE_EDITED_SUCCESS")}`,
          })
        }, 1500)
    })
    .catch((err) => {
      setErrors(err?.response?.data)
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
      setLoading(false)
    })
  }

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      type: yup.string().required(`${t('REQUIRED_FIELD')}`),
      compare_type: yup.string().required(`${t('REQUIRED_FIELD')}`),
      value: yup.string().required(`${t('REQUIRED_FIELD')}`),

    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit: isEdit ? onEdit : onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      <Title>{isEdit ? `${t("EDIT_FOR")} «${rule?.name || rule?.id}»` : t("CREATE_RULE")}</Title>
      <FormWrapper>
        <Select
          toolTip={false}
          required
          data={ruleTypes}
          selectedValue={values['type']}
          title={t("TYPE")}
          fieldName={`type`}
          onChange={(e:any) => handleChange('type')(e)}
          {...formikProps}
        />
        <Select
          toolTip={false}
          required
          data={ruleCompareTypes}
          selectedValue={values['compare_type']}
          title={t("COMPARE_TYPE")}
          fieldName={`compare_type`}
          onChange={(e:any) => handleChange('compare_type')(e)}
          {...formikProps}
        />
        <Input
          fieldName={`value`}
          toolTip={false}
          required
          title={t("VALUE")}
          placeholder=''
          {...formikProps}
        />
        <Toggle value={invert} onChange={() => setInvert(!invert)} title={t("INVERT")}/>
        <Toggle value={admin} onChange={() => setAdmin(!admin)} title={t("ADMIN_STATE_UP")}/>
      </FormWrapper>
      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          loading={loading}
          loadingComplete={loadingComplete}
          title={isEdit ? t("SAVE") : t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate(-1)}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default CreateRule