import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoBlock } from '../../../../../components'
import {
  Container,
  TopInfoWrapper
} from '../interfaces/styles'
import {
  NetworkContainer,
  Subnet,
} from '../../styles'
type Props = {
  router:any,
  networks:any[]
}

const General:FC<Props> = ({router, networks}) => {
  const [t] = useTranslation()
  const [nets, setNets] = useState<any[]>([])
  useEffect(() => {
    if(networks?.length > 0){
      networks.forEach((net) => {
        setNets((prev:any) => {
          if(!prev.some((it:any) => it.networkName === net.networkName)){
            return [...prev, net]
          } else {
            return prev
          }
        })
      })
    }
  },[networks])
  const specifications = [
    {
      title:t("ID"),
      id:'1',
      value:router.id
    },
    {
      title:t("PROJECT_ID"),
      id:'1',
      value:router.project
    },
    // {
    //   title:t("Placement Region"),
    //   id:'1',
    //   value:router.region
    // },
    {
      title:t("EXTERNAL_NETWORK"),
      id:'1',
      value:router.external_network_id
    },
    {
      title:t("NETWORKS"),
      id:'1',
      value: nets?.length > 0 ? (
 
  <div style={{display:'flex', flexDirection:'column',  gap:'6px',  alignItems: 'flex-start'}}>
          {nets.map((net:any) => (
            <NetworkContainer>
              <div style={{display:'flex'}}>
                <Subnet>
                  {net.networkName}
                </Subnet>:
              </div>
              <div style={{display:'flex'}}>
                {net.subnets.map((sub:any, index:any) => (
                  <div style={{display:'flex'}}>
                    {/* {index === 0 ? null : '\s'} */}
                    <Subnet>
                      {sub.name}
                    </Subnet>
                    {index + 1 === net.subnets.length ? null : ','} &nbsp;
                  </div>
                ))}
              </div>
            </NetworkContainer>
          ))}
        </div>
      ) : 'None'
    },
  ]
  return(
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          title={""}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1', marginRight:'10px'}}
        />
      </TopInfoWrapper>
    </Container>
  )
}
export default General
