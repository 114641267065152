import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";

const imageService = {
  getImagesList:(qureies:QueriesApi) => ApiClient.get('openstack/images', {
    params:{
      ...qureies
    }
  }),
  getCreateOptions:() => ApiClient.get('openstack/images/create_options'),
  getImage:(imageId:string) => ApiClient.get(`openstack/images/${imageId}`),
  deleteImage:(imageId:string) => ApiClient.delete(`openstack/images/${imageId}`),
  getRegions:() => ApiClient.get('openstack/regions?page_size=20'),
  copyImage:(imageId:string, data:Object) => ApiClient.post(`openstack/images/${imageId}/copy-image`, data),
  createImage:(data:Object, headers:any, handleProgress:(id:any, progress:any) => void, id:any, client?:any) => ApiClient.post(`openstack/images?active_client=${client}`, data,
    {
      headers,
      onUploadProgress: function(progressEvent:any) {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        handleProgress(id, progress)
      }
    },
  ),
  getPrice:(qureies:Object) => ApiClient.get(`openstack/images/get_price`, {
    params:{
      ...qureies,
    }
  }),
  editImage:(imageId:string, data:Object) => ApiClient.put(`openstack/images/${imageId}`,data),
  downloadImage:(imageId:string, token:string) => ApiClient.get(`openstack/images/${imageId}/download?fleio-token=${token}`),
}

export default imageService