import  secureLocalStorage  from  "react-secure-storage";

type localStorageHook = {
  key:string,
  data?:any
}
export const useLocalStorage = () => {

  const setDataToLs = (props:localStorageHook) => {
    let toSave = JSON.stringify(props.data)
    secureLocalStorage.setItem(props.key, toSave)
  }
  const getDataToLs = (props:localStorageHook) => {
    //@ts-ignore
    return  JSON.parse(secureLocalStorage.getItem(props.key))
  }
  const removeItemFromLs = (key:string) => {
    secureLocalStorage.removeItem(key)
  }
  return {
    setDataToLs,
    getDataToLs,
    removeItemFromLs
  }
}