import styled from 'styled-components'
import { HeadLine1, BodySmall } from '../../components/typography/styled'


export const Container = styled.div`
  display: flex;
  flex-direction:row;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 40px;
  padding-right:30px;
  /* z-index: 1; */
`

export const WelcomeTitle = styled(HeadLine1)`
  display: flex;
  color:${({theme}) => theme.palette[850]};
  margin-top:10px;
  margin-bottom:20px;
`
type ErrorProp = {
  isError?:boolean
}
export const ErrorContainer = styled.div<ErrorProp>`
  display: ${({isError}) => isError ? 'flex' : 'none'};
  width: 100%;
  /* height: 30px; */
  background: #FFE6E6;
  border-radius: 4px;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  margin-top:18px;
`

export const ErrorText = styled(BodySmall)`
  color: #FF3D3D;
  margin-left:6px;
`