import React, { FC, useEffect, useRef, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { instanceService } from "../../../api";
import TopProgress from "../../TopBarProgress";
import { useUi } from "../../../context/ui.context";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  instanceId?:string;
  alertTitle?:string;
  loading?:boolean;
  viewModal?:boolean
}  


const AssociateSecurityToInstance:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instanceId,
    alertTitle,
    loading,
    viewModal
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    group:'',
  })
  const {
    viewAlert
  } = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loadingFloating, setLoadingFloating] = useState<boolean>(false)
  const [groups, setGroups] = useState<any[]>([])
  const _getOptions = () => {
    if(instanceId && viewModal){
      instanceService.getAssociateSecurityOptions(instanceId)
      .then((res:any) => {
        setGroups(res.data.groups.map((group:any) => {
          return {
            ...group,
            id:group.id,
            value:group.id,
            label:group.name
          }
        }))
      })
      .catch((e:any) => {
        if(e?.response?.data?.detail) {
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
          if(e?.response?.data?.detail === 'No free ports available'){
            closeModalCancel()
          }
        }
      })
      .finally(() => {
        setPageLoading(false)
        setLoadingFloating(false)
      })
    }
    if(!viewModal){
      setPageLoading(false)
    }
  }
  useEffect(() => {
    _getOptions()
  },[])
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      group: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={loadingFloating}/>
      <HeadWrapper>
      <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {`${t("ASSOCIATE_GROUP_FOR_INSTANCE")} «${alertTitle}»`}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Select
          required
          data={groups}
          fieldName='group'
          selectedValue={values['group']}
          onChange={(e) => handleChange('group')(e)}
          title={t("SECURITY_GROUP")}
          customStyles={{width:'100%'}}
          {...formikProps}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            loading={loading}
            title={t("ASSOCIATE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default AssociateSecurityToInstance