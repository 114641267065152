import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BackArrow,
  Input,
  Button,
  TopProgress
} from '../../../components'
import {
  Container,
  TitleBig,
  FormWrapper,
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { sshService } from '../../../api'
import { useUi } from '../../../context/ui.context'

const EditSSh:FC = () => {
  const navigate = useNavigate()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const {sshId} = useParams() 
  const [initialValues, setInitialValues ] = useState<any>({
    name:'',
    public_key:'',
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      name:values.name,
      public_key:values.public_key,
      id:sshId,
      private_key:''
    }
    sshService.editSsh(data)
    .then((res) => {
      navigate(-1)
    })
    .catch((e) => {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      if(e?.response?.data.public_key){
        setFieldError('public_key', e?.response?.data.public_key)
      }
      setErrors(e?.response?.data)
    })
    .finally(() => {
      setLoading(false)
    })
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldError,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t('REQUIRED_FIELD')}`).max(30, `${t("STRING_VALIDATE_MAX")} 30 ${t("CHARACTERS")}`),
      public_key:yup.string().required(`${t('REQUIRED_FIELD')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  useEffect(() => {
    if(sshId){
      sshService.getSshById(sshId)
      .then((res) => {
        setInitialValues({
          name:res.data.name,
          public_key:res.data.public_key
        })
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
  },[])
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={loading}/>
      <BackArrow
        onClick={() => navigate('/ssh-keys')}
        title={t("BACK_TO_KEYS")}
      />
      <TitleBig>{t("EDIT_SSH")}</TitleBig>
        <FormWrapper>
          <Input
            required
            fieldName='name'
            title={t("NAME_KEY")}
            placeholder=''
            customStyles={{marginBottom:'14px'}}
            {...formikProps}
          />
          <Input
            required
            isTextArea
            fieldName='public_key'
            toolTip={true}
            title={t("KEY_CONTENT")}
            customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
            placeholder=''
            {...formikProps}
          />
        </FormWrapper>
      <div style={{display:'flex', marginTop:'40px', gap:'10px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{}}
          onClick={handleSubmit}
          title={t("SAVE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate("/ssh-keys")}
          title={t("CANCEL")}
        />
      </div>
    </Container>
  )
}

export default EditSSh
