import React, { FC, useState } from "react";
import { icons } from "../../icons";
import {
  IconBackground,
  Title,
  TitleWrapper,
  Content,
  CloseIcon,
  AlertCustom,
} from './styles'
import Warning from './warning.svg'
import Error from './error.svg'
import Info from './info.svg'
import ImageIcon from '../side-bar/icons/images-hover.svg'
import { useTranslation } from "react-i18next";
import Link from "../link";

interface Notification {
  severity: "info" | "error" | "warning" | "image",
  message: string,
  title: string,
  canClose?: boolean,
  progress?: number,
  isDark?: any,
  customStyles?: any,
  link?: string,
  linkText?: string,
}
const NotificationBlock:FC<Notification> = (props) => {

  const {
    severity,
    message,
    canClose = true,
    title,
    customStyles,
    linkText,
    link,
    // custom
  } = props
  const [t] = useTranslation()
  const [closing, setClosing] = useState(false);

  const handleClose = () => {
    if (!canClose) {
      return;
    }
    setClosing(true);
  };
  const selectedIcon = (type:string) => {
    switch(type){
      case 'info':
        return Info
      case 'error':
        return Error
      case 'warning':
        return Warning
      case 'image':
        return ImageIcon
      default:
        return Info
    }
  } 

  return (
      <AlertCustom style={customStyles} closing={closing} isDark={props.isDark}>
        <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
          <>
          <IconBackground isDark={props.isDark} type={severity}>
            <img src={selectedIcon(severity)}/>
          </IconBackground>
          <TitleWrapper>
            <Title isDark={props.isDark}>
              {title}
            </Title>
            <Content isDark={props.isDark}>
              {message}
            </Content>
            {linkText &&
              <Link customStyles={{marginTop: '8px'}} linkText={linkText} link={link}/>
            }
          </TitleWrapper>
          </>
          {canClose &&
            <CloseIcon isDark={props.isDark} onClick={handleClose} src={icons.CloseIcon}/>
          }
        </div>
      </AlertCustom>
  )
}


export default NotificationBlock