import React, {FC, useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { ModalVariables, Table, TableSkeleton, TopProgress, Typography } from "../../../../components";
import {
  Container
} from './styles'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { billingService } from "../../../../api";
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";
import { StatusText } from "../overview/styles";
import { HeadLine1 } from "../../../../components/typography/styled";
import { icons } from "../../../../icons";
import { useUi } from "../../../../context/ui.context";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";
import { handleStatusName } from "../../../../utils/handle-status-name";

const Services:FC = () => {
  const theme:any = useTheme()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [services, setServices] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [servicesPageData, setServicesPageData] = useState<any[]>([])
  const [t] = useTranslation()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [serviceId, setServiceId] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const {viewAlert} = useUi()
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const getServices = () => {
    setLoading(true)
    billingService.getServices({
      page:page || 1,
      page_size:pageSize || 10,
      ordering:ordering,
    })
    .then((res) => {
      setServices(res.data.objects.map((service:any) => {
        return {
          ...service,
          name:service.product.name,
          status:<StatusText status={service.status}>{handleStatusName(service.status, t)}</StatusText>,
          created_at:timeConverterMMDDYYHHMM(service.created_at),
          navigate:service.id.toString(),
          paid_until:timeConverterMMDDYYHHMM(service.paid_until),
        }
      }))
      setServicesPageData(res.data)
      setPageLoading(false)
      setLoading(false)
    })
    .catch((e) => {
      console.log('error', e);
    })
  }
  useEffect(() => {
    getServices()
    const interval = setInterval(() => {
      getServices()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  const items = [
    {
      title:t("UNSHELVE"),
      id:'23',
      icon:icons.Shelve,
      divider:false,
      onClick:(service:any) => {
        setServiceId(service.id)
        setAlertTitle(t("UNSHELVE_ALL_INSTANCES_TITLE"))
        setAlertSubTitle(t("UNSHELVE_ALL_INSTANCES_SUBTITLE"))
        openModal('universal')
      },
    },
    {
      title:t("SHELVE"),
      id:'24',
      icon:icons.Unshelve,
      divider:false,
      onClick:(service:any) => {
        setServiceId(service.id)
        setAlertTitle(t("SHELVE_ALL_INSTANCES_TITLE"))
        setAlertSubTitle(t("SHELVE_ALL_INSTANCES_SUBTITLE"))
        openModal('universal')
      },
    },
  ]
  const closeModal = () => {
    if (modalType !== 'universal' || !serviceId) return;
    setLoading(true);
    const data = {
      action: alertTitle === t("SHELVE_ALL_INSTANCES_TITLE") ? 'shelve' : 'unshelve'
    };
  
    billingService.executeActionAllInstances(serviceId, data)
      .then(handleSuccess)
      .catch(handleError)
      .finally(() => {
        setLoading(false);
        setViewModal(false);
        setActiveAnimation(false);
      });
  
    function handleSuccess(res:any) {
      viewAlert({
        severity: 'info',
        message: res.data.detail
      });
    }
  
    function handleError(err:any) {
      if (err?.response?.data?.detail) {
        viewAlert({
          severity: 'error',
          message: err.response?.data?.detail
        });
      }
    }
  };

const ColumnsServices = [
  {
    title:t("NAME"),
    key:'name'
  },
  {
    title:t("STATUS"),
    key:'status'
  },
  {
    title:t("PAID_UNTIL"),
    key:'paid_until'
  },
  {
    title:t("CREATED_AT"),
    key:'created_at'
  },
  {
    title:'',
    key:'props',
  }
]

  if(pageLoading) return (
    <>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={125} style={{marginBottom:"5px"}} height={30}
      />
      <TableSkeleton
          rows={5}
          columns={5}
          // columnsData={Columns}
        />
    </>
  )
  return (
    <Container>
      <HeadLine1 style={{marginBottom:'10px'}}>
        {t("SERVICES")}
      </HeadLine1>
      <Table
        columns={ColumnsServices}
        rows={services}
        // hideOther
        hideCheckbox
        settings={items}
        paginationData={servicesPageData}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        alertSubTitle={alertSubTitle}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        loading={loading}
        confirmButtonText={t("CANCEL")}
        cancelButtonText={t("CONFIRM")}
      />
    </Container>
  )
}

export default Services

