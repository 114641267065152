import light from './light'
import dark from './dark'

const defaultColors = {
  base: {
    white: '#FFFFFF',
    black: '#000000'
  },
  success: '#229B61',
  
  errors: {
    100: '#FFE5E5',
    200: '#FFD2CF',
    600: '#E85048',
  },
  primary: {
    25: '#F9FAFE',
    40: '#F1F4F9',
    50: '#E8EEF9',
    100: '#BDCEEE',
    200: '#B5CAFD',
    300: '#A4BCFD',
    400: '#658DFE',
    500: '#0F68E9',
    550: '#3069E1',
    600: '#4671F6',
    700: '#043F8F',
    800: '#083170',
    900: '#001E45',
  },
  primaryDark: {
    25: '#242424',
    40: '#F1F4F9',
    50: '#393939',
    100: '#BDCEEE',
    200: '#B5CAFD',
    300: '#A4BCFD',
    400: '#658DFE',
    500: '#0F68E9',
    550: '#3069E1',
    600: '#E3E3E3',
    700: '#FCFBFC',
    800: '#083170',
    900: '#001E45',
  },
  grey:{
    100: '#FCFBFC',
    200: '#F7F7F7',
    300: '#F3F1F1',
    350: '#E8E8E8',
    400: '#DBDBDB',
    600: '#6C6C6C',
    700: '#858A94',
    800: '#474B53',
    900: '#1E1E1E',
  },
  greyDark:{
    100: '#242424',
    200: '#F7F7F7',
    300: '#2D2D2D',
    350: '#393939',
    400: '#5C5C5C',
    600: '#6C6C6C',
    700: '#858A94',
    800: '#474B53',
    900: '#1E1E1E',
  }
}

export default (isDark?:boolean) => {
  return {
    isDark:isDark,
    palette: isDark ? dark.colors : light.colors,
    grey: isDark ? {...defaultColors.greyDark} : {...defaultColors.grey},
    rgb: isDark ? '36,36,36' : '255,255,255',
    base: {
      white: isDark ? defaultColors.base.black : defaultColors.base.white,
      black: isDark ? defaultColors.base.white : defaultColors.base.black
    },
    // ...defaultColors,
    primary: isDark ? {...defaultColors.primaryDark} : {...defaultColors.primary},
    success: defaultColors.success,
    errors: {...defaultColors.errors},
  }
}

