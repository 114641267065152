import styled from "styled-components";
import { HeadLine1, HeadLine3, BodyDefault, VerySmall, BodySmall, BodyMedium } from "../../../../../components/typography/styled";
import { statusColor } from "../../../../../utils/statusColor";

type StatusProp = {
  active?:string
}

export const EstimateContainer = styled.div`
  box-sizing: border-box;
  padding: 15px;
  min-width: 240px;
  background: ${({theme}) => theme.palette[200]};
  border-radius: 8px;
`

export const EstimateTitle = styled(HeadLine3)`
  color:${({theme}) => theme.palette[850]};
`

export const EstimateSubTitle = styled(BodySmall)`
  color:${({theme}) => theme.palette[700]};
  margin-top:14px;
`
export const EstimateDesc = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  margin-top:4px;
`

export const StateWrapper = styled.div`
  display: flex;
  gap:6px;
  align-items: flex-start;
  margin-top:4px;
`

export const GreenDot = styled.div<StatusProp>`
  max-width:12px;
  max-height:12px;
  min-width:12px;
  min-height:12px;
  margin-top:4px;
  background-color: ${({active}) => statusColor(active)};
  border-radius: 100%;
`

export const Greentext = styled(BodyMedium)<StatusProp>`
  color: ${({active}) => statusColor(active)};
  
`