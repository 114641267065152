import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  BodyWrapper,
  WarningBlock,
  NotesContainer,
  NotesWrapper,
  NotesText,
  SelectWrapper,
  Divider,
  CheckBoxWrapper,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
  WarningText
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { BodyDefault, BodySmall, HeadLine3 } from "../../typography/styled";
import Select from "../../select";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { instanceService, volumeService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import Input from "../../input";
import { formatCurrencyString, formatCurrency } from "../../../utils/display-price";
import Counter from "../../counter";
            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any, handleClose:any) => void;
  alertTitle?:any;
  alertSubTitle?:any;
  loading?:boolean;
  loadingComplete?:boolean;
  instance?:any;
  instanceId?:any;
}  

const CreateInstanceBackupSchedule:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    loadingComplete,
    instanceId,
    loading,
  } = props

  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    backup_name:'',
    rotation:1,
    day:'0',
    hour:'0',
  })
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()
  const typeBackupData = [
    {label:t("DAILY"), value:'daily'},
    {label:t("EVERY_OTHER_DAY"), value:'every_other_day'},
    {label:t("WEEKLY"), value:'weekly'},
  ]
  const daysBackupData = [
    {label:t("MONDAY"), value:'0'},
    {label:t("TUESDAY"), value:'1'},
    {label:t("WEDNESDAY"), value:'2'},
    {label:t("THURSDAY"), value:'3'},
    {label:t("FRIDAY"), value:'4'},
    {label:t("SATURDAY"), value:'5'},
    {label:t("SUNDAY"), value:'6'},
  ]
  const hoursBackupData = [
    {label:'00:00', value:'0'},
    {label:'01:00', value:'1'},
    {label:'02:00', value:'2'},
    {label:'03:00', value:'3'},
    {label:'04:00', value:'4'},
    {label:'05:00', value:'5'},
    {label:'06:00', value:'6'},
    {label:'07:00', value:'7'},
    {label:'08:00', value:'8'},
    {label:'09:00', value:'9'},
    {label:'10:00', value:'10'},
    {label:'11:00', value:'11'},
    {label:'12:00', value:'12'},
    {label:'13:00', value:'13'},
    {label:'14:00', value:'14'},
    {label:'15:00', value:'15'},
    {label:'16:00', value:'16'},
    {label:'17:00', value:'17'},
    {label:'18:00', value:'18'},
    {label:'19:00', value:'19'},
    {label:'20:00', value:'20'},
    {label:'21:00', value:'21'},
    {label:'22:00', value:'22'},
    {label:'23:00', value:'23'},
  ]
  const onSubmit = (values:any) => {
    const data = {
      backup_name:values.backup_name,
      backup_type:values.backup_type,
      day: +values.day,
      hour: +values.hour,
      instance:instanceId,
      rotation:values.rotation,
      run_at:null,
    }
    closeModal(data, () => resetForm())
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    resetForm,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      backup_name: yup
        .string()
        .required(`${t("REQUIRED_FIELD")}`)
        .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
        .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      rotation: yup.string().required(`${t("REQUIRED_FIELD")}`),
      backup_type: yup.string().required(`${t("REQUIRED_FIELD")}`),
      hour: yup.string(),
      day: yup.string().when("backup_type", {
        is: (backup_type:any) => backup_type === 'weekly',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  const getCurrentPrice = () => {

    setPriceLoading(true)
    instanceService.getPriceInstanceBackup(instanceId)
    .then((res) => {
      setPrice(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
      setPriceMonth(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
      setPriceLoading(false)
    })
  }
  
  useEffect(() => {
    getCurrentPrice()
  },[values])
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("CREATE_SCHEDULE_BACKUP_FOR_INSTANCE")} «{alertTitle}»
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <BodyWrapper>
          <WarningBlock>
            <Icon src={icons.Warning}/>
            <WarningText>
              {t("BACKUP_INSTANCE_CREATE_WARNING")}
            </WarningText>
          </WarningBlock>
          <Input
            required
            title={t('NAME')}
            fieldName='backup_name'
            placeholder={''}
            {...formikProps}
          />
          <Select
            required
            toolTip={false}
            data={typeBackupData}
            selectedValue={values['backup_type']}
            onChange={(e) => {
              handleChange('backup_type')(e)}}
            title={t("BACKUP_SCHEDULE_TYPE")}
            customStyles={{width:'360px'}}
            fieldName='backup_type'
            {...formikProps}
          />
          {/* <Select
            required
            toolTip={false}
            data={hoursBackupData}
            selectedValue={values['hour']}
            onChange={(e) => {
              handleChange('hour')(e)}}
            title={t("HOUR_UTC")}
            customStyles={{width:'360px'}}
            fieldName='hour'
            {...formikProps}
          /> */}
          {values['backup_type'] === 'weekly' &&
            <Select
              required
              toolTip={false}
              data={daysBackupData}
              selectedValue={values['day']}
              onChange={(v:any) => {
                handleChange('day')(v.toString())
              }}
              title={t("DAY")}
              customStyles={{width:'360px'}}
              fieldName='day'
              {...formikProps}
            />
          }
          <Counter
            title={t('ROTATION')}
            value={values['rotation']}
            tooltipText={`${t("ROTATION_INFO")}`}
            toolTip={true}
            minCount={1}
            maxCount={200}
            onChange={(v:any) => {
              handleChange('rotation')(v.toString())
            }}
            fieldName='rotation'
            {...formikProps}
          />
          {/* <EstimateContainer>
            <EstimateTitle>
              {t("ESTIMATED_COST")}
            </EstimateTitle>
            {priceLoading ? 
              <LoaderSpinner customStyles={{color:theme.base.black}}/>
            :
              price ? 
                <div style={{display:'flex', gap:'10px',  alignItems:'flex-start'}}>
                  <div style={{display:'flex', alignItems:'flex-end'}}>
                    <EstimateSum>
                      {`${formatCurrency(price, user.user.clients?.[0]?.currency || 'UAH')}`}
                    </EstimateSum>
                    <PerHour>{t("HOURS")}</PerHour>
                  </div>
                  <div style={{display:'flex', alignItems:'flex-end'}}>
                    <EstimateSum>
                      {`${formatCurrency(priceMonth, user.user.clients?.[0]?.currency || 'UAH')}`}
                    </EstimateSum>
                    <PerHour>{t("MONTH")}</PerHour>
                  </div>
                </div>
              :
                null
              // <PerHour>
              //   To display the price, select a network
              // </PerHour>
            }
          </EstimateContainer> */}
        </BodyWrapper>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("CREATE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default CreateInstanceBackupSchedule