import styled, { css } from 'styled-components'

type StyleButtonProps = {
  size?: 'display' | 'mid' | 'small' | 'custom',
  customStyles?:any,
  disabled?:boolean,
  active?:boolean,
  isProps?:boolean,
}
function styleMyEl(size:any, customStyles:any, disabled:any) {
  switch (size) {
    case 'display':
      return css`
        width: 40px;
        height: 40px;
        border: ${({theme}) => `1px solid ${theme.grey[400]}`};
        box-shadow:${({theme}) => disabled ? '' : '0px 1px 2px rgba(0, 0, 0, 0.1)'};
        ${customStyles}
      `;
    case 'mid':
      return css`
        width: 40px;
        height: 36px;
        border: ${({theme}) => `1px solid ${theme.grey[400]}`};
        box-shadow:${({theme}) => disabled ? '' : '0px 1px 2px rgba(0, 0, 0, 0.1)'};
        ${customStyles}
      `;
    case 'small':
      return css`
        width: 30px;
        height: 30px;
        border: none;
        ${customStyles}
      `;
    default:
      return css`
        width: 30px;
        height: 30px;
        ${customStyles}
      `;
  }
}
const IconButton = styled.button<StyleButtonProps>`
  background-color: ${({theme, active, isProps}) => isProps ? theme.palette[10] : active ? theme.palette[10] : theme.palette[10]};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1E1E1E;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  box-shadow:${({theme, active}) => active ? theme.isDark ? `0px 0px 0px 3px #242424` : `0px 0px 0px 3px #E0EAFF` : ''};
  ${({size, customStyles, disabled}) => styleMyEl(size, customStyles, disabled)}
  border: ${({theme, active, isProps}) =>  active ? `1px solid ${theme.primary[200]}`  : ''};

  &:hover{
    box-shadow: ${({disabled, active}) => disabled || active ? '' :'0px 1px 3px rgba(0, 0, 0, 0.1)'};
    background: ${({theme}) => theme.palette[200]};
    cursor: pointer;
  }
  &:active{
    
    border: ${({theme, active, isProps}) =>  `1px solid ${theme.primary[200]}`};
    background-color: ${({theme}) => theme.base.white};
    box-shadow: ${({theme, disabled}) => disabled ? '' : theme.isDark ? `0px 0px 0px 3px #242424` : `0px 0px 0px 3px #E0EAFF`};
  }
  &:disabled{
    /* background: #FCFBFC; */
    background-color:${({theme}) => theme.isDark ? '#111111' : theme.palette[10]};
    border-width:1px;
    border-style:solid;
    border-color:${({theme}) => theme.isDark ? '#242424' : '#DBDBDB'};
    box-shadow: '0px 0px 0px 0px #FFFFF';
    opacity:${({theme}) => theme.isDark ? '' : '0.5'};
  }
`

export default IconButton