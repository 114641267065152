import React, {FC, useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography, Input, Select, Table, TopProgress, TableSkeleton } from "../../components";
import {
  Container,
  TitleBig,
  BodyText,
  TutorItem,
  TutorTitle,
  TutorTitleWrapper,
  TutorContent,
  BodyContent,
  BodyContentRight,
  TutorIcon,
  HeaderRow,
  FilterContainer,
  InputWrapperRow,
  InputWrapper,
  CloseIconContainer,
  CloseIcon,
} from './styles'
import Screen from './support.svg'
import { useNavigate } from "react-router-dom";
import { icons } from "../../icons";
import { InfoContainer, InfoTitle } from "../../components/info-block/styles";
import { supportPalService } from "../../api";
import { useAuth } from "../../context/user.context";
import moment from 'moment'
import { GreenDot, Greentext, StateWrapper } from "../Instances/styles";
import { useUi } from "../../context/ui.context";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import useDebounce from "../../hooks/useDebounce";
import { handleStatusName } from "../../utils/handle-status-name";
import rootConfig from "../../config";



type ItemProps = {
  item:any
}

const SupportPage:FC = () => {
  const [page, setPage] = useQueryParam('page', StringParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  // const [number, setNumber] = useQueryParam('number', StringParam);
  // const [subject, setSubject] = useQueryParam('subject', StringParam)
  const [number, setNumber] = useState<any>();
  const [department, setDepartment] = useQueryParam('department', StringParam)
  const [status, setStatus] = useQueryParam('status', StringParam)
  const [priority, setPriority] = useQueryParam('priority', StringParam)
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {viewAlert} = useUi()
  const [tickets, setTickets] = useState<any[]>([])
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const {user} = useAuth()
    

  const [priorities, setPriorities] = useState<any[]>([])
  const [departments, setDepartments] = useState<any[]>([])
  const [statuses, setStatuses] = useState<any[]>([])

  const getCreateOptions = async () => {
    try{
      const resDepartments = await supportPalService.getDepartments()
      const resPriority = await supportPalService.getPriority()
      const resStatus = await supportPalService.getStatus()
      await setDepartments(resDepartments.data.data.map((department:any) => {
        return {
          // ...department,
          label:department.name,
          value:department.id.toString()
        }
      }))
      await setPriorities(resPriority.data.data.map((priority:any) => {
        return{
          // ...priority,
          value:priority.id.toString(),
          label: priority.name,
          // icon:
        }
      }))
      await setStatuses(resStatus.data.data.map((status:any) => {
        return{
          // ...priority,
          value:status.id.toString(),
          label: status.name,
          // icon:
        }
      }))
      await setPageLoading(false)
    } catch(err:any) {
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:err?.response?.data?.detail
        })
      }
      // navigate(-1)
      setPageLoading(false)
    }

  }

  const data = [
    {
      title:t("GETTING_STARTED"),
      id:'1',
      content:t("GETTING_STARTED_DECRIPTION"),
      url:`${rootConfig.docs_url}control-panel/personal-area/registration-in-the-control-panel`,
    },
    {
      title:t("PAYMENTS"),
      id:'2',
      content:t("PAYMENTS_DECRIPTION"),
      url:`${rootConfig.docs_url}`,
    },
    {
      title:t("USE_CASES"),
      id:'3',
      content:t("USE_CASES_DECRIPTION"),
      url:`${rootConfig.docs_url}`,
    },
  ]
  const guideItem = {
    title:t("USER_GUIDE"),
    id:'1',
    content:t("USER_GUIDE_DESCRIPTION"),
    url:`${rootConfig.docs_url}`,
    icon:Screen
  }


  const _getTickets = () => {
    // const queries = 
    supportPalService.getTicketList({
      user_email:user.user.email,
      page:page||1,
      pageSize:pageSize||2,
      number,
      department,
      status,
      priority,
      // region:'ua',
      // userFleioId:user.user.id
    })
    .then((res) => {
      setTickets(res.data.data.map((ticket:any) => {
        return {
          ...ticket,
          id:ticket.number.toString(),
          subject:ticket.subject,
          status:(
            <StateWrapper>
              <GreenDot style={{backgroundColor:ticket.status.colour}} active={ticket.status.name}/>
              <Greentext  style={{textTransform:"capitalize", color:ticket.status.colour}} active={ticket.status.name}>
                {handleStatusName(ticket.status.name, t)}
              </Greentext>
            </StateWrapper>),
          submitted:moment(ticket.created_at * 1000).fromNow(),
          navigate:ticket.id.toString(),
          last_reply: moment(ticket.last_reply.created_at * 1000).fromNow(),
        }
      }))
      setDataLoading(false)
      setPageLoading(false)
    })
  }
  const debounce = useDebounce(number, 500)
  useEffect(() => {
    setDataLoading(true)
    _getTickets();
    getCreateOptions()
    const interval = setInterval(() => {
      _getTickets();
    },5000)
    return () => {
      clearInterval(interval)
    }
  },[
    debounce,
    pageSize,
    page,
    department,
    status,
    priority
  ])

  const Columns = [
    {
      title:t("NUMBER"),
      key:'id'
    },
    {
      title:t("SUBJECT"),
      key:'subject'
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    {
      title:t("SUBMITTED"),
      key:'submitted'
    },
    {
      title:t("LAST_REPLY"),
      key:'last_reply'
    },
  ]

  // if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={dataLoading}/>
      <TitleBig>{t("SUPPORT")}</TitleBig>
      {dataLoading || pageLoading || tickets?.length > 0 ||
        (number ||
        department ||
        status ||
        priority) ? null :
        <BodyText>{t("HAVE_NO_TICKETS")}</BodyText>
      }
      <HeaderRow>
        <Button
          title={t("CREATE_TICKET")}
          onClick={() => navigate('create')}
          size='display'
          variant="primary"
        />
        {tickets?.length > 0 ||
        (number|| 
        department|| 
        status|| 
        priority) ? 
        <Button
          title={t("FILTER")}
          onClick={() => setOpenFilter((prev) => !prev)}
          size='display'
          icon={icons.Filter}
          active={openFilter}
          variant="stroke"
        />
      : null}
      </HeaderRow>
      {openFilter &&
        <FilterContainer>
          <InputWrapperRow>
            <InputWrapper>
              <Input
                title={t("NUMBER")}
                // fieldName='cc'
                value={number}
                handleChange={(e:any) => setNumber(e.target.value)}
                containerCustom={{width:'100%'}}
                placeholder={t("TYPE_TO_FILTER")}
              />
              <CloseIconContainer onClick={() => setNumber(null)}>
                <CloseIcon src={icons.CloseIconBold}/>
              </CloseIconContainer>
            </InputWrapper>
            <InputWrapper>
              <Select
                onChange={(e:any) => setDepartment(e)}
                data={departments}
                selectedValue={department}
                title={t("DEPARTMENT")}
                toolTip={false}
                customDropStyles={{paddingRight:'40px'}}
                customStyles={{width:'100%'}}
              />
              <CloseIconContainer onClick={() => setDepartment(null)}>
                <CloseIcon src={icons.CloseIconBold}/>
              </CloseIconContainer>
            </InputWrapper>
          </InputWrapperRow>
          <InputWrapperRow>            
            <InputWrapper>
              <Select                                
                onChange={(e:any) => setStatus(e)}
                data={statuses}
                selectedValue={status}
                title={t("STATUS")}
                toolTip={false}
                customDropStyles={{paddingRight:'40px'}}
                customStyles={{width:'100%'}}
              />
              <CloseIconContainer onClick={() => setStatus(null)}>
                <CloseIcon src={icons.CloseIconBold}/>
              </CloseIconContainer>
            </InputWrapper>
            <InputWrapper>
              <Select
                onChange={(e) => setPriority(e)}
                data={priorities}
                selectedValue={priority}
                title={t("PRIORITY")}
                toolTip={false}
                customDropStyles={{paddingRight:'40px'}}
                customStyles={{width:'100%'}}
              />
              <CloseIconContainer onClick={() => setPriority(null)}>
                <CloseIcon src={icons.CloseIconBold}/>
              </CloseIconContainer>
            </InputWrapper>
          </InputWrapperRow>
        </FilterContainer>
      }
      {pageLoading ? 
        <>
          <TopProgress loading={pageLoading}/> 
            <TableSkeleton
              rows={5}
              columns={Columns?.length || 5}
              columnsData={Columns}
            />
        </> 
        :
      tickets?.length > 0 ? 
        <BodyContent style={{flexDirection:'column'}}>
          <Table
            columns={Columns}
            rows={tickets}
            // settings={settings}
            hideCheckbox
            hideOther
            // allChecked={tableData?.allChecked}
            // setAllChecked={tableData?.setAllChecked}
            // checked={tableData?.checked}
            // setChecked={tableData?.setChecked}
            // paginationData={paginationData}
            // updateData={_updateOperations}
          />
        </BodyContent>
      :
        (dataLoading || number|| 
          department|| 
          status|| 
          priority ?
          <div style={{marginTop:'20px'}}>
            <Typography variant='body'>{t("HAVE_NO_TICKETS_FILTERS")}</Typography>
          </div>
        :
        <BodyContent>
          {/* <div style={{height:'100%'}}> */}
            <TutorItemView item={guideItem}/>
          {/* </div> */}
          <BodyContentRight>
            {data.map((item:any) => (
              <TutorItemView key={item.id} item={item}/>
            ))}
          </BodyContentRight>
        </BodyContent>)
      }
    </Container>
  )
}

export default SupportPage


const TutorItemView:FC<ItemProps> = ({item}) => {
  return(
    <TutorItem
      onClick={() => window.open(item.url)}
      withIcon={item.icon}
      style={item.icon && {backgroundImage:`url(${item.icon})`}}
      key={item.id}
    >
      <TutorTitleWrapper>
        <TutorTitle>
          {item.title}
        </TutorTitle>
        <TutorContent>
          {item.content}
        </TutorContent>
      </TutorTitleWrapper>
      {/* {<TutorIcon src={item?.icon}/>} */}
    </TutorItem>
  )
}
