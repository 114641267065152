import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,

} from '../subnet/styles'
import { Button, ModalVariables, Table, TopProgress } from '../../../../../components'
import { icons } from '../../../../../icons'
import { useParams } from 'react-router-dom'
import { networkService } from '../../../../../api'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { useUi } from '../../../../../context/ui.context'
import { compareArrays } from '../../../../../utils/findIntersection'
import { BodyDefault } from '../../../../../components/typography/styled'

const Subnet:FC = () => {
  const [t] = useTranslation()
  const {id} = useParams()
  const {viewAlert} = useUi()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [ports, setPorts] = useState<any[]>([])

  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()

  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [deletedPort, setDeletedPort] = useState<any>()


  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(ports, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const _updatePorts = () => {
    //@ts-ignore
    networkService.getRelatedPorts(id, {
      page:page||1,
      ordering,
      pageSize:pageSize||20
    })
    .then((res) => {
      setPorts(res.data.objects.map((port:any) => {
        return{
          ...port,
          name:port.id,
          fixed_ips:port.fixed_ips.map((ip:any) => ip.ip_address),
          admin_state_up:port.admin_state_up ? 'Yes' : 'No'
        }
      }))
      setPageLoading(false)
      setLoading(false)
    })
  }
  const _deletePorts = async () => {
    setLoading(true)
    try{
      if(allChecked) {
        let promises:any[] = []
        await ports.map((item:any) => {
          let promise = networkService.deletePort(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
        await viewAlert({
          severity:'info',
          message:'Ports deleted successfully!'
        })
        await _updatePorts()
        await setAllChecked(false)
      } else if (checked?.length > 0){
        let promises:any[] = []
        await checked.map((item:any) => {
          let promise = networkService.deletePort(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
        await viewAlert({
          severity:'info',
          message:'Ports deleted successfully!'
        })
        await _updatePorts()
        await setChecked([])
      } else if (deletedPort) {
        await networkService.deletePort(deletedPort.id)
        await viewAlert({
          severity:'info',
          message:'Port successfully!'
        })
        await _updatePorts()
      }
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      _updatePorts()
      setPageLoading(false)
      setLoading(false)
    } finally {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }
  useEffect(() => {
    _updatePorts()
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = (props:any) => {
    _deletePorts()
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("DELETE"),
      id:'4',
      onClick:(id:any) => {
        openModal('delete')
        setAlertTitle(t("PORT"))
        setAlertSubTitle([id])
        setDeletedPort(id)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("ID"),
      key:'name'
    },
    {
      title:t("FIXED_IPS"),
      key:'fixed_ips',
  
    },
    {
      title:t("MAC_ADDRESS"),
      key:'mac_address'
    },
    {
      title:t("DEVICE_OWNER"),
      key:'device_owner'
    },
    {
      title:t("ADMIN_STATE_UP"),
      key:'admin_state_up'
    },
    {
      title:t("REGION"),
      key:'region'
    },
    {
      title:'',
      key:'props'
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={loading}/>
      <TopInfoWrapper>
        <div style={{display:'flex', marginBottom:'20px'}}>
          {/* <Button
            variant="primary"
            size="display"
            onClick={() => {}}
            title={t("CREATE_PORT")}
            customStyles={{marginBottom:'20px'}}
          /> */}
          <Button
            onClick={() => {
              setAlertTitle(t("PORTS"))
              setAlertSubTitle(allChecked ? ports : checked)
              openModal('delete')
            }}
            variant='icon'
            size='display'
            disabled={!allChecked && checked?.length === 0 }
          >
            <img src={icons.DeleteIcon}/>
          </Button>
        </div>
      </TopInfoWrapper>
      {ports?.length > 0 ?
        <Table
          columns={Columns}
          rows={ports}
          settings={items}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          checked={checked}
          setChecked={editChecked}
          hideOther
          // hideCheckbox
        />
      :
        <BodyDefault>
          {t("NO_PORTS")}
        </BodyDefault>
      }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={editedSubnet}
        loading={loading}
      />
    </Container>
  )
}
export default Subnet