import styled from 'styled-components'
import { BodyDefault, BodySmall } from '../typography/styled';
import StyledIcon from '../styled-icon'
import { Link } from "react-router-dom";
type ActiveProp = {
  active?:boolean;
  isSmall?:boolean;
  isDown?:boolean;
  visible?:any;
  isSoon?:boolean;
  disabled?:boolean;
}
export const Container = styled(Link)<ActiveProp>`
  box-sizing: border-box;
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top:4px;
  padding: 6px 8px;
  z-index: 1;
  border:none;
  opacity: ${({isSoon}) => isSoon ? '0.5' : '1'};
  width: ${({isSmall}) => isSmall ? '36px' : '255px'};
  transition: 0.2s width ease-in-out;
  /* height: 32px; */
  border-radius: 4px;
  text-decoration: none;
  pointer-events:${({disabled}) => disabled ? 'none' : ''};
  background-color:${({theme, active}) => active ? theme.isDark ? theme.palette[20] : theme.primary[40] : ''};
  &:hover{
    cursor: pointer;
    background: ${({theme, isSoon}) => isSoon ? '' : theme.isDark ? theme.palette[200] : theme.primary[40]};
    border-radius: 4px;
  }
  &:active{
    background-color: ${({theme, isSoon}) =>  isSoon ? '' : theme.isDark ? theme.palette[400] : theme.primary[50]};
    transition: 0.1s background-color ease-in-out;
  }

`

export const StyledChevron = styled(StyledIcon)`
  transform: rotate(-90deg);
  &path{
    fill:#FFFF;
  }
  z-index: 6;
`

export const Title = styled(BodyDefault)<ActiveProp>`
  color:${({theme}) => theme.isDark ? theme.palette[800] : theme.palette[900]};
  /* white-space: nowrap; */
  /* word-wrap: break-word; */
  /* word-break: break-word; */
  display: flex;
  align-items: center;
  gap:10px;
`

export const StyledIconColored = styled.img`
  margin-right:16px;
  width: 20px;
  height: 20px;
`

export const DropOptions = styled.div<ActiveProp>`
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 99;
  width: 280px;
  height: 302px;
  top:${({isDown}) => isDown ? '-265px' : '-3px'};
  /* top: auto; */
  /* bottom:auto; */
  /* top:${({visible}) => visible ? '-3px' : '-265px'}; */
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  opacity:${({active}) => active ? '1' : '0'};
  transform-origin: ${({isDown}) => isDown ? 'bottom left' : 'top left'};
  transition-property:all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  left: ${({isSmall}) => isSmall ? '53px' : '258px'};
  /* transition: 0.2s left ease-in-out; */
  background: ${({theme}) => theme.palette[10]};
  border: ${({theme}) => `1px solid ${theme.grey[350]}`};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

export const DropOptionsTitle = styled(BodySmall)`
  color:${({theme}) => theme.palette[650]};
  padding: 10px 10px 0px;
  text-transform: uppercase;
`

export const DropItemWrapper = styled.div`
  padding: 8px 12px;
  &:hover{
    cursor: pointer;
    background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[100]};
  }
  &:active{
        background-color: ${({theme}) => theme.isDark ? theme.palette[400] : theme.grey[200]};
    transition: 0.1s background-color ease-in-out;
  }
`

export const DropOptionItem = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  
`

export const Divider = styled.div`
  width: 278px;
  border-bottom: ${({theme}) => `1px solid ${theme.palette[350]}`};
`

export const SoonText = styled.div`
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  white-space: nowrap;
  background-color: ${({theme}) => theme.grey[300]};
  line-height: 12px;
  text-transform: uppercase;
  color:${({theme}) => theme.palette[800]};
  border-radius: 4px;
  padding: 2px;
`

export const BetaText = styled.div`
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  white-space: nowrap;
  /* background-color: ${({theme}) => theme.grey[300]}; */
  background-color: #FFDF8D;
  line-height: 12px;
  text-transform: uppercase;
  color: #000;
  border-radius: 4px;
  padding: 4px;
`