import { useTranslation } from 'react-i18next'
import {
  CopyContainer,
  BodyValue,
  Container
} from './styles'
import React, { FC, useState } from 'react'
import { BodySmall } from '../typography/styled'
import copy from 'copy-to-clipboard';


const ClipBoardWrapper:FC<any> = ({children, toCopy}) => {
  const [copyText, setCopyText] = useState<boolean>(false)
  const [t] = useTranslation()
  const setIsCopied = () => {
    setCopyText(true)
    setTimeout(() => {
      setCopyText(false)
    },1500)
  }

  return (
    <Container>
      <BodyValue onClick={() => {
        setIsCopied()
        copy(toCopy || children)
        }}
      >
        {children}
      </BodyValue>
      {copyText ?
        <CopyContainer>
          <BodySmall>
            {t("COPIED")}
          </BodySmall>
        </CopyContainer>
      : null}
    </Container>
  )
}

export default ClipBoardWrapper