import React, {FC, useState, useEffect, useRef} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import {
  Container,
  SearchWrapper,
  ImgArrow,
  OptionsWrapper,
  SearchInput,
  Title,
  IconWrapper,
  Divider,
  SearchResultsContainer,
  DividerCategory,
  DropOptionsTitle,
  DropItemWrapper,
  DropOptionItem,
} from './styles'
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import useDebounce from "../../hooks/useDebounce";
import { transformHitsToCategories } from "../../utils/search-response-handler";


// const AlgoliaClient = axios.create({
//   // baseURL: `${config.apiUrl}api/`,
//   // baseURL: 'https://core.ocplanet.cloud/backend/api/',
//   baseURL:`https://NQO6IXTPQW.algolia.net/1/indexes/a96759f341966142e7b893daa53a61a1/facets/facetName/query`,
//   withCredentials: true,
//   headers: {
//     'X-Algolia-Application-Id':'NQO6IXTPQW',
//     'X-Algolia-API-Key':'a96759f341966142e7b893daa53a61a1'
//     // 'Access-Control-Allow-Headers': "Cookie, Set-Cookie",
//   }
// });

const searchClient = algoliasearch('NQO6IXTPQW', 'a96759f341966142e7b893daa53a61a1')
type SearchProps = {
  searchString?:string
}

const SearchComponent:FC<SearchProps> = () => {
  const index = searchClient.initIndex('onecloudplanet')
  const selectRef:any = useRef()
  const [active, setActive] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [hits, setHits] = useState<any[]>([])
  const [activeResults, setActiveResults] = useState<boolean>(false)
  const [t] = useTranslation()
  useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
				document.removeEventListener('click', handleClickOutside, true);
		};
  },[]);

  const handleClickOutside = (event:any) => {
    if (selectRef && !selectRef.current.contains(event.target)){
      setActive(false)
      setActiveResults(false)
    }
  }

  const requestOptions:any = {
    headers:{

    },
    // queryParameters:{
      hitsPerPage: 100,
      facetFilters: [`language:${localStorage.getItem('lang') === 'uk' ? 'ua' : 'en'}`],
    // }
  }
  const debounce = useDebounce(search, 500)
  const searchFunc = () => {
    index
    .search(search, requestOptions)
    .then(({ hits }) => setHits(transformHitsToCategories(hits)))
  }
  useEffect(() => {
    searchFunc()

  },[debounce])

  return (
    <Container>
      <SearchWrapper onClick={() => {
        setActive(true)
        setTimeout(() => {
          setActiveResults(true)
        }, 10)
      }} activeResults={activeResults} active={active} >
        <div style={{display:'flex', width:'100%', alignItems:'flex-end'}}>
          <IconWrapper>
            <img src={icons.SearchIcon}/>
            <Title>
              {t("SEARCH")}
            </Title>
          </IconWrapper>
          <SearchInput
            autoComplete="off"
            placeholder={`${t("PRODUCTS_SERVICES_DOCS")}`}
            onClick={() => setActive(false)}
            active={active}
            value={search}
            onChange={(e:any) => setSearch(e.target.value)}
          />
        </div>
        {active ? 
            <ImgArrow src={icons.CloseIcon}/>
        :
          null
        }
      </SearchWrapper>
      <SearchResultsContainer active={activeResults}>
        <Divider active={activeResults}/>
        <OptionsWrapper style={{paddingTop:'18.5px', paddingBottom:'18.5px'}} ref={selectRef} active={activeResults}>
          {hits.map((hit:any, index:any) => (
            <>
              <DropOptionsTitle>
                {hit.title}
              </DropOptionsTitle>
              {hit.value.map((product:any) => (
                <DropItemWrapper onClick={() => window.open(product.url)}>
                  <img src={icons.Document}/>
                  <DropOptionItem>
                    {product.title}
                  </DropOptionItem>
                  
                </DropItemWrapper>
              ))}
              {index !== hits.length-1 &&
                <DividerCategory/>
              }
            </>
          ))}
        </OptionsWrapper>
      </SearchResultsContainer>
    </Container>
  )
}

export default SearchComponent