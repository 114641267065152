export function formatCurrency(value: number|string, currency: 'EUR' | 'USD' | 'PLN' | 'UAH'): string {

  const currencies = {
    EUR: '€',
    USD: '$',
    PLN: 'zł',
    UAH: '₴',
  };
  if(value === 0 || !currency){
    return '0'
  }
  const roundedValue = Math.ceil(+value * 100) / 100;
  const formattedValue = roundedValue.toLocaleString('en-US', {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });

  return `${currencies[currency]} ${value || 0}`;
}

export const formatCurrencyString = (price:string) => {
  
  return {
    hour: price?.replaceAll(",", ".")
    .replaceAll('€','')
    .replaceAll(' ','')
    .replaceAll('$','')
    .replaceAll('zł','')
    .replaceAll('₴','')
    .replaceAll('EUR','')
    .replaceAll('USD','')
    .replaceAll('PLN','')
    .replaceAll('UAH',''),
    month: price?.replaceAll(",", ".")
    .replaceAll('€','')
    .replaceAll(' ','')
    .replaceAll('$','')
    .replaceAll('zł','')
    .replaceAll('₴','')
    .replaceAll('EUR','')
    .replaceAll('USD','')
    .replaceAll('PLN','')
    .replaceAll('UAH',''),
  }
}