import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CheckBox from '../checkbox'
import {
  Container,
  TabText,
  TabWrapper,
  AddInfoContainer,
  AddInfoText,
} from './styles'

type TabsProps = {
  tabs:any[],
  size:'small' | 'display',
  checked?:boolean,
  isSteps?:boolean,
  location?:any,
  onChange?:(tab:any) => void,
  block?:any,
  greyBack?:boolean,
  blockNavigate?:boolean,
  customStyles?:any,
}
type ItemTab = {
  item:any,
  size:'small' | 'display',
  isSteps?:boolean,
  last?:any,
  location?:any,
  onChange?:(tab:any) => void,
  checked?:boolean,
  blocked?:boolean,
  blockNavigate?:boolean
}
const Tabs:FC<TabsProps> = ({blockNavigate, block, tabs, size, onChange, checked, isSteps, location, greyBack, customStyles}) => {
  
  return (
    <Container style={customStyles} greyBack={greyBack} isSteps={isSteps}>
      {tabs.map((item:any, index:any) => {
        const blockedTab = item.slug === block?.route && block?.isBlock

        return(
        <div style={isSteps ? {display:"flex", width:'100%', flexDirection:'column'} : {display:"flex"}}>
          <TabItem
            key={item.id}
            location={location}
            isSteps={isSteps}
            blocked={blockedTab}
            size={size}
            item={item}
            onChange={onChange}
            blockNavigate={blockNavigate}
            checked={!blockedTab && (checked && location === 'finally' || checked &&  tabs.findIndex((item:any) => item.slug === location) > index)}
          />
          {item.additional_info ? 
            <AddInfoContainer>
              <AddInfoText active={!blockedTab && (checked && location === 'finally' || checked &&  tabs.findIndex((item:any) => item.slug === location) >= index)}>
                {item.additional_info}
              </AddInfoText>
            </AddInfoContainer>
          : null}
        </div>
      )})}
    </Container>
  )
}

export default Tabs

const TabItem:FC<ItemTab> = ({blockNavigate, blocked, item, size, isSteps,last, location, checked, onChange}) => {
  const [hover, setHover] = useState<boolean>(false)
  const navigate = useNavigate()
  return (
    <TabWrapper
      onClick={onChange ? () =>  {
        if(!blockNavigate){
          navigate(`${item.slug}`)
        }
        onChange(item.slug) 
      } : () => {}}
      isSteps={isSteps}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      active={!blocked &&  (location === item.slug || checked || item.checked)} size={size}>
      <TabText isSteps={isSteps} hover={isSteps ? false : hover} active={!blocked &&  (location === item.slug || checked || item.checked)}>
        {item.title}
      </TabText>
      {checked ? 
        <CheckBox title={''} onChange={() => {}} value={checked}/>
      :
        null
      }
    </TabWrapper>
  )
}