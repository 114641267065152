import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { instanceService } from "../../../../../api";
import { Button, RadioButtonGroup, TopProgress } from "../../../../../components";
import { useUi } from "../../../../../context/ui.context";
import {
  Container,
  Title,
  ButtonsWrapper,
  LogContainer,
  LogText
} from './styles'
import LogViewer from './logView'
type SystemLogsProps = {
  instance:any
}
const SystemLogs:FC<SystemLogsProps> = ({instance}) => {
  const [lines, setLines] = useState<string>('30')
  const [log, setLog] = useState<string>('')
  const {viewAlert} = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [t] = useTranslation()
  const _getConsoleLog = () => {
    setPageLoading(true)
    instanceService.getConsoleOutput(instance.id, lines)
    .then((res) => {
      setLog(res.data.console_text)
      setPageLoading(false)
    })
    .catch((err) => {
      if(err?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:err.response.data.detail,
        })
      }
    })
  }
  useEffect(() => {
    _getConsoleLog()
  },[lines])

  
  return(
    <Container>
      <Title>
        {t("NUMER_OF_LINES_TO_SHOW")}
      </Title>
      <ButtonsWrapper>
        <div style={{width: '299px', marginRight:'10px'}}>
          <RadioButtonGroup
            toolTip={false}
            title={''}
            buttons={buttons}
            selectedButton={lines}
            onChange={setLines}
          />
        </div>
        <Button
          variant='stroke'
          size='display'
          isFill
          customStyles={{marginTop:'3px'}}
          onClick={_getConsoleLog}
          arrow
          title={t("REFRESH_SESSION")}
        />
      </ButtonsWrapper>
      {pageLoading ? 
        <TopProgress loading={pageLoading}/>
      :
        <LogContainer>
          <LogViewer log={log}/>
          {/* <LogText>
            {log}
          </LogText> */}
        </LogContainer>
      }
    </Container>
  )
}

export default SystemLogs

const buttons = [
  {
    value:'30',
    id:'30',
    title:'30'
  },
  {
    value:'100',
    id:'2',
    title:'100'
  },
  {
    value:'500',
    id:'3',
    title:'500'
  },
  {
    value:'1000',
    id:'4',
    title:'1000'
  },
]
