import React, { FC, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  IconBackground,
  HeadWrapper,
  BodyModal,
  GreyText,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import SaveIcon from './save.svg'
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(isSave?:boolean) => void;
}  

const UnsavedModal:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel
  } = props
  const [t] = useTranslation()
  
  return(
    <Container>
      <HeadWrapper>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <IconBackground>
          <img style={{width:'50px', height:'50px'}} src={SaveIcon}/>
        </IconBackground>
        <Typography variant="h3">
          {t("UNSAVED_CHANGES")}
        </Typography>
        <GreyText>
          {t("UNSAVED_INFO")}
        </GreyText>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '157px', justifyContent:'center' }}
            onClick={() => closeModal(false)}
            title={t("DISCARD")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '157px', justifyContent:'center' }}
            onClick={() => closeModal(true)}
            title={t("SAVE_CHANGES")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default UnsavedModal