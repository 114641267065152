import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { clientService } from "../../../../../api";
import { Button, ModalVariables, Table, TopProgress } from "../../../../../components";
import { useUi } from "../../../../../context/ui.context";
import { useAuth } from "../../../../../context/user.context";
import { icons } from "../../../../../icons";
import {
  PageContainer
} from '../styles'

const Users:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [modalType, setModalType] = useState<any>('')
  const [users, setUsers] = useState<any[]>([])
  const [selectedUserId, setSelectedUserId] = useState<any>(false)
  const [trigger, setTrigger] = useState(false)
  const [userToEdit, setUserToEdit] = useState()
  const {viewAlert} = useUi()
  const {id} = useParams()
  const {
    setAvailableNotifications,
    setAvailableRoles,
    setClientToInvite,
    clientForInvite
  } = useAuth()
  const [paginationData, setPaginationData] = useState<any>()
  const updateUsers = () => {
    if(id){
      clientService.getClientUsers(id)
      .then((res:any) => {
        setUsers([...res.data.users, ...res?.data?.invited_users].map((user:any) => {
          return {
            ...user,
            email:user.email,
            _invitation:!user.invitation ? t("YES") : t("NO"),
            roles:user.roles.map((role:any) => role.display_name),
            id:user.id,
            invitation:user.invitation,
            _roles:user.roles,
            invitation_id:user.invitation_id,
          }
        }))
      })
      .catch((e:any) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
  }
  const closeModal = () => {
    if(modalType === 'universal'){
      _dissociateUser(userToEdit)
      setModalType(null)
    }
    setViewModal(false)
    setActiveAnimation(false)
    updateUsers()
    // navigate("/instances")
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }

  const getDataForInvite = async () => {
    const roles:any = await clientService.getAvailableRoles(id)
    const notifications:any = await clientService.getUserNotificationSettings(id)
    setAvailableNotifications(
      Object.entries(notifications.data.detail).map((e) => (  e[1] ))
      )
    setAvailableRoles(roles.data.roles)
    setTrigger(!trigger)
  }
  useEffect(() => {
    getDataForInvite()
  },[])
  useEffect(() => {
    if(id) {
      clientService.getClient(id)
      .then((res) => {
        setClientToInvite(res.data)
        updateUsers()
      })
      .catch((e) => {
        console.log('err get client', e);
      })
    }
  },[])

  const _dissociateUser = (user:any) => {
    if(user?.notInvited) {
      clientService.deleteUserFromClientNotInvited(user.invitation_id)
      .then((res) => {
        if(res.data.detail === 'User dissociated'){
          viewAlert({
            severity:'info',
            message:res.data.detail
          })
        }
        setViewModal(false)
        setActiveAnimation(false)
        updateUsers()
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
      })
    } else {
      const data = {
        id: id,
        relation_id: user.invitation_id
      }
      clientService.dissociateUser(id, data)
      .then((res) => {
        if(res.data.detail === 'User dissociated'){
          viewAlert({
            severity:'info',
            message:res.data.detail
          })
        }
        setViewModal(false)
        setActiveAnimation(false)
        updateUsers()
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
      })
    }
  }
  const items = [
    {
      title:t("EDIT_ROLES"),
      id:'2',
      onClick:(item:any) => {
        setSelectedUserId(item)
        openModal('edit-roles')
      },
      checkField:'invitation',
      icon:icons.Clients,
      divider:false
    },
    {
      title:t("EDIT_NOTIFICATION_SETTINGS"),
      id:'3',
      onClick:(item:any) => {
        setSelectedUserId(item)
        openModal('edit-notifications')
      },
      checkField:'invitation',
      icon:icons.Notification,
      divider:false
    },
    {
      title:t("DISSOCIATE_USER"),
      id:'4',
      onClick:(item:any) => {
        setUserToEdit(item)
        openModal('universal')
      },
      icon:icons.MinusDefault,
      divider:false
    },
  ]
  const limitedItems = [
    {
      title:t("DISSOCIATE_USER"),
      id:'6',
      icon:icons.MinusDefault,
      onClick:(item:any) => {
        setUserToEdit({...item, notInvited:true});
        openModal('universal')
      },
      divider:false
    },
  ]
  const columns = [
    {
      title:t("EMAIL"),
      key:"email"
    },
    {
      title:t("ACCEPTED_INVITE"),
      key:"_invitation"
    },
    {
      title:t("ROLES"),
      key:"roles",
      withBackground:true,
    },
    {
      title:"",
      key:"props"
    }
  ]
  
  return(
    <PageContainer style={{display:'block'}}>
      <TopProgress loading={pageLoading}/>
      {pageLoading ? null :
        <>
          {clientForInvite ? 
            <Button
              variant="primary"
              size="display"
              customStyles={{marginBottom:"10px"}}
              onClick={() => openModal('invite-user')}
              title={t("INVITE_USER")}
            />
          : null}
          <Table
            columns={columns}
            rows={users}
            settings={items}
            paginationData={paginationData}
            limitedItems={limitedItems}
            // updateData={updateList}
            hideOther
            trigger={trigger}
          />
          <ModalVariables
            id={selectedUserId}
            modalType={modalType}
            closeModalCancel={closeModalCancel}
            viewModal={viewModal}
            activeAnimation={activeAnimation}
            closeModal={closeModal}
            alertTitle={t("DISSOCIATE_USER")}
            alertSubTitle={t("DISSOCIATE_USER_ALERT")}
          />
        </>
      }
    </PageContainer>
  )
}

export default Users

