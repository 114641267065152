import React, { FC, useState, useEffect } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider,
  CheckBoxWrapper,
  RolesContainer,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import { HeadLine3 } from "../../typography/styled";
import CheckBox from "../../checkbox";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/user.context";
import { clientService } from "../../../api";
import { useUi } from "../../../context/ui.context";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:() => void;
}  


const InviteUser:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
  } = props
  const {
    availableNotifications,
    availableRoles,
    clientForInvite
  } = useAuth()
  const [t] = useTranslation()
  const [selectedRoles, setSelectedRoles] = useState<any[]>([])
  const {id} = useParams()
  const [general, setGeneral] = useState<boolean>(true)
  const [billing, setBilling] = useState<boolean>(true)
  const [technical, setTechnical] = useState<boolean>(true)
  const [cc_tickets, setCc_tickets] = useState<boolean>(true)
  const {viewAlert} = useUi()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  const [initialValues, setInitialValues] = useState<any>({
    email:'',
  })
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      id: clientForInvite.id,
      notifications_settings: [
        {target_category: "general", enabled: general},
        {target_category: "billing_and_service", enabled: billing},
        {target_category: "technical", enabled: technical},
        {target_category: "cc_to_all_ticket_emails", enabled: cc_tickets}
      ],
      roles: selectedRoles || [],
      email: values.email,
    }
    clientService.inviteUserCustom(data)
    .then((res) => {
      setLoadingComplete(true)
      viewAlert({
        severity:'info',
        message:`${t("INVITE_SENDED_SUCCESS")}`
      })
      setTimeout(() => {
        setLoadingComplete(false)
        setLoading(false)
        closeModal()
        resetForm()
        setSelectedRoles([])
        setGeneral(true)
        setBilling(true)
        setTechnical(true)
        setCc_tickets(true)
      },800)
    })
    .catch((e) => {
      setLoading(false)
      setLoadingComplete(false)
      if(e?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:e?.response?.data?.detail
        })
      }
    })
  }
  const editSelectedRoles = (id:string) => {
    const founded = selectedRoles.find((item:any) => item === id)
    if(founded) {
      setSelectedRoles((prev:any) => {
        return prev.filter((item:any) => item !== id)
      })
    } else {
      setSelectedRoles([...selectedRoles, id])
    }
  } 
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      email: yup.string().email(`${t('NOT_VALID_EMAIL')}`).required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  // const roles = [
  //   {title:t("OWNER"), id:1},
  //   {title:t("PROGRAMMER"), id:2},
  //   {title:t("ACCOUNTER"), id:3},
  //   {title:t("MEMBER"), id:4},
  //   {title:t("ONLY_NOTIFICATIONS"), id:5},
  // ]
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  useEffect(() => {
    const listener = (event:any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSubmit()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {`${t("INVITE_USER_FOR_CLIENT")} ${clientForInvite.first_name} ${clientForInvite.last_name}`}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Input
          required
          title={t('USER_EMAIL')}
          fieldName='email'
          placeholder={''}
          toolTip={true}
          onBlur={handleBlur}
          {...formikProps}
        />
        <Divider/>
        <Typography variant='body-medium'>
          {t("SELECT_USER_ROLES")}
        </Typography>
        <RolesContainer>
          {availableRoles.map((item:any) => (
            <CheckBoxWrapper onClick={() => editSelectedRoles(item.id)}>
              <CheckBox
                title={item.display_name}
                value={selectedRoles.find((role) => role === item.id)} 
                onChange={() => editSelectedRoles(item.id)}
              />
            </CheckBoxWrapper>
          ))}
        </RolesContainer>
        <div style={{display:'flex', flexDirection:'column', gap:'10px', marginTop:'20px'}}>
          <Typography variant='body-medium'>
            {t("SELECT_NOTIFICATIONS_SETTINGS")}
          </Typography>
          <Typography variant="body">
            {t("NOTIFICATION_INFO")}
          </Typography>
        </div>
        <RolesContainer>
          <CheckBox
            title={t("GENERAL_ANNOUNCEMENTS")}
            value={general} 
            onChange={() => setGeneral(!general)}
          />
          <CheckBox
            title={t("BILLING_AND_SERVICE")}
            value={billing}
            onChange={() => setBilling(!billing)}
          />
          <CheckBox
            title={t("CC_TO_ALL_TICKETS_EMAIL")}
            value={cc_tickets}
            onChange={() => setCc_tickets(!cc_tickets)}
          />
          <CheckBox
            title={t("TECHNICAL_ANNOUNCEMENTS")}
            value={technical}
            onChange={() => setTechnical(!technical)}
          />
        </RolesContainer>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("INVITE_USER")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default InviteUser