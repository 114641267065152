import React, { FC, useState } from 'react'
import {
  Container,
  ToolTipImg,
  ToolTipView,
  LabelText,
  LabelWrapper,
  SmallText,
  Zirochka,
  DonutData
} from './styles'
import { icons } from '../../icons'
import { BodyMedium, BodySmall } from '../typography/styled'
import Typography from '../typography'
import { useTranslation } from 'react-i18next'

type LabelProps = {
  title:string|any,
  tooltipText?:any,
  toolTip?:boolean,
  margin?:string,
  reverse?:boolean,
  thinText?:boolean,
  required?:boolean,
  isDonut?:boolean,
  value?:any,
  valueMax?:any,
}

const Label:FC<LabelProps> = (props) => {
  const {
    title,
    required,
    tooltipText,
    margin,
    toolTip=true,
    reverse,
    thinText,
    isDonut,
    value,
    valueMax,
  } = props
  const [hover, setHover] = useState<boolean>(false)
  const [t] = useTranslation()
  return (
    <Container>
      <LabelWrapper reverse={reverse}>
        {thinText ?
          <SmallText>{title}{required && <Zirochka>*</Zirochka>}</SmallText>  
        :
          <LabelText>{title}{required && <Zirochka>*</Zirochka>}</LabelText>
        }
        <div style={{position:'relative', display:'flex'}}>
          {toolTip ? 
            <ToolTipImg
              reverse={reverse}
              onMouseLeave={() => setHover(false)}
              onMouseEnter={() => setHover(true)}
              src={icons.IconInfo}
            />
          : null }
          {hover && tooltipText && !reverse ? 
            <ToolTipView>
              {isDonut && 
                <DonutData>
                  <BodySmall style={{textTransform:"lowercase"}}>
                    min-max  {t("ON")} {title}
                  </BodySmall>
                  <BodyMedium>
                    {value} - {valueMax}
                  </BodyMedium>
                </DonutData>
              }
              <Typography variant='small'>
                {tooltipText}
              </Typography>
            </ToolTipView> 
          :
            null
          }
        </div>
      </LabelWrapper>
    </Container>
  )
}

export default Label