import React, { FC, useEffect, useState } from "react";
import {
  Container,
  Block,
  TextBlock,
  InfoText,
  DocsBlock,
  DocsWrapper,
  IconLinkContainer,
  StyledIllu,
} from "./styles";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Table,
  Button,
  TopProgress,
  ModalVariables,
} from "../../../../../components";
import IconLink from "./link-ic.svg";
import Frame1 from "./frame-1-light.svg";
import Frame1Dark from "./frame-1-dark.svg";
import Frame2 from "./fame-2.svg";
import { useTheme } from "styled-components";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { objStorageService } from "../../../../../api";
import { icons } from "../../../../../icons";
import { useUi } from "../../../../../context/ui.context";
import {
  GreenDot,
  Greentext,
  StateWrapper,
} from "../../../../Instances/styles";

const Component: FC = () => {
  const [t] = useTranslation();
  const theme: any = useTheme();
  const [rules, setRules] = useState<any>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { bucketId } = useParams();
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false);
  const [modalType, setModalType] = useState<any>("");
  const [alertTitle, setAlertTitle] = useState<any>();
  const [alertSubTitle, setAlertSubTitle] = useState<any>();
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRule, setSelectedRule] = useState<any>(null);
  const [rulesClear, setRulesClear] = useState<any[]>([]);

  const { viewAlert } = useUi();

  const openModal = (modal: any) => {
    setModalType(modal);
    setViewModal(true);
    setActiveAnimation(true);
  };
  const closeModalCancel = () => {
    setViewModal(false);
    setModalType("");
    setActiveAnimation(false);
  };
  const getData = () => {
    if (bucketId) {
      objStorageService
        .getRules(bucketId)
        .then((res) => {
          setRulesClear(res.data.Rules);
          setRules(res.data?.Rules?.map((rule: any) => {
              return {
                name: rule.ID,
                prefix: rule.Prefix,
                status: (
                  <StateWrapper>
                    <GreenDot active={rule.Status} />
                    <Greentext active={rule.Status}>{rule.Status}</Greentext>
                  </StateWrapper>
                ),
                dropOptions:[
                  {
                    title: t("EDIT"),
                    id: "5",
                    icon: icons.Edit,
                    onClick: (file: any) => {
                      navigate(`/object-storage/buckets/${bucketId}/lifecycle-rules/${rule.ID}/edit`)
                    },
                    divider: false,
                  },
                  {
                    title: rule.Status === "Enabled" ? t("DISABLE") : t("ENABLE"),
                    id: "1",
                    icon: icons.ToggleIcon,
                    onClick: async (file: any) => {
                      setLoading(true);
                      try {
                        if (file) {
                          //@ts-ignore
                          await objStorageService.createRule(bucketId,
                            res.data?.Rules?.map((ruleClear: any) => {
                              return ruleClear.ID === rule.ID
                              ?  {
                                  ...ruleClear,
                                  Status: rule.Status === "Disabled" ? "Enabled" : "Disabled",
                                }
                              : ruleClear
                            })
                          );
                        }
                        getData();
                        setLoadingComplete(true);
                        setTimeout(() => {
                          setLoading(false);
                          setLoadingComplete(false);
                          setViewModal(false);
                          setActiveAnimation(false);
                          setModalType("");
                        }, 1000);
                      } catch (e: any) {
                        if (e?.response?.data.detail) {
                          viewAlert({
                            severity: "error",
                            message: e.response.data.detail,
                          });
                          setLoading(false);
                        }
                      }
                    },
                    divider: false,
                  },
                  {
                    title: t("DELETE"),
                    id: "7",
                    onClick: (file: any) => {
                      openModal("delete");
                      setAlertTitle(t("FILE"));
                      setAlertSubTitle([file]);
                      setSelectedRule(file);
                    },
                    icon: icons.DeleteIcon,
                    divider: false,
                  },
                ],
                transition: rule.Transition,
                abortIncompleteMultipartUpload:
                  rule.AbortIncompleteMultipartUpload,
                expiration: rule.Expiration,
              };
            })
          ||[]);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPageLoading(false);
        });
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const deleteRule = async () => {
    setLoading(true);
    try {
      if (selectedRule) {
        //@ts-ignore
        await objStorageService.createRule(bucketId,
          rulesClear?.filter((rule: any) => rule.ID !== selectedRule.name)
        );
      }
      getData();
      setLoadingComplete(true);
      setTimeout(() => {
        setLoading(false);
        setLoadingComplete(false);
        setViewModal(false);
        setActiveAnimation(false);
        setModalType("");
      }, 1000);
    } catch (e: any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: "error",
          message: e.response.data.detail,
        });
        setLoading(false);
      }
    }
  };
  const closeModal = async (props?: any) => {
    if (modalType === "delete") {
      deleteRule();
    }
  };
  // const props = 
  if (pageLoading) return <TopProgress loading={pageLoading} />;
  return (
    <Container>
      <Block>
        <TextBlock>
          <InfoText>{t("LIFECYCLE_CONFIGURATION_TITLE")}</InfoText>
          <Button
            variant="primary"
            size="display"
            onClick={() =>
              navigate(
                `/object-storage/buckets/${bucketId}/create-lifecycle-rule`
              )
            }
            title={`+ ${t("CREATE_LIFECYCLE_RULE")}`}
          />
        </TextBlock>
      </Block>
      <Block>
        <Typography variant="h1">{t("BUCKET_INFO")}</Typography>
        <div>
          <Table
            columns={[
              {
                title: t("NAME"),
                key: "name",
              },
              {
                title: t("STATUS"),
                key: "status",
              },
              {
                title: t("SCOPE"),
                key: "scope",
              },
              {
                title: t("OBJECT_TAGS"),
                key: "object_tags",
              },
              {
                title: "",
                key: "props",
              },
            ]}
            rows={rules}
            hideOther
            hideCheckbox
            // settings={props}
            // paginationData={paginationData}
          />
        </div>
      </Block>
      <Typography variant="h1">{t("RECOMMENDED_FOR_YOU")}</Typography>
      <Block style={{ gap: "10px" }}>
        <DocsWrapper>
          <DocsBlock>
            <IconLinkContainer>
              <img src={IconLink} alt="IconLink" />
            </IconLinkContainer>
            <div>
              <InfoText>{t("OPTIMIZE_PERFOMANCE_TITLE")}</InfoText>
              <Typography variant="h3">
                {t("OPTIMIZE_PERFOMANCE_DESCRIPTION")}
              </Typography>
            </div>
            <StyledIllu src={theme.isDark ? Frame1Dark : Frame1} />
          </DocsBlock>
          <DocsBlock>
            <IconLinkContainer>
              <img src={IconLink} alt="IconLink" />
            </IconLinkContainer>
            <div>
              <InfoText>{t("PROTECT_DATA_TITLE")}</InfoText>
              <Typography variant="h3">
                {t("PROTECT_DATA_DESCRIPTION")}
              </Typography>
            </div>
            <StyledIllu style={{ top: "12px", right: "34px" }} src={Frame2} />
          </DocsBlock>
        </DocsWrapper>
      </Block>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        instance={{ ...selectedRule, bucketId: bucketId }}
        loading={loading}
        loadingComplete={loadingComplete}
        alertSubTitle={alertSubTitle}
      />
    </Container>
  );
};

export default Component;
