import 'react-international-phone/style.css';
import {
  Container,
  Countries,
  SelectedCountry,
  ImgArrow,
  CountriesList,
  Country,
  SelectInput,
} from './styles'
import React, { FC, useEffect, useState, useRef } from 'react';
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import ArrowDown from './arrow-down.svg'
import Typography from '../typography';

const PhoneInput:FC<any> = ({ value, onChange, ...restProps }) => {
  const selectRef:any = useRef()
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: localStorage.getItem('region_ocp_zone') === 'ua-1' ? 'ua' : 'pl',
      value,
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone);
      },
    });
  const [active, setActive] = useState<boolean>(false)
  const [activeInput, setActiveInput] = useState<boolean>(false)

  useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
				document.removeEventListener('click', handleClickOutside, true);
		};
  },[]);


  const handleClickOutside = (event:any) => {
    if (selectRef && !selectRef.current.contains(event.target)){
      setActive(false)
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
  const handleEsc = (event:any) => {
    if (
      event.keyCode === 27
    ) {
      setActive(false)
   }
  };
  console.log('country',country);
  return (
    <Container
      ref={selectRef}
      color="primary"
      // placeholder="Phone number"
      active={active || activeInput}
      // onChange={handlePhoneValueChange}
      //@ts-ignore
      
    >
      <Countries>
        <SelectedCountry  onClick={() => setActive(!active)}>
          <FlagImage
            iso2={country.iso2}
            style={{ marginRight: '8px' }}
          />
          <ImgArrow active={active} src={ArrowDown}/>
        </SelectedCountry>
        <SelectInput
          ref={inputRef}
          value={inputValue}
          onFocus={() => setActiveInput(true)}
          onBlur={() => setActiveInput(false)}

          onChange={(e) => handlePhoneValueChange(e)}
        />
        {active &&
          <CountriesList>
            {defaultCountries.map((c:any) => {
              const country = parseCountry(c);
              return(
                <Country onClick={() => {
                  setCountry(country.iso2)
                  setActive(false)
                }}>
                  <FlagImage
                    iso2={country.iso2}
                  />
                  <Typography variant='body'>{country.name}</Typography>
                  <Typography variant='body'>+{country.dialCode}</Typography>
                </Country>
              )
            })}

          </CountriesList>
        }
      </Countries>
    </Container>
  );
};

export default PhoneInput