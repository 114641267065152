import React, { FC, useEffect, useState } from 'react'
import AuthorizedRouter from './authorized.router'
import NoAuthorizedRouter from './noAuthorized.router'
import {RouterWrapper} from './styles'
import { Routes, Route} from 'react-router-dom';
import { useAuth } from '../context/user.context';
import {TechBreak} from '../pages'
import ApiClient from '../api/apiClient';
import { affiliateService, authService } from '../api';
import { useQueryParam, StringParam } from 'use-query-params';
import { useCookies } from 'react-cookie';
// import { useQueryParam, StringParam, NumberParam, ArrayParam } from 'use-query-params';

type RouterProps = {
  isAuth:boolean
}
  
          
const RootRouter:FC<RouterProps> = ({isAuth}) => {
  const {user, networkErr, setNetworkErr, techWork, setTechWork} = useAuth()
  ApiClient.interceptors.response.use(response => {
    return response;
  }, error => {
    console.log('errinterceptors',error);
    if(error.code === "ERR_NETWORK" && error.config.url !== "current-user"){
      setTechWork(true)
      setNetworkErr(true)
      const interval = setInterval(() => {
        authService.getUser()
        .then((res) => {
          clearInterval(interval)
          setTechWork(false)
          setNetworkErr(false)
        })
        .catch((err:any) => {
          
        })
      },3000)
    } else {
      return Promise.reject(error);
    }
  });


  // useEffect(() => {
  //   if(cookies?.refCode && cookies?.refCode?.length > 0){
  //     // console.log('pidor', cookies);
  //     affiliateService.addToReff({link:`https://ocp-front-dev.netengi.com?ref=${cookies?.refCode}`})
  //   }
  // },[cookies])
  // useEffect(() => {
  //   if(ref && ref?.length > 0){
  //     affiliateService.addToReff({link:`https://ocp-front-dev.netengi.com?ref=${ref}`})
  //   }
  // },[ref])
  return(
      <RouterWrapper>
        <Routes>
          {/* {user !== null && user?.user !== undefined ?  */}
          {techWork ?
            <Route path='/*' element={<TechBreak/>}/>
          :
            <Route path='/*' element={<AuthorizedRouter/>}/>
          }
          {/* //   :
          //   <Route path='/*' element={<NoAuthorizedRouter/>}/>
          // } */}
        </Routes>
      </RouterWrapper>
  )
}

export default RootRouter