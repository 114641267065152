import styled from "styled-components";
import { InfoContainer } from "../../../../components/info-block/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap:20px;
`
export const InputWrapper = styled.div`
  display: flex;
  gap:10px;
  align-items: center;
  position: relative;
`

export const ReportingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
`

export const ReportingItems = styled.div`
  display: flex;
  gap:10px;
  flex-wrap: wrap;
  width:100%;
`

export const ReporingItem = styled(InfoContainer)`
  display: flex;
  flex-direction:column;
  gap:10px;
  width:535px;
  flex: none;
  flex-basis:0;
  flex-grow: 1;

`
export const InfoBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  width:535px;
  height:100%;
  border-radius: 8px;
  padding: 20px;
  gap:24px;
  background-color: ${({theme}) => theme.grey[100]};
  position: relative;
  box-sizing: border-box;
  flex: none;
  flex-basis:0;
  overflow: hidden;
  flex-grow: 1;
`
export const ImgBig = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;

`
export const CopyContainer = styled.div`
  position:absolute;
  
  top:-10px;
  left:240px;
  /* right: 0px; */
  white-space: nowrap;
  /* width: 100px; */
  border-radius: 4px;
  padding: 2px 4px;
  background: ${({theme}) => theme.isDark ? '#474B53' : theme.palette[400]};

`