import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 20px;
  box-sizing: border-box;
  width: 400px;
  /* height: 350px; */
  left: 540px;
  top: 305px;
  background: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
export const DatePickerWrapper = styled.div`
  [data-slot='input-wrapper']{
    border:${({theme}) => `1px solid ${theme.grey[350]}`};
    &:focus{
      border: ${({theme}) => theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[300]}`};
      box-shadow: ${({theme}) => theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`};
    }
    &:active{
      border: ${({theme}) => theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[300]}`};
      box-shadow: ${({theme}) => theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`};
    }
    &:hover{
      border: ${({theme}) => theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[300]}`};
      box-shadow: ${({theme}) => theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`};

    }
  }
  [data-slot="cell"] {

    .data-\[selected\=true\]\:bg-primary[data-selected="true"] {
      background-color: ${({theme}) => theme.primary[700]}!important;
      color:#FFF;
    }
    [data-selected] {
      background-color: ${({theme}) => theme.primary[700]}!important;
      color:#FFF;
    }
  }
`
export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`
export const BodyModal = styled.div`
  display:flex;
  flex-direction: column;
  /* align-items: center; */
  gap:20px;
  padding:0px 10px;
`

export const Icon = styled.img`
  &:hover{
    cursor: pointer;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  gap:10px;
`

export const CheckboxContainer = styled.div<{active:boolean}>`
  padding: 20px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 8px;
  gap: 6px;
  /* box-shadow: 0px 1px 2px 0px #0000001A; */
  /* border: 1px solid #E8E8E8 */
  background-color: ${({theme}) => theme.palette[10]};
  box-shadow: ${({active, theme}) => active ? '' : '0px 1px 2px rgba(0, 0, 0, 0.1)'};
  border: ${({theme, active}) => active ? `1px solid ${theme.primary[200]}` : `1px solid ${theme.palette[20]}`};
  &:hover{
    cursor: pointer;
    opacity:0.8;
  }
`