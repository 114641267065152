import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";



const apiAccessService = {
  getTokens:() =>  ApiClient.get('tokens/'),
  revokeToken:(id:string|number) =>  ApiClient.delete(`tokens/${id}/`),
  createToken:(data:Object) => ApiClient.post('tokens/', data),
  downloadYaml:(id:string|number) => ApiClient.get(`tokens/${id}/download_yaml_file/`),
  downloadOpenrc:(id:string|number) => ApiClient.get(`tokens/${id}/download_openrc_file/`),
}

export default apiAccessService