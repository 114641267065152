import React, {FC, useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  HeaderWrapper,
  BodyWrapper,
  OperationDot,
  OperationStatus,
  OperationStatusContainer,
  StepsTitleWrapper,
  StepsTitle,
  StepCount,
  StepCountNumber,
  StepsBodyWrapper,
  StepContainer,
  ErrorContainer,
  StepName,
  ErrorText,
  RotateImg
} from './styles'
import { operationService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { BackArrow, InfoBlock, ModalVariables, TopProgress, Typography } from "../../../components";
import { useTranslation } from "react-i18next";
import {timeConverterMMDDYYHHMM} from '../../../utils/dates'
import { InfoContainer, InfoTitle } from "../../../components/info-block/styles";
import MenuButton from "../../../components/button/menu-button";
import { icons } from "../../../icons";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";
import OperationSkeleton from './skeleton'
import { handleStatusName } from "../../../utils/handle-status-name";

const OperationPage:FC = () => {
  const {operationId} = useParams()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const theme:any = useTheme()
  const navigate = useNavigate()
  const [operationData, setOperationData] = useState<any>()
  const [otherDetails, setOtherDetails] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const _updateOperation = () => {
    if(operationId){
      operationService.getOperationById(operationId)
      .then((res) => {
        setOperationData(res.data)
        setOtherDetails([
          {
            title:t("OPERATION_TYPE"),
            value:res.data.operation_type,
          },
          {
            title:t("CREATED_AT"),
            value:timeConverterMMDDYYHHMM(res.data.created_at),
          },
          {
            title:t("COMPLETED"),
            value:res.data.completed ? 'Yes' : 'No',
          },
          {
            title:t("PRIMARY_OBJECT_ID"),
            copy: res?.data?.primary_object_id && true,
            value:res?.data?.primary_object_id || '-',
          },
          // {
          //   title:t("PRIMARY_OBJECT_ID"),
          //   value:res?.data?.primary_object_id || '-',
          // },
        ])
      })
      .catch((err:any) => {
        
        if(err?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:err.response?.data?.detail
          })
        }
        navigate('/operations')
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
  }
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = () => {
    if(modalType === 'universal'){
      if(alertTitle === t("RETRY_OPERATION")){
        setLoading(true)
        operationService.retryOperationStart(operationData.id)
        .then((res) => {
          if(res?.data?.detail){
            viewAlert({
              severity:'info',
              message: res.data?.detail
            })
            _updateOperation()
          }
        })
        .catch((err) => {
          if(err?.response?.data?.detail){
            viewAlert({
              severity:'error',
              message: err?.response?.data?.detail
            })
          }
        })
        .finally(() => {
          setViewModal(false)
          setActiveAnimation(false)
          setLoading(false)
        })
      } else if (alertTitle === t("ABORT_OPERATION")) {
        setLoading(true)
        operationService.abortOperation(operationData.id)
        .then((res) => {
          if(res?.data?.detail){
            viewAlert({
              severity:'info',
              message: res.data?.detail
            })
            _updateOperation()
          }
        })
        .catch((err) => {
          viewAlert({
            severity:'error',
            message: err?.response?.data?.detail || t("ERROR_OCCURRED")
          })
        })
        .finally(() => {
          setViewModal(false)
          setActiveAnimation(false)
          setLoading(false)
        })
      } else {
        setViewModal(false)
        setActiveAnimation(false)
        setLoading(false)
      }
    } else {
      setViewModal(false)
        setActiveAnimation(false)
        setLoading(false)
    }
    
  }
  useEffect(() => {
    _updateOperation()
    const interval = setInterval(() => {
      _updateOperation()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[])
  if(pageLoading) return <OperationSkeleton pageLoading={pageLoading}/>
  return(
    <Container>
      <BackArrow title={t("BACK_TO_OPERATIONS")} onClick={() => navigate('/operations')}/>
      <HeaderWrapper>
        <Typography variant="h1">
          {`${t("OPERATION")} ${operationData.id}`}
        </Typography>
        {operationData.status === 'succeeded' ? null : operationData?.status === 'in progress' ?
          <MenuButton 
            onClick={() => {
              setAlertTitle(t("ABORT_OPERATION"))
              setAlertSubTitle(`${t("ABORT_OPERATION_ALERT")} ${operationData.id}`)
              openModal('universal')
            }}
            >
            <img src={icons.CloseIconBold}/>
          </MenuButton>
         :
          <MenuButton 
            onClick={() => {
              setAlertTitle(t("RETRY_OPERATION"))
              setAlertSubTitle(`${t("ARE_YOU_SHURE_YOU_WANT_TO_RETRY_OPERATION")} ${operationData.id}`)
              openModal('universal')
            }}
            >
            <img src={icons.Restart}/>
          </MenuButton>
        }
      </HeaderWrapper>
      <OperationStatusContainer>
        <OperationDot active={operationData.status}/>
        <OperationStatus active={operationData.status}>
          {handleStatusName(operationData.status, t)}
        </OperationStatus>
      </OperationStatusContainer>
      <BodyWrapper>
        <InfoContainer
          style={{
            display:'flex',
            flex:'auto 1',
            marginBottom:'0px',
            flexGrow:'1',
            flexBasis:'0'
            }}>
          <InfoTitle>
            {t("PROGRESS")}
          </InfoTitle>
          <StepsTitleWrapper>
            <StepsTitle>
              {t("STEPS_COMPLETED")}
            </StepsTitle>
            <StepCount>
              <StepCountNumber>
                {operationData.progress.completed_steps}
              </StepCountNumber>
            </StepCount>
            <Typography variant="small-medium">
              /
            </Typography>
            <StepCount>
              <StepCountNumber>
                {operationData.progress.total_steps}
              </StepCountNumber>
            </StepCount>
          </StepsTitleWrapper>
          <StepsBodyWrapper>
            {operationData.progress.steps_details?.length > 0 && operationData.progress.steps_details.map((step:any) => (
              <StepContainer>
                <RotateImg active={step.status === 'in progress'} src={step.status !== 'succeeded' ? step.status === 'in progress' ? icons.Restart : icons.Error : icons.TwoMark}/>
                <StepName>
                  {step?.display || step.name}
                </StepName>
              </StepContainer>
            ))}
            {operationData.progress.steps_details?.length > 0 && operationData.progress.steps_details.map((step:any) => (
              step.status === 'failed' && (step.error_message || step?.timeout_error_message) ?
                <ErrorContainer>
                  <ErrorText>
                    {step?.error_message || step.timeout_error_message}
                  </ErrorText>
                </ErrorContainer>
              : 
                null
            ))}
          </StepsBodyWrapper>
        </InfoContainer>
        <InfoBlock title={t("OTHER_DETAILS")} 
        customStyles={{
          flex:'auto 1',
          marginBottom:'0px',
          flexGrow:'1',
          flexBasis:'0'
          }} data={otherDetails}/>
      </BodyWrapper>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
      />
    </Container>
  )
}

export default OperationPage