import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {
  EstimateContainer,
  EstimateTitle,
  EstimateSubTitle,
  EstimateDesc,
  StateWrapper,
  GreenDot,
  Greentext,
} from './styles'
import moment from 'moment'
import { handleStatusName } from "../../../../../utils/handle-status-name"

const DetailsComponent:FC<any> = ({ticket}) => {
  const [t] = useTranslation()
  return(
    <EstimateContainer>
      <EstimateTitle>
        {t("TICKET_DETAILS")}
      </EstimateTitle>
      <div style={{marginRight:'30px'}}>
        <EstimateSubTitle>
          {t("NUMBER")}
        </EstimateSubTitle>
        <EstimateDesc>
          {ticket.number}
        </EstimateDesc>
      </div>
      <div style={{marginRight:'30px'}}>
        <EstimateSubTitle>
          {t("DEPARTMENT")}
        </EstimateSubTitle>
        <EstimateDesc>
          {ticket.department.name}
        </EstimateDesc>
      </div>
      <div style={{marginRight:'30px', display:'flex', gap:"30px"}}>
        <div>
          <EstimateSubTitle>
            {t("STATUS")}
          </EstimateSubTitle>
            <StateWrapper>
              <GreenDot style={{backgroundColor:ticket.status.colour}} active={ticket.status.name}/>
              <Greentext  style={{textTransform:"capitalize", color:ticket.status.colour}} active={ticket.status.name}>
                {handleStatusName(ticket.status.name, t)}
              </Greentext>
            </StateWrapper>
        </div>
        <div>
          <EstimateSubTitle>
            {t("PRIORITY")}
          </EstimateSubTitle>
          <EstimateDesc style={{color:ticket.priority.colour}}>
            {ticket.priority.name}
          </EstimateDesc>
        </div>
      </div>
      <div style={{marginRight:'30px'}}>
        <EstimateSubTitle>
          {t("SUBMITTED")}
        </EstimateSubTitle>
        <EstimateDesc>
          {moment(ticket.created_at * 1000).fromNow()}
        </EstimateDesc>
      </div>
      <div style={{marginRight:'30px'}}>
        <EstimateSubTitle>
          {t("LAST_REPLY")}
        </EstimateSubTitle>
        <EstimateDesc>
          {/* {moment.unix().format("MM/DD/YYYY")} */}
          {moment(ticket.last_reply.created_at * 1000).fromNow()}
        </EstimateDesc>
      </div>
    </EstimateContainer>
  )
}

export default DetailsComponent