import React, {FC, useState, useEffect, useRef} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { BackArrow, Button, Input, LoaderDashed, LoaderSpinner, TextEditor, TopProgress } from "../../../components";
import { icons } from "../../../icons";
import {
  Container,
  TitleBig,
  RowHeader,
  BodyTicket,
  TicketOverflow,
  EnterDataContainer,
  TicketColumn
} from "./styles";
import DetailsComponent from "./components/ticket-details";
import TicketMessageComponent, { AttachmentComponent } from "./components/ticket-message";
import { supportPalService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { useAuth } from "../../../context/user.context";
import * as yup from 'yup';
import { useFormik } from 'formik';
import {AttachmentsWrapper} from './components/ticket-message/styles'


const TicketPage:FC = () => {
  const [t] = useTranslation()
  const {
    viewAlert
  } = useUi()
  const navigate = useNavigate()
  const {user} = useAuth()
  const [state, setState] = useState<any>()
  const [ticket, setTicket] = useState<any>()
  const [messages, setMessages] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [resolveLoading, setResolveLoading] = useState<boolean>(false)
  const {ticketId} = useParams()
  const [files, setFiles] = useState<any[]>([])
  const [prevFiles, setPrevFiles] = useState<any[]>([])
  const [initialValues, setInitialValues] = useState<any>({
    cc:'',
    message:'',
  })
  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false)
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([])
  // const { isLoading, uploaded, handleUploadFiles } = useFileUploader();

  const [statuses, setStatuses] = useState<any[]>([])
  const inputFileRef:any = useRef();
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  const getTickets = () => {
    if(ticketId){
      supportPalService.getTicketById(ticketId)
      .then((res) => {
        setTicket(res.data.data)
        supportPalService.getTicketMessagesById(ticketId)
        .then((resMessages:any) => {
          setMessages(resMessages.data.data)
          setPageLoading(false)
          setLoading(false)
        })
        .catch((err:any) => {
          if(err?.response?.data?.detail){
            viewAlert({
              severity:'error',
              message:err?.response?.data?.detail
            })
          }
          navigate(-1)
          setPageLoading(false)
          setLoading(false)
        })
      })
      .catch((err) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:err?.response?.data?.detail
          })
        }
        navigate(-1)
        setPageLoading(false)
        setLoading(false)
      })
    }
  }
  const getStatuses = () => {
    supportPalService.getStatus()
    .then((res) => {
      setStatuses(res.data.data.map((status:any) => {
        return{
          ...status,
          value:status.id.toString(),
          label: status.name,
          // icon:
        }
      }))
    })
    .catch((err) => {

    })
  }
  useEffect(() => {
    getTickets()
    getStatuses()
    const interval = setInterval(() => {
      getTickets()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const onSubmit = (values:any) => {
    setLoading(true)
    const data:any = {
      ticket_id:ticketId,
      // cc:values.cc,
      text:values.message,
      user_id:ticket.user_id
    }
    if(uploadedFiles.length > 0){
      data.attachment = uploadedFiles.map((file:any) => {
        return {
          filename:file.name,
          contents:file.base
        }
      })
    }
    setUploadedFiles([])
    supportPalService.addMessageToTicket(data)
    .then((res:any) => {
      getTickets()
      handleChange('message')('')
    })
    .catch((err) => {
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:err?.response?.data?.detail
        })
      }
      setLoading(false)
      // navigate(-1)
    })
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      cc: yup.string(),
      message: yup.string().required(`${t('REQUIRED_FIELD')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const resolveTicket = async () => {
    setResolveLoading(true)
    if(ticketId){
      const data = {
        id: ticketId,
        status: statuses.find((status:any) => status.name === 'Closed').id
      }
      supportPalService.editTicketById(ticketId, data)
      .then((res:any) => {
        getTickets()
      })
      .catch((err:any) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:err?.response?.data?.detail
          })
        }
      })
      .finally(() => {
        setResolveLoading(true)
      })
    }
  }
  const handleUploadFiles = (files:any) => {
    const uploaded:any = [...uploadedFiles];
    files.some((file:any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (uploaded.findIndex((f:any) => f.name === file.name) === -1 && reader.result) {
          //@ts-ignore
          const ext = reader?.result?.split(',')
          ext.shift()
          uploaded.push({
            type:file.type,
            name:file.name,
            size:file.size,
            base:ext.join(''),
            url:URL.createObjectURL(file)
          });
          setLoadingUploadFile(false)
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    })
    setUploadedFiles(uploaded)
  }
  
  const handleDelete = (name:any) => {
    setUploadedFiles((prev) => prev.filter((f:any) => f.name !== name))
  }
  const handleFileEvent =  (e:any) => {
    if (e.target.files && e.target.files[0]) {
      setLoadingUploadFile(true);
      const chosenFiles = Array.prototype.slice.call(e.target.files)
      handleUploadFiles(chosenFiles);
    }
  }
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow
        title={t("BACK_TO_TICKETS")}
        onClick={() => navigate(-1)}
      />
      <RowHeader>
        <TitleBig>
          {ticket.subject}
        </TitleBig>
        {ticket.status.name === 'Closed' ? null :
          <Button
            variant="stroke"
            size='small'
            loading={resolveLoading}
            title={t("MARK_AS_RESOLVED")}
            onClick={resolveTicket}
            icon={icons.HideEyeBlue}
          />
        }
      </RowHeader>
      <BodyTicket>
        <TicketColumn>
          <TicketOverflow>
            {messages.map((message:any) => (
              <TicketMessageComponent
                active={message.user.email !== user.user.email}
                email={message.user.email}
                displayName={message.user.firstname ? `${message.user.firstname} ${message.user.lastname}` : message.user.email}
                time={message.updated_at}
                message={message.text}
                attachments={message.attachments}
              />
            ))}
          </TicketOverflow>
          <EnterDataContainer>
            {/* <Input
              title={t("CC")}
              fieldName='cc'
              customStyles={{width:'360px'}}
              placeholder={t("ENTER_EMAIL_ADDRESS")}
              {...formikProps}
            /> */}
            <div style={{display:'flex', width:'100%', flexDirection:'column'}}>
              <TextEditor
                onChange={(e:any) => handleChange('message')(e)}
                fieldName={'message'}
                {...formikProps}
              />
            </div>
            <AttachmentsWrapper>
    
            {uploadedFiles.map((attachment:any) => (
              <AttachmentComponent 
                size={attachment.size}
                name={attachment.name}
                url={attachment.url}
                handleDelete={handleDelete}
                isUpload
              />
            ))}
            </AttachmentsWrapper>
            <input
              style={{display:'none'}}
              type="file"
              multiple
              ref={inputFileRef}
              onChange={handleFileEvent}
            />
            <Button
              variant="stroke"
              size="display"
              icon={icons.PlusDefault}
              loading={loadingUploadFile}
              customStyles={{flex:'0', marginBottom:'10px'}}
              onClick={onBtnClick}
              title={t("ADD_ATTACHMENT")}
            />
            <Button
              variant="primary"
              size="display"
              customStyles={{flex:'0', marginBottom:'10px'}}
              onClick={handleSubmit}
              loading={loading}
              title={t("POST")}
            />
          </EnterDataContainer>
        </TicketColumn>
        <DetailsComponent ticket={ticket}/>
      </BodyTicket>
    </Container>
  )
}

export default TicketPage