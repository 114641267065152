import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";

const clientService = {
  getOptions:() => ApiClient.get('clients/create_options'),
  createClient:(data:any) => ApiClient.post('clients', data),
  sendSms:(data:any) => ApiClient.post('tfa/send_code/', data),
  reSendSms:(data:any) => ApiClient.post('tfa/resend_code/', data),
  confirmEmail:(code:string) => ApiClient.post('confirm-email/', {token:code}),
  getClient:(id:string) => ApiClient.get(`clients/${id}`),
  getClientList:(queries:QueriesApi) => {
    return ApiClient.get(`clients?page_size=${queries.pageSize}&ordering=${queries.ordering}&page=${queries.page}`)
  },
  getClientUsers:(id:string, pageSize:any=20, ordering:any='-date_created') => ApiClient.get(`clients/${id}/get_users?page_size=${pageSize}&ordering=${ordering}`),
  inviteUserToClient:(id?:string, data?:any) => ApiClient.post(`clients/${id}/send_invitation`, data),
  getAvailableRoles:(id?:string) => ApiClient.get(`clients/${id}/get_available_roles`),
  getUserNotificationSettings:(id?:string) => ApiClient.get(`notifications/get_notifications_settings`),
  canDissociate:(id:string) => ApiClient.get(`clients/${id}/can_dissociate_as_owner`),
  dissociateFromClient:(id?:string) => ApiClient.post(`clients/${id}/dissociate_from_client`, {}),
  updateClient:(id?:string, data?:any) => ApiClient.put(`clients/${id}`, data),
  editUserNotifications:(id?:string, data?:any) => ApiClient.post(`clients/${id}/set_user_notifications_settings`, data),
  getUserNotifications:(client?:any, user?:any) => ApiClient.get(`clients/${client}/get_user_notifications_settings?user_id=${user}`),
  updateUserRoles:(id?:string, data?:any) => ApiClient.post(`clients/${id}/update_roles`, data),
  dissociateUser:(id?:string, data?:any) => ApiClient.post(`clients/${id}/dissociate_user`, data),

  inviteUserCustom:(data:Object) => ApiClient.post(`invites/`, data),
  addUserToClientCustom:(data:Object) => ApiClient.post(`invites/add_user_to_client/`,data),
  deleteUserFromClientNotInvited:(invite_id:any) => ApiClient.delete(`invites/${invite_id}`),
}

export default clientService