import React, { FC } from "react";
import {
  Info,
  InfoText
} from './styles'
import Label from "../label";
import { useTranslation } from "react-i18next";

type InfoProp = {
  title?:string,
  info:string,
  customStyles?:any
}

const RightInformation:FC<InfoProp> = ({title, info, customStyles}) => {
  const [t] = useTranslation()
  return (
    <Info customStyles={customStyles}>
      <Label reverse title={title ? title : t("INFORMATION")}/>
      <InfoText>
        {info}
      </InfoText>
    </Info>
  )
}

export default RightInformation