import React, { FC, useEffect, useState } from 'react';
import {
  Container,
  HeadWrapper,
  Icon,
  IconBackground,
  BodyModal,
  GreyText,
  NetworksWrapper,
  Title,
} from './styles';
import { networkService, portService, routerService } from '../../../api';
import { BodyDefault, BodyMedium, HeadLine3 } from '../../typography/styled';
import { useTranslation } from 'react-i18next';
import { icons } from '../../../icons';
import DeleteIcon from '../delete-warning/delete.svg'
import InfoBlock from '../../info-block';
import { InstanceWrapper } from '../rename/styles';
import Button from '../../button';
import TopProgress from '../../TopBarProgress';
import { useUi } from '../../../context/ui.context';
type ModalProps = {
  closeModalCancel: () => void;
  closeModal: (props?: any) => void;
  dataToEdit?: any,
  loading?: boolean,
  viewModal?: boolean,
  alertTitle?:string;
  alertSubTitle?:any;
}


const DeleteNetwork: FC<ModalProps> = (props) => {
  const {
    viewModal,
    closeModalCancel,
    closeModal,
    dataToEdit,
    // loading,
    alertTitle,
    alertSubTitle,
  } = props
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [network, setNetworks] = useState<any>()
  const [routers, setRouters] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [deletedSubnets, setDeletedSubnets] = useState<any[]>([])
  const [deletedInterface, setDeletedInterface] = useState<any[]>([1])
  const [deletedPorts, setDeletedPorts] = useState<any[]>([])

  const checkIntersection = (array1:any, array2:any) => {
    return array1.every((item1:any) => array2.some((item2:any) => item1 === item2.id));
  };
  
  const getNetworkInfo = async () => {
    setPageLoading(true)
    const routersRes = await routerService.getRouters({
      page:1,
      ordering:'',
      pageSize:100
    })
    // await setRouters(routersRes?.data?.objects)
    const routetsNew = await routersRes?.data?.objects?.map(async(router:any) => {
      const routerSingleRes = await routerService.getSingleRouter(router.id)
      return routerSingleRes.data
    })
    const nets = alertSubTitle.map(async (net:any) => {
      const resNetwork = await networkService.getNetwork(net.id)
      const resPorts = await networkService.getRelatedPorts(net.id, {
        page:1,
        ordering:'',
        pageSize:100
      })
      return {
        network:resNetwork.data,
        ports:resPorts.data?.objects
      }
    })
    Promise.all(nets)
    .then((results) => {
      setNetworks(results)
      Promise.all(routetsNew)
      .then(async (routers) => {
        setDeletedSubnets(results.reduce(
          (acc:any, val:any) => acc?.concat(val?.network?.subnets), []
        ))
        setDeletedInterface(routers
          .map((router:any) => {
            // if(router?.interfaces?.filter((interfaceR:any) => interfaceR?.fixed_ips?.filter((ip:any) => deletedSubnets.filter((sub:any) => sub.id === ip?.subnet_id)))?.length === 0) {
            //   return false
            // }
            // const routerSubnetIds = router.interfaces
            // .flatMap((interfaceK:any) => interfaceK.fixed_ips)
            // .map((fixed_ip:any) => fixed_ip.subnet_id);
          
            return {
              router:router.name,
              routerId:router.id,
              interfaces: router.interfaces.filter((interfaceK:any) => {
                const interfaceSubnetIds = interfaceK.fixed_ips.map((fixed_ip:any) => fixed_ip.subnet_id);
                return checkIntersection(interfaceSubnetIds, results.reduce(
                  (acc:any, val:any) => acc?.concat(val?.network?.subnets), []
                ))})
              
              
              // router?.interfaces?.filter((interfaceR:any) => interfaceR?.fixed_ips?.filter((ip:any) => deletedSubnets.filter((sub:any) => sub.id === ip?.subnet_id))),
            }
          }))

        setDeletedPorts(results?.reduce(
          (acc:any, val:any) => acc?.concat(val?.ports), []
        ))
        setRouters(routers.filter((router:any) => {
          const routerSubnetIds = router.interfaces
            .flatMap((interfaceK:any) => interfaceK.fixed_ips)
            .map((fixed_ip:any) => fixed_ip.subnet_id);
          return checkIntersection(routerSubnetIds, results.reduce(
            (acc:any, val:any) => acc?.concat(val?.network?.subnets), []
          ));
        })?.filter((rtr:any) => rtr?.interfaces?.length >= 1))
        setPageLoading(false)
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
    })
    .catch((error) => {
      console.error("Error occurred:", error);
    });

  }
  useEffect(() => {
    getNetworkInfo()
  }, [viewModal])
  const deleteNetworkFunc = async () => {
    setLoading(true)
    try{
      let promises:any[] = []
      // let promisesSubs:any[] = []
      let promisesPorts:any[] = []
      let promisesNetworks:any[] = []
        
      if(deletedInterface?.filter((val:any) => val?.interfaces?.length > 0)?.length > 0){
        deletedInterface?.filter((val:any) => val?.interfaces?.length > 0)?.map((int:any) => {
          int?.interfaces?.map((item:any) => {
            let data = {
              id:int.routerId,
              interface_id:item.id,
              options: "remove_interface"
            }
            //@ts-ignore
            let promise = routerService.deleteInterface(int.routerId, data)
            promises.push(promise)
          })
        })
          Promise.all(promises)
          .then((res) => {
            setTimeout(() => {
              if(deletedPorts?.length > 0){
                deletedPorts?.filter((fil:any) => !deletedInterface.reduce((acc:any, val:any) => acc?.concat(val?.interfaces), [])?.find((it:any) => it?.id === fil?.id))?.map((item:any) => {
                  let promisePort = networkService.deletePort(item?.id)
                  promisesPorts.push(promisePort)
                })
                Promise.all(promisesPorts)
                .then((res) => {
                  network?.map((item:any) => {
                    let promiseNet = networkService.deleteNetwork(item?.network?.id)
                    promisesNetworks.push(promiseNet)
                  })
                  Promise.all(promisesNetworks)
                  .then(async(res) => {
                    if(routers?.length > 0) {
                      let promisesRt:any[] = []
                      await routers.map(async(item:any) => {
                        let promise = await routerService.deleteRouter(item.id)
                        await promisesRt.push(promise)
                      })
                      Promise.all(promisesRt)
                      .then((res) => {
                        setLoadingComplete(true)
                        setTimeout(() => {
                          setLoadingComplete(false)
                          setLoading(false)
                          closeModal()
                        }, 1500);
                      })
                      .catch((err) => {
                        closeModalCancel()
                        setLoading(false)
                        viewAlert({
                          severity: 'error',
                          message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                        })
                      })
                    } else {
                      setLoadingComplete(true)
                      setTimeout(() => {
                        setLoadingComplete(false)
                        setLoading(false)
                        closeModal()
                      }, 1500);
                    }
                  })
                  .catch((err) => {
                    getNetworkInfo()
                    setLoading(false)
                    viewAlert({
                      severity: 'error',
                      message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                    })
                  })
                })
                .catch((err) => {
                  getNetworkInfo()
                  setLoading(false)
                  viewAlert({
                    severity: 'error',
                    message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                  })
                })
              } else {
                network?.map((item:any) => {
                  let promiseNet = networkService.deleteNetwork(item?.network?.id)
                  promisesNetworks.push(promiseNet)
                })
                Promise.all(promisesNetworks)
                .then(async(res) => {
                  if(routers?.length > 0) {
                    let promisesRt:any[] = []
                    await routers.map(async(item:any) => {
                      let promise = await routerService.deleteRouter(item.id)
                      await promisesRt.push(promise)
                    })
                    Promise.all(promisesRt)
                    .then((res) => {
                      setLoadingComplete(true)
                      setTimeout(() => {
                        setLoadingComplete(false)
                        setLoading(false)
                        closeModal()
                      }, 1500);
                    })
                    .catch((err) => {
                      closeModalCancel()
                      setLoading(false)
                      viewAlert({
                        severity: 'error',
                        message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                      })
                    })
                  } else {
                    setLoadingComplete(true)
                    setTimeout(() => {
                      setLoadingComplete(false)
                      setLoading(false)
                      closeModal()
                    }, 1500);
                  }
                })
                .catch((err) => {
                  getNetworkInfo()
                  setLoading(false)
                  viewAlert({
                    severity: 'error',
                    message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                  })
                })
              }
            },1000)
          })
          .catch((err) => {
            getNetworkInfo()
            setLoading(false)
            viewAlert({
              severity: 'error',
              message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
            })
          })
      } else {
        if(deletedPorts?.length > 0){
          deletedPorts?.map((item:any) => {
            let promisePort = networkService.deletePort(item?.id)
            promisesPorts.push(promisePort)
          })
          Promise.all(promisesPorts)
          .then((res) => {
            network?.map((item:any) => {
              let promiseNet = networkService.deleteNetwork(item?.network?.id)
              promisesNetworks.push(promiseNet)
            })
            Promise.all(promisesNetworks)
            .then(async(res) => {
              if(routers?.length > 0) {
                let promisesRt:any[] = []
                await routers.map(async(item:any) => {
                  let promise = await routerService.deleteRouter(item.id)
                  await promisesRt.push(promise)
                })
                Promise.all(promisesRt)
                .then((res) => {
                  setLoadingComplete(true)
                  setTimeout(() => {
                    setLoadingComplete(false)
                    setLoading(false)
                    closeModal()
                  }, 1500);
                })
                .catch((err) => {
                  closeModalCancel()
                  setLoading(false)
                  viewAlert({
                    severity: 'error',
                    message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                  })
                })
              } else {
                setLoadingComplete(true)
                setTimeout(() => {
                  setLoadingComplete(false)
                  setLoading(false)
                  closeModal()
                }, 1500);
              }
            })
            .catch((err) => {
              getNetworkInfo()
              setLoading(false)
              viewAlert({
                severity: 'error',
                message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
              })
            })
          })
          .catch((err) => {
            getNetworkInfo()
            setLoading(false)
            viewAlert({
              severity: 'error',
              message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
            })
          })
        } else {
          network?.map((item:any) => {
            let promiseNet = networkService.deleteNetwork(item?.network?.id)
            promisesNetworks.push(promiseNet)
          })
          Promise.all(promisesNetworks)
          .then(async(res) => {
            // getNetworkInfo()
            if(routers?.length > 0) {
              let promisesRt:any[] = []
              await routers.map(async(item:any) => {
                let promise = await routerService.deleteRouter(item.id)
                await promisesRt.push(promise)
              })
              Promise.all(promisesRt)
              .then((res) => {
                setLoadingComplete(true)
                setTimeout(() => {
                  setLoadingComplete(false)
                  setLoading(false)
                  closeModal()
                }, 1500);
              })
              .catch((err) => {
                closeModalCancel()
                setLoading(false)
                viewAlert({
                  severity: 'error',
                  message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                })
              })
            } else {
              setLoadingComplete(true)
              setTimeout(() => {
                setLoadingComplete(false)
                setLoading(false)
                closeModal()
              }, 1500);
            }
          })
          .catch((err) => {
            getNetworkInfo()
            setLoading(false)
            viewAlert({
              severity: 'error',
              message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
            })
          })
        }
      }
    } catch(err:any) {
      console.log('err', err);
      getNetworkInfo()
      setLoading(false)
      viewAlert({
        severity: 'error',
        message: `${t("TRY_AGAIN")}`
      })
    }
  }

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      <HeadWrapper>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <IconBackground>
          <img style={{width:'50px', height:'50px'}} src={DeleteIcon}/>
        </IconBackground>
        <HeadLine3 style={{textAlign:'center'}}>
          {t("DELETE_NETWORKS")}
        </HeadLine3>
        <GreyText>
          {t("ARE_YOU_SURE_WANT_TO_DELETE_THIS")}&nbsp;{alertTitle}?&nbsp;{t("THIS_ACTION_CANNOT_BE_UNDONE")}
        </GreyText>
        <GreyText style={{marginTop:'10px'}}>
          {/* {t("WITH_NETWORK")}&nbsp;{alertTitle}?&nbsp;{t("WAS_DELETE")} */}
        </GreyText>
        <NetworksWrapper>
          {network?.map((net:any) => (
            <div style={{display:'flex', flexDirection:'column', gap:'0px', width:'100%'}}>
              <BodyMedium style={{marginBottom:'10px', width:'290px', wordBreak:'break-all'}}>
                {net?.network?.name || net?.network?.id}
              </BodyMedium>
              {net?.network?.subnets?.length > 0 &&
                <InfoBlock
                  dropped
                  noTitles
                  //@ts-ignore
                  title={<BodyMedium>{`${net?.network?.subnets?.length} ${t("SUBNETS")}`}</BodyMedium>}
                  data={[]}
                  customStyles={{padding:'10px', justifyContent:'flex-start'}}
                  customDropped={
                    <>
                      {net?.network?.subnets?.map((subj:any) => (
                        <InstanceWrapper style={{alignItems:'flex-start'}}>
                          <Title >
                            {subj?.name || subj?.id || subj?.ip_address}
                          </Title>
                        </InstanceWrapper>
                      ))}
                    </>
                  }
                />
              }
              {net?.ports?.length > 0 &&
                <InfoBlock
                  dropped
                  noTitles
                  //@ts-ignore
                  title={<BodyMedium>{`${net?.ports?.length} ${t("PORTS")}`}</BodyMedium>}
                  data={[]}
                  customStyles={{padding:'10px', justifyContent:'flex-start'}}
                  customDropped={
                    <>
                      {net?.ports?.map((subj:any) => (
                        <InstanceWrapper style={{alignItems:'flex-start'}}>
                          <Title >
                            {subj?.name || subj?.id || subj?.ip_address}
                          </Title>
                        </InstanceWrapper>
                      ))}
                    </>
                  }
                />
              }
            </div>
          ))}
        </NetworksWrapper>

        {routers?.length > 0 &&
          <>
            <GreyText style={{width:'100%', textAlign:'left', margin:"0px 0px 10px"}}>
              {t("DELETED_ROUTERS")}
            </GreyText>
            <InfoBlock
              dropped
              noTitles
              //@ts-ignore
              title={<BodyMedium>{`${routers?.length} ${t("ROUTERS")}`}</BodyMedium>}
              data={[]}
              customStyles={{padding:'10px', justifyContent:'flex-start'}}
              customDropped={
                <>
                  {routers?.map((subj:any) => (
                    <InstanceWrapper style={{alignItems:'flex-start'}}>
                      <Title style={{ width:'290px', wordBreak:'break-all', whiteSpace:'normal'}}>
                        {subj?.name || subj?.id || subj?.ip_address}
                      </Title>
                    </InstanceWrapper>
                  ))}
                </>
              }
            />
          </>
        }
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '157px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            isDelete
            customStyles={{ width: '157px', justifyContent:'center' }}
            onClick={deleteNetworkFunc}
            loading={loading}
            loadingComplete={loadingComplete}
            title={t("DELETE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default DeleteNetwork