import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { instanceService } from "../../../api";
import TopProgress from "../../TopBarProgress";
import { useUi } from "../../../context/ui.context";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  instanceId?:string;
  alertTitle?:string;
  loading?:boolean;
  viewModal?:boolean

}  


const AddPortToInstance:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instanceId,
    alertTitle,
    loading,
    viewModal
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    name:'',
  })
  const {
    viewAlert
  } = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [networks, setNetworks] = useState<any[]>([])
  const [subnets, setSubnets] = useState<any[]>([])
  const [projectId, setProjectId] = useState('')
  const _getOptions = () => {
    if(instanceId && viewModal){
      instanceService.addPortCreateOptions(instanceId)
      .then((res:any) => {
        setNetworks(res.data.networks.map((network:any) => {
          return {
            ...network,
            value:network.id,
            label:network.name
          }
        }))
        setProjectId(res.data.project_id)
        handleChange('network')(res.data.networks?.[0]?.id)
      })
      .catch((e:any) => {
        if(e?.response?.data?.detail) {
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
    if(!viewModal){
      setPageLoading(false)
    }
  }
  useEffect(() => {
    _getOptions()
  },[])
  
  const onSubmit = (values:any) => {
    const data = {
      attach_to:'instance',
      device_id:instanceId,
      device_owner: "compute:nova",
      fixed_ips:networks.find((net:any) => net.id === values.network).subnets.map((sub:any) => {
        return {
          subnet_id:sub.id
        }
      }),
      network:values.network,
      project:projectId,
      region:networks?.[0]?.region,
      subnet:values.subnet
    }
    closeModal(data)
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      network: yup.string().required(`${t("REQUIRED_FIELD")}`),
      subnet: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  useEffect(() => {
    setSubnets(networks.find((net:any) => net.id === values['network'])?.subnets?.map((sub:any) => {
      return {
        value:sub.id,
        label:`${sub.name} (${sub.cidr})`
      }
    }))
  },[values['network']])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
      <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {`${t("ADD_PORT_FOR")} «${alertTitle}»`}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>

        <Select
          required
          toolTip={true}
          data={networks}
          selectedValue={values['network']}
          onChange={(e) => handleChange('network')(e)}
          title={t("NETWORK")}
          customStyles={{width:'360px'}}
        />
        <Divider/>
        <Select
          required
          toolTip={true}
          data={subnets}
          selectedValue={values['subnet']}
          onChange={(e) => handleChange('subnet')(e)}
          title={t("SUBNET")}
          customStyles={{marginBottom:'20px', width:'360px'}}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            loading={loading}
            title={t("ADD_PORT")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default AddPortToInstance