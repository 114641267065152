import React, {FC, useRef, useState, useEffect, memo} from "react";
import {
  BodyTitle,
  Row,
  BodyName,
  PropsContainer,
  ListWrapper,
  StyledIconMore,
  BodyNameLink,
} from "./styles";
import CheckBox from "../checkbox";
import { icons } from "../../icons";
import StyledIcon from "../styled-icon";
import DropOptions  from '../drop-options';
// import DropOptions from "../header/components/drop-options";
import { useNavigate } from "react-router-dom";
import NewNotification from './new.svg'
import Button from "../button";
import { ChevronIc } from "../header/styles";


export type RowItem = {
  item: any,
  darkBack:boolean,
  first:boolean,
  more:boolean,
  setHover:any,
  index:any,
  hover:boolean,
  hideCheckbox?:boolean,
  navigateTo?:string,
  withBack?:boolean,
  eye?:boolean,
  isNotifications?:boolean,
  generated?:boolean,
  isRead?:boolean,
  settings?:any,
  itemToEdit?:any,
  limitedItems?:any,
  allChecked?:any,
  checked?:any,
  setChecked?:any,
  key?:any,
  hasDroppedRow?:boolean,
  options?:any,
  drop?:any,
  setDrop?:any,
  setOptions?:any,
  dropNodes?:any,
  setDropNodes?:any,
  testId?:string,
}

const TableRowItem:FC<RowItem> = (props) => {
  // const [options, setOptions] = useState<boolean>(false)
  // const [drop, setDrop] = useState<boolean>(false)

  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  const {
    item,
    darkBack,
    first,
    more,
    setHover,
    index,
    hover,
    hideCheckbox,
    navigateTo,
    withBack,
    eye,
    isNotifications,
    generated,
    isRead,
    settings,
    itemToEdit,
    limitedItems,
    allChecked,
    checked,
    setChecked,
    key,
    hasDroppedRow,
    options,
    drop,
    setDrop,
    setOptions,
    dropNodes,
    setDropNodes,
    testId,
  } = props 
  const navigate = useNavigate()
  useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
      document.removeEventListener('click', handleClickOutside, true);
		};
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef?.current?.contains(event.target) && !dropsRef?.current?.contains(event.target)){
      setOptions(false)
    }
  }
  const handleChangeDropped = () => {
    setDrop(!drop)
  }
  const selectRef:any = useRef()
  const checkSelectContainerPosition = () => {
    const windowHeight = window.innerHeight;
    const selectContainer = dropsRef.current;
    const containerPosition = selectContainer?.getBoundingClientRect();
    if(containerPosition === undefined){
      return 
    }
    if (containerPosition?.bottom && (optionsRef.current?.getBoundingClientRect()?.height + containerPosition.bottom) > windowHeight) {
      optionsRef.current?.style.setProperty('bottom', `${selectRef.current?.getBoundingClientRect()?.height - 15}px`);
    } else {
      optionsRef.current?.style.setProperty('bottom', 'auto');
    }
  };
  useEffect(() => {
    checkSelectContainerPosition();
    const handleScroll = () => {
      checkSelectContainerPosition();
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window.innerHeight, options]);
  return (
    <Row
      data-test={testId}
      hover={hover}
      ref={selectRef}
      hasDisplayTask={itemToEdit?.display_task !== null && itemToEdit?.display_task !== undefined}
      onMouseEnter={() => {
        setHover(index)
      }}
      onMouseLeave={() => {
        setHover(null)
      }}
      //@ts-ignore
      onClick={hasDroppedRow && itemToEdit?.hasHistory ? handleChangeDropped : null}
      isProps={more || eye}
      back={darkBack}
      key={key}
    >
      <div style={
        more || eye ? 
          {display:'flex', alignItems:'flex-start', width:'100%', height:'100%', justifyContent:'flex-end'}
        :
          {display:'flex', alignItems:'center', height:'100%', width:'100%', justifyContent:'flex-start'}
        }
      >
        {hasDroppedRow && first && itemToEdit.hasHistory && 
          <Button
            onClick={handleChangeDropped}
            variant='icon'
            size='small'
            // disabled
            active={drop}
            customStyles={
              { width: '22px', height: "22px", margin: '3px 10px 0px 0px' }
            }
          >
            <ChevronIc active={drop} src={icons.Chevron} />
          </Button>
        }
        {first && !hideCheckbox && !itemToEdit?.hideCheckbox ? 
          <CheckBox
            customStyles={{marginTop:'3px'}}
            value={checked && checked.find((check:any) => check?.id === itemToEdit.id)}
            title=''
            onChange={() => setChecked(itemToEdit)}
          />
        : null}
        {first && navigateTo ? 
          <BodyNameLink
            isRead={isRead}
            isNotifications={isNotifications && !isRead}
            //@ts-ignore
            to={navigateTo}
            // href={navigateTo}
            onClick={() => {
              if(itemToEdit?.onClick){
                itemToEdit.onClick()
              }
            }}
          >
            {isNotifications ? !isRead ? <img src={NewNotification}/> : null : null}
            {Array.isArray(item) ?
              <ListWrapper>{item.map((text:string) => text)}</ListWrapper>
            : 
              item
            }
          </BodyNameLink>
        :
          <>
            {(more || eye) ? item ? item :
              itemToEdit?.hideProps ? null :
              <div style={{display:'flex'}} ref={dropsRef}>
                {eye ?
                  <div onClick={itemToEdit?.onPressEye}>
                    <StyledIcon
                      src={isRead ? icons.ShowPassword : icons.HidePassword}
                    />
                  </div> 
                :
                  <Button
                    onClick={() => setOptions((options:boolean) => !options)}
                    variant='icon'
                    active={options}
                    isProps={true}
                    size='small'
                    // disabled
                    customStyles={{width:'26px', height:'20px'}}
                  >
                    <StyledIconMore src={icons.More}/>
                  </Button>
                  // <StyledIcon
                  // onClick={() => setOptions((options:boolean) => !options)} src={icons.More}/>
                }
              </div>
            : 
              Array.isArray(item) ? 
                <ListWrapper withBack={withBack}>
                  {item.map((text:string) => (
                    <BodyTitle withBack={withBack}>{text}</BodyTitle>
                  ))}
                </ListWrapper> 
              :
                <BodyTitle
                  style={first && itemToEdit?.customBodyRow ? {paddingRight:'10px'} : {}}
                  isRead={isRead} 
                  isNotifications={isNotifications && !generated}
                >
                  {item}
                </BodyTitle>
            }
          </>
        }
        {first && itemToEdit.customBodyRow && 
          <Button
            onClick={itemToEdit?.id === dropNodes ? () => {
              setDropNodes(false)
            } : () => {
              setDropNodes(itemToEdit.id)
            }}
            variant='icon'
            size='small'
            // disabled
            active={itemToEdit?.id === dropNodes}
            customStyles={{ width: '22px', height: "22px", margin: '3px 10px 0px 0px' }}
          >
            <ChevronIc active={itemToEdit?.id === dropNodes} src={icons.Chevron} />
          </Button>
        }
      </div>
      {drop && first && itemToEdit.hasHistory ? 
        <div style={{display:'flex', maxWidth:'250px'}}>
          {itemToEdit.customBody}
        </div>
      : null}
      <PropsContainer ref={optionsRef} active={options}>
        <DropOptions
          options={itemToEdit?.dropOptions?.length > 0 ? itemToEdit?.dropOptions : settings || []}
          itemToEdit={itemToEdit}
          limitedItems={limitedItems}
          handleClose={() => setOptions(false)}
          // itemForCheck={item}
        />
      </PropsContainer>
    </Row>
  )
}

export default TableRowItem