import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import CheckBox from "../../checkbox";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
  viewModal?:boolean;
}  

const ChangeBootableStatus:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    loading,
    viewModal
  } = props
  const [t] = useTranslation()
  const [status, setStatus] = useState<boolean>(instance.bootable)

  useEffect(() => {
    if(!viewModal){
      setStatus(instance.bootable)
    }
  },[instance])
  const onSubmit = () => {
    closeModal({new_status:status})
  }

  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {alertTitle}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <CheckBox
          // customStyles={{marginBottom:"20px"}}
          title={t("BOOTABLE_STATUS")}
          value={status}
          onChange={() => setStatus(!status)}
          test_id="bootable-status"
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={onSubmit}
            title={t("CHANGE_STATUS")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default ChangeBootableStatus