import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, InfoBlock, ModalVariables, Tabs, TopProgress } from "../../../../components";
import DropOptions from "../../../../components/header/components/drop-options";
import { PropsContainer } from "../../../../components/table/styles";
import { icons } from "../../../../icons";
import {
  ContainerGlobal,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  InstanceHeadInfo,
} from './styles'
import { loadBalancerService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { handleStatusName } from "../../../../utils/handle-status-name";
import { GreenDot, Greentext, StateWrapper } from "../../../Instances/styles";
// import CreateLoadBalancer from "../create-page";
import {
  TopInfoWrapper,
  Container
} from '../members/styles'
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";


const MemberPage:FC = () => {
  const navigate = useNavigate()
  const {poolId, memberId, lbId} = useParams()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const [member, setMember] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)

  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [modalType, setModalType] = useState<any>('')
  const {viewAlert} = useUi()

  const updateMember = () => {
    // setPageLoading(true)
    if(memberId && poolId){
      loadBalancerService.getMemberById(memberId, poolId)
      .then((res) => {
        setMember(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
        // navigate('/not-found')
      })
    }
  }
  useEffect(() => {
    updateMember()
    const interval = setInterval(() => {
      updateMember()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])

  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(!poolId) return;
    if(modalType === 'delete'){
      setLoading(true);
      loadBalancerService.deleteMember(poolId, member.id)
      .then((res) => {
        updateMember()
        setViewModal(false)
        setActiveAnimation(false)
        navigate(`/load-balancer-pool/${lbId}/${poolId}/members`)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }

  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      // onClick: () => navigate(`/load-balancers/edit/${lbPool.id}`),
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        setAlertTitle(t("MEMBER_DELETE"))
        openModal('delete')
        setAlertSubTitle([member])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <ContainerGlobal>
      <BackArrow onClick={() => navigate(`/load-balancer-pool/${lbId}/${poolId}/members`)} title={t("GO_BACK")}/>
      <HeadWrapper>
        <InstanceName>
          {member?.name || member.id} 
        </InstanceName>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              active={options}
              title={`${t("MENU")}`}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <InstanceHeadInfo>
        <StateWrapper>
        <GreenDot active={member.operating_status}/>
          <Greentext active={member.operating_status}>
          {handleStatusName(member.operating_status, t)}
          </Greentext>
        </StateWrapper>
      </InstanceHeadInfo>
      <MemberInfo member={member} />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={instance}
        instanceId={member.id}
        // instance={instance}
        loading={loading}
      />
    </ContainerGlobal>
  )
}

export default MemberPage



const MemberInfo:FC<any> = ({member}) => {
  const [t] = useTranslation()

  const monitorInfo = [
    {
      title:t("ID"),
      id:'1',
      value:member?.id || '-',
      copy:true,
    },
    {
      title:t("NAME"),
      id:'2',
      value:member?.name || 'n/a'
    },
    {
      title:t("OPERATING_STATUS"),
      id:'3',
      value: (
      <StateWrapper>
        <GreenDot active={member.operating_status}/>
        <Greentext active={member.operating_status}>
          {handleStatusName(member.operating_status, t)}
        </Greentext>
      </StateWrapper>) 
    },
    {
      title:t("PROVISIONING_STATUS"),
      id:'4',
      value: (
      <StateWrapper>
        <GreenDot active={member.provisioning_status}/>
        <Greentext active={member.provisioning_status}>
          {handleStatusName(member.provisioning_status, t)}
        </Greentext>
      </StateWrapper>)
    },
    {
      title:t("ADMIN_STATE"),
      id:'14',
      value:member?.admin_state_up ? 'Yes' : 'No'
    },
    {
      title:t("CREATED_AT"),
      id:'6',
      value: timeConverterMMDDYYHHMM(member?.created_at)
    },
    {
      title:t("UPDATED_AT"),
      id:'7',
      value:timeConverterMMDDYYHHMM(member?.updated_at)
    },

    //------------------------------------------------------------------
    {
      title:t("BACKUP"),
      id:'8',
      value:member?.backup ? 'Yes' : 'No',
    },
    {
      title:t("PORT"),
      id:'9',
      value:member?.protocol_port || '-',
    },
    {
      title:t("IP_ADDRESS"),
      id:'11',
      value:member?.address || '-',
    },
    {
      title:t("WEIGHT"),
      id:'16',
      value:member?.weight || '-',
    },
    {
      title:t("MONITOR_ADDRESS"),
      id:'10',
      value:member?.monitor_address || '-',
    },
    {
      title:t("MONITOR_PORT"),
      id:'12',
      value:member?.monitor_port || '-',
    },
  ]
  return (
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={monitorInfo}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1'}}
        />
      </TopInfoWrapper>
    </Container>
  )
}