import React, { FC, useState } from "react";
import {Container, TableTitle} from './styles'
import { Button, InfoBlock, Table, RevealButton } from "../../../../components";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, useParams } from "react-router-dom";
import { useQueryParam, StringParam } from 'use-query-params';
import { useTranslation } from "react-i18next";
import { InfoContainer, InfoTitle } from "../../../../components/info-block/styles";
import { useBalancer } from "../../../../context/balancerContext";
import { loadBalancerService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

const Finally:FC<any> = ({isCreateListener, isCreatePool}) => {
  const navigate = useNavigate()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [listenerId, setListenerId] = useQueryParam('listenerId', StringParam);
  const [loading, setLoading] = useState<boolean>(false)
  const {removeItemFromLs} = useLocalStorage()
  const {
    poolRemembers,
    monitorDetails,
    loadDetails,
    listenerDetails,
    poolDetails,
  } = useBalancer()
  const {lbId} = useParams()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const balancerDetails = [
    {title:t("NAME_LOAD_BALANCER"), value:loadDetails?.name||'-'},
    {title:t("IP_ADDRESS"), value:loadDetails?.vip_address||'-'},
    {title:t("DESCRIPTION"), value:loadDetails?.description||'-',isLong:true},
    // {title:t("AVAILABILITY_ZONE"), value:'Yes'},
    {title:t("FLAVORS"), value:loadDetails?.flavor||'-'},
    {title:t("SUBNET"), value:loadDetails?.vip_subnet_id||'-'},
    {title:t("ADMIN_STATE_UP"), value: loadDetails?.admin_state_up ? t("YES") : t("NO")},
  ]
  const listenerDetailsInfo = [
    {title:t("NAME"), value:listenerDetails?.name || '-'},
    {title:t("DESCRIPTION"), value:listenerDetails?.description || '-',isLong:true},
    {title:t("PROTOCOL"), value:listenerDetails?.protocol || '-'},
    {title:t("PORT"), value:listenerDetails?.protocol_port || '-'},
    {type:'divider'},
    {type:'subtitle', title:t("ADDITIONAL_SETTINGS")},
    {title:t("CLIENT_DATA_TIMEOUT"), value:listenerDetails?.timeout_client_data || '-'},
    {title:t("TCP_INSPECT_TIMEOUT"), value:listenerDetails?.timeout_tcp_inspect || '-'},
    {title:t("MEMBER_CONNECT_TIMEOUT"), value:listenerDetails?.timeout_member_connect || '-'},
    {title:t("MEMBER_DATA_TIMEOUT"), value:listenerDetails?.timeout_member_data || '-'},
    {title:t("CONNECTION_LIMIT"), value:listenerDetails?.connection_limit || '-'},

  ]
  const monitorDetailsInfo = [
    {title:t("NAME"), value:monitorDetails?.name || '-'},
    {title:t("TYPE"), value:monitorDetails?.type || '-'},
    {title:t("MAX_RETRIES_DOWN"), value:monitorDetails?.max_retries_down || '-'},
    {title:t("MAX_RETRIES"), value:monitorDetails?.max_retries || '-'},
    {title:t("DELAY_SEC"), value:monitorDetails?.delay || '-'},
    {title:t("TIMEOUT_SEC"), value:monitorDetails?.timeout || '-'},
    {title:t("ADMIN_STATE_UP"), value:monitorDetails?.admin_state_up ? 'Yes' : 'No'},
  ]
  const poolDetailsInfo = [
    {title:t("NAME"), value:poolDetails?.name || '-'},
    {title:t("DESCRIPTION"), value:poolDetails?.description || '-',isLong:true},
    {title:t("ALGORITHM"), value:poolDetails?.lb_algorithm || '-'},
    {title:t("SESSION_PERCENTILE"), value:poolDetails?.persistance || '-'},
  ]
  const columns1 = [
    {
      title:t("IP_ADDRESS"),
      key:'address'
    },
    {
      title:t("SUBNET"),
      key:'subnet_id'
    },
    {
      title:t("PORT"),
      key:'protocol_port'
    },
    {
      title:t("WEIGHT"),
      key:'weight'
    }
  ]

  console.log('poolRemembers', poolRemembers);
  const create = () => {
    if(isCreateListener){
      setLoading(true)
      const data = {
        listener_details:listenerDetails,
        loadbalancer_id:lbId,
        monitor_details:monitorDetails,
        pool_details:poolDetails,
        pool_members:poolRemembers
      }
      loadBalancerService.createListener(data)
      .then(res => {
        navigate(`/load-balancers/${lbId}/listeners`)
        removeItemFromLs('balancer.balancer_details')
        removeItemFromLs('balancer.listener_details')
        removeItemFromLs('balancer.pool_details')
        removeItemFromLs('balancer.pool_remembers')
        removeItemFromLs('balancer.monitor_details')
      })
      .catch((err) => {
        viewAlert({
          severity:'error',
          message:err?.response?.data?.details || t("ERROR_OCCURRED")
        })
      })
      .finally(() => {
        setLoading(false)
      })
    } else if (isCreatePool) {
      setLoading(true)
      const data:any = {
        loadbalancer_id:lbId,
        monitor_details:monitorDetails,
        pool_details:poolDetails,
        pool_members:poolRemembers,
      }
      if(listenerId){
        data.listener_id=listenerId
      }
      console.log('data', data);
      loadBalancerService.createPool(data)
      .then(res => {
        console.log('res', res);
        navigate(`/load-balancers/${lbId}/pools`)
        removeItemFromLs('balancer.balancer_details')
        removeItemFromLs('balancer.listener_details')
        removeItemFromLs('balancer.pool_details')
        removeItemFromLs('balancer.pool_remembers')
        removeItemFromLs('balancer.monitor_details')
      })
      .catch((err) => {
        viewAlert({
          severity:'error',
          message:err?.response?.data?.details || t("ERROR_OCCURRED")
        })
      })
      .finally(() => {
        setLoading(false)
      })
    } else {
      setLoading(true)
      const data = {
        listener_details:listenerDetails,
        load_balancer_details:loadDetails,
        monitor_details:monitorDetails,
        pool_details:poolDetails,
        pool_members:poolRemembers
      }
      loadBalancerService.create(data)
      .then(res => {
        console.log('res', res);
        navigate('/load-balancers')
        removeItemFromLs('balancer.balancer_details')
        removeItemFromLs('balancer.listener_details')
        removeItemFromLs('balancer.pool_details')
        removeItemFromLs('balancer.pool_remembers')
        removeItemFromLs('balancer.monitor_details')
      })
      .catch((err) => {
        viewAlert({
          severity:'error',
          message:err?.response?.data?.details || t("ERROR_OCCURRED")
        })
      })
      .finally(() => {
        setLoading(false)
      })
    }
    
    // navigate('/load-balancers')
  }
  return(
    <Container>
      {!isCreateListener && !isCreatePool ?
      <InfoBlock  title={t("LOAD_BALANCER_DETAILS")} data={balancerDetails}/> : null}
      {!isCreatePool &&
      <InfoBlock  title={t("LISTENER_DETAILS")} data={listenerDetailsInfo}/>}
      <InfoBlock  title={t("POOL_DETAILS")} data={poolDetailsInfo}/>
      <InfoContainer>
        <InfoTitle style={{marginBottom:'10px'}}>
          {t("POOL_REMEMBERS")}
        </InfoTitle>
        <TableTitle>
          {t("ALLOCATED_MEMBERS")}
        </TableTitle>
        <Table
          columns={columns1}
          rows={poolRemembers || []}
          hideOther
          hideCheckbox
        />
        {/* <TableTitle style={{marginTop:'14px', width:'148px'}}>
          {t("EXISTING_INSTANCES")}
        </TableTitle>
        <Table
          columns={columns2}
          rows={rows2}
          hideOther
        /> */}
      </InfoContainer>
      <InfoBlock  title={t("MONITOR_DETAILS")} data={monitorDetailsInfo}/>
      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={create}
          loading={loading}
          title={t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep('monitor-details')}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default Finally


