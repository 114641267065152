import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ModalVariables, Table, TopProgress } from "../../components";
import { icons } from "../../icons";
import { InstanceName } from "../Account-settings/styles";
import {
  Container,
  Eye,
  StatusText,
} from './styles'
import StyledIcon from '../../components/styled-icon'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { notificationService } from "../../api";
import { timeConverterMMDDYYHHMM } from "../../utils/dates";
import { useAuth } from "../../context/user.context";
import { useUi } from "../../context/ui.context";
import { handleStatusName } from "../../utils/handle-status-name";

const Notifications:FC = () => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<any[]>([])
  const [paginationData, setPaginationData] = useState<any>()
  const [t] = useTranslation()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const {updateData} = useAuth()
  const {viewAlert} = useUi()
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const markAsSeen = (id:string|number) => {
    setLoading(true)
    notificationService.viewNotification(id)
    .then((res) => {
      getNotifications()
    })
    .catch((err) => {
      setLoading(false)
      console.log('err notification read', err);
      viewAlert({
        severity:'error',
        message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    })
  }
  const markAsUnseen = (id:string|number) => {
    setLoading(true)
    notificationService.markAsUnread(id)
    .then((res) => {
      getNotifications()
    })
    .catch((err) => {
      setLoading(false)
      console.log('err notification read', err);
      viewAlert({
        severity:'error',
        message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    })
  }
  const getNotifications = () => {
    notificationService.getNotifications({
      page:page||1,
      ordering,
      pageSize:pageSize||10
    })
    .then((res:any) => {
      setPaginationData(res.data)
      setNotifications(res.data.objects.map((notification:any) => {
        return{
          ...notification,
          navigate:notification.id.toString(),
          name:notification.title,
          isRead:notification.status === 'seen',
          generated:timeConverterMMDDYYHHMM(notification.generated),
          onPressEye:notification.status === 'seen' ? 
            () => {
              markAsUnseen(notification.id)
            }
          :
            () => {
              markAsSeen(notification.id)
            }
          ,
          status: 
          (<StatusText
              isSeen={notification.status === 'seen'}
            >
            {handleStatusName(notification.status, t)}
          </StatusText>) ,
        }
      }))
    })
    .catch((e) => {
      console.log('e',e);
    })
    .finally(() => {
      setPageLoading(false)
      setLoading(false)
    })
  }
  const columns = [
    {
      title:t("TITLE"),
      key:'name',
    },
    {
      title:t("GENERATED"),
      key:'generated'
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    {
      key:'eye',
      title:''
    }
  ]
  
  const markAllAsRead = () => {
    notificationService.markAllAsRead()
    .then(() => {
      getNotifications()
      setLoading(false)
    })
    .catch(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    getNotifications()
    const interval = setInterval(() => {
      getNotifications()
    },3000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  return(
    <Container>
      <TopProgress loading={loading}/>
      <InstanceName>
        {t("NOTIFICATIONS")}
      </InstanceName>
      <Button
        variant="primary"
        size="display"
        customStyles={{marginBottom:"10px", marginTop:'20px'}}
        onClick={markAllAsRead}
        title={t("MARK_AS_ALL_READ")}
      />
      <Table
        columns={columns}
        rows={notifications}
        paginationData={paginationData}
        hideCheckbox
        isNotifications
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        loading={loading}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={editedSubnet}
      />
    </Container>
  )
}

export default Notifications



