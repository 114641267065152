import styled from "styled-components";
import { BodyDefault } from "../../../../components/typography/styled";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px 50px 20px;
  gap:20px;
  position: relative;
`
export const Body = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px;
  width: 100%;
 @media screen and (max-width:1000px) {
    flex-wrap: wrap;
 }
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
  width: 100%;
  box-sizing: border-box;
  flex:none;
  flex-grow: 0;
  flex-shrink: 1;
`

export const Item = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  gap: 8px;
  padding: 14px 20px;
  background-color: ${({theme}) => theme.grey[100]};
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  
`

export const ItemTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap:8px;
  align-items: flex-start;
`

export const ImgBig = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;

`
export const BigInfo = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 440px;
  min-width: 540px !important;
  flex:none;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  gap: 24px;
  padding: 20px 20px 0px 20px;
  background-color: ${({theme}) => theme.grey[100]};
  overflow: hidden;
  border-radius: 8px;
  @media screen and (max-width:1000px) {
    width:100%;
 }
`
export const RuleItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const FoundedBlock = styled(BodyDefault)`
  color: ${({theme}) => theme.primary[550]};
  /* background-color: ${({theme}) => theme.primary[500]}; */
  background-color:#E7EBFC;
  border-radius: 4px;
  padding: 6px 10px;
`

export const YoursBlock = styled(BodyDefault)`
  color:#229B61;
  background-color:#E0F3E8;
  border-radius: 4px;
  padding: 6px 10px;
`
export const LinkContainer = styled(BodyDefault)`
  padding:4px 6px;
  color: ${({theme}) => theme.grey[850]};
  opacity: 0.6;
  border-radius: 4px;
  background-color: ${({theme}) => theme.grey[300]};
`