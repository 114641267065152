import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, Tabs, TopProgress } from "../../../components";
import MenuButton from "../../../components/button/menu-button";
import DropOptions from "../../../components/header/components/drop-options";
import { PropsContainer } from "../../../components/table/styles";
import { icons } from "../../../icons";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  InstanceHeadInfo,
  InstanceDot,
  InstanceStatus,
  InstanceType,
} from './styles'
import {
  Subnet,
  Ports,
  Extra,
} from './components'
import { BodyDefault } from "../../../components/typography/styled";
import { networkService } from "../../../api";
import { useUi } from "../../../context/ui.context";

const NetworkPage:FC = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const [network, setNetwork] = useState<any>()
  const [editedSubnet, setEditedSubnet] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [subnets, setSubnets] = useState<any>([])
  const {viewAlert} = useUi()

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const updateNetwork = () => {
    // setPageLoading(true)
    if(id){
      networkService.getNetwork(id)
      .then((res) => {
        setNetwork(res.data)
        setPageLoading(false)
        setSubnets(res.data.subnets.map((sub:any) => {
          return {
            ...sub,
            name:sub.name,
            cidr:sub.cidr,
            ip_version:sub.ip_version,
            gateway_ip:sub?.gateway_ip,
            onClick:() => {
              setEditedSubnet(sub)
              openModal('create-subnet')
            }
          }
        }))
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
      })
    }
  }
  useEffect(() => {
    updateNetwork()
    const interval = setInterval(() => {
      updateNetwork()
    },5000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("SUBNETS"),
      slug:'subnet',
      id:'1'
    },
    {
      title:t("PORTS"),
      slug:'ports',
      id:'2'
    },
    {
      title:t("EXTRA_INFO"),
      slug:'extra',
      id:'3'
    },
  ])
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow onClick={() => navigate('/networks')} title={t("BACK_TO_NETWORKS")}/>
      <HeadWrapper>
        <InstanceName>
          {network.name}
        </InstanceName>
        {/* <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              title='Menu'
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer> */}
        <div/>
      </HeadWrapper>
      <InstanceHeadInfo>
        <InstanceDot active={network.status === "ACTIVE"}/>
        <InstanceStatus active={network.status === "ACTIVE"}>
        {network.status === "ACTIVE" ?
          t('RUNNING') : t('STOPPED')
        }
        </InstanceStatus>
        <InstanceType>
          {t("TYPE")}
        </InstanceType>
        <BodyDefault style={{marginRight: '15px'}}>
          {network.router_external ? 'External' : 'Internal'} &nbsp;
        </BodyDefault>
        <InstanceType>
        {t("ID")}
        </InstanceType>
        <BodyDefault>
        {network.id}
        </BodyDefault>
      </InstanceHeadInfo>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>

      <Routes>
        <Route path="/" element={<Navigate replace to="subnet" />} /> 
        <Route
          path='/subnet'
          element={
            <Subnet
              external={network.router_external}
              subnets={subnets}
              updateFunc={updateNetwork}
              pageLoading={pageLoading}
              setPageLoading={setPageLoading}
              editedSubnet={editedSubnet}
              setEditedSubnet={setEditedSubnet}
              viewModal={viewModal}
              setViewModal={setViewModal}
              activeAnimation={activeAnimation}
              setActiveAnimation={setActiveAnimation}
              modalType={modalType}
              setModalType={setModalType}
              openModal={openModal}
            />
          }/>
        <Route path='/ports' element={<Ports/>}/>
        <Route path='/extra' element={<Extra network={network}/>}/>
      </Routes>
    </Container>
  )
}

export default NetworkPage