import { queries } from "@testing-library/react";
import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";

const floatingIpService = {
  getIps:(queries:QueriesApi) => ApiClient.get(`openstack/floatingips?page_size=${queries.pageSize}&ordering=${queries.ordering}&page=${queries.page}`),
  getCreateOptions:() => ApiClient.get('openstack/floatingips/create_options'),
  createFloatingIP:(data:any) => ApiClient.post('openstack/floatingips', data),
  deleteIp:(id:string) => ApiClient.delete(`openstack/floatingips/${id}`),
  setPtr:(data:any) => ApiClient.post('openstack/dns/set_floating_ip_ptr', data),
  getFloatingPtr:(id:any) => ApiClient.get(`openstack/dns/get_floating_ip_ptr?floating_ip_id=${id}`),
  getEstimate:(queries:Object) => ApiClient.get('openstack/floatingips/get_price', {
    params:{
      ...queries,
    }
  }),
}

export default floatingIpService;