import React, {FC, useEffect, useState} from "react";
import {
  Container,
  BodyWrapper,
} from './styles'
import { TopProgress } from "../../../../components";
import { InfoContainer } from "../../../../components/info-block/styles";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";


const OperationSkeleton:FC<any> = ({pageLoading}) => {
  const theme:any = useTheme()
  return (
    <Container>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={135} height={15}
      />
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={535} height={20}
      />
      <BodyWrapper>
        <Skeleton
          baseColor={theme.palette[300]}
          highlightColor={theme.palette[20]}
          width={12} style={{borderRadius:"100%"}} height={12}
        />
        <Skeleton
          baseColor={theme.palette[300]}
          highlightColor={theme.palette[20]}
          width={135} height={10}
        />
      </BodyWrapper>
      <BodyWrapper>
        <InfoContainer
          style={{
            display:'flex',
            flexDirection:'row',
            flex:'1 1',
            marginBottom:'0px',
            flexGrow:'1',
            flexBasis:'0',
            paddingTop:'30px',
            boxSizing:'border-box',
          }}>

            <div style={{
              display:"flex",
              flexDirection:'column',
              gap:'20px',
              flexGrow:'1',
              flexBasis:'0'
            }}>
              {['1','2','3','4'].map((_) => (
                <Skeleton
                  baseColor={theme.palette[300]}
                  highlightColor={theme.palette[20]}
                  width={155} style={{marginBottom:"5px"}} height={15}
                />
              ))}
            </div>
            <div style={{
              display:"flex",
              flexDirection:'column',
              gap:'20px',
              flexGrow:'1',
              flexBasis:'0'
            }}>
              {['1','2','3','4'].map((_) => (
                <Skeleton
                  baseColor={theme.palette[300]}
                  highlightColor={theme.palette[20]}
                  width={195} style={{marginBottom:"5px"}} height={15}
                />
              ))}
            </div>
        </InfoContainer>
        <InfoContainer
          style={{
            display:'flex',
            flexDirection:'row',
            flex:'1 1',
            marginBottom:'0px',
            flexGrow:'1',
            flexBasis:'0',
            paddingTop:'30px',
            boxSizing:'border-box',
          }}>

            <div style={{
              display:"flex",
              flexDirection:'column',
              gap:'20px',
              flexGrow:'1',
              flexBasis:'0'
            }}>
              {['1','2','3','4'].map((_) => (
                <Skeleton
                  baseColor={theme.palette[300]}
                  highlightColor={theme.palette[20]}
                  width={155} style={{marginBottom:"5px"}} height={15}
                />
              ))}
            </div>
            <div style={{
              display:"flex",
              flexDirection:'column',
              gap:'20px',
              flexGrow:'1',
              flexBasis:'0'
            }}>
              {['1','2','3','4'].map((_) => (
                <Skeleton
                  baseColor={theme.palette[300]}
                  highlightColor={theme.palette[20]}
                  width={195} style={{marginBottom:"5px"}} height={15}
                />
              ))}
            </div>
        </InfoContainer>
      </BodyWrapper>
    </Container>
  )
}

export default OperationSkeleton