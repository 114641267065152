import ApiClient from './apiClient'
import { QueriesApi } from './apiTypes'



const routerService = {
  getRouters:(queries:QueriesApi) => {
    return ApiClient.get(`openstack/routers?page_size=${queries.pageSize}&ordering=${queries.ordering}&page=${queries.page}`)
  },
  findRouter:(name:string) => ApiClient.get(`openstack/routers?search=${name}&page_size=50`),
  // (pageSize:any=10, ordering:any='-date_created') => ApiClient.get(`openstack/routers??page_size=${pageSize}&ordering=${ordering}`),
  getSingleRouter:(id:string) => ApiClient.get(`openstack/routers/${id}`),
  createOptionsRouter:() => ApiClient.get('openstack/routers/create_options'),
  createRouter:(data:any) => ApiClient.post('openstack/routers', data),
  addInterfaceToRouter:(id:string, data:any) => ApiClient.post(`openstack/routers/${id}/add_interface`, data),
  addInterfaceOptions:(id:string) => ApiClient.get(`openstack/routers/${id}/create_add_interface_options`),
  deleteRouter:(id:string) => ApiClient.delete(`openstack/routers/${id}`),
  deleteInterface:(id:string, data:any) => ApiClient.post(`openstack/routers/${id}/remove_interface`, data),
  editRouter:(id:string,data:any) => ApiClient.put(`openstack/routers/${id}`, data),
  checkRouter:(name:string) => ApiClient.get(`check_router?name=${name}`)
}

export default routerService