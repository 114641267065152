import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { instanceService } from "../../../api";
import TopProgress from "../../TopBarProgress";
import { useUi } from "../../../context/ui.context";
import Counter from "../../counter";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  instanceId?:string;
  alertTitle?:string;
  loading?:boolean;
  viewModal?:boolean

}  


const AddIpToInstanceAutomatically:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instanceId,
    alertTitle,
    loading,
    viewModal
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    port:'',
    subnet:'',
    count_ips:'1',
  })
  const {
    viewAlert
  } = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [networks, setNetworks] = useState<any[]>([])
  const [subnets, setSubnets] = useState<any[]>([])
  const [ports, setPorts] = useState<any[]>([])
  const _getOptions = () => {
    if(instanceId && viewModal){
      instanceService.addIpOptions(instanceId)
      .then((res:any) => {
        setNetworks(res.data.networks.map((sub:any) => {
          return {
            ...sub,
            value:sub.floating_ip_address,
            label:sub.floating_ip_address
          }
        }))
        setPorts(res.data.ports.map((port:any) => {
          return {
            ...port,
            value:port.fixed_ips?.[0]?.ip_address,
            label:`${port.id.slice(0, 4)}...${port.id.slice(-4)} - ${port.fixed_ips?.[0]?.ip_address}`
          }
        }))
      })
      .catch((e:any) => {
        if(e?.response?.data?.detail) {
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
          if(e?.response?.data?.detail === 'No free ports available'){
            closeModalCancel()
          }
        }
        
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
    if(!viewModal){
      setPageLoading(false)
    }
  }
  useEffect(() => {
    _getOptions()
  },[])
  const createArrForCount = (count:any, item:any) => {
    let arr = []
    for(let i = 0; i < count; i++) {
      arr.push(item)
    }
    return arr
  }
  const onSubmit = (values:any) => {
    const data = {
      port:values.port,
      region:networks?.[0]?.region,
      fixed_ips:createArrForCount(values.count_ips, {subnet_id:values.subnet})
    }
    closeModal(data)
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      port : yup.string().required(`${t("REQUIRED_FIELD")}`),
      subnet: yup.string().required(`${t("REQUIRED_FIELD")}`),
      count_ips: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  useEffect(() => {
    setSubnets(networks.find((net:any) => net.id === ports.find((port:any) => port.id === values['port']).network)?.subnets?.map((sub:any) => {
      return {
        value:sub.id,
        label:`${sub.name} (${sub.cidr})`
      }
    }))
  },[values['port']])
  
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
      <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {`${t("ADD_IP_TO_INSTANCE")} «${alertTitle}»`}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Select
          required
          toolTip={true}
          data={ports}
          selectedValue={values['port']}
          onChange={(e) => handleChange('port')(e)}
          title={t("PORT")}
          customStyles={{width:'360px'}}
        />
        <Select
          required
          toolTip={true}
          data={subnets}
          selectedValue={values['subnet']}
          onChange={(e) => handleChange('subnet')(e)}
          title={t("SUBNET")}
          customStyles={{width:'360px'}}
        />
        <Counter
          required
          title={t("IPS_TO_ALLOCATE")}
          value={values['count_ips']}
          minCount={1}
          // hideItems={'GB'}
          onChange={(v:any) => {
            handleChange('count_ips')(v.toString())}}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            loading={loading}
            title={t("ADD_IP")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default AddIpToInstanceAutomatically