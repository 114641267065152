import React, { FC, useState } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import { ErrorText } from "../../input/styles";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle:string;
  loading?:boolean;
  modalType?:string;
}  


const DetachPort:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    alertTitle,
    loading,
    modalType
  } = props
  const [t] = useTranslation()
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
        {modalType === 'detach-volume' ? 
          `${t("DETACH_VOLUME")} «${alertTitle}»`
        : 
          `${t("DETACH_PORT")} «${alertTitle}»`
        }
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Typography variant='body'>{t('ARE_YOU_SURE')}</Typography>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("NO")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={() => closeModal(alertTitle)}
            title={t("YES")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default DetachPort