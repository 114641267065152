import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, ModalVariables, Table, Tabs, TopProgress } from "../../../components";
import MenuButton from "../../../components/button/menu-button";
import DropOptions from "../../../components/drop-options";
import { PropsContainer } from "../../../components/table/styles";
import { icons } from "../../../icons";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  InstanceHeadInfo,
  StateWrapper,
  GreenDot,
  Greentext,
  InstanceType,
} from './styles'
import {
  Overview,
  Networking,
  Addons
} from './components'
import { authService, kubernetesService, volumeService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { BodyDefault } from "../../../components/typography/styled";
import { handleStatusName } from "../../../utils/handle-status-name";
import DownloadIc from './download_ic.svg'
import rootConfig from "../../../config";
import { timeConverterMMDDYYHHMM } from "../../../utils/dates";
import ChecksIc from './components/overview/checkMarks.svg'
import ViewNodeName from './viewNodeName'
import { Prop, PropContainer } from "../../Instances/styles";
import { handleOsIcons } from "../../../hooks/useOsIcons";




const ClusterPage:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const {clusterId} = useParams()
  const {viewAlert} = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [masterNodes, setMasterNodes] = useState<any[]>([])
  const [nodePools, setNodePools] = useState<any[]>([])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },
    {
      title:t("NETWORKING"),
      slug:'network',
      id:'2'
    },
    {
      title:t("ADD_ONS"),
      slug:'add-ons',
      id:'3'
    },
  ])
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>()
  const [cluster, setCluster] = useState<any>()

  const _updateVolume = () => {
    if(clusterId) {
      kubernetesService.getClusterById(clusterId)
      .then((res) => {
        if(res.data.status === 'unknown'){
          viewAlert({
            severity: 'error',
            message: res.data.status_reason || t("ERROR_OCCURRED")
          })
          navigate('/kubernetes')
        } else {
          setCluster(res.data)
          setMasterNodes(res.data.control_nodes.map((node:any) => {
            return {
              ...node,
              navigate: `/instances/${node.id}`,
              name: node.name || node.id,
              // os: (<img src={handleOsIcons(node?.image?.os_distro)} />),
              state:(
              <StateWrapper>
                <GreenDot active={node.state}/>
                <Greentext style={{textTransform:"capitalize"}} active={node.state}>
                  {handleStatusName(node.state,t)}
                </Greentext>
              </StateWrapper>),
            }
          }))
          setNodePools(res.data.node_pools.map((node:any) => {
            return {
              ...node,
              customBodyRow:true,
              nodes:node?.nodes?.map((n:any,i:any) => {
                return {
                  ...n, 
                  poolId:node?.id,
                  navigate: `/instances/${n.id}`,
                  name:<ViewNodeName i={i} n={n} node={node}/>,
                  count:(
                    <StateWrapper>
                      <GreenDot active={n.state}/>
                      <Greentext style={{textTransform:"capitalize"}} active={n.state}>
                        {handleStatusName(n.state,t)}
                      </Greentext>
                    </StateWrapper>),
                }
              }),
              
              name: (
                <div style={{display:'flex', gap:'4px'}}>
                  {node.name || node.id}
                  {node?.is_default && 
                    <PropContainer>
                      <Prop style={{fontSize:'10px', fontWeight:500}}>
                        DEFAULT
                      </Prop>
                    </PropContainer>
                  }
                </div>
              ),
              countEdit:node?.count,
              autoscaleView:node?.autoscale ? t("YES") : '-',
              version:node?.nodes?.[0]?.version || '-',
              count:(
              <StateWrapper>
                <img src={ChecksIc}/>
                {`${node?.nodes?.filter((nd:any) => nd?.ready)?.length}/${node?.count} ready`}
                {/* {`${node?.nodes?.filter((nd:any) => nd?.ready)?.length}/${node?.autoscale ? node?.max_count : node?.nodes?.length} ready`} */}
                {/* <GreenDot active={node.state}/>
                <Greentext style={{textTransform:"capitalize"}} active={node.state}>
                  {handleStatusName(node.state,t)}
                </Greentext> */}
              </StateWrapper>),
              // state:(
              // <StateWrapper>
              //   <GreenDot active={node.state}/>
              //   <Greentext style={{textTransform:"capitalize"}} active={node.state}>
              //     {handleStatusName(node.state,t)}
              //   </Greentext>
              // </StateWrapper>),
            }
          }))
          setPageLoading(false)
        }
      })
      .catch((e) => {
        setPageLoading(false)

        // if(e?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: e?.response?.data?.detail || t("ERROR_OCCURRED")
          })
          navigate('/kubernetes')
        // }
      })
    }
  }

  useEffect(() => {
    _updateVolume()
    const interval = setInterval(() => {
      _updateVolume()
    },5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const deleteFunc = async () => {
    setLoading(true);
    try {
      await kubernetesService.deleteCluster(cluster.id);
      await navigate('/kubernetes')
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setViewModal(false);
      setActiveAnimation(false);
      setLoading(false);
    }
  };
  const closeModal = async () => {
    try {
      setLoading(true);
      switch (modalType) {
        case 'delete':
          deleteFunc()
          break; 
      }
    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } 
    // setViewModal(false)
    // setActiveAnimation(false)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow onClick={() => navigate('/kubernetes')} title={t("BACK_TO_CLUSTERS")}/>
      <HeadWrapper>
        <InstanceName>
          {cluster?.cluster_name || cluster?.id || 'CLUSTER'}
        </InstanceName>

        {/* <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              isFill
              title={t("MENU")}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer> */}
      </HeadWrapper>
      <InstanceHeadInfo>
        <StateWrapper>
          <GreenDot active={cluster?.status}/>
          <Greentext active={cluster?.status}>
            {handleStatusName(cluster?.status,t)}
          </Greentext>
        </StateWrapper>
      </InstanceHeadInfo>
      <InstanceHeadInfo>
        <Button
          title={`${t("DOWNLOAD_KUBE_CONFIG")}`}
          onClick={async () => {
            const res = await authService.generateOtpToken()
            const url = `${rootConfig.apiUrl}api/cluster/${clusterId}/download_config/?fleio-token=${res.data.otp_token}`
            // window.open(url);
            window.location.href = url
          }}
          variant='primary'
          size='custom'
          icon={DownloadIc}
          customStyles={{height:'40px', marginRight:'6px'}}
        />
        <Button
          onClick={() => {
            openModal('delete')
            setAlertTitle(t("CLUSTER"))
            setAlertSubTitle([cluster])
          }}
          variant='icon'
          size='display'
          customStyles={{margin:'0px 6px 0px 0px'}}
        >
          <img src={icons.DeleteIcon}/>
        </Button>
        <div style={{display:'flex', alignItems:'center', gap:'6px'}}>
          {/* <Button
          onClick={() => {}}

            test_id='button-delete'
            variant='icon'
            size='display'
            // disabled={!allChecked && checked?.length === 0}
          >
            <img src={icons.DeleteIcon}/>
          </Button> */}
          {/* <Button
            onClick={() => {}}
            variant='icon'
            size='display'
            // disabled={!allChecked && checked?.length === 0}
          >
            <img src={icons.PauseIcon}/>
          </Button>
          <Button
            onClick={() => {}}
            variant='icon'
            size='display'
            // disabled={!allChecked && checked?.length === 0}
          >
            <img src={icons.PlayIcon}/>
          </Button> */}
        </div>
      </InstanceHeadInfo>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview masterNodes={masterNodes} nodePools={nodePools} cluster={cluster}/>}/>
        <Route path='/network' element={<Networking updateFunc={_updateVolume} cluster={cluster}/>}/>
        <Route path='/add-ons' element={<Addons />}/>
      </Routes>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={cluster}
      />
    </Container>
  )
}

export default ClusterPage