import React, {useState, useRef, FC, useEffect} from "react";
import {
  Container,
  DropDown,
  ImgArrow,
  OptionsWrapper,
  Title
} from './styles'
import Typography from '../typography';
import ArrowDown from './arrow-down.svg'

type SelectProps = {
  children:any,
  title:string,
  customStyles?:any,
}

const RevealButton:FC<SelectProps> = ({title, children, customStyles}) => {
  const selectRef:any = useRef()
  const [active, setActive] = useState<boolean>(false)

  return (
    <Container ref={selectRef}>
      <DropDown
        active={active}
        onClick={() => setActive(!active)}
        style={customStyles}
      >
        <Title active={active}>
          {title}
        </Title>
        <ImgArrow active={active} src={ArrowDown}/>
      </DropDown>
      <OptionsWrapper active={active}>
        {children}
      </OptionsWrapper>
    </Container>
  )
}

export default RevealButton