import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";



const portService = {
  createPort:(data:any) => ApiClient.post('openstack/ports', data),
  editPort:(data:any) => ApiClient.put(`openstack/ports/${data?.id}`, data),
  getPort:(id:string) => ApiClient.get(`openstack/ports/${id}`),
  getPorts:(queries:QueriesApi) => ApiClient.get('openstack/ports',{
    params:{
      ...queries
    }
  }),
  getPortsForAttach:(queries:any) => ApiClient.get('openstack/ports',{
    params:{
      page_size:'50',
      // queries
      filtering:queries.filtering
    }
  }),
  autoAddIp:(portId:string, data:any) => ApiClient.post(`openstack/ports/${portId}/automatic_add_ips`, data),
  addSingleIp:(portId:string, data:any) => ApiClient.post(`openstack/ports/${portId}/add_ip`, data),
  deletePort:(portId:string) => ApiClient.delete(`openstack/ports/${portId}`),
  getCreateOptions:() => ApiClient.get(`openstack/ports/create_options`),
}

export default portService;