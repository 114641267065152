import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TabContainer,
  // TopInfoWrapper,

} from '../../styles'
import { Button, Input, TopProgress } from '../../../../components'
import { icons } from '../../../../icons'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../../../context/user.context'
import { authService } from '../../../../api'
import { useUi } from '../../../../context/ui.context'

const MyProfile:FC = () => {
  const [t] = useTranslation()
  // const {user} = useAuth()
  const [user, setUser] = useState<any>({})
  const {viewAlert} = useUi()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [initialValues, setInitialValues ] = useState<any>({
    first_name:user.first_name,
    last_name:user.last_name,
    email:user.email,
    phone:user.mobile_phone_number,
  })
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const onSubmit = (values:any) => {
    const data = {
      first_name:values.first_name,
      last_name:values.last_name,
      email:values.email,
      mobile_phone_number:values.phone,
      language:'en',
      old_password:'',
      password:'',
      password_confirm:'',
    }
    setLoading(true)
    setTimeout(() => {
      authService.updateClient(data)
      .then((res) => {
        authService.getUserProfile()
        .then((res) => {
          setLoadingComplete(true)
          setTimeout(() => {
            setLoading(false)
            setLoadingComplete(false)
            setUser({...res.data.user, mobile_phone_number:res.data.user.mobile_phone_number || ''})
            setInitialValues({
              first_name:res.data.user.first_name,
              last_name:res.data.user.last_name,
              email:res.data.user.email,
              phone:res.data.user.mobile_phone_number || '',
            })
          },1000)
        })
        .catch((e) => {
          if(e.response.data.detail){
            viewAlert({
              severity:'error',
              message:e.response.data.detail,
            })
          }
        })
      })
      .catch((e => {
        if(e.response.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
        if(e.response.data.mobile_phone_number){
          viewAlert({
            severity:'error',
            message:e.response.data.mobile_phone_number,
          })
        }
        setErrors(e.response.data)
        setLoading(false)
      }) )
    },500)
  }

  useEffect(() => {
    authService.getUserProfile()
    .then((res) => {
      setUser({...res.data.user, mobile_phone_number:res.data.user.mobile_phone_number || ''})
      setInitialValues({
        first_name:res.data.user.first_name,
        last_name:res.data.user.last_name,
        email:res.data.user.email,
        phone:res.data.user.mobile_phone_number || '',
      })
    })
    .catch((e) => {
      if(e.response.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      } 
    })
    .finally(() => setPageLoading(false))
  },[])
  
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      first_name: yup.string().required(`${t("REQUIRED_FIELD")}`),
      last_name: yup.string().required(`${t("REQUIRED_FIELD")}`),
      email: yup.string().email(`${t("NOT_VALID_EMAIL")}`).required(`${t("REQUIRED_FIELD")}`),
      phone: yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <TabContainer>
      <div style={{width:'360px', display:'flex', gap:'20px', flexDirection:'column'}}>
        <Input
          fieldName='first_name'
          title={t("FIRST_NAME")}
          placeholder=''
          {...formikProps}
        />
        <Input
          fieldName='last_name'
          title={t("LAST_NAME")}
          placeholder=''
          {...formikProps}
        />
        <Input
          fieldName='email'
          title={t("EMAIL_ADDRESS")}
          placeholder=''
          {...formikProps}
        />
        <Input
          fieldName='phone'
          title={t("PHONE_NUMBER")}
          phoneNumber
          placeholder=''
          {...formikProps}
        />
      </div>
      <Button
        variant='primary'
        disabled={user.email === values.email && user.first_name === values.first_name
          && user.last_name === values.last_name && (user.mobile_phone_number === values.phone )}
        size='display'
        loading={loading}
        loadingComplete={loadingComplete}
        onClick={handleSubmit}
        customStyles={{marginTop:'24px'}}
        title={t("SAVE")}
        // icon={icons.Filter}
      />

    </TabContainer>
  )
}
export default MyProfile
