import styled from "styled-components";


export const Container = styled.div`
  padding-top:20px;
  padding-right:40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
  gap:10px;
`
export const AddOnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const AddonImg = styled.img`
  margin-right: 10px;
`