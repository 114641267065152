import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { BodyDefault, HeadLine3 } from "../../typography/styled";
import TopProgress from "../../TopBarProgress";
import { instanceService, portService } from "../../../api";
import { useUi } from "../../../context/ui.context";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  loading?:boolean;
  instanceId?:string;
  dataToEdit?:any;
  viewModal?:boolean;
  alertTitle:string;
}  


const AttachVolume:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    loading,
    dataToEdit,
    instanceId,
    viewModal,
    alertTitle
  } = props
  const {
    viewAlert
  } = useUi()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    volume:'',
  })
  const [modalLoading, setModalLoading] = useState<boolean>(true)
  const [volumes, setVolumes] = useState<any[]>([])

  useEffect(() => {
    if(instanceId) {
      instanceService.getAttachableVolumes(instanceId)
      .then((res) => {
        setVolumes(res.data.objects.map((vol:any) => {
          return {
            ...vol,
            value: vol.id,
            label: vol.name
          }
        }))
        setModalLoading(false)
      })
      .catch((e) => {
        setModalLoading(false)
        closeModalCancel()
      })
    }
  },[viewModal])
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  const {
    values,
    errors,
    touched,
    resetForm,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      volume: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  if(modalLoading) return <TopProgress loading={modalLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {`${t("ATTACH_VOLUME_TO_INSTANCE")} «${alertTitle}»`}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        {volumes?.length > 0 ?
          <Select
            required
            toolTip={true}
            data={volumes}
            selectedValue={values['volume']}
            onChange={(e) => handleChange('volume')(e)}
            title={t("SELECT_VOLUME")}
            customStyles={{width:'360px'}}
          />
        :
          <BodyDefault>
            {t("NO_VOLUMES_TO_ATTACH")}
          </BodyDefault>
        }
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            disabled={volumes?.length <= 0}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("ATTACH")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default AttachVolume