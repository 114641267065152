import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Button, Table, ModalVariables, TopProgress, Label } from "../../../../../components";
import {
  Container,
  ButtonsWrapper
} from './styles'
import { icons } from "../../../../../icons";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { volumeService } from "../../../../../api";
import { useParams } from "react-router-dom";
import { timeConverterMMDDYYHHMM } from "../../../../../utils/dates";
import { useUi } from "../../../../../context/ui.context";
import { compareArrays } from "../../../../../utils/findIntersection";

type OverviewProps = {
  volume:any
}

const Snapshot:FC<OverviewProps> = ({volume}) => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam);
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [snapshots, setSnapshots] = useState<any[]>([])
  const [paginationData, setPaginationData] = useState<any>()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedSnapshot, setSelectedSnapshot] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(snapshots, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const {volumeId} = useParams()
  const _updateSnapshots = () => {
    if(volumeId){
    // setLoading(true)
      volumeService.getVolumeSnapshotsList({
        page:page || 1,
        pageSize:pageSize || 10,
        ordering:ordering,
      }, volumeId)
      .then((res) => {
        setSnapshots(res.data.objects.map((snapshot:any) => {
          return {
            ...snapshot,
            uniq_id: snapshot.id,
            sizeString:`${snapshot.size} GB`,
            nameStr: snapshot.name,
            name: (
              <div style={{
                display: "flex",
                alignItems: "center",
                gap:'1px'
              }}>
                {snapshot.name || snapshot.id}
                {snapshot.description && 
                  <Label
                    title=''
                    tooltipText={snapshot.description}
                  />
                }
                {/* <img src={icons.IconInfo}/> */}
              </div>),
            created_at:timeConverterMMDDYYHHMM(snapshot.created_at),
          }
        }))
        setPaginationData(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        console.log('error', e);
      })
    }
  }
  useEffect(() => {
    _updateSnapshots()
    const interval = setInterval(() => {
      _updateSnapshots()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = async (values:any) => {
    let res:any;
    try{
      setLoading(true);
      switch(modalType){
        case 'update-volume-snapshot':
          res = await volumeService.resetStateSnapshot(selectedSnapshot.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'edit-volume-backup':
          let data = {
            ...values,
            id:selectedSnapshot.id,
            volume_id:volumeId
          }
          res = await volumeService.updateSnapshot(selectedSnapshot.id, data);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case "delete":
          try {
            if (allChecked) {
              await Promise.all(snapshots.map((item) => volumeService.deleteSnapshot(item.id)));
              _updateSnapshots();
              setAllChecked(false);
              setChecked([]);
            } else if (checked?.length > 0) {
              await Promise.all(checked.map((item) => volumeService.deleteSnapshot(item.id)));
              _updateSnapshots();
              setChecked([]);
            } else if (selectedSnapshot) {
              await volumeService.deleteSnapshot(selectedSnapshot.id);
              _updateSnapshots();
            }
          } catch (e:any) {
            if (e?.response?.data.detail) {
              viewAlert({
                severity: 'error',
                message: e.response.data.detail,
              });
            }
            setLoading(false);
          } finally {
            setViewModal(false);
            setActiveAnimation(false);
          }
          break;
        case "create-snapshot":
          res = await volumeService.createSnapshot(values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
      }
      setViewModal(false)
      setActiveAnimation(false)
    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setLoading(false);
    }
  }
  const items = [
    {
      title:`${t("EDIT")}`,
      id:'2',
      icon:icons.Edit,
      onClick: (snapshot:any) => {
        openModal('edit-volume-backup')
        setSelectedSnapshot(snapshot)
        setAlertTitle(`${t("EDIT_SNAPSHOT")} «${snapshot.nameStr || snapshot.id}»`)
        
      },
      divider:false
    },
    {
      title:`${t("RESET_STATE")}`,
      id:'6',
      icon:icons.BackArrowRevert,
      onClick: (snapshot:any) => {
        openModal('update-volume-snapshot')
        setSelectedSnapshot(snapshot)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(snapshot:any) => {
        openModal('delete')
        setAlertTitle(t("SNAPSHOT"))
        setAlertSubTitle([snapshot])
        setSelectedSnapshot(snapshot)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("ID"),
      key:'uniq_id'
    },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
    {
      title:'',
      key:'props'
    },
  ]
  
  
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      {
        snapshots.length === 0 ? 
          <>
            <Typography variant="body">{t("THERE_ARE_NO_SNAPSHOT_YET")}</Typography>
            <Button
              title={t('CREATE_SNAPSHOT')}
              onClick={() => {
                setAlertTitle(volume.name || volume.id)
                openModal('create-snapshot')
              }}
              variant='primary'
              size='custom'
              customStyles={{height:'40px', margin:'20px 0px'}}
            />
          </>
        :
          <>
            <ButtonsWrapper>
              <div>
                <Button
                  title={t('CREATE_SNAPSHOT')}
                  onClick={() => {
                    setAlertTitle(volume.name || volume.id)
                    openModal('create-snapshot')
                  }}
                  variant='primary'
                  size='custom'
                  customStyles={{height:'40px', marginRight:'6px'}}
                />
                <Button
                  onClick={() => {
                    setAlertTitle(t("SNAPSHOTS"))
                    setAlertSubTitle(allChecked ? snapshots : checked)
                    openModal('delete')
                  }}
                  variant='icon'
                  test_id='button-delete'
                  size='display'
                  disabled={!allChecked && checked?.length === 0}
                  // customStyles={{margin:'20px 6px 20px 0px'}}
                >
                  <img src={icons.DeleteIcon}/>
                </Button>
              </div>
            </ButtonsWrapper>
            <Table
              columns={Columns}
              rows={snapshots}
              paginationData={paginationData}
              settings={items}
              allChecked={allChecked}
              setAllChecked={setAllChecked}
              checked={checked}
              setChecked={editChecked}
              updateData={_updateSnapshots}
            />
          </>
      }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        instance={{name:selectedSnapshot?.nameStr, description:selectedSnapshot?.description}}
        loading={loading}
        dataToEdit={{
          id:volumeId,
          volume_size:volume.size,
          region:volume.region,
        }}
      />
    </Container>
  )
}

export default Snapshot

