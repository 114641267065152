import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,
  NetworkBlock,
  NetworkText,
  NetworkBorder,
  NetworkLine,
  InfoName,
  InfoNameGrey,
  InfoNameBold,
  NameBorder,
  NetworkContainer,
  IpGroupsWrapper,
  NetworkInfoContainer,
} from './styles'
import { InfoBlock, Table, Button, ModalVariables } from '../../../../../components'
import { InfoTitle, InfoContainer } from '../../../../../components/info-block/styles'
import { icons } from '../../../../../icons'
import MetricsChart from '../../../../../components/metrics'
import { timeConverterMMDDYYHHMM } from '../../../../../utils/dates'
import { findIntersection } from '../../../../../utils/findIntersection'
import { instanceService } from '../../../../../api'
import { useUi } from '../../../../../context/ui.context'
import { BodyDefault } from '../../../../../components/typography/styled'
import { handleOsIcons } from '../../../../../hooks/useOsIcons'
import { useNavigate } from 'react-router-dom'
import { PropContainer, Prop } from '../../../styles'
// import {
//   datesToPercentagesWithValues,
//   calculateScales,
//   createPercentageArrays
// } from '../metrics/metricFuncs'
type OverviewProps = {
  instance:any,
  volumes?:any,
}


const Overview:FC<OverviewProps> = ({instance, volumes}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [attachVolumeLoading, setAttachVolumeLoading] = useState<boolean>(false)
  const [alertTitle, setAlertTitle] = useState<any>()
  const [editedIp, setEditedIp] = useState<any>()

  // const [cpuMetrics, setCpuMetrics] = useState<any[]>([])
  // const [networkMetrics, setNetworkMetrics] = useState<any[]>([])
  // const [networkMetricsOut, setNetworkMetricsOut] = useState<any[]>([])
  // const [scales, setScales] = useState<any[]>([])



  const {viewAlert} = useUi()
  // const _updateMetrics = (hours:any=1) => {
  //   instanceService.getMetrics(instance?.id, {
  //     hours:hours,
  //     metric: 'cpu_util'
  //   })
  //   .then((res) => {
  //     setCpuMetrics(datesToPercentagesWithValues(res?.data?.measures))
  //   })
  //   .catch((err) => {
  //     console.log('err',err);
  //   })
  //   instanceService.getMetrics(instance?.id, {
  //     hours:hours,
  //     metric: 'interface_traffic'
  //   })
  //   .then((res:any) => {
  //     setScales(calculateScales(res?.data?.measures))
  //     setNetworkMetrics(createPercentageArrays(res?.data?.measures, 'bytes_in'))
  //     setNetworkMetricsOut(createPercentageArrays(res?.data?.measures, 'bytes_out'))
  //   })
  //   .catch((err) => {
  //     console.log('err',err);
  //   })
  // }

  // const updateCpu = (hours:any=1) => {
  //   instanceService.getMetrics(instance?.id, {
  //     hours:hours,
  //     metric: 'cpu_util'
  //   })
  //   .then((res) => {
  //     setCpuMetrics(datesToPercentagesWithValues(res?.data?.measures))
  //   })
  //   .catch((err) => {
  //     console.log('err',err?.response?.data);
  //   })
  // }
  // const updateNetworks = (hours:any=1, type?:any) => {
  //   instanceService.getMetrics(instance?.id, {
  //     hours:hours,
  //     metric: 'interface_traffic'
  //   })
  //   .then((res) => {
  //     // setNetworkMetrics(res?.data?.measures)
  //     setScales(calculateScales(res?.data?.measures))
  //     setNetworkMetrics(createPercentageArrays(res?.data?.measures, type === '2' ? 'bytes_in' : 'packets_in'))
  //     setNetworkMetricsOut(createPercentageArrays(res?.data?.measures, type === '2' ? 'bytes_out' : 'packets_out'))
  //   })
  //   .catch((err) => {
  //     console.log('err',err?.response?.data);
  //   })
  // }
  // useEffect(() => {
  //   _updateMetrics()
  // },[])

  // console.log('networkMetrics',networkMetrics);
  // console.log('networkMetricsOut',networkMetricsOut);

  const specifications = [
    {
      title:t("IMAGE"),
      id:'1',
      value:instance?.image?.name,
      icon: handleOsIcons(instance?.image?.os_distro)
    },
    {
      title:t("FLAVOR"),
      id:'2',
      value:instance?.flavor?.name
    },
    {
      title:t("MEMORY"),
      id:'3',
      left:true,
      options:['2'],
      custom: (
        <NameBorder style={{border:'none', padding:'0px'}}>
          <InfoNameBold>{instance?.flavor?.memory_gb}GB</InfoNameBold>&nbsp;
          <InfoNameGrey>({instance?.flavor?.memory_mb})</InfoNameGrey>
          &nbsp;
          <InfoName>RAM</InfoName>
        </NameBorder>
      ),
      // value:'2GB (2048) RAM'
    },
    {
      title:t("VIRTUAL_CPU_S"),
      id:'4',
      value:instance?.flavor?.vcpus
    },
    {
      title:t("STORAGE"),
      id:'5',
      left:true,
      custom: (
        <div
          style={{
            display: 'flex',
            flexDirection:'column',
            gap:'6px',
            alignItems:'flex-start',
          }}
        >
          {!instance.storage_details.hide_local_storage && 
            <NameBorder>
              <InfoName>Local Storage:</InfoName>&nbsp;
              <InfoNameBold>{instance?.flavor?.root_gb}GB</InfoNameBold>
              &nbsp;
            </NameBorder>
          }
          {instance.storage_details.volume_attachments.map((volume:any) => (
            <NameBorder>
              <div style={{display:'flex'}}>
                <InfoNameGrey style={{fontSize:'12px'}}>{t("VOLUME")}</InfoNameGrey>
                <InfoName style={{marginLeft:'6px'}}>{volume.volume_name}:</InfoName>&nbsp;
                <InfoNameBold>{volume.size}GB</InfoNameBold>
                &nbsp;
                {volume.is_boot ? 
                  <InfoNameGrey>(boot)</InfoNameGrey>
                : null}
              </div>
            </NameBorder>

          ))}
        </div>
      ),
    },
  ]
  const other = [
    {
      title:t("INSTANCE_UUID"),
      id:'1',
      value:instance.uuid,
      copy:true
    },
    {
      title:t("CREATED_AT"),
      id:'2',
      value: timeConverterMMDDYYHHMM(instance.created) 
    },
    // {
    //   title:t("REGION"),
    //   id:'3',
    //   value:instance.region
    // },
    {
      title:t("TOTAL_TRAFFIC_CYCLE"),
      id:'4',
      value:`${instance.current_cycle_traffic} GB`
    },
    {
      title:t("TOTAL_TRAFFIC_MONTH"),
      id:'5',
      value:`${instance.current_month_traffic} GB`
    },
    {
      title:t("TAGS"),
      id:'6',
      custom: (
        <div style={{display:'flex', gap:'6px'}}>
        {instance?.tags?.map((tag:any) => (
          <PropContainer key={tag?.id}>
            <Prop>
              {tag?.name}
            </Prop>
          </PropContainer>
        ))}
      </div>
      ),
    },
  ]
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }

  const closeModal = (values:any) => {
    let data: any;
    let serviceCall: any;
    let successMessage: any;
    let errorMessage: string = t("ERROR_OCCURRED");

    switch (modalType) {
      case 'attach-volume':
        data = { volume_id: values.volume };
        serviceCall = () => instanceService.attachVolume(instance.id, data);
        successMessage = (res: any) => res.data?.detail;
        break;

      case 'assign-ip':
        data = values;
        serviceCall = () => instanceService.addFloatingIp(instance.id, data);
        successMessage = (res: any) => res.data?.detail;
        break;

      case 'detach-volume':
        data = { volume_id: values };
        serviceCall = () => instanceService.detachVolume(instance.id, data);
        successMessage = (res: any) => res.data?.detail;
        break;

      default:
        setViewModal(false);
        setActiveAnimation(false);
        setModalType('')
        return; // если modalType не соответствует ни одному из известных значений, ничего не делаем
    }

    setAttachVolumeLoading(true);

    serviceCall()
    .then((res: any) => {
      viewAlert({
        severity: 'info',
        message: successMessage(res),
      });
      setViewModal(false);
      setActiveAnimation(false);
      setModalType('')
    })
    .catch((err: any) => {
      viewAlert({
        severity: 'error',
        message: err?.response?.data?.detail || errorMessage,
      });
    })
    .finally(() => {
      setAttachVolumeLoading(false);
    });
  }
  const items = [
    {
      title:t("DETACH"),
      id:'1',
      icon:icons.MinusDefault,
      onClick:(volume:any) => {
        openModal('detach-volume')
        setAlertTitle(volume.volume_id)
      },
      divider:false
    },
  ]
  const Columns = [
    {
      title:t('ID'),
      key:'name'
    },
    // {
    //   title:t("STATUS"),
    //   key:'status'
    // },
    {
      title:t("SIZE"),
      key:'size'
    },
    {
      title:t("HAS_SNAPSHOT"),
      key:'has_snapshots'
    },
    {
      title:'',
      key:'props'
    }
  ]
  return(
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          title={t("INSTANCE_SPECIFICATIONS")}
          customStyles={{width:"545px", flex:'auto 1', marginBottom:'0px'}}
        />
        <InfoBlock
          data={other}
          title={t("OTHER_DETAILS")}
          customStyles={{width:"545px", flex:'auto 1', marginBottom:'0px'}}
        />
      </TopInfoWrapper>
      <InfoContainer style={{ flex:'auto 1', display:'flex', width:'100%', marginBottom:'0px'}}>
        <InfoTitle style={{marginBottom:'10px'}}>
          {t("NETWORK_DETAILS")}
        </InfoTitle>
        {instance.net_details.grouped_ips.map((ip:any) => (
          <NetworkContainer style={{marginBottom:'10px'}}>
            <BodyDefault>
              {ip?.network_name}:
            </BodyDefault>
            <IpGroupsWrapper>
              {ip.ipv4s?.length > 0 ?
                <NetworkInfoContainer>
                  <NetworkText >
                    IPv4:
                  </NetworkText>
                  <IpGroupsWrapper  style={{flexWrap: 'wrap'}}>
                    {ip.ipv4s.map((address:any, index:any) => (
                      <div style={{display:'flex', alignItems:'center'}}>
                        <NetworkText>
                          {address?.ip_address}
                        </NetworkText>
                        {/* <Button
                          variant='stroke'
                          size='small'
                          customStyles={{
                            color:"#4671F6",
                            height:'20px',
                            padding: '2px 4px',
                            marginLeft:'4px',
                            fontWeight:'500',
                            fontSize:'12px',
                            marginRight:'2px'
                          }}
                          onClick={() => {}}
                          title={"rDNS"}
                        /> */}
                        {index + 1 === ip.ipv4s?.length ? null : ','}
                      </div>
                    ))}
                  </IpGroupsWrapper>
                </NetworkInfoContainer>
              : null}
              {ip.ipv6s?.length > 0 ? 
                <NetworkInfoContainer>
                  <NetworkText isBlack>
                    IPv6:
                  </NetworkText>
                  <IpGroupsWrapper  style={{flexWrap: 'wrap'}}>
                    {ip.ipv6s.map((address:any, index:any) => (
                      <div style={{display:'flex', alignItems:'center'}}>
                        <NetworkText>
                          {address?.ip_address}
                        </NetworkText>
                        {index + 1 === ip.ipv6s?.length ? null : ','}
                      </div>
                    ))}
                  </IpGroupsWrapper>
                </NetworkInfoContainer>
              : null}
              {/* Find and view floting Ip for connected network */}
              {instance.net_details.ports.map((port:any) => {
                return (
                  port.floating_ips.length > 0 && 
                  port.ipv4s.find((f:any) => f.ip_address === findIntersection(port.ipv4s, ip.ipv4s, 'ip_address')?.[0]?.ip_address) ?
                    <>
                      <NetworkInfoContainer>
                        <NetworkText>
                          {t("FLOATING_IPS")}:
                        </NetworkText>
                        <IpGroupsWrapper  style={{flexWrap: 'wrap'}}>
                          {port.floating_ips.map((ip:any, index:any) => (
                            <div style={{display:'flex', alignItems:'center'}}>
                              <NetworkText>
                                {ip?.floating_ip_address}
                              </NetworkText>
                              <Button
                                variant='stroke'
                                size='small'
                                customStyles={{
                                  color:"#4671F6",
                                  height:'20px',
                                  padding: '2px 4px',
                                  marginLeft:'4px',
                                  fontWeight:'500',
                                  fontSize:'12px'
                                }}
                                onClick={() => {
                                  openModal('set-ptr')
                                  setEditedIp(ip)
                                }}
                                title={t("RDNS")}
                              />
                              {index + 1 === port.floating_ips?.length ? null : ','}
                            </div>
                          ))}
                        </IpGroupsWrapper>
                      </NetworkInfoContainer>
                    </>
                  :
                    <NetworkInfoContainer>
                      <Button
                        variant='stroke'
                        size='display'
                        customStyles={{marginRight:'6px'}}
                        onClick={() => {
                          setAlertTitle(instance.name)
                          openModal('assign-ip')
                        }}
                        title={t("ASSIGN_FLOATING_IP")}
                        icon={icons.PlusDefault}
                      />
                    </NetworkInfoContainer>
                )
              })}
            </IpGroupsWrapper>
          </NetworkContainer>
        ))}
      </InfoContainer>
      {/* {volumes.length > 0 ?  */}
        <InfoContainer style={{display:'flex', flex:'auto 1', marginBottom:'0px'}}>
          <InfoTitle style={{marginBottom:'10px'}}>
            {t("VOLUME_DETAILS")}
          </InfoTitle>
          <div>
            <Button
              variant="stroke"
              size="display"
              icon={icons.PlusDefault}
              customStyles={{flex:'0', marginBottom:'10px'}}
              onClick={() => {
                setAlertTitle(instance.name)
                openModal('attach-volume')
              }}
              title={t("ATTACH_VOLUME")}
            />
          </div>
          {volumes?.length > 0 ? 
          <Table settings={items} hideCheckbox hideOther columns={Columns} rows={volumes}/>
          : 
            <BodyDefault>
              {t("YOU_HAVE_NO_VOLUMES_ATTACHED")}
            </BodyDefault>
          }
        </InfoContainer>
      {/* // : null} */}
      {/* <div style={{display:'flex', flexDirection:'column', width:'100%', gap: '10px' }}>
        {networkMetrics?.length > 0 && networkMetricsOut?.length > 0 &&
          <MetricsChart
            updateData={updateNetworks}
            title={t("NETWORK_METRIC")}
            scales={scales}
            data={networkMetrics}
            dataSecond={networkMetricsOut}
          />
        }
        {cpuMetrics?.length > 0 &&
          <MetricsChart
            updateData={updateCpu}
            title={t("CPU_USAGE")}
            isCpu
            data={cpuMetrics}
          />
        }
      </div> */}
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        instanceId={instance.id}
        loading={attachVolumeLoading}
        dataToEdit={modalType === 'set-ptr' ? editedIp : instance}
      />
    </Container>
  )
}

export default Overview

