import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";



const notificationService = {
  getNotifications:(queries:QueriesApi) => ApiClient.get(`notifications?page_size=${queries.pageSize}&page=${queries.page}&ordering=-generated&name=frontend`),
  markAllAsRead: () => ApiClient.post('notifications/mark_all_as_read'),
  markAsUnread: (notificationId:string|number) => ApiClient.post(`notifications/${notificationId}/unseen`),
  viewNotification:(notificationId:string|number) => ApiClient.post(`notifications/${notificationId}/view`),
  getById:(notificationId:string|number) => ApiClient.get(`notifications/${notificationId}`)
}

export default notificationService