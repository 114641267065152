import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const DateWrapper = styled.div`
  display: flex;
  position: relative;

`
export const CloseIconContainer = styled.div`
  border-left:1px solid ${({theme}) => theme.grey[350]};
  display: flex;
  position:absolute;
  right:0px;
  bottom:6px;
  width:36px;
  height:40px;
  justify-content: center;
  align-items: center;
  &:hover{
    cursor:pointer;
  }
`
export const CloseIcon = styled.img`
  width:16px;
  height:16px;
`