import React, { FC, useState, useEffect, useRef } from 'react'
import {
  Container,
  ToolTipImg,
  StyledInput,
  ErrorText,
  Eye,
  StyledTextArea,
  IconInput,
  PropContainer,
  Prop,
  DeleteTag,
  PrefixText
} from './styles'
import { BodyMedium } from '../typography/styled'
import { icons } from '../../icons'
import Label from '../label'
import StyledIcon from '../styled-icon'
import { BlueText } from '../../pages/SignIn/styles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask';
import Typography from '../typography'
type InputProps = {
  values?:any,
  title:string,
  toolTip?:boolean,
  toolTipText?:string,
  placeholder:string,
  handleChange?:any,
  touched?:any,
  errors?:any,
  fieldName?:any,
  isPassword?:boolean,
  customStyles?:any,
  forgot?:boolean,
  onBlur?:any,
  handleBlur?:any,
  onFocus?:any,
  onFocusOut?:any,
  ref?:any,
  isTextArea?:any,
  containerCustom?:any,
  phoneNumber?:boolean,
  readOnly?:boolean,
  masked?:boolean,
  mask?:string,
  handleKeyDown?:any,
  defaultValue?:any,
  type?:string,
  value?:any,
  required?:boolean,
  arrayFields?:boolean
  arrayName?:any,
  arrayIndex?:any,
  arrayFieldName?:any,
  manualErr?:any,
  manualTouched?:any,
  icon?:string,
  tags?:any,
  changeTags?:(tag:string) => void;
  isRestrictionIp?:boolean;
  prefix?:string;
}

const Input:FC<InputProps> = (props) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [show, setShow] = useState<boolean>(false)
  const [localError, setLocalError] = useState<any>(false)

  const {
    values,
    handleChange,
    touched,
    errors,
    fieldName,
    isPassword,
    title,
    toolTip=false,
    placeholder,
    customStyles,
    forgot,
    onBlur,
    onFocus,
    ref,
    onFocusOut,
    isTextArea,
    containerCustom,
    handleBlur,
    phoneNumber,
    readOnly,
    toolTipText,
    masked,
    mask,
    handleKeyDown,
    defaultValue,
    type,
    value,
    required,
    arrayFields=false,
    arrayName,
    arrayIndex,
    arrayFieldName,
    manualErr,
    manualTouched,
    icon,
    tags,
    changeTags,
    isRestrictionIp,
    prefix
  } = props
  const TagRef:any = useRef()
  // console.log('tags', tags);
  const ipPattern = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])$/;
  // const ipPattern = /^((25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])(\/\d{1,2})?$/;

  const handleSpacebar = (event:any) => {
    if (event.code === 'Space' && changeTags) {
      if(isRestrictionIp && !ipPattern.test(values?.[fieldName]?.replaceAll(' ', ''))){
        setLocalError(`${t("RESTRICTION_IP_VALIDATE")}`)
        return
      }
      if(values?.[fieldName]?.length >= 20){
        setLocalError(`${t("STRING_VALIDATE_MAX")} 20 ${t("CHARACTERS")}`)
        return
      } else {
        changeTags(values?.[fieldName]?.replaceAll(' ', ''))
        setLocalError('')
        TagRef.current.value = ''
        handleChange('')
      }
    }
  };
  // useEffect(() => {
  //   setLocalError('')
  // },[tags])
  useEffect(() => {
    if(tags){
      window.addEventListener('keydown', handleSpacebar);
      return () => {
        window.removeEventListener('keydown', handleSpacebar);
      };
    }
  });
  const prefixRef:any = useRef()

  const fieldValue = values?.[fieldName];
  const fieldError = manualErr ? manualErr : arrayFields ? errors?.[arrayName]?.[arrayIndex]?.[arrayFieldName] : errors?.[fieldName];
  const fieldTouched = manualTouched ? manualTouched : arrayFields ? touched?.[arrayName]?.[arrayIndex]?.[arrayFieldName] : touched?.[fieldName];
  return(
    <Container containerCustom={containerCustom}>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
        <Label required={required} title={title} toolTip={toolTip} tooltipText={toolTipText} />
      </div>
      {icon ? 
        <IconInput>
          <img src={icon}/>
        </IconInput>
      : null}
      {isTextArea ? 
        <StyledTextArea
          customStyles={customStyles}
          ref={ref}
          error={fieldTouched && (fieldError || localError)}
          placeholder={placeholder}
          name={fieldName}
          value={fieldValue}
          readOnly={readOnly}
          onFocus={onFocus}
          // autocomplete="off"
          onBlur={(val) => {
            if(onFocusOut){
              onFocusOut()
            }
            if(handleBlur){
              handleBlur(val)
            }
          }}
          onChange={handleChange}
        />
      :
        phoneNumber || masked ? 
          <InputMask
            mask={mask || '+99 (999) 999-99-99'}
            value={fieldValue}
            onChange={handleChange}
          >
            <StyledInput
              customStyles={customStyles}
              ref={ref}
              error={fieldTouched && fieldError}
              placeholder={placeholder}
              name={fieldName}
              onFocus={onFocus}
              type={!show && isPassword ? 'password' : 'text'}
              onKeyDown={handleKeyDown}
    
            />
          </InputMask>
        :
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          {prefix &&
            <div 
              style={{
                position: 'absolute',
                // transform: 'translateY(50%)',
                top:"15px",
                left:'10px',
                zIndex:10,
              }}
              ref={prefixRef}
            >
              <PrefixText>
                {prefix}-
              </PrefixText>
            </div>
          }
          <StyledInput
            customStyles={customStyles}
            style={prefix ? {paddingLeft:prefixRef?.current?.offsetWidth+10} : {}}
            ref={ref || TagRef}
            error={(fieldTouched && fieldError) || localError}
            defaultValue={defaultValue}
            placeholder={placeholder}
            name={fieldName}
            value={fieldValue || value}
            onFocus={onFocus}
            readOnly={readOnly}
            // autoComplete={disableAutocomplete ? 'off' : ''}
            onBlur={(val) => {
              if(onFocusOut){
                onFocusOut()
              }
              if(handleBlur){
                handleBlur(val)
              }
            }}
            type={type ? type : (!show && isPassword) ? 'password' : 'text'}
            onChange={(e) => changeTags ? e.target.value === ' ' ? null : handleChange(e) : handleChange(e)}
          />
        </div>
      }
      {isPassword ? 
        <Eye>
          <StyledIcon onClick={() => setShow(!show)} src={show ? icons.ShowPassword : icons.HidePassword}/>
        </Eye>
      : null}
      <ErrorText>
        {(fieldTouched && fieldError) || localError}
      </ErrorText>
      {tags !== ' ' && tags !== undefined && tags && tags?.length > 0 && 
        <div style={{
          display:'flex',
          flexWrap:"wrap",
          alignItems:'center',
          gap:'4px',
          marginTop:'10px'
          }}>
          {tags?.map((tag:string) => (
            <PropContainer>
              <Prop>
                {tag}
              </Prop>
              {changeTags &&
                <DeleteTag onClick={() => changeTags(tag)} style={{width:'8px', height:'8px'}} src={icons.CloseIcon}/>
              }
              </PropContainer>
          ))}
        </div>
      }
    </Container>
  )
}

export default Input