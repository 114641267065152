import styled from "styled-components";
import { BodyDefault, BodyMedium, HeadLine2 } from "../../../../components/typography/styled";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContainerTitle = styled(HeadLine2)`
  color:${({theme}) => theme.palette[850]};
  z-index: 3;
  margin-bottom:10px;
`

export const ServicesWrapper = styled.div`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:10px;

  /* flex: 1 1 160px; */
`
type ItemProp = {
  disabled?:boolean;
}
export const ServiceItem = styled.div<ItemProp>`
  display: flex;
  box-sizing: border-box;
  padding: 14px 20px;
  /* width:33%; */
  text-decoration: none;
  gap: 10px;
  width: 30%;
  /* height: 92px; */
  border: ${({theme}) => theme.isDark ? '' : '1px solid #E8E8E8'};
  border-radius: 8px;
  /* flex: none; */
  flex-grow: 1;
  opacity: ${({disabled}) => disabled ? '0.5' : '1'};
  background-color: ${({theme}) => theme.palette[10]};
  /* flex-basis: 33.33333%; */
  &:hover {
    cursor: pointer;
    background: ${({theme, disabled}) => disabled ? '' : theme.isDark ? theme.palette[200] : theme.grey[100]};
  }
  &:active{
    background-color: ${({theme, disabled}) => disabled ? '' : theme.isDark ? theme.palette[400] : theme.grey[200]};
    transition: 0.1s background-color ease-in-out;
  }
`

export const IconService = styled.img`
  width:24px;
  height:24px;
  margin-right:10px;
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ServiceTitle = styled(BodyMedium)`
  color:${({theme}) => theme.palette[800]};
  display: flex;
  align-items: center;
  gap:6px;
`

export const ServiceSubTitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
`

export const SoonText = styled.div`
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  white-space: nowrap;
  background-color: ${({theme}) => theme.grey[300]};
  line-height: 12px;
  text-transform: uppercase;
  color:${({theme}) => theme.palette[800]};
  border-radius: 4px;
  padding: 2px;
`