import React, { FC, useEffect } from 'react'
import RenameModal from './rename'
import DeleteModal from './delete-warning'
import UnsavedModal from './unsaved-changes'
import AssingIp from './assing-floating-ip'
import Dialog from '../dialog'
import DissociateIp from './dissociate-ip'
import InviteUser from './invite-user'
import CompleteModal from './complete-registration'
import RegenerateWarning from './regenerate-google-code'
import EditNotifications from './edit-notification-user'
import EditRoles from './edit-roles-user'
import UniversalModal from './universal-modal'
import PaymentSuccess from './payment-success'
import CreateSubnet from './create-subnet'
import CreateRouter from './create-router'
import AddInterface from './add-interface'
import SetPtr from './set-ptr'
import CreateSSh from './create-ssh'
import AddPortToInstance from './add-port-to-instance'
import DetachPort from './detach-port'
import AttachPort from './attach-port'
import AddIpToInstance from './add-ip-to-instance'
import AddIpToInstanceAutomatically from './add-ip-automatically'
import AttachVolume from './attach-volume'
import InstanceResize from './resize-instance'
import RebuildInstance from './rebuild-instance'
import RescueInstance from './rescue-instance'
import ChangePassword from './change-password'
import PasswordConfirm from './password-confirm'
import ExtendVolume from './extend-volume'
import ChangeBootableStatus from './edit-bootable-status'
import UploadVolumeToImage from './uploadVolume-toImage'
import CreateSnapshot from './create-snapshot'
import CreateBackup from './create-backup'
import RevertVolume from './revert-volume'
import ResetStateVolumeSnapshot from './update-volume-snapshot-state'
import RestoreVolumeBackup from './restore-volume-backup'
import {RestoreVolumeBackups} from './modal-types'
import CopyImage from './copy-image'
import AddCredit from './add-credit'
import PayMultipleInvoices from './multiple-invoices'
import AssociateSecurityToInstance from './associate-security-to-instance'
import AssignIpToBalancer from './assign-ip-to-balancer'
import CreateInstanceSnapshot from './create-instance-snapshot'
import CreateInstanceBackup from './create-instance-backup'
import CreateInstanceBackupSchedule from './create-backup-schedule'
import CreateFloatingIp from './create-floating-ip'
import DeleteNetwork from './delete-network'
import EditVolumeBackup from './edit-backup-volume'
import ScalePool from './scale-pool'
import CreateVolumeBackup from './create-volume-backup'
import AddTag from './add-tag'
import ClusterCreate from './cluster-create-alert'
import RequestAccessCluster from './request-access-cluster'
import BareMetalFloatingAdd from './baremetal-floating-add'
import BareMetalDissociateFloating from './baremetal-dissociate-floating'
import EditBareMetal from './edit-baremetal'
import ReinstallBareMetal from './reinstall-baremetal'
import BareMetalBreakDown from './bare-metal-breakdown'
import ReinstallStatus from './baremetal-reinstall-status'
import EnableGoogleAuth from './alert-2fa'
import ChangePasswordUser from './change-password-user'
import DisableGoogleAuth from './alert-2fa-disable'
import AddApiToken from './add-api-token'
import UpgradeCluster from './upgrade-kubernetes'
import ConfirmPaymentMethod from './confirm-payment-method'
import AddFolder from './add-folder'
import MoreBucketInfo from './bucket-file-more-info'
import BucketMetadata from './bucket-file-metadata'
import BucketVisibility from './bucket-visibility'
import BucketTags from './bucket-file-tags'
import BucketСopyFileLink from './bucket-copy-filelink'


type ModalProp = {
  viewModal:boolean,
  activeAnimation:boolean,
  closeModal:(props?:any, setErrors?:any) => void,
  closeModalCancel:() => void,
  modalType:'delete'|'rename'|'assign-ip'|'dissociate-ip'|'unsaved'|'invite-user'|'complete'|'regenerate-google'|
  'edit-notifications'|'edit-roles'|'universal'|'payment-success'|'create-subnet'|'create-router'|'add-interface'|
  'set-ptr'|'create-ssh'|'generate-ssh'|'add-port-to-instance'|'detach-port'|'attach-port'|'add-ip-to-instance'|
  'add-ip-to-instance-auto'|'attach-volume'|'detach-volume'|'instance-resize'|'rebuild-instance'|'rescue-instance'|
  'change-password'|'confirm-password'|'extend'|'change-status'|'upload-volume-to-image'|'create-snapshot'|
  'create-backup'|'revert-volume'|'update-volume-snapshot'|'restore-volume-backup'|'copy-image'|'add-credit'|
  'pay-multiple-invoices'|'associate-security'|'add-ip-to-balancer'|'create-instance-snapshot'|'create-instance-backup'|
  'create-backup-schedule'|'create-floating-ip'|'delete-networks'|'edit-volume-backup'|'scale-pool'|'create-volume-backup'|
  'add-tag'|'cluster-alert'|'request-access-cluster'|'baremetal-add-floating'|'baremetal-dissociate-floating'|'edit-baremetal'|
  'reinstall-baremetal'|'baremetal-breakdown'|'enable-google-auth'|'change-password-user'|'disable-google-auth'|
  'baremetal-status'|'add-api-token'|'upgrade-cluster'|'confirm-payment-method'|'add-folder'|'more-bucket-info'|
  'bucket-metadata'|'bucket-visibility'|'bucket-file-tags'|'bucket-copy-filelink',
  id?:any,
  alertTitle?:any,
  alertSubTitle?:any,
  confirmButtonText?:any,
  cancelButtonText?:any,
  dataToEdit?:any,
  loading?:boolean,
  loadingComplete?:boolean,
  instanceId?:string,
  instance?:any,
  restoreVloumeBackupProps?:RestoreVolumeBackups,
  setScroll?:any,
}

const ModalVariables:FC<ModalProp> = (props) => {
    const {
    viewModal,
    activeAnimation,
    closeModal,
    closeModalCancel,
    modalType,
    id,
    alertTitle,
    alertSubTitle,
    dataToEdit,
    loading,
    instanceId,
    instance,
    restoreVloumeBackupProps,
    confirmButtonText,
    cancelButtonText,
    loadingComplete,
  } = props
  const Variable = () => {
    const setHidden = () => {
      document.body.style.overflowY = "hidden";
    };
    const setScroll = () => {
      document.body.style.overflowY = "scroll";
    };
    useEffect(() => {
      if(viewModal && activeAnimation){
        setHidden()
      } else {
        setScroll()
      }
    },[viewModal, activeAnimation])
    const modalProps = {
      closeModal:closeModal,
      closeModalCancel:closeModalCancel,
      obj:id,
      alertTitle:alertTitle,
      alertSubTitle:alertSubTitle,
      dataToEdit:dataToEdit,
      loading:loading,
      viewModal:viewModal,
      instanceId:instanceId,
      modalType:modalType,
      instance:instance,
      confirmButtonText:confirmButtonText,
      cancelButtonText:cancelButtonText,
      loadingComplete:loadingComplete,
      setScroll:setScroll,
    }
    switch(modalType){
      case 'delete':
        return <DeleteModal {...modalProps}/>
      case 'rename':
        return <RenameModal {...modalProps}/>
      case 'assign-ip':
        return <AssingIp {...modalProps}/>
      case 'dissociate-ip':
        return <DissociateIp {...modalProps}/>
      case 'detach-port':
        return <DetachPort {...modalProps}/>
      case 'unsaved':
        return <UnsavedModal {...modalProps}/>
      case 'invite-user':
        return <InviteUser  {...modalProps}/>
      case 'complete':
        return <CompleteModal {...modalProps}/>
      case 'regenerate-google':
        return <RegenerateWarning {...modalProps}/>
      case 'edit-notifications':
        return <EditNotifications {...modalProps}/>
      case 'edit-roles':
        return <EditRoles {...modalProps}/>
      case 'universal':
        return <UniversalModal  {...modalProps}/>
      case 'payment-success':
        return <PaymentSuccess  {...modalProps}/>
      case 'create-subnet':
        return <CreateSubnet {...modalProps}/>
      case 'create-router':
        return <CreateRouter {...modalProps}/>
      case 'add-interface':
        return <AddInterface {...modalProps}/>
      case 'set-ptr':
        return <SetPtr {...modalProps}/>
      case 'create-ssh':
        return <CreateSSh {...modalProps}/>
      case 'generate-ssh':
        return <CreateSSh {...modalProps}/>
      case "add-port-to-instance":
        return <AddPortToInstance {...modalProps}/>
      case "attach-port":
        return <AttachPort {...modalProps}/>
      // case "attach-volume":
      //   return <AttachPort {...modalProps}/>
      case 'add-ip-to-instance':
        return <AddIpToInstance {...modalProps}/>
      case 'add-ip-to-instance-auto':
        return <AddIpToInstanceAutomatically {...modalProps}/>
      case 'detach-volume':
        return <DetachPort {...modalProps}/>
      case 'attach-volume':
        return <AttachVolume {...modalProps}/>
      case 'instance-resize':
        return <InstanceResize {...modalProps}/>
      case 'rebuild-instance':
        return <RebuildInstance {...modalProps}/>
      case 'rescue-instance':
        return <RescueInstance {...modalProps}/>
      case 'change-password':
        return <ChangePassword {...modalProps}/>
      case 'confirm-password':
        return <PasswordConfirm {...modalProps}/>
      case 'extend':
        return <ExtendVolume {...modalProps}/>
      case 'change-status':
        return <ChangeBootableStatus {...modalProps}/>
      case 'upload-volume-to-image':
        return <UploadVolumeToImage {...modalProps}/>
      case 'create-snapshot':
        return <CreateSnapshot {...modalProps}/>
      case 'create-backup':
        return <CreateBackup {...modalProps}/>
      case 'revert-volume':
        return <RevertVolume {...modalProps}/>
      case 'update-volume-snapshot':
        return <ResetStateVolumeSnapshot {...modalProps}/>
      case 'restore-volume-backup':
        return <RestoreVolumeBackup {...modalProps} {...restoreVloumeBackupProps}/>
      case 'copy-image':
        return <CopyImage {...modalProps}/>
      case 'add-credit':
        return <AddCredit {...modalProps}/>
      case 'pay-multiple-invoices':
        return <PayMultipleInvoices {...modalProps}/>
      case 'associate-security': 
        return <AssociateSecurityToInstance {...modalProps}/>
      case 'add-ip-to-balancer':
        return <AssignIpToBalancer {...modalProps}/>
      case 'create-instance-snapshot':
        return <CreateInstanceSnapshot {...modalProps}/>
      case 'create-instance-backup':
        return <CreateInstanceBackup {...modalProps}/>
      case 'create-backup-schedule':
        return <CreateInstanceBackupSchedule {...modalProps}/>
      case 'create-floating-ip':
        return <CreateFloatingIp {...modalProps}/>
      case 'edit-volume-backup':
        return <EditVolumeBackup {...modalProps}/>
      case 'delete-networks':
        return <DeleteNetwork {...modalProps}/>
      case 'edit-volume-backup':
        return <EditVolumeBackup {...modalProps}/>
      case 'scale-pool':
        return <ScalePool {...modalProps}/>
      case 'create-volume-backup':
        return <CreateVolumeBackup {...modalProps}/>
      case 'add-tag':
        return <AddTag {...modalProps}/>
      case 'scale-pool':
        return <ScalePool {...modalProps}/>
      case 'cluster-alert':
        return <ClusterCreate {...modalProps}/>
      case 'request-access-cluster':
        return <RequestAccessCluster {...modalProps}/>
      case 'baremetal-add-floating':
        return <BareMetalFloatingAdd {...modalProps}/>
      case 'baremetal-dissociate-floating':
        return <BareMetalDissociateFloating {...modalProps}/>
      case 'edit-baremetal':
        return <EditBareMetal {...modalProps}/>
      case 'reinstall-baremetal':
        return <ReinstallBareMetal {...modalProps}/>
      case 'baremetal-breakdown':
        return <BareMetalBreakDown {...modalProps}/>
      case 'enable-google-auth':
        return <EnableGoogleAuth {...modalProps}/>
      case 'change-password-user':
        return <ChangePasswordUser {...modalProps}/>
      case 'disable-google-auth':
        return <DisableGoogleAuth {...modalProps}/>
      case 'baremetal-status':
        return <ReinstallStatus {...modalProps}/>
      case 'add-api-token':
        return <AddApiToken {...modalProps}/>
      case 'upgrade-cluster':
        return <UpgradeCluster {...modalProps}/>
      case 'confirm-payment-method':
        return <ConfirmPaymentMethod {...modalProps}/>
      case 'add-folder':
        return <AddFolder {...modalProps}/>
      case 'more-bucket-info':
        return <MoreBucketInfo {...modalProps}/>
      case 'bucket-metadata':
        return <BucketMetadata {...modalProps}/>
      case 'bucket-visibility':
        return <BucketVisibility {...modalProps}/>
      case 'bucket-file-tags':
        return <BucketTags {...modalProps}/>
      case 'bucket-copy-filelink':
        return <BucketСopyFileLink {...modalProps}/>
    }
  }

  return(
  <Dialog
    noIframe
    open={viewModal}
    active={activeAnimation}
    handleClose={closeModalCancel}
  >
    {Variable()}
  </Dialog>
  )
}

export default ModalVariables