import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { clientService } from "../../../../../api";
import { CheckBox, InfoBlock, TopProgress } from "../../../../../components";
import { useUi } from "../../../../../context/ui.context";
import { timeConverterMMDDYYHHMM } from "../../../../../utils/dates";
import {
  PageContainer,
  LabelWrapper
} from '../styles'

const Details:FC = () => {
  const [t] = useTranslation()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const { id } = useParams();
  const [info, setInfo] = useState<any[]>([])
  const {viewAlert} = useUi()
  useEffect(() => {
    if(id){
      clientService.getClient(id)
      .then((res) => {
        setInfo([
          {title:t("COUNTRY"), value:res.data.country_name},
          // {title:t("CITY"), value:'Kharkiv'},
          // {title:t("ADDRESS"), value:'My Address, 1'},
          // {title:t("ZIP_CODE"), value:'62433'},
          {title:t("PHONE"), value:res.data.phone},
          {title:t("ID"), value:res.data.id},
          {title:t("CREATED_AT"), value:timeConverterMMDDYYHHMM(res.data.date_created)},
          {title:t("CLIENT_IS_ON_A_BILLING"), value:res.data?.has_openstack_services ? t('YES') : t('NO')},
        ])
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
  },[])
  return(
    <PageContainer style={{flexDirection:'row', gap:'10px'}}>
      <TopProgress loading={pageLoading}/>
      {pageLoading ? null :
        <InfoBlock data={info} title={t("CLIENT_INFO")}/>
      }
    </PageContainer>
  )
}

export default Details