import React, { FC, useEffect, useState } from "react";
import {
  Container,
  TitleBig,
  FormWrapper,
  BodyWrapper,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
} from './styles'
import { BackArrow, Input, RadioButtonGroup, DonutArea, Button, Select, Counter, TopProgress, LoaderSpinner } from "../../../components";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { volumeService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { useTheme } from "styled-components";
import { useAuth } from "../../../context/user.context";
import { formatCurrency, formatCurrencyString } from "../../../utils/display-price";



const CreateAndEditVolume:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const theme:any = useTheme()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [volumeTypes, setVolumeTypes] = useState<any[]>([])
  const [sourceImage, setSourceImage] = useState<any[]>([])
  const [sourceVolume, setSourceVolume] = useState<any[]>([])
  const [region, setRegion] = useState<any>()
  const {user} = useAuth()
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()
  const {quotas} = useAuth()

  const [initialValues, setInitialValues] = useState<any>({
      name:'',
      size:'10',
      source_type:'new',
      type:'__DEFAULT__',
  })
  const getCreateOptions = async () => {
    try{
      const res = await volumeService.getCreateOptions()
      setRegion(res.data.regions?.[0]?.id)
      setVolumeTypes(res.data.types.map((type:any) => {
        return {
          ...type,
          value:type.name,
          title:type.type_display
        }
      }))
      setSourceVolume(res.data.sources.volume.map((vol:any) => {
        return {
          ...vol,
          value:vol.id,
          label:vol.name || vol.id,
        }
      }))
      setSourceImage(res.data.sources.image.filter((im:any) => im.name.indexOf('kube') === -1).map((image:any) => {
        return {
          ...image,
          label: image.name,
          value:image.id,
        }
      }))
      setPageLoading(false)
    } catch (err:any) {
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message: err.response?.data?.detail
        })
      }
      setPageLoading(false)
    }
    
  }
  useEffect(() => {
    getCreateOptions()
  },[])
  const onSubmit = (values:any) => {
    setLoading(true)
    const data:any = {
      availability_zone: "",
      description: "",
      name: values.name,
      region: region,
      size: values.size,
      type: values.type,
    }
    if(values.source_type === 'new') {
      data.source = {source_type: values.source_type}
    } else {
      data.source = {source: values.source, source_type: values.source_type}
    }
    volumeService.createVolume(data)
    .then((res) => {
      setLoadingComplete(true)
      setTimeout(() => {
        navigate('/volumes')
        resetForm()
        setLoadingComplete(false)
        setLoading(false)
      },1000)
    })
    .catch((err) => {
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message: err.response?.data?.detail
        })
      } else {
        setErrors(err?.response?.data)
      }
      setLoading(false)
    })
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/

  const {
    values,
    errors,
    touched,
    resetForm,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name:yup.string()
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .required(`${t('REQUIRED_FIELD')}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      size:yup.number().required(`${t('REQUIRED_FIELD')}`)
      .positive()
      .integer()
      .min(10, `${t("SIZE_MUST_BE_GREATER_OR_EQUAL")} 10GB`)
      .max((quotas?.volume?.gigabytes?.limit - quotas?.volume?.gigabytes?.in_use), `${t("SIZE_MUST_BE_LESS_THAN_OR_EQUAL")} ${Math.floor(quotas?.volume?.gigabytes?.limit - quotas?.volume?.gigabytes?.in_use)} GB`),
      source_type: yup.string().required(`${t('REQUIRED_FIELD')}`),
      type: yup.string().required(`${t('REQUIRED_FIELD')}`),
      source:yup.string().when("source_type", {
        is: (source_type:any) => source_type === 'image' || source_type === 'volume',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const defaultDataDisk = [
    {
      part1:10,
      part2:32,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_READING"),
      value:50,
      valueMax:160
    },
    {
      part1:4,
      part2:10.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_WRITING"),
      value:20,
      valueMax:54
    }
  ]
  const defaultOperationDisk = [
    {
      part1:4.4,
      part2:22.2,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_IOPS_READING"),
      value:2000,
      valueMax:10000
    },
    {
      part1:1.5,
      part2:28.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_IOPS_WRITING"),
      value:700,
      valueMax:13000
    }
  ]

  const getCurrentPrice = () => {
    let queries:any = {
      volume_size: values['size'],
      volume_type:values['type'],
      region: region,

    }

    if(values['size'] && values['type'] && region){
      setPriceLoading(true)
      volumeService.getEstimateCoast(queries)
      .then((res) => {
        setPrice(+formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
        setPriceMonth(+formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
        setPriceLoading(false)
      })
    }
  }
  
  useEffect(() => {
    getCurrentPrice()
  },[values['size'], values['type'], region])
  const buttons = [
    {
      value:'new',
      id:'1',
      title:t("EMPTY_DISK")
    },
    {
      value:'image',
      id:'2',
      title:t("IMAGE")
    },
    {
      value:'volume',
      id:'3',
      title:t("VOLUME")
    },
    // {
    //   value:'4',
    //   id:'4',
    //   title:'Snapshot'
    // }
  ]
  console.log('price', price);
  console.log('priceMonth', priceMonth);

  if(pageLoading) return (
    <TopProgress loading={pageLoading}/>
  )
  return(
    <Container>
      <BodyWrapper>
        <BackArrow
          onClick={() => navigate('/volumes')}
          title={t("BACK_TO_VOLUMES")}
        />
        <TitleBig>{t("CREATE_NEW_VOLUME")}</TitleBig>
        <FormWrapper>
          <Input
            fieldName='name'
            title={t("NAME_VOLUME")}
            required
            placeholder=''
            customStyles={{width:'360px'}}
            {...formikProps}
          />
          <div style={{width: '500px'}}>
            <RadioButtonGroup
              toolTip={false}
              title={t("SOURCE_TYPE")}
              buttons={buttons}
              selectedButton={values['source_type']}
              onChange={(e) => handleChange('source_type')(e)}
            />
          </div>
          {values['source_type'] === 'image' ? 
            <Select
              toolTip={false}
              data={sourceImage}
              selectedValue={values['source']}
              onChange={(e) => handleChange('source')(e)}
              title={t("SOURCE")}
              customStyles={{width:'360px'}}
              test_id="source"
            />
          : null}
          {values['source_type'] === 'volume' ? 
            <Select
              toolTip={false}
              data={sourceVolume}
              selectedValue={values['source']}
              onChange={(e) => handleChange('source')(e)}
              title={t("SOURCE")}
              customStyles={{width:'360px'}}
              test_id="source"
            />
          : null}
          <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            <RadioButtonGroup
              toolTip={false}
              title={t("DISK_TYPE")}
              buttons={volumeTypes}
              selectedButton={values['type']}
              onChange={(e) => handleChange('type')(e)}
            />
          </div>
          <div style={{display:'flex',flexWrap:'wrap', gap:'20px'}}>
            <DonutArea
              title={t("BANDWIDTH")}
              subtitle={t("MB_S")}
              isLeft
              data={defaultDataDisk}
            />
            <DonutArea
              title={t("OPERATIONS_PER_SEC")}
              subtitle={t("IOPS")}
              data={defaultOperationDisk}
            />
          </div>
          <Counter
            // customStyles={{marginBottom:"20px"}}
            toolTip={true}
            tooltipText={`${t("STORAGE_SIZE_TOOLTIP")}`}
            title={t("STORAGE")}
            value={values['size']}
            minCount={1}
            hideItems={'GB'}
            onChange={(v:any) => {
              handleChange('size')(v.toString())
            }}
            fieldName='size'
            {...formikProps}
          />
        </FormWrapper>
        <div style={{display:'flex', marginTop:'40px'}}>
          <Button
            variant="primary"
            size="display"
            customStyles={{marginRight:"10px"}}
            onClick={handleSubmit}
            loading={loading}
            loadingComplete={loadingComplete}
            title={t("CREATE")}
          />
          <Button
            variant="stroke"
            size="display"
            // customStyles={{width: '81px'}}
            onClick={() => navigate("/volumes")}
            title={t("CANCEL")}
          />
        </div>
      </BodyWrapper>
      <EstimateContainer>
        <EstimateTitle>
          {t("ESTIMATED_COST")}
        </EstimateTitle>
        {priceLoading ? 
          <LoaderSpinner customStyles={{color:theme.base.black}}/>
        :
          price && priceMonth ? 
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              <div style={{display:'flex', alignItems:'flex-end'}}>
                <EstimateSum>
                  {`${formatCurrency(price?.toFixed(3), user.user.clients?.[0]?.currency || 'UAH')}`}
                </EstimateSum>
                <PerHour>{t("HOURS")}</PerHour>
              </div>
              <div style={{display:'flex', alignItems:'flex-end'}}>
                <EstimateSum>
                  {`${formatCurrency(priceMonth?.toFixed(2), user.user.clients?.[0]?.currency || 'UAH')}`}
                </EstimateSum>
                <PerHour>{t("MONTH")}</PerHour>
              </div>
            </div>
          :
          <PerHour>
            {t("SELECT_OPTIONS_TO_DISPLAY_PRICE")}
          </PerHour>
        }
      </EstimateContainer>
    </Container>
  )
}

export default CreateAndEditVolume
