import React, {FC, useEffect, useRef, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  BodyWrapper,
  InfoTopBlocks,
  NetworkText,
  PropsContainer,
} from './styles'
import { bareMetalService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { Button, DropOptions, InfoBlock, ModalVariables, TopProgress, Typography } from "../../../../components";
import { useTranslation } from "react-i18next";
import {timeConverterMMDDYYHHMM} from '../../../../utils/dates'
import { InfoContainer, InfoTitle } from "../../../../components/info-block/styles";
import { icons } from "../../../../icons";
import { useTheme } from "styled-components";
import OperationSkeleton from './skeleton'
import FloatingBigIcon from './icon.svg'
import { formatQuotasRam, formatStorageMB } from "../../../../utils/sizes";
import { StyledIconMore } from "../../../../components/table/styles";

const BareMetalPage:FC<any> = ({bareMetal, updateData}) => {
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [otherDetails, setOtherDetails] = useState<any>([])
  const [serverSpecifications, setServerSpecifications] = useState<any>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const _updateOperation = () => {
    setServerSpecifications([
      {
        title:t("IMAGE"),
        value:bareMetal.image,
      },
      {
        title:t("TYPE"),
        value:bareMetal.type,
      },
      {
        title:t("MEMORY"),
        value:(
          <>
            {/* {bareMetal?.memory.count}
            &nbsp;X&nbsp; */}
            {formatQuotasRam(bareMetal?.memory.value)}
          </>
        ),
      },
      {
        title:'CPU',
        value:(
          <>
            {/* {bareMetal?.cpu.count}
            &nbsp;X&nbsp; */}
            {bareMetal?.cpu.model}
          </>
        ),
      },
      {
        title:t("STORAGE"),
        value:(
          <>
          {/* {bareMetal.storage.count}X  */}
          {formatStorageMB(bareMetal.storage.value, 1)} {bareMetal.storage.model}
          </>
        ),
      },

    ])
    setOtherDetails([
      {
        title:t("ID"),
        copy: true,
        value:bareMetal.id,
      },
      {
        title:t("CREATED_AT"),
        value:timeConverterMMDDYYHHMM(bareMetal.created_at),
      },
      // {
      //   title:t("BANDWIDTH_TYPE"),
      //   value:'Shared',
      // },
      // {
      //   title:t("PUBLICK_BANDWIDTH"),
      //   value:'1GB',
      // },
      // {
      //   title:t("PRIVATE_BANDWIDTH"),
      //   value:'1GB',
      // },
      {
        title:t("SSH_COMMAND"),
        copy:true,
        value:`ssh root@${bareMetal.primary_ip}`,
      },
      {
        title:t("TAGS"),
        value:bareMetal?.tags?.length === 0 ? '-' : '',
        options:bareMetal?.tags
      },
    ])
    setPageLoading(false)
  }
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = async (values:any) => {
    let res:any; // объявляем переменную res
    try {
      setLoading(true);
      switch (modalType) {
        case 'baremetal-add-floating':
          res = await bareMetalService.addFloating(bareMetal.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        default:
          break;
      }
  
      updateData();
      setViewModal(false);
      setActiveAnimation(false);
      setModalType('')
    } catch (e: any) {
      viewAlert({
        severity: 'error',
        message: e?.response?.data?.detail || t("ERROR_OCCURRED"),
      });
    } finally {
      setLoading(false);
    }
    
  }
  useEffect(() => {
    _updateOperation()
    const interval = setInterval(() => {
      _updateOperation()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[])
  if(pageLoading) return <OperationSkeleton pageLoading={pageLoading}/>
  return(
    <Container>
      <BodyWrapper>
        <InfoTopBlocks>
          <InfoBlock
            title={t("SERVER_SPECIFICATIONS")} 
            customStyles={{
              flex:'auto 1',
              marginBottom:'0px',
              flexGrow:'1',
              flexBasis:'0'
              }}
            data={serverSpecifications}
          />
          <InfoBlock
            title={t("OTHER_DETAILS")} 
            customStyles={{
              flex:'auto 1',
              marginBottom:'0px',
              flexGrow:'1',
              flexBasis:'0'
            }}
            data={otherDetails}
          />
        </InfoTopBlocks>
        {bareMetal.floating_ips?.length > 0 ?
          <InfoContainer style={{display:'flex', alignItems:'flex-start'}}>
            <InfoTitle style={{marginBottom:'10px'}}>
              {t("FLOATING_IP")}
            </InfoTitle>
            <Button
              size="display"
              variant="primary"
              title={t("ADD_FLOATING_IP")}
              customStyles={{marginBottom:'20px'}}
              onClick={() => {
                setAlertTitle(bareMetal.name)
                openModal('baremetal-add-floating')}}
            />
            {bareMetal.floating_ips.map((floating:any) => (
              <div key={floating.ip}>
                <IpComponent updateData={updateData} bareMetalId={bareMetal.id} floating={floating}/>
              </div>
            ))}
          </InfoContainer>
        :
          <InfoContainer style={{width:'100%', display:'flex', gap:'14px', alignItems:'center'}}>
            <InfoTitle>
              {t("FLOATING_IP")}
            </InfoTitle>
            <img style={{width:'82px'}} src={FloatingBigIcon}/>
            <Typography variant="body">
              {t("FLOATING_DESCRIPTION")}
            </Typography>
            <Button
              size="display"
              variant="primary"
              title={t("ADD_FLOATING_IP")}
              onClick={() => {
                setAlertTitle(bareMetal.name)
                openModal('baremetal-add-floating')
              }}
            />
          </InfoContainer>
        }
      </BodyWrapper>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        instanceId={bareMetal.id}
        loading={loading}
      />
    </Container>
  )
}

export default BareMetalPage


const IpComponent:FC<any> = ({floating, updateData, bareMetalId}) => {
  const [active, setActive] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const {viewAlert} = useUi()
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  const [t] = useTranslation()
  useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
      document.removeEventListener('click', handleClickOutside, true);
		};
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef?.current?.contains(event.target) && !dropsRef?.current?.contains(event.target)){
      setActive(false)
    }
  }
  const options = [
    {
      title:t("DISSOCIATE_IP"),
      onClick:(floating:any) => {
        setAlertTitle(`${t("DISSOCIATING_IP")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_DISSOCIATE_IP")} ${floating?.ip}?`)
        openModal('universal')
      },
      id:'6',
      icon:icons.MinusDefault,
      divider:false
    }
  ]
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = async (values:any) => {
    let res:any; // объявляем переменную res
    try {
      setLoading(true);
      switch (modalType) {
        case 'universal':
          setLoading(true);
          if (alertTitle === `${t('DISSOCIATING_IP')}`) {
            res = await bareMetalService.removeFloating(bareMetalId, {ip:floating?.ip})
          } 
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        default:
          break;
      }
  
      updateData();
      setViewModal(false);
      setActiveAnimation(false);
    } catch (e: any) {
      viewAlert({
        severity: 'error',
        message: e?.response?.data?.detail || t("ERROR_OCCURRED"),
      });
    } finally {
      setLoading(false);
    }
    
  }
  return(
    <InfoContainer style={{width:'145px', padding:'6px 10px', position:'relative'}}>
      <Typography variant="body">
        IPv4 {floating.primary_ip && '(Primary)'}
      </Typography>
      <div style={{display:'flex', alignItems:'center', width:'100%', justifyContent:'space-between'}}>
        <NetworkText>
          {floating.ip}
        </NetworkText>
        {!floating.primary_ip && 
          <Button
            onClick={() => setActive(true)}
            variant='icon'
            active={false}
            isProps={true}
            size='small'
            // disabled
            customStyles={{width:'26px', height:'20px'}}
          >
            <StyledIconMore src={icons.More}/>
          </Button>
        }
        <PropsContainer ref={optionsRef} active={active}>
          <DropOptions
            options={options}
            handleClose={() => setActive(false)}
            itemToEdit={floating}
          />
        </PropsContainer>
      </div>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        instanceId={bareMetalId}
        loading={loading}
      />
    </InfoContainer>
  )
}