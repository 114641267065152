import styled from "styled-components";
import { BodyMedium } from "../../components/typography/styled";

type StatusProp = {
  isSeen: boolean;
}
export const Container = styled.div`
  padding: 10px 30px 10px 20px;

`
export const Eye = styled.div`
  display: flex;
  /* position: absolute; */
  top:33.5px;
  right:10px;
`

export const StatusText = styled(BodyMedium)<StatusProp>`
  color:${({theme, isSeen}) => isSeen ? theme.success : '#E8B533'};

`