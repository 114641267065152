import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from '../../../../components';
import { BodySmall } from "../../../../components/typography/styled";
import { formatQuotasRam, formatStorageMB } from '../../../../utils/sizes';
import { ErrorText } from '../../../Instances/components/create-instance/configure/styles';
import { useAuth } from '../../../../context/user.context';
import { formatCurrency } from '../../../../utils/display-price';
export const GreySmall = styled(BodySmall)`
  color:${({theme}) => theme.grey[600]};
`
type RowProps = {
  selected?:boolean;
  disabled?:boolean;
}
// Styled components
const Table = styled.table`
  border-collapse: separate; /* allow spacing between cell borders */
  table-layout: fixed;
  border-spacing: 0 4px;
  /* display: flex; */
  box-sizing: border-box;
  flex-direction: column;
  gap:10px;
`;

const TableRow = styled.tr<RowProps>`
  border:${({theme, selected}) => `1.5px solid ${selected ? theme.primary[600] : theme.palette[20]}`};
  opacity: ${({disabled}) => disabled ? '0.5' : ''};
  background-color:${({theme, selected}) => selected ? theme.primary[25] : ""};
  &:hover{
    cursor: ${({disabled}) => disabled ? '' : 'pointer'} ;
  }
`;

const TableCell = styled.td<RowProps>`
  padding: 11px;
  /* text-align: left; */
  /* border-bottom: 1px solid #ddd; */
  border-color:${({theme, selected}) => selected ? theme.primary[700] : theme.palette[20]};
  border-width: 1px 0;
  border-style: solid;
  &:first-child {
    border-left-width: 1px;
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border-right-width: 1px;
    border-radius: 0 5px 5px 0;
  }

`;

const TableHead = styled.thead`
  height: 34px;
  /* border-radius: 4px; */
  background-color: ${({theme}) => theme.palette[200]};
`

const TableHeadColumn = styled.th`
  padding: 0px 10px;
  text-align: left;
  background-color: ${({theme}) => theme.palette[200]};
  color:${({theme}) => theme.palette[900]};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:first-child {
    border-left-width: 1px;
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border-right-width: 1px;
    border-radius: 0 5px 5px 0;
  }
`
const Tr = styled.tr`
  margin-bottom:0px;
  background-color: ${({theme}) => theme.palette[200]};
`
// Component
const ServerTable:FC<any> = ({ servers, selectedValue, onChange, fieldName, errors }) => {
  const [t] = useTranslation()
  const {user} = useAuth()
  const handleSelect = (server:any) => {
    onChange(server.value);
  };
  const columns:any = [
    {title:t("NAME")},
    {title:t("PRICE")},
    {title:'vCPU'},
    {title:'RAM'},
    {title:t("STORAGE")},
    {title:t("DISK_COUNT")},
  ]
  console.log('servers',servers);

  const fieldError = errors?.[fieldName];
  return (
    <div style={{display:"flex", flexDirection:'column', gap:'6px'}}>
      {servers?.length > 0 ?
        <Table>
          <TableHead>
            <Tr>
              {columns?.map((item:any) => (
                <TableHeadColumn>
                  {item.title}
                </TableHeadColumn>
              ))}
            </Tr>
          </TableHead>
          <tbody>
            {servers.map((server:any, index:any) => (
              <TableRow disabled={server.disabled} selected={selectedValue === server.value} onClick={() => server.disabled ? null : handleSelect(server)} key={index}>
                <TableCell selected={selectedValue === server.value}>
                  <Typography variant='small'>
                    {server.name}
                  </Typography>
                </TableCell>
                <TableCell selected={selectedValue === server.value}>
                  <div style={{display:"flex", gap:'2px'}}>
                    <Typography variant='small'>{formatCurrency(server.price, user.user.clients?.[0]?.currency)}</Typography>
                    <GreySmall style={{textTransform:'lowercase'}}>/{t("DAY")}</GreySmall>
                  </div>
                  <div style={{display:"flex", gap:'2px'}}>
                    <Typography variant='small'>{formatCurrency(server.price*30, user.user.clients?.[0]?.currency)}</Typography>
                    <GreySmall>{t("MONTH")}</GreySmall>
                  </div>
                </TableCell>
                <TableCell selected={selectedValue === server.value}>
                  <div style={{display:"flex", flexDirection:'column'}}>
                    <Typography variant='small'>{server.cpu.count}X {server.cpu.model}</Typography>
                    <GreySmall>{server.cpu.value}{server.cpu.value_type}, {server.cpu.cores} cores</GreySmall>
                  </div>
                </TableCell>
                <TableCell selected={selectedValue === server.value}>
                  <Typography variant='small'>
                    {formatQuotasRam(server.ram.value)}
                  </Typography>
                </TableCell>
                <TableCell selected={selectedValue === server.value}>
                  {server?.diskType.map((disk:any) => (
                    <div style={{display:"flex", gap:'6px'}}>
                      <Typography variant='small'>{formatStorageMB(disk.size, 1)}</Typography>
                      <Typography variant='small' >{disk?.type}</Typography>
                    </div>
                  ))}
{/* 
                  <Typography variant='small'>
                    {formatStorageMB(server.storage.value, 1)} {server.storage.model}
                  </Typography> */}
                </TableCell>
                <TableCell selected={selectedValue === server.value}>
                  <Typography variant='small'>
                    {server.storage.count}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      :
        <ErrorText>
          {t("NO_FREE_SERVERS")}
        </ErrorText>
      }
      <ErrorText> 
        {servers?.length > 0 && fieldError}
      </ErrorText>
    </div>
  );
};

export default ServerTable;
