import styled from "styled-components";
import { BodyDefault } from "../../typography/styled";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 20px;
  box-sizing: border-box;
  width: 400px;
  /* height: 350px; */
  left: 540px;
  top: 305px;
  background: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  padding: 10px 10px 20px;
  width: 360px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette[10]};
  border-radius: 8px;
`

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`
export const BodyModal = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  padding:0px 10px;
`

export const Icon = styled.img`
  &:hover{
    cursor: pointer;
  }
`

export const WarningBlock = styled.div`
  display: flex;
  box-sizing: border-box;
  padding:6px 8px;
  gap:6px;
  background-color: #FFF5D2;
  width: 320px;
  height: 72px;
  border-radius: 4px;
  margin-top:10px;
`

export const InstanceWrapper = styled.div`
  background-color: ${({theme}) =>  theme.palette[200]};
  display:flex;
  padding: 5px 8px;
  border-radius: 4px;
`

export const Title = styled(BodyDefault)`
  color:${({theme}) => theme.isDark ? theme.palette[800] : theme.palette[900]};
  white-space: nowrap;
`

export const StyledIconColored = styled.img`
  margin-right:16px;
  width: 20px;
  height: 20px;
`
export const PasswordWrapper = styled.div`
  position: relative;
`