import React, { FC, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  IconBackground,
  HeadWrapper,
  BodyModal,
  GreyText,
  Icon,
  InstanceWrapper,
  Title,
  StyledIconColored,
} from './styles'
import { icons } from "../../../icons";
import DeleteIcon from './delete.svg'
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";

import InstanceImage from './instances-default.svg'
import SSHKeyImage from '../../side-bar/icons/ssh-keys-default.svg'
import NetworkImage from '../../side-bar/icons/network-default.svg'
import RouterIcon from '../../side-bar/icons/router-default.svg'
import FloatingIpIcon from '../../side-bar/icons/floating-ip-default.svg'
import ImageIcon from '../../side-bar/icons/images-default.svg'
import VolumeIcon from '../../side-bar/icons/volumes-default.svg'
import SecurityGroupIcon from '../../side-bar/icons/security-group-default.svg'
import LoadBalancerIcon from '../../side-bar/icons/load-balancer-default.svg'
import KubernetesIcon from '../../side-bar/icons/kubernetes-default.svg'
import { HeadLine3 } from "../../typography/styled";



type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(item?:any) => void;
  alertTitle?:string;
  alertSubTitle?:any;
  loading?:boolean;
  loadingComplete?:boolean;
}  



const DeleteModal:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    alertSubTitle,
    loading,
    loadingComplete
  } = props
  const [t] = useTranslation()
  const handleDeleteImage = (page?:string) => {
    switch(page){
      case t("SSH_KEY"):
        return SSHKeyImage
      case t("SSH_KEYS"):
        return SSHKeyImage
      case t("NETWORK"):
        return NetworkImage
      case t("NETWORKS"):
        return NetworkImage
      case t("INSTANCE"):
        return InstanceImage
      case t("INSTANCES"):
        return InstanceImage
      case t("ROUTER"):
        return RouterIcon
      case t("ROUTERS"):
        return RouterIcon
      case t("FLOATING_IPS"):
        return FloatingIpIcon
      case t("FLOATING_IP"):
        return FloatingIpIcon
      case t("IMAGES"):
        return ImageIcon
      case t("IMAGE"):
        return ImageIcon
      case t("VOLUMES"):
        return VolumeIcon
      case t("VOLUME"):
        return VolumeIcon
      case t("CLUSTER"):
        return KubernetesIcon
      case t("CLUSTERS"):
        return KubernetesIcon
      case t("SECURITY_GROUPS"):
        return SecurityGroupIcon
      case t("SECURITY_GROUPS"):
        return SecurityGroupIcon
      case t("LOAD_BALANCER"):
        return LoadBalancerIcon
      case t("LOAD_BALANCERS"):
        return LoadBalancerIcon
      default: 
        return null
    }
  }
  return(
    <Container>
      <HeadWrapper>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <IconBackground>
          <img style={{width:'50px', height:'50px'}} src={DeleteIcon}/>
        </IconBackground>
        <HeadLine3 style={{textAlign:'center'}}>
          {t("DELETE")}&nbsp;{alertTitle}
        </HeadLine3>
        <GreyText>
          {t("ARE_YOU_SURE_WANT_TO_DELETE_THIS")}&nbsp;{alertTitle}?&nbsp;{t("THIS_ACTION_CANNOT_BE_UNDONE")}
        </GreyText>
          {alertSubTitle?.map((subj:any) => (
            <InstanceWrapper>
                {handleDeleteImage(alertTitle) && 
                //@ts-ignore
                  <StyledIconColored src={handleDeleteImage(alertTitle)}/>
                }
              <Title >
                {subj?.name || subj?.id || subj?.ip_address}
              </Title>
            </InstanceWrapper>
          ))}
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '157px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            isDelete
            customStyles={{ width: '157px', justifyContent:'center' }}
            onClick={() => closeModal()}
            loading={loading}
            loadingComplete={loadingComplete}
            title={t("DELETE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default DeleteModal