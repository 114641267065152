import React, {FC, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { authService, imageService, instanceService } from '../../../../../api'
import { Button, ModalVariables, Table, TopProgress } from '../../../../../components'
import { InfoContainer, InfoTitle } from '../../../../../components/info-block/styles'
import { BodyDefault } from '../../../../../components/typography/styled'
import { useUi } from '../../../../../context/ui.context'
import { icons } from '../../../../../icons'
import {
  Container,
  RulesWrapper,
  HeadContainer,
  RuleContainer
} from './styles'
import { handleStatusName } from '../../../../../utils/handle-status-name'
import {
  StateWrapper,
  GreenDot,
  Greentext,
} from '../../../styles'
import { formatFileSize } from '../../../../../utils/sizes'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import rootConfig from '../../../../../config'
import { useInstance } from '../../../../../context/instanceContext'
type SecurityProps = {
  instance:any,
}

const SnapshotsInstancePage:FC<SecurityProps> = ({instance}) => {
  const [t] = useTranslation()
  const {instanceId} = useParams()
  const navigate = useNavigate()
  const {setDataToLs} = useLocalStorage()
  const [snapshots, setSnapshots] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [selectedSnapshot, setSelectedSnapshot] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const {viewAlert} = useUi()
  const {setSnapshotForRebuild} = useInstance()
  const getSnapshots = () => {
    if(instanceId){
      instanceService.getInstanceSnapshots(instanceId)
      .then((res) => {
        setSnapshots(res.data.objects.map((snapshot:any) => {
          return {
            ...snapshot,
            name: snapshot.display_name,
            navigate:`/images/${snapshot.id}`,
            //@ts-ignore
            size: snapshot.size === undefined || snapshot.size === null  ? '-' : formatFileSize(snapshot.size),
            status: 
            (<StateWrapper>
              <GreenDot active={instance.display_status}/>
              <Greentext active={instance.display_status}>
                {handleStatusName(instance.display_status,t)}
              </Greentext>
            </StateWrapper>),
          }
        }))
        setPageLoading(false)
      })
      .catch((err) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: err?.response?.data?.detail
          })
        }
      })
    }
  }
  useEffect(() => {
    getSnapshots()
    const interval = setInterval(() => {
      getSnapshots()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }

  const closeModal = async (values:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'create-instance-snapshot':
          res = await instanceService.createInstanceSnapshot(instance.id, values)
          await setLoadingComplete(true)
          await getSnapshots()
          await setTimeout(() => {
            setLoadingComplete(false)
            setLoading(false)
            setViewModal(false)
            setActiveAnimation(false)
          }, 1000)
          break;
        case 'delete':
          res = await imageService.deleteImage(selectedSnapshot.id);
          await getSnapshots()
          await setViewModal(false)
          await setActiveAnimation(false)
          break; 
        case 'rebuild-instance':
          res = await instanceService.rebuildInstance(instance.id, values);
          await setLoadingComplete(true)
          await setTimeout(() => {
            setLoadingComplete(false)
            setLoading(false)
            setViewModal(false)
            setActiveAnimation(false)
          }, 1000)
          if (res.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data.detail,
            });
          }
          break;
      }

    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setLoading(false);
    }
    // setViewModal(false)
    // setActiveAnimation(false)
  }

  const Columns = [
    {
      title:t('NAME'),
      key:'name'
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    {
      title:t("SIZE"),
      key:'size'
    },
    // {
    //   title:t("HAS_SNAPSHOT"),
    //   key:'has_snapshots'
    // },
    {
      title:'',
      key:'props'
    }
  ]
  const items = [
    {
      title:t("LAUNCH"),
      id:'2',
      icon:icons.Launch,
      onClick: (image:any) => {
        setDataToLs({key:'instance.configure', data:{os:image?.id}})
        navigate('/instances/create?step=configure')
      },
      divider:false
    },
    {
      title:t("EDIT"),
      id:'3',
      icon:icons.Edit,
      onClick: (image:any) => {
        navigate(`/images/${image.id}/edit`)
      },
      divider:false
    },
    {
      title:t("REBUILD_CURRENT_INSTANCE"),
      id:'6',
      icon:icons.Rebuild,
      onClick:(image:any) => {
        setAlertTitle(instance.name)
        openModal('rebuild-instance')
        setSnapshotForRebuild(image)
      },
      divider:false
    },
    {
      title:t("DOWNLOAD_IMAGE"),
      id:'5',
      icon:icons.Download,
      onClick: async (image:any) => {
        const res = await authService.generateOtpToken()
        const url = `${rootConfig.apiUrl}api/openstack/images/${image.id}/download?fleio-token=${res.data.otp_token}`
        // window.open(url);
        window.location.href = url
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(image:any) => {
        openModal('delete')
        setAlertTitle(t("IMAGE"))
        setAlertSubTitle([image])
        setSelectedSnapshot(image)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <div style={{display:'flex', alignItems:'flex-start'}}>
        <Button
          title={t("CREATE_SNAPSHOT")}
          size='display'
          variant='primary'
          onClick={() => {
            setAlertTitle(instance.name)
            openModal('create-instance-snapshot')
          }}
        />
      </div>
        {snapshots?.length > 0 ?
          <Table
          settings={items}
          hideCheckbox
          hideOther
          columns={Columns}
          rows={snapshots}
          />
        : 
          <BodyDefault>
            {t("THERE_ARE_NO_SNAPSHOT_YET")}
          </BodyDefault>
        }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        instanceId={instanceId}
        loadingComplete={loadingComplete}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={instance}
      />
    </Container>
  )
}

export default SnapshotsInstancePage