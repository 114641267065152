import ApiClient from './apiClient'
import { QueriesApi } from './apiTypes'


const volumeService = {
  getVolumeList:(queries:QueriesApi) => ApiClient.get('openstack/volumes', {
    params:{
      ...queries
    }
  }),
  getVolumeSnapshotsList:(queries:QueriesApi, volumeId:string) => ApiClient.get(`openstack/volumesnapshots`, {
    params: {
      ...queries,
      volume__id:volumeId
    }
  }),
  getVolumeBackupsList:(queries:QueriesApi, volumeId:string) => ApiClient.get(`openstack/volumebackups`, {
    params: {
      ...queries,
      volume__id:volumeId
    }
  }),
  getEstimateCoast:(queries:any) => ApiClient.get('openstack/volumes/get_price', {
    params: {
      ...queries,
    }
  }),
  createVolume:(data:any) => ApiClient.post('openstack/volumes', data),
  getCreateOptions:() => ApiClient.get(`openstack/volumes/create_options`),
  getAttachmentsToInstance:(instanceID:string) => ApiClient.get(`openstack/volumes/get_attachments?instance_uuid=${instanceID}`),
  deleteVolume:(volumeId:string) => ApiClient.delete(`openstack/volumes/${volumeId}`),
  getVolume:(volumeId:string) => ApiClient.get(`openstack/volumes/${volumeId}`),
  renameVolume:(volumeId:string, data:Object) => ApiClient.post(`openstack/volumes/${volumeId}/rename`,data), //name
  renameVolumeBackup:(backupId:string, data:Object) => ApiClient.put(`openstack/volumebackups/${backupId}`,data), //name
  extendVolume:(volumeId:string, data:Object) => ApiClient.post(`openstack/volumes/${volumeId}/extend`, data), // size
  botableVolume:(volumeId:string, data:Object) => ApiClient.post(`openstack/volumes/${volumeId}/change_bootable_status`, data), //new_status
  revertToSnapshotVolume:(volumeId:string, data:Object) => ApiClient.post(`openstack/volumes/${volumeId}/revert_to_snapshot`, data), //snapshot_id
  uploadToImage:(volumeId:string, data:Object) => ApiClient.post(`openstack/volumes/${volumeId}/upload_to_image`, data), // disk_format, image_name
  getEstimateSnapshot:(queries:Object) => ApiClient.get('openstack/volumesnapshots/get_price', {
    params:{
      ...queries
    }
  }),
  getEstimateBackup:(queries:Object) => ApiClient.get('openstack/volumebackups/get_price', {
    params:{
      ...queries
    }
  }),
  getSnapshotToRevert:(volumeId:string) => ApiClient.get(`openstack/volumesnapshots/get_snapshot_for_revert?volume_id=${volumeId}`),
  createSnapshot:(data:Object) => ApiClient.post(`openstack/volumesnapshots`, data),
  createBackup:(data:Object) => ApiClient.post(`openstack/volumebackups`, data),
  deleteBackup:(backupId:string) => ApiClient.delete(`openstack/volumebackups/${backupId}`),
  deleteSnapshot:(snapshotId:string) => ApiClient.delete(`openstack/volumesnapshots/${snapshotId}`),
  updateSnapshot:(snapshotId:string, data:Object) => ApiClient.put(`openstack/volumesnapshots/${snapshotId}`, data),
  updateBackup:(backupId:string, data:Object) => ApiClient.put(`openstack/volumebackups/${backupId}`, data),
  resetStateSnapshot:(snapshotId:string, data:Object) => ApiClient.post(`openstack/volumesnapshots/${snapshotId}/reset_state`, data),
  getVolumesAvailableForRestoration:(backupId:string) => ApiClient.get(`openstack/volumebackups/${backupId}/get_volumes_available_for_restoration`),
  restoreVolumeBackup:(backupId:string, data:Object) => ApiClient.post(`openstack/volumebackups/${backupId}/restore`, data),
}

export default volumeService