import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import Input from "../../input";
import { floatingIpService, routerService } from "../../../api";
import { useParams } from "react-router-dom";
import { useUi } from "../../../context/ui.context";
import TopProgress from "../../TopBarProgress";
import Counter from "../../counter";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data?:any) => void;
  loading?:boolean;
  dataToEdit?:any;
}


const SetPtr:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    // loading,
    dataToEdit
  } = props
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    ptrdname:'',
    description:'',
    ttl:'1'
  })
  const [_loading, _setLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      ptrdname:values.ptrdname,
      floating_ip_id:dataToEdit.id,
      description:values.description,
      ttl:values.ttl,
    }
    floatingIpService.setPtr(data)
    .then((res) => {
      if(res?.data.detail){
        viewAlert({
          severity:'info',
          message:res.data.detail,
        })
      }
      setLoading(false)
      closeModal()
    })
    .catch((e) => {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      } else if(e?.response?.data){
        setErrors(e.response.data)
      }
      setLoading(false)
    })
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      description: yup.string(),
      ptrdname: yup.string().required(`${t("DOMAIN_NAME_IS_REQUIRED")}`),
      ttl:yup.number().required(`${t("REQUIRED_FIELD")}`).positive(`${t("TTL_POSITIVE_VALIDATION")}`)
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const getPrevData = async () => {
    // setLoading(false)
    floatingIpService.getFloatingPtr(dataToEdit.id)
    .then((res) => {
      _setLoading(false)
      setInitialValues({
        ptrdname:res.data.ptrdname || '',
        floating_ip_id:dataToEdit.id,
        description:res.data.description || '',
        ttl:res.data.ttl || '1',
      })
    })
    .catch((err) => {

    })
  }
  useEffect(() => {
    getPrevData()
  },[])
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(_loading) return <TopProgress loading={true}/>
  return(
    <Container>
      <HeadWrapper>
      <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {/* {t("ADD_AN_INTERFACE")} */}
          {t("SET_PTR_FOR")}&nbsp;{dataToEdit?.floating_ip_address}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Input
          title={t('DOMAIN_NAME')}
          fieldName='ptrdname'
          required
          placeholder={''}
          customStyles={{width:'360px'}}
          // onBlur={handleBlur}
          {...formikProps}
        />
        <Input
          isTextArea
          title={t('DESCRIPTION')}
          fieldName='description'
          placeholder={''}
          customStyles={{minWidth:'360px', maxWidth:'360px', height:'200px'}}
          // onBlur={handleBlur}
          {...formikProps}
        />
        <Counter
          required
          hideItems
          title={t("TTL")}
          maxCount={255}
          value={values['ttl']}
          fieldName='ttl'
          {...formikProps}

          onChange={(v:any) => {
            handleChange('ttl')(v.toString())}}
          />
        <div style={{display:"flex", gap:'6px', marginTop:'16px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("SAVE")}
            loading={loading}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default SetPtr