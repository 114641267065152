import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authService, notificationService, quotasService, setClientToken } from '../api';
import { useUi } from './ui.context';
interface AuthContextInterface {
  user:any,
  isAdmin:boolean,
  loadingUser:boolean,
  UpdateFunc:() => void,
  setUser:(clear:null) => void,
  notifications:any,
  setAvailableNotifications:(arr:any[]) => void,
  setAvailableRoles:(arr:any[]) => void,
  availableNotifications:any[],
  availableRoles:any[],
  updateUser:() => void,
  setClientToInvite:(client:any) => void,
  clientForInvite:any,
  quotas:any,
  setQuotas:(quotas:any) => void,
  quotasLoading?:boolean,
  appMenu:any,
  setAppMenu:(menu:any) => void,
  updateData:() => void,
  networkErr?:boolean,
  setNetworkErr:(status:boolean) => void,
  techWork?:boolean,
  setTechWork:(status:boolean) => void,
  }
type UserType = {
  _id:string,
  email:string,
  image:string,
  name:string,
}
const AuthContext = React.createContext<Partial<AuthContextInterface>>({});

const AuthProvider = React.memo((props:any) => {
  const [t] = useTranslation()
  const [user, setUser] = useState<any | null>(null)
  const [loadingUser, setLoadingUser] = useState<boolean>(false)
  const [availableNotifications, setAvailableNotifications] = useState<any[]>([])
  const [availableRoles, setAvailableRoles] = useState<any[]>([])
  const [clientForInvite, setClientToInvite] = useState<any>()
  const [quotasLoading, setQuotasLoading] = useState<boolean>(true)
  const [quotas, setQuotas] = useState<any>([])
  const {viewAlert} = useUi()
  const [notifications, setNotifications] = useState<any>()
  const [appMenu, setAppMenu] = useState<any>()
  const [networkErr, setNetworkErr] = useState<boolean>(false)
  const [techWork, setTechWork] = useState<boolean>(false)

  const UpdateFunc = async () => {
    setLoadingUser(true)
    //@ts-ignore
    // await setClientToken(localStorage.getItem('authToken'))

  }
  const updateData = () => {
    // setQuotasLoading(true)
    quotasService.get()
    .then((res) => {
      setQuotas(res?.data)
    })
    .catch((e) => {
      console.log('err quotas get', e);
    })
    .finally(() => {
      setQuotasLoading(false)
    })
    notificationService.getNotifications({
      page:1,
      pageSize: 10
    })
    .then((res:any) => {
      setNotifications(res?.data)
    })
    .catch((e:any) => {
      console.log('enotification', e);
      if(e?.response?.data?.detail && e?.response?.data?.detail !== 'Invalid token.'){
        viewAlert({
          severity:'error',
          message:e?.response?.data?.detail
        })
      }
      if(e.response.status === 401){
        viewAlert({
          severity:'error',
          message:`${t("TOKEN_EXPIRED")}`
        })
        setTimeout(() => {
          // window.location.reload()
        }, 1000)
      }
    })
  }
  useEffect(() => {
    if(user && !user.requires_email_verification && user.user?.clients.length > 0){
      notificationService.getNotifications(
        {
          page:1,
          pageSize: 10
        }
      )
      .then((res:any) => {
        setNotifications(res.data)
        quotasService.get()
        .then((res) => {
          setQuotas(res.data)
        })
        .catch((e) => {
          console.log('err quotas get', e);
        })
        .finally(() => {
          setQuotasLoading(false)
        })
      })
      .catch((e:any) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
      })
    }
    
    const update = setInterval(() => {
      if(user && user.user?.clients.length > 0 && !networkErr){
        updateData()
      } else {
        clearInterval(update)
      }
    },10000)
    return () => {
      clearInterval(update)
    }
  },[user, networkErr])

  const updateUser = () => {

  }
  useEffect(() => {
    UpdateFunc()
  },[])
  return (
    <AuthContext.Provider
      value={{
        user,
        loadingUser,
        UpdateFunc,
        setUser,
        notifications,
        setAvailableNotifications,
        setAvailableRoles,
        availableNotifications,
        availableRoles,
        updateUser,
        clientForInvite,
        setClientToInvite,
        quotas,
        setQuotas,
        quotasLoading,
        appMenu,
        setAppMenu,
        updateData,
        networkErr,
        setNetworkErr,
        techWork,
        setTechWork,
      }}
      {...props}
    />
  );
})

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context as AuthContextInterface;
};

export { AuthProvider, useAuth };
