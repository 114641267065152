import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, ModalVariables, Tabs, TopProgress } from "../../components";
import MenuButton from "../../components/button/menu-button";
import DropOptions from "../../components/header/components/drop-options";
import { PropsContainer } from "../../components/table/styles";
import { icons } from "../../icons";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  InstanceHeadInfo,
} from './styles'
import {
  Pools,
  Policies,
  Overview,
} from './components'
import { BodyDefault } from "../../components/typography/styled";
import { loadBalancerService } from "../../api";
import { useUi } from "../../context/ui.context";
import { handleStatusName } from "../../utils/handle-status-name";
import { GreenDot, Greentext, StateWrapper } from "../Instances/styles";
// import CreateLoadBalancer from "../create-page";

const LbListeners:FC = () => {
  const navigate = useNavigate()
  const {lbId, listenerId} = useParams()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const [listener, setListener] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [subnets, setSubnets] = useState<any>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [modalType, setModalType] = useState<any>('')
  const {viewAlert} = useUi()
  const updateLb = () => {
    if(listenerId){
      loadBalancerService.getListenerById(listenerId)
      .then((res) => {
        setListener(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
        // navigate('/not-found')
      })
    }
  }
  useEffect(() => {
    updateLb()
    const interval = setInterval(() => {
      updateLb()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },
    {
      title:t("POOLS"),
      slug:'pools',
      id:'3'
    },
    {
      title:t("L7_POLICIES"),
      slug:'policies',
      id:'2'
    },
  ])
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(modalType === 'delete'){
      if(!lbId) return;
      setLoading(true);
      loadBalancerService.deleteListener(lbId, listener.id)
      .then((res) => {
        updateLb()
        setViewModal(false)
        setActiveAnimation(false)
        navigate(-1)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }

  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: () => navigate(`/load-balancer-listener/${lbId}/${listener.id}/edit`),
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        setAlertTitle(t("LISTENER"))
        openModal('delete')
        setAlertSubTitle([listener])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow onClick={() => navigate(`/load-balancers/${lbId}/listeners`)} title={t("GO_BACK")}/>
      <HeadWrapper>
        <InstanceName>
          {listener?.name || listener.id} 
        </InstanceName>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              active={options}
              title={`${t("MENU")}`}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <InstanceHeadInfo>
        <StateWrapper>
        <GreenDot active={listener.operating_status}/>
          <Greentext active={listener.operating_status}>
          {handleStatusName(listener.operating_status, t)}
          </Greentext>
        </StateWrapper>
      </InstanceHeadInfo>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview lb={listener}/>}/>
        <Route path='/policies' element={<Policies/>}/>
        {/* <Route path='/listeners/create' element={<CreateLoadBalancer/>}/> */}
        <Route path='/pools' element={<Pools/>}/>
        {/* <Route path='/pools/create' element={<CreateLoadBalancer/>}/> */}
      </Routes>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={instance}
        instanceId={listener.id}
        // instance={instance}
        loading={loading}
      />
    </Container>
  )
}

export default LbListeners