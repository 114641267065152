import React, { FC, useEffect, useRef, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider,
  WarningBlock
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { BodySmall, HeadLine3 } from "../../typography/styled";
import { floatingIpService, instanceService } from "../../../api";
import TopProgress from "../../TopBarProgress";
import { useUi } from "../../../context/ui.context";
import { formatCurrency, formatCurrencyString } from "../../../utils/display-price";
import { EstimateSum, PerHour } from "../../../pages/Floating-IPs/create-page/styles";
import { useAuth } from "../../../context/user.context";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any) => void;
  instanceId?:string;
  alertTitle?:string;
  loading?:boolean;
  viewModal?:boolean

}  


const AssignIp:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instanceId,
    alertTitle,
    loading,
    viewModal
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    floating_ip:'',
    fixed_ip:'',
  })
  const {
    viewAlert
  } = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loadingFloating, setLoadingFloating] = useState<boolean>(false)
  const [ips, setIps] = useState<any[]>([])
  const [ports, setPorts] = useState<any[]>([])
  const [network, setNetwork] = useState<any[]>([])
  const [region, setRegion] = useState<any[]>([])
  const [priceMonth, setPriceMonth] = useState<any>()
  const {user} = useAuth()
  const createdFloating:any = useRef()
  const _getOptions = () => {
    if(instanceId && viewModal){
      floatingIpService.getCreateOptions()
      .then(resOptions => {
        setNetwork(resOptions.data.networks[0]?.id)
        setRegion(resOptions.data.regions[0]?.id)

        instanceService.getAssociateIpOptions(instanceId)
        .then((res:any) => {
          setIps(res.data.free_ips.map((ip:any) => {
            return {
              ...ip,
              id:ip.floating_ip_address,
              value:ip.floating_ip_address,
              label:ip.floating_ip_address
            }
          }))
          if(createdFloating.current){
            const founded = res.data.free_ips.find((ip:any) => ip.id === createdFloating.current)
            handleChange('floating_ip')(founded.floating_ip_address)
          }
          setPorts(res.data.ports.map((port:any) => {
            return {
              // ...port,
              value:port.fixed_ips?.[0]?.ip_address,
              label:`${port.id.slice(0, 4)}...${port.id.slice(-4)} - ${port.fixed_ips?.[0]?.ip_address}`
            }
          }))
          let queries:any = {
            region: resOptions.data.regions[0]?.id,
          }
          floatingIpService.getEstimate(queries)
          .then((resEstimate) => {
            setPriceMonth(formatCurrencyString(resEstimate.data.estimated_cost_display.split('-')[1]).month)
          })
        })
        .catch((e:any) => {
          if(e?.response?.data?.detail) {
            viewAlert({
              severity:'error',
              message:e.response.data.detail,
            })
            if(e?.response?.data?.detail === 'No free ports available'){
              closeModalCancel()
            }
          }
        })
        .finally(() => {
          setPageLoading(false)
          setLoadingFloating(false)
        })
      })
      .catch(err => console.log('error', err));
    }
    if(!viewModal){
      setPageLoading(false)
    }
  }
  useEffect(() => {
    _getOptions()
  },[])
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      floating_ip: yup.string().required(`${t("REQUIRED_FIELD")}`),
      fixed_ip: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const handleCreateFloatingIp = async () => {
    try{
      setLoadingFloating(true)
      const dataFloating = await {
        description:'',
        region:region,
        floating_network:network,
      }
      const res = await floatingIpService.createFloatingIP(dataFloating)
      createdFloating.current = res.data.id
      viewAlert({
        severity:'info',
        message:`${t("FLOATIN_IP_SUCCESSFULLY_CREATED")}`,
      })
      _getOptions()
    } catch (err:any) {
      if(err?.response?.data?.detail) {
        viewAlert({
          severity:'error',
          message:err.response.data.detail,
        })
      }
      setLoadingFloating(false)
    }
  }
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={loadingFloating}/>
      <HeadWrapper>
      <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {`${t("ASSIGN_FLOATING_IP_TO")} «${alertTitle}»`}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <WarningBlock>
        <Icon src={icons.Warning}/>
        <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
          <BodySmall style={{color:"#000", width:'100%'}}>
            {t("CREATE_FLOATING_WARNING")}&nbsp;
            <img 
              style={{
                boxSizing:'border-box',
                width:'19px',
                height:'19px',
                background:"#FFF",
                padding:'3px',
                borderRadius:"4px",
              }}
              src={icons.PlusDefault}
            />.
          </BodySmall>
          {priceMonth && 
            <BodySmall style={{color:"#000"}}>
              {t("CREATE_FLOATING_ESTIMATE")} 
              <div style={{display:'flex', alignItems:'flex-end'}}>
                  <EstimateSum style={{color:"#000"}}>
                    {`${formatCurrency(priceMonth, user.user.clients?.[0]?.currency || 'UAH')}`}
                  </EstimateSum>
                  <PerHour>{t("MONTH")}</PerHour>
                </div>
            </BodySmall>
          }
        </div>
      </WarningBlock>
      <BodyModal>
        <div style={{display:'flex', gap:"14px"}}>
          <Select
            required
            toolTip={true}
            test_id="floating_ip-select"
            data={ips}
            fieldName='floating_ip'
            selectedValue={values['floating_ip']}
            onChange={(e) => handleChange('floating_ip')(e)}
            title={t("FLOATING_IPS")}
            customStyles={{width:'100%'}}
            {...formikProps}
          />
          <Button
            variant="icon"
            size='display'
            customStyles={{minWidth:'40px', marginTop:'24px'}}
            onClick={handleCreateFloatingIp}
            test_id="create_floating_ip"
          >
            <img src={icons.PlusDefault}/>
          </Button>
        </div>
        <Divider/>
        <Select
          required
          test_id="port-select"
          toolTip={true}
          data={ports}
          fieldName='fixed_ip'
          selectedValue={values['fixed_ip']}
          onChange={(e) => handleChange('fixed_ip')(e)}
          title={t("PORTS")}
          customStyles={{marginBottom:'20px', width:'360px'}}
          {...formikProps}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            loading={loading}
            title={t("ASSOCIATE_IP")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default AssignIp