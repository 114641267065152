import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  WarningBlock,
  WarningText,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import { HeadLine3 } from "../../typography/styled";
import { useAuth } from "../../../context/user.context";
import MultiInput from "../../multi-input";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:() => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
  setScroll?:any;
}  

const ClusterCreate:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    loading,
    setScroll
  } = props
  const [t] = useTranslation()
  const submit = () => {
    setScroll()
    closeModal()
  }
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("IMPORTANT_INFO")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <WarningBlock>
          <img src={icons.Warning}/>
          <div style={{display:'flex', flexDirection:'column', gap:"20px"}}>
            <WarningText>{t("INFO_CREATE_CLUSTER1")}</WarningText>
            <WarningText>{t("INFO_CREATE_CLUSTER2")}</WarningText>
            <WarningText>{t("INFO_CREATE_CLUSTER3")}</WarningText>
            <WarningText>{t("INFO_CREATE_CLUSTER4")}</WarningText>
          </div>
        </WarningBlock>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={submit}
            title={t("CONFIRM")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default ClusterCreate