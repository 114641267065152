import React, { FC, useState, useEffect } from "react";
import {
  Container 
} from './styles'
import {
  calculateScales,
  createPercentageArrays,
  generateYScale,
  convertToPercentageCpu
} from '../metrics/metricFuncs'
import { instanceService } from "../../../../../api";
import MetricsChart from "../../../../../components/metrics";
import { useTranslation } from "react-i18next";
import { TopProgress } from "../../../../../components";
import { BodyDefault } from "../../../../../components/typography/styled";

type MetricsProps = {
  instance:any,
}


const Metrics:FC<MetricsProps> = ({instance}) => {
  const [t] = useTranslation()

  const [cpuMetrics, setCpuMetrics] = useState<any[]>([])
  const [networkMetrics, setNetworkMetrics] = useState<any[]>([])
  const [networkMetricsOut, setNetworkMetricsOut] = useState<any[]>([])
  const [scales, setScales] = useState<any[]>([])
  const [cpuScales, setCpuScales] = useState<any[]>([])
  const [preLoadMetrics, setPreLoadMetrics] = useState<boolean>(true)
  const [loadNetwork, setLoadNetwork] = useState<boolean>(false)
  const [loadCpu, setLoadCpu] = useState<boolean>(false)
  const _updateMetrics = (hours:any=1) => {
    instanceService.getMetrics(instance?.id, {
      hours:'1',
      metric: 'cpu_util'
    })
    .then((res) => {
      //@ts-ignore
      setCpuScales(generateYScale(res.data.measures))
      setCpuMetrics(convertToPercentageCpu(res?.data?.measures))
    })
    .catch((err) => {
      console.log('err',err);
      setPreLoadMetrics(false)
    })
    instanceService.getMetrics(instance?.id, {
      hours:hours,
      metric: 'interface_traffic'
    })
    .then((res:any) => {
      setScales(calculateScales(res?.data?.measures))
      setNetworkMetrics(createPercentageArrays(res?.data?.measures, 'bytes_in'))
      setNetworkMetricsOut(createPercentageArrays(res?.data?.measures, 'bytes_out'))
      setPreLoadMetrics(false)
    })
    .catch((err) => {
      console.log('err',err);
      setPreLoadMetrics(false)
    })
  }

  const updateCpu = (hours:any=1) => {
    setLoadCpu(true)
    instanceService.getMetrics(instance?.id, {
      hours:hours,
      metric: 'cpu_util'
    })
    .then((res:any) => {
      //@ts-ignore
      setCpuScales(generateYScale(res.data.measures))
      setCpuMetrics(convertToPercentageCpu(res?.data?.measures))
      setLoadCpu(false)
    })
    .catch((err) => {
      console.log('err',err);
      setLoadCpu(false)
    })
  }
  const updateNetworks = (hours:any=1, type?:any) => {
    setLoadNetwork(true)
    instanceService.getMetrics(instance?.id, {
      hours:hours,
      metric: 'interface_traffic'
    })
    .then((res) => {
      // setNetworkMetrics(res?.data?.measures)
      setScales(calculateScales(res?.data?.measures))
      setNetworkMetrics(createPercentageArrays(res?.data?.measures, type === '2' ? 'bytes_in' : 'packets_in'))
      setNetworkMetricsOut(createPercentageArrays(res?.data?.measures, type === '2' ? 'bytes_out' : 'packets_out'))
      setLoadNetwork(false)
    })
    .catch((err) => {
      console.log('err',err?.response?.data);
      setLoadNetwork(false)
    })
  }
  useEffect(() => {
    _updateMetrics()
  },[])
  if(preLoadMetrics) return <TopProgress loading={preLoadMetrics}/>
  // if(networkMetrics?.length === 0 && cpuMetrics?.length === 0) return(
  //   <Container>
  //     <BodyDefault>
  //       {t("HAS_NO_METRICS_YET")}
  //     </BodyDefault>
  //   </Container>
  // )
  return(
    <Container>
      {/* {networkMetrics?.length > 0 && networkMetricsOut?.length > 0 && */}
        <MetricsChart
          updateData={updateNetworks}
          title={t("NETWORK_METRIC")}
          scales={scales}
          data={networkMetrics}
          dataSecond={networkMetricsOut}
          loading={loadNetwork}
        />
      {/* } */}
      {/* {cpuMetrics?.length > 0 && */}
        <MetricsChart
          updateData={updateCpu}
          title={t("CPU_USAGE")}
          isCpu
          data={cpuMetrics}
          scalesCpu={cpuScales}
          loading={loadCpu}
        />
      {/* } */}
    </Container>
  )
}

export default Metrics