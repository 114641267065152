import styled from 'styled-components'
import { BodyDefault, BodyMedium, BodySmall } from '../typography/styled'

type ActiveProp = {
  active?:boolean,
  isError?:boolean
}
type ErrorProp = {
  isError?:boolean
}
type DragProps = {
  customStyles?:any,
}

export const FileText = styled(BodyMedium)`
  /* color:${({theme}) => theme.grey[800]}; */
  margin-bottom: 4px;
`

export const DragWrapper = styled.div<DragProps>`
  display: flex;
  flex-direction:column;
  box-sizing: border-box;
  width:100%;
  ${({customStyles}) => customStyles}
`
export const BackGround = styled.div<ActiveProp>`
  background:${({theme, active}) => active ? theme.isDark ? theme.grey[400] :  theme.primary[25] : theme.palette[10]};
  width: 550px;
  display: flex;
  flex-direction: column;
  position: relative;
  
  /* z-index: 20; */
  align-items: center;
  justify-content: center;
  /* padding: 20px 10px; */
`
export const Container = styled.div<ActiveProp>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 550px;
  padding: 20px 10px;
  background:${({theme, active}) => {
    return active ? 
      `linear-gradient(to right, ${theme.isDark ? theme.palette[600] : theme.primary[300]} 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(${theme.isDark ? theme.palette[600] : theme.primary[300]} 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(to right, ${theme.isDark ? theme.palette[600] : theme.primary[300]} 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(${theme.isDark ? theme.palette[600] : theme.primary[300]} 50%, rgba(255, 255, 255, 0) 0%);`
    :

      `linear-gradient(to right, ${theme.isDark ? theme.palette[400] : theme.grey[350]} 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(${theme.isDark ? theme.palette[400] : theme.grey[350]} 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(to right, ${theme.isDark ? theme.palette[400] : theme.grey[350]} 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(${theme.isDark ? theme.palette[400] : theme.grey[350]} 50%, rgba(255, 255, 255, 0) 0%);`
  }};
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 10px 1px, 1px 10px;
  
  &:hover{
    cursor: pointer;
  }
`

export const Title = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
`

export const SubTitle = styled(BodySmall)`
  color:${({theme}) => theme.grey[600]};
`

export const TitleWrapper = styled.div`
  display: flex;
  margin-top: 18px;
`

export const ErrorContainer = styled.div<ErrorProp>`
  display: ${({isError}) => isError ? 'flex' : 'none'};
  width: 363px;
  /* height: 30px; */
  background: #FFE6E6;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  margin-top:18px;
`

export const ErrorText = styled(BodySmall)`
  color: #FF3D3D;
  margin-left:6px;
`

export const UploadedFileContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 10px;
  width: 550px;
  /* height: 67px; */
  background: ${({theme}) => theme.base.white};
  border: ${({theme}) => `1px solid ${theme.grey[350]}`};
  border-radius: 4px;
`
export const SubTitleWrapper = styled.div`
  display: flex;
  width:100%;
  justify-content: space-between;
`

export const FileNameWrapper = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  margin-left:10px;
`

export const UploadInfoWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction: row;
`

export const ProgressWrapper = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ProgressBar = styled.progress`
  width: 100%;
  height: 4px;
  border-radius: 6px;
  margin-top:6px;
  ::-webkit-progress-bar {
    background-color: #D9D9D9;
    border-radius: 6px;
  }
  ::-webkit-progress-value {
    background-color: ${({theme}) => theme.primary[600]};
    border-radius: 6px;
  }
`

export const ImageDeleteFile = styled.img`
  &:hover{
    cursor: pointer;
  }
`
export const Overlay = styled.label`
  top:0;
  left:0;
  position: absolute;
  width:100%;
  height:100%;
  &:hover{
    cursor: pointer;
  }
`

export const Zirochka = styled.span`
  color:#658dfe!important;
`