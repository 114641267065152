import React, { FC, useEffect, useState } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  RolesContainer,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import CheckBox from "../../checkbox";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/user.context";
import { clientService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import TopProgress from "../../TopBarProgress";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:() => void;
  obj?:any
}  


const EditNotifications:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    obj,
  } = props
  const {
    clientForInvite
  } = useAuth()
  const [t] = useTranslation()
  const {id} = useParams()
  const [general, setGeneral] = useState<boolean>(true)
  const [billing, setBilling] = useState<boolean>(true)
  const [technical, setTechnical] = useState<boolean>(true)
  const [cc_tickets, setCc_tickets] = useState<boolean>(true)
  const {viewAlert} = useUi()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const onSubmit = () => {
    setLoading(true)
    const data = {
      id: clientForInvite.id,
      settings: 
        {general:{name: "general", enabled: general, display_name: "General announcements"},
        billing_and_service:{name: "billing_and_service", enabled: billing, display_name: "Billing and service related notifications"},
        technical:{name: "technical", enabled: technical, display_name: "Technical announcements"},
        cc_to_all_ticket_emails:{name: "cc_to_all_ticket_emails", enabled: cc_tickets, display_name: "CC to all ticket emails"}}
      ,
      user_id: obj.id,
    }
    clientService.editUserNotifications(id, data)
    .then((res:any) => {
      setLoadingComplete(true)
      setTimeout(() => {
        setLoadingComplete(false)
        setLoading(false)
        closeModal()
      },800)
    })
    .catch((e:any) => {
      setLoading(false)
      setLoadingComplete(false)
      if(e?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:e?.response?.data?.detail
        })
      }
    })
  }

  useEffect(() => {
    clientService.getUserNotifications(clientForInvite.id, obj.id)
    .then((res) => {
      setGeneral(res.data.detail.general.enabled)
      setBilling(res.data.detail.billing_and_service.enabled)
      setTechnical(res.data.detail.technical.enabled)
      setCc_tickets(res.data.detail.cc_to_all_ticket_emails.enabled)
      setPageLoading(false)
    })
    .catch((e) => {
      setLoading(false)
      setLoadingComplete(false)
      setPageLoading(false)
      if(e?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:e?.response?.data?.detail
        })
      }
      // closeModalCancel()
    })
  },[obj])
  if(pageLoading) return  <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {t("NOTIFICATIONS_SETTINGS")}
        </HeadLine3>
        <div/>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <RolesContainer>
          <Typography variant="body">
          {t("EDIT_NOTIFICATION_SETTING_FOR_USER")}&nbsp;{obj.full_name}&nbsp;{t("IN_RELATION_WITH_CLIENT")}&nbsp;{obj.client_full_name}.
          </Typography>
          <CheckBox
            title={t("GENERAL_ANNOUNCEMENTS")}
            value={general} 
            onChange={() => setGeneral(!general)}
          />
          <CheckBox
            title={t("BILLING_AND_SERVICE")}
            value={billing}
            onChange={() => setBilling(!billing)}
          />
          <CheckBox
            title={t("CC_TO_ALL_TICKETS_EMAIL")}
            value={cc_tickets}
            onChange={() => setCc_tickets(!cc_tickets)}
          />
          <CheckBox
            title={t("TECHNICAL_ANNOUNCEMENTS")}
            value={technical}
            onChange={() => setTechnical(!technical)}
          />
        </RolesContainer>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={onSubmit}
            title={t("SAVE")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default EditNotifications