import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,
  TutorItem,
  HideWrapper,
  BodyLink,
} from './styles'
import { Button, InfoBlock, ModalVariables, Table } from '../../../../../components'
import StyledIcon from "../../../../../components/styled-icon";
import { timeConverterMMDDYYHHMM } from '../../../../../utils/dates'
import { InfoContainer, InfoTitle } from '../../../../../components/info-block/styles'
import DocIcon from './ic-document.svg'
import { icons } from '../../../../../icons'
import ScaleIc from './scaleIc.svg'
import { useNavigate } from 'react-router-dom';
import { kubernetesService } from '../../../../../api';
import { useUi } from '../../../../../context/ui.context';

type Node = {
  control_plane:boolean,
  flavor:string,
  id:string,
  name:string,
  node_pool:string|null,
  ready:boolean,
  state:string,
  version:string,
}

type OverviewProps = {
  cluster:any,
  masterNodes:Node[],
  nodePools:Node[],
}

const Overview:FC<OverviewProps> = ({cluster, masterNodes, nodePools}) => {
  const [t] = useTranslation()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [deletedNodePool, setDeletedNodePool] = useState<any>()
  const [editedNodePool, setEditedNodePool] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const {viewAlert} = useUi()
  const specifications = [
    {
      title:t("KUBE_API"),
      id:'4',
      value:cluster?.api_address || '-'
    },
    {
      title:t("KUBE_VERSION"),
      id:'5',
      value:cluster?.cluster_version || '-'
    },
    {
      title:t("ID"),
      id:'1',
      value:cluster?.id || '-',
      copy:true,
    },
    {
      title:t("RESTRICTION_API"),
      id:'6',
      copy:cluster?.restriction_api ? true : false,
      value:cluster?.restriction_api ? cluster?.restriction_ips?.map((ip:string) => `${ip} `) : 'False'
    },
    {
      title:t("CREATED_AT"),
      id:'2',
      value:timeConverterMMDDYYHHMM(cluster?.created_at) || '-'
    },
    {
      title:t("UPDATED_AT"),
      id:'3',
      value:timeConverterMMDDYYHHMM(cluster?.updated_at) || '-'
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("STATUS"),
      key:'state'
    },
    {
      title:t("KUBE_VERSION"),
      key:'version'
    },
    {
      title:t("FLAVOR_CLUSTER"),
      key:'flavor'
    },
  ]
  const ColumnsPools = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("NODE_COUNT"),
      key:'count'
    },
    {
      title:t("AUTOSCALE"),
      key:'autoscaleView'
    },
    {
      title:t("FLAVOR_CLUSTER"),
      key:'flavor'
    },
    {
      title:t("KUBE_VERSION"),
      key:'version'
    },
    {
      title:'',
      key:'props'
    }
  ]
  const items = [
    {
      title:`${t("SCALE")}`,
      id:'12',
      icon:ScaleIc,
      divider:false,
      onClick:(pool:any) => {
        setEditedNodePool(pool)
        openModal('scale-pool')
      }
    },
    // {
    //   title:`${t("EDIT")}`,
    //   id:'12',
    //   icon:icons.Edit,
    //   divider:false,
    //   onClick:(pool:any) => {
    //     navigate(`/kubernetes/${cluster.id}/edit_node_pool/${pool.id}`)
    //   }
    // },
    {
      title:t("DELETE"),
      id:'13',
      icon:icons.DeleteIcon,
      divider:false,
      onClick:(node_pool:any) => {
        setDeletedNodePool(node_pool)
        setAlertTitle(t("NODE_POOL"))
        setAlertSubTitle([node_pool])
        openModal('delete')
      },
    },
  ]

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = async () => {
    
    if(modalType === 'delete'){
      setLoading(true)
      try{
        const res = await kubernetesService.deleteNodePool(deletedNodePool.id)
        await setViewModal(false)
        await setActiveAnimation(false)
        await setLoading(false)
      } catch (e:any) {
        viewAlert({
          severity: 'error',
          message: e?.response?.data?.detail || t("ERROR_OCCURRED")
        })
        await setLoading(false)
      }

    } else {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }
  return(
    <Container>
      <TopInfoWrapper>
        {/* <TutorItem style={{backgroundImage:`url(${DocIcon})`}}>
          <Button
            title={t('DOWNLOAD_CONFIG_FILE')}
            onClick={() => {}}
            variant='primary'
            size='custom'
            customStyles={{height:'40px'}}
          />
          <HideWrapper onClick={() => window.open(``)}>
            <StyledIcon src={icons.LinkBlue}/>
            <BodyLink>{t("USE_CONFIG_FILE_TO_CONNECT_CLUSTER")}</BodyLink>
          </HideWrapper>
        </TutorItem> */}
        <InfoBlock
          data={specifications}
          title={t("DETAILS")}
          customStyles={{width:"545px", flex:'auto 1', marginBottom:'0px'}}
        />
      </TopInfoWrapper>
      <InfoContainer>
        <InfoTitle style={{marginBottom:'10px'}}>
          {t("MASTER_NODES")}
        </InfoTitle>
        <Table
          columns={Columns}
          rows={masterNodes}
          hideOther
          hideCheckbox
        />
      </InfoContainer>
      <InfoContainer>
        <InfoTitle style={{marginBottom:'10px'}}>
          {t("NODE_POOLS")}
        </InfoTitle>
        <Button
          variant="stroke"
          size="display"
          icon={icons.PlusDefault}
          customStyles={{marginBottom:'10px'}}
          //@ts-ignore
          onClick={() => navigate(`/kubernetes/${cluster.id}/create_node_pool`)}
          title={t("ADD_NODE_POOL")}
        />
        <Table
          columns={ColumnsPools}
          rows={nodePools}
          settings={items}
          hideOther
          hideCheckbox
        />
      </InfoContainer>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        dataToEdit={editedNodePool}
        instanceId={editedNodePool?.id}
        instance={editedNodePool}
        loading={loading}
      />
    </Container>
  )
}
export default Overview