import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoBlock } from '../../../../../../components'
import {
  Container,
  TopInfoWrapper
} from '../../../pools/styles'
import {timeConverterMMDDYYHHMM} from '../../../../../../utils/dates'
import { handleStatusName } from '../../../../../../utils/handle-status-name'
import { GreenDot, Greentext, StateWrapper } from '../../../../../Instances/styles'

const Overview:FC<any> = ({policy}) => {
  const [t] = useTranslation()
  const specifications = [
    {
      title:t("ID"),
      id:'1',
      value:policy?.id || '-',
      copy:true,
    },
    {
      title:t("NAME"),
      id:'2',
      value:policy?.name || 'n/a'
    },
    {
      title:t("DESCRIPTION"),
      id:'3',
      value: policy?.description || 'n/a'
    },
    {
      title:t("OPERATING_STATUS"),
      id:'5',
      value:(<StateWrapper>
              <GreenDot active={policy?.operating_status}/>
              <Greentext active={policy?.operating_status}>
                {handleStatusName(policy?.operating_status, t)}
              </Greentext>
            </StateWrapper>) ,
    },
    {
      title:t("PROVISIONING_STATUS"),
      id:'6',
      value: (<StateWrapper>
              <GreenDot active={policy?.provisioning_status}/>
              <Greentext active={policy?.provisioning_status}>
                {handleStatusName(policy?.provisioning_status, t)}
              </Greentext>
            </StateWrapper>),
    },
    {
      title:t("ADMIN_STATE"),
      id:'7',
      value:policy?.admin_state_up ? 'Yes' : 'No',
    },
    {
      title:t("CREATED_AT"),
      id:'8',
      value:timeConverterMMDDYYHHMM(policy?.created_at)
    },

    {
      title:t("UPDATED_AT"),
      id:'9',
      value:timeConverterMMDDYYHHMM(policy?.updated_at)
    },
    {
      title:t("ACTION"),
      id:'10',
      value:policy?.action || '-',
    },
    {
      title:t("REDIRECT_POOL_ID"),
      id:'12',
      value:policy?.redirect_pool_id || '-',
      copy:true,
    },
    {
      title:t("REDIRECT_URL"),
      id:'13',
      value:policy?.redirect_url || '-'
    },
    {
      title:t("POSITION"),
      id:'14',
      value:policy?.position || '-'
    },
    {
      title:t("LISTENER_ID"),
      id:'15',
      value:policy?.listener_id || '-',
      copy:true,
    },
  ]
  return(
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1'}}
        />
      </TopInfoWrapper>
    </Container>
  )
}
export default Overview
