import styled, {keyframes, css} from "styled-components";
import { BodyMedium, BodyDefault, VerySmall } from "../typography/styled";


type ActiveProp = {
  active:boolean;
  activeResults?:boolean;
}

export const Container = styled.div`
  position: relative;
  width:100%;
  /* flex: 0 0 50%; */
  
`
export const SearchWrapper = styled.div<ActiveProp>`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: ${({active}) => active ? '650px' : '293px'}; */
  width: 100%;
  z-index:3;
  position: absolute;
  transition: width 0.5s ease-in-out;
  height: 38px;
  top:-20px;
  background-color: ${({theme, active}) => active ? theme.palette[10] : theme.palette[300]};
  padding: ${({active}) => active ? '10px' : '10px 20px 10px 10px'};
  box-shadow:${({theme, activeResults}) => !activeResults ? '' : `0px 0px 0px 3px ${theme.palette[30]}`};
  border-radius: ${({activeResults}) => activeResults ? '4px 4px 0px 0px' : '4px'};
  color: ${({theme}) => theme.primary[700]};
  border-bottom-color: ${({theme}) => theme.grey[400]} ;
  border: ${({theme, active}) =>  active && !theme.isDark ?  `1px solid ${theme.primary[300]}` : `1px solid ${theme.palette[10]}`};
  &:hover{
    cursor: pointer;
    color: ${({theme}) => theme.primary[500]};
  }
`

export const ImgArrow = styled.img`

`
export const IconWrapper = styled.div`
  display: flex;
  z-index: 5;
`
export const Title = styled(BodyMedium)`
  color:${({theme}) => theme.palette[800]};
  margin-left: 6px;
  margin-right: 13px;
`

export const SearchInput = styled.input<ActiveProp>`
  width: 100%;
  border:none;
  font-size: 15px;
  outline: none;
  color:${({theme}) => theme.palette[900]};
  background-color: ${({theme, active}) => active ? theme.palette[10] : theme.palette[300]};
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({theme}) => theme.palette[900]};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({theme}) => theme.palette[900]};
      /* -webkit-text-fill-color: #fff !important; */
  }
`
export const Divider = styled.div<ActiveProp>`
  position: absolute;
  box-sizing: border-box;
  z-index: 5;
  /* width: 648px;
   */
  width:100%;
  height: 15px;
  left:0px;
  top: 7px;

  background-color: ${({theme}) => theme.palette[10]};
  border-bottom: ${({theme, active}) =>  active ? `1px solid ${theme.grey[400]}` : ''};
  border-left: ${({theme, active}) =>  active && !theme.isDark ? `1px solid ${theme.primary[300]}` : ''};
  border-right: ${({theme, active}) =>  active && !theme.isDark ? `1px solid ${theme.primary[300]}` : ''};
`
export const DividerCategory = styled.div`
  width: 100%;
  border-bottom: ${({theme}) => `1px solid ${theme.palette[400]}`};
  margin-top:16.5px;
  margin-bottom:15px;
`

export const SearchResultsContainer = styled.div<ActiveProp>`
  display: ${({active}) => active ? '' : 'none'};
  transition: opacity 0.1s  ease-in-out;
`

export const OptionsWrapper = styled.div<ActiveProp>`
  box-sizing: border-box;
  top: 20px;
  position: absolute;
  display: flex;
  z-index:1;
  flex-direction: column;
  border-radius: ${({active}) => active ? '0px 0px 4px 4px' : '4px'};
  padding: 0px 0px 0px 8px;
  border: ${({theme}) => !theme.isDark ? `1px solid ${theme.primary[300]}` : ``};
  background-color: ${({theme}) => theme.palette[10]};
  box-shadow:${({theme}) => `0px 0px 0px 3px ${theme.palette[30]}`};
  
  width:100%;
  max-height: 354px;
  /* min-height: 354px; */
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 2px 2px ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[400]};
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  ::-webkit-scrollbar-button{
    display: none;
  }
`
export const DropOptionsTitle = styled(VerySmall)`
  color:${({theme}) => theme.palette[600]};
  padding: 0px 10px 2px 12px;
  text-transform: uppercase;
  font-weight: 500;
`

export const DropItemWrapper = styled.div`
  padding: 8px 12px;
  display: flex;
  &:hover{
    cursor: pointer;
     background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[100]};
  }
  &:active{
    background-color: ${({theme}) => theme.isDark ? theme.palette[400] : theme.grey[200]};
    transition: 0.1s background-color ease-in-out;
  }
`

export const DropOptionItem = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  margin-left: 13px;
  font-size: 16px;
  line-height: 19px;
`