import React, {FC, useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { supportPalService } from "../../../../api";
import { BackArrow, TopProgress, Typography } from "../../../../components";
import { InfoContainer } from "../../../../components/info-block/styles";
import { useUi } from "../../../../context/ui.context";
import {
  Container,
  TitleBig,
  InfoContainerCustom,
  TutorTitle,
  TutorContent,
} from "./styles";

type IncomingProps = {
  department:string,
  setDepartment:(department:string) => void,
}
type Department = {
  title:string,
  slug:string,
  description:string | null,
}
const SelectDepartmentPage:FC<IncomingProps> = (props) => {
  const [t] = useTranslation()
  const {
    viewAlert
  } = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [departments, setDepartments] = useState<any[]>([])
  const navigate = useNavigate()
  const {
    department,
    setDepartment,
  } = props
  const getDepartments = () => {
    supportPalService.getDepartments()
    .then((res:any) => {
      setDepartments(res.data.data.map((department:any) => {
        return {
          ...department,
          title:department.name,
          slug:department.id
        }
      }))
      setPageLoading(false)
    })
    .catch((err) => {
      setPageLoading(false)
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:err?.response?.data?.detail
        })
      }
    })
  }
  useEffect(() => {
    getDepartments()
  },[])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow
        onClick={() => navigate('/support')}
        title={t("BACK_TO_TICKETS")}
      />
      <TitleBig>
        {t("SUBMIT_A_TICKET")}
      </TitleBig>
      <InfoContainer style={{display:'flex', gap:'10px'}}>
        <Typography variant='h3'>
          {t("SELECT_DEPARTMENT")}
        </Typography>
        <Typography variant='small'>
          {t("SELECT_DEPARTMENT_DESCRIPTION")}
        </Typography>
        <div>
          {departments.map((item:Department) => (
            <InfoContainerCustom
              active={department === item.title}
              onClick={() => {
                setDepartment(item.title)
                navigate(`submit?department=${item.slug}`)
              }}
            >
              <TutorTitle>
                {item.title}
              </TutorTitle>
              {item?.description && <TutorContent>
                {item?.description}
              </TutorContent>}
            </InfoContainerCustom>
          ))}
        </div>
      </InfoContainer>
    </Container>
  )
}

export default SelectDepartmentPage