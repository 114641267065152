export const handleStatusName = (status: string, t:any) => {
  switch (status.toLocaleLowerCase()) {
    case 'available':
      return t("AVAILABLE")
    case "Stopped".toLocaleLowerCase():
      return t("STOPPED");
    case "Suspended".toLocaleLowerCase():
      return t("SUSPENDED");
    case "failed".toLocaleLowerCase():
      return t("FAILED");
    case "aborted".toLocaleLowerCase():
      return t("ABORTED");
    case "Running".toLocaleLowerCase():
      return t("RUNNING");
    case "Open".toLocaleLowerCase():
      return t("OPEN");
    case "Starting".toLocaleLowerCase():
      return t("STARTING");
    case "Stopping".toLocaleLowerCase():
      return t("STOPPING");
    case "Reboot".toLocaleLowerCase():
      return t("REBOOT");
    case "Rebuild".toLocaleLowerCase():
      return t("REBUILD");
    case "in progress".toLocaleLowerCase():
      return t("IN_PROGRESS");
    case "Verify Resize".toLocaleLowerCase():
      return t("VERIFY_RESIZE");
    case "Building".toLocaleLowerCase():
      return t("BUILDING");
    case "Resize".toLocaleLowerCase():
      return t("RESIZING");
    case "Rescue".toLocaleLowerCase():
      return t("RESCUE");
    case "Resizing".toLocaleLowerCase():
      return t("RESIZING");
    case "Shelved Offloaded".toLocaleLowerCase():
      return t("SHELVED_OFFLOADED");
    case "Revert Resize".toLocaleLowerCase():
      return t("REVERT_RESIZE");
    case "Rescued".toLocaleLowerCase():
      return t("RESCUED");
    case "Succeeded".toLocaleLowerCase():
      return t("SUCCEEDED");
    case "DOWN".toLocaleLowerCase():
      return t("DOWN");
    case "ACTIVE".toLocaleLowerCase():
      return t("ACTIVE");
    case "paid".toLocaleLowerCase():
      return t("PAID");
    case "unpaid".toLocaleLowerCase():
      return t("UNPAID");
    case "seen".toLocaleLowerCase():
      return t("SEEN");
    case "pending".toLocaleLowerCase():
      return t("PENDING");
    case "Queued".toLocaleLowerCase():
      return t("QUEUED")
    case "error".toLocaleLowerCase():
      return t("ERROR")
    case "offline".toLocaleLowerCase():
      return t("DOWN")
    case "online".toLocaleLowerCase():
      return t("ACTIVE");
    case "PENDING_CREATE".toLocaleLowerCase():
      return t("PENDING_CREATE")
    case "PENDING_DELETE".toLocaleLowerCase():
      return t("PENDING_DELETE")
    case "PENDING_UPDATE".toLocaleLowerCase():
      return t("PENDING_UPDATE")
    case "inactive".toLocaleLowerCase():
      return t("INACTIVE")
    default:
      return status;
  }
}




export const handleStatusTask = (status: string, t:any) => {
  switch (status.toLocaleLowerCase()) {
    case "Scheduling".toLocaleLowerCase():
      return t("SCHEDULING");
    case "Block device mapping".toLocaleLowerCase():
      return t("BLOCK_DEVICE_MAPPING");
    case "Networking".toLocaleLowerCase():
      return t("NETWORKING_TASK");
    case "Spawning".toLocaleLowerCase():
      return t("SPAWNING");
    case "Image snapshot".toLocaleLowerCase():
      return t("IMAGE_SNAPSHOT");
    case "Image snapshot pending".toLocaleLowerCase():
      return t("IMAGE_SNAPSHOT_PENDING");
    case "Image pending upload".toLocaleLowerCase():
      return t("IMAGE_PENDING_UPLOAD");
    case "Image uploading".toLocaleLowerCase():
      return t("IMAGE_UPLOADING");
    case "Image backup".toLocaleLowerCase():
      return t("IMAGE_BACKUP");
    case "Updating password".toLocaleLowerCase():
      return t("UPDATING_PASSWORD");
    case "Resize prep".toLocaleLowerCase():
      return t("RESIZE_PREP");
    case "Resize migrating".toLocaleLowerCase():
      return t("RESIZE_MIGRATING");
    case "Resize migrated".toLocaleLowerCase():
      return t("RESIZE_MIGRATED");
    case "Resize finish".toLocaleLowerCase():
      return t("RESIZE_FINISH");
    case "Resize reverting".toLocaleLowerCase():
      return t("RESIZE_REVERTING");
    case "Resize confirming".toLocaleLowerCase():
      return t("RESIZE_CONFIRMING");
    case "Rebooting".toLocaleLowerCase():
      return t("REBOOTING");
    case "Reboot pending".toLocaleLowerCase():
      return t("REBOOT_PENDING");
    case "Reboot started".toLocaleLowerCase():
      return t("REBOOT_STARTED");
    case "Rebooting hard".toLocaleLowerCase():
      return t("REBOOTING_HARD");
    case "Reboot pending hard".toLocaleLowerCase():
      return t("REBOOT_PENDING_HARD");
    case "Reboot started hard".toLocaleLowerCase():
      return t("REBOOT_STARTED_HARD");
    case "Pausing".toLocaleLowerCase():
      return t("PAUSING");
    case "Unpausing".toLocaleLowerCase():
      return t("UNPAUSING");
    case "Suspending".toLocaleLowerCase():
      return t("SUSPENDING");
    case "Resuming".toLocaleLowerCase():
      return t("RESUMING");
    case "Powering off".toLocaleLowerCase():
      return t("POWERING_OFF")
    case "Powering on".toLocaleLowerCase():
      return t("POWERING_ON")
    case "Rescuing".toLocaleLowerCase():
      return t("RESCUING")
    case "Booting from ISO".toLocaleLowerCase():
      return t("BOOTING_FROM_ISO");
    case "Unrescuing".toLocaleLowerCase():
      return t("UNRESCUING");
    case "Unmounting ISO and rebooting".toLocaleLowerCase():
      return t("UNMOUNTING_AND_REBOOTING");
    case "Rebuilding".toLocaleLowerCase():
      return t("REBUILDING");
    case "Rebuild block device mapping".toLocaleLowerCase():
      return t("REBUILD_BLOCK_DEVICE_MAPPING");
    case "Rebuild spawning".toLocaleLowerCase():
      return t("REBUILD_SPAWNING");
    case "Migrating".toLocaleLowerCase():
      return t("MIGRATING");
    case "Deleting".toLocaleLowerCase():
      return t("DELETING");
    case "Soft deleting".toLocaleLowerCase():
      return t("SOFT_DELETING");
    case "Restoring".toLocaleLowerCase():
      return t("RESTORING");
    case "Shelving".toLocaleLowerCase():
      return t("SHELVING");
    case "Shelving image pending upload".toLocaleLowerCase():
      return t("SHELVING_IMAGE_PENDING_UPLOAD");
    case "Shelving image uploading".toLocaleLowerCase():
      return t("SHELVING_IMAGE_UPLOADING");
    case "Shelving offloading".toLocaleLowerCase():
      return t("SHELVING_OFFLOADING");
    case "Unshelving".toLocaleLowerCase():
      return t("UNSHELVING");
    default:
      return status;
  }
}