import styled from 'styled-components'
import { BodyDefault } from '../../../../components/typography/styled'

type ClusterTypeProps = {
  selected:boolean,
}
export const Container = styled.div`
  padding-top:20px;
  padding-right:40px;
  display:flex;
  width: 100%;
  flex-direction:column;
  gap:20px;
`


export const TypesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  /* max-width: 930px; */
`

export const ClusterTypeContainer = styled.div<ClusterTypeProps>`
  position: relative;
  display: flex;
  /* padding: 0px 20px; */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
  background-color: ${({theme, selected}) => selected ? theme.isDark ? theme.grey[800] : theme.primary[25] : theme.palette[10]};
  border: ${({theme, selected}) =>  selected ? `1.5px solid ${theme.primary[600]}` : `1px solid ${theme.grey[350]}` };
  border-radius: 8px;
  &:hover{
    cursor:pointer;
    /* background-color: #FDFEFF; */
    background-color: ${({theme, selected}) => selected ? theme.isDark ? theme.grey[800] : theme.primary[25] : theme.isDark ? theme.palette[300] : '#FDFEFF'};
  }
`
export const TypeTopDiv = styled.div`
  display: flex;
  /* height: 100%; */
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0px 20px 20px;
  width: 100%;
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
`
export const MasterWrapperDiv = styled.div`
  display: flex;
  /* height: 100%; */
  align-items: center;
  box-sizing: border-box;
  padding: 20px 40px 20px 20px;
  height:100%;
  border-right: ${({theme}) => `1px solid ${theme.grey[350]}`};
`
export const NodesInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 0px 0px 0px;
`
export const TypeLeftDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* padding: 20px 20px 20px 0px; */
  /* gap:20px; */
  width: 100%;
`
export const InfoElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  gap:4px;
  /* max-width: 950px; */
  flex:1 auto;

`
export const TypeRightDiv = styled.div`
  display: flex;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
  padding: 20px 20px 20px 20px;
  border-left: ${({theme}) => `1px solid ${theme.grey[350]}`};
`
export const GreyText = styled(BodyDefault)`
  color:${({theme}) => theme.grey[700]};
`
export const MasterSquare = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #4671F6;
`

export const NodeSquare = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: linear-gradient(119deg, #B9C9F9 0.53%, rgba(185, 201, 249, 0.50) 100.53%);
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap:20px;
`

export const NodesWrapper = styled.div`
  display: flex;
  gap: 4px;
`

export const SelectedCircle = styled.div`
  position: absolute;
  left: -11px;
  top: -11px;
  display: flex;
  width: 22px;
  height: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  /* background: var(--primary-600, #4671F6); */
`