import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoBlock } from '../../../../../components'
import {
  Container,
  TopInfoWrapper
} from '../subnet/styles'
import {timeConverterMMDDYYHHMM} from '../../../../../utils/dates'

const Subnet:FC<any> = ({network}) => {
  const [t] = useTranslation()
  const specifications = [
    {
      title:t("PROJECT"),
      id:'1',
      value:network.project
    },
    {
      title:t("PLACEMENT_REGION"),
      id:'1',
      value:network.region
    },
    {
      title:t("CREATED_AT"),
      id:'1',
      value: timeConverterMMDDYYHHMM(network.created_at)
    },
    {
      title:t("UPDATED_AT"),
      id:'1',
      value:timeConverterMMDDYYHHMM(network.updated_at)
    },
    {
      title:t("SHARED"),
      id:'1',
      value:network.shared ? t("YES") : t("NO")
    },
    {
      title:t("ADMIN_STATE"),
      id:'1',
      value:network.admin_state_up ? t("STATE_UP") : t("STATE_DOWN")
    },
  ]
  return(
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1'}}
        />
      </TopInfoWrapper>
    </Container>
  )
}
export default Subnet
