import React, { FC, useEffect, useState } from 'react'
import {
  Container,
  Donut,
  AnimationWrapper,
  DonutWrapper,
  DonutBackground,
  DiagramTitle,
  DiagramTitleGrey,
  PieWrapper
} from './styles'
import Label from '../label'
import { useTranslation } from 'react-i18next'

type DonutAreaProps = {
  data:any[],
  customStyles?:any,
  title:string,
  subtitle:string,
  isLeft?:boolean,
  toolTip?:boolean,
  tooltipText?:string,
}


const DonutArea:FC<DonutAreaProps> = (props) => {
  const {
    customStyles,
    data,
    title,
    subtitle,
    isLeft,
    toolTip,
    tooltipText,
  } = props
  const [t] = useTranslation()
  return(
    // <Container style={customStyles}>
    <PieWrapper style={customStyles}>
      <div style={{display:'flex'}}>
        <DiagramTitle>
          {title}
        </DiagramTitle>
        <DiagramTitleGrey>
          {subtitle}
        </DiagramTitleGrey>
      </div>
      <div style={{display:'flex', marginTop:'21px'}}>
        {data.map((item:any) => (
          <DonutWrapper>
            <AnimationWrapper>
              <DonutBackground/>
              <Donut part1={item.part1} part2={item.part2} isLeft={isLeft}/>
            </AnimationWrapper>
            <Label
              isDonut
              value={item.value}
              valueMax={item.valueMax}
              title={item.title}
              toolTip={item.toolTip}
              tooltipText={item.tooltipText}
            />
            <DiagramTitle>{item.value}</DiagramTitle>
          </DonutWrapper>
        ))}
      </div>
    </PieWrapper>
  )
}

export default DonutArea