import styled from "styled-components";
import { HeadLine1 } from "../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 25px 10px 20px;
  position: relative;
`
export const TopInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
  margin-right: 10px;
`

export const RightHeadContainer = styled.div`
  display:flex;
  position: relative;
`

export const InstanceName = styled(HeadLine1)`
  color:${({theme}) => theme.palette[800]};
  margin-top:10px;
  margin-bottom:10px;
  max-width:500px;
  word-break: break-word;
`
export const Divider = styled.div`
  width:100%;
  border-bottom: ${({theme}) => `1px solid ${theme.palette[20]}`};
  margin: 30px 0px 30px;
`