import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { Button, ClipBoardWrapper, ModalVariables, StartPage } from "../../components";
import TutorImg from './illustration.svg'
import TutorDarkImg from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { bareMetalService, } from "../../api";
import { GreenDot, Prop, PropContainer, StateWrapper, Greentext } from "../Instances/styles";
import { useUi } from "../../context/ui.context";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../context/user.context";
import rootConfig from "../../config";
import { compareArrays } from "../../utils/findIntersection";
import RebootIc from './ic-reboot.svg'
import RestartIc from './ic-restart.svg'

const BareMetal:FC = () => {
  const [servers, setServers] = useState<any[]>([])
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam);
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [paginationData, setPaginationData] = useState<any>()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedServer, setSelectedServer] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user, quotas} = useAuth()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(servers, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {setDataToLs} = useLocalStorage()
  const _updateImages = () => {
    // setLoading(true)
    bareMetalService.getListMyServers()
    .then((res) => {
      setServers(res.data.objects.map((item:any) => {
        return {
          ...item,
          navigate:`${item.id}`,
          clearName:item?.name,
          status: (
          <StateWrapper>
            <GreenDot active={item.state.power_status}/>
            <Greentext active={item.state.power_status}>
              {item.state.power_status}
            </Greentext>
          </StateWrapper>),
          access_ip: !item.primary_ip ? '-' : 
            (<ClipBoardWrapper>
              {item?.primary_ip}
            </ClipBoardWrapper>)
          ,
          name: (
          <div style={{display:'flex', flexDirection:'column'}}>
            <a>
              {item?.name}
            </a>
            <div style={{
              display:'flex',
              flexWrap:"wrap",
              alignItems:'center',
              gap:'4px',
              }}>
                {item?.tags?.map((tag:any) => (
                  <PropContainer key={tag}>
                    <Prop>
                      {tag}
                    </Prop>
                  </PropContainer>
                ))}
              </div>
            </div>
          ),
        }
      }))
      setPaginationData(res.data)
      setPageLoading(false)
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      // navigate('/home')
      setPageLoading(false)
    })
  }
  useEffect(() => {
    _updateImages()
    const interval = setInterval(() => {
      _updateImages()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  
  const _deleteServer = async () => {
    setLoading(true);
    try {
      if (allChecked) {
        await Promise.all(servers.map((item:any) => bareMetalService.deleteServerById(item.id)));
        _updateImages();
        setAllChecked(false);
        setChecked([]);
      } else if (checked?.length > 0) {
        await Promise.all(checked.map((item) => bareMetalService.deleteServerById(item.id)));
        _updateImages();
        setChecked([]);
      } else if (selectedServer) {
        await bareMetalService.deleteServerById(selectedServer.id);
        _updateImages();
      }
      await setLoadingComplete(true)
      await setTimeout(() => {
        setLoading(false);
        setLoadingComplete(false);
        setViewModal(false);
        setActiveAnimation(false);
        setModalType('')
      },1500)
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      setChecked([]);
      setLoading(false);
    } 
  };
  const _StartStopServer = async (status:string) => {
    setLoading(true);
    try {
      if (allChecked) {
        await Promise.all(servers.map((item:any) => bareMetalService.changePowerStatus(item.id, status)));
        _updateImages();
        setAllChecked(false);
        setChecked([]);
      } else if (checked?.length > 0) {
        await Promise.all(checked.map((item) => bareMetalService.changePowerStatus(item.id, status)));
        _updateImages();
        setChecked([]);
      } else if (selectedServer) {
        await bareMetalService.changePowerStatus(selectedServer.id, status)
        _updateImages();
      }
      await setLoadingComplete(true)
      await setTimeout(() => {
        setLoading(false);
        setLoadingComplete(false);
        setViewModal(false);
        setActiveAnimation(false);
        setModalType('')
      },1500)
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      setChecked([]);
      setLoading(false);
    } 
  };
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = async (values:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'baremetal-add-floating':
          res = await bareMetalService.addFloating(selectedServer.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'baremetal-dissociate-floating':
          res = await bareMetalService.removeFloating(selectedServer.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'delete':
          _deleteServer()
          break;

        case 'reinstall-baremetal':
          res = await bareMetalService.reinstallServer(selectedServer.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'edit-baremetal':
          res = await bareMetalService.editServer(selectedServer.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'universal':
          // setLoading(true);
          if (alertTitle === `${t('STOP_SERVER')}` || alertTitle === `${t('STOP_SERVERS')}`) {
            _StartStopServer('stop')
          } else if (alertTitle === `${t('START_SERVER')}` || alertTitle === `${t('START_SERVERS')}`) {
            _StartStopServer('start')
          } else if (alertTitle === `${t('RESTART_SERVER')}`) {
            _StartStopServer('restart')
          } else if (alertTitle === `${t('REBOOT_SERVER')}`) {
            _StartStopServer('reboot')
            // res = await bareMetalService.changePowerStatus(bareMetal.id, 'reboot');
          }
  
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        }
        if(modalType !== 'delete' && modalType !== 'universal'){
          await setLoadingComplete(true)
          await setTimeout(() => {
            setLoading(false);
            setLoadingComplete(false);
            setViewModal(false);
            setActiveAnimation(false);
            setModalType('')
          },1500)
        }
        // await setViewModal(false);
        // await setActiveAnimation(false);
        // await setModalType('')
      } catch (e: any) {
        console.log('e',e);
        if (e?.response?.data.detail) {
          viewAlert({
            severity: 'error',
            message: e.response.data.detail,
          });
        }
      } finally {
        if(modalType !== 'delete' && modalType !== 'universal'){
          setLoading(false);
        }
      }
    // setViewModal(false)
    // setActiveAnimation(false)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("REINSTALL"),
      id:'1',
      icon:icons.Rebuild,
      onClick: (server:any) => {
        setSelectedServer(server)
        openModal('reinstall-baremetal')
      },
      divider:false
    },
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (server:any) => {
        setSelectedServer(server)
        openModal('edit-baremetal')
      },
      divider:false
    },
    {
      title:t("ASSIGN_FLOATING_IP"),
      onClick:(server:any) => {
        setSelectedServer(server)
        setAlertTitle(server.clearName)
        openModal('baremetal-add-floating')
      },
      id:'3',
      icon:icons.PlusDefault,
      divider:false
    },
    {
      title:t("DISSOCIATE_IP"),
      onClick:(server:any) => {
        setSelectedServer(server)
        setAlertTitle(server.clearName)
        openModal('baremetal-dissociate-floating')
      },
      id:'4',
      icon:icons.MinusDefault,
      divider:false
    },
    {
      title:t("REBOOT"),
      id:'5',
      onClick:(server:any) => {
        setSelectedServer(server)
        setAlertTitle(`${t("REBOOT_SERVER")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_REBOOT_SERVER")} ${server?.clearName}?`)
        openModal('universal')
      },
      icon:RebootIc,
      divider:false
    },
    {
      title:t("RESTART"),
      id:'7',
      onClick:(server:any) => {
        setSelectedServer(server)
        setAlertTitle(`${t("RESTART_SERVER")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_RESTART_SERVER")} ${server?.clearName}?`)
        openModal('universal')
      },
      icon:RestartIc,
      divider:false
    },
    {
      title:t("DELETE"),
      id:'6',
      onClick:(server:any) => {
        openModal('delete')
        setAlertTitle(t("BARE_METAL"))
        setAlertSubTitle([server])
        setSelectedServer(server)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name',
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    {
      title:t("SERVER"),
      key:'type'
    },
    {
      title:t("IP_ADDRESS"),
      key:'access_ip'
    },
    {
      title:t("OS"),
      key:'os'
    },
    {
      title:'',
      key:'props'
    },
  ]
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else {
      navigate('create')
    }
  }
  return (
    <>
      <StartPage
        title={`${t("BARE_METAL")}`}
        noResourceText={`${t("THERE_ARE_NO_BARE_METAL_YET")}`}
        createResourceButton={`${t('CREATE_BARE_METAL')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={servers.length > 0}
        learnMoreAboutResource={`${t('LEARN_MORE_BARE_METAL')}`}
        howCreateVideoText={`${t('HOW_CREATE_BARE_METAL')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/bare-metal`}
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center', gap:'6px'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("BARE_METAL_SERVERS"))
                setAlertSubTitle(allChecked ? servers : checked)
                openModal('delete')
              }}
              test_id='button-delete'
              variant='icon'
              size='display'
              disabled={!allChecked && checked?.length === 0 }
            >
              <img src={icons.DeleteIcon}/>
            </Button>
            <Button
              onClick={() => {
                setAlertTitle(`${t("STOP_SERVERS")}`)
                setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_STOP_SERVERS")} ${checked.map((ch:any) => ch.clearName)}?`)
                openModal('universal')
              }}
              variant='icon'
              test_id='button-stop'
              size='display'
              disabled={!allChecked && checked?.length === 0}
            >
              <img src={icons.PauseIcon}/>
            </Button>
            <Button
              onClick={() => {
                setAlertTitle(`${t("START_SERVERS")}`)
                setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_START_SERVERS")} ${checked.map((ch:any) => ch.clearName)}?`)
                openModal('universal')
              }}
              variant='icon'
              test_id='button-start'
              size='display'
              disabled={!allChecked && checked?.length === 0}
            >
              <img src={icons.PlayIcon}/>
            </Button>
          </div>
        }
        videoImage={TutorImg}
        videoImageDark={TutorDarkImg}
        pageLoading={pageLoading}
        tableData={{
          columns:Columns,
          data:servers,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          paginationData:paginationData,
          updateData:_updateImages
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        loadingComplete={loadingComplete}
        instance={selectedServer}
      />
    </>
  )
}


export default BareMetal

