import styled from "styled-components";
import { BodyDefault, HeadLine1 } from "../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 30px 30px;
  gap:20px;
`
export const CreateContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  margin-top: 20px;
  margin-bottom: 20px;
`
export const FormWrapper = styled.div`
  display:flex;
  flex-direction: column;
  width: 360px;
  gap:20px;

`
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 14px;
  background: ${({theme}) => theme.grey[100]};
  border-radius: 8px;
  width: 238px;
`

export const InfoText = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
`

export const DarkForm = styled.div`
  display: flex;
  background-color: ${({theme}) => theme.palette[200]};
  align-items: flex-start;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
  /* width:100%; */
  padding: 14px;
  gap:10px;
`
