import React, { FC, useEffect, useState } from "react";
import {
  Container,
  TitleBig,
  FormWrapper,
  CreatedOverlay,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
  CreateContainer,
} from './styles'
import {
  BackArrow,
  Input,
  RadioButtonGroup,
  Select,
  Button,
  RevealButton,
  Counter,
  Toggle,
  DragAndDrop,
  TopProgress,
  LoaderSpinner
} from "../../../components";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { imageService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { fileRequestHandler } from "../../../utils/request-file-handler";
import { useTheme } from "styled-components";
import { formatCurrencyString,formatCurrency } from "../../../utils/display-price";
import { useAuth } from "../../../context/user.context";
import { ErrorText } from "../../HomePage/styles";



const CreateAndEditImage:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert, updateAlertProgress} = useUi()
  const theme:any = useTheme()
  const {user} = useAuth()
  // const [protectedValue, setProtected] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [visibilities, setVisibilities] = useState<any[]>([])
  const [diskFormats, setDiskFormats] = useState<any[]>([])
  const [architecture, setArchitecture] = useState<string>('')
  const [distro, setDistro] = useState<any[]>([])
  const [region, setRegion] = useState<string>('')
  const [file, setFile] = useState<any>()
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()
  const [oneSubmitted, setOneSubmitted] = useState<boolean>(false)

  const [initialValues, setInitialValues ] = useState<any>({
    disk_format:'qcow2',
    source:'url',
    name:'',
    url:'',
    min_disk:'1',
    min_ram:'1',
  })
  const getCreateOptions = () => {
    imageService.getCreateOptions()
    .then((res) => {
      setArchitecture(res.data.architectures?.[0]?.[0])
      setDistro(res.data.os_distros.map((os:any) => {
        return {
          value:os,
          label:os,
        }
      }))
      setDiskFormats(res.data.disk_formats.map((format:string) => {
        return {
          value:format,
          label:format,
        }
      }))
      setRegion(res.data.regions?.[0]?.id)
      setVisibilities(res.data.visibilities.map((visibility:string) => {
        return{
          value:visibility,
          label:visibility,
        }
      }))
      setPageLoading(false)
    })
    .catch((err) => {
      
    })
    .finally(() => {

    })
  }
  useEffect(() => {
    getCreateOptions()
  },[])
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      ...values,
      region:region,
      protected:false,
      architecture:architecture,
      visibility:visibilities?.[0]?.value,
      hypervisor_type: ''
    }
    if(file && values.source === 'file'){
      data.file = file
      delete data.url
    }
    const handledData = fileRequestHandler(values.source, data)
    imageService.createImage(handledData.body, handledData.headers,
      updateAlertProgress, (values.source === 'file' ? viewAlert({
        severity:'image',
        // timeout:0,
        message: '',
        canClose:false,
      }): null), user?.user?.clients?.[0]?.id) 
    .then((res) => {
      viewAlert({
        severity:'info',
        message: `${t("IMAGE_SUCCESSFULLY_CREATED")}`
      })
      setLoadingComplete(true)
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
        if(res?.data?.detail){
          viewAlert({
            severity:'info',
            message: res.data.detail
          })
        }
        navigate('/images')
      },1000)

    })
    .catch((e) => {
      if(e?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message: e.response.data?.detail
        })
        setLoading(false)
      } else {
        setErrors(e?.response?.data)
        setLoading(false)
      }
    })
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
      .required(`${t('REQUIRED_FIELD')}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      visibility:yup.string(),
      os_distro:yup.string(),
      os_version:yup.string(),
      min_disk:yup.string(),
      min_ram:yup.string(),
      disk_format:yup.string(),
      source:yup.string(),
      url: yup.string().when("source", {
        is: (source:any) => source === 'url',
        then: yup.string().required(`${t('REQUIRED_FIELD')}`)
      }),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const getCurrentPrice = () => {
    let queries:any = {
      region: region,
      visibility:visibilities?.[0]?.value,
      disk_format:values['disk_format'],
    }
    if(values['source'] === 'file' && file){
      queries.size = file.size
    }
    
    if(!pageLoading){
      setPriceLoading(true)
      imageService.getPrice(queries)
      .then((res) => {
        setPrice(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
        setPriceMonth(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
        setPriceLoading(false)
      })
    }
  }
  
  useEffect(() => {
    getCurrentPrice()
  },[
    values?.visibility,
    values?.disk_format,
    file
  ])
    const buttonsDisk = [
      {
        value:'url',
        id:'url',
        title:t('URL')
      },
      {
        value:'file',
        id:'file',
        title:t('DISK')
      },
    ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <CreateContainer>
      <Container>
        <CreatedOverlay active={loading && values['source'] === 'file'}/>
        <BackArrow
          onClick={() => navigate('/images')}
          title={t("BACK_TO_IMAGES")}
        />
        <TitleBig>{t("CREATE_NEW_IMAGE")}</TitleBig>
        <FormWrapper>
          <Input
            required
            fieldName='name'
            title={t("NAME_IMAGE")}
            placeholder=''
            {...formikProps}
          />
          {/* <Select
            toolTip={false}
            data={visibilities}
            selectedValue={values['visibility']}
            onChange={(e) => handleChange('visibility')(e)}
            title={t("VISIBILITY")}
            fieldName='visibility'
            {...formikProps}
          /> */}
          <Select
            toolTip={false}
            test_id="image-type"
            data={diskFormats}
            selectedValue={values['disk_format']}
            onChange={(e) => handleChange('disk_format')(e)}
            title={t("DISK_FORMAT")}
          />
          <div style={{width: '250px'}}>
            <RadioButtonGroup
              toolTip={false}
              title={t("SOURCE")}
              buttons={buttonsDisk}
              selectedButton={values['source']}
              onChange={(e) => handleChange('source')(e)}
            />
          </div>
        </FormWrapper>

        <FormWrapper style={{marginBottom:'20px', marginTop:'20px'}}>
          {values['source'] === 'file' ?
          <div>
            <DragAndDrop
              required
              uploadedFile={file}
              onChange={setFile}
              isImage
              formats={'iso, mdf, img, dmg, bin, nrg, qcow2'}
              fileValidation={['iso', 'mdf', 'img', 'dmg', 'bin', 'nrg', 'qcow2']}
              requiredListener={values['source'] === 'file' && oneSubmitted && !file}
            />
          </div>
          :
            <Input
              required
              fieldName='url'
              title={t("URL")}
              placeholder=''
              customStyles={{width:'360px'}}
              {...formikProps}
            />
          }
        </FormWrapper>
        <div style={{width:'400px', marginTop:'0px', display:'flex', alignItems:'flex-start'}}>
          <RevealButton
            // customStyles={{width:'188px'}}
            title={t("ADDITIONAL_SETTINGS")}
        >
            {/* <FormWrapper> */}
              <Select
                toolTip={false}
                data={distro}
                selectedValue={values['os_distro']}
                onChange={(e) => handleChange('os_distro')(e)}
                title={t("OS")}
                test_id="image-os"
                customStyles={{marginBottom:'14px'}}
              />
              <Input
                fieldName='os_version'
                title={t("OS_VERSION")}
                placeholder=''
                customStyles={{marginBottom:'14px', width:'360px'}}
                {...formikProps}
              />
              <Counter
                customStyles={{marginBottom:"20px"}}
                title={t("MINIMUM_DISK_SIZE")}
                value={values['min_disk']}
                hideItems={'GB'}
                fieldName={'min_disk'}
                minCount={1}
                onChange={(v:any) => {
                  handleChange('min_disk')(v.toString())}}
              />
              <Counter
                customStyles={{marginBottom:"20px"}}
                title={t("MINIMUM_REQUIRED_RAM")}
                value={values['min_ram']}
                minCount={1}
                hideItems={'GB'}
                fieldName={'min_ram'}
                onChange={(v:any) => {
                  handleChange('min_ram')(v.toString())}}
              />
              {/* <Toggle
                title={t("PROTECTED")} 
                value={protectedValue}
                onChange={() => setProtected(!protectedValue)}
              /> */}

            {/* </FormWrapper> */}
          </RevealButton>
        </div>
        <div style={{display:'flex', marginTop:'40px'}}>
          <Button
            variant="primary"
            size="display"
            customStyles={{marginRight:"10px"}}
            onClick={() => {
              setOneSubmitted(true)
              handleSubmit()
            }}
            loading={loading}
            disabled={values['source'] === 'file' && !file}
            loadingComplete={loadingComplete}
            title={t("CREATE")}
          />
          <Button
            variant="stroke"
            size="display"
            // customStyles={{width: '81px'}}
            onClick={() => navigate("/images")}
            title={t("CANCEL")}
          />
        </div>
      </Container>
      <EstimateContainer>
        <EstimateTitle>
          {t("ESTIMATED_COST")}
        </EstimateTitle>
        {priceLoading ? 
          <LoaderSpinner customStyles={{color:theme.base.black}}/>
        :
          price ? 
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              <div style={{display:'flex', alignItems:'flex-end'}}>
                <EstimateSum>
                  {`${formatCurrency(price, user.user.clients?.[0]?.currency || 'UAH')}`}
                </EstimateSum>
                <PerHour>{t("HOURS")}</PerHour>
              </div>
              <div style={{display:'flex', alignItems:'flex-end'}}>
                <EstimateSum>
                  {`${formatCurrency(priceMonth, user.user.clients?.[0]?.currency || 'UAH')}`}
                </EstimateSum>
                <PerHour>{t("MONTH")}</PerHour>
              </div>
            </div>
          :
          <PerHour>
            {t("SELECT_OPTIONS_TO_DISPLAY_PRICE")}
          </PerHour>
        }
      </EstimateContainer>
    </CreateContainer>
  )
}

export default CreateAndEditImage



