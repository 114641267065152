import styled from "styled-components";
import { VerySmall } from "../../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  margin-top: 20px;
  width:100%;
  padding-right: 20px;
  flex-wrap: wrap;
  flex-direction: column;
`
export const PropContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 3px 4px;
  /* height:21px; */
  background-color: ${({theme}) => theme.palette[300]};
  border-radius: 4px;
  align-items: center;
`
export const Prop = styled(VerySmall)`
  color:${({theme}) => theme.palette[850]};
`