import styled from "styled-components";
import { BodyDefault, HeadLine3 } from "../../../../components/typography/styled";
import SVG from "react-inlinesvg";

export const Container = styled.div`
  display: flex;
  margin-top:10px;
  background-color: ${({theme}) => theme.base.white};
  min-height: 280px;
  gap:10px;
  flex-wrap:wrap;
`

export const NavItem = styled.div`
  box-sizing: border-box;
  background: ${({theme}) => theme.palette[100]};
  border-radius: 8px;
  margin-bottom: 10px;
  height: 240px;
  padding: 20px;
  display: flex;
  /* z-index: 3; */
  overflow: hidden;
  flex-direction: column;
  position: relative;
  /* justify-content: space-between; */
  flex: none;
  flex-basis:0;
  flex-grow: 1;
  &:hover {
    cursor: pointer;
    background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[200]};
  }
  &:active{
    background-color: ${({theme}) => theme.isDark ? theme.palette[400] : theme.grey[300]};
    transition: 0.1s background-color ease-in-out;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height:105px; */

`
export const NavTitle = styled(HeadLine3)`
  color:${({theme}) => theme.palette[800]};
  margin-bottom:8px;
`

export const NavDesc = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
  margin-bottom:10px;
`


export const CreateWrapper = styled.div`
  display: flex;
`

export const IconCreate = styled.img`
  width:20px;
  margin-right:8px;
`

export const CreateLink = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};

`
export const BackgroundIcon = styled(SVG)`
  position: absolute;
  /* z-index: 2; */
  opacity: 0.2;
  width:134px;
  height:134px;
  top:136px;
  &path{
    fill:${({theme}) => theme.isDark ? 'linear-gradient(180deg, #A9A9A9 0%, #787878 100%)' : ''};
  }
  /* @media screen and (max-width: 800px) {
    display: none;
  } */
`