import React, { FC, useState } from "react";
import {Container} from './styles'
import { Button, Input, Select, Toggle } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from 'use-query-params';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useBalancer } from "../../../../context/balancerContext";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
const MonitorDetails:FC<any> = ({internetAcc}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {setDataToLs, getDataToLs} = useLocalStorage()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [admin, setAdmin] = useState<boolean>(true)
  const [initialValues, setInitialValues ] = useState<any>({
    admin_state_up:true,
    delay:5,
    expected_codes:"200",
    http_method:"GET",
    max_retries:3,
    max_retries_down:3,
    name:"",
    timeout:5,
    type:"HTTPS",
    url_path:"/",
  })
  const {
    httpMethods,
    protocols,
    setMonitorDetails
  } = useBalancer()
  const onSubmit = (values:any) => {
    console.log('submit');
    setDataToLs({key:'balancer.monitor_details', data:values})
    setMonitorDetails(values)
    setStep('finally')
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      delay: yup.string().required(),
      max_retries: yup.string().required(),
      max_retries_down: yup.string().required(),
      timeout: yup.string().required(),
      type: yup.string().required(),
      expected_codes:yup.string(),
      http_method:yup.string(),
      url_path:yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  return (
    <Container>
      <Input
        fieldName='name'
        toolTip={false}
        title={t("NAME")}
        placeholder=''
        customStyles={{width:'360px', marginBottom:'14px'}}
        {...formikProps}
      />
      <Select
        required
        toolTip={false}
        data={protocols}
        selectedValue={values['type']}
        onChange={(e:any) => handleChange('type')(e)}
        title={t("TYPE")}
        customStyles={{marginBottom:'20px', width:'360px'}}
      />
      {values['type'] === 'HTTP' || values['type'] === 'HTTPS' ? 
        <>
          <Select
            toolTip={false}
            data={httpMethods}
            selectedValue={values['http_method']}
            onChange={(e:any) => handleChange('http_method')(e)}
            title={t("HTTP_METHOD")}
            customStyles={{marginBottom:'20px', width:'360px'}}
          />
          <Input
            fieldName='expected_codes'
            toolTip={false}
            title={t("EXPECTED_CODES")}
            placeholder=''
            customStyles={{width:'360px', marginBottom:'14px'}}
            {...formikProps}
          />
          <Input
            fieldName='url_path'
            toolTip={false}
            title={t("URL_PATH")}
            placeholder=''
            customStyles={{width:'360px', marginBottom:'14px'}}
            {...formikProps}
          />
        </>
      : null}
      <div style={{display:'flex', gap:'20px'}}>
        <Input
          required
          fieldName='max_retries_down'
          toolTip={false}
          title={t("MAX_RETRIES_DOWN")}
          placeholder=''
          customStyles={{width:'245px', marginBottom:'14px'}}
          {...formikProps}
        />
        <Input
          required
          fieldName='delay'
          toolTip={false}
          title={t("DELAY_SEC")}
          placeholder=''
          customStyles={{width:'245px', marginBottom:'14px'}}
          {...formikProps}
        />
      </div>
      <div style={{display:'flex', gap:'20px'}}>
        <Input
          required
          fieldName='max_retries'
          toolTip={false}
          title={t("MAX_RETRIES")}
          placeholder=''
          customStyles={{width:'245px', marginBottom:'14px'}}
          {...formikProps}
        />
        <Input
          required
          fieldName='timeout'
          toolTip={false}
          title={t("TIMEOUT_SEC")}
          placeholder=''
          customStyles={{width:'245px', marginBottom:'14px'}}
          {...formikProps}
        />
      </div>
      <Toggle value={admin} onChange={() => setAdmin(!admin)} title={t("ADMIN_STATE_UP")}/>
      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep('pool-remembers')}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default MonitorDetails