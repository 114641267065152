import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { ErrorText } from "../input/styles";
import Label from "../label";
import StyledIcon from "../styled-icon";
import {Container, CounterWrapper, Input, StyledText} from './styles'

type CounterProps = {
  value:number,
  onChange:any,
  maxCount?:number,
  minCount?:number,
  title?:any,
  customStyles?:any,
  hideItems?:boolean|string,
  fieldName?:any,
  errors?:any,
  touched?:any,
  tooltipText?:string,
  toolTip?:boolean,
  required?:boolean,
  arrayName?:any,
  arrayIndex?:any,
  arrayFieldName?:any,
  arrayFields?:boolean,
}

const Counter:FC<CounterProps> = (props) => {
  const {
    value=0,
    minCount=0,
    onChange,
    maxCount=Infinity,
    title,
    customStyles,
    hideItems,
    fieldName,
    errors,
    touched,
    tooltipText,
    toolTip=false,
    required,
    arrayName,
    arrayIndex,
    arrayFieldName,
    arrayFields
  } = props
  const fieldError = arrayFields ? errors?.[arrayName]?.[arrayIndex]?.[arrayFieldName] : errors?.[fieldName];
  const fieldTouched = arrayFields ? touched?.[arrayName]?.[arrayIndex]?.[arrayFieldName] : touched?.[fieldName];
  const [t] = useTranslation()
  return(
    <Container customStyles={customStyles}>
      {/* <Label title={title || ''} tooltipText="" margin='' /> */}
      <Label required={required} tooltipText={tooltipText} toolTip={toolTip} title={title}/>
      <CounterWrapper>
        <StyledIcon disable={value <= minCount} onClick={() => {
          if(value == minCount){
            return
          } else {
            onChange(+value-1)
          }
          }} src={icons.MinusDefault}/>
        <Input
          name={fieldName}
          min={minCount}
          type='number'
          value={value}
          onChange={(e) => {
            if (e.target.value.toString().length <= 5){
              onChange(e.target.value)
            }
          }}
          />
        {hideItems ? typeof hideItems === 'boolean' ? null : <StyledText>{hideItems}</StyledText> : <StyledText>{t("ITEMS")}</StyledText>}
        <StyledIcon onClick={() => maxCount == value ? null : onChange(+value + 1)} src={icons.PlusDefault}/>
      </CounterWrapper>
      <ErrorText> 
        {fieldTouched && fieldError}
      </ErrorText>
    </Container>
  )
}

export default Counter