import styled from "styled-components";


export const Container = styled.div`
  /* display: flex; */
  flex-direction: column;
  /* margin-right:10px; */

  padding: 25px 10px 25px 0px;
`
export const ButtonsWrapper = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  &>div{
    display:flex;
    flex-direction: row;
  }
`