import React, { FC, useEffect, useState } from "react";
import {Container, DarkForm, HideContainer} from './styles'
import {
  Button,
  Input,
  Select,
  Toggle,
  RadioButtonGroup,
  DonutArea,
  Counter,
  InfoBlock,
  BackArrow,
  TopProgress,
  
} from "../../../components";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from 'use-query-params';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useBalancer } from "../../../context/balancerContext";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { InfoContainer, InfoTitle } from "../../../components/info-block/styles";
import { icons } from "../../../icons";
import { useInstance } from "../../../context/instanceContext";
import { useKubernetes } from "../../../context/kubernetesContext";
import { TitleBig } from "../create-cluster/styles";
import { kubernetesService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { useAuth } from "../../../context/user.context";
import { ErrorContainer, ErrorText } from "../../Instances/components/create-instance/configure/styles";
import ErrorIc from './ic-error.svg'
import { randomNameGenerator } from "../../../utils/create-random-name";
const effects = [
  {value:'NoSchedule', label:'NoSchedule'},
  {value:'PreferNoSchedule', label:'PreferNoSchedule'},
  {value:'NoExecute', label:'NoExecute'}
]

const CreateNodePool:FC<any> = () => {
  const [t] = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const {quotas} = useAuth()
  const {
    flavors,
    pageLoading,
    getCreateOptions
  } = useInstance()
  const {setDataToLs, getDataToLs} = useLocalStorage()
  const {clusterId, poolId} = useParams()
  const [nodePool, setNodePool] = useState<any>()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [taints, setTaints] = useState<any[]>(getDataToLs({key:'cluster.configure_pools'})?.taints || [])
  const [labels, setLabels] = useState<any[]>(getDataToLs({key:'cluster.configure_pools'})?.labels || [])
  const [pools, setPools] = useState<any[]>([])
  const [autoscale, setAutoscale] = useState<boolean>(false)
  const [pageLoadingLocal, setPageLoadingLocal] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [typeValid, setTypeValid] = useState<any>()
  const [clusterData, setClusterData] = useState<any>({})
  const [initialValues, setInitialValues ] = useState<any>({
    name: randomNameGenerator({name:'nodepool', findArr:[]}),
    flavor_id: flavors.filter((flav:any) => flav.memory_gb >= 8)[0]?.value,
    count: 1,
    max_count:3,
    labels:[],
    taints:[],
  })

  const {viewAlert} = useUi()
  const onSubmit = (values:any) => {
    setLoading(true)
    // if(poolId) {
    //   kubernetesService.editNodePool(poolId, values)
    //   .then((res) => {
    //     setLoadingComplete(true)
    //     setTimeout(() => {
    //       setLoadingComplete(false)
    //       setLoading(false)
    //       navigate(`/kubernetes/${clusterId}`)
    //     },1500)
    //   })
    //   .catch((err) => {
    //     console.log('err', err?.response);
    //     viewAlert({
    //       severity:'error',
    //       message: err?.response?.data?.detail || t("ERROR_OCCURRED")
    //     })
    //     setLoading(false)
    //   })
    // } else {
      const data = {
        ...values,
        name:`${clusterData?.cluster_name}-${values.name}`,
        cluster:clusterId,
        is_default: false,
        autoscale:autoscale,
      }
      kubernetesService.createNodePool(data)
      .then((res) => {
        setLoadingComplete(true)
        setTimeout(() => {
          setLoadingComplete(false)
          setLoading(false)
          navigate(`/kubernetes/${clusterId}`)
        },1500)
      })
      .catch((err) => {
        console.log('err', err?.response);
        setErrors(err?.response?.data)
        viewAlert({
          severity:'error',
          message: err?.response?.data?.detail || t("ERROR_OCCURRED")
        })
        setLoading(false)
      })
    // }

    //   setPools((prev:any) => [...prev, values])
    //   setTaints([])
    //   setLabels([])
    //   setInitialValues({
    //     name: '',
    //     flavor_id: '',
    //     count: 1,
    //     labels:[],
    //     taints:[],
    //   })
    //   resetForm()
  }
  const maxCountInstances = (quotas:any, value?:any) => {
    const flavorObj = flavors.find((flav:any) => flav.id === typeValid)
    if(!flavorObj){
      return quotas?.core?.instances?.limit - quotas?.core?.instances?.in_use
    }
    let maxCores = (quotas?.core?.cores?.limit - quotas?.core?.cores?.in_use) / flavorObj?.vcpus
    let maxRam = (quotas?.core?.ram?.limit - quotas?.core?.ram?.in_use) / flavorObj?.memory_mb
    let maxInstances = quotas?.core?.instances?.limit - quotas?.core?.instances?.in_use
    return Math.min(Math.floor(maxCores), Math.floor(maxRam), Math.floor(maxInstances) )
  }

  const regName = /^[a-z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setErrors,
    resetForm,
    handleChange,
    handleBlur,
    isValid
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().matches(regName,`${t("ONLY_ENGLISH_LETTERS_SMALL")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`).required(`${t('REQUIRED_FIELD')}`),
      flavor_id: yup.string().required(`${t('REQUIRED_FIELD')}`),
      count:yup.number().required(`${t('REQUIRED_FIELD')}`)
        .positive()
        .integer()
        .min(1, `${t("COUNT_MUST_BE_GREATER_OR_EQUAL")} 1`)
        .max(maxCountInstances(quotas), `${t("MAX_COUNT_MACHINES")} ${maxCountInstances(quotas)}`),
      max_count: yup.number().required(`${t('REQUIRED_FIELD')}`)
        .positive()
        .integer()
        .min(1, `${t("COUNT_MUST_BE_GREATER_OR_EQUAL")} 1`)
        // .max(maxCountInstances(quotas), `${t("MAX_COUNT_MACHINES")} ${maxCountInstances(quotas)}`)
        .when("autoscale", {
          is: (autoscale:any, max_count:any) => autoscale,
          then: yup.number().required(`${t('REQUIRED_FIELD')}`)
          .positive()
          .integer()
          .min(1, `${t("COUNT_MUST_BE_GREATER_OR_EQUAL")} 1`)
          .moreThan(yup.ref('count'), `${t("COUNT_MUST_BE_GREATER")} ${t("MIN_NODE_COUNT")}`)
          .max(maxCountInstances(quotas), `${t("MAX_COUNT_MACHINES")} ${maxCountInstances(quotas)}`),
        }),
      labels: yup.array().of(
        yup.object().shape({
          key: yup.string().required(`${t('REQUIRED_FIELD')}`),
          value: yup.string().required(`${t('REQUIRED_FIELD')}`),
        })
      ),
      taints: yup.array().of(
        yup.object().shape({
          effect: yup.string().required(`${t('REQUIRED_FIELD')}`),
          key: yup.string().required(`${t('REQUIRED_FIELD')}`),
          value: yup.string().required(`${t('REQUIRED_FIELD')}`),
        })
      ),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });


  useEffect(() => {
    setTypeValid(values['flavor_id'])
  },[values['flavor_id']])
  const defaultDataDisk = [
    {
      part1:10,
      part2:32,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_READING"),
      value:50,
      valueMax:160
    },
    {
      part1:4,
      part2:10.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_WRITING"),
      value:20,
      valueMax:54
    }
  ]
  const defaultOperationDisk = [
    {
      part1:4.4,
      part2:22.2,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_IOPS_READING"),
      value:2000,
      valueMax:10000
    },
    {
      part1:1.5,
      part2:28.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_IOPS_WRITING"),
      value:700,
      valueMax:13000
    }
  ]
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const getNodePool = () => {
    setPageLoadingLocal(true)
    getCreateOptions()
    if(poolId){
      kubernetesService.getNodePool(poolId)
      .then((res) => {
        setNodePool(res?.data)
        // setPageLoadingLocal(false)
        setInitialValues({
          name: res?.data?.name ||'',
          flavor_id: res?.data?.flavor_id ||'',
          count: res?.data?.count ||  1,
          // labels:res?.data?.labels ||[],
          // taints:res?.data?.taints ||[],
        })
      })
      .catch((err) => {
        console.log('err', err?.response);
        viewAlert({
          severity:'error',
          message: err?.response?.data?.detail || t("ERROR_OCCURRED")
        })
      })
    } 
  }
  const getCluster = () => {
    setPageLoadingLocal(true)
    getCreateOptions()
    if(clusterId){
      kubernetesService.getClusterById(clusterId)
      .then((res) => {
        setClusterData(res?.data)
        setPageLoadingLocal(false)

      })
      .catch((err) => {
        console.log('err', err?.response);
        viewAlert({
          severity:'error',
          message: err?.response?.data?.detail || t("ERROR_OCCURRED")
        })
      })
    } else {
      setTimeout(() => {
        setPageLoadingLocal(false)
      },1000)
    }
  }
  useEffect(() => {
    getCluster()
    getNodePool()
  },[])
  const handleInputChange = (index:any, field:any, value:any, data:any[], setData:(data:any[]) => void, yupField:any) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setFieldValue(yupField, updatedData);
  };
  const addNewItemLabel = () => {
    setLabels((prev:any) => {
      setFieldValue('labels', [...prev, 
        {
          key: "",
          value: "",
        }
      ]);
      return [...prev, 
        {
          key: "",
          value: "",
        }
      ]
    })
  };
  const addNewItemTaint = () => {
    setTaints((prev:any) => {
      setFieldValue('taints', [...prev, 
        {
          effect: 'NoSchedule',
          key: "",
          value: "",
        }
      ]);
      return [...prev, 
        {
          effect: 'NoSchedule',
          key: "",
          value: "",
        }
      ]
    })
  };
  const removeItem = (index:any, data:any[], setData:(data:any[]) => void, yupField:any) => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
    setFieldValue(yupField, updatedData);
  };
  const isEdit = !location.pathname.includes('create') && nodePool
  if(pageLoading || pageLoadingLocal) return <TopProgress loading={pageLoading || pageLoadingLocal}/>
  return (
    <Container>
      <BackArrow
          onClick={() => navigate(-1)}
          title={t("BACK_TO_CLUSTER")}
        />
        <TitleBig style={{margin:'0px'}}>{isEdit ? t("EDIT_NODE_POOL") + ' ' + (nodePool?.name || nodePool?.id) : t("CREATE_A_NEW_NODE_POOL")}</TitleBig>
      <Input
        fieldName='name'
        toolTip={false}
        title={t("NAME_NODE_POOL")}
        prefix={clusterData?.cluster_name}
        placeholder=''
        customStyles={{width:'360px'}}
        {...formikProps}
      />
      <Select
        toolTip={false}
        data={flavors.filter((flav:any) => flav.memory_gb >= 8)}
        disabled={flavors?.length <= 0}
        selectedValue={values['flavor_id']}
        onChange={(e:any) => handleChange('flavor_id')(e)}
        placeholder={flavors?.length <= 0 ? t("NO_FLAVORS_AVAILABLE") : t("CHOOSE")}
        title={t("TYPE_NODE_POOL")}
        customStyles={{width:'360px'}}
        fieldName='flavor_id'
        {...formikProps}
      />
      {/* <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
        <RadioButtonGroup
          toolTip={false}
          title={t("DISK_TYPE")}
          buttons={[]}
          selectedButton={values['type']}
          onChange={(e) => handleChange('type')(e)}
        />
      </div>
      <div style={{display:'flex',flexWrap:'wrap', gap:'20px'}}>
        <DonutArea
          title={t("BANDWIDTH")}
          subtitle={t("MB_S")}
          isLeft
          data={defaultDataDisk}
        />
        <DonutArea
          title={t("OPERATIONS_PER_SEC")}
          subtitle={t("IOPS")}
          data={defaultOperationDisk}
        />
      </div> */}
      <HideContainer active={!autoscale}>
        <Counter
          // customStyles={{marginBottom:"20px"}}
          // toolTip={true}
          // tooltipText={`${t("NUMBER_OF_WORKER_NODES")}`}
          title={t("NODE_COUNT")}
          value={values['count']}
          minCount={1}
          hideItems={`${t("ITEMS")}`}
          onChange={(v:any) => {
            handleChange('count')(v.toString())
          }}
          fieldName='count'
          {...formikProps}
        />
      </HideContainer>
      {/* <Counter
        // customStyles={{marginBottom:"20px"}}
        toolTip={true}
        tooltipText={`${t("STORAGE_SIZE_TOOLTIP")}`}
        title={t("STORAGE")}
        value={values['size']}
        minCount={1}
        hideItems={'GB'}
        onChange={(v:any) => {
          handleChange('size')(v.toString())
        }}
        fieldName='size'
        {...formikProps}
      /> */}
      <Toggle
        title={t("AUTOSCALE")}
        value={autoscale}
        onChange={() => setAutoscale(!autoscale)}
      />
      <HideContainer active={autoscale}>
        <Counter
          // customStyles={{marginBottom:"20px"}}
          // toolTip={true}
          // tooltipText={`${t("NUMBER_OF_WORKER_NODES")}`}
          title={t("MIN_NODE_COUNT")}
          value={values['count']}
          minCount={1}
          hideItems={`${t("ITEMS")}`}
          onChange={(v:any) => {
            handleChange('count')(v.toString())
          }}
          fieldName='count'
          {...formikProps}
        />
        <Counter
          // customStyles={{marginBottom:"20px"}}
          // toolTip={true}
          // tooltipText={`${t("NUMBER_OF_WORKER_NODES")}`}
          title={t("MAX_NODE_COUNT")}
          value={values['max_count']}
          minCount={1}
          hideItems={`${t("ITEMS")}`}
          onChange={(v:any) => {
            handleChange('max_count')(v.toString())
          }}
          fieldName='max_count'
          {...formikProps}
        />
      </HideContainer>
      <>
        <InfoContainer customStyles={{width:'100%', alignItems:"center"}}>
          <InfoTitle>
            {t("LABELS")}
          </InfoTitle>
          <Button
            variant="stroke"
            size="display"
            icon={icons.PlusDefault}
            customStyles={{marginTop:"10px"}}
            //@ts-ignore
            onClick={addNewItemLabel}
            title={t("ADD_LABEL")}
          />
          {labels?.map((item:any, index:any) => (
            <DarkForm>
              <Input
                fieldName={`labels[${index}].key`}
                required
                toolTip={false}
                title={t("KEY")}
                placeholder=''
                value={item.key}
                customStyles={{width:'100%', flex:'auto 1'}}
                {...formikProps}
                handleChange={(e:any) => handleInputChange(index, 'key', e.target.value, labels, setLabels, 'labels')}
                arrayFields={true}
                arrayName={'labels'}
                arrayIndex={index}
                arrayFieldName={'key'}
              />
              <Input
                fieldName={`labels[${index}].value`}
                required
                toolTip={false}
                title={t("VALUE")}
                placeholder=''
                value={item.value}
                customStyles={{width:'100%', flex:'auto 1'}}
                {...formikProps}
                handleChange={(e:any) => handleInputChange(index, 'value', e.target.value, labels, setLabels, 'labels')}
                arrayFields={true}
                arrayName={'labels'}
                arrayIndex={index}
                arrayFieldName={'value'}
              />
              <Button
                onClick={() => removeItem(index, labels, setLabels, 'labels')}
                variant='icon'
                size='display'
                // disabled
                customStyles={{marginTop:'24px'}}
              >
                <img src={icons.DeleteIcon}/>
              </Button>
            </DarkForm>
          ))}
        </InfoContainer>
        <InfoContainer>
          <InfoTitle>
            {t("TAINTS")}
          </InfoTitle>
          <Button
            variant="stroke"
            size="display"
            icon={icons.PlusDefault}
            customStyles={{marginTop:"10px"}}
            //@ts-ignore
            onClick={addNewItemTaint}
            title={t("ADD_TAINT")}
          />
          {taints?.map((item:any, index:any) => (
            <DarkForm>
              <Input
                fieldName={`taints[${index}].key`}
                required
                toolTip={false}
                title={t("KEY")}
                placeholder=''
                value={item.key}
                customStyles={{width:'241px', flex:'auto 1'}}
                {...formikProps}
                handleChange={(e:any) => handleInputChange(index, 'key', e.target.value, taints, setTaints, 'taints')}
                arrayFields={true}
                arrayName={'taints'}
                arrayIndex={index}
                arrayFieldName={'key'}
              />
              <Input
                fieldName={`taints[${index}].value`}
                required
                toolTip={false}
                title={t("VALUE")}
                placeholder=''
                value={item.value}
                customStyles={{width:'241px', flex:'auto 1'}}
                {...formikProps}
                handleChange={(e:any) => handleInputChange(index, 'value', e.target.value, taints, setTaints, 'taints')}
                arrayFields={true}
                arrayName={'taints'}
                arrayIndex={index}
                arrayFieldName={'value'}
              />
              <Select
                toolTip={false}
                required
                data={effects}
                selectedValue={item.effect || 'NoSchedule'}
                // onChange={() => {}}
                title={t("EFFECT")}
                customStyles={{width:'241px', maxWidth:'360px', flex:'auto 1'}}
                fieldName={`taints[${index}].effect`}
                onChange={(e:any) => handleInputChange(index, 'effect', e, taints, setTaints, 'taints')}
                // arrayIndex={index}
                // arrayFieldName={'protocol_port'}
              />
              <Button
                onClick={() => removeItem(index, taints, setTaints, 'taints')}
                variant='icon'
                size='display'
                // disabled
                customStyles={{marginTop:'24px'}}
              >
                <img src={icons.DeleteIcon}/>
              </Button>
            </DarkForm>
          ))}
        </InfoContainer>
      </>
      {maxCountInstances(quotas) < (autoscale ? values['max_count'] : values['count']) &&
        <ErrorContainer isError={true}>
          <img src={ErrorIc}/>
          <ErrorText>{t("ERROR_POOL_CREATE_QUOTAS")}</ErrorText>
        </ErrorContainer>
      }
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          loading={loading}
          disabled={maxCountInstances(quotas) < (autoscale ? values['max_count'] : values['count'])}
          loadingComplete={loadingComplete}
          onClick={() => {
            handleSubmit()
          }}
          title={isEdit ? t("EDIT") : t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate(-1)}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default CreateNodePool