import styled from 'styled-components'
import SVG from "react-inlinesvg";
type ActiveProp = {
  active?:boolean,
  disable?:boolean,
  hover?:boolean,
  toChangeColor?:boolean,
}
const StyledIcon = styled(SVG)<ActiveProp>`
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  opacity: ${({hover, disable}) => hover || disable ? '0.7' : ''};
  filter: ${({active, disable}) => active && !disable ? 'brightness(77%)' : ''};
  & path {
    fill:${({theme, toChangeColor}) => toChangeColor ? theme.palette[800] : null};
  };
  &:hover {
    cursor: ${({disable}) => disable ? '' : 'pointer'};
    opacity: ${({hover}) => hover ? '' : '0.7'};
  }
  &:active{
    filter: ${({disable}) => !disable ? 'brightness(77%)' : ''};
  }
`

export default StyledIcon