import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { Button, ModalVariables, StartPage, TopProgress } from "../../components";
import Illustration from './illustration.svg'
import IllustrationDark from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { sshService } from "../../api";
import { useUi } from "../../context/ui.context";
import {timeConverterMMDDYYHHMM} from '../../utils/dates'
import { useAuth } from "../../context/user.context";
import rootConfig from "../../config";
import { compareArrays } from "../../utils/findIntersection";

const SSHKeys:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user} = useAuth()
  const [keys, setKeys] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [deletedKey, setSeletedKey] = useState<any>()
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [paginationData, setPaginationData] = useState<any>()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(keys, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const updateKeys = (params?:any) => {
    setLoading(true)
    sshService.getSshKeys(params.pageSize, params.sort[1] ? `${params.sort[0]}` : `-${params.sort[0]}`)
    .then((res) => {
      setPaginationData(res.data)
      setKeys(res.data.objects.map((key:any) => {
        return {
          ...key,
          name:key.name,
          fingerPrint:key.fingerprint,
          created_at: timeConverterMMDDYYHHMM(key.created_at),
          id:key.id
        }
      }))
    })
    .catch((e) => {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
    })
    .finally(() => {
      setPageLoading(false)
      setLoading(false)
    })
  }
  useEffect(() => {
    updateKeys({pageSize:10, sort:[]})
  },[])
  const items = [
    {
      title:t('EDIT'),
      id:'2',
      icon:icons.Edit,
      onClick: (id:any) => navigate(`edit/${id.id}`),
      divider:false
    },
    {
      title:t('DELETE'),
      id:'4',
      onClick:(id:any) => {
        openModal('delete')
        setAlertTitle(t("SSH_KEY"))
        setAlertSubTitle([id])
        setSeletedKey(id)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]

  const _deleteSsh = async () => {
    setPageLoading(true)
    if(allChecked) {
      let promises:any[] = []
      await keys.map(async(item) => {
        let promise = sshService.deleteSsh(item.id)
        promises.push(promise)
      })
      await Promise.all(promises)
      .then((res) => {
        updateKeys({pageSize:10, sort:[]})
        setAllChecked(false)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
    } else if (checked?.length > 0){
      let promises:any[] = []
      await checked.map(async(item) => {
        let promise = sshService.deleteSsh(item.id)
        promises.push(promise)
      })
      await Promise.all(promises)
      .then((res) => {
        updateKeys({pageSize:10, sort:[]})
        setChecked([])
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
    } else if (deletedKey) {
      sshService.deleteSsh(deletedKey.id)
      .then((res) => {
        updateKeys({pageSize:10, sort:[]})
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
    }
  }
  const closeModal = () => {
    _deleteSsh()
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const Columns = [
    {
      title:t("NAME"),
      key:'name',
      sorted:true
    },
    {
      title:t("FINGERPRINT"),
      key:'fingerPrint',
      sorted:false
    },
    {
      title:t("CREATED_AT"),
      key:'created_at',
      sorted:true
    },
    {
      title:'',
      key:'props',
      sorted:false
    },
  ]
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else {
      navigate('create')
    }
  }
  
  return (
    <>
      <TopProgress loading={loading}/>
      <StartPage
        title={`${t("SSH_KEYS")}`}
        noResourceText={`${t("YOU_HAVE_NO_SSH")}`}
        createResourceButton={`${t('CREATE_SSH')}`}
        createResourceButtonEventOrLink={_createFunc}
        howCreateVideoUrl="https://www.youtube.com/embed/n0mEex_ri9U"
        haveResource={keys?.length > 0}
        rightButtonComponentCustom={
          <Button
            onClick={() => navigate('import')}
            title={t("IMPORT")}
            variant='stroke'
            size='display'
            icon={icons.Upload}
            // disabled
            customStyles={{margin:'0px 6px 0px 10px'}}
          >
            <img src={icons.DeleteIcon}/>
          </Button>
        }
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => navigate('import')}
              title={t("IMPORT")}
              variant='stroke'
              size='display'
              icon={icons.Upload}
              // disabled
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
            <Button
              onClick={() => {
                setAlertTitle(t("SSH_KEYS"))
                setAlertSubTitle(allChecked ? keys : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        learnMoreAboutResource={`${t('LEARN_MORE_SSH')}`}
        howCreateVideoText={`${t('HOW_CREATE_SSH')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/ssh-keys`}
        videoImage={Illustration}
        videoImageDark={IllustrationDark}
        pageLoading={pageLoading}
        tableData={{
          columns:Columns,
          data:keys,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          paginationData:paginationData,
          updateData:updateKeys
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
      />
    </>
  )
}


export default SSHKeys

