import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { Button, ModalVariables, StartPage} from "../../components";
import Illustration from './illustration.svg'
import IllustrationDark from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { useAuth } from "../../context/user.context";
import { useUi } from "../../context/ui.context";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { loadBalancerService } from "../../api";
import { GreenDot, StateWrapper, Greentext } from "../Instances/styles";
import { handleStatusName } from "../../utils/handle-status-name";
import rootConfig from "../../config";
import { compareArrays } from "../../utils/findIntersection";


const LoadBalancers:FC = () => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)

  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [paginationData, setPaginationData] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [modalType, setModalType] = useState<any>('')
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [instanceId, setInstanceId] = useState<any>()
  const [checked, setChecked] = useState<any[]>([])
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [loadBalancers, setLoadBalancers] = useState<any[]>([])
  const [deletedBalancer, setDeletedBalancer] = useState<any>()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(loadBalancers, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {
    setViewModalComplete,
    setActiveAnimationComplete,
    viewAlert,
  } = useUi()
  const {user} = useAuth()
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else {
      navigate('create')
    }
  }
  const _updateLoadBalancers = () => {
    loadBalancerService.getList({
      page:page||1,
      ordering,
      page_size:pageSize||10,
      active_client:user?.user?.clients?.[0]?.id,
    })
    .then((res:any) => {
      setPaginationData(res.data)
      setLoadBalancers(res.data.objects.map((balancer:any) => {
        return{
          ...balancer,
          navigate:balancer.id,
          name:balancer.name || balancer.id,
          status: 
            (<StateWrapper>
              <GreenDot active={balancer.operating_status}/>
              <Greentext active={balancer.operating_status}>
                {handleStatusName(balancer.operating_status, t)}
              </Greentext>
            </StateWrapper>) ,
          provisioning_status: 
            (<StateWrapper>
              <GreenDot active={balancer.provisioning_status}/>
              <Greentext active={balancer.provisioning_status}>
                {handleStatusName(balancer.provisioning_status, t)}
              </Greentext>
            </StateWrapper>),
        }
      }))
    })
    .catch((e) => {
      console.log('e',e);
    })
    .finally(() => {
      setPageLoading(false)
      // setLoading(false)
    })
  }
  const _deleteBalancers = async () => {
    // setPageLoading(true)
    if(allChecked) {
      let promises:any[] = []
      await loadBalancers.map(async(item) => {
        let promise = loadBalancerService.deleteBalancer(item.id)
        promises.push(promise)
      })
      await Promise.all(promises)
      .then((res) => {
        _updateLoadBalancers()
        setAllChecked(false)
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    } else if (checked?.length > 0){
      let promises:any[] = []
      await checked.map(async(item) => {
        let promise = loadBalancerService.deleteBalancer(item.id)
        promises.push(promise)
      })
      await Promise.all(promises)
      .then((res) => {
        _updateLoadBalancers()
        setChecked([])
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    } else if (deletedBalancer) {
      loadBalancerService.deleteBalancer(deletedBalancer.id)
      .then((res) => {
        _updateLoadBalancers()
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(modalType === 'delete'){
      setLoading(true);
      _deleteBalancers()
    } else if (modalType === 'add-ip-to-balancer') {
      setLoading(true);
      loadBalancerService.associateFloatingIp(instanceId.id, values)
      .then((res) => {
        _updateLoadBalancers()
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    } else if (modalType === 'universal'){
      setLoading(true);
      if (alertTitle === `${t('DISSOCIATE_IP_TITLE')}`) {
        loadBalancerService.dissociateFloatingIp(instanceId)
        .then((res) => {
          _updateLoadBalancers()
          setViewModal(false)
          setActiveAnimation(false)
        })
        .catch((e) => {
          if(e?.response?.data.detail){
            viewAlert({
              severity:'error',
              message:e.response.data.detail,
            })
          }
        })
        .finally(() => {
          setLoading(false)
        })
      }
    } else {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }

  useEffect(() => {
    _updateLoadBalancers()
    const update = setInterval(() => {
      _updateLoadBalancers()
    },6000)
    return () => {
      clearInterval(update)
    }
  },[page, ordering, pageSize])
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("OPERATING_STATUS"),
      key:'status'
    },
    {
      title:t("IP_ADDRESS"),
      key:'vip_address'
    },
    {
      title:t("PROVISIONING_STATUS"),
      key:'provisioning_status'
    },
    // {
    //   title:t("GATEWAYS"),
    //   key:'gateways'
    // },
    {
      title:'',
      key:'props'
    },
  ]
  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (id:any) => navigate(`edit/${id.id}`),
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(id:any) => {
        openModal('delete')
        setAlertTitle(t("LOAD_BALANCER"))
        setAlertSubTitle([id])
        setDeletedBalancer(id)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  return (
    <>
      <StartPage
        title={`${t("LOAD_BALANCERS")}`}
        noResourceText={`${t("YOU_HAVE_NO_LOAD_BALANCERS")}`}
        createResourceButton={`${t('CREATE_LOAD_BALANCER')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={loadBalancers.length > 0}
        pageLoading={pageLoading}
        learnMoreAboutResource={`${t('LEARN_MORE_LOAD_BALANCERS')}`}
        howCreateVideoText={`${t('HOW_CREATE_LOAD_BALANCER')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/load-balancers`}
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("LOAD_BALANCERS"))
                setAlertSubTitle(allChecked ? loadBalancers : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked && checked?.length === 0}
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        videoImage={Illustration}
        videoImageDark={IllustrationDark}
        tableData={{
          columns:Columns,
          data:loadBalancers,
          paginationData:paginationData,
          allChecked:allChecked,
          props:items,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          updateData:_updateLoadBalancers,
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={instance}
        instanceId={instanceId}
        // instance={instance}
        loading={loading}
      />
    </>
  )
}


export default LoadBalancers


