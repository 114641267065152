import styled from "styled-components";
import { BodyDefault, HeadLine3, VerySmall } from "../../typography/styled";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 20px;
  box-sizing: border-box;
  width: 400px;
  /* height: 350px; */
  left: 540px;
  top: 305px;
  background: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  padding: 10px 10px 20px;
  width: 360px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette[10]};
  border-radius: 8px;
`

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  word-break: break-all;
  align-items: flex-start;
  width: 100%;
`
export const BodyModal = styled.div`
  /* display:flex; */
  flex-direction: column;
  align-items: center;
  padding:0px 10px;
`

export const Icon = styled.img`
  &:hover{
    cursor: pointer;
  }
`

export const InstanceWrapper = styled.div`
  background-color: ${({theme}) =>  theme.palette[200]};
  display:flex;
  padding: 5px 8px;
  border-radius: 4px;
`

export const Title = styled(BodyDefault)`
  color:${({theme}) => theme.isDark ? theme.palette[800] : theme.palette[900]};
  white-space: nowrap;
`

export const StyledIconColored = styled.img`
  margin-right:16px;
  width: 20px;
  height: 20px;
`

export const EstimateContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction:column;
  padding: 15px;
  background: ${({theme}) => theme.palette[200]};
  border-radius: 8px;
  margin-top: 20px;
`

export const EstimateTitle = styled(HeadLine3)`
  color:${({theme}) => theme.palette[850]};
`
export const EstimateSum = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
`
export const PerHour = styled(VerySmall)`
  color:${({theme}) => theme.palette[650]};
  margin-left:2px;
  margin-bottom:2px;
`
