import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, Tabs, TopProgress } from "../../../components";
import { icons } from "../../../icons";
import {
  Container,
  HeadWrapper,
  InstanceName,
  NavWrapper,
} from './styles'
import {
  Interfaces,
  General,
} from './components'
import { routerService, networkService } from "../../../api";
import { useUi } from "../../../context/ui.context";

const RouterPage:FC = () => {
  const navigate = useNavigate()
  const {idRouter} = useParams()
  const [t] = useTranslation()
  const [router, setRouter] = useState<any>()
  const [interfaces, setInterfaces] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [networks, setNetworks] = useState<any>()
  const {viewAlert} = useUi()
  const updateRouter = () => {
    // setPageLoading(true)
    if(idRouter){
      routerService.getSingleRouter(idRouter)
      .then(async(res) => {
        setRouter(res.data)
        if(res.data.interfaces.length > 0) {
          let promises:any[] = []
          res.data.interfaces.map((intfc:any) => {
            let promise = networkService.getSubnet(intfc.fixed_ips?.[0]?.subnet_id)
            promises.push(promise)
          })
          await Promise.all(promises)
          .then((subs:any) => {
            setInterfaces(subs.map((r:any) => {
              return {
                ...r.data,
                name:res.data.interfaces.find((s:any) => r.data.id === s.fixed_ips?.[0]?.subnet_id).id,
                id:res.data.interfaces.find((s:any) => r.data.id === s.fixed_ips?.[0]?.subnet_id).id
              }
            }))
            setNetworks(subs.map((net:any) => {
              return {
                networkName:net.data.network_name,
                subnets:subs.filter((item:any) => item.data.network_name === net.data.network_name).map((item:any) => item.data)
              }
            }))
            setPageLoading(false)
          })
          .catch((e) => {
            if(e?.response?.data?.detail){
              viewAlert({
                severity:'error',
                message:e?.response?.data?.detail
              })
            }
          })
        } else {
          setPageLoading(false)
          setInterfaces([])
        }

      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
      })
    }
  }
  useEffect(() => {
    updateRouter()
    const interval = setInterval(() => {
      updateRouter()
    },5000)
    return () => clearInterval(interval)
  },[])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("GENERAL_INFORMATION"),
      slug:'general',
      id:'1'
    },
    {
      title:t("INTERFACES"),
      slug:'interfaces',
      id:'2'
    },
  ])
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow onClick={() => navigate('/routers')} title={t("BACK_TO_ROUTERS")}/>
      <HeadWrapper>
        <InstanceName>
          {router.name}
        </InstanceName>
      </HeadWrapper>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="general" />} /> 
        <Route
          path='/general'
          element={
            <General router={router} networks={networks}/>
          }/>
        <Route path='/interfaces' element={
          <Interfaces
            interfaces={interfaces}
            updateFunc={updateRouter}
          />
        }/>
      </Routes>
    </Container>
  )
}

export default RouterPage
