import React, {FC, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { authService, billingService } from '../../../../api';
import { BackArrow, Button, InfoBlock, TopProgress, Typography, Select, Table } from '../../../../components';
import { InfoContainer, InfoTitle } from '../../../../components/info-block/styles';
import { useUi } from '../../../../context/ui.context';
import { icons } from '../../../../icons';
import { timeConverterMMDDYYHHMM } from '../../../../utils/dates';
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InvoiceName,
  RowBodyWrapper,
  ColumnBodyWrapper,
  PaymentMethodContainer,
  ItemTitle,
  Divider,
  PriceTotalContainer,
  PriceTotalWrapper,
  PriceWrapper,
  InvoiceStatusContainer,
  InvoiceStatusText,
  LeftSide,
} from './styles';
import ResourceItem from './resourceItem';
import { formatCurrency } from '../../../../utils/display-price';
import InvoiceSkeleton from './skeleton'
import config from '../../../../config';
import { handleStatusName } from '../../../../utils/handle-status-name';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {Helmet} from "react-helmet";
import { useAuth } from '../../../../context/user.context';


const InvoicPage:FC = () => {
  const [invoice, setInvoice] = useState<any>({})
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [paymentMethods, setPaymentMethods] = useState<any[]>([])
  const [journal, setJournal] = useState<any[]>([])
  const [items, setItems] = useState<any[]>([])
  const {invoiceId} = useParams()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const getInvoice = () => {
    if(invoiceId){
      
      billingService.getInvoice(invoiceId)
      .then((res:any) => {
        setInvoice(res.data)
        if(res.data?.payment_options?.gateways){
          setPaymentMethods([{value:'credit_balance', label:t("CREDIT_BALANCE")}, ...res.data.payment_options.gateways.map((item:any) => {
            return {
              ...item,
              id:`${item.id}`,
              value:`${item.id}`,
              label:item.display_name
            }
          })])
        }
        if(res.data.items?.length > 0){
          setItems(res.data.items.map((item:any) => {
            return {
              ...item
            }
          }))
        }
        if(res.data.journal.length > 0){
          setJournal(res.data.journal.map((item:any) => {
            return {
              ...item,
              date_added:timeConverterMMDDYYHHMM(item.date_added),
              gateway_display_name:item.transaction ? item.transaction.gateway_display_name : item.destination_name,
              amount:`${item.source_amount}`
            }
          }))
        }
      })
      .catch((err:any) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message:err.response?.data?.detail
          })
        }
        navigate(-1)
      })
      .finally(() => {
        setPageLoading(false)
        setLoading(false)
      })
    }
  }
  
  useEffect(() => {
    getInvoice()
  },[])
  const downloadPdf = async () => {
    const res = await authService.generateOtpToken()
    const url = `${config.apiUrl}api/billing/invoices/${invoice.id}/download?content_type=pdf&fleio-token=${res.data.otp_token}`
    // window.open(url);
    window.location.href = url
  }
  const onSubmit = async (values:any) => {
    console.log('values', values);
    console.log('paymentMethods', paymentMethods);
    const paymentMethod = paymentMethods.find((meth:any) => meth.value === values.paymentMethod)
    try{
      if(values.paymentMethod === 'credit_balance'){
        setLoading(true)
        const paymantRes = await billingService.payFromCredit(invoice.id)
        if(paymantRes?.data?.detail){
          await viewAlert({
            severity:'info',
            message:paymantRes?.data?.detail
          })
        }
        await getInvoice()
      } 
      else if (paymentMethod.pay_method_id) {
        // this is a saved card, use it to pay without entering card data again
        // const savedCard = this.selectedGateway;
        // this.authSvc.generateOtpToken().subscribe(token => {
        //   let params = `fleio-token=${token}&invoice=${encodeURIComponent(this.object.id)}`;
        //   params += `&pay_method_id=${encodeURIComponent(savedCard.pay_method_id)}`;
        //   window.location.href = this.config.getPanelApiUrl(
        //     'billing/gateway/' + savedCard.gateway_name + '/pay_invoice?' + params,
        //   );
        // });
      } 
      else {
        if(paymentMethod?.name){
          const res = await authService.generateOtpToken()
          let params = `fleio-token=${res.data.otp_token}&invoice=${encodeURIComponent(invoice.id)}`;
          // if (this.saveCard) {
          //   params = params + '&recurring=True';
          // }
          // window.location.href 
          let url = `${config.apiUrl}api/billing/gateway/` + paymentMethod.name + '/pay_invoice?' + params
          window.open(url);
        }
      }
    } catch (err:any) {
      if(err?.response?.data?.detail){
        viewAlert({
          severity:'error',
          message:err.response?.data?.detail
        })
      } else {
        viewAlert({
          severity:'error',
          message:err
        })
      }
      setLoading(false)
    }
  }
  const ColumnsJournal = [
    {
      title:t("DATE_ADDED"),
      key:'date_added'
    },
    {
      title:t("PAYMENT_METHOD"),
      key:'gateway_display_name'
    },
    {
      title:t("DESTINATION_NAME"),
      key:'destination_name'
    },
    {
      title:t("AMOUNT"),
      key:'amount'
    },
  ]
  
  const [initialValues, setInitialValues ] = useState<any>({
    paymentMethod:'',
  })
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      paymentMethod: yup.string().required(`${t('REQUIRED_FIELD')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const {user} = useAuth()
  if(pageLoading) return <InvoiceSkeleton pageLoading={pageLoading}/>
  return (
    <Container>
      {/* <Helmet>
        <script src="https://script.tapfiliate.com/tapfiliate.js" type="text/javascript" async></script>
        <script type="text/javascript">
          {`(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');

          tap('create', '47976-36852c', { integration: "javascript" });
          tap('conversion', ${invoice.id}, ${invoice.total}, {customer_id: "${user?.user?.email}"}, 'add');`}
        </script>
      </Helmet> */}
      <BackArrow onClick={() => navigate('/billing/invoices')} title={t("BACK_TO_BILLING")}/>
      <HeadWrapper>
        <LeftSide>
          <InvoiceName>
            {invoice.name || invoice.id}
          </InvoiceName>
          <InvoiceStatusContainer status={invoice.status}>
            <InvoiceStatusText status={invoice.status}>
              {handleStatusName(invoice.status, t)}
            </InvoiceStatusText>
          </InvoiceStatusContainer>
        </LeftSide>
        <RightHeadContainer>
          <Button
            variant="stroke"
            size='small'
            icon={icons.Download}
            title={t("DOWNLOAD_PDF")}
            onClick={downloadPdf}
          />
        </RightHeadContainer>
      </HeadWrapper>
      <RowBodyWrapper>
        <ColumnBodyWrapper>
          <div style={{display:'flex', gap:'10px'}}>
            <InfoBlock
              customStyles={{ marginBottom:'0px'}}
              title={t("TIMELINE")}
              data={[
                {
                  title:t("ISSUE_DATE"),
                  id:'1',
                  value:timeConverterMMDDYYHHMM(invoice.issue_date),
                },
                {
                  title:t("DUE_DATE"),
                  id:'2',
                  value:timeConverterMMDDYYHHMM(invoice.due_date),
                },
              ]}
            />
            <InfoBlock
              customStyles={{ marginBottom:'0px'}}
              title={t("TO")}
              noTitles
              data={[
                {
                  title:'',
                  id:'1',
                  value:`${invoice.first_name} ${invoice.last_name}`,
                },
                // {
                //   title:'',
                //   id:'2',
                //   value:invoice.address1,
                // },
                // {
                //   title:'',
                //   id:'3',
                //   value:invoice.city,
                // },
                {
                  title:'',
                  id:'4',
                  value:invoice.phone,
                },
              ]}
            />
          </div>
            <InfoContainer style={{display:'flex', flex:'auto 1', marginBottom:'0px'}}>
              <InfoTitle style={{marginBottom:'10px'}}>
                {t("ITEMS_BILLING")}
              </InfoTitle>
              <InfoContainer style={{display:'flex', flex:'auto 1', marginBottom:'0px'}}>
                {items.map((item:any) => (
                  <div style={{display:'flex', flexDirection:"column", gap:'14px'}}>
                    <ItemTitle>{item.description}</ItemTitle>
                    {item.usage?.resource_types?.length > 0 &&
                      <Divider/>
                    }
                    {item.usage?.resource_types.map((resourceType:any) => (
                      <ResourceItem resourceType={resourceType} items={item.usage.resource_usages.filter((re:any) => re.resource_type.name === resourceType.name)}/>
                    ))}
                    <PriceTotalWrapper>
                      <PriceTotalContainer>
                        <PriceWrapper>
                          <Typography variant='body'>
                            {t("SUBTOTAL")}
                          </Typography>
                          <Typography variant='body-medium'>
                            {formatCurrency(invoice.subtotal, invoice.currency)}
                          </Typography>
                        </PriceWrapper>
                      </PriceTotalContainer>
                      <PriceTotalContainer>
                        <PriceWrapper>
                          <Typography variant='body'>
                              {t("TOTAL")}
                          </Typography>
                          <Typography variant='body-medium'>
                            {formatCurrency(invoice.total, invoice.currency)}
                          </Typography>
                        </PriceWrapper>
                      </PriceTotalContainer>
                    </PriceTotalWrapper>
                  </div>
                ))}
              </InfoContainer>
            </InfoContainer>
            <InfoContainer style={{display:'flex', flex:'auto 1', marginBottom:'0px'}}>
              <InfoTitle style={{marginBottom:'10px'}}>
                {t("JOURNAL")}
              </InfoTitle>
              {invoice.journal.length === 0 ?
                <Typography variant='body'>{t("NO_ENTRIES_FOUND")}</Typography>
              :
                <Table
                  columns={ColumnsJournal}
                  rows={journal}
                  hideOther
                  hideCheckbox
                  // paginationData={paginationData}
                />
              }
            </InfoContainer>
        </ColumnBodyWrapper>
        {invoice.status !== 'paid' ?
          <PaymentMethodContainer>
            <Select
              required
              title={t("SELECTED_PAYMENT_METHOD")}
              data={paymentMethods}
              fieldName='paymentMethod'
              selectedValue={values['paymentMethod']}
              onChange={(e:string) => handleChange('paymentMethod')(e)}
              {...formikProps}

            />
            <Typography variant='h3'>{t("SUMMARY")}</Typography>
            <Typography variant='body'>{formatCurrency(invoice.balance, invoice.currency)}</Typography>
            <Button
              title={t("PAY_NOW")}
              loading={loading}
              onClick={handleSubmit}
              variant={'primary'}
              size="display"
            />
          </PaymentMethodContainer>
        : null}
      </RowBodyWrapper>
    </Container>
  )
}

export default InvoicPage

