import styled from "styled-components";
import { InfoContainer } from "../../../../components/info-block/styles";
import { HeadLine1, BodyMedium, BodySmall, BodyDefault } from "../../../../components/typography/styled";

type DepartmentsProps = {
  active: boolean,
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 30px 50px 20px;
  box-sizing: border-box;
  position: relative;
  gap:10px;
  align-items: flex-start;
`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  /* margin-top: 20px; */
  margin-bottom: 20px;
`
export const InfoContainerCustom = styled(InfoContainer)<DepartmentsProps>`
  background-color: ${({active, theme}) => active ? theme.palette[200] : ''};
  box-shadow:none;
  display: flex;
  gap:4px;
  border: 1px solid ${({theme, active}) => active ? theme.primary[300] : theme.grey[350]};
  &:hover{
    cursor: pointer;
    background-color: ${({theme}) => theme.palette[200]};
    border: 1px solid ${({theme}) => theme.palette[200]};
  }
  &:active{
    background-color: ${({theme}) => theme.palette[300]};
    border: 1px solid ${({theme}) => theme.palette[300]};
  }
`
export const TutorTitle = styled(BodyMedium)`
  color:${({theme}) => theme.primary[600]};
`

export const TutorContent = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
`