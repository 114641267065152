import React, { FC, useEffect, useState, useMemo } from "react";
import { Table, Typography } from "../../../../components";
import { formatCurrency } from "../../../../utils/display-price";
import { useTranslation } from "react-i18next";





const ResourceItem:FC<any> = React.memo(({resourceType, items}) => {
  const [t] = useTranslation()
  const memoizedItems = useMemo(() => {
    return items.flatMap((resource:any) => {
      return resource.history.map((history:any) => {
        return {
          display_name: `${resource.display_name} - ${history.rule_display}`,
          quantity: `${history.price_details.units}${history.price_details.unit_display}`,
          unit_price: formatCurrency(history.price_details.unit_price, resource.currency.code),
          cost: formatCurrency(history.price, resource.currency.code) 
        }
      })
    })
  }, [items]);
  const ColumnsItemResources = [
    {
      title:t("NAME"),
      key:'display_name'
    },
    {
      title:t("QUANTITY"),
      key:'quantity'
    },
    {
      title:t("UNIT_PRICE"),
      key:'unit_price'
    },
    {
      title:t("COST"),
      key:'cost'
    },
  ]
  return (
    <div style={{display:'flex', flexDirection:"column", gap:'14px'}}>
      <Typography variant='h3'>{resourceType.display_name}</Typography>
      <Table
        hideCheckbox
        hideOther
        columns={ColumnsItemResources}
        rows={memoizedItems}
      />
    </div>
  )
})

export default ResourceItem;

