import React, { FC, useEffect, useState } from "react";
import {
  FormWrapper
} from './styles'
import {
  Input,
  Button,
  Toggle,
  Select,
  TopProgress,
  ModalVariables,
  Table,
  Label,
} from '../../../../components'
import {
  Info,
  InfoText,
  BodyWrapper
} from '../styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from 'use-query-params';
import { networkService, routerService } from "../../../../api";
import { randomNameGenerator } from "../../../../utils/create-random-name";
import { useUi } from "../../../../context/ui.context";
import { InfoContainer } from "../../../../components/info-block/styles";
import { icons } from "../../../../icons";
import { useNavigate, useParams } from "react-router-dom";
import ContentLoader, { List } from 'react-content-loader'
import { useTheme } from "styled-components";

const Network:FC<any> = (props) => {
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const navigate = useNavigate()
  const {networkId} = useParams()
  const [regions, setRegions] = useState<any[]>([])
  const [routers, setRouters] = useState<any[]>([])
  const [editedNetwork, setEditedNetwork] = useState<any>()
  const [routerOptions, setRouterOptions] = useState<any>({})
  const [selectedRouter, setSelectedRouter] = useState<any>()
  const [createNewRouter, setCreateNewRouter] = useState<boolean>(false)
  const [admin, setAdmin] = useState<boolean>(true)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [editedSubnet, setEditedSubnet] = useState<any>()
  const [initialValues, setInitialValues] = useState<any>({
    name:'',
    region:''
  })
  const [deletedKey, setDeletedKey] = useState<any>()
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [subnets, setSubnets] = useState<any[]>([
    {
      name:randomNameGenerator({name:'subnet', findArr:[]}),
      cidr:`10.0.1.0/24`,
      ip_version:'4',
      // setGatewayIp: false,
      setGatewayIp:'default',
      dns_name:'8.8.8.8',
      enable_dhcp:true,
      dns_nameservers:[],
      host_routes:[],
      // network_id:res.data.id,
      _id:randomNameGenerator({name:'', findArr:[]}),
      network_mask:0,
      network_address_source:'manually',
      allocation_pools:[],

      //data we don't have fields
      ipv6_ra_mode:null,
      ipv6_address_mode:null,
      // gateway_ip:'',
    }
  ])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = (props?:any) => {
    if(modalType === 'delete'){
      setSubnets((prev) => {
        return prev.filter((sub) => sub.name === deletedKey.name)
      })
    }
    if(modalType === 'create-subnet'){
      if(subnets.find((s) => s._id === props._id)){
        setSubnets((prev) => {
          return prev.map((s) => s._id === props._id ? props : s)
        })
      } else {
        setSubnets([props, ...subnets])
      }
    }
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }



  useEffect(() => {
    routerService.getRouters({
      page:1,
      ordering:null,
      pageSize:10
    })
    .then((res) => {
      setRouters([{value:'new', label:t("CREATE_NEW_ROUTER")}, ...res.data.objects.map((router:any) => {
        return {
          value:router.id,
          label:router.name
        }
      })])
    })
    routerService.createOptionsRouter()
    .then((res) => {
      setRouterOptions(res.data)
    })
    networkService.getCreateOptions()
    .then((res) => {
      setRegions(res.data.regions.map((reg:any) => {
        return {value:reg.id, label:reg.id}
      }))
      handleChange('region')(res.data.regions?.[0]?.id)
      setInitialValues({
        name:randomNameGenerator({name:'network', findArr:[]}),
        region:regions[0]?.value
      })
      if(networkId){
        networkService.getNetwork(networkId)
        .then((res) => {
          setInitialValues({
            name:res.data.name,
            region:res.data.region
          })
          setEditedNetwork(res.data)
          setSubnets(res.data.subnets)
          setPageLoading(false)
          setAdmin(res.data.admin_state_up)
        })
      } else {
        setPageLoading(false)
      }
    })
  },[])
  useEffect(() => {
    setSelectedRouter(routers?.[1]?.value || routers?.[0]?.value)
  },[routers])
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      admin_state_up:admin,
      description:'',
      name:values.name,
      region:values.region || regions[0].value
    }
    if(networkId){
      networkService.updateNetwork(networkId, data)
      .then((res) => {
        // setChecked([])
        setLoadingComplete(true)
        setTimeout(() => {
          setLoading(false)
          setLoadingComplete(false)
          setViewModal(false)
          setActiveAnimation(false)
          viewAlert({
            severity:'info',
            message:`${t('NETWORK_UPDATED_SUCCESSFULLY')}`,
          })
          navigate('/networks')
        },1000)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
        setLoading(false)
        setLoadingComplete(false)
      })
    } else {
      networkService.createNetwork(data)
      .then(async(res) => {
        setTimeout( async () => {
        let promises:any[] = []
        await subnets.map((item:any) => {
          let promise = networkService.createSubnet({
            ...item,
            network_id:res.data.id,
          })
          promises.push(promise)
        })

        await Promise.all(promises)
        .then(async (resNetworks) => {
          if(props.internetAcc){
            if(createNewRouter || selectedRouter === 'new'){
              const dataRouter = {
                admin_state_up:true,
                description:'',
                external_network_id:routerOptions.external_networks.find((net:any) => net.name === 'external_network').id,
                name:randomNameGenerator({name:'router', findArr:[]}),
                region: values.region || regions[0].value,
              }
              routerService.createRouter(dataRouter)
              .then(async(res) => {
                routerService.checkRouter(res.data.name)
                .then((normRouter) => {
                  setTimeout( async () => {
                    let promises:any[] = []
                    await resNetworks.map((sub:any) => {
                      let subData = {
                        // ip: sub.data?.gateway_ip === null ? '' : sub.data?.gateway_ip,
                        ip: '',
                        subnet:sub.data.id
                      }
                      let promise = routerService.addInterfaceToRouter(normRouter.data.router, subData)
                      promises.push(promise)
                    })
                    
                      await Promise.all(promises)
                      .then((res) => {
                        // setChecked([])
                        setLoadingComplete(true)
                        setTimeout(() => {
                          setLoading(false)
                          setLoadingComplete(false)
                          setViewModal(false)
                          setActiveAnimation(false)
                          navigate(-1)
                        },1000)
                      })
                      .catch((e) => {
                        if(e?.response?.data.detail){
                          viewAlert({
                            severity:'error',
                            message:e.response.data.detail,
                          })
                        }
                        // viewAlert({
                        //   severity:'error',
                        //   message:`${t('ERROR_ADD_INTERFACES')}`,
                        // })
                        navigate('/networks')
                        setActiveAnimation(false)
                        setLoading(false)
                      })
                  }, 2000)
                })
                .catch((e) => {
                  if(e?.response?.data.detail){
                    viewAlert({
                      severity:'error',
                      message:e.response.data.detail,
                    })
                  }
                  viewAlert({
                    severity:'error',
                    message:`${t('ERROR_ADD_INTERFACES')}`,
                  })
                  navigate('/networks')
                  setActiveAnimation(false)
                  setLoading(false)
                })
              })
              .catch((e) => {
                if(e?.response?.data?.detail){
                  viewAlert({
                    severity:'error',
                    message:e?.response?.data?.detail
                  })
                }
                setLoading(false)
                navigate('/networks')
              })
            } else if(selectedRouter || routers?.[1]?.value) {
              setTimeout(async() => {
                let promises:any[] = []
                await resNetworks.map((sub:any) => {
                  let subData = {
                    ip:sub.data?.gateway_ip === null ? '' : sub.data?.gateway_ip,
                    subnet:sub.data.id
                  }
                  let promise = routerService.addInterfaceToRouter(routers?.[1]?.value, subData)
                  promises.push(promise)
                })
                await Promise.all(promises)
                .then((res) => {
                  // setChecked([])
                  setViewModal(false)
                  setActiveAnimation(false)
                  setLoadingComplete(true)
                  setTimeout(() => {
                    setLoadingComplete(false)
                    navigate(-1)
                  },1000)
                })
                .catch((e) => {
                  if(e?.response?.data.detail){
                    viewAlert({
                      severity:'error',
                      message:e.response.data.detail,
                    })
                  }
                  viewAlert({
                    severity:'error',
                    message:`${t('ERROR_ADD_INTERFACES')}`,
                  })
                  setActiveAnimation(false)
                  navigate('/networks')
                  setActiveAnimation(false)
                  setLoading(false)
                })
              },2000)
            }
          } else {
            setLoadingComplete(true)
            setTimeout(() => {
              setLoadingComplete(false)
              navigate(-1)
            },1000)
          }
        })
        .catch((e) => {
          if(e?.response?.data?.detail){
            viewAlert({
              severity:'error',
              message:e?.response?.data?.detail
            })
          }
          setLoading(false)
          navigate('/networks')
        })
      },2000)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        } else {
          setErrors(e?.response?.data)
        }
        // navigate('/networks')
        setActiveAnimation(false)
        setLoading(false)
      })
    }
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
      .required(`${t('REQUIRED_FIELD')}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      region: yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const items = [
    {
      title:t('EDIT'),
      id:'2',
      icon:icons.Edit,
      onClick: (id:any) => {
        setEditedSubnet(id)
        openModal('create-subnet')
      },
      divider:false
    },
    
    {
      title:t('DELETE'),
      id:'4',
      onClick:(id:any) => {
        setAlertTitle(t('SUBNET'),)
        setAlertSubTitle([id])
        setDeletedKey(id)
        openModal('delete')
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const theme:any = useTheme()
  if(pageLoading) return (
    <>
      <TopProgress loading={pageLoading}/>
      <ContentLoader backgroundColor={theme.palette[10]} viewBox="0 0 500 800" height={800} width={500} {...props}>
        <rect x="0" y="45" rx="4" ry="4" width="343" height="48" />

        <rect x="0" y="125" rx="4" ry="4" width="343" height="48" />

        <rect x="0" y="205" rx="4" ry="4" width="343" height="88" />

        <rect x="0" y="315" rx="4" ry="4" width="543" height="208" />

        <rect x="100" y="535" rx="4" ry="4" width="80" height="40" />
        <rect x="0" y="535" rx="4" ry="4" width="80" height="40" />
      </ContentLoader>
    </>
  )
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("NETWORK_ADDRESS"),
      key:'cidr'
    },
    {
      title:t("IP_VERSION"),
      key:'ip_version'
    },
    {
      title:t("GATEWAY_IP"),
      key:'setGatewayIp'
    },
    {
      title:'',
      key:'props'
    },
  ]
  return(
    <BodyWrapper style={{alignItems:'flex-start'}}>
      <FormWrapper>
        <Input
          required
          placeholder=''
          customStyles={{width:'360px'}}
          title={t("NAME_NETWORK")}
          fieldName='name'
          {...formikProps}
        />
        {/* <Select
          toolTip={false}
          data={regions}
          selectedValue={values['region']}
          onChange={(e) => handleChange('region')(e)}
          title={t("PLACEMENT_REGION")}
          customStyles={{width:'360px'}}
        /> */}
        {networkId ? null :
          <Toggle
            value={props.internetAcc}
            onChange={() => props.setInternetAcc(!props.internetAcc)}
            title={t("INTERNET_ACCESS")}
            customStyles={{}}
            test_id='interner-access-toggle'
          />
        }
        <Toggle
          value={admin}
          onChange={() => setAdmin(!admin)}
          title={t("ADMIN_STATE_UP")}
          test_id='admin-state-toggle'

        />
        {props.internetAcc && !networkId ? 
          <Select
            toolTip={false}
            data={routers}
            selectedValue={selectedRouter}
            onChange={(e) => {
              if(e === 'new'){
                setCreateNewRouter(true)
              } else {
                setCreateNewRouter(false)
              }
              setSelectedRouter(e)
            }}
            title={t("ROUTER")}
            test_id="routers-select"
            customStyles={{width:'360px'}}
          />
        : null}
        {networkId ? null :
        <InfoContainer style={{flex:'1 1'}}>
        <Button
          variant="stroke"
          size="display"
          icon={icons.PlusDefault}
          onClick={() => {
            setEditedSubnet({
              name:randomNameGenerator({name:'subnet', findArr:[]}),
              cidr:`10.0.${subnets.length + 1}.0/24`,
              ip_version:'4',
              setGatewayIp:'default',
              enable_dhcp:true,
              dns_nameservers:[],
              host_routes:[],
              // network_id:res.data.id,
              _id:randomNameGenerator({name:'', findArr:[]}),
              network_mask:0,
              network_address_source:'manually',
              allocation_pools:[],
        
              //data we don't have fields
              ipv6_ra_mode:null,
              ipv6_address_mode:null,
              // gateway_ip:'',
            })
            openModal('create-subnet')}}
          title={t("CREATE_SUBNET")}
        />
        <FormWrapper>
          <Table
            columns={Columns}
            rows={subnets}
            settings={subnets?.length > 1 ? items : [{
              title:t("EDIT"),
              id:'2',
              icon:icons.Edit,
              onClick: (id:any) => {
                openModal('create-subnet')
                setEditedSubnet(id)
              },
              divider:false
            },]}
            hideOther
            hideCheckbox
          />
        </FormWrapper>
        </InfoContainer>
        }
        <div style={{display:'flex', marginTop:'20px'}}>
          <Button
            variant="primary"
            size="display"
            customStyles={{marginRight:"10px"}}
            onClick={handleSubmit}
            title={networkId ? t("EDIT") : t("CREATE")}
            loading={loading}
            loadingComplete={loadingComplete}
          />
          <Button
            variant="stroke"
            size="display"
            onClick={() =>  navigate('/networks')}
            title={t("CANCEL")}
          />
        </div>
        <ModalVariables
          modalType={modalType}
          viewModal={viewModal}
          activeAnimation={activeAnimation}
          closeModal={closeModal}
          closeModalCancel={closeModalCancel}
          alertTitle={alertTitle}
          alertSubTitle={alertSubTitle}
          dataToEdit={editedSubnet}
        />
      </FormWrapper>
      <Info>
        <Label reverse title={t("INFORMATION")}/>
        <InfoText>
          {t('INFO_CREATE_NETWORK')}
        </InfoText>
      </Info>
    </BodyWrapper>
  )
}

export default Network

