import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { notificationService } from "../../../api";
import { BackArrow, TopProgress, Typography } from "../../../components";
import { InfoContainer } from "../../../components/info-block/styles";
import { useUi } from "../../../context/ui.context";
import { timeConverterMMDDYYHHMM } from "../../../utils/dates";
import { InstanceName } from "../../Account-settings/styles";
import {
  Container,
  Date,
  DateWrapper,
  HeadWrapper,
  DivMessage
} from './styles'
import TestIcon from './testIcon.svg'

const NotificationPage:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const {notificationId} = useParams()

  const [notification, setNotification] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)



  const getNotification = async () => {
    if(notificationId){
      await setLoading(true)
      try{
        const resView = await notificationService.viewNotification(notificationId)
        const res = await notificationService.getById(notificationId)
        await setNotification(res.data)
        await setPageLoading(false)
        await setLoading(false)

      } catch (error) {
        navigate(-1)
        viewAlert({
          severity:"error",
          message:`${t("NOTIFICATION_NOT_FOUND")}`
        })
        // setPageLoading(false)
      }
    } else {
      navigate(-1)
      viewAlert({
        severity:"error",
        message:`${t("NOTIFICATION_NOT_FOUND")}`
      })
    }
  }
  useEffect(() => {
    getNotification()
  },[notificationId])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
    <TopProgress loading={loading}/>
      <BackArrow onClick={() => navigate('/notifications')} title={t("BACK_TO_NOTIFICATIONS")}/>
      <HeadWrapper>
        <InstanceName>
          {notification.title}
        </InstanceName>
        <img style={{width:'60px', height:'60px'}} src={TestIcon}/>
      </HeadWrapper>
      <DateWrapper>
        <Date>
          {t("DATE")}
        </Date>
        <Typography variant='small'>
          {timeConverterMMDDYYHHMM(notification.generated)}
        </Typography>
      </DateWrapper>
      <InfoContainer style={{marginTop:"20px",gap:'30px', display:'flex', flexDirection:"column"}}>
        <DivMessage dangerouslySetInnerHTML={{__html:notification.body}}/>
        {/* <Typography variant='body'>
          Hello! 
        </Typography>
        <Typography variant='body'>
        Your cloud resources have been suspended because you are out of credit. 
        </Typography>
        <Typography variant='body'>
        To add credit to your account follow this link: https://cp.netengi.com
        </Typography>
        <Typography variant='body'>
        Thank you!
        </Typography> */}
      </InfoContainer>
    </Container>
  )
}

export default NotificationPage