import React, { FC, useEffect, useState } from "react";
import {
  Container,
  AddonsWrapper,
  ItemAddon,
} from './styles'
import { Button, Label, Select, TopProgress, Typography } from "../../../../../components";
import { kubernetesService } from "../../../../../api";
import MetricsIc from '../../../create-cluster/add-ons/ic-kubernetes.svg'
import CloudNativeIc from '../../../create-cluster/add-ons/cloudnative.svg'
import PrometheusIc from '../../../create-cluster/add-ons/Prometheus_software_logo 1.svg'
import RedisIc from '../../../create-cluster/add-ons/redis-logo-svgrepo-com 1.svg'
import NginxIc from '../../../create-cluster/add-ons/ic-nginx.svg'
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const icons = [
  {key:'metrics-server', icon:MetricsIc},
  {key:'cloudnative-pg', icon:CloudNativeIc},
  {key:'prometheus', icon:PrometheusIc},
  {key:'redis', icon:RedisIc},
  {key:'ingress-nginx', icon:NginxIc},
]
const Addons = () => {
  const [addons, setAddons] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  useEffect(() => {
    kubernetesService.getAddons()
    .then((res) => {
      setAddons(res.data?.map((addon:any) => {
        return{
          ...addon,
          release:addon?.releases?.[0],
          releases:addon?.releases?.map((release:any) => {
            return{
              label:release.version,
              value:release.version,
            }
          })
        }
      }))
      setPageLoading(false)
    })
    .catch((err) => {
      console.log('err',err);
    })
  },[])

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <AddonsWrapper>
        {addons.map((addon:any) => (
          <AddonItem {...addon}/>
        ))}
      </AddonsWrapper>
    </Container>
  )
}

export default Addons


const AddonItem:FC<any> = (addOn) => {
  const [version, setVersion] = useState<any>(addOn.releases[0]?.value)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const {clusterId} = useParams()
  const [t] = useTranslation()
  const installVersion = () => {
    if(clusterId) {
      setLoading(true)
      kubernetesService.installAddon(clusterId, {addons:[{name:addOn.name, version:version}]})
      .then((res:any) => {
        console.log('res',res);
        setLoadingComplete(true)
        setTimeout(() => {
          setLoading(false)
          setLoadingComplete(false)
        }, 1500)
      })
      .catch((err:any) => {
        console.log('err',err);
      })
    }
  }
  return(
    <ItemAddon>
      <div style={{display:'flex', flexDirection:'row'}}>
        <img style={{marginRight:'8px'}} src={icons.find((ic:any) => ic.key === addOn?.name)?.icon}/>
        <Label margin='10px' toolTip={true} tooltipText={addOn.description} title={addOn.name}/>
      </div>
      <div style={{display:'flex',  flexDirection:'row', gap:'6px'}}>
        <Typography customStyles={{marginTop:'2px'}} variant='small'>
          {t("VERSION")}
        </Typography>
        <Select
          toolTip={false}
          required={true}
          isSmall
          data={addOn.releases}
          // disabled={addOn.releases?.length <= 0 || !addOns?.find((o:any) => o.name === addOn.name)}
          selectedValue={version}
          onChange={(e) => {
            setVersion(e)
            // ChangeAddonVersion(addOn,e)
          }}
          placeholder={addOn.releases?.length <= 0 ? t("NO_DATA") : t("CHOOSE")}
          title={t("VERSION_KUBERNETES")}
          customStyles={{minWidth:'61px'}}
          fieldName='addon_version'
          // errors={errorsView && addOns?.find((o:any) => o.name === addOn.name) && !addOns?.find((o:any) => o.name === addOn.name)?.version ? {addon_version:`${t('REQUIRED_FIELD')}`} : {}}
          // {...formikProps}
        />
      </div>
      <Button
        variant="secondary"
        size="display"
        onClick={installVersion}
        title={t("INSTALL")}
        customStyles={{
          display:'flex',
          width:'100%',
          justifyContent:"center",
          // height:'auto',
          boxSizing:'border-box',
        }}
        loading={loading}
        loadingComplete={loadingComplete}
      />
    </ItemAddon>
  )
}