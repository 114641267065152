import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";



const networkService = {
  getCreateOptions:() => ApiClient.get('openstack/networks/create_options'),
  getAllNetworks:(queries:QueriesApi) => {
    return ApiClient.get(`openstack/networks?page_size=${queries.pageSize}&ordering=${queries.ordering}&page=${queries.page}`)
  },
  updateNetwork:(id:string, data:any) => ApiClient.put(`openstack/networks/${id}`, data),
  getNetwork:(id:string) => ApiClient.get(`openstack/networks/${id}`),
  deleteNetwork:(id:string) => ApiClient.delete(`openstack/networks/${id}`),
  deleteSubnet:(id:string) => ApiClient.delete(`openstack/subnets/${id}`),
  createNetwork:(data:any) => ApiClient.post("openstack/networks", data),
  getCreateSubnetOptions:(id:string) => ApiClient.get(`openstack/subnets/create_options?network_id=${id}`),
  createSubnet:(data:any) => ApiClient.post('openstack/subnets', data),
  getSubnets:() => ApiClient.get('openstack/subnets'),
  getSubnet:(id:string) => ApiClient.get(`openstack/subnets/${id}`),
  editSubnet:(id:string, data:any) => ApiClient.put(`openstack/subnets/${id}`, data),
  getRelatedPorts:(networkId:string, queries:QueriesApi) => ApiClient.get(`openstack/ports?filtering=network:${networkId}&page_size=${queries.pageSize}&ordering=${queries.ordering}&page=${queries.page}`),
  deletePort:(id:string) => ApiClient.delete(`openstack/ports/${id}`),
  checkNetwork:(name:string) => ApiClient.get(`check_network?name=${name}`)
}

export default networkService