import styled from 'styled-components'
type ActiveProp = {
  active?:boolean;
  error?:boolean;
  containerCustom?:any;
  customStyles?:any;
}

export const Country = styled.div`
  display:flex;
  align-items:center;
  gap:6px;
  width:200px;
  &:hover{
    opacity: 0.7;
    cursor: pointer;
  }
`

export const CountriesList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 15;
  border-radius: 4px;
  width: 230px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4px 6px;
  left: -11px;
  top:38px;
  gap:6px;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 2px 2px ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[400]};
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  ::-webkit-scrollbar-button{
    display: none;
  }
  background-color: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  filter: ${({theme}) => theme.isDark ? 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.5));' : ''};
  max-height: 354px;
`
export const ImgArrow = styled.img<ActiveProp>`
  transform: ${({active}) => active ? 'rotate(180deg)' : ''};
  transition:0.2s transform ease-in-out;
`
export const Container = styled.div<ActiveProp>`
  display: flex;
  appearance: none;
  -moz-appearance: textfield; /* Firefox */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    display: none;
  }
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 4px 10px;
  margin-top: 4px;
  font-weight: 400;
  padding-right: 40px;
  font-size: 15px;
  line-height: 20px;
  outline: none;
  background-color: ${({theme}) => theme.palette[10]};
  /* border:${({theme, error}) => error ? `1px solid ${theme.errors[600]}` :`1px solid ${theme.grey[350]}`}; */
  border: ${({theme, active,  error}) => error ? `1px solid ${theme.errors[600]}` : active ? theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[300]}` : `1px solid ${theme.palette[20]}`};
  box-shadow: ${({active, theme,  error}) => active ? error ? theme.isDark ? '0px 0px 0px 3px rgb(205,58,50, 0.4)' : `0px 0px 0px 3px ${theme.errors[100]}` : theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' :  '0px 0px 0px 3px #E0EAFF' : ''};
  color:${({theme}) => theme.palette[900]};
  border-radius:4px;
  ${({customStyles}) => customStyles};
  &:focus{
    border: ${({theme, error}) => error ? `1px solid ${theme.errors[600]}` : theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[300]}`};
    box-shadow: ${({theme, error}) => error ? theme.isDark ? '0px 0px 0px 3px rgb(205,58,50, 0.4)' : `0px 0px 0px 3px ${theme.errors[100]}` : theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`};
  }
  &:target{
    border: ${({theme, error}) => error ? `1px solid ${theme.errors[600]}` : theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[300]}`};
    box-shadow: ${({theme, error}) => error ? theme.isDark ? '0px 0px 0px 3px rgb(205,58,50, 0.4)' : `0px 0px 0px 3px ${theme.errors[100]}` : theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`};
  }
`

export const Countries = styled.div`
  position: relative;
  display: flex;
`

export const SelectedCountry = styled.div`
  display: flex;
  align-items: center;
  &:hover{
    cursor: pointer;
  }
`

export const SelectInput = styled.input`
  width:100%;
  height:27px;
  border:none;
  outline: none;
  color:${({theme}) => theme.base.black};
  background-color:${({theme}) => theme.palette[10]};
  font-size: 15px;
  line-height: 20px;
  font-family:Inter;

`
