import styled from "styled-components";
import StyledIcon from "../styled-icon";
import { BodyDefault } from "../typography/styled";

type ActiveProp = {
  active?:boolean,
  isSmall?:any,
  hideSideBar?:boolean
}
export const Container = styled.div<ActiveProp>`
  box-sizing: border-box;
  display: flex;
  width:${({isSmall}) => isSmall ? 'calc(100vw - 70px)' : 'calc(100vw - 280px)'};
  transition: 0.2s width ease-in-out;
  z-index: 2;
  padding: 20px 25px 20px 20px;
  height:80px;
  justify-content: space-between;
  align-items: center;
  position: relative;
`
export const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-left:10%;
  z-index: 6;
`

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 15vw;
  z-index: 2;
  &:hover{
    cursor: pointer;
  }
`
export const ChevronIc = styled.img<ActiveProp>`
  transform: ${({active}) => active ? 'rotate(180deg)' : ''};
  transition: 0.2s transform ease-in-out;
`

export const Avatar = styled.img`
  width:24px;
  height:24px;
  border-radius: 100%;
  object-fit: contain;
  flex-shrink: 0;
`

export const NoAvatar = styled.div`
  text-align: center;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width:24px;
  height:24px;
  border-radius: 100%;
  color:${({theme}) => theme.palette[650]};
  background-color: ${({theme}) => theme.palette[400]};
`


export const ProfileName = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  display: block;
  margin-left: 12px;
  margin-right: 4px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`

export const DropDownProfile = styled.div<ActiveProp>`
  box-sizing: border-box;
  padding: 4px 0px;
  right: 26px;
  top: 60px;
  z-index: 6;
  position: absolute;
  background-color: ${({theme}) => theme.palette[10]};
  width: 240px;
  /* height: 244px; */
  border:${({theme}) => `1px solid ${theme.grey[350]}`};
  border-radius:4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.1);
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  opacity:${({active}) => active ? '1' : '0'};
  transform-origin: top center;
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
`

export const NotificationsContainer = styled.div<ActiveProp>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: 6;
  width: 400px;
  max-height: 430px;
  right:80px;
  top:60px;
  padding: 16px 15px 14px;
  background: ${({theme}) => theme.palette[10]};
  border: ${({theme}) => `1px solid ${theme.grey[350]}`};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  opacity:${({active}) => active ? '1' : '0'};
  transform-origin: top center;
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
`

export const ToolTipOpenstack = styled.div<ActiveProp>`
  display:flex;
  padding: 1px 6px;
  position: absolute;
  bottom:-23px;
  left:-27px;
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  opacity:${({active}) => active ? '1' : '0'};
  transform-origin: top center;
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  background-color: ${({theme}) => theme.palette[10]};
  border-radius: 4px;
  z-index: 13;
  /* box-shadow: 0px 2px 15px 0px ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[400]}; */
  box-shadow:0px 2px 10px rgba(0, 0, 0, 0.1);
`
export const OpenstackWrapper = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  align-items: flex-end;
`

export const Rect = styled.div<ActiveProp>`
  width: 6px;
  height: 6px;
  bottom:79%;
  left:50%;
  position: absolute;
  transform: rotate(45deg) translateX(-50%);

  z-index: 5;
  background: ${({theme}) => theme.base.white};
  border-right: none;
  border-top: none;
  display: ${({active}) => active ? '' : 'none'};
`

export const BalanceContainer = styled.div`
  box-sizing: border-box;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction:column;
  /* flex-wrap:wrap; */
  width: 100%;
  max-height:40px;
  min-height: 40px;
  margin-bottom: 3px;
  background-color: ${({theme}) => theme.palette[10]};
  padding: 4px 8px 4px 10px;
  border: ${({theme}) =>  `1px solid ${theme.palette[20]}`};
  border-radius: 4px;
  color: ${({theme}) => theme.grey[600]};
  &:hover{
    cursor: pointer;
    color: ${({theme}) => theme.grey[900]};
  }
`