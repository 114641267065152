import styled from "styled-components";
import { BodyDefault, BodyMedium, BodySmall, HeadLine3, VerySmall } from "../typography/styled";
import SVG from "react-inlinesvg";

type ActivePropImg = {
  active?:boolean,
  disable?:boolean,
  hover?:boolean,
}
export const StyledIconCopy = styled(SVG)<ActivePropImg>`
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  & path {
    stroke: ${({ theme }) => theme.base.black};
  }
  opacity: ${({hover, disable}) => hover || disable ? '0.7' : ''};
  filter: ${({active, disable}) => active && !disable ? 'brightness(77%)' : ''};
  &:hover {
    cursor: pointer;
    opacity: ${({hover}) => hover ? '' : '0.7'};
  }
  &:active{
    filter: ${({disable}) => !disable ? 'brightness(77%)' : ''};
  }
`

type ContainerProps = {
  customStyles?:any
}
type ActiveProp = {
  active?:boolean
  copy?:boolean,
  dropped?:boolean,
  isLong?:boolean,

}

export const InfoContainer = styled.div<ContainerProps>`
  background-color: ${({theme}) => theme.palette[10]};
  /* display: flex; */
  flex-direction: column;
  /* align-items: flex-start; */
  box-sizing: border-box;
  /* justify-content: center; */
  width: 100%;

  padding: 16px 20px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: ${({theme}) => `1px solid ${theme.palette[20]}`};
  margin-bottom:10px;
  ${({customStyles}) => customStyles}
`
export const InfoTitle = styled(HeadLine3)`
  width: 100%;
`

export const InfoName = styled(BodyDefault)`
  color:${({theme}) => theme.palette[700]};
  display: flex;
  width:50%;
  /* flex: auto 1; */
  /* flex-grow: 1; */
`
export const InfoNameGrey = styled(InfoName)`
  color:${({theme}) => theme.palette[20]};
`
export const InfoNameBold = styled(BodyMedium)`
  color:${({theme}) => theme.palette[700]};
  display: flex;
  /* width:100%; */
`
export const NameBorder = styled.div`
  border: ${({theme}) => `1px solid ${theme.palette[20]}`};
  border-radius: 4px;
  padding: 3px 4px; 
  white-space:nowrap;
`
export const InfoItem = styled.div`
  display: flex;
  /* justify-content: space-between; */
  /* width: 100%; */
  align-items: center;
  gap:20px;
`

export const InfoValue = styled.div<ActiveProp>`
  /* align-items: center; */
  display: flex;
  word-break: break-word;
  white-space:normal;
  /* width: 100%; */

  /* width: 40%; */
  /* overflow: hidden !important; */
  /* flex:1 auto; */
  /* white-space: nowrap; */
  overflow: hidden !important;
  text-overflow: ellipsis;
  &:hover{
    cursor:${({copy}) => copy ? 'pointer' : ''};
  }

`

export const InfoValueText = styled(BodyDefault)<ActiveProp>`
  /* align-items: center; */
  display: ${({copy}) => copy ? 'inline-block' : 'inline-block'};
  word-break: ${({copy}) => copy ? 'break-all' : 'break-all'};
  white-space:${({copy}) => copy ? 'nowrap' : 'normal'};
  /* width: ${({isLong}) => isLong ? "100%" : ""}; */
  overflow: hidden !important;
  /* flex:1 auto; */
  /* width: 100%; */
  /* white-space: ${({isLong}) => isLong ? "" : "nowrap"}; */
  text-overflow: ellipsis;
  &:hover{
    cursor:${({copy}) => copy ? 'pointer' : ''};
  }

`

export const DropInfo = styled.div<ActiveProp>`
  margin-top: ${({active, dropped}) => dropped ? active ? '10px' : '0px' : ''};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap:6px;
  height:${({active}) => active ? '100%' : '0px'};
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  transform-origin: top;
  transition: 0.1s transform ease-in-out;
  transition: 0.2s opacity, height ease-in-out;
  opacity:${({active}) => active ? '1' : '0'};
`

type WrapperProp = {
  dropped?:boolean,
}
export const TitleWrapper = styled.div<WrapperProp>`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 10px; */
  &:hover{
    cursor: ${({dropped}) => dropped ? 'pointer' : ''};
  }
`
export const CountItems = styled(VerySmall)`
  color:${({theme}) => theme.palette[650]};
  margin-left: 10px;
  margin-top: 3px;
`
export const Divider = styled.div`
  margin:16px 0px;
  width: 100%;
  height: 1px;
  border-bottom: ${({theme}) => `1px solid ${theme.palette[20]}`};

`
export const InfoSubtitle = styled(BodyMedium)`
  margin-bottom: 10px;
`


export const CopyIcon = styled(SVG)<ActiveProp>`
  padding: 3px;
  border-radius: 4px;
  background: ${({theme}) => theme.grey[300]} ;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  & path {
    stroke:${({theme}) => theme.palette[800]};
  }
  &:hover {
    cursor:pointer;
    opacity: 0.7;
  }
  &:active{
    filter: brightness(77%);
  }
`

export const BodyValue = styled.div<ActiveProp>`
  display:flex;
  align-items:center;
  width:40%;
  flex:1 1;
  justify-content: flex-start;
  position: relative;
  /* word-break: break-all; */
  /* flex-wrap: wrap; */
  &:hover{
    cursor:${({copy}) => copy ? 'pointer' : ''};
  }
`

export const PropContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 3px 4px;
  height:21px;
  background-color: ${({theme}) => theme.palette[300]};
  border-radius: 4px;
  align-items: center;
`
export const Prop = styled(VerySmall)`
  color:${({theme}) => theme.palette[850]};
`
export const PropMulti = styled(BodySmall)`
  color:${({theme}) => theme.palette[850]};
`
export const PropsWrapper = styled.div`
  display:flex;
  flex-wrap: wrap;
  /* width:360px; */
  gap:6px;
`

export const CopyContainer = styled.div`
  position:absolute;
  top:-25px;
  /* right:15px; */
  right: 0px;
  white-space: nowrap;
  /* width: 100px; */
  border-radius: 4px;
  padding: 2px 4px;
  background: ${({theme}) => theme.isDark ? '#474B53' : theme.palette[400]};

`