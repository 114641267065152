import styled from "styled-components";
import { BodyDefault } from "../typography/styled";


export const Container = styled.div`


`

export const ErrorText = styled(BodyDefault)`
  color:${({theme}) => theme.errors[600]};
  margin-top: 6px;
  &:first-letter {
    text-transform: uppercase;
  }
`