import styled from "styled-components";
import { BodySmall } from "../../../components/typography/styled";

export const Container = styled.div`
  padding: 10px 30px 10px 20px;

`
export const Date = styled(BodySmall)`
  color:${({theme}) => theme.palette[600]};
  margin-right:6px;
`

export const DateWrapper = styled.div`
  display: flex;
`
export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DivMessage = styled.div`
  color:${({theme}) => theme.base.black};
  &>p{
    word-break: break-all;
  }

`