import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { Button, ModalVariables, StartPage } from "../../components";
import TutorImg from './illustration.svg'
import TutorDarkImg from './illustration-dark.svg'
import { useTheme } from "styled-components";
import { useNavigate } from "react-router";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { authService, imageService } from "../../api";
import { timeConverterMMDDYYHHMM } from "../../utils/dates";
import { GreenDot, Greentext, StateWrapper } from "../Instances/styles";
import { useUi } from "../../context/ui.context";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../context/user.context";
import config from "../../config";
import { handleStatusName } from "../../utils/handle-status-name";
import rootConfig from "../../config";
import { compareArrays } from "../../utils/findIntersection";



const Images:FC = () => {
  const [images, setImages] = useState<any[]>([])
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam);
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [paginationData, setPaginationData] = useState<any>()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedImage, setSelectedImage] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user, quotas} = useAuth()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(images, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {setDataToLs} = useLocalStorage()
  const _updateImages = () => {
    // setLoading(true)
    imageService.getImagesList({
      page:page || 1,
      page_size:pageSize || 10,
      ordering:ordering,
    })
    .then((res) => {
      setImages(res.data.objects.map((image:any) => {
        return {
          ...image,
          navigate: image.id,
          sizeString:`${image.size} GB`,
          name: image.name || image.id,
          created_at:timeConverterMMDDYYHHMM(image.created_at),
          protected:image.protected ? 'True' : 'False',
          min_disk:`${image.min_disk} GB`,
          status:(
          <StateWrapper>
            <GreenDot active={image.status}/>
            <Greentext style={{textTransform:"capitalize"}} active={image.status}>
              {handleStatusName(image.status,t)}
            </Greentext>
          </StateWrapper>) ,
        }
      }))
      setPaginationData(res.data)
      setPageLoading(false)
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      navigate('/home')
      setPageLoading(false)
    })
  }
  useEffect(() => {
    _updateImages()
    const interval = setInterval(() => {
      _updateImages()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  
  const _deleteVolume = async () => {
    setLoading(true);
    try {
      if (allChecked) {
        await Promise.all(images.map((item:any) => imageService.deleteImage(item.id)));
        _updateImages();
        setAllChecked(false);
        setChecked([]);
      } else if (checked?.length > 0) {
        await Promise.all(checked.map((item) => imageService.deleteImage(item.id)));
        _updateImages();
        setChecked([]);
      } else if (selectedImage) {
        await imageService.deleteImage(selectedImage.id);
        _updateImages();
      }
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      setChecked([]);
    } finally {
      setViewModal(false);
      setActiveAnimation(false);
      setLoading(false);
    }
  };
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = async (values:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'copy-image':
          res = await imageService.copyImage(selectedImage.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        // case 'upload-volume-to-image':
        //   res = await volumeService.uploadToImage(selectedVolume.id, values);
        //   if (res?.data?.detail) {
        //     viewAlert({
        //       severity: 'info',
        //       message: res.data?.detail,
        //     });
        //   }
        //   break;
        case 'delete':
          _deleteVolume()
          break; 
      }
      if(modalType !== 'delete'){
        setViewModal(false)
        setActiveAnimation(false)
      }
    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      if(modalType !== 'delete'){
        setLoading(false);
      }
    }
    // setViewModal(false)
    // setActiveAnimation(false)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("LAUNCH"),
      id:'2',
      icon:icons.Launch,
      onClick: (image:any) => {
        setDataToLs({key:'instance.configure', data:{os:image?.id}})
        navigate('/instances/create?step=configure')
      },
      divider:false
    },
    {
      title:t("EDIT"),
      id:'3',
      icon:icons.Edit,
      onClick: (image:any) => {
        setSelectedImage(image)
        navigate(`${image.id}/edit`)
      },
      divider:false
    },
    {
      title:t("DOWNLOAD_IMAGE"),
      id:'5',
      icon:icons.Download,
      onClick: async (image:any) => {
        const res = await authService.generateOtpToken()
        const url = `${config.apiUrl}api/openstack/images/${image.id}/download?fleio-token=${res.data.otp_token}`
        // window.open(url);
        window.location.href = url
      },
      divider:false
    },
    {
      title:t("DOUBLE_IMAGE"),
      id:'6',
      icon:icons.CopyProps,
      onClick: (image:any) => {
        openModal('copy-image')
        setSelectedImage(image)
        setAlertTitle(image.name || image.id)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(image:any) => {
        openModal('delete')
        setAlertTitle(t("IMAGE"))
        setAlertSubTitle([image])
        setSelectedImage(image)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name',
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    {
      title:t("PROTECTED"),
      key:'protected'
    },
    // {
    //   title:t("PLACEMENT_REGION"),
    //   key:'region'
    // },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
    {
      title:t("MIN_DISK_SIZE"),
      key:'min_disk'
    },
    {
      title:'',
      key:'props'
    },
  ]
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else {
      navigate('create')
    }
  }
  return (
    <>
      <StartPage
        title={`${t("IMAGES")}`}
        noResourceText={`${t("THERE_ARE_NO_IMAGES_YET")}`}
        createResourceButton={`${t('CREATE_IMAGE')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={images.length > 0}
        learnMoreAboutResource={`${t('LEARN_MORE_IMAGES')}`}
        howCreateVideoText={`${t('HOW_CREATE_IMAGE')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/images`}
        // howCreateVideoUrl="https://www.youtube.com/embed/3ffI9KM4pbs?si=ypRZ7d7_vZGTCQ0l"
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("IMAGES"))
                setAlertSubTitle(allChecked ? images : checked)
                openModal('delete')
              }}
              test_id='button-delete'
              variant='icon'
              size='display'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        videoImage={TutorImg}
        videoImageDark={TutorDarkImg}
        pageLoading={pageLoading}
        tableData={{
          columns:Columns,
          data:images,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          paginationData:paginationData,
          updateData:_updateImages
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={selectedImage}
      />
    </>
  )
}


export default Images

