import styled from 'styled-components'
import { BodyDefault, VerySmall } from '../../../../components/typography/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top:20px;
`

export const QrContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  /* gap: 60px; */
  isolation: isolate;
  justify-content: center;
  width: 820px;
  height: 290px;
  background: ${({theme}) => theme.palette[10]};
  border: ${({theme}) => ` 1px solid${theme.palette[20]}`};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top:10px;
  margin-bottom:30px;
  position:relative;
`
export const QrWrapper = styled.div`
  display: flex;
  padding: 16px 20px;
  box-sizing: border-box;
  justify-content: space-between;
  /* gap: 60px; */
  width: 100%;
  height: 100%;
`
export const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width:100%; */
`

export const BlockDivider = styled.div`
  position: absolute;
  width:0px;
  top:0px;
  height:  100%;
  right: 50%;
  border-right: ${({theme}) => ` 1px solid${theme.palette[20]}`};
  /* transform: rotate(90deg); */
`
export const OrBackground = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;

  display: flex;
  align-items: center;
  /* left: 47.4%; */
  z-index: 2;
  top: calc(50% - 20px);
  background: ${({theme}) => theme.grey[800]};
  border: ${({theme}) => ` 1px solid${theme.palette[20]}`};
`

export const OrText = styled(VerySmall)`
  color:${({theme}) => '#FFFF'};
`

export const KeyWrapper = styled.div`
  background-color: ${({theme}) => theme.grey[300]};
  /* width: 337px; */
  /* display:flex; */
  /* height: 59px; */
  padding: 10px 14px;
  /* box-sizing: border-box; */
  border-radius: 4px;
  margin-bottom: 30px;
`
export const KeyTitle = styled(VerySmall)`
  color: ${({theme}) => theme.palette[650]};
`
export const KeyBody = styled(BodyDefault)`

`

