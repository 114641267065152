import React, { FC, useState, useRef, useEffect } from "react";
import {
  Container,
  DropItem,
  Divider,
  DropTitle,
} from './styles'
import MoonIcon from './moon.svg'
import SettingsIcon from './settings.svg'
import ClientsIcon from './clients.svg'
import BillingIcon from './billing.svg'
import QuotasIcon from './quotas.svg'
import ActivitiesIcon from './activity-logs.svg'
import LogOutIcon from './log-out.svg'
import Toggle from '../../../toggle'
import StyledIcon from "../../../styled-icon";
import { useUi } from "../../../../context/ui.context";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { authService } from "../../../../api";
import axios from "axios";
import config from "../../../../config";
import keycloak from '../../../../keycloak';
import { useAuth } from "../../../../context/user.context";
type DropItemType = {
  title:string,
  id:string,
  icon:any,
  divider:boolean 
}
type DropType = {
  active?:boolean,
  handleClose?:any,
  ref?:any,
  options?:any[],
  itemToEdit?:any,
  checkFiled?:any,
  limitedItems?:any[]
}
const DropOptions:FC<DropType> = ({options, itemToEdit, limitedItems, handleClose}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['enduserFleioToken']);
  const {
    setDataToLs,
    getDataToLs,
    removeItemFromLs
  } = useLocalStorage()
  const [t] = useTranslation()
  const items = [
    {
      title:t("DARK_MODE"),
      id:'1',
      icon:MoonIcon,
      divider:true
    },
    {
      title:t("ACCOUNT_SETTINGS"),
      id:'2',
      icon:SettingsIcon,
      divider:false,
      navigate:'account-settings'
    },
    {
      title:t("QUOTAS"),
      id:'3',
      icon:QuotasIcon,
      divider:false,
      navigate:'quotas'
    },
    {
      title:t("CLIENTS"),
      id:'4',
      icon:ClientsIcon,
      divider:false,
      navigate:'clients'
    },
    {
      title:t("BILLING"),
      id:'5',
      icon:BillingIcon,
      navigate:'billing',
      divider:false
    },
    // {
    //   title:t("ACTIVITIES_LOGS"),
    //   id:'5',
    //   icon:ActivitiesIcon,
    //   divider:true
    // },
    {
      title:t("LOGOUT"),
      id:'6',
      icon:LogOutIcon,
      onClick:() => {
        removeItemFromLs('userToken')
        removeCookie('enduserFleioToken');
        authService.logout()
        // authService.logoutKek()
        document.cookie = ''
        // window.location.href = 'https://cp.netengi.com/backend/saml2/logout'
        // window.location.reload()
        keycloak.logout()
      },
      divider:false
    },
    // {
    //   title:'Keycloak logout',
    //   id:'7',
    //   icon:LogOutIcon,
    //   onClick:() => {
    //     removeItemFromLs('userToken')
    //     removeCookie('enduserFleioToken');
    //     authService.logout()
    //     // authService.logoutKek()
    //     document.cookie = ''
    //     // window.open('https://cp.netengi.com/backend/saml2/ls/')
    //     window.location.href = `${config.apiUrl}saml2/logout/`
    //     // window.location.reload()
    //   },
    //   divider:false
    // },

  ]
  return (
      <Container>
        {options ? 
          itemToEdit?.invitation ? 
          limitedItems?.map((item:DropItemType) => (
            <DropItemComponent handleClose={handleClose} itemToEdit={itemToEdit} key={item.id} item={item}/>
              
          )) :
          options?.map((item:DropItemType) => (
            <DropItemComponent handleClose={handleClose} itemToEdit={itemToEdit} key={item.id} item={item}/>
              
          ))
          :
          items.map((item:DropItemType) => (
            <DropItemComponent handleClose={handleClose} itemToEdit={itemToEdit} key={item.id} item={item}/>
          ))
        }
      </Container>
    
  )
}

export default DropOptions

const DropItemComponent:FC<any> = ({item, itemToEdit, handleClose}) => {
  const [hover, setHover] = useState<boolean>(false)
  const navigate = useNavigate()
  const [activeIcon, setActiveIcon] = useState<boolean>(false)
  const {
    themeDark,
    setThemeDark,
  } = useUi()
  const [t] = useTranslation()
  const [loadingNav, setLoadingNav] = useState<boolean>(false)
  const {user} = useAuth()
  const {viewAlert} = useUi()
  const checkClient = async (navigateTo:string) => {
    setLoadingNav(true)
    if(!user.user.active_client && navigateTo === 'billing'){
    setLoadingNav(false)
      navigate('/complete-registration')
      return
    }
    try {
      // const res = await authService.checkRoute(route)
      // const resUser = await authService.getUser()

      await navigate(navigateTo)
    } catch (err:any) {
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      // navigate('/home')
    } finally {
      setLoadingNav(false)
    }
  }
  return (
    <>
      <DropItem
        onClick={() => {
          if(item.onClick){
            item.onClick(itemToEdit)
            handleClose()
          }
          if(item.navigate){
            checkClient(item.navigate)
          }
        }}
        data-test-id={item?.testId}
        key={item.id}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseDown={() => setActiveIcon(true)}
        onMouseUp={() => setActiveIcon(false)}

      >
        <div style={{display:'flex'}}>
          <StyledIcon active={activeIcon} hover={hover} src={item.icon}/>
          <DropTitle>
            {item.title}
          </DropTitle>
        </div>
        {item.title === t("DARK_MODE") ?
          <Toggle
            title={''}
            value={themeDark}
            onChange={() => setThemeDark(!themeDark)}
          />
        :
          null
        }
      </DropItem>
      {item.divider ? 
        <Divider/>
      :
        null
      }
    </>
  )
}
