import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoBlock } from '../../../../components'
import {
  Container,
  TopInfoWrapper
} from '../members/styles'
import {timeConverterMMDDYYHHMM} from '../../../../utils/dates'
import { handleStatusName } from '../../../../utils/handle-status-name'
import { GreenDot, Greentext, StateWrapper } from '../../../Instances/styles'

const Overview:FC<any> = ({lbPool}) => {
  const [t] = useTranslation()
  const specifications = [
    {
      title:t("ID"),
      id:'1',
      value:lbPool?.id || '-',
      copy:true,
    },
    {
      title:t("AVAILABILITY_ZONE"),
      id:'2',
      value:lbPool?.availability_zone || 'n/a'
    },
    {
      title:t("OPERATING_STATUS"),
      id:'3',
      value: (
      <StateWrapper>
        <GreenDot active={lbPool.operating_status}/>
        <Greentext active={lbPool.operating_status}>
          {handleStatusName(lbPool.operating_status, t)}
        </Greentext>
      </StateWrapper>) 
    },
    {
      title:t("PROVISIONING_STATUS"),
      id:'4',
      value: (
      <StateWrapper>
        <GreenDot active={lbPool.provisioning_status}/>
        <Greentext active={lbPool.provisioning_status}>
          {handleStatusName(lbPool.provisioning_status, t)}
        </Greentext>
      </StateWrapper>)
    },
    {
      title:t("ADMIN_STATE"),
      id:'14',
      value:lbPool?.admin_state_up ? 'Yes' : 'No'
    },
    {
      title:t("DESCRIPTION"),
      id:'5',
      value:lbPool?.description || '-'
    },
    {
      title:t("CREATED_AT"),
      id:'6',
      value: timeConverterMMDDYYHHMM(lbPool?.created_at)
    },
    {
      title:t("UPDATED_AT"),
      id:'7',
      value:timeConverterMMDDYYHHMM(lbPool?.updated_at)
    },
    {
      title:t("ALGORITHM"),
      id:'8',
      value:lbPool?.lb_algorithm || '-',
    },
    {
      title:t("PROTOCOL"),
      id:'9',
      value:lbPool?.protocol || '-',
    },
    {
      title:t("SESSION_PERCENTILE"),
      id:'11',
      value:lbPool?.session_persistence?.type || '-',
    },
    {
      title:t("HEALTH_MONITOR_ID"),
      id:'10',
      value:lbPool?.healthmonitor_id || '-',
      copy:true,
    },
  ]
  return(
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1'}}
        />
      </TopInfoWrapper>
    </Container>
  )
}
export default Overview
