import styled from "styled-components";

type RuleProp = {
  isLast:boolean,
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 20px 10px 0px 0px;
  width: 100%;
  /* align-items: flex-start; */
  gap: 10px;
`

export const RulesWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  border:${({theme}) => `1px solid ${theme.palette[20]}`};
  margin-top:14px;
`

export const HeadContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const RuleContainer = styled.div<RuleProp>`
  border-bottom: ${({theme, isLast}) => !isLast ? `1px solid ${theme.grey[300]}` : ''};
  /* border-top: ${({theme}) =>  `1px solid ${theme.grey[300]}`}; */
  padding:10px;
  box-sizing: border-box;
  display:flex;
`