import styled, { css } from 'styled-components'

type StyleButtonProps = {
  size?: 'display' | 'mid' | 'small' | 'custom',
  customStyles?:any,
  disabled?:boolean
}
const MenuButton = styled.button<StyleButtonProps>`
  ${({customStyles}) => customStyles};
  width: 30px;
  height: 30px;
  background: ${({theme}) => theme.palette[300]};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1E1E1E;
  border: ${({theme}) => `none`};
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* box-shadow:${({theme, disabled}) => disabled ? '' : '0px 1px 2px rgba(0, 0, 0, 0.1)'}; */
  &:hover{
    color: ${({theme}) => theme.palette[850]};
    /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); */
    cursor: pointer;
    opacity:0.7;
  }
  &:active{
    /* border-color: ${({theme}) => theme.primary[200]}; */
    color: ${({theme}) => theme.palette[850]};
    /* filter: brightness(77%); */
    border: ${({theme}) => theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[200]}`};
    /* box-shadow: ${({theme}) => theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`}; */
  }
  &:disabled{
    background: ${({theme}) => theme.grey[100]};
    color:#858A94;
    border-color: #DBDBDB;
  }
`

export default MenuButton