import React, {FC, useEffect, useState} from "react";
import {
  Container,
  BodyWrapper,
} from './styles'
import { TableSkeleton, TopProgress } from "..";
import { InfoContainer } from "../info-block/styles";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";


const InfoSkeleton:FC<any> = ({size, hideTitle, hidePart}) => {
  const theme:any = useTheme()
  const [arrCount, setArrCount] = useState<any[]>([])
  useEffect(() => {
    if(size > 1){
      let arr = []
      for(let i = 1; i<=size; i++){
        arr.push(i)
        setArrCount(arr)
      }
    }
  },[size])
  return (
    <InfoContainer
      style={{
        display:'flex',
        flexDirection:'row',
        flex:'1 1',
        marginBottom:'0px',
        flexGrow:'1',
        flexBasis:'0',
        boxSizing:'border-box',
    }}>
      <div style={{
        display:"flex",
        flexDirection:'column',
        gap:'10px',
        flexGrow:'1',
        flexBasis:'0'
      }}>
        {hideTitle ? null : 
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={95} style={{marginBottom:"5px"}} height={15}
          />
        }
        {arrCount.map((_) => (
          <Skeleton
            baseColor={theme.palette[300]}
            highlightColor={theme.palette[20]}
            width={155} style={{marginBottom:"5px"}} height={10}
          />
        ))}
      </div>
      {hidePart ? null :
        <div style={{
          display:"flex",
          flexDirection:'column',
          gap:'10px',
          flexGrow:'1',
          flexBasis:'0'
        }}>
          {[...arrCount, 'dop'].map((_, index:any) => index === 0 ? <div style={{minHeight:'20px'}}/> : (
            <Skeleton
              baseColor={theme.palette[300]}
              highlightColor={theme.palette[20]}
              width={195} style={{marginBottom:"5px"}} height={10}
            />
          ))}
        </div>
      }
    </InfoContainer>
  )
}

export default InfoSkeleton