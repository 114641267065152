import React, { FC, useState, useEffect } from "react";
import {
  Container,
  TitleBig,
  BodyWrapper,
  Info,
  InfoText,
} from './styles'
import { Tabs, BackArrow, Label } from "../../../components";
import { Route, Routes, useNavigate, Navigate, useNavigation, useLocation, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Network from "./network";
import { useQueryParam, StringParam } from 'use-query-params';
import Subnet from "./subnet";
import Router from "./router";
import Extra from "./extra";
import Finally from "./finally";

const CreateNetwork:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [step, setStep] = useQueryParam('step', StringParam);
  const {networkId} = useParams()
  const [internetAcc, setInternetAcc] = useState<boolean>(true)
  // useEffect(() => {
  //   if(!step){
  //     setStep('network')
  //   }
  //   if(!internetAcc && step ==='router'){
  //     setStep('subnet')
  //   }
  //   if(tabs.find((item:any) => item.slug === step) === -1){
  //     setStep('network')
  //   }
  // },[])
  // const [tabs, setTabs] = useState<any[]>([
  //   {
  //     title:t("NETWORK"),
  //     slug:'network',
  //     id:'1',
  //     component:(props:any) => <Network internetAcc={props.internetAcc} setInternetAcc={props.setInternetAcc} />,
  //     info:t('INFO_CREATE_NETWORK'),
  //     infoHeight:'168px'

  //   },
  //   {
  //     title:t('SUBNET'),
  //     slug:'subnet',
  //     id:'2',
  //     component:(props:any) => <Subnet internetAcc={props.internetAcc}/>,
  //     info:t('SUBNET_INFO'),
  //     infoHeight:'228px'

  //   },
    // {
    //   title:t("ROUTER"),
    //   slug:'router',
    //   id:'3',
    //   additional_info:t("ADD_INFO_ROUTER_STEP"),
    //   component:() => <Router/>,
    //   info:t('SUBNET_INFO'),
    //   infoHeight:'228px'

    // },
    // {
    //   title:t("EXTRA_OPTIONS"),
    //   slug:'extra',
    //   id:'4',
    //   component:(props:any) => <Extra internetAcc={props.internetAcc}/>,
    //   info:t('INFO_CREATE_NETWORK'),
    //   infoHeight:'168px'
    // },
  // ])


  return(
    <Container>
      <BackArrow
        onClick={() => navigate('/networks')}
        title={t("BACK_TO_NETWORKS")}
      />
      <TitleBig>{networkId ? `${t("EDIT_NETWORK")}` : t("CREATE_NEW_NETWORK")}</TitleBig>
      {/* <div style={{width:'100%'}}>
        <Tabs
          tabs={tabs}
          size='display'
          checked
          isSteps
          location={step}
          block={{route:'router', isBlock:!internetAcc}}
        />
      </div> */}
      <BodyWrapper>
      <Network
        internetAcc={internetAcc}
        setInternetAcc={setInternetAcc}
      />
        {/* {tabs.map((item:any) => (
          step === item.slug ? 
            <>
              {item.component({
                internetAcc:internetAcc,
                setInternetAcc:setInternetAcc
              })}
              <Info style={{height:item.infoHeight}}>
                <Label reverse title={t("INFORMATION")}/>
                <InfoText>
                  {item.info}
                </InfoText>
              </Info>
            </>
          : null
        ))}
        {step === 'finally' ? 
          <Finally internetAcc={internetAcc}/>
        : null} */}

      </BodyWrapper>
    </Container>
  )
}

export default CreateNetwork