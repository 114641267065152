import styled from 'styled-components'
import { BodyDefault } from '../../../components/typography/styled'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px 20px 30px;
  gap:20px;
`
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-end;
  width:100%;
  padding: 20px 0px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width:100%;
`
export const InfoText = styled(BodyDefault)`
  color:${({theme}) => theme.grey[700]};
`

export const LinkWrapper = styled.a`
  display: flex;
  gap: 6px;
  align-items: center;
  &:hover{
    opacity: 0.8;
    cursor: pointer;
  }
`

export const Link = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
`