import styled from "styled-components";
import { BodySmall, HeadLine1 } from "../../../components/typography/styled";
import SVG from "react-inlinesvg";
import { statusColor } from "../../../utils/statusColor";
type ActiveProp = {
  active?:boolean,
  disable?:boolean,
  hover?:boolean,
}
export const StyledConsoleIc = styled(SVG)<ActiveProp>`
  width: 20px;
  & path {
    stroke:${({theme}) => theme.palette[800]};
  };
  &:hover {
    cursor: pointer;
    stroke: {
      color: ${({theme}) => theme.grey[700]};
      fill:${({theme}) => theme.grey[700]};
    }
  }

`
export const StyledIcon = styled(SVG)<ActiveProp>`
  width: 20px;
  /* opacity: ${({hover, disable}) => hover || disable ? '0.7' : ''}; */
  path: {
    fill:${({theme}) => '#FFF'};

  };
  filter: hue-rotate(180deg);
  /* path: {
    color: ${({theme}) => "#FFF"};
    fill:${({theme}) => '#FFF'};
  } */
  /* filter: ${({active, disable}) => active && !disable ? 'brightness(77%)' : ''}; */
  &:hover {
    cursor: pointer;
    /* opacity: ${({hover}) => hover ? '' : '0.7'}; */
    stroke: {
      color: ${({theme}) => theme.grey[700]};
      fill:${({theme}) => theme.grey[700]};
    }
  }

`
type InstanceActive = {
  active?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 30px 30px;
  position: relative;
  box-sizing: border-box;
`
export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-top:10px;
  gap:50px;
  justify-content: space-between;
  width:100%;
  margin-right: 10px;
`

export const RightHeadContainer = styled.div`
  display:flex;
  margin-right: 10px;
  position: relative;
`

export const InstanceName = styled(HeadLine1)`
  color:${({theme}) => theme.palette[800]};
  
  margin-bottom:10px;
  word-break: break-word;
`
export const InstanceHeadInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`

export const InstanceDot = styled.div<InstanceActive>`
  width:12px;
  height:12px;
  background-color: ${({active}) => statusColor(active)};
  border-radius: 100%;
`
export const InstanceStatus = styled(BodySmall)<InstanceActive>`
  color:${({active}) => statusColor(active)};
  text-transform: capitalize;
  margin-left: 6px;
  margin-right: 5px;
`

export const InstanceType = styled(BodySmall)`
  margin-left: 15px;
  margin-right: 6px;
  color:${({theme}) => theme.palette[650]};
`
export const OperationDot = styled.div<InstanceActive>`
  width:12px;
  height:12px;
  background-color: ${({active}) => statusColor(active)};
  border-radius: 100%;
`
export const OperationStatus = styled(BodySmall)<InstanceActive>`
  color:${({active}) => statusColor(active)};
  margin-left: 6px;
  margin-right: 5px;
  text-transform: capitalize;
`

export const OperationStatusContainer = styled.div`
  display:flex;
  align-items: center;
`


export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  display: flex;
  margin-right:10px;
  &>div{
    position: absolute;
    bottom:-1px;
    left:0px;
    /* width:563px; */
  }
`
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
export const StyledIconMenu = styled(SVG)<ActiveProp>`
  opacity: ${({hover, disable}) => hover || disable ? '0.7' : ''};
  filter: ${({active, disable}) => active && !disable ? 'brightness(77%)' : ''};
  & path {
    /* stroke:${({theme}) => theme.palette[600]}; */
    fill:${({theme}) => theme.isDark ? theme.palette[900] : theme.palette[600]};
  }
  &:hover {
    cursor: ${({disable}) => disable ? '' : 'pointer'};
    /* opacity: ${({hover}) => hover ? '' : '0.7'}; */
  }
  &:active{
    filter: ${({disable}) => !disable ? 'brightness(77%)' : ''};
  }
`