import React, {FC, useState, useEffect, useMemo, useCallback, useRef} from "react";
import {
  StateWrapper,
  GreenDot,
  Greentext,
  PropContainer,
  Prop,
  Divider,
  FilterHeader,
  BlueText,
  Filter,
  FilterBody,
  BlueBack
} from './styles'
import { Diagrams } from "../HomePage/components";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import StyledIcon from "../../components/styled-icon";
import {
  Button,
  CheckBox,
  ClipBoardWrapper,
  LoaderDashed,
  ModalVariables,
  StartPage,
  TopProgress,
  Typography,
} from "../../components";
import TutorImg from './illustration.svg'
import TutorDarkImg from './illustration-dark.svg'
import { useTheme } from "styled-components";
import { useNavigate } from "react-router";
import {useUi} from '../../context/ui.context'
import { useAuth } from "../../context/user.context";
import { instanceService, operationService } from "../../api";
import { useQueryParam, StringParam, NumberParam, ArrayParam } from 'use-query-params';
import { InstanceType } from "../../types/instance";
import { handleOsIcons } from "../../hooks/useOsIcons";
import { handleStatusName, handleStatusTask } from "../../utils/handle-status-name";
import rootConfig from "../../config";
import { compareArrays, compareArraysTags } from "../../utils/findIntersection";
import copy from 'copy-to-clipboard';
import { PropsContainer } from "../../components/table/styles";
import { BodySmall } from "../../components/typography/styled";

const Instances:FC = () => {
  const [t] = useTranslation()
  const {
    setViewModalComplete,
    setActiveAnimationComplete,
    viewAlert,
  } = useUi()
  const {user, quotas} = useAuth()
  const navigate = useNavigate()
  const theme:any = useTheme()
  const [page=1, setPage] = useQueryParam('page', NumberParam);
  const [ordering='-created_at', setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [tags, setTags] = useQueryParam('tags', ArrayParam)

  const [instances, setInstances] = useState<InstanceType[]>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [paginationData, setPaginationData] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [modalType, setModalType] = useState<any>('')
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [deletedInstance, setDeletedInstance] = useState<any>()
  const [instance, setInstance] = useState<any>()
  const [instanceId, setInstanceId] = useState<any>()
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [filterList, setFilterList] = useState<any[]>([])
  const [selectedOperation, setSelectedOperation] = useState<any>()

  const optionsRef:any = useRef()
  const dropsRef:any = useRef()

  const editFilters = (fil:any) => {
    if(fil === null) {
      setTags([])
      return
    }
    const founded = tags?.find((item:any) => item === fil)
    if(founded) {
      setTags(() => {
        return tags?.filter((item:any) => item !== fil)
      })
    } else {
      //@ts-ignore
      setTags(() => [...tags||[], fil])
    }
  } 
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      setAllChecked(false)
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(instances, checked) && checked?.length !== 0){
      setAllChecked(true)
    } else {
      setAllChecked(false)
    }
  },[checked])
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
const handleClickOutside = (event:any) => {
  if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
    setOpenFilter(false)
  }
}
  const handleDropOptions = (status:string, distro?:any) => {
    switch(status) {
      case 'Завантажується з ISO':
        return itemsBootedIso
      case 'Booted from ISO':
        return itemsBootedIso
      case 'Rescued':
        return itemsRescued
      case 'Відновлено':
        return itemsRescued
      case 'Verify Resize':
        return itemsVerifyResize
      case "Підтвердьте зміну розміру":
        return itemsVerifyResize
      case 'Error':
        return itemsError
      case 'Невідомо':
        return itemsError
      case 'Помилка':
        return itemsError
      case 'Shelved Offloaded':
        return itemsShelvedOffloaded
      case 'Збережений стан і звільнені ресурси':
        return itemsShelvedOffloaded
      default:
        return distro === 'windows' ? items.filter((it) => it.id !== '7') : items
    }
  }  
  const _updateInstances = () => {
    instanceService.getTags()
    .then((res) => {
      setFilterList(res.data.objects.map((tag:any) => tag.name))
    })
    .catch((err) => {
      console.log('err', err?.response?.data);
    })
    instanceService.getInstanceList({
      page:page||1,
      ordering,
      pageSize:pageSize||10,
      //@ts-ignore
      tags:tags||[]
    })
    .then((res:any) => {
      setPaginationData(res.data)
      setInstances(tags && tags?.length > 0 ? 
        res.data.objects.map((instance:InstanceType) => {
        return{
          ...instance,
          navigate:instance.id,
          type:instance?.flavor?.name,
          access_ip: instance.floating_ips?.length === 0 ? (instance?.access_ip || '-') : instance.floating_ips?.map(((ip:any) => (
            <ClipBoardWrapper>
              {ip?.floating_ip_address }
            </ClipBoardWrapper>
          ))),
          clearName:instance.name,
          name: instance?.display_task ? (
            <div style={{display:'flex', alignItems:'center'}}>
              <a>
                {instance?.name}
              </a>
              <LoaderDashed title={handleStatusTask(instance?.display_task, t)}/>
            </div>
          ) : (
          <div style={{display:'flex', flexDirection:'column'}}>
            <a>
              {instance?.name}
            </a>
            <div style={{
              display:'flex',
              flexWrap:"wrap",
              alignItems:'center',
              gap:'4px',
              }}>
                {instance?.tags?.map((tag:any) => (
                  <PropContainer key={tag?.id}>
                    <Prop>
                      {tag?.name}
                    </Prop>
                  </PropContainer>
                ))}
              </div>
            </div>
          ),
          display_task: instance?.display_task ? handleStatusTask(instance?.display_task, t) : null,
          //@ts-ignore
          os: (<img src={handleOsIcons(instance?.image?.os_distro)} />),
          dropOptions:handleDropOptions(instance.display_status, instance?.image?.os_distro),
          status: instance?.display_task !== null ?
            (<StateWrapper>
              <GreenDot style={{backgroundColor:'#E8B533'}}/>
              <Greentext style={{color:'#E8B533'}}>
                {instance.display_status}
              </Greentext>
            </StateWrapper>)
          :
            (<StateWrapper>
            <GreenDot active={instance.status}/>
            <Greentext active={instance.status}>
              {instance.display_status}
            </Greentext>
          </StateWrapper>) ,
        }
      }) :[
        ...res.data.operations.map((operation:any) => {
          return{
            ...operation,
            dropOptions:[
              {
                title:'Abort',
                onClick:(operation:any) => {
                  setSelectedOperation(operation)
                  setAlertTitle(t("ABORT_OPERATION"))
                  setAlertSubTitle(`${t("ABORT_OPERATION_ALERT")} ${operation.id}`)
                  openModal('universal')
                },
                id:'3',
                icon:icons.CloseIconBold,
                divider:false
              },
            ],
            navigate:`/operations/${operation.id}`,
            isOperation:true,
            name:operation.name,
            status: 
            (<StateWrapper>
              <GreenDot style={{backgroundColor:'#E8B533'}}/>
              <Greentext style={{color:'#E8B533'}}>
                {t("IN_PROGRESS")}
              </Greentext>
            </StateWrapper>),
          }
        }),
        ...res.data.objects.map((instance:InstanceType) => {
        return{
          ...instance,
          navigate:instance.id,
          type:instance?.flavor?.name,
          access_ip: instance.floating_ips?.length === 0 ? '-' : instance.floating_ips?.map(((ip:any) => (
            <ClipBoardWrapper>
              {ip?.floating_ip_address}
            </ClipBoardWrapper>
          ))),
          clearName:instance.name,
          name: instance?.display_task ? (
            <div style={{display:'flex', alignItems:'center'}}>
              <a>
                {instance?.name}
              </a>
              <LoaderDashed title={handleStatusTask(instance?.display_task, t)}/>
            </div>
          ) : (
          <div style={{display:'flex', flexDirection:'column'}}>
            <a>
              {instance?.name}
            </a>
            <div style={{
              display:'flex',
              flexWrap:"wrap",
              alignItems:'center',
              gap:'4px',
              }}>
                {instance?.tags?.map((tag:any) => (
                  <PropContainer key={tag?.id}>
                    <Prop>
                      {tag?.name}
                    </Prop>
                  </PropContainer>
                ))}
              </div>
            </div>
          ),
          display_task: instance?.display_task ? handleStatusTask(instance?.display_task, t) : null,
          //@ts-ignore
          os: (<img src={handleOsIcons(instance?.image?.os_distro)} />),
          dropOptions:handleDropOptions(instance.display_status, instance?.image?.os_distro),
          status: instance?.display_task !== null ?
            (<StateWrapper>
              <GreenDot style={{backgroundColor:'#E8B533'}}/>
              <Greentext style={{color:'#E8B533'}}>
                {instance.display_status}
              </Greentext>
            </StateWrapper>)
          :
            (<StateWrapper>
            <GreenDot active={instance.status}/>
            <Greentext active={instance.status}>
              {instance.display_status}
            </Greentext>
          </StateWrapper>) ,
        }
      })])
    })
    .catch((e) => {
      console.log('e',e);
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
        // navigate('/home')
      }
      if(!user.user.active_client){
        navigate('/complete-registration')
        return
      }
    })
    .finally(() => {
      setPageLoading(false)
      // setLoading(false)
    })
  }
  useEffect(() => {
    _updateInstances()
    const update = setInterval(() => {
      _updateInstances()
    },3000)
    return () => {
      clearInterval(update)
    }
  },[page, ordering, pageSize, tags])
  useEffect(() => {
    setPage(1)
  },[pageSize])
  const _startInstances = async () => {
    setPageLoading(true)
    if(allChecked) {
      let promises:any[] = []
      await instances.map(async(item) => {
        let promise = instanceService.startInstance(item.id)
        promises.push(promise)
      })
      await Promise.all(promises)
      .then((res:any) => {
        _updateInstances()
        setAllChecked(false)
        setChecked([])
        if (res?.[0]?.data?.detail) {
          viewAlert({
            severity: 'info',
            message: res?.[0]?.data?.detail,
          });
        }
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
    } else if (checked?.length > 0){
      let promises:any[] = []
      await checked.map(async(item) => {
        let promise = instanceService.startInstance(item.id)
        promises.push(promise)
      })
      await Promise.all(promises)
      .then((res:any) => {
        _updateInstances()
        setChecked([])
        if(res?.[0]?.data?.detail) {
          viewAlert({
            severity: 'info',
            message: res?.[0]?.data?.detail,
          });
        }
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
    }
  }
  const _stopInstances = async () => {
    setPageLoading(true);
    try {
      let instancesToStop = [];
      if (allChecked) {
        instancesToStop = instances.map(item => item.id);
        setAllChecked(false);
        setChecked([]);
      } else if (checked?.length > 0) {
        instancesToStop = checked.map(item => item.id);
        setChecked([]);
      }
      const promises = instancesToStop.map(id => instanceService.stopInstance(id));
      const results = await Promise.all(promises);
      _updateInstances();
      if (results[0]?.data?.detail) {
        viewAlert({
          severity: 'info',
          message: results[0].data.detail,
        });
      }
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setPageLoading(false);
    }
  };

  const _deleteInstances = async () => {
    try {
      let instancesToDelete = [];

      if (allChecked) {
        instancesToDelete = instances.map(item => item.id);
        setAllChecked(false);
      } else if (checked?.length > 0) {
        instancesToDelete = checked.map(item => item.id);
        setChecked([]);
      } else if (deletedInstance) {
        instancesToDelete = [deletedInstance.id];
      }

      const promises = instancesToDelete.map(id => instanceService.deleteInstance(id));
      await Promise.all(promises);
      await setLoadingComplete(true);
      await setTimeout(() => {
        _updateInstances();
        setLoadingComplete(false);
        setViewModal(false);
        setActiveAnimation(false);
        setLoading(false);
      }, 1000);
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
            severity: 'error',
            message: e.response.data.detail,
        });
      }
    }
  };
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = async (values:any) => {
    let res:any; // объявляем переменную res
    try {
      setLoading(true);
      switch (modalType) {
        case 'dissociate-ip':
          res = await instanceService.dissociateFloatingIp(instance.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'change-password':
          res = await instanceService.changeRootPassword(instance.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          // navigate('/instances')
          break;
        case 'delete':
          _deleteInstances()
          break;
        case 'assign-ip':
          res = await instanceService.addFloatingIp(instance.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'rebuild-instance':
          res = await instanceService.rebuildInstance(instance.id, values);
          if (res.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data.detail,
            });
          }
          break;
        case 'rescue-instance':
          res = await instanceService.rescueInstance(instance.id, values);
          if (res.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data.detail,
            });
          }
          break;
        case 'rename':
          res = await instanceService.renameInstance(instance.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'add-tag':
          const news = values.filter((tag:any) => !tag.id)
          const missingElementsArray = compareArraysTags(instance.tags, values);
          let promisesDelete:any[] = []
          let promisesNew:any[] = []
          await news.map((miss:any) => {
            let promise = instanceService.addTag(instance.id, miss)
            promisesNew.push(promise)
          })
          await missingElementsArray.map((miss) => {
            let promise = instanceService.deleteTag(instance.id, miss.id)
            promisesDelete.push(promise)
          })
          await Promise.all(promisesDelete)
          await Promise.all(promisesNew)
          break;
        case 'universal':
          setLoading(true);
  
          if (alertTitle === `${t('RESET_INSTANCE_STATE')}`) {
            res = await instanceService.resetStateInstance(instance.id);
          } else if (alertTitle === `${t('CONFIRM_INSTANCE_RESIZE')}`) {
            res = await instanceService.confirmResizeInstance(instance.id);
          } else if (alertTitle === `${t('REVERT_INSTANCE_RESIZE')}`) {
            res = await instanceService.revertResizeInstance(instance.id);
          } else if (alertTitle === `${t('STOP_INSTANCE')}`) {
            _stopInstances()
          } else if (alertTitle === `${t('REBOOT_INSTANCE')}`) {
            res = await instanceService.rebootInstance(instance.id);
          } else if (alertTitle === `${t('UNRESCUE_INSTANCE')}`) {
            res = await instanceService.unrescueInstance(instance.id);
          } else if (alertTitle === `${t("UNSHELVE_INSTANCE")}`) {
            res = await instanceService.unshelveInstance(instance.id);
          } else if (alertTitle === `${t("SHELVE_INSTANCE")}`) {
            res = await instanceService.shelveInstance(instance.id);
          } else if (alertTitle === `${t('ABORT_OPERATION')}`) {
            res = await operationService.abortOperation(selectedOperation.id)
          } else if (alertTitle === `${t('UNMOUNT_ISO_AND_REBOOT')}`) {
            res = await instanceService.unrescueInstance(instance.id)
          }
  
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'instance-resize':
          res = await instanceService.resizeInstance(instance.id, {flavor: values});
          if (res.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data.detail,
            });
          }
          break;
        default:
          break;
      }
      if(modalType !== 'delete'){
        await setLoadingComplete(true);
        await setTimeout(() => {
          _updateInstances();
          setLoadingComplete(false);
          setViewModal(false);
          setActiveAnimation(false);
          setLoading(false);
        }, 1000);

      }

    } catch (e: any) {
      viewAlert({
        severity: 'error',
        message: e?.response?.data?.detail || t("ERROR_OCCURRED"),
      });
      setLoading(false);
    } 
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas?.core?.instances?.limit === quotas?.core?.instances?.in_use){
      viewAlert({
        severity:'error',
        message:`${t('INSTANCE_QUOTA_EXCEEDED')}`,
      })
    } else {
      navigate('create')
    }
  }
  const itemsShelvedOffloaded = [
    {
      title:t("UNSHELVE"),
      id:'23',
      icon:icons.Unshelve,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(`${t("UNSHELVE_INSTANCE")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_UNSHELVE_INSTANCE")} «${instance.clearName}»?`)
        openModal('universal')
      },
    },
    {
      title:t("DELETE"),
      id:'24',
      icon:icons.DeleteIcon,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(t("INSTANCE"))
        setAlertSubTitle([instance])
        setDeletedInstance(instance)
        openModal('delete')
      },
    },
  ]
  const itemsBootedIso = [
    {
      title:t("UNMOUNT_ISO_AND_REBOOT"),
      id:'25',
      icon:icons.Rebuild,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(`${t("UNMOUNT_ISO_AND_REBOOT")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_YOU_WANT_TO_ANMOUNT_ISO_AND_REBOOT")}`)
        openModal('universal')
      },
    },
    {
      title:t("DELETE"),
      id:'26',
      icon:icons.DeleteIcon,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(t("INSTANCE"))
        setAlertSubTitle([instance])
        setDeletedInstance(instance)
        openModal('delete')
      },
    },
  ]
  const itemsError = [
    {
      title:t("RESET_STATE"),
      id:'25',
      icon:icons.BackArrowRevert,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(`${t("RESET_INSTANCE_STATE")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_RESET_INSTANCE")} «${instance.clearName}» ${t("STATE")}?`)
        openModal('universal')
      },
    },
    {
      title:t("DELETE"),
      id:'26',
      icon:icons.DeleteIcon,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(t("INSTANCE"))
        setAlertSubTitle([instance])
        setDeletedInstance(instance)
        openModal('delete')
      },
    },
  ]
  const itemsRescued = [
    {
      title:`${t("UNRESCUE_INSTANCE")}`,
      id:'12',
      icon:icons.Rebuild,
      divider:false,
      onClick:(instance:any) => {
        setAlertTitle(`${t("UNRESCUE_INSTANCE")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_UNRESCUE_INSTANCE")} «${instance.clearName}»?`)
        setInstance(instance)
        setInstanceId(instance.id)
        openModal('universal')
      }
    },
    {
      title:t("DELETE"),
      id:'13',
      icon:icons.DeleteIcon,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(t("INSTANCE"))
        setAlertSubTitle([instance])
        setDeletedInstance(instance)
        openModal('delete')
      },
    },
  ]
  const itemsVerifyResize = [
    {
      title:`${t("CONFIRM_INSTANCE_RESIZE")}`,
      id:'14',
      icon:icons.CheckMark,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(`${t("CONFIRM_INSTANCE_RESIZE")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_CONFIRM_INSTANCE")} «${instance.clearName}» ${t("RESIZE")}?`)
        openModal('universal')
      }
    },
    {
      title:`${t("REVERT_INSTANCE_RESIZE")}`,
      id:'15',
      icon:icons.CloseWithBack,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(`${t("REVERT_INSTANCE_RESIZE")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_REVERT_INSTANCE")} «${instance.clearName}» ${t("RESIZE")}?`)
        openModal('universal')
      }
    },
    {
      title:t("DELETE"),
      id:'16',
      icon:icons.DeleteIcon,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(t("INSTANCE"))
        setAlertSubTitle([instance])
        setDeletedInstance(instance)
        openModal('delete')
      },
    },
  ]
  const items = [
    {
      title:t("REBUILD"),
      id:'0',
      onClick:(instance:any) => {
        setAlertTitle(instance.clearName)
        openModal('rebuild-instance')
        setInstance(instance)
        setInstanceId(instance.id)
      },
      icon:icons.Rebuild,
      divider:false
    },
    {
      title:t("SHELVE"),
      id:'1',
      icon:icons.Shelve,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(`${t("SHELVE_INSTANCE")}`)
        setAlertSubTitle(`${t("ARE_YOU_SHURE_WANT_TO_SHELVE_INSTANCE")} «${instance.clearName}»?`)
        openModal('universal')
      },
    },
    {
      title:t("RESCUE"),
      id:'2',
      onClick:(instance:any) => {
        setAlertTitle(instance.clearName)
        openModal('rescue-instance')
        setInstance(instance)
        setInstanceId(instance.id)
      },
      icon:icons.Rescue,
      divider:false
    },
    {
      title:t("RENAME"),
      id:'3',
      icon:icons.Rename,
      onClick:(instance:any) => {
        setInstance({...instance, name:instance.clearName})
        setInstanceId(instance.id)
        setAlertTitle(`${t("RENAME_INSTANCE")} «${instance.clearName}»`)
        openModal('rename')
      },
      divider:false
    },
    {
      title:t("RESIZE"),
      id:'4',
      icon:icons.Resize,
      toChangeColor:true,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        openModal('instance-resize')
        setAlertTitle(instance.clearName)
      }
    },
    {
      title:t("ASSIGN_FLOATING_IP"),
      onClick:(instance:any) => {
        setAlertTitle(instance.clearName)
        setInstance(instance)
        setInstanceId(instance.id)
        openModal('assign-ip')
      },
      id:'5',
      icon:icons.PlusDefault,
      divider:false
    },
    {
      title:t("DISSOCIATE_IP"),
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(instance.clearName)
        openModal('dissociate-ip')
      },
      id:'6',
      icon:icons.MinusDefault,
      divider:false
    },
    {
      title:t("CHANGE_PASSOWRD"),
      id:'7',
      icon:icons.Password,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(instance.clearName)
        openModal('change-password')
      }
    },
    {
      title:t("CHANGE_TAGS"),
      id:'9',
      icon:icons.IconTag,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        // setInstanceId(instance.id)
        // setAlertTitle(instance.name)
        openModal('add-tag')
      }
    },
    {
      title:t("DELETE"),
      id:'8',
      icon:icons.DeleteIcon,
      divider:false,
      onClick:(instance:any) => {
        setInstance(instance)
        setInstanceId(instance.id)
        setAlertTitle(t("INSTANCE"))
        setAlertSubTitle([instance])
        setDeletedInstance(instance)
        openModal('delete')
      },
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name',
    },
    {
      title:t("INSTANCE_STATE"),
      key:'status',
    },
    {
      title:t("INSTANCE_TYPE"),
      key:'type'
    },
    {
      title:t("ACCESS_IP"),
      key:'access_ip'
    },
    {
      title:t("OS_SHORT"),
      key:'os'
    },
    {
      title:'',
      key:'props'
    },
  ]
  // if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <>
      <TopProgress loading={pageLoading}/>
      <StartPage
        title={`${t("INSTANCES")}`}
        noResourceText={`${t("NO_INSTANCES")}`}
        createResourceButton={`${t('CREATE_INSTANCE')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={instances?.length > 0}
        // setHaveResource={setHaveNetworks}
        pageLoading={pageLoading}
        learnMoreAboutResource={`${t('LEARN_MORE_INSTANCES')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/instances`}
        howCreateVideoUrl="https://www.youtube.com/embed/WLpGZdhKh20"
        howCreateVideoText={`${t('HOW_CREATE_INSTANCE')}`}
        rightButtonComponentCustomWithResources={
          <div style={{ position:'relative', display:'flex', width:'100%', alignItems:'center', justifyContent:'space-between'}}>
            <div style={{display:'flex', alignItems:'center', gap:'6px'}}>
              <Button
                onClick={() => {
                  setAlertTitle(t("INSTANCES"))
                  setAlertSubTitle(allChecked ? instances : checked)
                  openModal('delete')
                }}
                test_id='button-delete'
                variant='icon'
                size='display'
                disabled={!allChecked && checked?.length === 0}
              >
                <img src={icons.DeleteIcon}/>
              </Button>
              <Button
                onClick={_stopInstances}
                variant='icon'
                size='display'
                disabled={!allChecked && checked?.length === 0}
              >
                <img src={icons.PauseIcon}/>
              </Button>
              <Button
                onClick={() => _startInstances()}
                variant='icon'
                size='display'
                disabled={!allChecked && checked?.length === 0}
              >
                <img src={icons.PlayIcon}/>
              </Button>
            </div>
            {filterList?.length > 0 &&
              <>
                <div ref={dropsRef}>
                  <Button
                    title={t("FILTER_BY_TAGS")}
                    onClick={() => setOpenFilter((prev) => !prev)}
                    size='display'
                    icon={icons.Filter}
                    active={openFilter}
                    //@ts-ignore
                    element={tags?.length > 0 ?(
                      <BlueBack>
                        <BodySmall style={{color:'inherit'}}>
                          {tags?.length}
                        </BodySmall>
                      </BlueBack>
                    ) : null}
                    variant="stroke"
                  />
                </div>
                <PropsContainer style={{top:'45px', right:'0px'}} ref={optionsRef} active={openFilter}>
                  <FilterHeader>
                    <Typography variant="small-medium">
                      {t("FILTER_BY_TAGS")}
                    </Typography>
                    <BlueText onClick={() => editFilters(null)}>
                      {t("CLEAR_FILTERS")}
                    </BlueText>
                  </FilterHeader>
                  <Divider/>
                  <FilterBody>
                    {filterList?.map((fil:any) => (
                      <Filter>
                        <CheckBox
                          title={fil}
                          onChange={() => editFilters(fil)}
                          //@ts-ignore
                          value={tags?.find((f:any) => f === fil)}
                        />
                        {/* <Typography variant="body-medium">
                          {filter.name}
                        </Typography> */}
                      </Filter>
                    ))}
                  </FilterBody>
                </PropsContainer>
              </>
            }
          </div>
        }
        videoImage={TutorImg}
        videoImageDark={TutorDarkImg}
        tableData={{
          columns:Columns,
          data:instances,
          paginationData:paginationData,
          allChecked:allChecked,
          props:items,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          updateData:_updateInstances,
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        dataToEdit={instance}
        instanceId={instanceId}
        instance={instance}
        loading={loading}
        loadingComplete={loadingComplete}
      />
    </>
  )
}


export default Instances

