import React, { FC, useState, useEffect } from "react";
import {
  Container,
  TitleBig,
  BodyWrapper,
  Info,
  InfoText,
} from './styles'
import {
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
  EstimateSubTitle,
  EstimateDesc,
  PropContainer,
  PropsWrapper,
  Divider,
} from '../../Instances/components/create-instance/styles'
import { Tabs, BackArrow, Label, TopProgress, LoaderSpinner } from "../../../components";
import {useNavigate} from "react-router";
import { useTranslation } from "react-i18next";
import SelectClusterType from "./cluster-type";
import { useQueryParam, StringParam } from 'use-query-params';
import ConfigureMaster from "./configure-master-node";
import MonitorDetails from "./configure-worker-node";
import NetworkingPage from './networking'
import Finally from "./finally";
import AddOnsPage from './add-ons'
import {useLocation, useParams} from 'react-router-dom'
import { KubernetesContextProvider, useKubernetes } from "../../../context/kubernetesContext";
import { useTheme } from "styled-components";
import { formatCurrency, formatCurrencyString } from "../../../utils/display-price";
import { useAuth } from "../../../context/user.context";
import { useInstance } from "../../../context/instanceContext";
import { floatingIpService, instanceService, loadBalancerService } from "../../../api";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useUi } from "../../../context/ui.context";

const CreateCluster:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [step, setStep] = useQueryParam('step', StringParam);
    
  const {getCreateOptions} = useInstance()
  useEffect(() => {
    getCreateOptions()
  },[])

  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("CONFIGURE_CLUSTER_TYPE"),
      slug:'cluster-type',
      id:'1',
      component:<SelectClusterType/>,
    },
    {
      title:t('CONFIGURE_MASTER_NODE'),
      slug:'configure-master',
      id:'2',
      component:<ConfigureMaster/>,
    },
    {
      title:t("NETWORKING"),
      slug:"networking",
      id:"3",
      component:<NetworkingPage/>,
    },
    {
      title:t("CONFIGURE_WORKER_NODE"),
      slug:"configure-worker",
      id:"4",
      component:<MonitorDetails/>,
    },
    {
      title:t("ADD_ONS"),
      slug:"add-ons",
      id:"5",
      component:<AddOnsPage/>,
    }
  ])
  useEffect(() => {
    if(!step){
      setStep('cluster-type')
    }

    if(tabs.indexOf((item:any) => item.slug === step) !== -1){
      setStep('cluster-type')
    }
  }, [])

  return(
    <Container>
      <BackArrow
        onClick={() => navigate('/kubernetes')}
        title={t("BACK_TO_CLUSTERS")}
      />
      <TitleBig>{t("CREATE_NEW_KUBERNETES_CLUSTER")}</TitleBig>
      <div style={{width:'100%'}}>
        <Tabs
          tabs={tabs}
          size='display'
          checked
          isSteps
          location={step}
        />
      </div>
      <KubernetesContextProvider>
        <ListPages
          tabs={tabs}
        />
      </KubernetesContextProvider>
    </Container>
  )
}

export default CreateCluster


const ListPages:FC<any> = ({tabs}) => {
  const [t] = useTranslation()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [priceMaster, setPriceMaster] = useState<any>()
  const [pricePools, setPricePools] = useState<any>()
  const {user} = useAuth()
  const [priceMonthMaster, setPriceMonthMaster] = useState<any>()
  const [priceMonthPools, setPriceMonthPools] = useState<any>()
  const theme:any = useTheme()
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [priceFloating, setPriceFloating] = useState<any>()
  const [priceMonthFloating, setPriceMonthFloating] = useState<any>()
  const navigate = useNavigate()
  const [priceBalancer, setPriceBalancer] = useState<any>(0.1)
  const [priceMonthBalancer, setPriceMonthBalancer] = useState<any>(3)

  const {pageKuberLoading, clusterData, nodePoolsData, addOns} = useKubernetes()
  const {
    regions,
    pageLoading,
  } = useInstance()
  const {
    viewAlert
  } = useUi()
  const getCurrentPriceFloating = () => {
    let queries:any = {
      region: regions[0]?.value,
    }

    floatingIpService.getEstimate(queries)
      .then((resEstimate) => {
        setPriceFloating(formatCurrencyString(resEstimate.data.estimated_cost_display.split('-')[0]).hour)
        setPriceMonthFloating(formatCurrencyString(resEstimate.data.estimated_cost_display.split('-')[1]).month)
      })
  }
  const getCurrentPriceLb = () => {
    loadBalancerService.getPrice()
      .then((resEstimate) => {
        setPriceBalancer(resEstimate.data.load_balancer_prices.find((price:any) =>  price.currency === user.user.clients?.[0]?.currency).price  / 30 / 24 || 0.1 / 24)
        setPriceMonthBalancer(resEstimate.data.load_balancer_prices.find((price:any) =>  price.currency === user.user.clients?.[0]?.currency).price || 3)
      })
  }

  const getCurrentPriceMasters = () => {
    let queries:any = {
      flavorId: clusterData?.flavor,
      region:regions?.[0]?.value,
      clientId: user?.user.active_client,
      networks: ['create-network']
    }
    setPriceLoading(true)
    instanceService.getPrice(queries)
    .then((res) => {
      setPriceMaster(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
      setPriceMonthMaster(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
      // setCurrency(res.data.estimated_cost_display[0])
      setPriceLoading(false)
    })
    
  }
  const getCurrentPricePools = async () => {
    setPriceLoading(true)
    let promisesCost:any[] = []

    await nodePoolsData?.map(async(pool:any) => {
      let queries:any = {
        flavorId: pool?.flavor_id,
        region:regions?.[0]?.value,
        clientId: user?.user.active_client,
        networks: ['create-network']
      }
      let promise = instanceService.getPrice(queries)
      promisesCost.push(...Array(pool.autoscale ? Number(pool.max_count) : Number(pool.count)).fill(promise))
    })
    await Promise.all(promisesCost)
    .then((res:any) => {
      setPricePools(res.reduce((accum:any, data:any) => {
        return accum + Number(formatCurrencyString(data?.data?.estimated_cost_display.split('-')[0])?.hour)
      }, 0))
      setPriceMonthPools(res.reduce((accum:any, data:any) => {
        return accum + Number(formatCurrencyString(data?.data?.estimated_cost_display.split('-')[1])?.month)
      }, 0))
      setPriceLoading(false)
    })

    
  }
  useEffect(() => {
    if(regions?.length > 0){
      getCurrentPriceFloating()
      getCurrentPriceLb()
    }
  },[regions])
  useEffect(() => {
    if(clusterData?.flavor && nodePoolsData?.length > 0){
      getCurrentPriceMasters()
      getCurrentPricePools()
    }
  },[clusterData, nodePoolsData])
  if(pageLoading || pageKuberLoading) return (
    <>
      <TopProgress loading={pageLoading || pageKuberLoading}/>
      {/* <ContentLoader backgroundColor={theme.palette[10]} viewBox="0 0 500 800" height={800} width={500}>
        <rect x="0" y="45" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="125" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="205" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="285" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="365" rx="4" ry="4" width="120" height="40" />
        <rect x="100" y="445" rx="4" ry="4" width="80" height="40" />
        <rect x="0" y="445" rx="4" ry="4" width="80" height="40" />
      </ContentLoader> */}
    </>
  )
  return(
    <BodyWrapper>
      {tabs.map((item:any) => (
        step === item.slug ? 
          <>
            {item.component}
            {/* <Info>
              <Label reverse title={t("INFORMATION")}/>
              <InfoText>
                {item.info}
              </InfoText>
            </Info> */}
          </>
        : null
      ))}
      {step === 'finally' ? 
        <Finally/>
      : null}
        <EstimateContainer style={{width:'290px'}}>
          <EstimateTitle>
            {t("ESTIMATED_COST")}
          </EstimateTitle>
          {priceLoading ? 
            <LoaderSpinner customStyles={{color:theme.base.black}}/>
          :
          priceMaster &&
          pricePools &&
          priceMonthMaster &&
          priceMonthPools ? 
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <div style={{display:'flex', alignItems:'center'}}>
                  <EstimateSum>
                    {`${formatCurrency((((+priceMaster * clusterData?.count_master) + (+pricePools) + (addOns.nginx ? (+priceFloating * 2) : +priceFloating) + (addOns.nginx ? (+priceBalancer * 2) : +priceBalancer))).toFixed(2), user.user.clients?.[0]?.currency || 'UAH')}`}
                  </EstimateSum>
                  <PerHour>{t("HOURS")}</PerHour>
                </div>
                <div style={{display:'flex', alignItems:'center'}}>
                  <EstimateSum>
                    {`${formatCurrency((((+priceMonthMaster * clusterData?.count_master) + (+priceMonthPools) + (addOns.nginx ? (+priceMonthFloating * 2) : +priceMonthFloating) + (addOns.nginx ? (+priceMonthBalancer * 2) : +priceMonthBalancer))).toFixed(2), user.user.clients?.[0]?.currency || 'UAH')}`}
                  </EstimateSum>
                  <PerHour>{t("MONTH")}</PerHour>
                </div>
              </div>
            :
            // null
            <PerHour>
              {t("TO_DISPLAY_PRICE_CONFIGURE_MASTER_NODES_AND_NODE_POOLS")}
            </PerHour>
          }
          {/* <Divider/> */}
          {/* {flavor ?
            <>
              <EstimateSubTitle>
                {t("TYPE_OF_INSTANCE")}
              </EstimateSubTitle>
              <div style={{maxWidth:'200px'}}>
                <EstimateDesc>
                  {flavor.name}
                </EstimateDesc>
                <PropsWrapper>
                  <PropContainer>
                    <Prop>
                      {flavor.vcpus} CPU
                    </Prop>
                  </PropContainer>
                  <PropContainer>
                    <Prop>
                      {flavor.memory_gb} GB RAM
                    </Prop>
                  </PropContainer>
                  <PropContainer>
                    <Prop>
                      {flavor.customItem.estimate}
                    </Prop>
                  </PropContainer>
                </PropsWrapper>
              </div>
            </>
          : null} */}
          {/* <EstimateSubTitle>
            {t("PLACEMENT_REGION")}
          </EstimateSubTitle>
          <EstimateDesc>
            ua-central-1 
          </EstimateDesc> */}
          <PropsWrapper>
            <div style={{marginRight:'30px'}}>
              {/* <EstimateSubTitle>
                {t("MACHINES")}
              </EstimateSubTitle> */}
              {/* <EstimateDesc> */}
                {/* {configureData?.countMachines} */}
              {/* </EstimateDesc> */}
            </div>
            {/* {(configureData?.storageType !== 'local' && configureData?.volumeSize) || flavors?.find((flavor:any) => flavor.value === configureData?.type)?.root_gb ? 
              <div>
                <EstimateSubTitle>
                  {t("STORAGE")}
                </EstimateSubTitle>
                <EstimateDesc>
                  {configureData?.storageType === 'local' ?
                  `${flavors?.find((flavor:any) => flavor.value === configureData?.type)?.root_gb || ''} GB`
                  :
                    `${configureData?.volumeSize || ''} GB`
                  }
                </EstimateDesc>
              </div>
            : null} */}
          </PropsWrapper>
          {/* {bootSource?.find((boot:any) => boot.value === configureData?.os)?.label ? 
            <>
              <EstimateSubTitle>
                {t("OS")}
              </EstimateSubTitle>
              <EstimateDesc>
                {bootSource?.find((boot:any) => boot.value === configureData?.os)?.label}
              </EstimateDesc>
            </>
          : null} */}
          {/* <EstimateSubTitle>
            {t("DISK_TYPE")}
          </EstimateSubTitle>
          <EstimateDesc>
            {}
            Ubuntu 18.04
          </EstimateDesc> */}
        </EstimateContainer>
    </BodyWrapper>
  )
}