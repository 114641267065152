import React, { FC } from "react";
import {
  Container,
  BodyWrapper,
  TextWrapper,
  GreyText,
  BlueText,
  LinkWrapper,
} from './styles'
import Illustration from './illustration.svg'
import { InstanceName } from "../Account-settings/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "../../components";
import { icons } from "../../icons";
import { useTheme } from "styled-components";


const NotFound:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const theme:any = useTheme()
  return(
    <Container>
      <InstanceName>
        {t("ERROR_404")}
      </InstanceName>
      <BodyWrapper>
        <img src={Illustration}/>
        <TextWrapper>
          <Typography variant='h1'>
            {t("404_TITLE")}
          </Typography>
          <GreyText>
            {t("404_SUBTITLE")}
          </GreyText>
          <Button
            variant="primary"
            size='display'
            title={t("GO_TO_HOME")}
            onClick={() => navigate('/home')}
          />
          <LinkWrapper>
            <img src={theme.isDark ? icons.SupportGrey : icons.Support}/>
            <BlueText>
              {t("WRITE_TO_SUPPORT")}
            </BlueText>
          </LinkWrapper>
        </TextWrapper>
      </BodyWrapper>
    </Container>
  )
}

export default NotFound