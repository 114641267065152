import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 30px 20px 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  gap:20px;
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:20px;
  width: 360px;
`