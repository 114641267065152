import styled, { css } from 'styled-components'

type StyleButtonProps = {
  size?: 'display' | 'mid' | 'small' | 'custom',
  customStyles?:any,
  loadingComplete?: boolean,
  loading?: boolean,
}
function styleMyEl(size:any, customStyles?:any) {
  switch (size) {
    case 'display':
      return css`
        height: 40px;
        ${customStyles}
      `;
    case 'mid':
      return css`
        height: 36px;
        ${customStyles}
      `;
    case 'small':
      return css`
        height: 30px;
        padding: 5px 10px;
        ${customStyles}
      `;
    case 'custom':
      return css`
        ${customStyles}
      `;
    default:
      return css`
        height: 30px;
        ${customStyles}
      `;
  }
}
const SecondaryButton = styled.button<StyleButtonProps>`
  background: ${({theme,loadingComplete}) => loadingComplete ? theme.success : theme.primary[50]};
  color: ${({theme}) => theme.primary[700]};
  padding: 10px 16px;
  border:none;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  ${({size, customStyles}) => styleMyEl(size, customStyles)}
  &:hover{
    color: ${({theme}) => theme.primary[500]};
    cursor: pointer;
  }
  &:active{
    background: ${({theme}) => theme.primary[100]};
    color: ${({theme}) => theme.primary[700]};
  }
  &:disabled{
    /* background: #DBDBDB; */
    color:${({theme}) => theme.base.white};
    background: ${({theme, loadingComplete, loading}) => loadingComplete || loading ? '' : theme.isDark ? theme.palette[200] : '#DBDBDB'};
  }
`

export default SecondaryButton
