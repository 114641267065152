import React, { FC } from "react";
import {
  Container,
  BackText,
  ArrowCloseImg,
  OnClickArea
} from './styles'
import Back from './arrowClose.svg'
type BackArrowProps = {
  title:string,
  onClick:() => void,
  customStyles?:any,
}
const BackArrow:FC<BackArrowProps> = ({title, onClick, customStyles}) => {
  return (
    <Container customStyles={customStyles}>
      <OnClickArea onClick={onClick}>
        <ArrowCloseImg src={Back}/>
        <BackText>{title}</BackText>
      </OnClickArea>
    </Container>
  )
}

export default BackArrow