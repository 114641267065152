import styled, {  keyframes, css } from 'styled-components'
import SVG from "react-inlinesvg";
import { BodyMedium, BodyDefault, BodySmall, VerySmall } from "../typography/styled";
type ActiveProp = {
  active?:boolean,
  type?:string,
  isDark?:boolean
}

function styleMyEl(type:any, isDark?:boolean) {
  switch (type) {
    case 'information':
      return css`
        background-color: #F1F4F9;
      `;
    case 'error':
      return css`
        background-color: #FFE5E5;
      `;
    case 'warning':
      return css`
        background: rgba(255, 209, 47, 0.1);;
      `;
    default:
      return css`
        background-color: ${isDark => isDark ? '#F1F4F9' : '#F1F4F9'};
      `;
  }
}

export const IconBackground = styled.div<ActiveProp>`
  ${({type, theme}) => styleMyEl(type, theme.isDark)};
  width:28px;
  height:28px;
  padding: 4px;
  margin-right: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.isDark ? '#393939' : '#F1F4F9'};
`
export const Title = styled(BodyMedium)<ActiveProp>`
  color: ${({theme}) => theme.isDark ? "#E3E3E3" : '#1E1E1E'};
  text-transform: capitalize;
`

export const TitleWrapper = styled.div`
  /* margin-top: 4px; */
  width: 100%;
  display:flex;
  flex-direction: column;
`

export const CloseIcon = styled.img<ActiveProp>`
  width: 20px;
  height: 20px;
  & path {
    stroke:${({theme}) => theme.isDark ? "#E3E3E3" : "#474B53"};
  }
  &:hover {
    cursor: pointer;
  }
`

export const Content = styled(BodyDefault)<ActiveProp>`
  color: ${({isDark}) => isDark ? "#FFF" : "#6C6C6C"};
  word-break: break-word;
`

export const ThinText = styled(VerySmall)`
  color:${({theme}) => theme.grey[600]};
`
type AlertPropsCustom = {
  isDark?:any;
  closing?:any;
  success?:boolean;
}
export const AlertCustom = styled.div<AlertPropsCustom>`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  border: ${({theme}) => theme.isDark ? `1px solid #393939` : '1px solid #E8E8E8'};
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: ${({theme}) => theme.isDark ? "#242424" : '#FFFF'};
`;
