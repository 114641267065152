import styled from "styled-components";
type ActiveProp = {
  active?:boolean,
  activeVideo?:boolean,
  noIframe?:boolean,
  password?:boolean,
}
export const Container = styled.div<ActiveProp>`
  position: fixed;
  width: ${({noIframe}) => noIframe ? '' : "100%"};
  /* height: 100vh; */
  /* box-sizing: border-box; */
  top:0px;
  left: 0px;
  z-index: 12;
  overflow-y: scroll;
  overflow-x: hidden;
  /* display: flex;
  box-sizing: border-box;
  padding-bottom: 180px; */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 2px 2px ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[400]};
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    margin: 0 0 0 0;
  }
  ::-webkit-scrollbar-button{
    display: none;
  }
  border:none;
  background: ${({theme}) => theme.isDark ? 'rgba(0, 0, 0, 0.7);' : 'rgba(27, 27, 27, 0.7)'};
  backdrop-filter: blur(0px);
  /* box-sizing: border-box; */
  height:${({active}) => active ? '100%' : '0px'};
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  opacity:${({active}) => active ? '1' : '0'};
`
export const Wrapper = styled.div`
  /* width:100%; */
  /* height:100%; */
  /* padding-top: 150px; */
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  left: 50%;
  display: flex;
  max-height: 90vh;
  /* overflow-y: scroll; */
  /* position: absolute; */

`

export const Body = styled.div<ActiveProp>`
  transform:${({activeVideo}) => activeVideo ? 'scale(1)' : 'scale(0)'};
  opacity:${({activeVideo}) => activeVideo ? '1' : '0'};
  width: ${({noIframe}) => noIframe  ?  '100%' : ''};
  padding:  ${({noIframe}) => noIframe ? '' : '80px'};
  box-sizing: ${({noIframe}) => noIframe ? '' : 'border-box'};
  height: ${({noIframe}) => noIframe ? '' : '100%'};
  z-index: 22;

  transform-origin: top;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
`
