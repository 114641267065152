import React, { FC, useState, useRef, useEffect } from "react";
import {
  Container,
  NavWrapper,
  HeadWrapper,
  RightHeadContainer
} from './styles'
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useParams } from "react-router-dom";
import { BackArrow, Button, Tabs, TopProgress } from "../../../components";
import {
  Users,
  Details
} from './components'
import { InstanceName } from "../../Account-settings/styles";
import { useUi } from "../../../context/ui.context";
import { timeConverterMMDDYYHHMM } from "../../../utils/dates";
import { clientService } from "../../../api";
import { useAuth } from "../../../context/user.context";
import EditClient from "../client-edit";

const ClientPage:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<string>('1')
  const location = useLocation()
  // const {setClientToInvite} = useAuth()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("DETAILS"),
      slug:'details',
      id:'1'
    },
    {
      title:t("USERS"),
      slug:'users',
      id:'2'
    },
    // {
    //   title:t("PERSONALIZATION"),
    //   slug:'personalization',
    //   id:'3'
    // },
  ])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const { id } = useParams();
  const [client, setClient] = useState<any>()
  const {viewAlert} = useUi()
  useEffect(() => {
    if(id){
      clientService.getClient(id)
      .then((res) => {
        setClient(`${res.data.first_name} ${res.data.last_name}`)
        // setClientToInvite(res.data)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
  },[])
  return(
    <Container>
      {/* <TopProgress/> */}
      {pageLoading ? null :
        <>
          <BackArrow onClick={() => navigate('/clients')} title={t("BACK_TO_CLIENTS")}/>
          <HeadWrapper>
            <InstanceName>
              {client}
            </InstanceName>
          </HeadWrapper>
          <NavWrapper>
            <div>
              <Tabs
                tabs={tabs}
                size='display'
                location={activeTab}
                onChange={setActiveTab}
              />
            </div>
          </NavWrapper>
        </>
      }
      <Routes>
        <Route path="/" element={<Navigate replace to="details" />} /> 
        <Route path='/details' element={<Details/>}/>
        <Route path='/users' element={<Users/>}/>
        {/* <Route path='/client-edit' element={<EditClient/>}/> */}
      </Routes>
    </Container>
  )
}

export default ClientPage

