import styled from 'styled-components'
import { BodyDefault } from '../../../typography/styled'

type ActiveProp = {
  active?:boolean,
  isSmall?:any
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 6;
`

// export const DropDownProfile = styled.div<ActiveProp>`
//   box-sizing: border-box;
//   padding: 4px 0px;
//   right: 26px;
//   top: 65px;
//   z-index: 6;
//   position: absolute;
//   background-color: ${({theme}) => theme.base.white};
//   width: 240px;
//   height: 244px;
//   border:${({theme}) => `1px solid ${theme.grey[350]}`};
//   border-radius:4px;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.1);
//   transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
//   opacity:${({active}) => active ? '1' : '0'};
//   transform-origin: top right;
//   transition-property: transform, opacity;
//   transition-duration: 0.2s;
//   transition-timing-function: ease-out;
// `
export const DropItem = styled.div`
  display: flex;
  padding: 8px 12px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 238px;
  /* height: 36px; */
  align-items: center;
  &:hover{
    cursor: pointer;
    background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[100]};
  }
  &:active{
    background-color: ${({theme}) => theme.isDark ? theme.palette[400] : theme.grey[200]};
    transition: 0.1s background-color ease-in-out;
  }
`
export const Divider = styled.div`
  border: ${({theme}) => `1px solid ${theme.palette[350]}`};
  width:237px;
  margin-top:4px;
  margin-bottom:4px;
`
export const DropTitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  margin-left:6px;
  word-wrap:break-word;
  word-break:break-all;
`