import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { billingService } from "../../../../api";
import { InfoBlock, Select, TopProgress, Typography, Table, Button } from "../../../../components";
import { BodyMedium } from "../../../../components/typography/styled";
import { useUi } from "../../../../context/ui.context";
import { icons } from "../../../../icons";
import { convertUTCtoLocalDate } from "../../../../utils/dates";
import { formatCurrency } from "../../../../utils/display-price";
import {
  Container,
  OptionsWrapper,
  PeriodWapper,
  DateRangeCOntainer,
  PeriodContainer,
  ArrowImage,
  ResourceName,
  ResourceNameContainer,
  TotalPriceWrapper,
  TotalPriceContainer,
  CustomBodyTable,
  CustomBodyTitle,
  Divider,
} from './styles'
import HistorySkeleton from './skeleton'

const BillingHistory:FC = () => {

  const [resBilling, setResBilling] = useState<any>(null)
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {viewAlert} = useUi()
  const [billingCycle, setBillingCycle] = useState<any>(0)
  const theme:any = useTheme()
  const [billingData, setBillingData] = useState<any>()
  const [billingDate, setBillingDate] = useState<any>()
  const [historyList, setHistoryList] = useState<any[]>([])
  const [availableServices, setAvailableServices] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [selectedService, setSelectedService] = useState<any>()

  const selectCycle = (resBilling:any) => {
    setHistoryList(resBilling.data.objects[billingCycle]?.usage?.usage_details)
    setAvailableServices(resBilling.data.available_services.map((service:any) => {
      return {
        ...service,
        value:service.related_dynamic_usage_id,
        label:service.display,
      }
    }))
    setBillingDate(`${convertUTCtoLocalDate(resBilling.data.objects[billingCycle].start_date)} - ${convertUTCtoLocalDate(resBilling.data.objects[billingCycle].billing_end)}`)
    setSelectedService(resBilling.data.available_services?.[0]?.related_dynamic_usage_id)
    setBillingData({
      ...resBilling.data.credit_and_invoicing_info,
      ...resBilling.data.estimated_cycle_cost,
      count:resBilling.data.count -1,
      usage:resBilling.data.objects[billingCycle].usage,
    })
  }
  useEffect(() => {
    if(resBilling){
      selectCycle(resBilling)
    }
  },[billingCycle])
  const getBillingData = () => {
    billingService.getBillingHistory()
    .then((res) => {
      setResBilling(res)
      selectCycle(res)
      setPageLoading(false)
    })
    .catch((err) => {
      console.log('err', err);
      if(err?.response?.data?.detail){
        viewAlert({
          severity: 'error',
          message: err.response.data.detail
        })
      }
      // setPageLoading(false)
      // navigate(-1)
    })
  }
  useEffect(() => {
    getBillingData()
  },[])
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("PRICE"),
      key:'price'
    },
  ]
  if(pageLoading) return <HistorySkeleton pageLoading={pageLoading}/>
  return (
    <Container>
      <Typography variant="h1">
        {t("BILLING_HISTORY")}
      </Typography>
      <OptionsWrapper>
        <Select
          data={availableServices}
          title={t("SERVICES")}
          selectedValue={selectedService}
          onChange={(e) => setSelectedService(e)}
        
        />
        <PeriodContainer>
          <Typography variant='body-medium'>
            {t("PERIOD")}
          </Typography>
          <PeriodWapper>
            <Button
              onClick={() => setBillingCycle(billingCycle+1)}
              variant='icon'
              size='display'
              disabled={billingData.count == billingCycle}
              customStyles={{ width: '30px', backgroundColor:theme.palette[10], height: "30px"}}
            >
              <ArrowImage src={icons.ArrowLeft}/>
            </Button>
            <DateRangeCOntainer>
              <Typography variant='body'>
                {billingDate}
              </Typography>
            </DateRangeCOntainer>
            <Button
              onClick={() => setBillingCycle(billingCycle-1)}
              variant='icon'
              size='display'
              disabled={billingCycle === 0}
              customStyles={{ width: '30px', backgroundColor:theme.palette[10], height: "30px"}}
            >
              <ArrowImage src={icons.ArrowRight}/>
            </Button>
          </PeriodWapper>
        </PeriodContainer>
      </OptionsWrapper>
      {historyList?.map((history) => (
        <InfoBlock
          dropped
          noTitles
          //@ts-ignore
          data={[]}
          customDropped={
            <Table
              columns={Columns}
              hasDroppedRow
              rows={history.usage.map((usageItem:any) => {
                return{
                  ...usageItem,
                  hasHistory:usageItem.history.length > 0,
                  customBody:(
                    <CustomBodyTable>
                      <CustomBodyTitle>
                        {t("USAGE_HISTORY")}
                      </CustomBodyTitle>
                      {usageItem.history.map((usageHistoryItem:any) => (
                        <DateRangeCOntainer>
                          <Typography  variant="body">
                            {`${usageHistoryItem.name} - ${usageHistoryItem.price_details.timespan} `}
                          </Typography>
                          <BodyMedium style={{whiteSpace:'nowrap'}}>{formatCurrency(usageHistoryItem.price, billingData.usage?.currency?.code)}</BodyMedium>
                        </DateRangeCOntainer>
                      ))}
                    </CustomBodyTable>
                  ),
                  price:formatCurrency(usageItem.price, billingData.usage?.currency?.code),
                  name:history.resource_name === 'Floating IPs' || history.resource_name === 'Volume' ? 
                    (
                      <ResourceNameContainer>
                        <Typography variant='body'>
                          {usageItem.display_name}
                        </Typography>
                      </ResourceNameContainer>
                    )
                  :
                    (
                      <ResourceNameContainer>
                        <ResourceName>
                          {history.resource_name}:
                        </ResourceName>
                        <Typography variant='body'>
                          {usageItem.display_name}
                        </Typography>
                      </ResourceNameContainer>
                    )
                }
              })}
              hideOther
              hideCheckbox
            />
          }
          title={<div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:"center"}}>
            <Typography variant="h3">
            {history.resource_name}
            </Typography>
            <Typography variant="body">
              {formatCurrency(history.price, billingData.usage?.currency?.code)}
            </Typography>
          </div>}
          customStyles={{flex:'auto 1', marginRight:'10px'}}
        />
      ))}
      <TotalPriceWrapper>
        <TotalPriceContainer>
          <Typography variant='body'>
            {t("TOTAL_PRICE")}
          </Typography>
          <Typography variant='h3'>
            {formatCurrency(Number(billingData.usage?.price)?.toFixed(2), billingData.usage?.currency?.code)}
          </Typography>
        </TotalPriceContainer>
        {billingCycle === 0 ? <Divider/> : null}
        {billingCycle === 0 ?
          <TotalPriceContainer>
            <Typography variant='body'>
              {t("ESTIMATED_CYCLE_HOST")}
            </Typography>
            <Typography variant='h3'>
              {formatCurrency(billingData?.amount, billingData.usage?.currency?.code)}
            </Typography>
          </TotalPriceContainer>
        : null}
      </TotalPriceWrapper>
    </Container>
  )
}

export default BillingHistory

