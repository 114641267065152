import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import TopProgress from "../../TopBarProgress";
import { portService } from "../../../api";
import { useUi } from "../../../context/ui.context";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  loading?:boolean;
  dataToEdit?:any;
  alertTitle?:any;
  viewModal?:boolean;
}  


const AttachPort:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    loading,
    dataToEdit,
    alertTitle,
    viewModal,
  } = props
  const {
    viewAlert
  } = useUi()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    port:'',
  })
  const [modalLoading, setModalLoading] = useState<boolean>(true)
  const [ports, setPorts] = useState<any[]>([])

  useEffect(() => {
    setModalLoading(true)
    portService.getPortsForAttach({
      filtering: 'project__project_id:' + dataToEdit.project + '+network__region:'
      + dataToEdit.region + '+device_id__exact:'
    })
    .then((res) => {
      setPorts(res.data.objects.map((obj:any) => {
        return {
          ...obj,
          value:obj.id,
          label:obj.name || obj.id,
        }
      }))
      if(res.data.objects?.length === 0){
        viewAlert({
          severity:"error",
          message:`${t("NO_FREE_PORTS_AVAILABLE")}`
        })
        closeModalCancel()
      }
      setModalLoading(false)
    })
    .catch((e) => {
      setModalLoading(false)
      closeModalCancel()
    })
  },[viewModal])
  const onSubmit = (values:any) => {
    closeModal(values)
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      port: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  if(modalLoading) return <TopProgress loading={modalLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {`${t("ATTACH_PORT_TO")} ${alertTitle}`}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Select
          required
          toolTip={true}
          data={ports}
          selectedValue={values['port']}
          onChange={(e) => handleChange('port')(e)}
          title={t("SELECT_PORT")}
          customStyles={{width:'360px'}}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("ATTACH")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default AttachPort