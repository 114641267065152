import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  CheckboxWrapper,
  CheckboxContainer,
  DatePickerWrapper
} from "./styles";
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import CheckBox from "../../checkbox";
import { useUi } from "../../../context/ui.context";
import { objStorageService } from "../../../api";
import TopProgress from "../../TopBarProgress";
import { useParams } from "react-router-dom";
import {DatePicker} from "@nextui-org/date-picker";
import {now, getLocalTimeZone} from "@internationalized/date";

import './index.css'
import copy from "copy-to-clipboard";

type ModalProps = {
  closeModalCancel: () => void;
  closeModal: (data: any) => void;
  alertTitle: string;
  instance?: any;
  loading?: boolean;
  loadingComplete?: boolean;
};

const BucketСopyFileLink: FC<ModalProps> = (props) => {
  const { closeModal, closeModalCancel, alertTitle, instance} = props;
  const [t] = useTranslation();
  const [visibility, setVisibility] = useState<string>("private");
  const [expire, setExpire] = useState<any>()
  const [loading,setLoading] = useState<boolean>(false)
  const {bucketId} = useParams()
  const {viewAlert} = useUi()
  const getLink = async () => {
    try{
      if(bucketId){
        setLoading(true)
        const res = await objStorageService.getFileInfo(bucketId, instance.name, expire)
          copy(res.data.Link)
          viewAlert({
            severity:'information',
            message:`${t("COPIED")}`
          })
        setLoading(false)

      }
      closeModalCancel()
    } catch (err:any) {
      console.log('errGET_PUBLIC_LINK',err);
      setLoading(false)
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    }
  }
  // console.log('date', new Date);
  return (
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{ marginLeft: "10px", marginTop: "10px" }}>
          {t("GENERATE_FILE_PUBLIC_LINK")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon} />
      </HeadWrapper>
      <BodyModal>
        <DatePickerWrapper>
          <DatePicker
            label={t("EXPIRATION_DATE")}
            variant="bordered"
            className="max-w-[284px]" 
            onChange={(e:any) => {
              console.log('EXPIRATION_DATE',e);
              const date = new Date(
                Date.UTC(
                  e.year,
                  e.month - 1, // В JS месяцы начинаются с 0
                  e.day,
                  e.hour,
                  e.minute,
                  e.second,
                  e.millisecond
                )
              );
              
              // Учитываем смещение часового пояса
              const utcTime = date.getTime() - e.offset;
              
              // Преобразуем в секунды
              const seconds = Math.floor(utcTime / 1000);
              console.log('seconds',seconds);
              setExpire(seconds)
            }}
            hideTimeZone
            showMonthAndYearPickers
            defaultValue={now(getLocalTimeZone())}
          />
        </DatePickerWrapper>
        <div style={{ display: "flex", gap: "6px", marginTop: "30px" }}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: "177px", justifyContent: "center" }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: "177px", justifyContent: "center" }}
            onClick={getLink}
            // loadingComplete={loadingComplete}
            title={t("COPY")}
          />
        </div>
      </BodyModal>
    </Container>
  );
};

export default BucketСopyFileLink;
