import styled from "styled-components";
import { BodySmall, HeadLine1 } from '../../../components/typography/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 30px 30px;
  /* position: relative; */
`
export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top:10px;
  justify-content: space-between;
  width:100%;
`

export const RightHeadContainer = styled.div`
  display:flex;
  margin-right: 10px;
  position: relative;
`

export const InstanceName = styled(HeadLine1)`
  color:${({theme}) => theme.palette[800]};
  word-break:break-all;
  margin-bottom:10px;
  word-break: break-word;
`
export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  display: flex;
  margin-top:50px;
  &>div{
    position: absolute;
    bottom:-1px;
    left:0px;
  }
`
export const InstanceHeadInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
type StatusProp = {
  active?:boolean
}
export const InstanceDot = styled.div<StatusProp>`
  width:12px;
  height:12px;
  background-color: ${({active}) => active ? '#229B61' : ''};
  border-radius: 100%;
`
export const InstanceStatus = styled(BodySmall)<StatusProp>`
  color: ${({active}) => active ? '#229B61' : ''};
  margin-left: 6px;
  margin-right: 20px;
`

export const InstanceType = styled(BodySmall)`
  margin-right: 6px;
  color:${({theme}) => theme.palette[650]};
`
