import React, { FC, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import {
  BigAlert, Button, ModalVariables,
} from '../../components'
import { AlertProps, useUi } from '../../context/ui.context'
import {
  Container,
  WelcomeTitle,
  ErrorContainer,
  ErrorText,
} from './styles'
import {
  Diagrams,
  ActivitiesLogs,
  NavComponents,
  GettingStarted,
  Services,
} from './components'
import { useQueryParam, StringParam } from 'use-query-params';
import { useAuth } from '../../context/user.context'
import { useTranslation } from 'react-i18next'
import AlertIcon from './error.svg'
import { useNavigate } from 'react-router-dom'
import { billingService } from '../../api'
import config from '../../config'
import {Helmet} from "react-helmet";

const HomePage:FC = () => {
  const {user} = useAuth()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [invoiceId, setInvoiceId] = useQueryParam('invoiceId', StringParam);
  const [invoice, setInvoice] = useState<any>()
  const [remainingInfo, setRemainingInfo] = useState<any>({})
  const closeModal = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const checkBillingInfo = async () => {
    const res = await billingService.getRemainingInfo()
    await setRemainingInfo(res.data)
  }
  useEffect(() => {
    checkBillingInfo()
    if(invoiceId){
      openModal('payment-success')
      setInvoice(invoiceId)
      setInvoiceId(null)
    }
  },[])
  return (
    <Container>
      {/* <Helmet>
        <script src="https://script.tapfiliate.com/tapfiliate.js" type="text/javascript" async></script>
        <script type="text/javascript">
          {`(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');

          tap('create', '47976-36852c', { integration: "javascript" });
          tap('customer', ${user?.user?.email});`}
        </script>
      </Helmet> */}
      <div style={{paddingLeft:'20px',paddingBottom:'0px',width:'100%', minHeight:'100%'}}>
        <WelcomeTitle>
        {t("WELCOME")} {user.user.first_name}!
        </WelcomeTitle>
        <BigAlert
          severity={t("GETTING_STARTED_ALERT")}
          message={t("GETTING_STARTED_ALERT_DESC")}
          onClick={() => window.open(config.docs_url)}
        />
        {remainingInfo?.reached_credit_limit &&
          <ErrorContainer isError={true}>
            <div style={{display:'flex', alignItems:"center", gap:'6px'}}>
              <img src={AlertIcon}/>
              <ErrorText>{t("ERROR_OUT_OF_CRADIT")}</ErrorText>
            </div>
            <Button
              title={t("GO_TO_BILLING")}
              onClick={() => navigate('/billing')}
              size='small'
              variant='primary'
            />
          </ErrorContainer>
        }
        <Diagrams/>
        <NavComponents/>
        <Services/>
        {/* <div style={{display:'flex', marginRight:'10px'}}> */}
          {/* <ActivitiesLogs/> */}
          <GettingStarted/>
        {/* </div> */}
      </div>
      <ModalVariables
        modalType={modalType}
        closeModalCancel={closeModalCancel}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        alertSubTitle={invoice}
      />
    </Container>
  )
}

export default HomePage
