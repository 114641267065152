import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { HeadLine3 } from "../../typography/styled";
import { InfoContainer, InfoTitle } from "../../info-block/styles";
import TopProgress from "../../TopBarProgress";
import { bareMetalService } from "../../../api";
import Table from "../../table";
import { formatFileSize, formatTrafficSize, formatFileTrafficSize } from "../../../utils/sizes";
import { convertUTCtoLocalDate } from "../../../utils/dates";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:() => void;
  alertTitle?:any;
  alertSubTitle?:any;
  confirmButtonText?:any;
  cancelButtonText?:any;
  loading?:boolean;
  instanceId?:any;
}  

const BareMetalBreakDown:FC<ModalProps> = (props) => {
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [data, setData] = useState<any[]>([])
  const [details, setDetails] = useState<any[]>([])
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    alertSubTitle,
    loading,
    confirmButtonText,
    cancelButtonText,
    instanceId,
  } = props
  const [t] = useTranslation()
  useEffect(() => {
    if(instanceId){
      bareMetalService.getBreakDown(instanceId)
      .then((res) => {
        console.log('res',res)
        setPageLoading(false)
        setDetails(res.data?.[0]?.stats.map((stat:any) => {
          return {
            ...stat,
            date: convertUTCtoLocalDate(stat.time),
            in:(
              <div style={{display:"flex", flexDirection:'column', gap:'3px'}}>
                <Typography variant="body">{formatFileTrafficSize(stat.in)}</Typography>
                <Typography variant='very-small'>{formatFileTrafficSize(stat.bwrate_in_95)}</Typography>
              </div>
              ),
            out:(
              <div style={{display:"flex", flexDirection:'column', gap:'3px'}}>
                <Typography variant="body">{formatFileTrafficSize(stat.out)}</Typography>
                <Typography variant='very-small'>{formatFileTrafficSize(stat.bwrate_out_95)}</Typography>
              </div>
            ),
            total:(
              <div style={{display:"flex", flexDirection:'column', gap:'3px'}}>
                <Typography variant="body">{formatFileTrafficSize(stat.total)}</Typography>
                <Typography variant='very-small'>{formatFileTrafficSize(stat.bwrate_total_95)}</Typography>
              </div>
            ),

          }
        }))
        setData(res.data?.[0]?.summarized.map((sum:any) => {
          return {
            date:sum.time,
            data:[{
            ...sum,
            // in:sum.in / (1024 * 1024 * 1024),
            in:formatFileTrafficSize(sum.in),
            out:formatFileTrafficSize(sum.out),
            total:formatFileTrafficSize(sum.total),
            bwrate_in_95:formatFileTrafficSize(sum.bwrate_in_95),
            bwrate_out_95:formatFileTrafficSize(sum.bwrate_out_95),
            bwrate_total_95:formatFileTrafficSize(sum.bwrate_total_95),
          }]
          }
        }))
      })
      .catch((err) => {
        console.log('err',err);
        setPageLoading(false)
      })
    }
  },[])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("TRAFFIC_USAGE_BREAKDOWN")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <InfoContainer 
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap:'10px'
          }}
        >
          {data.map((item) => (
            <>
              <InfoTitle>
                {convertUTCtoLocalDate(item.date)}
              </InfoTitle>
              <Table
                columns={[
                  {title:'Traffic In', key:'in'},
                  {title:'Traffic Out', key:"out"},
                  {title:'Total', key:"total"},
                ]}
                rows={item.data}
                hideOther
                hideCheckbox
              />
              <Table
                columns={[
                  {title:'95th In', key:'bwrate_in_95'},
                  {title:'95th Out', key:"bwrate_out_95"},
                  {title:'Total', key:"bwrate_total_95"},
                ]}
                rows={item.data}
                hideOther
                hideCheckbox
              />
            </>
          ))}

          <InfoTitle>
            Details
          </InfoTitle>
          <Table
            columns={[
              {title:'Date (UTC)', key:'date'},
              {title:'In / 95th', key:"in"},
              {title:'Out / 95th', key:"out"},
              {title:'Total / 95th', key:"total"},
            ]}
            rows={details}
            hideOther
            hideCheckbox
          />
        </InfoContainer>
          <Button
            variant="stroke"
            size="display"
            onClick={closeModalCancel}
            customStyles={{marginTop:'20px', justifyContent: 'center'}}
            title={t("CLOSE")}
          />
      </BodyModal>
    </Container>
  )
}

export default BareMetalBreakDown