import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoBlock } from '../../../../../components'
import {
  Container,
  TopInfoWrapper
} from '../pools/styles'
import {timeConverterMMDDYYHHMM} from '../../../../../utils/dates'
import { handleStatusName } from '../../../../../utils/handle-status-name'
import { GreenDot, Greentext, StateWrapper } from '../../../../Instances/styles'

const Overview:FC<any> = ({lb}) => {
  const [t] = useTranslation()

  const specifications = [
    {
      title:t("IP_ADDRESS"),
      id:'1',
      value:lb?.vip_address || '-'
    },
    {
      title:t("AVAILABILITY_ZONE"),
      id:'2',
      value:lb?.availability_zone || 'n/a'
    },
    {
      title:t("OPERATING_STATUS"),
      id:'3',
      value: (
      <StateWrapper>
        <GreenDot active={lb.operating_status}/>
        <Greentext active={lb.operating_status}>
          {handleStatusName(lb.operating_status, t)}
        </Greentext>
      </StateWrapper>) 
    },
    {
      title:t("PROVISIONING_STATUS"),
      id:'4',
      value: (
      <StateWrapper>
        <GreenDot active={lb.provisioning_status}/>
        <Greentext active={lb.provisioning_status}>
          {handleStatusName(lb.provisioning_status, t)}
        </Greentext>
      </StateWrapper>)
    },
    {
      title:t("ADMIN_STATE"),
      id:'14',
      value:lb?.admin_state_up ? 'Yes' : 'No'
    },
    {
      title:t("DESCRIPTION"),
      id:'5',
      value:lb?.description || '-'
    },
    {
      title:t("CREATED_AT"),
      id:'6',
      value: timeConverterMMDDYYHHMM(lb?.created_at)
    },
    {
      title:t("UPDATED_AT"),
      id:'7',
      value:timeConverterMMDDYYHHMM(lb?.updated_at)
    },
    {
      title:t("PORT_ID"),
      id:'8',
      value:lb?.vip_port_id || '-',
      copy:true,
    },
    {
      title:t("SUBNET_ID"),
      id:'9',
      value:lb?.vip_subnet_id || '-',
      copy:true,
    },
    {
      title:t("NETWORK_ID"),
      id:'10',
      value:lb?.vip_network_id || '-',
      copy:true,
    },
    {
      title:t("PROVIDER"),
      id:'11',
      value:lb?.provider || '-'
    },
    {
      title:t("FLAVOR_ID"),
      id:'12',
      value:lb?.flavor_id || '-',
      copy:true,
    },
    {
      title:t("FLOATING_IP"),
      id:'13',
      value:lb?.floating_ip || '-'
    },

  ]
  return(
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          title={t("SPECIFICATIONS")}
          data={specifications}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1'}}
        />
      </TopInfoWrapper>
    </Container>
  )
}
export default Overview
