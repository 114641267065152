import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding-top:20px;
  height:100%;
  align-items: flex-start;
  padding-right:10px;
  flex-direction: column;
  gap:20px;
`
