import styled from "styled-components";
import { BodyDefault } from "../typography/styled";
type Donut = {
  isLeft?:boolean,
  part1?:any,
  part2?:any,
}
export const Container = styled.div`
  display: flex;
`

export const Donut = styled.div<Donut>`
	width: 60px;
  height: 60px;
	border-radius: 50%;
  box-sizing: border-box;
	border:${({theme}) => `10px solid ${theme.isDark ? theme.palette[350] : theme.grey[350]}`};
  position: absolute;
  &::before {
    content: "";
    position: absolute;
    inset:0;
    width: 60px;
    height: 60px;
    left:-10px;
    top:-10px;
    border-radius: 50%;
    transition: all 0.5s ease-in;
    background: ${({isLeft, part1, part2, theme}) => {
      return theme.isDark ?
       `conic-gradient(
        ${isLeft ? '#229B61' : '#8BABF8'} 0deg ${part1}%,
        ${isLeft ? '#5F6A65' : '#656872'} 0deg ${part2}%, #0000 0
        )`
      :
       `conic-gradient(
        ${isLeft ? '#229B61' : '#193E8A'} 0deg ${part1}%,
        ${isLeft ? '#9BD0B7' : '#8BABF8'} 0deg ${part2}%, #0000 0
        )`
    }};
  }
`
export const AnimationWrapper = styled.div`
  position:relative;
  margin-bottom: 10px;
  display: flex;
  width: 60px;
  height: 60px;
`
export const DonutWrapper = styled.div`
  display:flex;
  flex-direction: column;
  width: 109px;
`

export const DonutBackground = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top:10px;
  z-index: 10;
  left:10px;
  position: absolute;
  background-color: ${({theme}) => theme.palette[10]};
  
`
export const DiagramTitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  margin-right: 4px;
`
export const DiagramTitleGrey = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
`
export const PieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 15px;
  width: 260px;
  height: 182px;
  background: ${({theme}) => theme.grey[100]};
  border-radius: 8px;

`