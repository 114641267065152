export const statusColor = (status?: string) => {
  switch (status?.toLowerCase()) {
    case 'saving':
      return "#E8B533";
    case 'disabled':
      return "#E85048";
    case 'queued':
      return "#E8B533";
    case 'booted_from_iso':
      return "#E8B533";
    case "stopped":
      return "#E85048";
    case "inactive":
      return "#E85048";
    case "down":
      return "#E85048";
    case "deleting":
      return "#E85048";
    case "blocked":
      return "#E85048";
    case "error":
      return "#E85048";
    case "unknown":
      return "#E8B533";
    case "offline":
      return "#E85048";
    case "suspended":
      return "#E85048";
    case "failed":
      return "#E85048";
    case "aborted":
      return "#E85048";
    case "running":
      return "#229B61";
    case "open":
      return "#229B61";
    case "starting":
      return "#E8B533";
    case "stopping":
      return "#E8B533";
    case "reboot":
      return "#E8B533";
    case "rebuild":
      return "#E8B533";
    case "in progress":
      return "#E8B533";
    case "verify resize":
      return "#E8B533";
    case "verify_resize":
      return "#E8B533";
    case "resized":
      return "#E8B533";
    case "building":
      return "#E8B533";
    case "resize":
      return "#E8B533";
    case "rescue":
      return "#E8B533";
    case "resizing":
      return "#E8B533";
    case "shelved offloaded":
      return "#E8B533";
    case "shelved_offloaded":
      return "#E8B533";
    case "revert resize":
      return "#E8B533";
    case "rescued":
      return "#E8B533";
    case "PENDING_CREATE".toLocaleLowerCase():
      return "#E8B533";
    case "PENDING_DELETE".toLocaleLowerCase():
      return "#E8B533";
    default:
      return "#229B61";
  }
}