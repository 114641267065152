import React, { FC, useEffect, useState } from "react";
import {
  Container,
} from './styles'
import {
  Button,
  Input,
  Select,
  RevealButton,
  Toggle,
  TopProgress,
} from "../../../../components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { loadBalancerService } from "../../../../api";
import { Title } from "../../../Instances/styles";
import { useUi } from "../../../../context/ui.context";


const EditListener:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {listenerId, lbId} = useParams()
  const {viewAlert} = useUi()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [admin, setAdmin] = useState<boolean>(true)
  const [listener, setListener] = useState<any>({})
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [initialValues, setInitialValues] = useState<any>({
    connection_limit: -1,
    description: "",
    name: "",
    timeout_client_data: 50000,
    timeout_member_connect: 5000,
    timeout_member_data: 50000,
    timeout_tcp_inspect: '0',
    default_pool_id: ''
  })
  const [pools, setPools] = useState<any[]>([])


  const getCreateOptions = async () => {
    if(!listenerId || !lbId) return;
    try{
      const listenerRes = await loadBalancerService.getListenerById(listenerId)
      await setListener(listenerRes.data)
      await setInitialValues({
        connection_limit: listenerRes?.data?.connection_limit || -1,
        description: listenerRes?.data?.description || "",
        name: listenerRes?.data?.name || "",
        timeout_client_data: listenerRes?.data?.timeout_client_data || 50000,
        timeout_member_connect: listenerRes?.data?.timeout_member_connect || 5000,
        timeout_member_data: listenerRes?.data?.timeout_member_data || 50000,
        timeout_tcp_inspect: listenerRes?.data?.timeout_tcp_inspect || '0',
        default_pool_id:listenerRes?.data?.default_pool_id || ''
      })
      await setAdmin(listenerRes?.data?.admin_state_up)
      const poolRes = await loadBalancerService.getPools(lbId)
      await setPools(poolRes.data.objects.map((pool:any) => {
        return {
          ...pool,
          value: pool.id,
          label: pool.id
        }
      }))
    } catch (e:any) {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    } finally {
      setPageLoading(false)
    }

  }

  useEffect(() => {
    getCreateOptions()
  },[])
  const onSubmit = async (values:any) => {
    if(!listenerId) return;
    setLoading(true)
    try{
      const data = {
        ...values,
        admin_state_up:admin,
      }
      await loadBalancerService.editListener(listenerId, data)
      await setLoadingComplete(true)
      await setTimeout(() => {
        navigate(-1)
        viewAlert({
          severity:'info',
          message:`${t("LISTENER_EDIT_SUCCESS")}`,
        })
      }, 1500)
    } catch (e:any) {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    } finally {
      setPageLoading(false)
    }

  }

  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
        .required(`${t('REQUIRED_FIELD')}`)
        .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
      timeout_client_data: yup.number(),
      timeout_tcp_inspect: yup.number(),
      timeout_member_connect: yup.number(),
      timeout_member_data: yup.number(),
      connection_limit: yup.number(),
      default_pool_id: yup.string()
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      <Title>{t("EDIT_FOR")} «{listener?.name || listener?.id}»</Title>
      <Input
        fieldName='name'
        required
        title={t("NAME")}
        placeholder=''
        customStyles={{width:'360px'}}
        {...formikProps}
      />
      <Input
        isTextArea
        fieldName='description'
        title={t("DESCRIPTION")}
        placeholder=''
        customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
        {...formikProps}
      />
      <Toggle value={admin} onChange={() => setAdmin(!admin)} title={t("ADMIN_STATE_UP")}/>

      <RevealButton
        title={t("ADDITIONAL_SETTINGS")}
      >
        <div style={{display:'flex', marginBottom:"20px", gap:'20px'}}>
          <Input
            fieldName='timeout_client_data'
            title={t("CLIENT_DATA_TIMEOUT")}
            type={'number'}
            placeholder=''
            customStyles={{width:'245px'}}
            {...formikProps}
          />
          <Input
            fieldName='timeout_tcp_inspect'
            title={t("TCP_INSPECT_TIMEOUT")}
            type={'number'}
            placeholder=''
            customStyles={{width:'245px'}}
            {...formikProps}
          />
          <Input
            fieldName='timeout_member_connect'
            title={t("MEMBER_CONNECT_TIMEOUT")}
            type={'number'}
            placeholder=''
            customStyles={{width:'245px'}}
            {...formikProps}
          />
        </div>
        <div style={{display:'flex', marginBottom:"20px", gap:'20px'}}>
          <Input
            fieldName='timeout_member_data'
            title={t("MEMBER_DATA_TIMEOUT")}
            type={'number'}
            placeholder=''
            customStyles={{width:'245px'}}
            {...formikProps}
          />
          <Input
            fieldName='connection_limit'
            title={t("CONNECTION_LIMIT")}
            type={'number'}
            placeholder=''
            customStyles={{width:'245px'}}
            {...formikProps}
          />
          <Select
            toolTip={false}
            data={pools}
            fieldName='default_pool_id'
            selectedValue={values['default_pool_id']}
            onChange={(e) => handleChange('default_pool_id')(e)}
            title={t("DEFAULT_POOL_ID")}
            customStyles={{width:'245px'}}
            {...formikProps}
          />
        </div>
      </RevealButton>
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("SAVE")}
          loading={loading}
          loadingComplete={loadingComplete}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate(-1)}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default EditListener