import axios from "axios";
import config from "../config";
import {Buffer} from 'buffer';

const supportClient = axios.create({
  baseURL: config.supportPalUrl,
  withCredentials: true,
  headers:{
    Authorization:'Basic ' + Buffer.from(config.supportPalToken + ':X').toString('base64'),
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

const items = ['','','','','','','','','true','','','','','','','']; // Ваш массив
const index = 8; // Индекс элемента, который вы хотите отметить


const supportPalService = {
  getTicketById:(ticketId:string) => supportClient.get(`ticket/ticket/${ticketId}`),
  editTicketById:(ticketId:string, data:Object) => supportClient.put(`ticket/ticket/${ticketId}`, data),
  getTicketMessagesById:(ticketId:string) => supportClient.get(`ticket/message?ticket_id=${ticketId}`),
  getTicketList:(queries:any) => supportClient.get(`ticket/ticket`, {
    params:{
      ...queries,
      
      // order_column:'created_at'
    }
  }),
  getTicketListCluster:(queries:any) => supportClient.get(`ticket/ticket`, {
    params:{
      ...queries,
      [`customfield[${index}]`]: items[index]
      // order_column:'created_at'
    }
  }),
  createTicket:(data:Object) => supportClient.post('ticket/ticket', data),
  getDepartments:() => supportClient.get('ticket/department'),
  getPriority:() => supportClient.get('ticket/priority'),
  getStatus:() => supportClient.get('ticket/status'),
  addMessageToTicket:(data:Object) => supportClient.post(`ticket/message`, data),
  downloadAttachment:(attachmentId:any) => supportClient.get(`ticket/attachment/${attachmentId}/download`),
  getCustomFields:() => supportClient.get('ticket/customfield')
}

export default supportPalService