import styled from 'styled-components';
import { BodySmallMedium } from '../../../../components/typography/styled';
import { statusColor } from '../../../../utils/statusColor';
type ActiveProp = {
  status?:string,
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 30px 30px;
  box-sizing: border-box;
  position: relative;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
`

export const RowHeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  width:100%;
`

export const RightSideComponent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

`

export const StatusDot = styled.div<ActiveProp>`
  width:12px;
  height:12px;
  background-color: ${({status}) => statusColor(status)};
  border-radius: 100%;
`
export const StatusText = styled(BodySmallMedium)<ActiveProp>`
  color:${({status}) => statusColor(status)};
  margin-left: 6px;
  margin-right: 5px;
  text-transform:capitalize;
`

export const StatusContainer = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
`

export const ServiceBody = styled.div`
  padding-top:20px;
  display: flex;
  flex-direction:column;
  align-items:flex-start;
  width: 100%;
  /* box-sizing: border-box; */
  gap:10px;
`
export const TopInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  /* flex-wrap: wrap; */
  gap: 10px;
`

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  /* margin-top: 10px; */
  gap:10px;
`