import Skeleton from 'react-loading-skeleton';
import styled, { useTheme } from 'styled-components';
import {
  Container,
  BodyTitle,
  RowWrapper,
  TableCustom,
  Tr,
  Row
} from "../table/styles";
type TableSkeletonProps = {
  rows: number;
  columns: number;
  columnsData?:any[];
}
const Thead = styled.thead`
  /* display: flex;
  align-items: center; */
  height: 44.5px;
  /* padding-left: 10px; */
  background-color: ${({theme}) => theme.palette[200]};
  border-collapse: none;
`
const StyledSkeleton = styled(Skeleton)`
  background-color: ${({theme}) => theme.palette[200]};
`
const TableSkeleton = ({ rows, columns }: TableSkeletonProps) => {
  const colWidths = `${100 / columns}%`.repeat(columns);
  const theme:any = useTheme()
  return (
    <TableCustom style={{marginTop:'15px'}}>
      <Thead>
        <Tr>
          {Array.from({ length: columns }, (_, i) => (
            <th key={i}>
              {/* <Skeleton /> */}
              </th>
          ))}
        </Tr>
      </Thead>
      <tbody>
        {Array.from({ length: rows }, (_, i) => (
          <tr key={i}>
            {Array.from({ length: columns }, (_, j) => (
              //@ts-ignore
              <Row style={{height:'41px'}} key={j} style={{ width: colWidths }}>
                <StyledSkeleton
                  baseColor={theme.palette[300]}
                  highlightColor={theme.palette[20]}
                />
              </Row>
            ))}
          </tr>
        ))}
      </tbody>
    </TableCustom>
  );
}

export default TableSkeleton;
