import styled from 'styled-components';

var size = 500
export const HeadLine1 = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  margin:0px;
  padding:0px;
  color:${({theme}) => theme.palette[900]};
`
export const HeadLine2 = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin:0px;
  padding:0px;
  color:${({theme}) => theme.palette[900]};
`
export const HeadLine3 = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin:0px;
  padding:0px;
  color:${({theme}) => theme.palette[900]};
`

export const BodyDefault = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin:0px;
  padding:0px;
  color:${({theme}) => theme.palette[900]};
`
export const BodyMedium = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin:0px;
  padding:0px;
  color:${({theme}) => theme.palette[900]};
`
export const BodySmall = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin:0px;
  padding:0px;
  color:${({theme}) => theme.palette[900]};
`
export const VerySmall = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin:0px;
  padding:0px;
  color:${({theme}) => theme.palette[900]};
`
export const BodySmallMedium = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin:0px;
  padding:0px;
  color:${({theme}) => theme.palette[900]};
`