import React, { FC, useEffect, useState } from "react";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { HeadLine3 } from "../../typography/styled";
import Counter from "../../counter";
import { useAuth } from "../../../context/user.context";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any, setErrors?:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
}  

const ExtendVolume:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    loading
  } = props
  const {quotas} = useAuth()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    size:instance?.size,
  })
  const onSubmit = (values:any) => {
    closeModal(values, setErrors)
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      size: yup.number().required(`${t("REQUIRED_FIELD")}`)
      .min(instance?.size+1, `${t("MIN_COUNT_NUMBER")} ${instance?.size+1}`)
      .max(quotas?.volume?.gigabytes?.limit - quotas?.volume?.gigabytes?.in_use, `${t("MAX_COUNT_MACHINES")} ${quotas?.volume?.gigabytes?.limit - quotas?.volume?.gigabytes?.in_use}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  useEffect(() => {
    setInitialValues({
      size:instance?.size,
    })
  },[instance])
  const formikProps = {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange
  }
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {alertTitle}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Counter
          required
          // customStyles={{marginBottom:"20px"}}
          title={t("STORAGE")}
          value={values['size']}
          minCount={instance?.size}
          hideItems={'GB'}
          onChange={(v:any) => {
            handleChange('size')(v.toString())}}
          fieldName={'size'}
          {...formikProps}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("EXTEND")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default ExtendVolume