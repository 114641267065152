import styled from "styled-components";

type ActiveProp = {
  active?:boolean;
  isArrow?:boolean;
  disabled?:boolean;
}

export const Container = styled.div`
  display: flex;
  position: relative;
  /* width: 100%; */
`
export const ItemWrapper = styled.div`
  position: relative;
`
export const PaginationItem = styled.div<ActiveProp>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 6px;
  width: 30px;
  height: 30px;
  margin: 2.5px;
  color:${({theme, active}) => active ? theme.isDark ? theme.base.black : theme.primary[600] : theme.base.black};
  border: ${({theme, disabled, isArrow}) => !disabled && isArrow ? `1px solid ${theme.grey[300]}` : ''};
  border-radius: 4px;
  box-shadow: ${({isArrow, disabled}) => isArrow && !disabled ? '0px 1px 2px rgba(0, 0, 0, 0.1)' : ''};
  background: ${({theme, active}) => active ? theme.isDark ? '#4671F6' : theme.primary[50] : theme.palette[10]};
  &:hover{
    opacity:0.9;
    background-color:${({theme}) => theme.isDark ? '' : theme.primary[25]};
    cursor: pointer;
  }
  &:active{
    border: ${({theme, disabled}) => disabled ? '' : theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[200]}`};
    box-shadow: ${({theme, disabled}) => disabled ? '' : theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : "0px 0px 0px 3px #E0EAFF"};
  }
`
export const SelectPageContainer = styled.div<ActiveProp>`
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 217px;
  height: 56px;
  top: -69px;
  left: -245%;
  display: ${({active}) => active ? 'flex' : 'none'};
  position: absolute;
  background: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
`

export const Rect = styled.div`
  width: 10px;
  height: 10px;
  top:51px;
  right: 50%;
  position: absolute;
  transform: rotate(45deg);
  z-index: 2;
  background: ${({theme}) => theme.palette[10]};
`

export const SelectPageInput = styled.input`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 10px;
  width: 80px;
  height: 40px;
  margin-right: 10px;
  outline: none;
  background: ${({theme}) => theme.base.white};
  border: ${({theme}) => `1px solid ${theme.palette[20]}`};
  border-radius: 4px;
`