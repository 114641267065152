import React, { FC, useEffect, useState } from 'react'
import { Container } from './styles'
import { BackArrow, Button, Input, Toggle, TopProgress } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { loadBalancerService } from '../../../api'
import { useUi } from '../../../context/ui.context'
import { InstanceName } from '../lb-page/styles'
import * as yup from 'yup';
import { useFormik } from 'formik';





const EditLB: FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loadBalancer, setLoadBalancer] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [admin, setAdmin] = useState<boolean>(loadBalancer?.admin_state_up ? loadBalancer.admin_state_up : true)
  const [initialValues, setInitialValues ] = useState<any>({
    name:loadBalancer?.name || "",
    description: loadBalancer?.description || "",
  })
  const {lbId} = useParams()
  const {viewAlert} = useUi()
  const updateLb = () => {
    // setPageLoading(true)
    if(lbId){
      loadBalancerService.getLbById(lbId)
      .then((res) => {
        setLoadBalancer(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
        navigate('/not-found')
      })
    }
  }
  useEffect(() => {
    updateLb()
    const interval = setInterval(() => {
      updateLb()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const onSubmit = (values:any) => {
    if(lbId){
      setLoading(true)
      const data = {
        admin_state_up:true,
        description:values?.description,
        id:lbId,
        name:values?.name,
      }
      loadBalancerService.edit(lbId, data)
      .then((res:any) => {
        setLoadingComplete(true)
        setTimeout(() => {
          setLoadingComplete(false)
          setLoading(false)
          navigate('/load-balancers')
        }, 1000)
      })
      .catch((e:any) => {
        setLoading(false)
        setLoadingComplete(false)
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail
          })
        }
      })
    }
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t('REQUIRED_FIELD')}`).matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      <div>
        <BackArrow onClick={() => navigate('/load-balancers')} title={t("BACK_TO_LOAD_BALANCERS")}/>
        <InstanceName>
          {loadBalancer?.name || loadBalancer.id} 
        </InstanceName>
      </div>
      <Input
        required
        placeholder=''
        customStyles={{width:'360px'}}
        title={t("NAME")}
        fieldName='name'
        {...formikProps}
      />
      <Input
        isTextArea
        placeholder=''
        customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
        title={t("DESCRIPTION")}
        fieldName='description'
        {...formikProps}
      />
      <Toggle
        value={admin}
        onChange={() => setAdmin(!admin)}
        title={t("ADMIN_STATE_UP")}
      />
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("EDIT")}
          loading={loading}
          loadingComplete={loadingComplete}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() =>  navigate('/load-balancers')}
          title={t("CANCEL")}
        />
      </div>
    </Container>
  )
}

export default EditLB