import React, { FC, useEffect, useRef, useState } from "react";
import { icons } from "../../icons";
import StyledIcon from "../styled-icon";
import { Container, Body, Wrapper } from './styles'

type DialogProps = {
  children: any,
  open: boolean,
  handleClose: () => void,
  active?: boolean,
  noIframe?: boolean,
  password?: boolean,
}

const Dialog: FC<DialogProps> = ({
  children,
  open,
  handleClose,
  active,
  noIframe=false,
  password,
}) => {
  const dialogRef: any = useRef()
  useEffect(() => {

    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        handleClose()
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  if (noIframe) {
    return (
      <Container
        id="modalContainer"
        activeVideo={active}
        active={open}
        onMouseDown={(e) => {
          e.stopPropagation()
          // e.preventDefault()
          if (!dialogRef.current.contains(e.target)) {
            handleClose()
          }
        }}
      >
        <Wrapper>
          <Body
            ref={dialogRef}
            password={password}
            noIframe={noIframe}
            activeVideo={active}
          >
            {children}
          </Body>
        </Wrapper>
      </Container>
    )
  } else {
    return (
      <Container
        id="videoContainer"
        activeVideo={active}
        noIframe={noIframe}
        active={open}
        onClick={(e) => {
          handleClose()
        }}
        // style={{boxSizing: 'border-box'}}
      >
        <StyledIcon
          style={{
            position: 'absolute',
            top: '40px',
            right: '40px',
            width: '30px',
            height: '30px'
          }}
          src={icons.CloseIcon}
        />
        {active ?
          <Body ref={dialogRef} noIframe={noIframe} activeVideo={active}>
            {children}
          </Body>
          : null
        }
      </Container>
    )
  }
}

export default Dialog