import styled from "styled-components";
import { BodyDefault } from "../../../../../components/typography/styled";

type ItemProp = {
  withIcon?: boolean;
  isTutorial?: boolean;
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap:10px;
  padding-right:10px;
`
export const TopInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top:20px;
  gap:10px;
`


export const TutorItem = styled.div<ItemProp>`
  box-sizing: border-box;
  background:${({theme}) => theme.palette[100]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* width: 100%; */
  padding:14px 20px 0px 20px;
  overflow: hidden;
  /* flex: none; */
  position: relative;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-origin: content-box;
  flex-grow: 1;
  gap:20px;
  @media screen and (max-width: 1000px) {
    background-size:0%;
  }

  &:hover {
    cursor: ${({isTutorial}) => !isTutorial ? '' : 'pointer'};
    background: ${({theme, isTutorial}) => !isTutorial ? '' : theme.isDark ? theme.palette[200] : theme.grey[200]};
    background-position: right bottom;
    background-repeat: no-repeat;
    background-origin: content-box;
  }

`

export const HideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 38px;
  &:hover{
    cursor:pointer;
  }
`
export const BodyLink = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
  margin-left: 8px;
`