import styled from "styled-components";
import { BodyDefault } from "../../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  margin-top: 20px;
  width:100%;
  flex-wrap: wrap;
  flex-direction: column;
`
export const TableTitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[900]};
  background-color: ${({theme}) => theme.palette[200]};
  padding: 10px;
  margin-bottom: 8px;
  box-sizing: border-box;
  width: 158px;
`