import React, { FC, useEffect, useState } from "react";
import {Container} from './styles'
import { Button, Input, Select, Toggle } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from 'use-query-params';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useBalancer } from "../../../../context/balancerContext";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
const LoadBalancersDetails:FC<any> = ({internetAcc}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {setDataToLs, getDataToLs} = useLocalStorage()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [admin, setAdmin] = useState<boolean>(true)
  const [initialValues, setInitialValues ] = useState<any>({
    admin_state_up:true,
    name:getDataToLs({key:'balancer.balancer_details'})?.name||'',
    description:getDataToLs({key:'balancer.balancer_details'})?.description||'',
    availability_zone:getDataToLs({key:'balancer.balancer_details'})?.availability_zone||'',
    vip_address:getDataToLs({key:'balancer.balancer_details'})?.vip_address||'',
    vip_subnet_id:getDataToLs({key:'balancer.balancer_details'})?.vip_subnet_id||'',
    flavor:getDataToLs({key:'balancer.balancer_details'})?.flavor||'',
  })
  const onSubmit = (values:any) => {
    setDataToLs({key:'balancer.balancer_details', data:{...values, admin_state_up:admin}})
    setLoadDetails({...values, admin_state_up:admin})
    setStep('listener-details')
  }
  const {
    subnets,
    flavors,
    zones,
    setLoadDetails
  } = useBalancer()

  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      description: yup.string().max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
      availability_zone: yup.string(),
      vip_address: yup.string(),
      flavor: yup.string(),
      vip_subnet_id: yup.string().required(`${t('REQUIRED_FIELD')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  // useEffect(() => {
  //   setDataToLs({key:'balancer.balancer_details', data:{...values, admin_state_up:admin}})
  //   setLoadDetails({...values, admin_state_up:admin})
  // },[values, admin])
  // console.log('subnets',subnets);
  return (
    <Container>
      <Input
        fieldName='name'
        toolTip={false}
        title={t("NAME_LOAD_BALANCER")}
        placeholder=''
        customStyles={{width:'360px'}}
        {...formikProps}
      />
      <Input
        fieldName='vip_address'
        toolTip={false}
        title={t("IP_ADDRESS")}
        placeholder=''
        customStyles={{width:'360px'}}
        {...formikProps}
      />
      <Input
        isTextArea
        toolTip={false}
        fieldName='description'
        title={t("DESCRIPTION")}
        placeholder=''
        customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
        {...formikProps}

      />
      {/* <Select
        toolTip={false}
        data={zones}
        selectedValue={values['availability_zone']}
        onChange={(e) => handleChange('availability_zone')(e)}
        title={t("AVAILABILITY_ZONE")}
        customStyles={{width:'360px'}}
        fieldName='availability_zone'
        {...formikProps}
      /> */}
      <Select
        toolTip={false}
        data={flavors}
        disabled={flavors?.length <= 0}
        selectedValue={values['availability_zone']}
        onChange={(e) => handleChange('availability_zone')(e)}
        placeholder={flavors?.length <= 0 ? t("NO_FLAVORS_AVAILABLE") : t("CHOOSE")}
        title={t("FLAVORS")}
        customStyles={{width:'360px'}}
        fieldName='flavor'
        {...formikProps}
      />
      <Select
        toolTip={false}
        data={subnets}
        required
        selectedValue={values['vip_subnet_id']}
        onChange={(e) => handleChange('vip_subnet_id')(e)}
        title={t("SUBNET")}
        customStyles={{width:'360px'}}
        fieldName='vip_subnet_id'
        {...formikProps}
      />
      <Toggle
        value={admin}
        onChange={() => setAdmin(!admin)}
        title={t("ADMIN_STATE_UP")}
      />
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate('/load-balancers')}
          title={t("CANCEL")}
        />
      </div>
    </Container>
  )
}

export default LoadBalancersDetails
