import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px 50px 0px;
  position: relative;
  gap:20px;
  box-sizing: border-box;
`