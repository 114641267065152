import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";

const operationService = {
  getList:(queries:QueriesApi, client?:any) => ApiClient.get('operations', {
    params:{
      page_size:queries.pageSize,
      page:queries.page,
      ordering:'-created_at',
      active_client:client
    }
  }),
  getOperationById:(id:string) => ApiClient.get(`operations/${id}`),
  retryOperationStart:(operationId:string) => ApiClient.post(`operations/${operationId}/retry`),
  abortOperation:(operationId:string) => ApiClient.post(`operations/${operationId}/abort_operation`)
}

export default operationService