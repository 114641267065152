import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import Input from "../../input";
import { routerService, sshService } from "../../../api";
import { useParams } from "react-router-dom";
import { useUi } from "../../../context/ui.context";
import TopProgress from "../../TopBarProgress";
import copy from 'copy-to-clipboard';
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data?:any) => void;
  loading?:boolean;
  modalType:string;
}


const CreateSSh:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    loading,
    modalType
  } = props
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [generated, setGenerated] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({
    name:'',
    public_key:'',
    private_key:''
  })
  const [_loading, setLoading] = useState<boolean>(true)
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false)
  const onSubmit = (values:any) => {
    setLoadingCreate(true)
    const data = {
      name:values.name,
      public_key:values.public_key,
    }
    if(values.private_key){
      //@ts-ignore
      data.private_key = values.private_key
    }
    sshService.createSsh(data)
    .then((res) => {
      viewAlert({
        severity:'info',
        message:`${t("CREATE_SSH_SUCCESSFULLY")}`,
      })
      closeModal()
    })
    .catch((e) => {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      if(e?.response?.data.public_key){
        setFieldError('public_key', e?.response?.data.public_key)
      }
    })
    .finally(() => {
      setLoadingCreate(false)
      resetForm()
    })
  }
  const {
    values,
    errors,
    setFieldError,
    touched,
    handleSubmit,
    resetForm,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t("REQUIRED_FIELD")}`).max(30, `${t("STRING_VALIDATE_MAX")} 30 ${t("CHARACTERS")}`),
      public_key:yup.string().required(`${t("REQUIRED_FIELD")}`),
      private_key:yup.string()
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  useEffect(() => {
    if(modalType === 'generate-ssh'){
      _generatePairKeys()
    } else {
      setGenerated(false)
      setLoading(false)
    }
  },[modalType])
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const _generatePairKeys = () => {
    setLoading(true)
    sshService.generateKeyPair()
    .then((res:any) => {
      setGenerated(true)
      setInitialValues({
        ...initialValues,
        public_key:res.data.public_key,
        private_key:res.data.private_key,
      })
      setLoading(false)
    })
    .catch((e) => {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      setLoading(false)
      closeModalCancel()
    })
  }
  if(_loading) return <TopProgress loading={true}/>
  return(
    <Container>
      <HeadWrapper>
      <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {t("CREATE_SSH")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        {generated ?
          <div 
            style={{
              display:'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap:'10px',
            }}
          >
            <Input
              fieldName='name'
              required
              title={t("NAME_KEY")}
              placeholder=''
              customStyles={{width:'360px'}}
              {...formikProps}
            />
            <Input
              isTextArea
              required
              fieldName='public_key'
              title={t("KEY_CONTENT")}
              customStyles={{maxWidth:'360px', minWidth:'360px', height:'200px'}}
              placeholder=''
              {...formikProps}
            />
            <Button
              variant="stroke"
              size="display"
              icon={icons.Copy}
              onClick={() => copy(values['public_key'])}
              title={t("COPY_PUBLIC_TO_CLIPBOARD")}
              // customStyles={{width:'265px'}}
            />
            <Input
              isTextArea
              required
              fieldName='private_key'
              title={t("PRIVATE_KEY")}
              customStyles={{maxWidth:'360px', minWidth:'360px', height:'200px'}}
              placeholder=''
              readOnly
              {...formikProps}
            />
            <Button
              variant="stroke"
              size="display"
              icon={icons.Copy}
              onClick={() => copy(values['private_key'])}
              title={t("COPY_PRIVATE_TO_CLIBOARD")}
              // customStyles={{width:'265px'}}
            />
          </div>
        :
          <>
            <Input
              required
              fieldName='name'
              title={t("NAME_KEY")}
              customStyles={{width:'360px'}}
              placeholder=''
              {...formikProps}
            />
            <Input
              required
              isTextArea
              fieldName='public_key'
              toolTip={true}
              toolTipText={`${t("PUBLIC_KEY_TOOLTIP")}`}
              title={t("KEY_CONTENT")}
              customStyles={{maxWidth:'360px', minWidth:'360px', height:'200px'}}
              placeholder=''
              {...formikProps}
            />
          </>
        }
        <div style={{display:"flex", gap:'6px', marginTop:'16px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("ADD")}
            loading={loadingCreate}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default CreateSSh