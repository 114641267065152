import styled from "styled-components";
import { BodyMedium } from "../../components/typography/styled";
type StatusProp = {
  active?:boolean
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 30px;
  position: relative;
`

export const StateWrapper = styled.div`
  display: flex;
  gap:6px;
  align-items: center;
`

export const GreenDot = styled.div<StatusProp>`
  width:12px;
  height:12px;
  background-color: ${({active}) => active ? '#229B61' : ''};
  border-radius: 100%;
`

export const Greentext = styled(BodyMedium)<StatusProp>`
  color: ${({active}) => active ? '#229B61' : ''};
`