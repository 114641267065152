import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider,
  IpContainer,
  IpsWrapper,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik, FieldArray } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { instanceService } from "../../../api";
import TopProgress from "../../TopBarProgress";
import { useUi } from "../../../context/ui.context";
import Input from "../../input";
import { randomNameGenerator } from "../../../utils/create-random-name";
import { ErrorText } from "../../input/styles";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(values:any, setErrorsIps?:any) => void;
  instanceId?:string;
  alertTitle?:string;
  loading?:boolean;
  viewModal?:boolean

}  


const AddIpToInstance:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instanceId,
    alertTitle,
    loading,
    viewModal
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    port:'',
    subnet:'',
  })
  const {
    viewAlert
  } = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [networks, setNetworks] = useState<any[]>([])
  const [subnets, setSubnets] = useState<any[]>([])
  const [ports, setPorts] = useState<any[]>([])
  const [ips, setIps] = useState<any[]>([{ip_address:'', id:randomNameGenerator({name:'ip', findArr:[]})}])
  const [errorsIps, setErrorsIps] = useState<any[]>([])
  const _getOptions = () => {
    if(instanceId && viewModal){
      instanceService.addIpOptions(instanceId)
      .then((res:any) => {
        setNetworks(res.data.networks.map((sub:any) => {
          return {
            ...sub,
            value:sub.floating_ip_address,
            label:sub.floating_ip_address
          }
        }))
        setPorts(res.data.ports.map((port:any) => {
          return {
            ...port,
            value:port.fixed_ips?.[0]?.ip_address,
            label:`${port.id.slice(0, 4)}...${port.id.slice(-4)} - ${port.fixed_ips?.[0]?.ip_address}`
          }
        }))
      })
      .catch((e:any) => {
        if(e?.response?.data?.detail) {
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
          if(e?.response?.data?.detail === 'No free ports available'){
            closeModalCancel()
          }
        }
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
    if(!viewModal){
      setPageLoading(false)
    }
  }
  useEffect(() => {
    _getOptions()
  },[])
  const onSubmit = (values:any) => {
    const data = {
      port:values.port,
      region:networks?.[0]?.region,
      subnet:values.subnet,
      fixed_ips:values.fixed_ips,
      device_owner: "compute:nova",
      device_id:instanceId,
      append_ip: true,
    }
    closeModal(data, setErrorsIps)
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    setFieldValue,
    handleBlur,
  } = useFormik({
    // validateOnChange:false,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      port : yup.string().required(),
      subnet: yup.string().required(),
      fixed_ips: yup.array().of(
        yup.object().shape({
          ip_address: yup.string(),
        })
      ),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  useEffect(() => {
    setSubnets(networks.find((net:any) => net.id === ports.find((port:any) => port.id === values['port']).network)?.subnets?.map((sub:any) => {
      return {
        value:sub.id,
        label:`${sub.name} (${sub.cidr})`
      }
    }))
  },[values['port']])
  const addObjToArr = () => {
    setIps((prev) => [...prev, {ip_address:'', id:randomNameGenerator({name:'ip', findArr:[]})}])
    setFieldValue("fixed_ips", ips);
  }
  const removeObj = (id:string, index?:any) => {
    const newItems = [...values.fixed_ips];
    newItems.splice(index, 1);
    setFieldValue("fixed_ips", newItems);
    setIps((prev) => prev.filter((ip:any) => ip.id !== id))
  }

  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
      <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {`${t("ADD_IP_TO_INSTANCE")} «${alertTitle}»`}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Select
          required
          toolTip={true}
          data={ports}
          selectedValue={values['port']}
          onChange={(e) => handleChange('port')(e)}
          fieldName='port'
          title={t("PORT")}
          customStyles={{width:'360px'}}
          {...formikProps}
        />
        <Select
          required
          toolTip={true}
          data={subnets}
          selectedValue={values['subnet']}
          onChange={(e) => handleChange('subnet')(e)}
          fieldName='subnet'
          title={t("SUBNET")}
          customStyles={{width:'360px'}}
          {...formikProps}
        />
        <Divider/>
        <IpsWrapper>
          <Button
            variant="stroke"
            size="display"
            icon={icons.PlusDefault}
            onClick={() =>  {
              addObjToArr()
            }}
            title={t("ADD_IP_ADDRESS")}
          />
          {ips.length > 0 && ips.map((field:any, index:any) => (
            <IpContainer key={field.id}>
              <div style={{display:'flex', gap:'10px'}}>
                <Input
                  fieldName={`fixed_ips[${index}].ip_address`}
                  title={t("IP_ADDRESS")}
                  placeholder=''
                  {...formikProps}
                  customStyles={{width: '282px'}}
                />
                <Button
                  onClick={() => {
                    removeObj(field.id, index)
                  }}
                  variant='icon'
                  size='display'
                  customStyles={{marginTop:'24px'}}
                >
                  <img src={icons.DeleteIcon}/>
                </Button>
              </div>
              <ErrorText>
              {errorsIps?.length > 0 && errorsIps?.[index]?.ip_address}
              </ErrorText>
            </IpContainer>
          ))}
        </IpsWrapper>
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            loading={loading}
            title={t("ADD_IP")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default AddIpToInstance