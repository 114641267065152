import styled from "styled-components";
import { BodyDefault } from "../typography/styled";

type InfoWrapperCustom = {
  customStyles?:any
}

export const Info = styled.div<InfoWrapperCustom>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 15px;
  margin-top:20px;
  gap: 14px;
  background: ${({theme}) => theme.grey[100]};
  border-radius: 8px;
  min-width: 240px;
  max-width: 240px;
  ${({customStyles}) => customStyles}
`

export const InfoText = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
`