import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  Divider
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import Input from "../../input";
import { routerService } from "../../../api";
import { useParams } from "react-router-dom";
import { useUi } from "../../../context/ui.context";
import TopProgress from "../../TopBarProgress";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  loading?:boolean;
}


const AddInterface:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    loading
  } = props
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    cidr:'',
    sub:'',
  })
  const [createOptions, setCreateOptions] = useState<any>()
  const [_loading, setLoading] = useState<boolean>(true)
  const onSubmit = (values:any) => {
    const data = {
      sub:values.sub,
      cidr:values.cidr
    }
    closeModal(data)
  }
  const {idRouter} = useParams()
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      cidr: yup.string(),
      sub: yup.string().required(`${t('REQUIRED_FIELD')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const getPrevData = async () => {
    try{
      if(idRouter){
        const {data} = await routerService.addInterfaceOptions(idRouter)
        await setCreateOptions(data.subnets.map((sub:any) => {
          return {
            ...sub,
            value:sub.id,
            label:`${sub.name} - ${sub.cidr}`
          }
        }))
        await setInitialValues({
          cidr:'',
          sub:data.subnets?.[0]?.id,
        })
      }
    } catch (e:any) {
      if(e?.response?.data?.details){
        viewAlert({
          severity:'error',
          message:e?.response?.data?.details
        })
      }
      closeModalCancel()
    } finally {
      setLoading(false)
    }
  }
  // useEffect(() => {
  //   if(createOptions){
  //     handleChange('cidr')(createOptions?.find((opt:any) => opt.id === values['sub'])?.cidr?.replace('0/24', '1'))
  //   }
  // },[values['sub']])
  useEffect(() => {
    getPrevData()
  },[])
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(_loading) return <TopProgress loading={true}/>
  return(
    <Container>
      <HeadWrapper>
      <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {t("ADD_AN_INTERFACE")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Select
          required
          toolTip={false}
          data={createOptions}
          selectedValue={values['sub']}
          onChange={(e) => handleChange('sub')(e)}
          title={t("SUBNET")}
          customStyles={{width:'360px'}}
          errors={errors}
          fieldName={'sub'}
        />
        <Input
          title={t('IP')}
          fieldName='cidr'
          placeholder={''}
          customStyles={{width:'360px'}}
          onBlur={handleBlur}
          {...formikProps}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'16px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("ADD")}
            loading={loading}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default AddInterface