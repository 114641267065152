import React, { FC, useEffect, useState } from "react";
import {Container, TableTitle, AddonBack, PropContainer, TextLabels, AddonImg} from './styles'
import { Button, InfoBlock, Label, TopProgress } from "../../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryParam, StringParam } from 'use-query-params';
import { useTranslation } from "react-i18next";
import { InfoContainer, InfoTitle } from "../../../../components/info-block/styles";
import { useBalancer } from "../../../../context/balancerContext";
import { useUi } from "../../../../context/ui.context";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { useKubernetes } from "../../../../context/kubernetesContext";
import { kubernetesService } from "../../../../api";
import { useInstance } from "../../../../context/instanceContext";
import { ItemLabelFlavor } from "../../../../components/select";
import MetricsIc from '../add-ons/ic-kubernetes.svg'
import CloudNativeIc from '../add-ons/cloudnative.svg'
import PrometheusIc from '../add-ons/Prometheus_software_logo 1.svg'
import RedisIc from '../add-ons/redis-logo-svgrepo-com 1.svg'
import NginxIc from '../add-ons/ic-nginx.svg'

const icons = [
  {key:'metrics-server', icon:MetricsIc},
  {key:'cloudnative-pg', icon:CloudNativeIc},
  {key:'prometheus', icon:PrometheusIc},
  {key:'redis', icon:RedisIc},
  {key:'ingress-nginx', icon:NginxIc},
]

const Finally:FC<any> = ({isCreateListener, isCreatePool}) => {
  const navigate = useNavigate()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [globalType, setGlobalType] = useState<any>({})
  const {
    poolRemembers,
    loadDetails,
  } = useBalancer()
  const {lbId} = useParams()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const {setDataToLs, getDataToLs, removeItemFromLs} = useLocalStorage()
  const {sshKeys, flavors} = useInstance()
  const {
    clusterTypes,
    selectedClusterType,
    setSelectedClusterType,
    networkingData,
    setNetworkingData,
    clusterData,
    setClusterData,
    nodePoolsData,
    setNodePoolsData,
    networks
  } = useKubernetes()

  const updateData = async () => {
    await setNetworkingData(getDataToLs({key:'cluster.networking'}))
    // if(!globalType){
    //   await setGlobalType(getDataToLs({key:'cluster.type'}))
    // }
    await setClusterData(getDataToLs({key:'cluster.configure_master'}))
    if(nodePoolsData.length === 0){
      await setNodePoolsData(getDataToLs({key:'cluster.poools'}))
    }
    await setPageLoading(false)
  }
  useEffect(() => {
    updateData()
  },[])
  // console.log('globalType:',globalType);
  // console.log('clusterData:',clusterData);
  // console.log('networkData:',networkingData);
  // console.log('nodePoolsData:',nodePoolsData);
  // console.log('clusterData:',clusterData);
  // console.log('flavors:',flavors);
  // const clusterType = [
  //   // {title:t("TYPE"), value:t(`${globalType?.title}`)||'-'},
  //   {title:t("MASTER_NODES"), value:clusterData?.count_master||'-'},
  //   // {title:t("WORKER_NODES"), value:nodePoolsData?.map?.pool_count||'-'},
  //   {title:t("VERSION_KUBERNETES"), value:loadDetails?.description||"v1.27.4"},
  //   // {type:'divider'},
  //   // {title:t("VCPU"), value:globalType?.vCpus||'-'},
  //   // {title:t("RAM"), value:`${globalType?.ram}GB`||'-'},
  //   // {title:t("STORAGE"), value:`${globalType?.storage}GB SSD`||'-'},
  // ]
  const configurationMaster = [
    {title:t("NAME_CLUSTER"), value:clusterData?.name||'-'},
    {title:t("TYPE_MASTER_NODE"), value:(<ItemLabelFlavor 
      blockedDown={flavors?.find((fl:any) => fl.value === clusterData?.flavor)?.blockedDown}
      blocked={flavors?.find((fl:any) => fl.value === clusterData?.flavor)?.blocked}
      item={flavors?.find((fl:any) => fl.value === clusterData?.flavor)?.customItem}
      withoutPrice
    />)||'-'},
    // {title:t("DISK_TYPE"), value:loadDetails?.description||'-'},
    {title:t("NUMBER_OF_MASTER_NODES"), value:+clusterData?.count_master||'-'},
    {title:t("VERSION_KUBERNETES"), value:clusterData?.cluster_version||"v1.27.4"},
    // {title:t("STORAGE"), value:loadDetails?.vip_subnet_id||'-'},
  ]
  const configurationNetworking = [
    {title:t("CONTAINER_NETWORK_INTERFACE"), value:networks.find((net:any) => net?.value === networkingData?.network)?.label ||'-'},
    {title:t("RESTRICTION_API"), value:networkingData?.restriction ? 'True' : 'False' ||'-'},
  ]
  const configurationNetworkingRestriction = [
    {title:t("CONTAINER_NETWORK_INTERFACE"), value:networks.find((net:any) => net?.value === networkingData?.network)?.label ||'-'},
    {title:t("RESTRICTION_API"), value:networkingData?.restriction ? 'True' : 'False' ||'-'},
    {title:t("RESTRICTION_API_IP_RANGES"), value:
    networkingData?.api_ranges?.length > 0 ? (
      <PropContainer>
        {networkingData?.api_ranges?.map((label:any) => (
          <div style={{display:'flex', flexDirection:'column', gap:'6px'}}>
            {label}
          </div>
        ))}
      </PropContainer>
    ):'-'},
  ]
  console.log('getDataToLs({key:cluster.add-ons})', getDataToLs({key:'cluster.add-ons'}));
  const create = () => {
    setLoading(true)
    const data = {
      cluster_name: clusterData?.name,
      cluster_version: clusterData?.cluster_version,
      master_flavor_id: clusterData?.flavor,
      master_count: +clusterData?.count_master,
      node_pools:nodePoolsData.map((pool:any) => {
        return {
          ...pool,
          name:`${clusterData?.name}-${pool.name}`
        }
      }),

      // ssh_keys: sshKeys?.find((key:any) => clusterData?.ssh === key?.value)?.label || "sss",
      image: clusterData?.os,
      networking: networkingData?.network,
      restriction_api: networkingData?.restriction,
      restriction_ips: networkingData?.api_ranges || [],
      addons:getDataToLs({key:'cluster.add-ons'})
      // addons:{
      //   dashboard: getDataToLs({key:'cluster.add-ons'})?.metrics || false,
      //   metrics: getDataToLs({key:'cluster.add-ons'})?.metrics || false,
      //   nginx: getDataToLs({key:'cluster.add-ons'})?.nginx || false
      // }
    }
    if(!networkingData?.restriction){
      data.restriction_ips = []
    }
    if(getDataToLs({key:'cluster.add-ons'})?.length === 0){
      delete data.addons
    }
    if(networkingData?.api_ranges?.length === 0){
      delete data.restriction_ips
    }
    kubernetesService.createCluster(data)
    .then((res) => {
      setLoadingComplete(true)
      setTimeout(() => {
        setLoadingComplete(false)
        setLoading(false)
        // console.log('res',res.data);
        removeItemFromLs('cluster.type')
        removeItemFromLs('cluster.configure_master')
        removeItemFromLs('cluster.configure_pools')
        removeItemFromLs('cluster.poools')
        removeItemFromLs('cluster.add-ons')
        removeItemFromLs('cluster.networking')
        navigate('/kubernetes')
      }, 1500)
    })
    .catch((err) => {
      console.log('err',err);
      viewAlert({
        severity: 'error',
        message: err?.response?.data?.detail || t("ERROR_OCCURRED")
      })
      setLoading(false)
    })
    // navigate('/load-balancers')
  }
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <InfoBlock  title={t("CONFIGURE_MASTER_NODE")} data={configurationMaster}/>
      <InfoBlock  title={t("NETWORKING")} data={networkingData?.restriction ? configurationNetworkingRestriction : configurationNetworking}/> 
      <InfoContainer>
        <InfoTitle style={{marginBottom:'20px'}}>{t('NODE_POOLS')}</InfoTitle>
        {nodePoolsData.map((pool:any, index:any) => (
          <InfoBlock  title={pool?.name} data={index !== 0 ? 
            [
              {title:t("TYPE_NODE_POOL"), value: (
              <ItemLabelFlavor 
                blockedDown={flavors?.find((fl:any) => fl.value === pool?.flavor_id).blockedDown}
                blocked={flavors?.find((fl:any) => fl.value === pool?.flavor_id).blocked}
                item={flavors?.find((fl:any) => fl.value === pool?.flavor_id).customItem}
                withoutPrice
              />)},
              {title:t("AUTOSCALE"), value:pool?.autoscale ? "True" :"False"},
              {title:pool?.autoscale ? t("MIN_NODE_COUNT") : t("NODE_COUNT"), value:pool?.count||'-'},
              {title:t("MAX_NODE_COUNT"), value:pool?.autoscale ? pool?.max_count : '-'},
              {title:t("LABELS"), value:pool?.labels?.length > 0 ? (
                <PropContainer>
                  {pool?.labels?.map((label:any) => (
                    <div style={{display:'flex', flexDirection:'column', gap:'6px'}}>
                      <div style={{display:'flex', flexDirection:'row', gap:'6px'}}>
                        <div style={{display:'flex', flexDirection:'row', gap:'6px'}}>
                          <TextLabels>Key</TextLabels>
                          {label.key}
                        </div>
                        <div style={{display:'flex', flexDirection:'row', gap:'6px'}}>
                          <TextLabels>Value</TextLabels>
                          {label.value}
                        </div>
                      </div>
                    </div>
                  ))}
                </PropContainer>
              ): '-'},
              {title:t("TAINTS"), value:pool?.taints?.length > 0 ? (
                <PropContainer>
                  {pool?.taints?.map((label:any) => (
                    <div style={{display:'flex', flexDirection:'column'}}>
                      <div style={{display:'flex', flexDirection:'row', gap:'6px'}}>
                        <TextLabels>Effect</TextLabels>
                        {label.effect}
                      </div>
                      <div style={{display:'flex', flexDirection:'row', gap:'6px'}}>
                        <div style={{display:'flex', flexDirection:'row', gap:'6px'}}>
                          <TextLabels>Key</TextLabels>
                          {label.key}
                        </div>
                        <div style={{display:'flex', flexDirection:'row', gap:'6px'}}>
                          <TextLabels>Value</TextLabels>
                          {label.value}
                        </div>

                      </div>
                    </div>
                  ))}
                </PropContainer>
              ): '-'},
            ]
          :
            [
              // {title:t("NAME_WORKER_NODE"), value:pool?.name||'-'},
              {title:t("TYPE_NODE_POOL"), value: (
                <ItemLabelFlavor 
                  blockedDown={flavors?.find((fl:any) => fl.value === pool?.flavor_id).blockedDown}
                  blocked={flavors?.find((fl:any) => fl.value === pool?.flavor_id).blocked}
                  item={flavors?.find((fl:any) => fl.value === pool?.flavor_id).customItem}
                  withoutPrice
                />)},
                {title:t("AUTOSCALE"), value:pool?.autoscale ? "True" :"False"},
                {title:pool?.autoscale ? t("MIN_NODE_COUNT") : t("NODE_COUNT"), value:pool?.count||'-'},
                {title:t("MAX_NODE_COUNT"), value: pool?.autoscale ? pool?.max_count : '-'},
            ]
            
          }/>
        ))}
      </InfoContainer>
      {getDataToLs({key:'cluster.add-ons'})?.length > 0 &&
        <InfoContainer style={{display:'flex', gap:'10px'}}>
          <InfoTitle>
            {t("ADD_ONS")}
          </InfoTitle>
          {getDataToLs({key:'cluster.add-ons'})?.map((addon:any) => (
            <AddonBack>
              {/* <img src={NginxIc}/> */}
              {icons.find((ic:any) => ic.key === addon?.name)?.icon &&
                <AddonImg src={icons.find((ic:any) => ic.key === addon?.name)?.icon}/>
              }
              <Label margin='10px' toolTip={false}  title={`${addon?.name} - v${addon?.version}`}/>
            </AddonBack>  
          ))}
        </InfoContainer>
      }
      {/* {getDataToLs({key:'cluster.add-ons'})?.metrics || getDataToLs({key:'cluster.add-ons'})?.nginx ?
        <InfoContainer style={{display:'flex', gap:'10px'}}>
          <InfoTitle>
            {t("ADD_ONS")}
          </InfoTitle>
          {getDataToLs({key:'cluster.add-ons'})?.metrics && 
            <AddonBack>
              <img src={MetricsIc}/>
              <Label margin='10px' toolTip={true} tooltipText={`${t("KUBERNETES_METRICS_SERVER_INFO")}`} title={t("KUBERNETES_METRICS_SERVER")}/>
            </AddonBack>
          }
          {getDataToLs({key:'cluster.add-ons'})?.nginx && 
            <AddonBack>
              <img src={NginxIc}/>
              <Label margin='10px' toolTip={true} tooltipText={`${t("NGINX_INGRESS_INFO")}`} title={`${t("NGINX_INGRESS")}`}/>
            </AddonBack>        
          }
        </InfoContainer>
      :null} */}
      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={create}
          loading={loading}
          loadingComplete={loadingComplete}
          title={t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep('add-ons')}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default Finally


