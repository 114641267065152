interface Measure {
  bytes_in: number;
  bytes_out: number;
  packets_in: number;
  packets_out: number;
  date: string;
}

function roundToHalf(value: number): number {
  const roundedValue = Math.round(value);
  return roundedValue;
}

export function calculateScales(data: any[],): any {
  const packetsScale: number[] = [];
  const bytesScale: number[] = [];
  const msInDay = 24 * 60 * 60 * 1000;
  const now:any = new Date();
  const last28Days = [...Array(28)].map((_, i) => new Date(now - msInDay * i).toISOString());
  const groupedData = last28Days.map(date => {
    const dailyData = data.filter((item:any) => {
      // item.created_at?.slice(0,10) === date?.slice(0,10)
      const date1 = new Date(item.created_at);
      const date2 = new Date(date);
      const day1 = date1.getDate();
      const month1 = date1.getMonth();
      const day2 = date2.getDate();
      const month2 = date2.getMonth();
      return day1 === day2 && month1 === month2
    });
    const total = dailyData.reduce((acc:any, curr:any) => acc + (curr.amount || 0), 0);
    const total2 = dailyData.reduce((acc:any, curr:any) => acc + (curr?.conversion_sub_amount || 0), 0);
    return { date, total, total2 };
  });
  // Находим максимальные значения packets_in, packets_out, bytes_in и bytes_out
  const maxPacketsIn = Math.max(...groupedData.map((measure) => measure.total));
  const maxPacketsOut = Math.max(...groupedData.map((measure) => measure.total2));
  // const maxBytesIn = Math.max(...data.map((measure) => measure.amount));
  // const maxBytesOut = Math.max(...data.map((measure) => measure.conversion_sub_amount));
  const minValue = 70
  // Определяем количество точек (в данном случае, 11)
  const numPoints = 8;

  // Вычисляем шаг для шкалы, используя максимальные значения из обоих полей
  const packetsStep = Math.max(maxPacketsIn, maxPacketsOut, minValue) / (numPoints - 1);
  const bytesStep = Math.max(maxPacketsIn, maxPacketsOut, minValue) / (numPoints - 1);

  // Создаем шкалы
  for (let i = 0; i < numPoints; i++) {
    const packetsValue = i * packetsStep;
    const bytesValue = i * bytesStep;

    // Округляем до половины с учетом знака
    const roundedPackets = roundToHalf(packetsValue);
    const roundedBytes = roundToHalf(bytesValue);
    const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
    const sizes1 = ['', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    //@ts-ignore
    packetsScale.push(transformValues(packetsValue, 2,sizes));
    //@ts-ignore
    bytesScale.push(transformValues(roundedBytes, 2 ,sizes1));
  }

  return [packetsScale?.reverse(), bytesScale?.reverse()];
}



function transformValues(value:any, decimals:any, sizes:any) {
  const dm = decimals || 2;
  if (value <= 1) {
    return value.toFixed(dm) + ' ' + sizes[0];
  }
  const k = 1000;
  const i = Math.floor(Math.log(value) / Math.log(k));
  return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function createPercentageArray(length:any) {
  if (length < 1) {
    return [];
  }

  const step = 100 / (length - 1);
  const result = [];

  for (let i = 0; i < length; i++) {
    result.push(i * step);
  }

  return result;
}

export function createPercentageArrays(data:any, fieldName:any) {
  const msInDay = 24 * 60 * 60 * 1000;
  const now:any = new Date();
  const last28Days = [...Array(28)].map((_, i) => new Date(now - msInDay * i).toISOString());

  // Группировка данных по датам
  const groupedData = last28Days.map(date => {
    const dailyData = data.filter((item:any) => {
      // item.created_at?.slice(0,10) === date?.slice(0,10)
      const date1 = new Date(item.created_at);
      const date2 = new Date(date);
      const day1 = date1.getDate();
      const month1 = date1.getMonth();
      const day2 = date2.getDate();
      const month2 = date2.getMonth();
      return day1 === day2 && month1 === month2
    });
    const total = dailyData.reduce((acc:any, curr:any) => acc + (curr[fieldName] || 0), 0);
    const total2 = dailyData.reduce((acc:any, curr:any) => acc + (curr?.conversion_sub_amount || 0), 0);
    return { date, total, total2 };
  });
  const maxPacketsIn = Math.max(...groupedData.map((measure:any) => measure.total));
  const maxPacketsOut = Math.max(...groupedData.map((measure:any) => measure.total2));
  const packetsStep = Math.max(maxPacketsIn, maxPacketsOut, 70);
  // Ваша логика расчета процентов
  const maxTotal = Math.max(...groupedData.map(item => item.total2));
  const resultArrays = groupedData.map((item:any, index:any) => {
    const percentValues = createPercentageArray(last28Days?.length);
    // const percentValue = maxTotal === 0 ? 0 : (item.total / maxTotal) * 100;
    const percentValue = item.total === 0 ? 0 : (item.total / packetsStep) * 100;
    return [percentValues[index], percentValue, item.date, item.total.toFixed(2)];
  });

  return resultArrays;
}

