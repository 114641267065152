import AlmaLinux from './os/ic-almalinux.svg'
import AltLinux from './os/ic-altlinux.svg'
import Arch from './os/ic-arch.svg'
import Centos from './os/ic-centos.svg'
import Cirros from './os/ic-cirros.svg'
import Debian from './os/ic-debian.svg'
import Dos from './os/ic-dos.svg'
import FedoraCoreos from './os/ic-fedora-coreos.svg'
import Fedora from './os/ic-fedora.svg'
import FedoraAtomic from './os/ic-fedora-atomic.svg'
import Gnome from './os/ic-gnome.svg'
import Macos from './os/ic-macos.svg'
import Mageia from './os/ic-mageia.svg'
import Mandrake from './os/ic-mandrake.svg'
import Mandriva from './os/ic-mandriva.svg'
import Netbsd from './os/ic-netbsd.svg'
import Netware from './os/ic-netware.svg'
import Openbsd from './os/ic-openbsd.svg'
import Opensuse from './os/ic-opensuse.svg'
import Rhel from './os/ic-rhel.svg'
import Rockylinux from './os/ic-rockylinux.svg'
import Solaris from './os/ic-solaris.svg'
import Ubuntu from './os/ic-ubuntu.svg'
import Windows from './os/ic-windows.svg'
import Undefined from './os/ic-undefined.svg'

export const handleOsIcons = (os:string) => {
  switch (os) {
    // case 'ubuntu':
    case 'almalinux' :
      return AlmaLinux
    case 'rockylinux' :
      return Rockylinux
    case 'altlinux' :
      return AltLinux
    case 'arch' :
      return Arch
    case 'centos' :
      return Centos
    case 'cirros' :
      return Cirros
    case 'debian' :
      return Debian
    case 'dos' :
      return Dos
    case 'fedora' :
      return Fedora
    case 'fedora-atomic' :
      return FedoraAtomic
    case 'fedora-coreos' :
      return FedoraCoreos
    // case 'freebsd' :
    //   return 
    case 'gnome' :
      return Gnome
    case 'macos' :
      return Macos
    case 'mageia' :
      return Mageia
    case 'mandrake' :
      return Mandrake
    case 'mandriva' :
      return Mandriva
    case 'netbsd' :
      return Netbsd
    case 'netware' :
      return Netware
    case 'openbsd' :
      return Openbsd
    case 'opensuse' :
      return Opensuse
    case 'rhel' :
      return Rhel
    // case 'rhl' :
    //   return 
    case 'solaris' :
      return Solaris
    // case 'suse' :
    //   return 
    case 'ubuntu' :
      return Ubuntu
    case 'windows' :
      return Windows
    default:
      return Undefined
  }
}

