import React, { FC, useState, useEffect } from "react";
import {
  Container,
  TitleBig,
  BodyWrapper,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
  EstimateSubTitle,
  EstimateDesc,
  PropContainer,
  PropsWrapper,
  Divider,
  Prop
} from './styles'
import { Tabs, BackArrow, LoaderSpinner, TopProgress } from "../../../components";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Configure from "./configure";
import Network from "./network";
import FinallyCreate from "./finally";
import { useQueryParam, StringParam } from 'use-query-params';
import { useInstance } from "../../../context/instanceContext";
import { bareMetalService, instanceService, sshService } from "../../../api";
import { useAuth } from "../../../context/user.context";
import { useTheme } from "styled-components";
import { formatCurrency, formatCurrencyString } from "../../../utils/display-price";
import { handleOsIcons } from "../../../hooks/useOsIcons";
import { useUi } from "../../../context/ui.context";


const CreateBareMetalServer:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [osList, setOsList] = useState<any[]>([])
  const [serverList, setServerList] = useState<any[]>([])
  const [sshKeys, setSshKeys] = useState<any[]>([])
  const [currency, setCurrency] = useState<string>()
  const {user, quotas} = useAuth()
  const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false)
  const [disks, setDisks] = useState<any[]>([])
  const getCreateOptions = async () => {
    const diskTypes = [
      {type:'HDD', id:"1"},
      {type:'SSD', id:"2"},
      {type:'NVMe', id:"3"},
    ]
    setLoadingRefresh(true)
    try {
      const [resDisks, resOs, resSsh, resServers] = await Promise.all([
        bareMetalService.getListOfDisk(),
        bareMetalService.getOs(),
        sshService.getSshKeys(),
        bareMetalService.getServers()
      ]);

      await setDisks(resDisks.data.disks.map((disk: any) => ({
        label: disk.name,
        value: disk.id.toString(),
        type: "disk_layout",
        min_disks: disk.min_disks
      })));

      await setSshKeys(resSsh.data.objects.map((ssh: any) => ({
        label: ssh.name,
        value: ssh.id.toString()
      })));

      await setOsList(resOs.data.OS.map((item: any) => ({
        value: `${item.id}`,
        label: item.name,
        icon: handleOsIcons(item.os_distro),
      })));

      await setServerList(resServers.data.servers?.map((server: any) => ({
        value: `${server.server_id}`,
        name: server.type,
        price: server.price || '-',
        cpu: server.cpu,
        ram: server.ram,
        storage: server.storage,
        disksCount: server.storage?.count,
        diskType: server.storage.details.map((detail:any) => {
          const typeValue = server?.inventory.find((inventory:any) => inventory.model === detail.model)?.customfields?.find((field:any) => field.fieldId === 1).value
          return{
            size: detail?.size,
            type: diskTypes.find((type:any) => type.id === typeValue)?.type
          }
        })
      })));

      if (resServers.data.servers?.length === 0) {
        viewAlert({
            severity: 'error',
            message: `${t("NO_FREE_SERVERS")}`
        });
        navigate('/bare-metal');
      }
      
      await setLoadingRefresh(false);
      await setPageLoading(false);
    } catch (err) {
        console.log('err', err);
    }
  }
  useEffect(() => {
    if(!step){
      setStep('configure')
    }
    if(tabs.find((item:any) => item.slug === step) === -1){
      setStep('configure')
    }
  },[])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("CONFIGURE_BAREMETAL_SERVER"),
      slug:'configure',
      id:'1'
    },
    {
      title:t("NETWORK_SETTINGS"),
      slug:'network',
      id:'2'
    },
  ])
  // const getCurrentPrice = () => {
  //   let queries:any = {
  //     flavorId: configureData?.type,
  //     region:configureData?.region,
  //     clientId: user?.user.active_client,
  //     networks: [networkData?.network]
  //   }
  //   if(networkData?.network){
  //     setPriceLoading(true)
  //     instanceService.getPrice(queries)
  //     .then((res) => {
  //       setPrice(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
  //       setPriceMonth(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
  //       setCurrency(res.data.estimated_cost_display[0])
  //       setPriceLoading(false)
  //     })
  //   }
  // }

  // useEffect(() => {
  //   getCurrentPrice()
  // },[configureData, networkData])
  useEffect(() => {
    getCreateOptions()
  },[])
  const theme:any = useTheme()
  // const flavor = flavors?.find((flavor:any) => flavor.value === configureData?.type)
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow
        onClick={() => navigate('/bare-metal')}
        title={t("BACK_TO_BARE_METAL")}
      />
      <TitleBig>{t("CREATE_NEW_BARE_METAL_SERVER")}</TitleBig>
      <div style={{width:'100%'}}>
        <Tabs
          tabs={tabs}
          size='display'
          checked
          isSteps
          location={step}
        />
      </div>
      <BodyWrapper>
        {step === 'configure' ? 
          <Configure disks={disks} serverList={serverList} osList={osList} />
        : null}
        {step === 'network' ? 
          <Network loadingRefresh={loadingRefresh} getCreateOptions={getCreateOptions} sshKeys={sshKeys}/>
        : null}
        {step === 'finally' ? 
          <FinallyCreate sshKeys={sshKeys} osList={osList} serverList={serverList}/>
          : null
        }
      </BodyWrapper>
    </Container>
  )
}

export default CreateBareMetalServer