import React, { FC, useEffect, useState } from "react";
import {Container} from './styles'
import { Button, Input, Select, Toggle, TopProgress } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { loadBalancerService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { Title } from "../../../Instances/styles";




const EditMember:FC<any> = ({isEdit}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {poolId, lbId, monitorId} = useParams()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [admin, setAdmin] = useState<boolean>(true)
  const {viewAlert} = useUi()
  const [initialValues, setInitialValues ] = useState<any>({
    admin_state_up:true,
    delay:5,
    expected_codes:"200",
    http_method:"GET",
    max_retries:3,
    max_retries_down:3,
    name:"",
    timeout:5,
    type:"HTTPS",
    url_path:"/",
  })
  const [protocols, setProtocols] = useState<any[]>([])
  const [httpMethods, setHttpMethods] = useState<any[]>([])
  const [monitor, setMonitor] = useState<any>()


  const getCreateOptions = async () => {
    try{
      const createOptionsRes = await loadBalancerService.getCreateOptions()
      await setProtocols(
        Object.values(createOptionsRes.data.monitor_types).map((protocol) => {
          return {
            value:protocol,
            label:protocol,
          }
        })
      )
      await setHttpMethods(
        Object.values(createOptionsRes.data.monitor_http_methods).map((method) => {
          return {
            value:method,
            label:method,
          }
        })
      )
      if(monitorId && poolId){
        const monitorRes:any = await loadBalancerService.getMonitorById(monitorId, poolId)
        await setMonitor(monitorRes.data)
        await setInitialValues({
          admin_state_up:monitorRes?.data?.admin_state_up,
          delay: monitorRes?.data?.delay,
          expected_codes: monitorRes?.data?.expected_codes,
          http_method: monitorRes?.data?.http_method,
          max_retries: monitorRes?.data?.max_retries,
          max_retries_down: monitorRes?.data?.max_retries_down,
          name: monitorRes?.data?.name,
          timeout: monitorRes?.data?.timeout,
          type: monitorRes?.data?.type,
          url_path: monitorRes?.data?.url_path,
          id: monitorRes?.data?.id
        })
        await setAdmin(monitorRes?.data?.admin_state_up)
      }
    } catch (err:any) {
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    } finally {
      setPageLoading(false)
    }
  }
  useEffect(() => {
    getCreateOptions()
  },[])
  const onSubmit = (values:any) => {
    if(!poolId) return;
    setLoading(true)
    if(isEdit) {
      loadBalancerService.editHealthMonitor(poolId, {...values, admin_state_up:admin})
      .then((res) => {
        setLoadingComplete(true)
          setTimeout(() => {
            navigate(`/load-balancer-pool/${lbId}/${poolId}/health-monitors`)
            viewAlert({
              severity:'info',
              message:isEdit ? `${t("MONITOR_EDITED_SUCCESS")}` : `${t("MONITOR_CREATE_SUCCESS")}`,
            })
          }, 1500)
      })
      .catch((err) => {
        viewAlert({
          severity:'error',
          message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
        })
        setLoading(false)
      })
    } else {
      loadBalancerService.createHealthMonitor(poolId, {...values, admin_state_up:admin})
      .then((res) => {
        setLoadingComplete(true)
          setTimeout(() => {
            navigate(`/load-balancer-pool/${lbId}/${poolId}/health-monitors`)
            viewAlert({
              severity:'info',
              message:isEdit ? `${t("MONITOR_EDITED_SUCCESS")}` : `${t("MONITOR_CREATE_SUCCESS")}`,
            })
          }, 1500)
      })
      .catch((err) => {
        viewAlert({
          severity:'error',
          message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
        })
        setLoading(false)
      })
    }
  }
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`).max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      delay: yup.string().required(),
      max_retries: yup.string().required(),
      max_retries_down: yup.string().required(),
      timeout: yup.string().required(),
      type: yup.string().required(),
      expected_codes:yup.string(),
      http_method:yup.string(),
      url_path:yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return (
    <Container>
      {isEdit &&
      <Title>{t("EDIT_FOR")} «{monitor?.name || monitor?.id}»</Title>}
      <Input
        fieldName='name'
        toolTip={false}
        title={t("NAME")}
        placeholder=''
        customStyles={{width:'360px', marginBottom:'14px'}}
        {...formikProps}
      />
      <Select
        required
        toolTip={false}
        data={protocols}
        selectedValue={values['type']}
        onChange={(e:any) => handleChange('type')(e)}
        title={t("TYPE")}
        customStyles={{marginBottom:'20px', width:'360px'}}
      />
      {values['type'] === 'HTTP' || values['type'] === 'HTTPS' ? 
        <>
          <Select
            toolTip={false}
            data={httpMethods}
            selectedValue={values['http_method']}
            onChange={(e:any) => handleChange('http_method')(e)}
            title={t("HTTP_METHOD")}
            customStyles={{marginBottom:'20px', width:'360px'}}
          />
          <Input
            fieldName='expected_codes'
            toolTip={false}
            title={t("EXPECTED_CODES")}
            placeholder=''
            customStyles={{width:'360px', marginBottom:'14px'}}
            {...formikProps}
          />
          <Input
            fieldName='url_path'
            toolTip={false}
            title={t("URL_PATH")}
            placeholder=''
            customStyles={{width:'360px', marginBottom:'14px'}}
            {...formikProps}
          />
        </>
      : null}
      <div style={{display:'flex', gap:'20px'}}>
        <Input
          required
          fieldName='max_retries_down'
          toolTip={false}
          title={t("MAX_RETRIES_DOWN")}
          placeholder=''
          customStyles={{width:'245px', marginBottom:'14px'}}
          {...formikProps}
        />
        <Input
          required
          fieldName='delay'
          toolTip={false}
          title={t("DELAY_SEC")}
          placeholder=''
          customStyles={{width:'245px', marginBottom:'14px'}}
          {...formikProps}
        />
      </div>
      <div style={{display:'flex', gap:'20px'}}>
        <Input
          required
          fieldName='max_retries'
          toolTip={false}
          title={t("MAX_RETRIES")}
          placeholder=''
          customStyles={{width:'245px', marginBottom:'14px'}}
          {...formikProps}
        />
        <Input
          required
          fieldName='timeout'
          toolTip={false}
          title={t("TIMEOUT_SEC")}
          placeholder=''
          customStyles={{width:'245px', marginBottom:'14px'}}
          {...formikProps}
        />
      </div>
      <Toggle value={admin} onChange={() => setAdmin(!admin)} title={t("ADMIN_STATE_UP")}/>
      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          loading={loading}
          loadingComplete={loadingComplete}
          title={isEdit ? t("SAVE") : t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate(-1)}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default EditMember