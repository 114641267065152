import React, {FC, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { billingService } from '../../../../api';
import {
  BackArrow,
  TopProgress,
  Typography,
  InfoBlock,
  ModalVariables,
} from '../../../../components';
import { useUi } from '../../../../context/ui.context';
import { icons } from '../../../../icons';
import {
  Container,
  Header,
  RowHeaderComponent,
  RightSideComponent,
  StatusDot,
  StatusText,
  StatusContainer,
  ServiceBody,
  TopInfoWrapper,
} from './styles';
import MenuButton from '../../../../components/button/menu-button';
import { InfoContainer, InfoTitle } from '../../../../components/info-block/styles';
import { timeConverterMMDDYYHHMM } from '../../../../utils/dates';
import ServiceSkeleton from './skeleton'
import { handleStatusName } from '../../../../utils/handle-status-name';

const ServicePage:FC = () => {
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const navigate = useNavigate()
  const [service, setService] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const {serviceId} = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }

  const getService = () => {
    if(serviceId){
      billingService.getService(serviceId)
      .then((res) => {
        setService(res.data)
        setPageLoading(false)
      })
      .catch((err) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: err.response.data.detail
          })
        }
        navigate(-1)
      })
    }
  }
  useEffect(() => {
    getService()
  },[serviceId])
  
  const dataTimeline = [
    {
      title:t("CREATED_AT"),
      id:'1',
      value:timeConverterMMDDYYHHMM(service?.created_at),
    },
    {
      title:t("NEXT_INVOICE_DATE"),
      id:'2',
      value:timeConverterMMDDYYHHMM(service?.next_invoice_date),
    },
    {
      title:t("PAID_UNTIL"),
      id:'3',
      value:timeConverterMMDDYYHHMM(service?.paid_until),
    },
    {
      title:t("LAST_UPDATE"),
      id:'4',
      value:timeConverterMMDDYYHHMM(service?.updated_at),
    },
  ]
  const dataProduct = [
    {
      title:t("NAME"),
      id:'1',
      value:service?.product?.name,
    },
    {
      title:t("DESCRIPTION"),
      id:'2',
      value:service?.product?.description,
    },
    {
      title:t("PAYMENT"),
      id:'3',
      value:service?.cycle?.display_name,
    },
    {
      title:t("SETUP_FEE"),
      id:'4',
      value:service?.cycle?.setup_fee,
    },
    {
      title:t("PROJECT_NAME"),
      id:'5',
      value:service?.resources?.service_resources.name,
    },
    {
      title:t("BILLING_CYCLE"),
      id:'6',
      value:service?.cycle?.display_name,
    },
    {
      title:t("CURRENCY"),
      id:'7',
      value:service?.currency?.code,
    },
    {
      title:t("IS_FREE"),
      id:'8',
      value:'No',
    },
    {
      title:t("RENEW_ENABLED"),
      id:'9',
      value:'No',
    },
  ]
  const closeModal = () => {
    if (modalType !== 'universal' || !serviceId) return;
    setLoading(true);
    const data = {
      action: alertTitle === t("SHELVE_ALL_INSTANCES_TITLE") ? 'shelve' : 'unshelve'
    };
  
    billingService.executeActionAllInstances(serviceId, data)
      .then(handleSuccess)
      .catch(handleError)
      .finally(() => {
        setLoading(false);
        setViewModal(false);
        setActiveAnimation(false);
      });
  
    function handleSuccess(res:any) {
      viewAlert({
        severity: 'info',
        message: res.data.detail
      });
    }
  
    function handleError(err:any) {
      if (err?.response?.data?.detail) {
        viewAlert({
          severity: 'error',
          message: err.response?.data?.detail
        });
      }
    }
  };

  if(pageLoading) return <ServiceSkeleton pageLoading={pageLoading}/>
  return (
    <Container>
      <Header>
        <BackArrow onClick={() => navigate(-1)} title={t("BACK_TO_BILLING")}/>
        <RowHeaderComponent>
          <Typography variant='h1'>
            {service.product.name}
          </Typography>
          <RightSideComponent>
            <MenuButton
              onClick={() => {
                setAlertTitle(t("UNSHELVE_ALL_INSTANCES_TITLE"))
                setAlertSubTitle(`${t("UNSHELVE_ALL_INSTANCES_SUBTITLE")} ${service.product.name}?`)
                openModal('universal')
              }}
              >
              <img src={icons.Shelve}/>
            </MenuButton>
            <MenuButton
              onClick={() => {
                setAlertTitle(t("SHELVE_ALL_INSTANCES_TITLE"))
                setAlertSubTitle(`${t("SHELVE_ALL_INSTANCES_SUBTITLE")} ${service.product.name}?`)
                openModal('universal')
              }}
              >
              <img src={icons.Unshelve}/>
            </MenuButton>
          </RightSideComponent>
        </RowHeaderComponent>
        <StatusContainer>
          <StatusDot status={service.status_display}/>
          <StatusText status={service.status_display}>
            {handleStatusName(service.status_display, t)}
          </StatusText>
        </StatusContainer>
      </Header>
      <ServiceBody>
        <TopInfoWrapper>
          <InfoBlock
            customStyles={{flex:'auto 1', marginBottom:'0px'}}
            title={t("PRODUCT")}
            data={dataProduct}
          />
          <InfoBlock
            customStyles={{flex:'auto 1', marginBottom:'0px'}}
            title={t("TIMELINE")}
            data={dataTimeline}
          />
        </TopInfoWrapper>
        {/* <InfoContainer>
          <InfoTitle>
            {t("CONFIGURATION_OPTIONS")}
          </InfoTitle>
          <Typography variant='body'>
            No configurable options
          </Typography>
        </InfoContainer> */}
        {/* <Button
          title={t("UPGRADE_DOWNGRADE_BILLING")}
          variant='primary'
          size='display'
          onClick={() => {}}
        /> */}
      </ServiceBody>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        alertSubTitle={alertSubTitle}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        loading={loading}
        confirmButtonText={t("CANCEL")}
        cancelButtonText={t("CONFIRM")}
      />
    </Container>
  )
}

export default ServicePage