import React, { FC, useRef, useState } from "react";
import {
  Container,
  VideoDescWrapper,
  PlayBackground,
  VideoTitle,
  VideoSubTitle,
} from './styles'
import BigPlay from './big-play.svg'
import Dialog from "../dialog";
type VideoProp = {
  url?:string,
  img?:any,
  title:string,
  subtitle:string
}

const TutorialVodeo:FC<VideoProp> = (props) => {
  const [active, setActive] = useState<boolean>(false)
  const [activeVideo, setActiveVideo] = useState<boolean>(false)
  const IframeRef:any = useRef()
  const {
    url,
    img,
    title,
    subtitle
  } = props
  let videoStopper = function(id:any) {
    let containerElement:any = document.getElementById(id);
    let iframe_tag = containerElement.querySelector( 'iframe');
    let video_tag = containerElement.querySelector( 'video' );
    if ( iframe_tag) {
        let iframeSrc = iframe_tag.src;
        iframe_tag.src = iframeSrc; 
    }
    if ( video_tag) {
        video_tag.pause();
    }
  }
  return (
    <>
      <Container onClick={() => {
          if(url){
            setActive(!active)
            setTimeout(() => {
              setActiveVideo(!activeVideo)
            },200)
          }
        }} style={{backgroundImage:`url(${img})`}}>
        <VideoDescWrapper>
          <PlayBackground>
            <img src={BigPlay}/>
          </PlayBackground>
          <VideoTitle>
            {title}
          </VideoTitle>
          <VideoSubTitle>
            {subtitle}
          </VideoSubTitle>
        </VideoDescWrapper>
      </Container>
      <Dialog  handleClose={() => {
        videoStopper("videoContainer")
        setActive(false)
        setTimeout(() => {
          setActiveVideo(false)
        },200)
        }} active={activeVideo} open={active}>
        {/* <div style={{width:'90vw'}}> */}
        <iframe
          ref={IframeRef}
          style={{backgroundColor:"black", border:'none'}}
          src={url}
          allow='autoplay; encrypted-media'
          
          title='video'
          width={'100%'}
          height={'100%'}
        />
        {/* </div> */}
      </Dialog>
    </>
  )
}

export default TutorialVodeo