import styled from "styled-components";


export const Container = styled.div`
  padding-top:20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap:20px;
`
export const AddonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap:20px;

`

export const ItemAddon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px;
  padding: 12px 20px;
  border-radius: 8px;
  width: 353px;
  flex: 1 auto;
  border: ${({theme}) => `1px solid ${theme.grey[350]}`};
  background-color: ${({theme}) => theme.palette[10]};
`