import axios from "axios";
import ApiClient from "./apiClient";

type SignUp = {
  email:string,
  first_name:string,
  last_name:string,
  password:string,
  rememberMe?:boolean,
  invitation_id?:any,
  invitation_token?:any,
}
type SignIn = {
  email:string,
  password:string,
  rememberMe?:boolean,
  sfa_params?:any
}

const authService = {
  signUp: (data:SignUp) => {
    document.cookie = ''
    return ApiClient.post('signup', data, {
    withCredentials: false,
  })},
  // signIn: (data:any) => axios.post('https://auth.netengi.ua/auth/realms/Onecloud/protocol/openid-connect/token', data, {
  //   headers:{
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   }
  // }),
  signIn: (data:any) => ApiClient.post('login', data),
  getUser: (token?:any) => ApiClient.get('current-user'),
  resetPassword:(data:any) => ApiClient.post('reset-password/', data),
  resendEmailCode:() => ApiClient.post('resend-confirmation-email/'),
  resetPasswordConfirm:(data:any) => ApiClient.post('reset-password/confirm/',data),
  updateClient: (data:any) => ApiClient.put('user_update/', data),
  // updateClient: (data:any) => ApiClient.put('userprofile/update', data),
  getUserProfile: () => ApiClient.get('userprofile'),
  getAppMenu: () => ApiClient.get('dynamic-ui/get_app_menu'),
  getsfAuth:() => ApiClient.get('sfauth/sfa-types-manager'),
  getAuthGoogleStatus:() => ApiClient.get('plugins/google_authenticator/api/get_sfa_method_status'),
  getAuthSecretKey:() => ApiClient.get('plugins/google_authenticator/api/get_code'),
  getGoogleQrCode: () => ApiClient.get('plugins/google_authenticator/api/get_qr_code', { responseType:"blob" }),
  enableGoogleAuth: (code:string) => ApiClient.post('plugins/google_authenticator/api/enable_sfa_method', {verification_code:code}),
  regenerateCode:() => ApiClient.post('plugins/google_authenticator/api/regenerate_key'),
  disableGoogleAuth:() => ApiClient.post('plugins/google_authenticator/api/disable_sfa_method'),
  checkInviteData:(id?:string, token?:string) => ApiClient.get(`invitations/${id}?token=${token}`),
  getTokenOS:() => ApiClient.post('give_token'),
  openstackGet:() => ApiClient.post('openstack_sso/'),
  confirmPasswordSfa:(data:any) => ApiClient.post(`check_password/`, data),
  // confirmPasswordSfa:(data:any) => ApiClient.post(`sfauth/sfa-types-manager/confirm_password`, data),
  generateOtpToken: () => ApiClient.post('generate_otp/'),
  samlAuth:(data:Object) => ApiClient.post(`saml2sso`, data),
  logout:() => ApiClient.post('logout'),
  checkRoute:(route:string) => ApiClient.get(`plugins/domains/${route}/`),
  getStatus2fa:() => ApiClient.get(`two_factor_auth/`),
  enable2fa:(data:Object) => ApiClient.put(`two_factor_auth/enable/`, data),
  disable2fa:(data:Object) => ApiClient.put(`two_factor_auth/disable/`, data),
  // activity:() => ApiClient.get('activitylog?filtering=user%3A857915&page_size=20&ordering=-created_at'),
}

export default authService