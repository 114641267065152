import React, { FC, useState } from "react";
import {
  Container,
  TitleBig,
  FormWrapper
} from './styles'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  BackArrow,
  Input,
  Button,
  DragAndDrop,
  TopProgress
} from '../../../components'
import { useUi } from "../../../context/ui.context";
import { sshService } from "../../../api";

const ImportSSHKey:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [generated, setGenerated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const {viewAlert} = useUi()
  const [initialValues, setInitialValues ] = useState<any>({
    name:'',
    public_key:'',
  })
  const [file, setFile] = useState<any>()
  const [oneSubmitted, setOneSubmitted] = useState<boolean>(false)
  const onSubmit = () => {
    setLoading(true)
    const data = {
      name:values.name,
      public_key:values.public_key,
    }
    if(values.private_key){
      //@ts-ignore
      data.private_key = values.private_key
    }
    sshService.createSsh(data)
    .then((res) => {
      navigate(-1)
    })
    .catch((e) => {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      if(e?.response?.data.public_key){
        setFieldError('public_key', e?.response?.data.public_key)
      }
      setErrors(e?.response?.data)
    })
    .finally(() => {
      setLoading(false)
    })
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    setFieldError,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t('REQUIRED_FIELD')}`).max(30, `${t("STRING_VALIDATE_MAX")} 30 ${t("CHARACTERS")}`),
      public_key:yup.string().required(`${t('REQUIRED_FIELD')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  return(
    <Container>
      <TopProgress loading={loading}/>
      <BackArrow
        onClick={() => navigate('/ssh-keys')}
        title={t("BACK_TO_KEYS")}
      />
      <TitleBig>{t("IMPORT_A_NEW_SSH")}</TitleBig>
      <FormWrapper>
        <Input
          required
          fieldName='name'
          title={t("NAME_KEY")}
          placeholder=''
          {...formikProps}
        />
        <DragAndDrop 
          // customStyles={{marginBottom:'20px'}}
          required
          uploadedFile={file}
          onChange={setFile}
          formats={'.pub'}
          fileValidation={['pub']}
          isSsh
          setStringSSh={(e:string) => { 
            handleChange('public_key')(e)
          }}
          requiredListener={oneSubmitted && !file}
        />
        {file &&
          <Input
            isTextArea
            required
            fieldName='public_key'
            toolTip={true}
            title={t("KEY_CONTENT")}
            readOnly
            customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
            placeholder=''
            {...formikProps}
          />
        }
        
      </FormWrapper>
      <div style={{display:'flex', marginTop:'40px', gap:'10px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{}}
          onClick={() => {
            setOneSubmitted(true)
            handleSubmit()
          }}
          title={t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate("/ssh-keys")}
          title={t("CANCEL")}
        />
      </div>
    </Container>
  )
}

export default ImportSSHKey