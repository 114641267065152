import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,

} from '../pools/styles'
import { Button, ModalVariables, Table, TopProgress } from '../../../../components'
import { icons } from '../../../../icons'
import { useNavigate, useParams } from 'react-router-dom'
import { loadBalancerService, networkService } from '../../../../api'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { useUi } from '../../../../context/ui.context'
import { handleStatusName } from '../../../../utils/handle-status-name'
import { StateWrapper, GreenDot, Greentext } from '../../../Instances/styles'
import { useAuth } from '../../../../context/user.context'

const Policies:FC = () => {
  const [t] = useTranslation()
  const {lbId,listenerId} = useParams()
  const {viewAlert} = useUi()
  const navigate = useNavigate()
  const {user} = useAuth()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [listeners, setListeners] = useState<any[]>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()

  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [deletedPolicy, setDeletedPolicy] = useState<any>()


  const editChecked = (item:any) => {
    const founded = checked.find((ckd:any) => ckd.id === item?.id)
    if(allChecked){
      setAllChecked(!allChecked)
    }
    if(founded) {
      setChecked((prev:any) => {
        return prev.filter((ckd:any) => ckd.id !== item?.id)
      })
    } else {
      setChecked([...checked, item])
    }
  }
  const _updatePorts = () => {
    //@ts-ignore
    loadBalancerService.getPolicies(listenerId, user?.user?.active_client)
    .then((res) => {
      setListeners(res.data.objects.map((policy:any) => {
        return{
          ...policy,
          name:policy.name || 'n/a',
          navigate:`/load-balancer-listener/${lbId}/${listenerId}/policies/${policy.id}/overview`,
          admin_state_up:policy.admin_state_up ? 'Yes' : 'No',
          operating_status: 
            (<StateWrapper>
              <GreenDot active={policy.operating_status}/>
              <Greentext active={policy.operating_status}>
                {handleStatusName(policy.operating_status, t)}
              </Greentext>
            </StateWrapper>),
          provisioning_status: 
            (<StateWrapper>
              <GreenDot active={policy.provisioning_status}/>
              <Greentext active={policy.provisioning_status}>
                {handleStatusName(policy.provisioning_status, t)}
              </Greentext>
            </StateWrapper>),
        }
      }))
      setPageLoading(false)
      setLoading(false)
    })
  }
  const _deletePorts = async () => {
    if(!lbId) return
    setLoading(true)
    try{
        await loadBalancerService.deletePolicy(deletedPolicy.id)
        await viewAlert({
          severity:'info',
          message:`${t("POLICY_DELETED_SUCCESSFUL")}`
        })
        await _updatePorts()
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      _updatePorts()
      setPageLoading(false)
      setLoading(false)
    } finally {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }
  useEffect(() => {
    _updatePorts()
    const interval = setInterval(() => {
      _updatePorts()
    },10000)
    return () => clearInterval(interval)
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = (props:any) => {
    _deletePorts()
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (policy:any) => navigate(`${policy.id}/edit`),
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(id:any) => {
        openModal('delete')
        setAlertTitle(t("POLICY"))
        setAlertSubTitle([id])
        setDeletedPolicy(id)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("POSITION"),
      key:'position',
    },
    {
      title:t("ACTION"),
      key:'action'
    },
    {
      title:t("OPERATING_STATUS"),
      key:'operating_status'
    },
    {
      title:t("PROVISIONING_STATUS"),
      key:'provisioning_status'
    },
    {
      title:t("ADMIN_STATE_UP"),
      key:'admin_state_up'
    },
    {
      title:'',
      key:'props'
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={loading}/>
      <TopInfoWrapper>
        <div style={{display:'flex', marginBottom:'20px'}}>
          <Button
            variant="primary"
            size="display"
            onClick={() => navigate(`create`)}
            title={t("CREATE_NEW_POLICY")}
            customStyles={{marginRight:'10px'}}
          />
          {/* <Button
            onClick={() => {
              setAlertTitle(t("LISTENERS"))
              setAlertSubTitle(allChecked ? listeners : checked)
              openModal('delete')
            }}
            variant='icon'
            size='display'
            disabled={!allChecked && checked?.length === 0 }
          >
            <img src={icons.DeleteIcon}/>
          </Button> */}
        </div>
      </TopInfoWrapper>
        <Table
          columns={Columns}
          rows={listeners}
          settings={items}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          checked={checked}
          setChecked={editChecked}
          hideOther
          hideCheckbox
        />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={editedSubnet}
        loading={loading}
      />
    </Container>
  )
}
export default Policies