import styled from 'styled-components'

export const Container = styled.div`
  padding-top:20px;
  padding-right:40px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap:20px;
`

export const DarkForm = styled.div`
  display: flex;
  background-color: ${({theme}) => theme.palette[200]};
  align-items: flex-start;
  border-radius: 8px;
  margin-top: 10px;
  width:100%;
  box-sizing: border-box;
  padding: 14px;
  flex-grow: 2;
  gap:10px;
`
type HideProps = {
  active:boolean,
}
export const HideContainer = styled.div<HideProps>`
  max-height: ${({active}) => active ? '1000px' : '0px'};
  overflow:${({active}) => active ? '' : 'hidden'};
  transition: max-height 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  gap:20px;
`

export const GreyContainer = styled.div`
  display: flex;
  width:100%;
  justify-content: space-between;
  padding: 4px 6px;
  border-radius: 4px;
  background: ${({theme}) => theme.palette[200]};
`