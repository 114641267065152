import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";



const affiliateService = {
  getConversions:() => ApiClient.get(`ref-program/conversions/`),
  getComissions:() => ApiClient.get(`ref-program/commissions/`),
  getCustomers:() => ApiClient.get(`ref-program/customers/`),
  createRef:() => ApiClient.post(`ref-program/`),
  getRef:() => ApiClient.get(`ref-program/info/`),
  addToReff:(data:any) => ApiClient.post(`ref-program/add_referral/`, data),
  getBalance:() => ApiClient.get(`ref-program/balance/`),
  // create:() => ApiClient.get(`ref-program/commissions/`),
  // getComissions:() => ApiClient.get(`ref-program/commissions/`),
}

export default affiliateService