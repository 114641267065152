import styled from 'styled-components';
import { BodyMedium, HeadLine1, HeadLine3 } from '../../../../components/typography/styled';

type StatusProp = {
  status?: string,
}

export const statusColor = (status?: string) => {
  switch (status) {
    case "unpaid":
      return "#FFF7E3";
    case "suspended":
      return "#FFE6E6";
    case "paid":
      return "rgba(34, 155, 97, 0.1);";
    default:
      return "";
  }
}

export const statusColorText = (status?: string) => {
  switch (status) {
    case "unpaid":
      return "#FFB423";
    case "suspended":
      return "#E85048";
    case "paid":
      return "#229B61";
    default:
      return "";
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 30px 30px;
  position: relative;
`
export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
  margin-right: 10px;
`

export const LeftSide = styled.div`
  display:flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;

`

export const RightHeadContainer = styled.div`
  display:flex;
  margin-right: 10px;
  position: relative;
`

export const InvoiceName = styled(HeadLine1)`
  color:${({theme}) => theme.palette[800]};
  margin-top:10px;
  margin-bottom:10px;
`
export const RowBodyWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  gap:30px;
  align-items:flex-start;
  margin-top:20px;
  margin-right:10px;
`

export const ColumnBodyWrapper = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  gap:10px;
`

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 14px;
  box-sizing: border-box;
  width: 240px;
  /* height: 225px; */
  background: ${({theme}) => theme.palette[200]};
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;

`

export const ItemTitle = styled(HeadLine3)`
  color: ${({theme}) => theme.primary[600]};
`
export const Divider = styled.div`
  border: ${({theme}) => `1px solid ${theme.palette[350]}`};
  width:100%;
  margin-top:10px;
  margin-bottom:10px;
`

export const PriceTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${({theme}) => `${theme.palette[200]}`};
  padding: 6px 10px;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
`

export const PriceTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

`

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

`

export const InvoiceStatusContainer = styled.div<StatusProp>`
  display: flex;
  box-sizing: border-box;
  padding:5px 10px;
  border-radius:4px;
  background-color: ${({status}) => statusColor(status)};
`

export const InvoiceStatusText = styled(BodyMedium)<StatusProp>`
  color: ${({status}) => statusColorText(status)};
  text-transform:capitalize;
`
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  /* margin-top: 10px; */
  gap:10px;
`