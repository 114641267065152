import React, {FC, useEffect, useState} from "react";
import {
  Container,
  BodyWrapper,
} from './styles'
import { TableSkeleton, TopProgress } from "../../../../components";
import { InfoContainer } from "../../../../components/info-block/styles";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";


const ServiceSkeleton:FC<any> = ({pageLoading}) => {
  const theme:any = useTheme()
  return (
    <Container style={{gap:'10px'}}>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={135} height={25}
      />
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={235} height={50}
      />
      <BodyWrapper>
      <InfoContainer
        style={{
          display:'flex',
          flexDirection:'row',
          flex:'1 1',
          marginBottom:'0px',
          flexGrow:'1',
          flexBasis:'0',
          boxSizing:'border-box',
        }}>
          <div style={{
            display:"flex",
            flexDirection:'column',
            gap:'10px',
            flexGrow:'1',
            flexBasis:'0'
          }}>
            <Skeleton
              baseColor={theme.palette[300]}
              highlightColor={theme.palette[20]}
              width={95} style={{marginBottom:"5px"}} height={15}
            />
            {['1','2','3','4','5','6','7','8','9'].map((_) => (
              <Skeleton
                baseColor={theme.palette[300]}
                highlightColor={theme.palette[20]}
                width={155} style={{marginBottom:"5px"}} height={10}
              />
            ))}
          </div>
          <div style={{
            display:"flex",
            flexDirection:'column',
            gap:'10px',
            flexGrow:'1',
            flexBasis:'0'
          }}>
            {['1','2','3','4','5','6','7','8','9','10'].map((_, index:any) => index === 0 ? <div style={{minHeight:'20px'}}/> : (
              <Skeleton
                baseColor={theme.palette[300]}
                highlightColor={theme.palette[20]}
                width={195} style={{marginBottom:"5px"}} height={10}
              />
            ))}
          </div>
        </InfoContainer>
        <InfoContainer
          style={{
            display:'flex',
            flexDirection:'row',
            flex:'1 1',
            marginBottom:'0px',
            flexGrow:'1',
            flexBasis:'0',
            boxSizing:'border-box',
        }}>
          <div style={{
            display:"flex",
            flexDirection:'column',
            gap:'10px',
            flexGrow:'1',
            flexBasis:'0'
          }}>
            <Skeleton
              baseColor={theme.palette[300]}
              highlightColor={theme.palette[20]}
              width={95} style={{marginBottom:"5px"}} height={15}
            />
            {['1','2','3','4'].map((_) => (
              <Skeleton
                baseColor={theme.palette[300]}
                highlightColor={theme.palette[20]}
                width={155} style={{marginBottom:"5px"}} height={10}
              />
            ))}
          </div>
          <div style={{
            display:"flex",
            flexDirection:'column',
            gap:'10px',
            flexGrow:'1',
            flexBasis:'0'
          }}>
            {['1','2','3','4','5'].map((_, index:any) => index === 0 ? <div style={{minHeight:'20px'}}/> : (
              <Skeleton
                baseColor={theme.palette[300]}
                highlightColor={theme.palette[20]}
                width={195} style={{marginBottom:"5px"}} height={10}
              />
            ))}
          </div>
        </InfoContainer>
      </BodyWrapper>
    </Container>
  )
}

export default ServiceSkeleton