import React, { FC, useEffect, useRef, useState } from "react";
import {
  Container,
  VideoDescWrapper,
  PlayBackground,
  VideoTitle,
  VideoSubTitle,
} from './styles'
import BigPlay from './big-play.svg'
import Dialog from "../dialog";
type VideoProp = {
  url:string,
  activeProp:boolean,
  handleClose:any,
}

const PrevImage:FC<VideoProp> = (props) => {
  const [active, setActive] = useState<boolean>(false)
  const [activeVideo, setActiveVideo] = useState<boolean>(false)
  const {
    url,
    activeProp,
    handleClose
  } = props
  useEffect(() => {
    setActive(activeProp)
    setActiveVideo(activeProp)
  },[activeProp])

  return (
    <>
      <Dialog  handleClose={handleClose} active={activeVideo} open={active}>
        {/* <div style={{width:'90vw'}}> */}
        <img style={{width:'100%', height:'100%', objectFit:"contain"}} src={url}/>
        {/* </div> */}
      </Dialog>
    </>
  )
}

export default PrevImage