import React, { FC } from 'react'
import {Container, StyledToggle, DotPoint, ToolTipImg} from './styles'
import { BodyMedium } from '../typography/styled'
import { icons } from '../../icons'
import Label from '../label'

type ToggleProps = {
  title:string,
  value:boolean,
  onChange:() => void,
  customStyles?:any,
  toolTip?:boolean,
  tooltipText?:string,
  test_id?:string,
}

const Toggle:FC<ToggleProps> = (props) => {
  const {
    title,
    value,
    onChange,
    customStyles,
    tooltipText,
    toolTip=false,
    test_id,
  } = props

  return(
    <Container customStyles={customStyles}>
      <StyledToggle data-test-id={test_id} noMargin={title.length === 0 } onClick={onChange} active={value} >
        <DotPoint  active={value} />
      </StyledToggle>
      {title.length === 0 ? null : 
        <Label margin={''} tooltipText={tooltipText} toolTip={toolTip}title={title}/>
      }
    </Container>
  )
}


export default Toggle