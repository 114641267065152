import styled from "styled-components";
import { BodyDefault, BodyMedium, HeadLine2 } from "../../../../components/typography/styled";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top:30px;
  /* margin-right:10px; */
  /* margin-left:30px; */
  position:relative;
  /* width: 50%; */
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom:10px;
  width: 100%;
`

export const Title = styled(HeadLine2)`
  color:${({theme}) => theme.grey[800]};
`
export const LinkStyled = styled(BodyDefault)`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  color:${({theme}) => theme.primary[600]};
  &:hover{
    cursor:pointer;
  }
`
export const TutorItem = styled.div`
  box-sizing: border-box;
  /* height: 92px; */
  background:${({theme}) => theme.palette[100]};
  border-radius: 8px;
  overflow: hidden;
  padding:14px 20px;
  width: 435px;
  order: 0;
  position: relative;
  /* margin-bottom: 10px; */
  align-self: stretch;
  flex: none;
  flex-grow: 1;
  display: flex;
  
  &:hover {
    cursor: pointer;
    background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[200]};
  }
  &:active{
    background-color: ${({theme}) => theme.isDark ? theme.palette[400] : theme.grey[300]};
    transition: 0.1s background-color ease-in-out;
  }
`

export const TutorTitle = styled(BodyMedium)`
  color:${({theme}) => theme.primary[600]};
  margin-bottom:4px;
`

export const TutorTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width:80%;
`

export const TutorContent = styled(BodyDefault)`
  color:${({theme}) => theme.palette[650]};
`

export const TutorIcon = styled.img`

  position: absolute;
  width: 88px;
  height: 88px;
  right:34px;
  bottom:-12px;
`

