import styled from 'styled-components'
import { BodyDefault, VerySmall } from '../typography/styled'
import SVG from "react-inlinesvg";
type ActiveProp = {
  active?:boolean;
  error?:boolean;
  containerCustom?:any;
  customStyles?:any;
}
export const Container = styled.div<ActiveProp>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({containerCustom}) =>containerCustom}
`

export const ToolTipImg = styled.img`
  margin-left:'4px';
  &:hover{
    cursor: pointer;
  }
`

export const PrefixText = styled.p`
  color:${({theme}) => theme.grey[600]};
  font-size: 15px;
  line-height: 20px;
  margin:0px;
  padding:0px;
`
export const StyledInput = styled.input<ActiveProp>`
  appearance: none;
  -moz-appearance: textfield; /* Firefox */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    display: none;
  }
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 12px 10px;
  margin-top: 4px;
  font-weight: 400;
  padding-right: 40px;
  font-size: 15px;
  line-height: 20px;
  outline: none;
  background-color: ${({theme}) => theme.palette[10]};
  border:${({theme, error}) => error ? `1px solid ${theme.errors[600]}` :`1px solid ${theme.grey[350]}`};
  color:${({theme}) => theme.palette[900]};
  font-family:Inter;
  border-radius:4px;
  ${({customStyles}) => customStyles};
  &:focus{
    border: ${({theme, error}) => error ? `1px solid ${theme.errors[600]}` : theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[300]}`};
    box-shadow: ${({theme, error}) => error ? theme.isDark ? '0px 0px 0px 3px rgb(205,58,50, 0.4)' : `0px 0px 0px 3px ${theme.errors[100]}` : theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`};
  }
  &::placeholder{
    color:${({theme}) => theme.grey[600]};
  }
  &:read-only{
    color:${({theme}) => theme.grey[700]};
  }
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({theme}) => theme.palette[900]};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({theme}) => theme.palette[900]};
      /* -webkit-text-fill-color: #fff !important; */
  }
`
export const StyledTextArea = styled.textarea<ActiveProp>`
  box-sizing: border-box;
  width: 100%;
  /* height: 40px; */
  min-height: 50px;
  max-height: 450px;
  padding: 12px 10px;
  margin-top: 4px;
  font-weight: 400;
  padding-right: 40px;
  font-size: 15px;
  line-height: 20px;
  outline: none;
  background-color: ${({theme}) => theme.palette[10]};
  border:${({theme, error}) => error ? `1px solid ${theme.errors[600]}` :`1px solid ${theme.grey[350]}`};
  color:${({theme}) => theme.palette[900]};
  font-family:Inter;
  border-radius:4px;
  /* transition: 3s height ease-in-out; */
  ${({customStyles}) => customStyles}
  &:focus{
    border: ${({theme, error}) => error ? `1px solid ${theme.errors[600]}` : theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[300]}`};
    box-shadow: ${({theme, error}) => error ? `0px 0px 0px 3px ${theme.errors[100]}` : theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`};
  }
  &::placeholder{
    color:${({theme}) => theme.grey[600]};
  }
`

export const ErrorText = styled(BodyDefault)`
  color:${({theme}) => theme.errors[600]};
  margin-top: 6px;
  &:first-letter {
    text-transform: uppercase;
  }
`
export const Eye = styled.div`
  display: flex;
  position: absolute;
  top:33.5px;
  right:10px;
`

export const IconInput = styled.div`
  display: flex;
  position: absolute;
  top:33.5px;
  left:10px;
`

export const PropContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 3px 4px;
  height:21px;
  background-color: ${({theme}) => theme.palette[300]};
  border-radius: 4px;
  align-items: center;
`
export const Prop = styled(VerySmall)`
  color:${({theme}) => theme.palette[850]};
`


export const DeleteTag = styled(SVG)`
  width: 16px;
  height: 16px;
  margin-left:2px;
  margin-top:2px;
  & path {
    stroke:${({theme}) => theme.palette[800]};
  };
  &:hover{
    cursor: pointer;
    & path {
    stroke:${({theme}) =>theme.palette[800]};
  };
  }
`