import React, { FC, useEffect, useState } from "react";
import {
  Container,
  TypesWrapper,
  ClusterTypeContainer,
  TypeLeftDiv,
  InfoElement,
  TypeRightDiv,
  GreyText,
  MasterSquare,
  NodeSquare,
  ColumnContainer,
  NodesWrapper,
  SelectedCircle,
  TypeTopDiv,
  NodesInfoWrapper,
  MasterWrapperDiv,
} from './styles'
import { Button, Label, Select, TopProgress, Typography } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from 'use-query-params';
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { useKubernetes } from "../../../../context/kubernetesContext";
import CheckIc from './checkIcon.svg'
import { useInstance } from "../../../../context/instanceContext";

const SelectClusterType:FC<any> = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {setDataToLs, getDataToLs} = useLocalStorage()
  const [step, setStep] = useQueryParam('step', StringParam);
  const {
    flavors,
    sshKeys,
  } = useInstance()

  const {
    clusterTypes,
    selectedClusterType,
    setSelectedClusterType,
    kuberVersions,
    setClusterData,
    clusterData,
    setNodePoolsData,
    nodePoolsData
  } = useKubernetes()
  const onSubmit = () => {
    setStep('configure-master')
  }
  const [kubVersion ,setKubVersion] = useState<any>()

  useEffect(() => {
    setDataToLs({key:'cluster.type', data:selectedClusterType})
    setClusterData({
      ...clusterData,cluster_version:kubVersion,
      count_master:selectedClusterType?.masterCount,
      os:kuberVersions.find((v:any) => v.value === kubVersion)?.images[0]?.value
    })
    if(nodePoolsData.length > 0){
      setNodePoolsData(nodePoolsData.map((pool:any) => {
        return {
          ...pool,
          count:selectedClusterType?.nodesCount
        }
      }))
    }
  },[selectedClusterType, kubVersion])
  useEffect(() => {
    setKubVersion(getDataToLs({key:'cluster.configure_master'})?.cluster_version || kuberVersions?.[0]?.value)
  },[])
  return (
    <Container>
      <TypesWrapper>
        {clusterTypes.map((clusterType) => {
          return clusterType?.isIndividual ? 
            <ClusterTypeContainer
              onClick={() => setSelectedClusterType(clusterType)}
              selected={selectedClusterType?.id === clusterType.id}
            >
              {selectedClusterType?.id === clusterType.id &&
                <SelectedCircle><img src={CheckIc}/></SelectedCircle>
              }
              <TypeLeftDiv>
                <InfoElement style={{justifyContent:'center', paddingLeft:'20px'}}>
                  <Label
                    toolTip={true}
                    tooltipText={t(clusterType.description)}
                    title={t(clusterType.title)}
                  />
                </InfoElement>
              </TypeLeftDiv>
              <TypeRightDiv>
                <Select
                  toolTip={true}
                  tooltipText={`${t("VERSION_KUBERNETES_DESCRIPTION")}`}
                  data={kuberVersions}
                  // disabled={flavors?.length <= 0}
                  selectedValue={kubVersion}
                  onChange={(e) => {
                    setKubVersion(e)
                    const values = getDataToLs({key:'cluster.configure_master'})
                     setDataToLs({key:'cluster.configure_master', data:{...values, cluster_version:e}})
                  }}
                  // placeholder={flavors?.length <= 0 ? t("NO_FLAVORS_AVAILABLE") : t("CHOOSE")}
                  title={t("VERSION_KUBERNETES_TITLE")}
                  customStyles={{width:'172px'}}
                  fieldName='flavor'
                />
              </TypeRightDiv>
            </ClusterTypeContainer>
          :
            <ClusterTypeContainer
              onClick={() => setSelectedClusterType(clusterType)}
              selected={selectedClusterType?.id === clusterType.id}
            >
              {selectedClusterType?.id === clusterType.id &&
                <SelectedCircle><img src={CheckIc}/></SelectedCircle>
              }

              <TypeLeftDiv>
                <TypeTopDiv>
                  <Label
                    toolTip={true}
                    tooltipText={t(clusterType.description)}
                    title={t(clusterType.title)}
                  />
                </TypeTopDiv>
                <NodesInfoWrapper>
                  <MasterWrapperDiv>
                    <InfoElement style={{gap:'10px'}}>
                      <ColumnContainer style={{gap:'6px'}}>
                        <Typography variant="body-medium">
                          {t("MASTER_NODES")}
                        </Typography>
                        <NodesWrapper>
                          {Array.from(Array(clusterType.masterCount).keys()).map(() => (
                            <MasterSquare/>
                          ))}
                        </NodesWrapper>
                      </ColumnContainer>
                      <ColumnContainer>
                        <InfoElement>
                          <GreyText>
                            vCPU
                          </GreyText>
                          <Typography variant="body-medium">
                            2
                          </Typography>
                        </InfoElement>
                        <InfoElement>
                          <GreyText>
                            RAM
                          </GreyText>
                          <Typography variant="body-medium">
                            4GB
                          </Typography>
                        </InfoElement>
                        <InfoElement>
                          <GreyText>
                            {t('STORAGE')}
                          </GreyText>
                          <Typography variant="body-medium">
                            {`30 GB`}
                          </Typography>
                        </InfoElement>
                      </ColumnContainer>
                    </InfoElement>
                  </MasterWrapperDiv>
                  <MasterWrapperDiv style={{border:'none'}}>
                    <InfoElement style={{gap:'10px'}}>
                      <ColumnContainer style={{gap:'6px'}}>
                        <Typography variant="body-medium">
                          {t("WORKER_NODES")}
                        </Typography>
                        <NodesWrapper>
                          {Array.from(Array(clusterType.nodesCount).keys()).map(() => (
                            <NodeSquare/>
                          ))}
                        </NodesWrapper>
                      </ColumnContainer>
                      <ColumnContainer>
                        <InfoElement>
                          <GreyText>
                            vCPU
                          </GreyText>
                          <Typography variant="body-medium">
                            2
                          </Typography>
                        </InfoElement>
                        <InfoElement>
                          <GreyText>
                            RAM
                          </GreyText>
                          <Typography variant="body-medium">
                            8GB
                          </Typography>
                        </InfoElement>
                        <InfoElement>
                          <GreyText>
                            {t('STORAGE')}
                          </GreyText>
                          <Typography variant="body-medium">
                            {`50 GB`}
                          </Typography>
                        </InfoElement>
                      </ColumnContainer>
                    </InfoElement>
                  </MasterWrapperDiv>
                </NodesInfoWrapper>
              </TypeLeftDiv>
              <TypeRightDiv>
                <Select
                  toolTip={true}
                  tooltipText={`${t("VERSION_KUBERNETES_DESCRIPTION")}`}
                  data={kuberVersions}
                  // disabled={flavors?.length <= 0}
                  selectedValue={kubVersion}
                  onChange={(e) => {
                    setKubVersion(e)
                    const values = getDataToLs({key:'cluster.configure_master'})
                     setDataToLs({key:'cluster.configure_master', data:{...values, cluster_version:e}})
                  }}
                  // placeholder={flavors?.length <= 0 ? t("NO_FLAVORS_AVAILABLE") : t("CHOOSE")}
                  title={t("VERSION_KUBERNETES_TITLE")}
                  customStyles={{width:'172px'}}
                  fieldName='flavor'
                />
              </TypeRightDiv>
            </ClusterTypeContainer>
          }
        )}
      </TypesWrapper>
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={onSubmit}
          disabled={!selectedClusterType}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => navigate('/kubernetes')}
          title={t("CANCEL")}
        />
      </div>
    </Container>
  )
}

export default SelectClusterType
