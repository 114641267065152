import React, { FC, useEffect, useState } from "react";
import {
  Container,
  PropContainer,
  Prop,
} from './styles'
import { Button, InfoBlock, TopProgress } from '../../../../components'
import { useQueryParam, StringParam } from 'use-query-params';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { bareMetalService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import { useAuth } from "../../../../context/user.context";
import { formatQuotasRam } from "../../../../utils/sizes";

const FinallyCreate:FC<any> = ({sshKeys, osList, serverList}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [localLoading, setLocalLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [configureData, setConfigureData] = useState<any>({})
  const [networkData, setNetworkData] =useState<any>({})
  const {getDataToLs, removeItemFromLs} = useLocalStorage()
  const {viewAlert} = useUi()

  useEffect(() => {
    // if(!configureData) {
      setConfigureData(getDataToLs({key:'baremetal.configure'}))
    // }
    // if(!networkData) {
      setNetworkData(getDataToLs({key:'baremetal.network'}))
    // }
    setLocalLoading(false)
  },[])
  const [step, setStep] = useQueryParam('step', StringParam);
  console.log('configureData',configureData);
  const configure = [
    {
      title:t("NAME_OF_INSTANCE"),
      value:configureData?.name,
    },
    {
      title:t("TYPE_OF_INSTANCE"),
      value: (
        <div style={{display:'flex', alignItems:'center', gap:'6px'}}>
          {serverList.find((server:any) => server.value === configureData?.type)?.name}
          <PropContainer>
            <Prop>
            {/* {serverList.find((server:any) => server.value === configureData?.type)?.cpu.count}
            &nbsp;X&nbsp; */}
            {serverList.find((server:any) => server.value === configureData?.type)?.cpu.model}
            </Prop>
          </PropContainer>
          <PropContainer>
            <Prop>
            {/* {serverList.find((server:any) => server.value === configureData?.type)?.ram.count}
            &nbsp;X&nbsp; */}
            {formatQuotasRam(serverList.find((server:any) => server.value === configureData?.type)?.ram.value)}
            </Prop>
          </PropContainer>
        </div>
      ),
    },

    {
      title:t("OS"),
      value:osList.find((os:any) => os.value === configureData.os)?.label,
      icon:osList.find((os:any) => os.value === configureData.os)?.icon,
    },
    {
      title:t("TAGS"),
      value:'',
      options:configureData?.tags
    },
  ]
  const network = [

    {
      title:t("SSH_KEY"),
      value:sshKeys?.find((sshKey:any) => sshKey?.value === networkData?.ssh)?.label || '-',
    },
    // {
    //   title:t("FIREWALL"),
    //   value:groups.find((group:any) => group.value === networkData.firewall)?.label || '-',
    //   left:true,
    //   // options:[groups.find((group:any) => group.value === networkData.firewall).label]

    // },
    // {
    //   title:t("ASSIGN_EXTERNAL_IP"),
    //   value:'Ubuntu 18.04',
    // },
  ]

  const handleCreateInstance = () => {
    setLoading(true)
    const data = {
      server_id:+configureData.type,
      tags:configureData?.tags,
      name:configureData?.name,
      os:+configureData?.os,
      password:configureData?.root_password,
      ssh_keys:[networkData.ssh],
      disk_layout_id:+configureData?.disk_layout_id,
    }
    bareMetalService.createServer(data)
    .then((res) => {
      setLoadingComplete(true)
      removeItemFromLs('baremetal.configure')
      removeItemFromLs('baremetal.network')
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
        navigate('/bare-metal')
      },1500)
    })
    .catch((err:any) => {
      viewAlert({
        severity: 'error',
        message: err?.response?.data?.detail || t("ERROR_OCCURRED")
      })
      setLoading(false)
    })

  }

  if(localLoading) return <TopProgress loading={localLoading}/>
  return(
    <Container data-test-id='container-finally-baremetal' >
      <InfoBlock title={t("CONFIGURE_INSTANCE")} data={configure}/>
      <InfoBlock title={t("NETWORK_SETTINGS")} data={network}/>
      <div style={{display:'flex', marginTop:'30px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleCreateInstance}
          loading={loading}
          loadingComplete={loadingComplete}
          title={t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep("network")}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default FinallyCreate