import styled, {css} from "styled-components";
import { BodyDefault, BodyMedium } from "../../components/typography/styled";
import SVG from "react-inlinesvg";


type ActiveProp = {
  active?:boolean;
}
type DashProp = {
  type?:'default' | 'orange' | 'yellow' | 'green'
}

export const ConfirmPassordContainer = styled.div<ActiveProp>`
  display: ${({active}) => active ? '' : 'none'};
  position:absolute;
  width: 270px;
  height: 184px;
  background: ${({theme}) => theme.isDark ? theme.palette[200] : "#FFFFFF"};
  border: ${({theme}) => theme.isDark ? '' : `1px solid ${theme.grey[350]}`};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  top:-35px;
  right:-287px;
  z-index: 13;
  border-radius: 10px;
  padding: 16px 20px;
  box-sizing: border-box;
`

const styledDash = (type:string | undefined) => {
  switch(type){
    case 'default':
      return css`
        border-color:${({theme}) => theme.isDark ? theme.grey[600] : ' #DBDBDB'};
      `
    case 'orange':
      return css`
        border-color: #E1963E;
      `
    case 'yellow':
      return css`
        border-color: #FFC93E;
      `
    case 'green':
      return css`
        border-color: #229B61;
      `
    default :
      return css`
        border-color:${({theme}) => theme.isDark ? theme.grey[600] : ' #DBDBDB'};
      `
  }
}

export const PasswordDash = styled.div<DashProp>`
  
  ${({type}) => styledDash(type)}
  width: 54px;
  border-radius: 40px;
  border-width: 1px;
  border-style: solid;
  margin-right: 4px;
  margin-top: 7px;
  margin-bottom: 16px;
`
export const ConfirmPasswordTitle = styled(BodyMedium)`
  color:${({theme}) => theme.palette[900]};
  white-space: nowrap;
`

export const ConfirmPasswordItem = styled(BodyDefault)<ActiveProp>`
  color:${({theme, active}) => active ? theme.grey[400] : theme.palette[650]};
  text-decoration-line: ${({active}) => active ? 'line-through' : ''};
`
export const ConfirmPasswordItemsTitle = styled(ConfirmPasswordItem)`
  color:${({theme}) => theme.isDark ? theme.palette[800] : ''};
`
export const DotItem = styled.div`
  width: 4px;
  height:4px;
  background-color: ${({theme}) => theme.primary[600]};
  
  border-radius: 100%;
`

export const Rect = styled.div<ActiveProp>`
  width: 10px;
  height: 10px;
  top:43%;
  left:-6px;
  position: absolute;
  transform: rotate(45deg);
  z-index: 12;
  background: ${({theme}) => theme.isDark ? theme.palette[200] : '#FFFF'};
  border: ${({theme}) => theme.isDark ? `1px solid ${theme.palette[200]}` : `1px solid ${theme.grey[350]}`};
  border-right: none;
  border-top: none;
  display: ${({active}) => active ? '' : 'none'};
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
`

export const CheckedBackground = styled.div`
  background-color: ${({theme}) => theme.isDark ? theme.palette[100] : '#E0E7FC'} ;
  width: 16.67px;
  height: 16.67px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11.67px;
  border-radius: 100%;
`

export const DotWrapper = styled.div`
  display: flex;
  margin-right: 18px;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
`

