import React, { FC, useEffect, useRef, useState } from 'react'
import PrimaryButton from './primary'
import SecondaryButton from './secondary'
import StrokeButton from './stroke'
import IconButton from './icon'
import RestartIcon from './restart.svg'
import Typography from '../typography'
import StyledIcon from '../styled-icon'
import StyledButtonIcon, {Spinner, SuccessCheckmark,SpinnerBlack} from './styles'
import { useTranslation } from 'react-i18next'
type ButtonProps = {
  disabled?:boolean,
  title?: any,
  onClick: () => void,
  variant: 'primary' | 'secondary' | 'stroke' | 'icon',
  size: 'display' | 'mid' | 'small' | 'custom',
  customStyles?:any,
  icon?: any,
  children?:any,
  arrow?:boolean,
  isDelete?:boolean,
  isFill?:boolean,
  loading?:boolean,
  loadingComplete?:boolean,
  active?:boolean,
  isProps?:boolean,
  isMenu?:boolean,
  test_id?:string,
  element?:any,
}

const Button:FC<ButtonProps> = (props) => {
  const [hoverIcon, setHoverIcon] = useState<boolean>(false)
  const [width, setWidth] = useState<any>(null)
  const [t] = useTranslation()
  const buttonRef:any = useRef()
  const {
    disabled,
    title,
    onClick,
    variant,
    size,
    children,
    customStyles,
    arrow,
    icon,
    isDelete,
    isFill, 
    loading,
    loadingComplete,
    active,
    isProps,
    isMenu,
    test_id,
    element
  } = props
  // console.log('buttonRef.current.clientWidth', buttonRef?.current.clientWidth);
  switch(variant) {
    case 'primary':
      return (
        <PrimaryButton
          ref={buttonRef}
          onClick={() => {
            setWidth(buttonRef.current.offsetWidth)
            setTimeout(() => {
              onClick()
            },10)
          }}
          disabled={disabled || loadingComplete || loading} 
          size={size}
          customStyles={customStyles}
          style={loading || loadingComplete ? {width:`${width}px`, justifyContent:'center'} : {}}
          isDelete={isDelete}
          loadingComplete={loadingComplete}
          loading={loading}
        >
          {icon ?
            <StyledButtonIcon isMenu={title === t("MENU")} isFill={isFill} hover={hoverIcon} style={{marginRight:'6px'}} src={icon}/>
          : null}
          {loading ? 
            loadingComplete ? 
              <SuccessCheckmark width="24" height="24">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
              </SuccessCheckmark>
            :
            <Spinner ><div></div></Spinner>
          :
            title
          }
        </PrimaryButton>
      )
    case 'secondary':
      return (
        <SecondaryButton
          onClick={onClick}
          disabled={disabled || loadingComplete || loading} 
          size={size}
          customStyles={customStyles}
          loadingComplete={loadingComplete}
          loading={loading}
        >
          {loading ? 
            loadingComplete ? 
              <SuccessCheckmark width="20" height="20">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
              </SuccessCheckmark>
            :
            <Spinner isSecondary ><div></div></Spinner>
          :
            title
          }
        </SecondaryButton>
      )
    case 'stroke':
      return (
        <StrokeButton
          onMouseEnter={()=> setHoverIcon(true)}
          onMouseLeave={()=> setHoverIcon(false)}
          onClick={onClick}
          disabled={disabled || loading}
          active={active}
          size={size}
          customStyles={customStyles}
          isIcon={icon}
          >
          {arrow ?
          <StyledButtonIcon isFill={isFill} style={{marginRight:'6px'}} src={RestartIcon}/>
          : null}
          {loading ? <SpinnerBlack ><div></div></SpinnerBlack> : icon ?
            element ? element :
          <StyledButtonIcon isMenu={title === t("MENU")} isFill={isFill} hover={hoverIcon} style={{marginRight:'6px'}} src={icon}/>
          : null}
          {/* {loading ? 
            loadingComplete ? 
              <SuccessCheckmark width="24" height="24">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
              </SuccessCheckmark>
            :
            <SpinnerBlack ><div></div></SpinnerBlack>
          :
            title
          } */}
          {title}
        </StrokeButton>
      )
    case 'icon':
      return (
        <IconButton
          data-test-id={test_id}
          onClick={onClick}
          disabled={disabled} 
          size={size}
          active={active}
          customStyles={customStyles}
          isProps={isProps}
        >
          {children}
        </IconButton>
      )
    default:
      return (
        <PrimaryButton
          onClick={onClick}
          disabled={disabled} 
          size={size}
          customStyles={customStyles}
        >
          {title}
        </PrimaryButton>
      )
  }
}


export default Button