import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, InfoBlock, ModalVariables, Tabs, TopProgress } from "../../../components";
import {DropOptions} from "../../../components";
import { PropsContainer } from "../../../components/table/styles";
import { icons } from "../../../icons";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  TopInfoWrapper
} from './styles'
import { volumeBackupService, volumeService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { timeConverterMMDDYYHHMM } from "../../../utils/dates";
import { BodyDefault } from "../../../components/typography/styled";

const BackupPage:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const {backupId} = useParams()
  const {viewAlert} = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [backup, setBackup] = useState<any>()

  const updateBackup = () => {
    if(backupId) {
      volumeBackupService.getBackup(backupId)
      .then((res) => {
        setBackup(res.data)
        setPageLoading(false)
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: e.response.data?.detail
          })
        }
      })
    }
  }
  console.log('backup', backup);
  useEffect(() => {
    updateBackup()
    const interval = setInterval(() => {
      updateBackup()
    },5000)
    return () => {
      clearInterval(interval)
    }
  }, [])


  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }
  const items = [
    {
      title:`${t("EDIT")}`,
      id:'2',
      icon:icons.Edit,
      onClick: () => {
        openModal('edit-volume-backup')
        setAlertTitle(`${t("EDIT_BACKUP")} «${backup.name || backup.id}»`)
      },
      divider:false
    },
    {
      title:`${t("RESTORE")}`,
      id:'6',
      icon:icons.BackArrowRevert,
      onClick: () => {
        openModal('restore-volume-backup')
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        openModal('delete')
        setAlertTitle(t("BACKUP"))
        setAlertSubTitle([backup])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = async (values:any) => {
    let res:any;
    try{
      setLoading(true);
      switch(modalType){
        case 'restore-volume-backup':
          res = await volumeService.restoreVolumeBackup(backup.id, values)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break
        case 'edit-volume-backup':
          res = await volumeService.renameVolumeBackup(backup.id, {...backup, name:values.name, description:values.description})
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break
        case "delete":
          try {
            await volumeService.deleteBackup(backup.id);
            await navigate('/volume-backups')
          } catch (e:any) {
            if (e?.response?.data.detail) {
              viewAlert({
                severity: 'error',
                message: e.response.data.detail,
              });
            }
            setLoading(false);
          } finally {
            setViewModal(false);
            setActiveAnimation(false);
          }
          break;
      }
      setViewModal(false)
      setActiveAnimation(false)
    } catch (e: any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setLoading(false);
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  const specifications = [
    {
      title:t("ID"),
      id:'1',
      value:backup.id
    },
    {
      title:t("SIZE"),
      id:'2',
      value:`${backup.size} GB`
    },
    {
      title:t("CREATED_AT"),
      id:'4',
      value:timeConverterMMDDYYHHMM(backup.created_at)
    },
    {
      title:t("IS_INCREMENTAL"),
      id:'8',
      value:backup.is_incremental ? t("YES") : t("NO")
    },
    {
      title:t("OBJECT_COUNT"),
      id:'8',
      value:backup.object_count
    },
    {
      title:t("HAS_DEPENDENT_BACKUPS"),
      id:'7',
      value:backup.has_dependent_backups ? t("YES") : t("NO")
    },
    {
      title:t("VOLUME"),
      id:'6',
      value:backup.volume || '-'
    },
    {
      title:t("DESCRIPTION"),
      id:'5',
      value:backup.description
    },
  ]

  return(
    <Container>
      <BackArrow onClick={() => navigate('/volume-backups')} title={t("BACK_TO_VOLUME_BACKUPS")}/>
      <HeadWrapper>
        <div>
          <InstanceName>
            {backup.name || backup.id}
          </InstanceName>
          <BodyDefault>
            {backup?.description}
          </BodyDefault>
        </div>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              isFill
              title={t("MENU")}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          title={t("VOLUME_BACKUP_SPECIFICATIONS")}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1', marginRight:'10px'}}
        />
      </TopInfoWrapper>

      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={backup}
        restoreVloumeBackupProps={{backupId:backup?.id}}
      />
    </Container>
  )
}

export default BackupPage