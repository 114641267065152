import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";



const objStorageService = {
  getBuckets:(queries:QueriesApi) => ApiClient.get(`object-storage/storage/`),
  getBucketFiles:(id:string, path?:string) => ApiClient.get(`object-storage/storage/${id}/path/${path ? path : ''}`),
  uploadFile:(storageId:any, data:any, handleProgress:(id:any, progress:any) => void, id:any, client?:any) => ApiClient.post(`object-storage/storage/${storageId}/files/?active_client=${client}`, data,
  {
    headers: {
      'Content-Type':'multipart/form-data'
    },
    onUploadProgress: function(progressEvent:any) {
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      handleProgress(id, progress)
    }
  }),
  updateFile:(id:any, path:any, data:any) => ApiClient.put(`/object-storage/storage/${id}/files/${path}/`, data),
  getRules:(id:string) => ApiClient.get(`object-storage/storage/${id}/lifecycle/`),
  createRule:(id:string, data:any) => ApiClient.put(`object-storage/storage/${id}/lifecycle/`, data),
  createPolicy:(id:string, data:any) => ApiClient.put(`object-storage/storage/${id}/policy/`, data),
  addFolder:(id:string, data:any) => ApiClient.post(`object-storage/storage/${id}/path/`, data),
  updateBucket:(id:string, data:any) => ApiClient.put(`object-storage/storage/${id}/`,data),
  getBucketById:(id:string) => ApiClient.get(`object-storage/storage/${id}/`),
  createBucket:(data:any) => ApiClient.post(`object-storage/storage/`,data),
  deleteBucket:(id:string) => ApiClient.delete(`object-storage/storage/${id}/`),
  deleteFile:(id:string) => ApiClient.delete(`object-storage/storage/${id}/`),
  editBucket:(id:string, data:Object) => ApiClient.put(`object-storage/storage/${id}/`,data),
  getPolicy:(id:string) => ApiClient.get(`object-storage/storage/${id}/policy/`),
  getFileInfo:(storage_id:string, name:string, expire?:any) => ApiClient.get(`object-storage/storage/${storage_id}/files/${name}/?link&exp=${expire || '3200'}`),
}

export default objStorageService