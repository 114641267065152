import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch, useParams } from "react-router-dom";
import { BackArrow, Button, ModalVariables, Tabs, TopProgress } from "../../../components";
import MenuButton from "../../../components/button/menu-button";
import DropOptions from "../../../components/header/components/drop-options";
import { PropsContainer } from "../../../components/table/styles";
import { icons } from "../../../icons";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  NavWrapper,
  InstanceHeadInfo,
} from './styles'
import {
  Pools,
  Listeners,
  Overview,
} from './components'
import { BodyDefault } from "../../../components/typography/styled";
import { loadBalancerService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { handleStatusName } from "../../../utils/handle-status-name";
import { GreenDot, Greentext, StateWrapper } from "../../Instances/styles";
import CreateLoadBalancer from "../create-page";

const BalancerPage:FC = () => {
  const navigate = useNavigate()
  const {lbId} = useParams()
  const [t] = useTranslation()
  const [options, setOptions] = useState(false)
  const [loadBalancer, setLoadBalancer] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [subnets, setSubnets] = useState<any>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [modalType, setModalType] = useState<any>('')
  const {viewAlert} = useUi()
  const updateLb = () => {
    // setPageLoading(true)
    if(lbId){
      loadBalancerService.getLbById(lbId)
      .then((res) => {
        setLoadBalancer(res.data)
        setPageLoading(false)
        // setSubnets(res.data.subnets.map((sub:any) => {
        //   return {
        //     ...sub,
        //     name:sub.name,
        //     cidr:sub.cidr,
        //     ip_version:sub.ip_version,
        //     gateway_ip:sub?.gateway_ip,
        //   }
        // }))
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
        navigate('/not-found')
      })
    }
  }
  useEffect(() => {
    updateLb()
    const interval = setInterval(() => {
      updateLb()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },
    {
      title:t("LISTENERS"),
      slug:'listeners',
      id:'2'
    },
    {
      title:t("POOLS"),
      slug:'pools',
      id:'3'
    },
  ])
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(modalType === 'delete'){
      setLoading(true);
      loadBalancerService.deleteBalancer(loadBalancer.id)
      .then((res) => {
        updateLb()
        setViewModal(false)
        setActiveAnimation(false)
        navigate('/load-balancers')
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
    } else if (modalType === 'add-ip-to-balancer') {
      setLoading(true);
      loadBalancerService.associateFloatingIp(loadBalancer.id, values)
      .then((res) => {
        updateLb()
        setViewModal(false)
        setActiveAnimation(false)
        viewAlert({
          severity: 'info',
          message: `${t('FLOATING_IP_ASSOCIATED_SUCCESFUL')}`,
        });
      })
      .catch((e) => {
        viewAlert({
          severity:'error',
          message:e.response.data.detail || `${t('ERROR_OCCURRED')}`,
        })
      })
      .finally(() => {
        setLoading(false)
      })
    } else if (modalType === 'universal'){
      setLoading(true);
      if (alertTitle === `${t('DISSOCIATE_IP_TITLE')}`) {
        loadBalancerService.dissociateFloatingIp(loadBalancer.id)
        .then((res) => {
          updateLb()
          setViewModal(false)
          setActiveAnimation(false)
          viewAlert({
            severity: 'info',
            message: res.data?.detail,
          });
        })
        .catch((e) => {
          if(e?.response?.data.detail){
            viewAlert({
              severity:'error',
              message:e.response.data.detail,
            })
          }
        })
        .finally(() => {
          setLoading(false)
        })
      }
    } else {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }

  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: () => navigate(`/load-balancers/edit/${loadBalancer.id}`),
      divider:false
    },
    {
      title:loadBalancer?.floating_ip ? t("DISSOCIATE_IP") : t("ASSIGN_FLOATING_IP"),
      onClick:loadBalancer?.floating_ip ? 
        () => {
          setAlertTitle(`${t("DISSOCIATE_IP_TITLE")}`)
          setAlertSubTitle(`${t("ARE_YOU_SURE_WANT_TO_DISSOCIATE_IP")} «${loadBalancer.name}» ${t("STATE")}?`)
          openModal('universal')
        } 
      : 
        () => {
          setAlertTitle(loadBalancer.name)
          openModal('add-ip-to-balancer')
        },
      id:'5',
      icon:loadBalancer?.floating_ip ? icons.MinusDefault : icons.PlusDefault,
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:() => {
        setAlertTitle(t("LOAD_BALANCER"))
        openModal('delete')
        setAlertSubTitle([loadBalancer])
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <BackArrow onClick={() => navigate('/load-balancers')} title={t("BACK_TO_LOAD_BALANCERS")}/>
      <HeadWrapper>
        <InstanceName>
          {loadBalancer?.name || loadBalancer.id} 
        </InstanceName>
        <RightHeadContainer>
          <div ref={dropsRef}>
            <Button
              variant="stroke"
              size='small'
              icon={icons.More}
              active={options}
              title={`${t("MENU")}`}
              onClick={() => setOptions(!options)}
            />
          </div>
          <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
            <DropOptions
              options={items}
            />
          </PropsContainer>
        </RightHeadContainer>
      </HeadWrapper>
      <InstanceHeadInfo>
        <StateWrapper>
        <GreenDot active={loadBalancer.operating_status}/>
          <Greentext active={loadBalancer.operating_status}>
          {handleStatusName(loadBalancer.operating_status, t)}
          </Greentext>
        </StateWrapper>
      </InstanceHeadInfo>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview lb={loadBalancer}/>}/>
        <Route path='/listeners' element={<Listeners/>}/>
        {/* <Route path='/listeners/create' element={<CreateLoadBalancer/>}/> */}
        <Route path='/pools' element={<Pools/>}/>
        {/* <Route path='/pools/create' element={<CreateLoadBalancer/>}/> */}
      </Routes>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        // dataToEdit={instance}
        instanceId={loadBalancer.id}
        // instance={instance}
        loading={loading}
      />
    </Container>
  )
}

export default BalancerPage