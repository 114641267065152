import React, { FC, useEffect, useRef, useState } from "react";
import {
  Container,
  DarkForm,
  HideContainer,
  GreyContainer,
} from './styles'
import {
  Button,
  Input,
  Select,
  Toggle,
  Counter,
  DonutArea,
  RadioButtonGroup,
  Typography,
} from "../../../../components";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from 'use-query-params';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { InfoContainer, InfoTitle } from "../../../../components/info-block/styles";
import { icons } from "../../../../icons";
import { useInstance } from "../../../../context/instanceContext";
import { useKubernetes } from "../../../../context/kubernetesContext";
import { randomNameGenerator } from "../../../../utils/create-random-name";
import { findDuplicateObjects } from "../../../../utils/findIntersection";
import { useAuth } from "../../../../context/user.context";
import { ErrorContainer, ErrorText } from "../../../Instances/components/create-instance/configure/styles";
import ErrorIc from './ic-error.svg'


const effects = [
  {value:'NoSchedule', label:'NoSchedule'},
  {value:'PreferNoSchedule', label:'PreferNoSchedule'},
  {value:'NoExecute', label:'NoExecute'}
]

const ConfigureWorker:FC<any> = ({internetAcc}) => {
  const [t] = useTranslation()
  const {
    flavors,
  } = useInstance()
  const {user, quotas} = useAuth()
  const {setDataToLs, getDataToLs} = useLocalStorage()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [maxInstances, setMaxInstances] = useState<any>(10);
  const [totalIstances, setTotalInstances] = useState<any>(1);
  const [errTotalIstances, setErrTotalInstances] = useState<boolean>(false);
  const [pools, setPools] = useState<any[]>(getDataToLs({key:'cluster.poools'}) || [
    {
      name:randomNameGenerator({name:'nodepool', findArr:[]}),
      flavor_id:flavors.filter((flav:any) => flav.memory_gb >= 8)[0]?.value,
      count:getDataToLs({key:'cluster.type'})?.nodesCount || '1',
      autoscale:false,
      max_count:+getDataToLs({key:'cluster.type'})?.nodesCount+2 || '3',
      is_default:true,
      labels:[],
      taints:[],
    }
  ])
  const [isContinue, setIsContinue] = useState<boolean>(true)
  const [initialValues, setInitialValues] = useState<any>({
    node_pools:getDataToLs({key:'cluster.poools'}) || [],
  })
  const {
    nodePoolsData,
    setNodePoolsData,
    setClusterData,
    clusterData,
    networkingData,

  } = useKubernetes()
  useEffect(() => {
    if(!clusterData){
      setClusterData(getDataToLs({key:'cluster.configure_master'}))
    }
  },[])
  const maxCountInstances = (quotas:any, type?:any) => {
    const flavorObj = flavors.find((flav:any) => flav.id === type)
    const flavorObjCluster = flavors.find((flav:any) => flav.id === clusterData.flavor)
    if(!flavorObj){
      return quotas?.core?.instances?.limit - quotas?.core?.instances?.in_use
    }
    let maxCores = (quotas?.core?.cores?.limit - (flavorObjCluster.vcpus * clusterData.count_master) - quotas?.core?.cores?.in_use) / flavorObj?.vcpus
    let maxRam = (quotas?.core?.ram?.limit - (flavorObjCluster.memory_mb * clusterData.count_master) - quotas?.core?.ram?.in_use) / flavorObj?.memory_mb
    let maxInstances = quotas?.core?.instances?.limit - quotas?.core?.instances?.in_use
    return Math.min(Math.floor(maxCores), Math.floor(maxRam), Math.floor(maxInstances) )
  }
  const onSubmit = (values:any) => {
    if(findDuplicateObjects(pools).length > 0){
      const WithErrors:any = []
      findDuplicateObjects(pools).map((item:any) => {
        WithErrors[item.index] = {name:`${t("UNIQUE_NODE_POOL_NAME")}`}
      })
      setErrors({node_pools:WithErrors})
    } else {
      const WithErrorsCount:any = []
      
      pools.map((pool:any, index:any) => {
        if(maxCountInstances(quotas, pool.flavor_id) < (pool.autoscale ? +pool.max_count : +pool.count)){
          WithErrorsCount[index] = pool.autoscale ?
          {max_count:`${t("MAX_COUNT_MACHINES")} ${maxCountInstances(quotas, pool.flavor_id)}`}
          :
          {count:`${t("MAX_COUNT_MACHINES")} ${maxCountInstances(quotas, pool.flavor_id)}`}
        }
      })
      if(WithErrorsCount.length > 0){
        setErrors({node_pools:WithErrorsCount})
      } else {
        let max = pools.reduce((accum:any, pool:any) => {
          return accum < Number(maxCountInstances(quotas, pool.flavor_id)) ? accum : Number(maxCountInstances(quotas, pool.flavor_id)) 
        })
        let total = pools.reduce((accum:any, pool:any) => {
          return accum + Number(pool.autoscale ? +pool.max_count : +pool.count)
        }, 0)

        if(max < total) {
          setErrTotalInstances(true)
        } else {
          setStep('add-ons')
        }
      }
    }
    // 
  }


  const regName = /^[a-z0-9]+([-a-z0-9]*[a-z0-9]+)*(\.[a-z0-9]+([-a-z0-9]*[a-z0-9]+)*)*$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleChange,
    handleBlur,
    isValid,
    setErrors,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      node_pools:yup.array().of(
        yup.object().shape({
          name: yup.string()
          .matches(regName,`${t("ONLY_ENGLISH_LETTERS_SMALL")}`)
          .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`)
          .required(`${t('REQUIRED_FIELD')}`),
          flavor_id: yup.string().required(`${t('REQUIRED_FIELD')}`),
          count:yup.number()
          .required(`${t('REQUIRED_FIELD')}`)
          .positive()
          .integer()
          .min(1, `${t("COUNT_MUST_BE_GREATER_OR_EQUAL")} 1`),
          // .max(10, `${t("COUNT_MUST_BE_LESS_THAN_OR_EQUAL")} 10`),
          // .max(maxCountInstances(yup.ref('flavor_id')), `${t("MAX_COUNT_MACHINES")} ${maxCountInstances(yup.ref('flavor_id'))}`),
          max_count: yup.number().required(`${t('REQUIRED_FIELD')}`)
            .when("autoscale", {
              is: (autoscale:any, flavor_id:any) => autoscale,
              then: yup.number().required(`${t('REQUIRED_FIELD')}`)
              .positive()
              .integer()
              .min(1, `${t("COUNT_MUST_BE_GREATER_OR_EQUAL")} 1`)
              .moreThan(yup.ref('count'), `${t("COUNT_MUST_BE_GREATER")} ${t("MIN_NODE_COUNT")}`)
              // .max(maxCountInstances(yup.ref('flavor_id')), `${t("MAX_COUNT_MACHINES")} ${maxCountInstances(yup.ref('flavor_id'))}`),
            }),
          labels: yup.array().of(
            yup.object().shape({
              key: yup.string().required(`${t('REQUIRED_FIELD')}`),
              value: yup.string().required(`${t('REQUIRED_FIELD')}`),
            })
          ),
          taints: yup.array().of(
            yup.object().shape({
              effect: yup.string().required(`${t('REQUIRED_FIELD')}`),
              key: yup.string().required(`${t('REQUIRED_FIELD')}`),
              value: yup.string().required(`${t('REQUIRED_FIELD')}`),
            })
          ),
        })
      )
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  useEffect(() => {
    setDataToLs({key:'cluster.poools', data:pools})
    setNodePoolsData(pools)
    // setNodePoolsData(values)
  },[pools])
  
  const defaultDataDisk = [
    {
      part1:10,
      part2:32,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_READING"),
      value:50,
      valueMax:160
    },
    {
      part1:4,
      part2:10.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_WRITING"),
      value:20,
      valueMax:54
    }
  ]
  const defaultOperationDisk = [
    {
      part1:4.4,
      part2:22.2,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_IOPS_READING"),
      value:2000,
      valueMax:10000
    },
    {
      part1:1.5,
      part2:28.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_IOPS_WRITING"),
      value:700,
      valueMax:13000
    }
  ]
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  const handleInputChange = (index:any, field:any, value:any, data:any[], setData:(data:any[]) => void, yupField:any) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setFieldValue(yupField, updatedData);
  };
  const addNewItemNodePool = () => {
    setPools((prev:any) => {
      setFieldValue('node_pools', [...prev, 
        {
          name:randomNameGenerator({name:'nodepool', findArr:[]}),
          flavor_id:flavors.filter((flav:any) => flav.memory_gb >= 8)[0]?.value,
          count:1,
          max_count:'3',
          autoscale:false,
          is_default:false,
          labels:[],
          taints:[],
        }
      ]);
      return [...prev, 
        {
          name:randomNameGenerator({name:'nodepool', findArr:[]}),
          flavor_id:flavors.filter((flav:any) => flav.memory_gb >= 8)[0]?.value,
          count:1,
          max_count:'3',
          autoscale:false,
          is_default:false,
          labels:[],
          taints:[],
        }
      ]
    })
  };
  const buttons = [
    {
      value:'new',
      id:'1',
      title:"SSD"
    },
    {
      value:'new1',
      id:'2',
      title:"HighIOPS",
      disabled:true,
    },
  ]
  const removeItem = (index:any, data:any[], setData:(data:any[]) => void, yupField:any) => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
    setFieldValue(yupField, updatedData);
  };
  return (
    <Container>
      {pools?.map((pool, indexGlobal) => (
        <InfoContainer style={{gap:'20px', display:'flex', flexDirection:'column'}}>

            <Input
              // fieldName='name'
              toolTip={false}
              title={t("NAME_NODE_POOL")}
              placeholder=''
              prefix={clusterData?.name}
              customStyles={{
                width:'360px',
                
              }}
              {...formikProps}
              fieldName={`node_pools[${indexGlobal}].name`}
              value={pool.name}
              handleChange={(e:any) => handleInputChange(indexGlobal, 'name', e.target.value, pools, setPools, 'node_pools')}
              arrayFields={true}
              arrayName={'node_pools'}
              arrayIndex={indexGlobal}
              arrayFieldName={'name'}
            />

          

          <Select
            toolTip={false}
            data={flavors.filter((flav:any) => flav.memory_gb >= 8)}
            disabled={flavors?.length <= 0}
            selectedValue={pool.flavor_id}
            // onChange={(e) => handleChange('flavor')(e)}
            placeholder={flavors?.length <= 0 ? t("NO_FLAVORS_AVAILABLE") : t("CHOOSE")}
            title={t("TYPE_NODE_POOL")}
            customStyles={{width:'360px'}}
            {...formikProps}
            fieldName={`node_pools[${indexGlobal}].flavor_id`}
            onChange={(e:any) => handleInputChange(indexGlobal, 'flavor_id', e, pools, setPools, 'node_pools')}
          />
          <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            <RadioButtonGroup
              toolTip={false}
              title={t("DISK_TYPE")}
              buttons={buttons}
              selectedButton={values['type']  || buttons?.[0]?.value }
              onChange={(e) => {}}
            />
          </div>
          <div style={{display:'flex',flexWrap:'wrap', gap:'20px'}}>
            <DonutArea
              title={t("BANDWIDTH")}
              subtitle={t("MB_S")}
              isLeft
              data={defaultDataDisk}
            />
            <DonutArea
              title={t("OPERATIONS_PER_SEC")}
              subtitle={t("IOPS")}
              data={defaultOperationDisk}
            />
          </div>
          {/* <HideContainer active={!pool.autoscale}> */}
          <HideContainer active={!pool.autoscale}>
            <Counter
              // customStyles={{marginBottom:"20px"}}
              // toolTip={true}
              // tooltipText={`${t("NUMBER_OF_WORKER_NODES")}`}
              title={t("NODE_COUNT")}
              value={pool.count}
              minCount={1}
              hideItems={`${t("ITEMS")}`}
              {...formikProps}
              fieldName={`node_pools[${indexGlobal}].count`}
              onChange={(v:any) => {
                handleInputChange(indexGlobal, 'count', v.toString(), pools, setPools, 'node_pools')
              }}
              arrayName={'node_pools'}
              arrayIndex={indexGlobal}
              arrayFieldName={'count'}
              arrayFields={true}
            />
          </HideContainer>
          {/* <Counter
            // customStyles={{marginBottom:"20px"}}
            toolTip={true}
            tooltipText={`${t("STORAGE_SIZE_TOOLTIP")}`}
            title={t("STORAGE")}
            value={values['size']}
            minCount={1}
            hideItems={'GB'}
            onChange={(v:any) => {
              handleChange('size')(v.toString())
            }}
            fieldName='size'
            {...formikProps}
          /> */}
          <Toggle
            title={t("AUTOSCALE")}
            value={pool.autoscale}
            onChange={() => handleInputChange(indexGlobal, 'autoscale', !pool.autoscale, pools, setPools, 'node_pools')}
          />
          <HideContainer active={pool.autoscale}>
            <Counter
              // toolTip={true}
              // tooltipText={`${t("NUMBER_OF_WORKER_NODES")}`}
              title={t("MIN_NODE_COUNT")}
              value={pool.count}
              minCount={1}
              hideItems={`${t("ITEMS")}`}
              {...formikProps}
              fieldName={`node_pools[${indexGlobal}].count`}
              onChange={(v:any) => {
                handleInputChange(indexGlobal, 'count', v.toString(), pools, setPools, 'node_pools')
              }}
              arrayName={'node_pools'}
              arrayIndex={indexGlobal}
              arrayFieldName={'count'}
              arrayFields={true}
            />
            <Counter
              // toolTip={true}
              // tooltipText={`${t("NUMBER_OF_WORKER_NODES")}`}
              title={t("MAX_NODE_COUNT")}
              value={pool.max_count}
              minCount={1}
              hideItems={`${t("ITEMS")}`}
              {...formikProps}
              fieldName={`node_pools[${indexGlobal}].max_count`}
              onChange={(v:any) => {
                handleInputChange(indexGlobal, 'max_count', v.toString(), pools, setPools, 'node_pools')
              }}
              arrayName={'node_pools'}
              arrayIndex={indexGlobal}
              arrayFieldName={'max_count'}
              arrayFields={true}
            />
          </HideContainer>
          {indexGlobal >= 1 &&
            <>
              <InfoContainer customStyles={{width:'100%', alignItems:"center"}}>
                <InfoTitle>
                  {t("LABELS")}
                </InfoTitle>
                <Button
                  variant="stroke"
                  size="display"
                  icon={icons.PlusDefault}
                  customStyles={{marginTop:"10px"}}
                  //@ts-ignore
                  onClick={() => {
                    handleInputChange(indexGlobal, 'labels', [
                      ...pool.labels,
                      {
                        key: "",
                        value: "",
                      }
                    ], pools, setPools, 'node_pools')
                  }}
                  title={t("ADD_LABEL")}
                />
                {pool.labels?.map((item:any, index:any) => (
                  <DarkForm>
                    <Input
                      fieldName={`node_pools[${indexGlobal}].labels[${index}].key`}
                      required
                      toolTip={false}
                      title={t("KEY")}
                      placeholder=''
                      value={item.key}
                      customStyles={{width:'100%', flex:'auto 1'}}
                      {...formikProps}
                      handleChange={(e:any) => {
                        const updatedData = [...pool.labels];
                        updatedData[index]['key'] = e.target.value;
                        handleInputChange(indexGlobal, 'labels', updatedData, pools, setPools, 'node_pools')
                      }}
                      arrayFields={true}
                      arrayName={`node_pools[${indexGlobal}].labels`}
                      arrayIndex={index}
                      arrayFieldName={`node_pools[${indexGlobal}].labels[${index}].key`}
                      //@ts-ignore
                      manualErr={errors?.node_pools?.[indexGlobal]?.labels?.[index]?.key}
                      //@ts-ignore
                      manualTouched={touched?.node_pools?.[indexGlobal]?.labels?.[index]?.key}
                    />
                    <Input
                      fieldName={`node_pools[${indexGlobal}].labels[${index}].value`}
                      required
                      toolTip={false}
                      title={t("VALUE")}
                      placeholder=''
                      value={item.value}
                      customStyles={{width:'100%', flex:'auto 1'}}
                      {...formikProps}
                      handleChange={(e:any) => {
                        const updatedData = [...pool.labels];
                        updatedData[index]['value'] = e.target.value;
                        handleInputChange(indexGlobal, 'labels', updatedData, pools, setPools, 'node_pools')
                      }}
                      arrayFields={true}
                      arrayName={`node_pools[${indexGlobal}].labels`}
                      arrayIndex={index}
                      arrayFieldName={`node_pools[${indexGlobal}].labels[${index}].value`}
                      //@ts-ignore
                      manualErr={errors?.node_pools?.[indexGlobal]?.labels?.[index]?.value}
                      //@ts-ignore
                      manualTouched={touched?.node_pools?.[indexGlobal]?.labels?.[index]?.value}
                    />
                    <Button
                      onClick={() => {
                        const updatedData = [...pool.labels];
                        updatedData.splice(index, 1);
                        handleInputChange(indexGlobal, 'labels', updatedData, pools, setPools, 'node_pools')
                        // removeItem(index, labels, setLabels, 'labels')
                      }}
                      variant='icon'
                      size='display'
                      // disabled
                      customStyles={{marginTop:'24px'}}
                    >
                      <img src={icons.DeleteIcon}/>
                    </Button>
                  </DarkForm>
                ))}
              </InfoContainer>
              <InfoContainer>
                <InfoTitle>
                  {t("TAINTS")}
                </InfoTitle>
                <Button
                  variant="stroke"
                  size="display"
                  icon={icons.PlusDefault}
                  customStyles={{marginTop:"10px"}}
                  //@ts-ignore
                  onClick={() => {
                    handleInputChange(indexGlobal, 'taints', [
                      ...pool.taints,
                      {
                        key: "",
                        value: "",
                        effect: 'NoSchedule',
                      }
                    ], pools, setPools, 'node_pools')
                  }}
                  title={t("ADD_TAINT")}
                />
                {pool.taints?.map((item:any, index:any) => (
                  <DarkForm>
                    <Input
                      fieldName={`node_pools[${indexGlobal}].taints[${index}].key`}
                      required
                      toolTip={false}
                      title={t("KEY")}
                      placeholder=''
                      value={item.key}
                      customStyles={{width:'241px', flex:'auto 1'}}
                      {...formikProps}
                      handleChange={(e:any) => {
                        const updatedData = [...pool.taints];
                        updatedData[index]['key'] = e.target.value;
                        handleInputChange(indexGlobal, 'taints', updatedData, pools, setPools, 'node_pools')
                        // handleInputChange(index, 'key', e.target.value, pool.taints, setTaints, 'taints')
                      }}
                      arrayFields={true}
                      arrayName={`node_pools[${indexGlobal}].taints`}
                      arrayIndex={index}
                      arrayFieldName={`node_pools[${indexGlobal}].taints[${index}].key`}
                      //@ts-ignore
                      manualErr={errors?.node_pools?.[indexGlobal]?.taints?.[index]?.key}
                      //@ts-ignore
                      manualTouched={touched?.node_pools?.[indexGlobal]?.taints?.[index]?.key}
                    />
                    <Input
                      fieldName={`node_pools[${indexGlobal}].taints[${index}].value`}
                      required
                      toolTip={false}
                      title={t("VALUE")}
                      placeholder=''
                      value={item.value}
                      customStyles={{width:'241px', flex:'auto 1'}}
                      {...formikProps}
                      handleChange={(e:any) => {
                        const updatedData = [...pool.taints];
                        updatedData[index]['value'] = e.target.value;
                        handleInputChange(indexGlobal, 'taints', updatedData, pools, setPools, 'node_pools')
                      }}
                      arrayFields={true}
                      arrayName={`node_pools[${indexGlobal}].taints`}
                      arrayIndex={index}
                      arrayFieldName={`node_pools[${indexGlobal}].taints[${index}].value`}
                      //@ts-ignore
                      manualErr={errors?.node_pools?.[indexGlobal]?.taints?.[index]?.value}
                      //@ts-ignore
                      manualTouched={touched?.node_pools?.[indexGlobal]?.taints?.[index]?.value}
                    />
                    <Select
                      toolTip={false}
                      required
                      data={effects}
                      selectedValue={item.effect || 'NoSchedule'}
                      // onChange={() => {}}
                      title={t("EFFECT")}
                      customStyles={{width:'241px', maxWidth:'360px', flex:'auto 1'}}
                      fieldName={`node_pools[${indexGlobal}].taints[${index}].effect`}
                      onChange={(e:any) => {
                        const updatedData = [...pool.taints];
                        updatedData[index]['effect'] = e;
                        handleInputChange(indexGlobal, 'taints', updatedData, pools, setPools, 'node_pools')
                      }}
                      // arrayFields={true}
                      // arrayName={`node_pools[${indexGlobal}].taints`}
                      // arrayIndex={index}
                      // arrayFieldName={`node_pools[${indexGlobal}].taints[${index}].value`}
                    />
                    <Button
                      onClick={() => {
                        const updatedData = [...pool.taints];
                        updatedData.splice(index, 1);
                        handleInputChange(indexGlobal, 'taints', updatedData, pools, setPools, 'node_pools')
                        // removeItem(index, labels, setLabels, 'labels')
                      }}
                      variant='icon'
                      size='display'
                      // disabled
                      customStyles={{marginTop:'24px'}}
                    >
                      <img src={icons.DeleteIcon}/>
                    </Button>
                  </DarkForm>
                ))}

              </InfoContainer>
            </>
          }
          {indexGlobal !== 0 &&
            <div style={{
              display:'flex',
              justifyContent: 'flex-start',
            }}>
              <Button
                variant="stroke"
                size="display"
                icon={icons.DeleteIcon}
                //@ts-ignore
                onClick={() => {
                  setPools(() => pools.filter((_, ind) => ind !== indexGlobal))
                  removeItem(indexGlobal, pools, setPools, 'node_pools')
                }}
                title={t("DELETE_NODE_POOL")}
              />
            </div>
          }
        </InfoContainer>
      ))}
      <Button
        variant="stroke"
        size="display"
        icon={icons.PlusDefault}
        //@ts-ignore
        onClick={addNewItemNodePool}
        title={t("ADD_NODE_POOL")}
      />
      {/* @ts-ignore */}
      {errTotalIstances || (errors && errors?.node_pools?.filter((pool:any) => pool?.max_count || pool?.count).length > 0) ?
        <ErrorContainer isError={true}>
          <img src={ErrorIc}/>
          <ErrorText>{t("ERROR_POOLS_CREATE_QUOTAS")}</ErrorText>
        </ErrorContainer>
      : null}
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={() => {
            setIsContinue(true)
            handleSubmit()
          }}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep('networking')}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default ConfigureWorker
