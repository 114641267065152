export default {
  colors:{
    10:'#FFFF',
    20:'#E8E8E8',
    30:'#E0EAFF',


    25: '#F9FAFE',
    40: '#F1F4F9',
    50: '#E8EEF9',
    100: '#FCFBFC',
    200: '#F7F7F7',
    300: '#F3F1F1',
    350: '#E8E8E8',
    400: '#DBDBDB',
    600: '#6C6C6C',
    650: '#6C6C6C',
    700: '#858A94',
    800: '#474B53',
    850: '#474B53',
    900: '#1E1E1E',
  }
}