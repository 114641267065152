import React, { FC, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { instanceService, portService } from '../../../../../api'
import { Button, ModalVariables, Table, TopProgress } from '../../../../../components'
import { InfoContainer } from '../../../../../components/info-block/styles'
import { useUi } from '../../../../../context/ui.context'
import { icons } from '../../../../../icons'
import { InstanceType } from '../../../../../types/instance'
import {
  Container,
  Title,
  PortContainer,
  ButtonsWrapper,
  StyledPortItem,
  PortTitle,
  PortSubtitle,
  BodyText,
} from './styles'
import {
  NetworkContainer,
  NetworkText,
  NetworkInfoContainer,
  IpGroupsWrapper,
} from '../overview/styles'
import { findIntersection } from '../../../../../utils/findIntersection'
import DropOptions from "../../../../../components/header/components/drop-options";
import { PropsContainer } from "../../../../../components/table/styles";
import PortIpItem from './portItem'
import { useParams } from 'react-router-dom'
import { BodyDefault } from '../../../../../components/typography/styled'
type NetworkingProps = {
  instance:InstanceType,
  updateDataInstance:() => void,
}

const Networking:FC<NetworkingProps> = ({instance, updateDataInstance}) => {
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [ports, setPorts] = useState<any[]>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [assignLoading, setAssignLoading] = useState(false)
  const {instanceId} = useParams()
  const _getNetworkData = async () => {
    // setPageLoading(true)
    try{
      let promises:any[] = []
      await instance.net_details.ports.map((port:any) => {
        let promise = instanceService.getPortById(port.id)
        promises.push(promise)
      })
      Promise.allSettled(promises)
      .then((res:any) => {
        setPorts(res.filter((it:any) => it.status === "fulfilled").map((r:any) => {
          return {
            ...r?.value.data,
            admin_state_up: r?.value?.data?.admin_state_up ? 'Yes' : 'No',
            // fixed_ips:r.data.fixed_ips.map((ip:any) => ip.ip_address)
          }
        }))
        setPageLoading(false)
      })
      .catch((e:any) => {
        console.log('error', e);
        // if(e?.response?.data.detail){
        //   viewAlert({
        //     severity:'error',
        //     message:e.response.data.detail,
        //   })
        // }
        setPageLoading(false)
      })
    } catch (e:any){
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
    }
  }
  // useEffect(() => {
  //   _getNetworkData()
  //   const interval = setInterval(() => {
  //     _getNetworkData()
  //   },5000)
  //   return () => {
  //     clearInterval(interval)
  //   }
  // },[])
  useEffect(() => {
    _getNetworkData()
  },[instance.net_details])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any, setErrorsModal?:any) => {
    if(modalType === 'assign-ip'){
      setAssignLoading(true)
      instanceService.addFloatingIp(instance.id, values)
      .then((res) => {
        updateDataInstance()
        _getNetworkData()
        setAssignLoading(false)
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e:any) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
        setAssignLoading(false)
        setViewModal(false)
        setActiveAnimation(false)
      })
    }
    if(modalType === 'add-port-to-instance'){
      setAssignLoading(true)
      portService.createPort(values)
      .then((res) => {
        updateDataInstance()
        _getNetworkData()
        setAssignLoading(false)
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e:any) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
        setAssignLoading(false)
        setViewModal(false)
        setActiveAnimation(false)
      })
    }
    if(modalType === 'attach-port' && instanceId){
      setAssignLoading(true)
      const data = {
        port_id:values.port
      }
      instanceService.attachPortToInstance(instanceId, data)
      .then((res) => {
        viewAlert({
          severity:'info',
          message:'Port attached successfully',
        })
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        updateDataInstance()
        _getNetworkData()
        setAssignLoading(false)
        setViewModal(false)
        setActiveAnimation(false)
      })
    }
    if(modalType === 'add-ip-to-instance-auto') {
      setAssignLoading(true)
      portService.autoAddIp(values.port, {
        region:values.region,
        fixed_ips:values.fixed_ips,
      })
      .then((res) => {
        viewAlert({
          severity:'info',
          message:'Ip added successfully',
        })
        updateDataInstance()
        _getNetworkData()
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
      })
      .finally(() => {
        setAssignLoading(false)
        setViewModal(false)
        setActiveAnimation(false)
      })
    }
    if(modalType === 'add-ip-to-instance'){
      setAssignLoading(true)
      portService.autoAddIp(values.port, values)
      .then((res) => {
        viewAlert({
          severity:'info',
          message:'Ip added successfully',
        })
        updateDataInstance()
        _getNetworkData()
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
          setViewModal(false)
          setActiveAnimation(false)
        } else if (e?.response?.data?.fixed_ips) {
          setErrorsModal(e?.response?.data?.fixed_ips)
        }
      })
      .finally(() => {
        setAssignLoading(false)
      })
    }
  }

  
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <Title>
        {t("PORTS")}
      </Title>
      <ButtonsWrapper>
        <div>
          <Button
            variant='primary'
            size='display'
            customStyles={{marginRight:'6px'}}
            onClick={() => openModal('add-port-to-instance')}
            title={t("ADD_PORT")}
          />
          <Button
            variant='stroke'
            size='display'
            customStyles={{marginRight:'6px'}}
            onClick={() => openModal('attach-port')}
            title={t("ATTACH_PORT")}
          />
          {ports.length === 0 || !ports ? null :
            <>
              <Button
                variant='stroke'
                size='display'
                customStyles={{marginRight:'6px'}}
                onClick={() => openModal('add-ip-to-instance')}
                title={t("ADD_IP")}
              />
              <Button
                variant='stroke'
                size='display'
                customStyles={{marginRight:'6px'}}
                onClick={() => openModal('add-ip-to-instance-auto')}
                title={t("ADD_IP_AUTOMATICALLY")}
              />
              <Button
                variant='stroke'
                size='display'
                customStyles={{marginRight:'6px'}}
                onClick={() => openModal('assign-ip')}
                title={t("ASSIGN_FLOATING_IP")}
              />
            </>
          }
        </div>
      </ButtonsWrapper>
      {/* {ports.length > 0 && instance.net_details.grouped_ips.map((groupIp:any) => {
        return (
        <PortItem
          updateData={() => {
            updateDataInstance()
            _getNetworkData()
          }}
          instance={instance}
          ip={groupIp}
          port={ports.find(port =>
            port.fixed_ips.some((ip:any) => groupIp.ipv4s.some((ipv4:any) => ip.subnet_id === ipv4.subnet_id))
          )}
        />
      )})} */}
      {ports.length > 0 ? instance.net_details.ports.map((port:any) => {
        return (
        <PortItem
          updateData={() => {
            updateDataInstance()
            _getNetworkData()
          }}
          instance={instance}
          ip={{
            ipv4s:port.ipv4s,
            ipv6s:port.ipv6s,
          }}
          port={ports.find((p:any) => p.id === port.id)}
        />
      )})
      :
        <BodyDefault>{t("YOU_HAVE_NO_PORTS")}</BodyDefault>
      }
      {/* {ports.length === 0 || !ports && <BodyText></BodyText>} */}
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={instance.name}
        instanceId={instance.id}
        loading={assignLoading}
        dataToEdit={instance}
      />
    </Container>
  )
}

export default Networking


type PortItemProps = {
  instance: InstanceType,
  port?:any,
  ip:any,
  updateData?:any,
}

const PortItem:FC<PortItemProps> = (props) => {
  const {
    instance,
    port,
    ip,
    updateData
  } = props
  const {instanceId} = useParams() 
  const {t} = useTranslation()
  const {viewAlert} = useUi()
  const [options, setOptions] = useState(false)
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [deletedPort, setDeletedPort] = useState<any>()
  const [deletedIp, setDeletedIp] = useState<any>()
  const [editedPort, setEditedPort] = useState<any>()
  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }
  const portProps = [
    {
      title:t("DETACH_PORT"),
      id:'4',
      icon:icons.MinusDefault,
      onClick:() => {
        openModal('detach-port')
        setAlertTitle(port.id)
      },
      divider:false
    },
    {
      title:t("DELETE_PORT"),
      onClick:() => {
        openModal('delete')
        setAlertTitle('Port')
        setAlertSubTitle([port])
        setDeletedPort(port)
      },
      id:'6',
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = (props:any) => {
    if(modalType === 'universal'){
      if (alertTitle === `${t('DISSOCIATE_FLOATING_IP')} ${editedPort}?`) {
        const data = {
          ip:editedPort
        }
        setLoading(true)
        instanceService.dissociateFloatingIp(instance.id, data)
        .then((res) => {
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          updateData()
          setViewModal(false)
          setActiveAnimation(false)
        })
        .catch((err)=> {
          if(err?.response?.data?.detail){
            viewAlert({
              severity:'error',
              message:err.response.data.detail,
            })
          }
          setViewModal(false)
          setActiveAnimation(false)
        })
        .finally(() => {
          setLoading(false)
        })
      }
    }
    if(modalType === 'delete'){
      if(alertTitle === 'Port'){
        setLoading(true)
        portService.deletePort(deletedPort?.id)
        .then((res) => {
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          updateData()
          setViewModal(false)
          setActiveAnimation(false)
        })
        .catch((err)=> {
          if(err?.response?.data?.detail){
            viewAlert({
              severity:'error',
              message:err.response.data.detail,
            })
          }
          setViewModal(false)
          setActiveAnimation(false)
        })
        .finally(() => {
          setLoading(false)
        })
      }
      if(alertTitle === 'IP'){
        const data = {
          region:port.region,
          fixed_ips:[deletedIp]
        }
        setLoading(true)
        instanceService.removeIp(port.id, data)
        .then((res) => {
          viewAlert({
            severity:'info',
            message:`${t("IP_DELETED_SUCCESSFULLY")}`,
          })
          updateData()
          setViewModal(false)
          setActiveAnimation(false)
        })
        .catch((err)=> {
          if(err?.response?.data?.detail){
            viewAlert({
              severity:'error',
              message:err.response.data.detail,
            })
          }
          setViewModal(false)
          setActiveAnimation(false)
        })
        .finally(() => {
          setLoading(false)
        })
      }
    }
    if(modalType === 'detach-port' && instanceId) {
      setLoading(true)
      const data = {
        port_id:port?.id
      }
      instanceService.detachPort(instanceId, data)
      .then((res) => {
        viewAlert({
          severity:'info',
          message:`${t("PORT_DETACHED_SUCCESSFULLY")}`,
        })
        updateData()
        setViewModal(false)
        setActiveAnimation(false)
      })
      .catch((e:any) => {
        if(e?.response?.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
        setViewModal(false)
        setActiveAnimation(false)
      })
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }

const Columns = [
  {
    title:t("ID_PORT"),
    key:'id'
  },
  {
    title:t("MAC_ADDRESS"),
    key:'mac_address'
  },
  {
    title:t("DEVICE_OWNER"),
    key:'device_owner'
  },
  {
    title:t("ADMIN_STATE_UP"),
    key:'admin_state_up'
  },
  {
    title:t("PLACEMENT_REGION"),
    key:'region'
  },
  {
    title:"",
    key:'props'
  },
]
  return (
    <NetworkContainer style={{marginBottom:'10px'}}>
      <PortContainer>
        {Columns.map((column:any) => (
          column.key === 'props' ?
            <div style={{position:'relative'}}>
              <div ref={dropsRef}>
                <Button
                  variant="icon"
                  size="small"
                  icon={icons.More}
                  isFill
                  // customStyles={{padding:'0px 5px', height:'30px'}}
                  onClick={() => setOptions(!options)}
                ><img src={icons.More}/></Button>
              </div>
              <PropsContainer style={{top:'35px', right:'0px'}} ref={optionsRef} active={options}>
                <DropOptions
                  options={portProps}
                />
              </PropsContainer>
            </div>
        :
          <StyledPortItem key={column.key}>
            <PortTitle>{column.title}</PortTitle>
            <PortSubtitle>{port?.[column.key]}</PortSubtitle>
          </StyledPortItem>
        ))}
      </PortContainer>
      <IpGroupsWrapper>
        {ip?.ipv4s?.length > 0 ?
          <PortIpItem
            arrayPort={ip.ipv4s}
            port={port}
            title={'IPv4:'}
            fieldPort={'ip_address'}
            isBlack
            onClick={(port:any) => {
              openModal('delete')
              setAlertTitle('IP')
              setAlertSubTitle([port])
              setDeletedIp(port)
            }}
          />
        : null}
        {ip.ipv6s?.length > 0 ? 
          <PortIpItem
            arrayPort={ip.ipv6s}
            port={port}
            title={'IPv6:'}
            fieldPort={'ip_address'}
            isBlack
            onClick={(port:any) => {
              openModal('delete')
              setAlertTitle('IP')
              setAlertSubTitle([port])
              setDeletedIp(port)
            }}
          />
        : null}
        {/* Find and view floting Ip for connected network */}
        {instance.net_details.ports.map((port:any) => {
          return (
            port.floating_ips.length > 0 && 
            port.ipv4s.find((f:any) => f.ip_address === findIntersection(port.ipv4s, ip.ipv4s, 'ip_address')?.[0]?.ip_address) ?
              <PortIpItem
                arrayPort={port?.floating_ips}
                port={port}
                title={'Floating IPs:'}
                fieldPort={'floating_ip_address'}
                isBlack
                onClick={(port:any) => {
                  setAlertTitle(`${t("DISSOCIATE_FLOATING_IP")} ${port['floating_ip_address']}?`)
                  setAlertSubTitle(`${t("ARE_YOU_SHURE")}`)
                  openModal('universal')
                  setEditedPort(port['floating_ip_address'])
                }}
              />
            : null
          )
        })}
      </IpGroupsWrapper>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        loading={loadingCreate || loading}
        alertSubTitle={alertSubTitle}
        // dataToEdit={editedPort}
      />
    </NetworkContainer>
  )
}
