import styled from "styled-components";
import { BodyDefault, BodySmall } from "../../../../components/typography/styled";


export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 30px 50px 20px;
  box-sizing: border-box;
  position: relative;
  gap:10px;
  align-items: flex-start;
`

export const BodyTicket = styled.div`
  display:flex;
  flex-direction: column;
  width:100%;
  align-items: flex-start;
  gap:20px;
`

export const DepartmentTitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[700]};
  margin-right:20px;
`
export const GreyText = styled(BodySmall)`
  color:${({theme}) => theme.palette[600]};
`