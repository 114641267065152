import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";

const securityService = {
  getSecurityList:(queries:QueriesApi) => ApiClient.get(`openstack/securitygroups?page_size=${queries.pageSize}&ordering=${queries.ordering}&page=${queries.page}`),
  deleteGroup:(id:string) => ApiClient.delete(`openstack/securitygroups/${id}`),
  editGroup:(id:string, data:any) => ApiClient.put(`openstack/securitygroups/${id}`, data),
  createGroup:(data:any) => ApiClient.post('openstack/securitygroups', data),
  getCreateOptions:() => ApiClient.get('openstack/securitygroups/create_options'),
  getGroupById:(id:string) => ApiClient.get(`openstack/securitygroups/${id}`),
  getGroupsForRule:() => ApiClient.get(`openstack/securitygroups?page_size=40`),
  createRule:(id:string, data:any) => ApiClient.post(`openstack/securitygroups/${id}/add_rule`, data),
  deleteRule:(id:string, data:any) => ApiClient.post(`openstack/securitygroups/${id}/delete_rule`, data),
}

export default securityService