import styled from 'styled-components'
import StyledIcon from '../styled-icon';
import { BodySmall } from '../typography/styled'
import SVG from "react-inlinesvg";
type ActiveProp = {
  active?:boolean,
  disable?:boolean,
  hover?:boolean,
}

export const Container = styled.div<ActiveProp>`
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  width: ${({active}) => active ? '70px' : '280px'};
  transition: 0.2s width ease-in-out;
  /* position: absolute; */
  min-height: 100vh;
  padding: 30px 20px;
  z-index: 10;
  background-color: ${({theme}) => theme.palette[100]};
`
export const ArrowCloseImg = styled(SVG)<ActiveProp>`
  transform: ${({active}) => active ? 'scale(-1, 1)' : ''};
  transition: 0.2s transform ease-in-out;
  z-index: 9;
  opacity: ${({hover, disable}) => hover || disable ? '0.7' : ''};
  filter: ${({active, disable}) => active && !disable ? 'brightness(77%)' : ''};
  &:hover {
    cursor: pointer;
    opacity: ${({hover}) => hover ? '' : '0.7'};
  }
  &:active{
    filter: ${({disable}) => !disable ? 'brightness(77%)' : ''};
  }
  & path {
    stroke:${({theme}) => theme.palette[800]};
  }
  max-width:14px;
`
export const LogoImg = styled(SVG)<ActiveProp>`
  /* height: ${({active}) => active ? '26px' :'38px'};
  top: ${({active}) => active ? '27.5px' :'21px'}; */
  left:${({active}) => active ? '14.5px' :'15px'};
  top:26px;
  margin-bottom: 40px;
  margin-left: 8px;
  z-index: -1;
  position: absolute;
  &:hover{
    cursor:pointer;
    opacity: 0.7;
  }
  & path {
    /* fill:${({theme}) => theme.palette[800]}; */
  }
`
export const TitleDivider = styled.h2<ActiveProp>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding:0px;
  margin-top: 30px;
  margin-bottom: 8px;
  margin-left: ${({active}) => active ? '14px' : ''};
  color: ${({theme, active}) => active ? theme.palette[700] : theme.palette[600]};
  text-transform: uppercase;
  white-space: nowrap;
`
export const ArrowCloseContainer = styled.div<ActiveProp>`
  display: flex;
  box-sizing: border-box;
  padding:7px;
  width:30px;
  top:25px;
  z-index: 9;
  height:30px;
  border-radius: 100%;
  background-color: ${({theme}) => theme.isDark ? theme.palette[600] : theme.base.white};
  border:${({theme}) => `1px solid ${theme.grey[300]}`};
  position:absolute;
  left:${({active}) => active ? '55px' : '265px'};
  transition: 0.2s left ease-in-out;
  filter: ${({theme}) => {
    return theme.isDark ? 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1))'
    : null}};
  &:hover{
    cursor:pointer;
    background-color:${({theme}) => theme.palette[100]}
  }
`