import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,

} from './styles'
import { InfoBlock } from '../../../../../components'
import { timeConverterMMDDYYHHMM } from '../../../../../utils/dates'

type OverviewProps = {
  volume:any
}

const Overview:FC<OverviewProps> = ({volume}) => {
  const [t] = useTranslation()
  const specifications = [
    {
      title:t("ID"),
      id:'1',
      value:volume.id
    },
    {
      title:t("SIZE"),
      id:'2',
      value:`${volume.size} GB`
    },
    {
      title:t("TYPE"),
      id:'3',
      value:volume.type
    },
    {
      title:t("CREATED_AT"),
      id:'4',
      value:timeConverterMMDDYYHHMM(volume.created_at)
    },
    {
      title:t("BOOTABLE"),
      id:'5',
      value:volume.bootable ? "True" :'False'
    },
  ]
  return(
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          title={t("VOLUME_SPECIFICATIONS")}
          customStyles={{marginTop:'10px', width:"545px", flex:'auto 1', marginRight:'10px'}}
        />
      </TopInfoWrapper>
    </Container>
  )
}
export default Overview