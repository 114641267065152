import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../../icons";
import Tabs from "../../tabs";
import Typography from "../../typography";
import {
  Container,
  Header,
  Body,
  ButtonWrapper,
  HeadContent,
  TitleWrapper,
  HeadTitle,
  HeadText,
  CloseWrapper,
  CloseIconWhite
} from './styles'
import UserIcon from './user.svg'
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import Button from "../../button";
import { authService, clientService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { useAuth } from "../../../context/user.context";
import { useLocalStorage } from "../../../hooks/useLocalStorage";


type ModalProps = {
  closeModalCancel:() => void;
  closeModal:() => void;
}  

const CompleteModal:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
  } = props
  const [t] = useTranslation()
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t('CONFIRM_YOUR_EMAIL'),
      slug:'email',
      id:'1'
    },
    {
      title:t('PHONE_NUMBER'),
      slug:'phone',
      id:'2'
    },
  ])
  const [slug, setSlug] = useState('email')

  return (
    <Container>
      <Header>
        <CloseWrapper>
          <CloseIconWhite onClick={closeModalCancel} src={icons.CloseIcon}/>
        </CloseWrapper>
        <HeadContent>
          <img style={{width:'80px', height:'80px'}} src={UserIcon}/>
          <TitleWrapper>
            <HeadTitle>
              {t("COMPLETION_EMAIL_ADDRESS")}
            </HeadTitle>
            <HeadText>
              {t("ALL_YOU_NEED_TO_DO_STARTED_WITH_ONECLOUDPLANET")}
            </HeadText>
          </TitleWrapper>
        </HeadContent>
      </Header>
      <Body>
        {/* <Tabs
          tabs={tabs}
          size='display'
          checked
          isSteps
          location={slug}
          greyBack
        /> */}
          <EmailForm setSlug={setSlug} closeModal={closeModal} closeModalCancel={closeModalCancel}/>

      </Body>
    </Container>
  );
}
 
export default CompleteModal;



const EmailForm:FC<any> = (props) => {
  const {user} = useAuth()
  const {
    closeModalCancel,
    closeModal
  } = props
  const {viewAlert} = useUi()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [t] = useTranslation()
  const [initialValues, setInitialValues ] = useState<any>({
    code:'',
  })
  const {setUser} = useAuth()
  const {setDataToLs} = useLocalStorage()
  const onSubmit = (values:any) => {
    setLoading(true)
    setTimeout(() => {
      clientService.confirmEmail(values.code)
      .then((res) => {
        setLoadingComplete(true)
        setTimeout(() => {
          setLoadingComplete(false)
          setLoading(false)
          closeModal()
          authService.getUser()
            .then((res) => {
              const data = {
                email:res.data.user.email,
                first_name:res.data.user.first_name,
                last_name:res.data.user.last_name,
              }
              authService.updateClient(data)
              .then((res) => {
                // localStorage.setItem('lang', selected.value)
                // window.location.reload()
              })
              .catch((err:any) => {
                console.log('err changelang', err);
              })
              if(res.data.user){
                setUser(res.data)
                setDataToLs({key:'userToken', data:res.data.user.fleio_token.token})
              }
            })
            .catch((e) => {
              if(e?.response?.data?.detail){
                viewAlert({
                  severity:'error',
                  message:e?.response?.data?.detail,
                })
              }
            })
        },1000)
      })
      .catch((e) => {
        if(e.response.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        }
        setLoading(false)
      })
    },100)
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      code: yup.string().required(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const resendEvent = () => {
    authService.resendEmailCode()
    .then((res) => {
      if(res.data.detail){
        viewAlert({
          severity:'info',
          message:`${t("ON_EMAIL_SENDED_TOKEN_PART1")}${user?.user?.email} ${t("ON_EMAIL_SENDED_TOKEN_PART2")}`

          // message:res.data.detail,
        })
      }

    })
    .catch((e) => {
      if(e.response.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
    })
  }
  return (
    <>
    {/* <div style={{width: '360px'}}> */}
      <Typography variant='body'>
        {t("CHECK_EMAIL_CONFIRM_TEXT")}
      </Typography>
      {/* </div> */}
      <Input
        required
        fieldName='code'
        title={t("CONFIRMATION_CODE")}
        customStyles={{width: '400px'}}
        toolTip={false}
        placeholder=''
        {...formikProps}
      />
      <ButtonWrapper>
        <Button
          variant="stroke"
          size="display"
          customStyles={{flex:'1', justifyContent:'center'}}
          onClick={closeModalCancel}
          title={t("CANCEL")}
        />
        <Button
          variant="stroke"
          size="display"
          arrow
          customStyles={{flex:'1', justifyContent:'center'}}
          onClick={resendEvent}
          title={t("RESEND_CODE")}
        />
        <Button
          variant="primary"
          size="display"
          customStyles={{flex:'1', justifyContent:'center'}}
          onClick={handleSubmit}
          loading={loading}
          loadingComplete={loadingComplete}
          title={t("CONFIRM")}
        />
      </ButtonWrapper>
    </>
  )
}

// const PhoneForm:FC<any> = (props) => {

//   const {
//     closeModalCancel,
//     setSlug
//   } = props
//   const [t] = useTranslation()
//   const [initialValues, setInitialValues ] = useState<any>({
//     phone:'',
//   }) 
//   const onSubmit = () => {
//     closeModalCancel()
//   }
//   const {
//     values,
//     errors,
//     touched,
//     handleSubmit,
//     handleChange,
//     handleBlur,
//   } = useFormik({
//     enableReinitialize: true,
//     validationSchema: yup.object().shape({
//       phone: yup.string().required(),
//     }),
//     initialValues: {
//       ...initialValues,
//     },
//     onSubmit,
//   });
  
//   const formikProps = {
//     errors,
//     values,
//     touched,
//     handleChange,
//     handleBlur,
//   };
//   return (
//     <>
//       <Typography variant='body'>
//         {t("PHONE_CONFIRM_TEXT")}
//       </Typography>
//       <Input
//         fieldName='phone'
//         title={t("PHONE_NUMBER")}
//         customStyles={{width: '360px'}}
//         disableAutocomplete
//         toolTip
//         placeholder=''
//         {...formikProps}
//       />
//       <ButtonWrapper>
//         <Button
//           variant="stroke"
//           size="display"
//           customStyles={{flex:'1', justifyContent:'center'}}
//           onClick={() => setSlug('email')}
//           title={t("CANCEL")}
//         />
//         <Button
//           variant="primary"
//           size="display"
//           customStyles={{flex:'1', justifyContent:'center'}}
//           onClick={handleSubmit}
//           title={t("CONFIRM")}
//         />
//       </ButtonWrapper>
//     </>
//   )
// }