import styled from 'styled-components'
import { BodyDefault, BodySmallMedium, HeadLine2 } from '../../../../../components/typography/styled'
import { BodyDay } from '../../create-instance/backup/styles'

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top:20px;
  flex-direction: column;
`

export const Title = styled(BodySmallMedium)`
  color:${({theme}) => theme.palette[850]};
  /* margin-bottom:6px; */
`

export const ButtonsWrapper = styled.div`
  display:flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: flex-end;
`
export const LogContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px 20px 20px;
  box-sizing: border-box;
  max-width: 1100px;
  height: 645px;
  background: ${({theme}) => theme.palette[100]};
  border-radius: 10px;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 2px 2px ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[400]};
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  ::-webkit-scrollbar-button{
    display: none;
  }
`

export const LogText = styled(BodyDefault)`
  color:${({theme}) => theme.palette[900]};
`