import React, { FC, useState } from "react";
import Dialog from "../../dialog";
import {
  IconBackground,
  ModalWrapper,
  HeadWrapper,
  BodyModal,
  GreyText,
  Icon,
  WarningBlock,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import AuthImage from './google-authenticator.svg'
import CheckBox from "../../checkbox";
import { BodySmall } from "../../typography/styled";
import Warning from '../../alert/warning.svg'
import { authService } from "../../../api";
import { useUi } from "../../../context/ui.context";

type ModalProps = {
  closeModalCancel:() => void;
  closeModal:() => void;
  loading?:boolean;
  loadingComplete?:boolean;
}  


const EnableGoogleAuth:FC<ModalProps> = (props) => {

  const {
    closeModalCancel,
    closeModal,
    loading,
    loadingComplete,
  } = props
  const {viewAlert} = useUi()

  const [t] = useTranslation()
  return(
    <ModalWrapper>
      <HeadWrapper>
        <Icon 
          onClick={closeModalCancel}
          src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <IconBackground>
          <img style={{width:'50px', height:'50px'}} src={AuthImage}/>
        </IconBackground>
        <Typography variant='h3'>
          {t("ENABLE_2FA_AUTH")}
        </Typography>
        <GreyText>
          {t("ARE_YOU_SURE")}
        </GreyText>
        <div style={{width:'100%', display:'flex', alignItems:'flex-start'}}>
          <WarningBlock>
            <img src={Warning}/>
            <BodySmall style={{color:"#1E1E1E"}}>
              {t("ENABLE_2FA_AUTH_INFO_WARNING")}
            </BodySmall>
          </WarningBlock>
        </div>
        <div style={{display:"flex", gap:'6px', marginTop:'30px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '157px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("NO")}
          />
          <Button
            variant="primary"
            size="display"
            customStyles={{ width: '157px', justifyContent:'center' }}
            onClick={closeModal}
            title={t("YES")}
            loading={loading}
            loadingComplete={loadingComplete}
          />
        </div>
      </BodyModal>
    </ModalWrapper>
  )
}

export default EnableGoogleAuth