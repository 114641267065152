import React, { FC, useEffect, useState } from 'react';
import {
  Container,
  ErrorText,
} from './styles'
import { Editor } from '@tinymce/tinymce-react';
import config from '../../config';
import { useUi } from '../../context/ui.context';

type EditorProps = {
  onChange:(value:any) => void,
  fieldName:any,
  values:any,
  errors:any,
  touched?:any,
  handleChange?:any,
  handleBlur?:any,
}


const TextEditor:FC<EditorProps> = (props) => {
  const {
    onChange,
    fieldName,
    values,
    errors,
  } = props;
  const [onChangeTheme, setOnChangeTheme] = useState<boolean>(true)
  const {
    themeDark,
    // setThemeDark,
  } = useUi()
  const fieldValue = values?.[fieldName];
  const fieldError = errors?.[fieldName];
  useEffect(() => {
    setOnChangeTheme(false)
    setTimeout(() => {
      setOnChangeTheme(true)
    },10)
  },[themeDark])
  return (
    <Container>
      {onChangeTheme &&
        <Editor
          value={fieldValue}
          apiKey={config.tinyToken}
          
          onEditorChange={(newValue:any, editor) => {
            onChange(newValue);
          }}
          init={{
            skin: themeDark ? "oxide-dark" : "fabric",
            content_css: themeDark ? "dark" : "fabric",
            height: 400,
            menubar: false,
            codesample_languages: [
              { text: 'HTML/XML', value: 'markup' },
              { text: 'JavaScript', value: 'javascript' },
              { text: 'CSS', value: 'css' },
              { text: 'PHP', value: 'php' },
              { text: 'Ruby', value: 'ruby' },
              { text: 'Python', value: 'python' },
              { text: 'Java', value: 'java' },
              { text: 'C', value: 'c' },
              { text: 'C#', value: 'csharp' },
              { text: 'C++', value: 'cpp' }
            ],
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image',
              'anchor', 'codesample', 'fullscreen',
              'insertdatetime', 'media', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | ' +
              'bold italic forecolor | alignleft aligncenter alignright alignjustify |' +
              'link codesample blockquote |' +
              'bullist numlist |' +
              'removeformat',
          }}
        />
      }
      <ErrorText>
        {fieldError}
      </ErrorText>
    </Container>
  )
}

export default TextEditor